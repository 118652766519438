import { SearchActions, SearchActionTypes } from '@app/store/actionTypes/searchActionTypes';

export type SearchState = {
  isSearchBarVisible: boolean | null;
};

const initialState: SearchState = {
  isSearchBarVisible: null,
};

export default (
  state = initialState,
  action: SearchActions,
): SearchState => {
  switch (action.type) {
    case SearchActionTypes.SET_SEARCH_BAR_VISIBILITY:
      return {
        ...state,
        isSearchBarVisible: action.payload,
      };
    default:
      return state;
  }
};
