import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { InviolaCatalogItem } from '@app/types/inviolaTypes';
import { CommonButton } from '@app/components/Buttons';
import { useInviolaPath } from '@app/components/Inviola/InviolaHooks';

interface MoreInfoButtonProps {
  item: InviolaCatalogItem;
}

const MoreInfoButton = ({ item }: MoreInfoButtonProps): React.ReactElement => {
  const history = useHistory();
  const { catalogPath } = useInviolaPath();

  return (
    <InfoButton onClick={() => history.push(`${catalogPath}/${item.id}`)}>
      <FormattedMessage id="buttons.moreInfos" />
    </InfoButton>
  );
};

export default MoreInfoButton;

export const InfoButton = styled(CommonButton)`
  border-color: ${(props): string => props.theme.color.red};
  color: ${(props): string => props.theme.color.red};
  height: 30px;
  width: 200px;
  margin: 0 7px;

  font-family: ${({ theme }): string => theme.fontFamily.commutersSans};
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;

  &:hover {
    border-color: ${(props): string => props.theme.color.red};
    background-color: ${(props): string => props.theme.color.red};
    color: white;
  }
`;
