import { useContext, useEffect } from 'react';
import { MountContext } from '@app/ReactContext';
import { useLanguage } from '@app/components/Hooks';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AppState from '@app/types/appStateTypes';
import { WebLineCarouselType, WebTemplateData } from '@app/types/webTemplateTypes';
import { getLineCarouselDataList } from '@app/store/actions/webTemplateActions';

export const useLineCarouselDataListDownload = (type: WebLineCarouselType, limit = 10): void => {
  const { isInitialMount } = useContext(MountContext);
  const language = useLanguage();

  const hasItems = useSelector<AppState, boolean>(
    (state) => !!state.webTemplate.dataList?.[type]?.[language]?.length,
    shallowEqual,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    // check if data has been loaded on server before very first render
    if (!isInitialMount || !hasItems) {
      dispatch(getLineCarouselDataList({
        language,
        type,
        limit,
      }));
    }
  }, [type, language]);
};

export const useLineCarouselDataList = (
  type: WebLineCarouselType,
): WebTemplateData[] | undefined => {
  const language = useLanguage();
  return useSelector<AppState, WebTemplateData[] | undefined>(
    (state) => state.webTemplate.dataList?.[type]?.[language],
    shallowEqual,
  );
};
