import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { WebTemplateEditorialDuo } from '@app/types/webTemplateTypes';
import { WebEditorialElementContainer } from '@app/components/WebTemplates/elements/WebEditorialElement';
import { getStaticImageConfig } from '@app/helpers/imageHelper';

interface WebCtaDuoButtonElementProps {
  data: WebTemplateEditorialDuo;
}

const WebEditorialDuoElement = ({ data }: WebCtaDuoButtonElementProps): React.ReactElement => (
  <Container isContentFirst={data.isContentFirst}>
    <img
      {...getStaticImageConfig({ url: data.image.url, width: 920 })}
      alt={data.image.description}
    />
    <TextContainer>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: data.content }} />
    </TextContainer>
  </Container>
);

export default WebEditorialDuoElement;

const Container = styled(WebEditorialElementContainer)<{ isContentFirst: boolean }>`
  display: flex;
  flex-direction: ${({ isContentFirst }): string => (isContentFirst ? 'column-reverse' : 'column')};
  align-items: flex-start;

  @media ${Devices.tabletLarge} {
    flex-direction: ${({ isContentFirst }): string => (isContentFirst ? 'row-reverse' : 'row')};
  }

  img {
    min-width: 50% !important;
    max-width: 100%;

    @media ${Devices.tabletLarge} {
      padding-right: 30px;
    }
  }
`;

const TextContainer = styled.div`
  padding-top: 20px;
`;
