import { ContentItem, Elements } from '@kentico/kontent-delivery';

import WebPartner from './web_partner';
import WebPerson from './web_person';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export default class WebDepartment extends ContentItem {
    public name: Elements.TextElement;
    public title: Elements.TextElement;
    public showDivider: Elements.MultipleChoiceElement;
    public content: Elements.LinkedItemsElement<WebPartner | WebPerson>;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'show_divider') {
                    return 'showDivider';
                }
                return elementName;
            })
        });
    }
}
