import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import {
  getSecondLevelMenuItem,
  getPageType,
  getTopLevelMenuItem,
  getThirdLevelMenuItem, getLastLevelMenuId, isBaseMenuItem,
} from '@app/helpers/menuHelpers';

import AppState from '@app/types/appStateTypes';
import { PageType } from '@app/types/commonPageTypes';
import {
  BaseMenuItem, ExternalLinkMenuItem,
  LocalizedBaseMenuItem,
  LocalizedMenuItem, LocalizedSubMenuItem, MenuItem, SeoData, SubMenuItem,
} from '@app/types/configurationTypes';

import { useLanguage } from '@app/components/Hooks';
import { useEffect } from 'react';
import { setCommonPageBaseMultiLangRoute } from '@app/store/actions/commonPageActions';
import { RouteParams } from '@app/types/routerTypes';
import { CommonPageBaseUrlParams } from '@app/helpers/routeHelpers';
import { Errors, PageError } from '@app/types/errorTypes';
import { useIsConfigurationDownloadCompleted } from '@app/components/Hooks/ConfigurationHooks';

export function useLocalizedTopLevelMenu(): LocalizedMenuItem {
  const { topLevel = '' } = useParams();
  const language = useLanguage();

  return useSelector<AppState, LocalizedMenuItem>(
    (state) => getTopLevelMenuItem(state, topLevel, language),
    isEqual,
  );
}

export function useTopLevelMenu(): MenuItem {
  const language = useLanguage();
  return useLocalizedTopLevelMenu()[language];
}

export function useLocalizedSecondLevelMenu(): LocalizedSubMenuItem | undefined {
  const { secondLevel = '', topLevel = '' } = useParams();
  const language = useLanguage();

  return useSelector<AppState, LocalizedSubMenuItem | undefined>(
    (state) => getSecondLevelMenuItem(state, topLevel, secondLevel, language),
    isEqual,
  );
}

export function useSecondLevelMenu(): SubMenuItem | undefined {
  const language = useLanguage();
  return useLocalizedSecondLevelMenu()?.[language];
}

export function useLocalizedThirdLevelMenu(): LocalizedBaseMenuItem | undefined {
  const { thirdLevel = '', secondLevel = '', topLevel = '' } = useParams();
  const language = useLanguage();

  return useSelector<AppState, LocalizedBaseMenuItem | undefined>(
    (state) => getThirdLevelMenuItem(state, topLevel, secondLevel, thirdLevel, language),
    isEqual,
  );
}

export function useThirdLevelMenu(): BaseMenuItem | undefined {
  const language = useLanguage();
  return useLocalizedThirdLevelMenu()?.[language];
}

const useLastLevelMenuItemData = (): BaseMenuItem | ExternalLinkMenuItem => {
  const topLevelMenu = useTopLevelMenu();
  const secondLevelMenu = useSecondLevelMenu();
  const thirdLevelMenu = useThirdLevelMenu();

  return thirdLevelMenu || secondLevelMenu?.data || topLevelMenu?.data;
};

export const useSeoData = (): SeoData | undefined => {
  const lastLevelMenuItemData = useLastLevelMenuItemData();
  if (lastLevelMenuItemData && isBaseMenuItem(lastLevelMenuItemData)) {
    return lastLevelMenuItemData.seo;
  }
};

export function useLastLevelMenuId(): string | null {
  const language = useLanguage();
  const params: RouteParams = useParams();

  return useSelector<AppState, string | null>(
    (state) => getLastLevelMenuId({ state, language, params }),
    shallowEqual,
  );
}

export const usePageType = (): PageType | undefined => {
  const { forthLevel = '' } = useParams();
  const secondLevelMenu = useSecondLevelMenu();

  return secondLevelMenu ? getPageType(secondLevelMenu, forthLevel) : PageType.Template;
};

export function useCommonPageBaseMultiLangRoute(initialParams?: CommonPageBaseUrlParams): void {
  const data: RouteParams = useParams();
  const language = useLanguage();
  const dispatch = useDispatch();

  useEffect((): void => {
    dispatch(setCommonPageBaseMultiLangRoute({ ...data, language }, initialParams));
  });
}

export function usePageValidation(): Errors {
  const { secondLevel = '' } = useParams();
  const isConfigurationDownloadCompleted = useIsConfigurationDownloadCompleted();
  const topLevelMenu = useTopLevelMenu();
  const secondLevelMenu = useSecondLevelMenu();

  const isSecondLevelMenuCorrect = !secondLevel || !!secondLevelMenu;

  return !isConfigurationDownloadCompleted || (topLevelMenu && isSecondLevelMenuCorrect) ? null : PageError.NotFound;
}
