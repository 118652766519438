import { ContentItem, Elements } from '@kentico/kontent-delivery';

import RelatedMenuItem from './related_menu_item';
import ExternalLink from './external_link';

export default class MenuItem extends ContentItem {
  public seoMetadataMetaRobots: Elements.MultipleChoiceElement;
  public seoMetadataSocialImage: Elements.CustomElement;
  public seoMetadataSocialDescription: Elements.TextElement;
  public squadOptaApi: Elements.MultipleChoiceElement;
  public subitems: Elements.LinkedItemsElement<MenuItem | RelatedMenuItem | ExternalLink>;
  public relatedItems: Elements.LinkedItemsElement<RelatedMenuItem | ExternalLink>;
  // Todo: update with correct value
  public webTemplate: Elements.LinkedItemsElement<ContentItem>;
  public isVisible: Elements.MultipleChoiceElement;
  public isPrivate: Elements.MultipleChoiceElement;
  public itemType: Elements.MultipleChoiceElement;
  public displayInHeaderMenu: Elements.MultipleChoiceElement;
  public seoMetadataCanonicalTag: Elements.TextElement;
  public name: Elements.TextElement;
  public squadOptaId: Elements.TextElement;
  public news: Elements.TaxonomyElement;
  public url: Elements.TextElement;
  public seoMetadataMetaDescription: Elements.TextElement;
  public partnersBottomSection: Elements.MultipleChoiceElement;
  public seoMetadataSocialTitle: Elements.TextElement;
  public seoMetadataMetaTitle: Elements.TextElement;
  public id: Elements.TextElement;
  constructor() {
    super({
      propertyResolver: ((elementName: string) => {
        if (elementName === 'seo_metadata__meta_robots') {
          return 'seoMetadataMetaRobots';
        }
        if (elementName === 'seo_metadata__social_image') {
          return 'seoMetadataSocialImage';
        }
        if (elementName === 'seo_metadata__social_description') {
          return 'seoMetadataSocialDescription';
        }
        if (elementName === 'squad_opta_api') {
          return 'squadOptaApi';
        }
        if (elementName === 'related_items') {
          return 'relatedItems';
        }
        if (elementName === 'web_template') {
          return 'webTemplate';
        }
        if (elementName === 'is_visible') {
          return 'isVisible';
        }
        if (elementName === 'is_private') {
          return 'isPrivate';
        }
        if (elementName === 'item_type') {
          return 'itemType';
        }
        if (elementName === 'display_in_header_menu') {
          return 'displayInHeaderMenu';
        }
        if (elementName === 'seo_metadata__canonical_tag') {
          return 'seoMetadataCanonicalTag';
        }
        if (elementName === 'squad_opta_id') {
          return 'squadOptaId';
        }
        if (elementName === 'seo_metadata__meta_description') {
          return 'seoMetadataMetaDescription';
        }
        if (elementName === 'partners_bottom_section') {
          return 'partnersBottomSection';
        }
        if (elementName === 'seo_metadata__social_title') {
          return 'seoMetadataSocialTitle';
        }
        if (elementName === 'seo_metadata__meta_title') {
          return 'seoMetadataMetaTitle';
        }
        return elementName;
      })
    });
  }
}
