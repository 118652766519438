import React from 'react';
import { FormattedDate } from 'react-intl';
import styled from 'styled-components';

import { Colors, Devices } from '@app/styles';
import { HorizontalScroll } from '@app/components';
import { scrollToElement } from '@app/helpers/documentHelper';
import { useCompetitionSelector, useSeasonIdActiveStateCheck } from '@app/pages/Season/Season/SeasonHooks';
import { isOptaSDCompetition } from '@app/services/opta/helpers/competitionHelpers';
import { useCalendarSelector } from '../ScheduleHooks';

const MatchCalendar = (): React.ReactElement => {
  const calendar = useCalendarSelector();
  const competitions = useCompetitionSelector();
  const isSeasonIdActive = useSeasonIdActiveStateCheck();
  const isOptaSD = isOptaSDCompetition(competitions?.categoryId);

  return (
    <Section>
      <Container>
        <HorizontalScrollWrapper>
          <HorizontalScroll color={Colors.shadow}>
            <Calendar>
              {
                calendar
                  .map((date) => {
                    const month = new Date(date);
                    const today = new Date();

                    const matchDateFormatted = `${month.getMonth()}-${month.getFullYear()}`;
                    const todayDateFormatted = `${today.getMonth()}-${today.getFullYear()}`;

                    return (
                      <Month
                        key={date}
                        onClick={(): void => scrollToElement(date, true)}
                        isSelected={(isSeasonIdActive || isOptaSD) && matchDateFormatted === todayDateFormatted}
                      >
                        <FormattedDate value={month} month="short" />
                      </Month>
                    );
                  })
              }
            </Calendar>
          </HorizontalScroll>
        </HorizontalScrollWrapper>
      </Container>
    </Section>
  );
};

export default MatchCalendar;

const Section = styled.section`
  background-color: transparent;
  margin-bottom: 15px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 960px;
  height: 40px;
  padding: 0 10px;
  margin: auto;

  @media ${Devices.responsiveContent} {
    padding: 0;
  }
`;

const HorizontalScrollWrapper = styled.div`
  width: 100%;
  height: inherit;
`;

const Calendar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
  height: 100%;
`;

const Month = styled.span<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  color: #000;
  opacity: ${({ isSelected }): number => (isSelected ? 1 : 0.4)};
  font-size: 13px;
  font-weight: bold;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  letter-spacing: 0.15px;
  margin-right: 30px;
  text-transform: uppercase;
  transition: all 0.3s ease;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
    transition: all 0.3s ease;

    &:after {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
`;
