import React from 'react';
import { DefenderStatistics } from '@app/types/teamsTypes';

import {
  ValueAndCaption, BlockContainerColumn, StatsBlock, StatsColumns,
} from '@app/components/Statistics';
import {
  DisciplineStatistics, DuelsStatistics, PassesStatistics,
} from '@app/pages/PlayerProfile/Profiles/PlayerPositionStatistics/StatisticsSections';

interface DefenderStatisticsProps {
  statistics?: DefenderStatistics;
}

const DefenderPlayerStatistics = ({ statistics }: DefenderStatisticsProps): React.ReactElement => (
  <BlockContainerColumn>
    <StatsBlock marginBottom="20px">
      <StatsBlock>
        <ValueAndCaption value={statistics?.recoveries ?? 0} caption="statistics.recoveries" isSmall />
      </StatsBlock>
      <StatsBlock>
        <ValueAndCaption value={statistics?.totalTackles ?? 0} caption="statistics.tackles" isSmall />
      </StatsBlock>
    </StatsBlock>
    <StatsBlock marginBottom="20px">
      <StatsBlock>
        <ValueAndCaption value={statistics?.blocks ?? 0} caption="statistics.blocks" isSmall />
      </StatsBlock>
      <StatsBlock>
        <ValueAndCaption value={statistics?.totalClearances ?? 0} caption="statistics.clearances" isSmall />
      </StatsBlock>
    </StatsBlock>
    <StatsBlock>
      <StatsBlock borderWidth="0 1px 0 0">
        <ValueAndCaption value={statistics?.totalFoulsConceded ?? 0} caption="statistics.foulsCommitted" isSmall />
      </StatsBlock>
      <StatsBlock>
        <ValueAndCaption value={statistics?.totalFoulsWon ?? 0} caption="statistics.foulsSustained" isSmall />
      </StatsBlock>
    </StatsBlock>
  </BlockContainerColumn>
);

const DefenderLayoutStatistics = ({ statistics }: DefenderStatisticsProps): React.ReactElement => (
  <>
    <StatsColumns>
      <DefenderPlayerStatistics statistics={statistics} />
      <PassesStatistics statistics={statistics} />
    </StatsColumns>
    <StatsColumns>
      <DuelsStatistics statistics={statistics} />
      <DisciplineStatistics statistics={statistics} />
    </StatsColumns>
  </>
);

export default DefenderLayoutStatistics;
