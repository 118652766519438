import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { ThronImage } from '@app/components/Image';
import { Metadata } from '@app/components';

import { usePromotionsLoadingState } from '@app/pages/Inviola/PromotionsListPage/PromotionsListPageHooks';
import { LocalizedDate } from '@app/components/Localization';
import { PageWrapper } from '@app/pages/components';
import NewsContent from '@app/pages/NewsDetails/NewsContent';
import {
  useDownloadPromotion,
  useInviolaPromotionsDetailsMultiLangRoute,
  usePromotion,
  usePromotionsDetailsPageValidation,
} from './PromotionsDetailsHooks';

const InviolaPromotionsDetails: React.FC = () => {
  useDownloadPromotion();
  useInviolaPromotionsDetailsMultiLangRoute();
  const promotion = usePromotion();
  const error = usePromotionsDetailsPageValidation(promotion);
  const isLoading = usePromotionsLoadingState();

  return (
    <PageWrapper error={error} isLoading={isLoading}>
      {promotion?.seo && <Metadata seo={promotion.seo} />}
      <Container>
        <ThronImage
          thronId={promotion?.mainImage?.thronId}
          alt={promotion?.mainImage?.name ?? ''}
          useLazyLoading
        />
        <Title>{promotion?.title}</Title>
        {promotion?.publicationDate && (
          <Time>
            <LocalizedDate date={promotion.publicationDate} />
          </Time>
        )}
        <Content>
          <NewsContent content={promotion?.content ?? []} />
        </Content>
      </Container>
    </PageWrapper>
  );
};

const Container = styled.div`
  max-width: 940px;
  margin: auto;
`;

const Title = styled.h1`
  margin: 30px 20px 0;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0.16px;
  color: #000000;
  text-transform: uppercase;

  @media ${Devices.desktop} {
    margin: 50px 10px 0;
  }
`;

const Time = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-weight: bold;
  font-size: 15px;
  color: #000000;
  opacity: 0.3;
  margin: 10px 20px;

  @media ${Devices.desktop} {
    margin: 10px;
  }
`;

const Content = styled.div`
  margin: 0;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 21px;
  font-weight: 300;
  line-height: 1.52;
  letter-spacing: 0.34px;
  text-align: justify;
  color: #000000;

  @media ${Devices.desktop} {
    // margin: 36px 10px 50px;
  }
`;

export default InviolaPromotionsDetails;
