import { ContentItem, Elements } from '@kentico/kontent-delivery';
import Competition from './competition';

export class Game extends ContentItem {
    public seoMetadataSocialDescription: Elements.TextElement;
    public seoMetadataMetaRobots: Elements.MultipleChoiceElement;
    public datetime: Elements.DateTimeElement;
    public competition: Elements.LinkedItemsElement<Competition>;
    public hospitalityUrl: Elements.TextElement;
    public awayTeam: Elements.LinkedItemsElement<ContentItem>;
    public season: Elements.MultipleChoiceElement;
    public messageDisplay: Elements.MultipleChoiceElement;
    public ticketsUrl: Elements.TextElement;
    public companionAd: Elements.AssetsElement;
    public seoMetadataCanonicalTag: Elements.TextElement;
    public messageTextEn: Elements.TextElement;
    public hospitalityActive: Elements.MultipleChoiceElement;
    public ticketsActive: Elements.MultipleChoiceElement;
    public ticketsPopup: Elements.MultipleChoiceElement;
    public homeTeam: Elements.LinkedItemsElement<ContentItem>;
    public ticketsPricing: Elements.LinkedItemsElement<ContentItem>;
    public seoMetadataMetaDescription: Elements.TextElement;
    public url: Elements.UrlSlugElement;
    public ticketsMessageEnglish: Elements.TextElement;
    public seoMetadataSocialImage: Elements.CustomElement;
    public companionAdUrl: Elements.TextElement;
    public seoMetadataMetaTitle: Elements.TextElement;
    public markUpScriptDescription: Elements.TextElement;
    public startingPrice: Elements.TextElement;
    public seoMetadataSocialTitle: Elements.TextElement;
    public gameOptaId: Elements.TextElement;
    public streamLanguage: Elements.MultipleChoiceElement;
    public audioStreamUrl: Elements.TextElement;
    public additionalAudioStreamUrl: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'seo_metadata__social_description') {
                    return 'seoMetadataSocialDescription';
                }
                if (elementName === 'seo_metadata__meta_robots') {
                    return 'seoMetadataMetaRobots';
                }
                if (elementName === 'hospitality_url') {
                    return 'hospitalityUrl';
                }
                if (elementName === 'away_team') {
                    return 'awayTeam';
                }
                if (elementName === 'message_display') {
                    return 'messageDisplay';
                }
                if (elementName === 'tickets_url') {
                    return 'ticketsUrl';
                }
                if (elementName === 'companion_ad') {
                    return 'companionAd';
                }
                if (elementName === 'seo_metadata__canonical_tag') {
                    return 'seoMetadataCanonicalTag';
                }
                if (elementName === 'message_text_en') {
                    return 'messageTextEn';
                }
                if (elementName === 'hospitality_active') {
                    return 'hospitalityActive';
                }
                if (elementName === 'tickets_active') {
                    return 'ticketsActive';
                }
                if (elementName === 'tickets_popup') {
                    return 'ticketsPopup';
                }
                if (elementName === 'home_team') {
                    return 'homeTeam';
                }
                if (elementName === 'tickets_pricing') {
                    return 'ticketsPricing';
                }
                if (elementName === 'seo_metadata__meta_description') {
                    return 'seoMetadataMetaDescription';
                }
                if (elementName === 'tickets_message_english') {
                    return 'ticketsMessageEnglish';
                }
                if (elementName === 'seo_metadata__social_image') {
                    return 'seoMetadataSocialImage';
                }
                if (elementName === 'companion_ad_url') {
                    return 'companionAdUrl';
                }
                if (elementName === 'seo_metadata__meta_title') {
                    return 'seoMetadataMetaTitle';
                }
                if (elementName === 'mark_up_script_description') {
                    return 'markUpScriptDescription';
                }
                if (elementName === 'starting_price') {
                    return 'startingPrice';
                }
                if (elementName === 'seo_metadata__social_title') {
                    return 'seoMetadataSocialTitle';
                }
                if (elementName === 'game_opta_id') {
                    return 'gameOptaId';
                }
                if (elementName === 'stream_language') {
                    return 'streamLanguage';
                }
                if (elementName === 'audio_stream_url') {
                    return 'audioStreamUrl';
                }
                if (elementName === 'additional_audio_stream_url') {
                    return 'additionalAudioStreamUrl';
                }
                return elementName;
            })
        });
    }
}
