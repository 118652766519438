import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { EditorialContentTypes, NewsListComponent } from '@app/types/newsTypes';

import { ThreeColumnGrid, TwoColumnGrid, TwoThreeColumnGrid } from '@app/components';
import { NewItem } from '@app/components/News';
import { LoadMoreButton } from '@app/components/Buttons';

import { useCommonPageBaseMultiLangRoute } from '@app/pages/CommonPage/CommonPageHooks';

const getButtonNameId = (newsType: EditorialContentTypes): string => {
  switch (newsType) {
    case EditorialContentTypes.NEWS:
      return 'buttons.loadingNews';
    case EditorialContentTypes.VIDEO:
      return 'buttons.loadingVideos';
    default:
      return 'buttons.loadingPhotos';
  }
};

interface NewsList extends NewsListComponent {
  dataRequest: () => void;
}

const NewsList: React.FC<NewsList> = ({
  items, hasMoreData, isLoading, layout, dataRequest,
}) => {
  useCommonPageBaseMultiLangRoute();

  let GridComponent;
  let sizes = {
    0: { width: 450, height: 250 },
  };

  switch (layout) {
    case 'layout_2x2':
      GridComponent = TwoColumnGrid;
      break;
    case 'layout_2x3':
      GridComponent = TwoThreeColumnGrid;
      sizes = {
        0: { width: 450, height: 250 },
      };
      break;
    default:
      GridComponent = ThreeColumnGrid;
  }

  return (
    <>
      <GridContainer>
        <GridComponent>
          {
            items.map((item) => (
              <NewItem data={item} key={item.id} imageWidthMap={sizes} />
            ))
          }
        </GridComponent>
      </GridContainer>
      { hasMoreData && (
        <ButtonContainer>
          <LoadMoreButton disabled={isLoading} onClick={dataRequest}>
            <FormattedMessage id={isLoading ? 'buttons.loading.inProgress' : getButtonNameId(items[0]?.type)} />
          </LoadMoreButton>
        </ButtonContainer>
      ) }
    </>
  );
};

const ButtonContainer = styled.div`
  padding: 0 0 40px;
`;

const GridContainer = styled.div`
  padding-bottom: 40px;
`;

export default NewsList;
