import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Promotion extends ContentItem {
  public mainImage: Elements.CustomElement;
  public title: Elements.TextElement;
  public thumbnail: Elements.CustomElement;
  public content: Elements.RichTextElement;
  public publicationDate: Elements.DateTimeElement;
  public expirationDate: Elements.DateTimeElement;
  public url: Elements.UrlSlugElement;
  public seoMetadataCanonicalTag: Elements.TextElement;
  public seoMetadataSocialImage: Elements.CustomElement;
  public seoMetadataSocialTitle: Elements.TextElement;
  public seoMetadataSocialDescription: Elements.TextElement;
  public seoMetadataMetaRobots: Elements.MultipleChoiceElement;
  public seoMetadataMetaDescription: Elements.TextElement;
  public seoMetadataMetaTitle: Elements.TextElement;

  constructor() {
    super({
      propertyResolver: ((elementName: string) => {
        if (elementName === 'main_image') {
          return 'mainImage';
        }
        if (elementName === 'publication_date') {
          return 'publicationDate';
        }
        if (elementName === 'expiration_date') {
          return 'expirationDate';
        }
        if (elementName === 'seo_metadata__meta_description') {
          return 'seoMetadataMetaDescription';
        }
        if (elementName === 'seo_metadata__social_title') {
          return 'seoMetadataSocialTitle';
        }
        if (elementName === 'seo_metadata__meta_title') {
          return 'seoMetadataMetaTitle';
        }
        if (elementName === 'seo_metadata__meta_robots') {
          return 'seoMetadataMetaRobots';
        }
        if (elementName === 'seo_metadata__social_image') {
          return 'seoMetadataSocialImage';
        }
        if (elementName === 'seo_metadata__social_description') {
          return 'seoMetadataSocialDescription';
        }
        if (elementName === 'seo_metadata__canonical_tag') {
          return 'seoMetadataCanonicalTag';
        }
        return elementName;
      })
    });
  }
}
