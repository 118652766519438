import React from 'react';
import styled from 'styled-components';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import { WebTabsTemplateElement } from '@app/types/webTemplateTypes';
import LocalizedLink from '@app/components/Localization/LocalizedLink';
import { Metadata } from '@app/components';
import AppRoutes from '@app/constants/routesConstants';
import { useCurrentTab } from '@app/pages/WebTemplate/WebTemplateHooks';

import WebTemplateSelect from '../WebTemplateSelect';
import { ContainerMedium } from '../components';

interface WebTabsElementProps {
  data: WebTabsTemplateElement;
}

const WebTabsElement = ({ data }: WebTabsElementProps): React.ReactElement => {
  const {
    topLevel = '', secondLevel = '',
  } = useParams();
  const { pathname } = useLocation();

  const currentTab = useCurrentTab();
  const history = useHistory();

  if (!currentTab) {
    const firstTabUrl = data.tabs[0].url;
    history.push(`${pathname}/${firstTabUrl}`);
  }
  const activeSeo = data?.tabs?.find((tab) => tab.url === currentTab?.url)?.seo;

  return (
    <ContainerMedium>
      { !!activeSeo && <Metadata seo={activeSeo} /> }
      <Container>
        <TabContainer>
          {data?.tabs?.map((tabItem, index) => (
            <TabLocalizedLink
              pathKey={AppRoutes.CommonPage.path}
              key={tabItem.id}
              pathParams={{
                topLevel,
                secondLevel,
                thirdLevel: data.tabs[index].url,
                forthLevel: '',
                fifthLevel: '',
              }}
              active={tabItem.url === currentTab?.url}
              isTab
            >
              {tabItem.name}
            </TabLocalizedLink>
          ))}
        </TabContainer>
        {
          currentTab
        && currentTab.content.map((content) => <WebTemplateSelect key={content?.id} data={content} />)
}
      </Container>
    </ContainerMedium>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TabContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const TabLocalizedLink = styled(LocalizedLink)<{ active: boolean }>`
  margin-right: 30px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  display: inline-block;
  color: ${(props): string => props.theme.color.purple};
  transition: all 0.3s ease;
  border-bottom: ${({ active }): string => (active ? '3px solid #5f259f' : 'none')};
  padding-bottom: 5px;

  &:last-of-type {
    margin-right: 0;
  }

  & {
    opacity: ${({ active }): string => (active ? '1' : '0.4')};
  }

  &:hover {
    opacity: 1;
  }
`;

export default WebTabsElement;
