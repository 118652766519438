import * as React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { isMenuItemVisible } from '@app/helpers/menuHelpers';
import { formatSubMenuLinkProps } from '@app/helpers/routeHelpers';
import { CompetitionTypesMap } from '@app/services/opta/constants/competitionConstants';

import { MenuItemTypes, SubMenuId } from '@app/types/configurationTypes';

import { Colors, Devices } from '@app/styles';
import { LocalizedLink } from '@app/components/Localization';
import { HorizontalScroll } from '@app/components';
import { useTopLevelMenu } from '@app/pages/CommonPage/CommonPageHooks';
import { useIsConnected } from '@app/components/Inviola/InviolaHooks';
import {
  useCompetitionSelector,
  useActiveSeasonIdForCompetitionSeriaA,
  useActiveUrlSlugForCompetitionSeriaA,
} from '@app/pages/Season/Season/SeasonHooks';

const SecondLevelMenu: React.FC = () => {
  const { secondLevel = '', thirdLevel = '', forthLevel = '' } = useParams();
  const isConnected = useIsConnected();
  const topLevelMenuItem = useTopLevelMenu();
  const competition = useCompetitionSelector();
  const subItems = Object.values(topLevelMenuItem?.navigation ?? {})
    .filter(({ data }) => {
      const useStandingRule = competition && data?.id === SubMenuId.STANDINGS;
      const isStandingVisible = data.isVisible && competition?.type && [
        CompetitionTypesMap.league,
        CompetitionTypesMap.tournament,
      ].includes(competition?.type);
      return useStandingRule ? isStandingVisible : isMenuItemVisible(data, isConnected);
    });
  const defaultSeasonThirdLevel = useActiveUrlSlugForCompetitionSeriaA();
  const defaultSeasonForthLevel = useActiveSeasonIdForCompetitionSeriaA();
  const seasonData = {
    thirdLevel: thirdLevel ?? defaultSeasonThirdLevel,
    forthLevel: forthLevel ?? defaultSeasonForthLevel,
  };

  if (!subItems.length) return null;

  return (
    <Container>
      <Menu>
        <HorizontalScroll color={Colors.white}>
          {
            subItems.map((item) => (
              <MenuItem key={item.data.id}>
                {
                  item.data.type === MenuItemTypes.ExternalLink && (
                    <MenuItemLink
                      as="a"
                      href={item.data?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      isActive={secondLevel === item.data?.url}
                    >
                      {item.data.name}
                    </MenuItemLink>
                  )
                }
                {item.data.type === MenuItemTypes.BaseMenuItem && (
                  <MenuItemLink
                    {...formatSubMenuLinkProps(topLevelMenuItem.data, item, seasonData)}
                    isActive={secondLevel === item.data?.url}
                  >
                    {item.data.name}
                  </MenuItemLink>
                )}
              </MenuItem>
            ))
          }
        </HorizontalScroll>
      </Menu>
    </Container>
  );
};

const Container = styled.nav`
  background-color: ${(props): string => props.theme.color.red};
  padding: 0 var(--horizontal-padding-mobile);

  @media ${Devices.desktopSmall} {
    padding: 0 var(--horizontal-padding);
  }
`;

const Menu = styled.ul`
  max-width: 920px;
  margin: 0 auto;
  height: 30px;
`;

const MenuItemLink = styled(LocalizedLink)<{ isActive: boolean }>`
  font-size: 11px;
  color: white;
  display: flex;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
  position: relative;

  &::after {
    display: ${({ isActive }): string => (isActive ? 'block' : 'none')};
    content: '';
    position: absolute;
    height: 2px;
    width: 40px;
    background-color: white;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &:hover {
    color: white;
    text-decoration: none;
  }
`;

const MenuItem = styled.li`
  display: inline-block;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-weight: bold;
  height: 100%;
  vertical-align: top;
  position: relative;
  padding: 0 15px;

  &:first-child {
    padding-left: 0;
  }
`;

export default SecondLevelMenu;
