import React from 'react';
import styled from 'styled-components';

import { formatDate } from '@app/helpers/dateHelpers';

import { AppLanguagesMap } from '@app/constants/localizationConstants';

import { useLanguage } from '@app/components/Hooks';

const LocalizedDate = ({ date, dateFormat = 'dd MMMM yyyy' }: { date: string | number | Date; dateFormat?: string }): React.ReactElement => {
  const language = useLanguage();
  const formattedDate = formatDate(date, dateFormat, language);

  return (
    <Date textTransform={language === AppLanguagesMap.en ? 'capitalize' : 'lowercase'}>
      {formattedDate}
    </Date>
  );
};

export default LocalizedDate;

const Date = styled.span<{ textTransform: string }>`
  text-transform: ${({ textTransform }): string => textTransform};
`;
