import React from 'react';
import { Helmet } from 'react-helmet';

import { langCodeToLanguageTerritory, AppLanguages } from '@app/constants/localizationConstants';

import { SeoData, SeoSocialTypes } from '@app/types/configurationTypes';

import { useLanguage, useCurrentRoute } from '@app/components/Hooks';

interface Metadata {
  seo: SeoData;
}

const Metadata = ({ seo }: Metadata): React.ReactElement => {
  const language = langCodeToLanguageTerritory[useLanguage()];
  const currentRoute = useCurrentRoute();

  const hrefLangData = !seo.canonicalTag
    ? AppLanguages.reduce((acc, lang) => {
      acc.push(<link
        key={lang}
        rel="alternate"
        href={`${window?.HOST_URL || window?.location?.origin}${currentRoute[lang]}`}
        hrefLang={lang}
      />);
      return acc;
    }, [] as React.ReactElement[])
    : null;

  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <link rel="canonical" href={seo.canonicalTag || window?.REQUEST_URL || window?.location?.href} />
      { hrefLangData }
      {
        !!seo.meta && (
          <meta name="robots" content="noindex, nofollow" />
        )
      }
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.socialTitle || seo.title} />
      <meta name="twitter:description" content={seo.socialDescription || seo.description} />
      <meta
        property="twitter:image"
        content={seo.socialImageUrl || `${window?.HOST_URL || window?.location?.origin}/images/logo.svg`}
      />
      <meta property="og:type" content={seo?.socialType || SeoSocialTypes.WEBSITE} />
      <meta property="og:title" content={seo.socialTitle || seo.title} />
      <meta property="og:url" content={window?.REQUEST_URL || window?.location?.href} />
      <meta property="og:description" content={seo.socialDescription || seo.description} />
      <meta property="og:locale" content={language} />
      <meta property="og:site_name" content="ACF Fiorentina" />
      <meta
        property="og:image"
        content={seo.socialImageUrl || `${window?.HOST_URL || window?.location?.origin}/images/logo.svg`}
      />
    </Helmet>
  );
};

export default Metadata;
