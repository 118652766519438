import pull from 'lodash/pull';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales';

import { ActionWithPromiseReturn, Action } from '@app/types/actionTypes';
import { LanguageType } from '@app/types/localizationTypes';
import {
  NewsActionTypes,
  ResetNewsList,
  SetLatestNews,
  ResetLatestNews,
  SetLatestGallery,
  ResetLatestGallery,
  SetLatestVideo,
  ResetLatestVideo,
  SetNewsList,
  SetNewsLoadingState,
  SetNews,
  ResetNews,
  SetVideo,
  ResetVideo,
  SetGallery,
  ResetGallery,
  SetError,
  SetDataList,
} from '@app/store/actionTypes/newsActionTypes';
import AppState from '@app/types/appStateTypes';
import {
  BaseNewsItem, EditorialContentTypes, LocalizedVideoItem, LocalizedGalleryItem, LocalizedNewsItem, NewsList,
} from '@app/types/newsTypes';
import { PageError } from '@app/types/errorTypes';
import { RouteParams } from '@app/types/routerTypes';

import { ApplicationConfig, LayoutTypeToItemsForDownload } from '@app/constants/configurationConstants';
import { NewsNavigationTypeMap } from '@app/constants/newsConstants';
import { AppLanguages } from '@app/constants/localizationConstants';

import { findCategoryMenuItemBySlug, getThirdLevelMenuItem } from '@app/helpers/menuHelpers';

import { waitForConfigurationDownload } from '@app/store/actions/configurationActions';

import { getNewsDetails, getNewsList as getNewsListService } from '@app/services/kentico/newsService';

function setNewsLoadingState(state: boolean): SetNewsLoadingState {
  return {
    type: NewsActionTypes.SET_NEWS_LOADING_STATE,
    payload: state,
  };
}

function setNewsList(news: NewsList): SetNewsList {
  return {
    type: NewsActionTypes.SET_NEWS_LIST,
    payload: news,
  };
}

function setDataList(type: EditorialContentTypes, data: Record<string, NewsList>): SetDataList {
  return {
    type: NewsActionTypes.SET_DATA_LIST,
    payload: { [type]: data },
  };
}

export const resetNewsList = (): ResetNewsList => ({ type: NewsActionTypes.RESET_NEWS_LIST });

export const setGallery = (gallery: LocalizedGalleryItem): SetGallery => ({
  type: NewsActionTypes.SET_GALLERY,
  payload: gallery,
});

export const resetGallery = (): ResetGallery => ({ type: NewsActionTypes.RESET_GALLERY });

export const setLatestNews = (latestNews: BaseNewsItem[]): SetLatestNews => ({
  type: NewsActionTypes.SET_LATEST_NEWS,
  payload: latestNews,
});

export const setLatestVideo = (latestNews: BaseNewsItem[]): SetLatestVideo => ({
  type: NewsActionTypes.SET_LATEST_VIDEO,
  payload: latestNews,
});

export type ResetLatestNewsAction = () => (ResetLatestNews | ResetLatestGallery | ResetLatestVideo)

export const resetLatestNews = (): ResetLatestNews => ({ type: NewsActionTypes.RESET_LATEST_NEWS });

export const resetLatestVideo = (): ResetLatestVideo => ({ type: NewsActionTypes.RESET_LATEST_VIDEO });

export const setLatestGallery = (latestGallery: BaseNewsItem[]): SetLatestGallery => ({
  type: NewsActionTypes.SET_LATEST_GALLERY,
  payload: latestGallery,
});

export const resetLatestGallery = (): ResetLatestGallery => ({ type: NewsActionTypes.RESET_LATEST_GALLERY });

export const setNews = (gallery: LocalizedNewsItem): SetNews => ({
  type: NewsActionTypes.SET_NEWS,
  payload: gallery,
});

export const resetNews = (): ResetNews => ({ type: NewsActionTypes.RESET_NEWS });

export const setVideo = (video: LocalizedVideoItem): SetVideo => ({
  type: NewsActionTypes.SET_VIDEO,
  payload: video,
});
export const resetVideo = (): ResetVideo => ({ type: NewsActionTypes.RESET_VIDEO });

export const setError = (error: PageError): SetError => ({
  type: NewsActionTypes.SET_ERROR,
  payload: error,
});

interface GetNewsByType {
  type: EditorialContentTypes;
  category?: string;
  language: LanguageType;
  limit?: number;
  skip?: number;
  thirdLevel?: string;
  withTopNews?: boolean;
}

const getNews = ({
  type,
  category,
  limit = 0,
  language,
  thirdLevel = '',
  withTopNews,
}: GetNewsByType): ActionWithPromiseReturn<NewsList> => (
  async (dispatch, getState): Promise<NewsList> => {
    await waitForConfigurationDownload();

    const state: AppState = getState();
    const layout = state.configuration[language]?.layout?.[type] ?? 'layout_3x3';
    const numberDownloadedNews = state.news.newsList.items.length;
    // eslint-disable-next-line camelcase
    const newsConfiguration = state.configuration[language]?.menu?.web_news?.navigation[NewsNavigationTypeMap[type]];
    const categories = newsConfiguration?.categories || [];

    return getNewsListService({
      type,
      limit: limit
        || (LayoutTypeToItemsForDownload[layout] - (withTopNews ? 0 : 1))
        || ApplicationConfig.news.defaultNewsLimit,
      skip: numberDownloadedNews,
      category: category ?? findCategoryMenuItemBySlug(categories, thirdLevel)?.editorialCategory,
      language,
    });
  }
);

export interface GetNews {
  (data: {
    language: LanguageType;
    topLevel: string;
    secondLevel: string;
    thirdLevel: string;
    type: EditorialContentTypes;
    withTopNews?: boolean;
  }): ActionWithPromiseReturn;
}

export const getNewsList: GetNews = ({
  topLevel, secondLevel, thirdLevel, language, type, withTopNews,
}) => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();
    const state = getState();
    const thirdLevelMenu = getThirdLevelMenuItem(state, topLevel, secondLevel, thirdLevel, language)?.[language];

    if (!thirdLevelMenu) {
      dispatch(setError(PageError.NotFound));
      return;
    }

    dispatch(setNewsLoadingState(true));

    try {
      const news = await dispatch(getNews({
        type,
        thirdLevel,
        language,
        withTopNews,
      }));
      dispatch(setNewsList(news));
    } catch (e) {
      console.error('Error on fetching articles', e);
      dispatch(setError(PageError.Sorry));
    } finally {
      dispatch(setNewsLoadingState(false));
    }
  }
);

export interface GetData {
  (data: {
    language: LanguageType;
    type: EditorialContentTypes;
    category: string;
    limit?: number;
  }): ActionWithPromiseReturn;
}

export const getDataList: GetData = ({ language, type, limit = 5, category }) => (
  async (dispatch): Promise<void> => {
    await waitForConfigurationDownload();

    try {
      const news = await dispatch(getNews({
        type,
        language,
        category,
        limit,
      }));
      dispatch(setDataList(type, { [category]: news }));
    } catch (e) {
      console.error('Error on fetching articles', e);
      dispatch(setError(PageError.Sorry));
    }
  }
);

interface GetNewsByUrlSlug extends RouteParams {
  type: EditorialContentTypes;
}

export const getNewsByUrlSlug = ({
  topLevel, secondLevel, thirdLevel = '', forthLevel = '', fifthLevel, language, type,
}: GetNewsByUrlSlug): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    try {
      dispatch(setNewsLoadingState(true));
      const currentLangNews = await getNewsDetails({
        publicationDate: forthLevel,
        urlSlug: fifthLevel,
        language,
        type,
      });

      if (!currentLangNews) {
        dispatch(setError(PageError.NotFound));
        return;
      }

      await waitForConfigurationDownload();
      const state = getState();
      const category = getThirdLevelMenuItem(state, topLevel, secondLevel, thirdLevel, language)?.[language];
      const isIncorrectThirdLevelUrl = category?.editorialCategory !== currentLangNews?.categoryCode
        && thirdLevel !== Translations[language]['news.landingThirdLevelUrl'];

      if (isIncorrectThirdLevelUrl) {
        dispatch(setError(PageError.NotFound));
        return;
      }

      switch (type) {
        case EditorialContentTypes.NEWS:
          dispatch(setNews({
            [language]: currentLangNews,
          }));
          break;
        case EditorialContentTypes.PHOTO:
          dispatch(setGallery({
            [language]: currentLangNews,
          }));
          break;
        default:
          dispatch(setVideo({
            [language]: currentLangNews,
          }));
      }

      const restLanguages = pull([...AppLanguages], language);

      const news = await Promise.all(restLanguages.map((language) => (
        getNewsDetails({
          publicationDate: forthLevel,
          id: currentLangNews.id,
          language,
          type,
        })
      )));

      const langToConfigMap = restLanguages.reduce((acc, lang, index) => {
        acc[lang] = news[index];
        return acc;
      }, {} as (LocalizedNewsItem | LocalizedGalleryItem | LocalizedVideoItem));

      switch (type) {
        case EditorialContentTypes.NEWS:
          dispatch(setNews(langToConfigMap as LocalizedNewsItem));
          break;
        case EditorialContentTypes.PHOTO:
          dispatch(setGallery(langToConfigMap as LocalizedGalleryItem));
          break;
        default:
          dispatch(setVideo(langToConfigMap as LocalizedVideoItem));
      }
    } catch (e) {
      console.error('Error on fetching news ', e);
      dispatch(setError(PageError.Sorry));
    } finally {
      dispatch(setNewsLoadingState(false));
    }
  }
);

type GetLatestNewsDetails = (params: {
  codeName?: string;
  language: LanguageType;
  type: EditorialContentTypes;
}) => ActionWithPromiseReturn<BaseNewsItem[]>

const getLatestNewsList: GetLatestNewsDetails = ({ codeName, language, type }) => (
  async (dispatch): Promise<BaseNewsItem[]> => {
    const limit = codeName
      ? ApplicationConfig.news.latestNewsLimit[type]
      : ApplicationConfig.home.latestNewsLimit[type];

    const news = await dispatch(getNews({
      type,
      limit,
      language,
    }));

    news.items = news.items
      .filter((item) => item.codeName !== codeName)
      .slice(0, 10);

    return news.items;
  }
);

export type GetLatestNews = (params: {
  codeName?: string;
  language: LanguageType;
}) => Action

export const getLatestNews: GetLatestNews = (params) => (
  async (dispatch): Promise<void> => {
    try {
      const news = await dispatch(getLatestNewsList({
        ...params,
        type: EditorialContentTypes.NEWS,
      }));

      dispatch(setLatestNews(news));
    } catch (e) {
      console.error('Error on fetching latest news: ', e);
    }
  }
);

export const getLatestVideo: GetLatestNews = (params) => (
  async (dispatch): Promise<void> => {
    try {
      const video = await dispatch(getLatestNewsList({
        ...params,
        type: EditorialContentTypes.VIDEO,
      }));

      dispatch(setLatestVideo(video));
    } catch (e) {
      console.error('Error on fetching latest news: ', e);
    }
  }
);

export const getLatestGallery: GetLatestNews = (params) => (
  async (dispatch): Promise<void> => {
    try {
      const gallery = await dispatch(getLatestNewsList({
        ...params,
        type: EditorialContentTypes.PHOTO,
      }));

      dispatch(setLatestGallery(gallery));
    } catch (e) {
      console.error('Error on fetching latest gallery: ', e);
    }
  }
);
