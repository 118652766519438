import React, { useState } from 'react';
import styled from 'styled-components';

import { MenuItem as MenuItemInterface } from '@app/types/configurationTypes';

import { isMenuItemVisible } from '@app/helpers/menuHelpers';
import { MenuItemTitle, SubMenuItem } from '@app/components';
import { useIsConnected } from '@app/components/Inviola/InviolaHooks';

interface MenuItemProps {
  item: MenuItemInterface;
  isHeader?: boolean;
}

const MenuItem = ({ item, isHeader }: MenuItemProps): React.ReactElement => {
  const [isOpened, setIsOpened] = useState(false);
  const isConnected = useIsConnected();
  const subItems = Object.values(item.navigation).filter(({ data }) => isMenuItemVisible(data, isConnected));
  // If (first SecondLevel item is ExternalLink) { make TopLevel item the same ExternalLink }

  const openMenu = (): void => { subItems.length && setIsOpened(true); };
  const closeMenu = (): void => setIsOpened(false);

  return (
    <StyledMenuItem>
      <div
        onMouseOver={openMenu}
        onFocus={openMenu}
        onMouseOut={closeMenu}
        onBlur={closeMenu}
      >
        <MenuItemTitle onClick={closeMenu} item={item} isHeader={isHeader} />
        <SubMenu isOpened={isOpened}>
          <SubMenuList>
            {
              subItems.map((subItem) => (
                <SubMenuItem subItem={subItem} item={item} onClick={closeMenu} key={subItem.data.id} />
              ))
            }
          </SubMenuList>
        </SubMenu>
      </div>
    </StyledMenuItem>
  );
};

export default MenuItem;

const StyledMenuItem = styled.li`
  --menu-item-left-padding: 15px;
  text-transform: uppercase;
  height: 100%;
  display: inline-block;
  align-items: center;
  position: relative;
  cursor: pointer;

  & > div {
    height: 100%;
  }

  &:hover {
    background-color: rgba(90, 50, 140, 0.95);
  }
`;

const SubMenu = styled.div<{ isOpened: boolean }>`
  position: absolute;
  background-color: rgba(90, 50, 140, 0.95);
  font-size: 12px;
  visibility: ${({ isOpened }): string => (isOpened ? 'visible' : 'hidden')};
  right: 0;
  top: 75px;
  z-index: 99;
`;

const SubMenuList = styled.ul`
  padding: 20px 20px 4px 35px;
  min-width: 160px;
`;
