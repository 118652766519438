// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import algoliasearch from 'algoliasearch/lite';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { PageError, Errors } from '@app/types/errorTypes';
import { AlgoliaSearchResult, AlgoliaSearchResultHit } from '@app/types/newsTypes';

import {
  useSearchConfig, useSearchParams, useSearchFilters, useSearchIndex, useSearchMultiLangUrl,
} from '@app/components/Header/components/SearchBar/SearchBarHooks';

import { PageWrapper } from '@app/pages/components';
import { Loader } from '@app/components';

import { Categories, TotalResults, ResultsList, LoadMoreButton } from './components';

const Search = (): React.ReactElement => {
  const { query, category } = useSearchParams();
  const { appId, apiKey } = useSearchConfig();
  const filters = useSearchFilters();
  const index = useSearchIndex();

  const searchClient = algoliasearch(appId, apiKey);
  const searchIndex = searchClient.initIndex(index);

  const [hitsList, setHitsList] = useState<AlgoliaSearchResultHit[] | undefined | null>(null);
  const [results, setResults] = useState<AlgoliaSearchResult | undefined | null>(null);
  const [pageError, setPageError] = useState<Errors>(null);

  const isLoading = Boolean(!results);
  const hasResults = Array.isArray(hitsList) && hitsList.length > 0 && !isLoading;
  const hasNoResults = Array.isArray(hitsList) && hitsList.length === 0 && !isLoading;

  const onLoadMoreButtonClick = (): void => {
    searchIndex
      .search(query, {
        page: results ? results.page + 1 : 1,
        hitsPerPage: 12,
        filters,
      })
      .then((response) => {
        setResults(response);
        setHitsList(hitsList?.concat(response?.hits));
      })
      .catch(() => setPageError(PageError.Sorry));
  };

  const onSearchQueryChange = (): void => {
    setResults(null);
    setHitsList(null);

    searchIndex
      .search(query, {
        page: 0,
        hitsPerPage: 12,
        filters,
      })
      .then((response) => {
        setResults(response);
        setHitsList(response?.hits);
      })
      .catch(() => setPageError(PageError.Sorry));
  };

  useEffect(() => {
    onSearchQueryChange();
  }, [query, category]);

  useSearchMultiLangUrl();

  return (
    <PageWrapper error={pageError}>
      <Container isLoading={isLoading}>
        <Content>
          <Heading>
            <TotalResults total={results?.nbHits} />
            <Categories />
          </Heading>
          {isLoading && (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
          {hasResults && (
            <>
              <ResultsList hits={hitsList} />
              <LoadMoreButton
                onClick={onLoadMoreButtonClick}
                isVisible={results && (results.page + 1 < results.nbPages)}
                isLoading={false}
              />
            </>
          )}
          {hasNoResults && (
            <NoResults>
              <FormattedMessage id="news.search.results.message" />
            </NoResults>
          )}
        </Content>
      </Container>
    </PageWrapper>
  );
};

export default Search;

const Container = styled.div<{ isLoading: boolean }>`
  opacity: ${({ isLoading }): number => (isLoading ? 0.33 : 1)};
  cursor: ${({ isLoading }): string => (isLoading ? 'wait' : 'unset')};
  width: 100%;
  height: 100%;
  max-width: 1280px;
  padding: 70px 20px 20px;
  margin: auto;

  @media ${Devices.desktopSmall} {
    padding: 70px 70px 20px;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 9999;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;

const NoResults = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  line-height: 1.47;
  letter-spacing: 0.17px;
  padding: 100px 0;
  color: #232323;;
`;
