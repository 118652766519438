import { FeedTypes } from '@app/services/opta/constants/feedTypes';
import { sendRequestTyped } from '@app/services/opta/request';
import { mapStandings, mapSDStandings } from '@app/helpers/seasonHelpers';
import { StandingsItem } from '@app/types/standingsTypes';
import { OptaStandingsResponse, OptaSDStandingsResponse } from '@app/services/opta/types/responseTypes';

export const getStandingsData = (seasonId: string, optaId: string): Promise<StandingsItem> => (
  sendRequestTyped<OptaStandingsResponse>({
    path: 'competition.php',
    params: {
      feed_type: FeedTypes.standings,
      json: true,
      season_id: seasonId,
      competition: optaId,
    },
  }).then(mapStandings)
);

export const getSDStandingsData = (seasonId: string): Promise<StandingsItem> => (
  sendRequestTyped<OptaSDStandingsResponse>({
    path: 'soccerdata/standings',
    isOptaSD: true,
    params: {
      _rt: 'b',
      _fmt: 'json',
      live: 'yes',
      type: 'total',
      tmcl: seasonId,
    },
  }).then(mapSDStandings)
);
