import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { isTeamFiorentina } from '@app/helpers/teamsHelpers';
import {
  useWidgetStandingSelector, useWidgetStandingsDownload,
} from '@app/pages/Season/Standings/StandingsHooks';
import {
  Logo, Table, TableDataName, TableDataPosition, TableDataStats, TableHead,
  TableHeadPosition, TableHeadRow, TableBodyRow, TableThead, TeamNameContainer, TableDataColor,
} from '@app/pages/Season/Standings/Standings';

const Standings = (): React.ReactElement => {
  const standings = useWidgetStandingSelector();
  useWidgetStandingsDownload();

  return (
    <Container>
      <Table>
        <TableThead>
          <WidgetTableHeadRow>
            <WidgetTableHead />
            <WidgetTableHeadPosition><FormattedMessage id="standings.position" /></WidgetTableHeadPosition>
            <WidgetTableHead />
            <WidgetTableHead><FormattedMessage id="standings.points" /></WidgetTableHead>
            <WidgetTableHead><FormattedMessage id="standings.played" /></WidgetTableHead>
            <WidgetTableHead><FormattedMessage id="standings.wons" /></WidgetTableHead>
            <WidgetTableHead><FormattedMessage id="standings.drawn" /></WidgetTableHead>
            <WidgetTableHead><FormattedMessage id="standings.lost" /></WidgetTableHead>
            <WidgetTableHead><FormattedMessage id="standings.goalsDiff" /></WidgetTableHead>
          </WidgetTableHeadRow>
        </TableThead>
        <tbody>
          {standings.map((team) => (
            <WidgetTableBodyRow key={team.id} isFiorentina={isTeamFiorentina(team.id)}>
              <TableDataColor isFiorentina={isTeamFiorentina(team.id)} index={team.position} />
              <WidgetTableDataPosition>{team.position}</WidgetTableDataPosition>
              <WidgetTableDataName>
                <TeamNameContainer>
                  <Logo src={team.logoUrl} alt={team.teamName} />
                  {team.teamName}
                </TeamNameContainer>
              </WidgetTableDataName>
              <WidgetTableDataStats>{team.points}</WidgetTableDataStats>
              <WidgetTableDataStats>{team.played}</WidgetTableDataStats>
              <WidgetTableDataStats>{team.wons}</WidgetTableDataStats>
              <WidgetTableDataStats>{team.drawn}</WidgetTableDataStats>
              <WidgetTableDataStats>{team.lost}</WidgetTableDataStats>
              <WidgetTableDataStats>{team.goalsDiff}</WidgetTableDataStats>
            </WidgetTableBodyRow>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Standings;

const Container = styled.section`
  padding: 0;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 380px;
`;

const WidgetTableHead = styled(TableHead)`
  display: table-cell !important;
`;

const WidgetTableHeadRow = styled(TableHeadRow)`
  height: 55px;
`;

const WidgetTableHeadPosition = styled(TableHeadPosition)`
  max-width: 15px;
  overflow: visible;

  @media ${Devices.tabletLarge} {
    padding-left: 10px;
  }
`;

const WidgetTableBodyRow = styled(TableBodyRow)`
  & > td {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

const WidgetTableDataPosition = styled(TableDataPosition)`
  @media ${Devices.tabletLarge} {
    padding-left: 10px;
  }
`;

const WidgetTableDataName = styled(TableDataName)`
  @media ${Devices.tablet} {
    padding-left: 10px;
  }
`;

const WidgetTableDataStats = styled(TableDataStats)`
  display: table-cell !important;
`;
