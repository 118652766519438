import format from 'date-fns/format';
import { utcToZonedTime } from 'date-fns-tz';
import {
  Elements, IContentItemsContainer, ItemResponses,
} from '@kentico/kontent-delivery';

import News from '@app/services/kentico/types/news';
import KenticoVideo from '@app/services/kentico/types/video';
import ThronAsset from '@app/services/kentico/types/thron_asset';
import PhotoGallery from '@app/services/kentico/types/photo_gallery';
import { LanguageType } from '@app/types/localizationTypes';
import {
  BaseNewsData,
  BaseNewsItem,
  EditorialContentTypes,
  Gallery,
  NewsContentItem,
  NewsContentType,
  NewsDetails,
  NewsList,
  ThroneContentItem,
  Video,
} from '@app/types/newsTypes';
import { ThronAssetTypes } from '@app/types/configurationTypes';
import { AppLanguagesMap } from '@app/constants/localizationConstants';

import { WebTemplatePageImage, WebTemplateTypes } from '@app/types/webTemplateTypes';
import {
  convertWebAdvertisementBanner,
  convertWebPageImage,
} from '@app/services/kentico/converters/webTemplateConverter';
import { WebPageImage } from '@app/services/kentico/types/web_page_image';
import { buildThronAsset } from '@app/helpers/thronHelper';
import { mapSeoData, mapThronData, mapThronList } from './configurationConverters';

export const getBaseNewsData = (data: News | KenticoVideo | PhotoGallery): BaseNewsData => ({
  urlSlug: data?.url?.value ?? '',
  publicationDate: format(
    utcToZonedTime(data?.publicationDate?.value ?? 0, 'Europe/London'), 'yyyy-MM-dd',
  ),
  title: data?.title?.value ?? '',
  subtitle: data?.subtitle?.value ?? '',
  categoryName: (data?.editorialCategory.value?.[0]?.name ?? '').toLowerCase(),
  categoryCode: (data?.editorialCategory?.value?.[0]?.codename ?? '').toLowerCase(),
  codeName: data?.system?.codename ?? '',
  id: data?.system?.id ?? '',
  type: data?.system?.type as unknown as EditorialContentTypes,
  isPremium: data?.premium?.value?.[0]?.codename === 'premium',
});

export const mapBaseNewsItem = (item: News | KenticoVideo | PhotoGallery): BaseNewsItem => ({
  ...getBaseNewsData(item),
  image: mapThronData(item?.mainImage),
  imageVertical: mapThronData(item?.mainImageVertical),
  photosNumber: mapThronList(item?.thronImageGalleryThron)?.length ?? 0,
});

export const mapNewsItems = (
  newsData: ItemResponses.ListContentItemsResponse<News | KenticoVideo | PhotoGallery>,
): NewsList => ({
  hasMoreData: Boolean(newsData?.pagination?.nextPage) ?? false,
  items: (newsData?.items ?? []).map((item) => mapBaseNewsItem(item)),
});

export const mapGallery = (data: PhotoGallery, language: LanguageType): Gallery => ({
  ...getBaseNewsData(data),
  photos: mapThronList(data?.thronImageGalleryThron),
  ...mapSeoData(data, language, true),
});

export const mapVideo = (data: KenticoVideo, language: LanguageType): Video => ({
  ...getBaseNewsData(data),
  image: mapThronData(data?.mainImage),
  video: mapThronData(data?.thronVideoThron),
  isLive: !!data?.live?.value?.length,
  ...mapSeoData(data, language, true),
});

export const mapVideoNoSeo = (data: KenticoVideo): Video => ({
  ...getBaseNewsData(data),
  image: mapThronData(data?.mainImage),
  video: mapThronData(data?.thronVideoThron),
  isLive: !!data?.live?.value?.length,
  ...mapSeoData(data, AppLanguagesMap.en, true),
});

const getThronEditorialContentItem = (item: Elements.CustomElement): ThroneContentItem => {
  // eslint-disable-next-line camelcase
  const data = mapThronList(item);

  if (data.length > 1) {
    return {
      type: NewsContentType.GALLERY,
      value: data,
    };
  }

  const asset = data?.[0];

  if (asset?.type === ThronAssetTypes.VIDEO) {
    return {
      type: NewsContentType.VIDEO,
      value: asset,
    };
  }

  return {
    type: NewsContentType.IMAGE,
    value: asset,
  };
};

export type linkedItems = News | ThronAsset | KenticoVideo | PhotoGallery | WebPageImage

type MapEditorialContent = (
  content: string,
  linkedItems: IContentItemsContainer<linkedItems>
) => (NewsContentItem | null)[]

export const mapEditorialContent: MapEditorialContent = (content, linkedItems) => (
  content
    .split(/(<\s*object[^>]*>(.*?)<\s*\/\s*object>)/)
    .filter((content) => content) // We filter here content which is empty string
    .map((content) => {
      const codeName = /data-codename="(.*?)"/g.exec(content)?.[1];

      if (!codeName) {
        return {
          type: NewsContentType.TEXT,
          value: content,
        };
      }

      const item = linkedItems[codeName];
      if (!item) return null;

      if (item.system.type === EditorialContentTypes.NEWS) {
        return {
          type: NewsContentType.NEWS,
          value: mapBaseNewsItem(item as News),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          isPremium: item?.premium?.value?.[0]?.codename === 'premium',
        };
      }

      if (item.system.type === EditorialContentTypes.VIDEO) {
        return {
          type: NewsContentType.VIDEO,
          value: mapThronData((item as KenticoVideo).thronVideoThron),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          isPremium: item?.premium?.value?.[0]?.codename === 'premium',
        };
      }

      if (item.system.type === EditorialContentTypes.PHOTO) {
        return {
          type: NewsContentType.GALLERY,
          value: mapThronList((item as PhotoGallery).thronImageGalleryThron),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          isPremium: item?.premium?.value?.[0]?.codename === 'premium',
        };
      }

      if (item.system.type === 'thron_asset') {
        // eslint-disable-next-line camelcase,@typescript-eslint/no-unsafe-member-access
        return getThronEditorialContentItem(item?.custom_element?.rawData);
      }

      if (item.system.type === 'embedded') {
        return {
          type: NewsContentType.TEXT,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          value: encodeURI(item?.html?.value ?? ''),
        };
      }

      if (item.system.type === WebTemplateTypes.WebPageImage) {
        const webPageImage = convertWebPageImage(item, item, linkedItems) as WebTemplatePageImage;
        return {
          type: NewsContentType.IMAGE,
          value: buildThronAsset(webPageImage.image),
        };
      }

      if (item.system.type === WebTemplateTypes.WebAdvertisementBanner) {
        return {
          type: NewsContentType.BANNER,
          value: convertWebAdvertisementBanner(item, item),
        };
      }

      return null;
    })
);

type MapNewsDetails = (
  data: ItemResponses.ListContentItemsResponse<News>,
  language: LanguageType,
) => NewsDetails

export const mapNewsDetails: MapNewsDetails = (data, language) => {
  const item = data.items[0];

  return ({
    ...getBaseNewsData(item),
    ...mapSeoData(item, language, true),
    topElement: getThronEditorialContentItem(item?.topElement),
    content: mapEditorialContent(item?.content?.value ?? '', data?.linkedItems as IContentItemsContainer<linkedItems>),
  });
};
