import { MatchStatus, MatchPeriod, MatchHalfPeriods, MatchHalfTimePeriods } from '@app/types/matchTypes';

export const MatchStatusMap: {
  [key in MatchStatus]: MatchStatus;
} = {
  live: 'live',
  finished: 'finished',
  upcoming: 'upcoming',
};

export const MatchPeriodsStatusMap: {
  [key in MatchPeriod]: MatchStatus;
} = {
  fulltime: 'finished',
  full: 'finished',
  played: 'finished',
  ft: 'finished',
  postmatch: 'finished',
  result: 'finished',
  live: 'live',
  halftime: 'live',
  extrahalftime: 'live',
  playing: 'live',
  firsthalf: 'live',
  secondhalf: 'live',
  extrafirsthalf: 'live',
  firstextrahalf: 'live',
  extrasecondhalf: 'live',
  secondextrahalf: 'live',
  shootout: 'live',
  prematch: 'upcoming',
  fixture: 'upcoming',
  postponed: 'upcoming',
  abandoned: 'upcoming',
  cancelled: 'upcoming',
};

export const MatchHalfPeriodsMap: {
  [key in MatchHalfPeriods]: MatchHalfPeriods;
} = {
  firsthalf: 'firsthalf',
  secondhalf: 'secondhalf',
  extrafirsthalf: 'extrafirsthalf',
  firstextrahalf: 'firstextrahalf',
  extrasecondhalf: 'extrasecondhalf',
  secondextrahalf: 'secondextrahalf',
};

export const MatchHalfTimePeriodsMap: {
  [key in MatchHalfTimePeriods]: MatchHalfTimePeriods;
} = {
  halftime: 'halftime',
  extrahalftime: 'extrahalftime',
};
