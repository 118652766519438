import React from 'react';
import { Route } from 'react-router-dom';

interface HttpStatusProps {
  status: number;
}

const HttpStatus = ({ status, children }: React.PropsWithChildren<HttpStatusProps>): React.ReactElement => (
  <Route
    render={({ staticContext }): React.ReactNode => {
      if (staticContext) staticContext.statusCode = status;
      return children;
    }}
  />
);

export default HttpStatus;
