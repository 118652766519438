import React from 'react';

import { WebTemplateData, WebTemplateTypes } from '@app/types/webTemplateTypes';
import { WebAdvertisementBannerElement, WebMatchbox } from '@app/components/WebTemplates/elements';
import {
  WebHomeCarouselElement, WebHomeElementSelect,
} from './elements';

interface WebTemplateSelectProps {
  data: WebTemplateData;
}

const HomeSelect = ({ data }: WebTemplateSelectProps): React.ReactElement | null => {
  switch (data?.type) {
    case WebTemplateTypes.WebCarousel:
      return (<WebHomeCarouselElement data={data} />);
    case WebTemplateTypes.WebHomeElement:
      return (<WebHomeElementSelect data={data} />);
    case WebTemplateTypes.WebMatchbox:
      return (<WebMatchbox data={data} />);
    case WebTemplateTypes.WebAdvertisementBanner:
      return (<WebAdvertisementBannerElement data={data} />);
    default:
      return null;
  }
};

export default HomeSelect;
