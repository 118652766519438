import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import AppRoutes from '@app/constants/routesConstants';
import { ImageWidthMap } from '@app/types/imageTypes';
import { Promotion } from '@app/types/promotionsTypes';
import { Background, NewsInfoContainer, NewsItemLinkContainer } from '@app/components/News/NewsItems';
import { usePromotionUrlParams } from '@app/pages/Inviola/PromotionsListPage/PromotionsListPageHooks';
import { NewsContentType } from '@app/types/newsTypes';
import { ThronImage } from '../Image';

interface PromotionItemProps {
  data: Promotion;
  onClick?: (item: Promotion) => void;
  imageWidthMap?: ImageWidthMap;
  home?: boolean;
  infoSize?: 'large' | 'medium' | 'small';
  shiftInfo?: boolean;
  current?: boolean;
}

export const InviolaPromotionItem: React.FC<PromotionItemProps> = ({
  data,
  onClick,
  imageWidthMap = { 0: { width: 450, height: 450 } },
}) => {
  const {
    thumbnail, title, content,
  } = data;
  const linksParams = usePromotionUrlParams(data);

  return (
    <NewsItemLinkContainer
      pathKey={AppRoutes.CommonPage.path}
      pathParams={linksParams}
      onClick={(): void => { onClick && onClick(data); }}
    >
      <PromotionItemContainer>
        <Background />
        {thumbnail && (
        <ThronImage
          thronId={thumbnail?.thronId}
          alt={thumbnail?.name}
          imageWidthMap={imageWidthMap}
          height={305}
          width={550}
          scaleMode="auto"
          useLazyLoading
        />
        )}
        <PromotionInfoContainer>
          <Title>
            { title }
          </Title>
          <Description>
            {content.filter((item) => item?.type === NewsContentType.TEXT).map((item) => (
              // eslint-disable-next-line react/no-danger
              <p dangerouslySetInnerHTML={{ __html: item?.value as string }} />
            ))}
          </Description>
        </PromotionInfoContainer>
      </PromotionItemContainer>
    </NewsItemLinkContainer>
  );
};

const PromotionInfoContainer = styled(NewsInfoContainer)`
  width: 100%;
`;

const PromotionItemContainer = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 50px; // required for correct lazy-load on tablets and desktop
  height: 100%;
  border-radius: 10px;
  color: white;
`;

const Title = styled.div`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.3;
  color: #ffffff;
  text-transform: uppercase;

  @media ${Devices.tablet} {
    font-size: 20px;
  }
`;

const Description = styled.div`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  color: #ffffff;
  max-height: 3em;
  overflow: hidden;

  @media ${Devices.tablet} {
    font-size: 16px;
    line-height: 1.25;
    max-height: 5em;
  }
`;
