import { shallowEqual, useSelector } from 'react-redux';

import { ShareIcons } from '@app/types/configurationTypes';
import { useLanguage } from '@app/components/Hooks';
import AppState from '@app/types/appStateTypes';

export const useShareIcons = (): ShareIcons => {
  const language = useLanguage();

  return useSelector<AppState, ShareIcons>(
    (state) => state.configuration[language]?.shareIcons ?? null,
    shallowEqual,
  );
};
