import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AppState } from '@app/types/appStateTypes';
import { CurrentRouteState } from '@app/store/reducers/currentRouteReducer';
import { useLanguage } from './LocalizationHooks';

export const useCurrentRoute = (): CurrentRouteState => (
  useSelector<AppState, CurrentRouteState>(
    (state) => state.currentRoute,
    shallowEqual,
  )
);

export const useIsTheSamePage = (): boolean => {
  const currentRoute = useCurrentRoute();
  const { pathname } = useLocation();
  const language = useLanguage();

  return currentRoute[language] === pathname;
};
