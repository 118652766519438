import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { AppState } from '@app/types/appStateTypes';
import { RouteParams } from '@app/types/routerTypes';
import { useLanguage, useIsTheSamePage, useError } from '@app/components/Hooks';

import { MountContext } from '@app/ReactContext';
import { LocalizedPromotionItem, Promotion } from '@app/types/promotionsTypes';
import { getPromotionByUrlSlug, resetPromotion } from '@app/store/actions/promotionsActions';
import { Errors, PageError } from '@app/types/errorTypes';
import { usePromotionsLoadingState } from '@app/pages/Inviola/PromotionsListPage/PromotionsListPageHooks';
import { LanguageType } from '@app/types/localizationTypes';
import { CommonPageBaseUrlParams } from '@app/helpers/routeHelpers';
import { useCommonPageBaseMultiLangRoute } from '@app/pages/CommonPage/CommonPageHooks';
import { AppLanguages } from '@app/constants/localizationConstants';

export const usePromotion = (): Promotion | null => {
  const language = useLanguage();

  return useSelector<AppState, Promotion | null>(
    (state) => state.promotions.selected?.item?.[language] ?? null,
    isEqual,
  );
};

export const useDownloadPromotion = (): void => {
  const promotion = usePromotion();
  const isTheSamePromotion = useIsTheSamePage();
  const dispatch = useDispatch();
  const params: RouteParams = useParams();
  const language = useLanguage();
  const { isInitialMount } = useContext(MountContext);

  useEffect(() => {
    if (!isInitialMount && (!promotion || !isTheSamePromotion)) {
      dispatch(resetPromotion());
      dispatch(getPromotionByUrlSlug({
        ...params,
        language,
      }));
    }
  }, [params.forthLevel]);
};

export const usePromotionsDetailsPageValidation = (promotion: Promotion | null): Errors => {
  const error = useError();
  const isLoading = usePromotionsLoadingState();

  if (error) {
    return error;
  }

  return !isLoading && !promotion ? PageError.NotFound : null;
};

export const useInviolaPromotionsDetailsMultiLangRoute = (): void => {
  const promotions = useSelector<AppState, LocalizedPromotionItem>(
    (state) => state.promotions.selected?.item ?? {},
    isEqual,
  );

  const thirdLevelParams:Partial<Record<LanguageType, string>> = promotions
    ? AppLanguages.reduce((accumulator, entry) => {
      accumulator[entry] = promotions[entry]?.publicationDate;
      return accumulator;
    }, {} as Partial<Record<LanguageType, string>>) : {};
  const fourthLevelParams:Partial<Record<LanguageType, string>> = promotions
    ? AppLanguages.reduce((accumulator, entry) => {
      accumulator[entry] = promotions[entry]?.urlSlug;
      return accumulator;
    }, {} as Partial<Record<LanguageType, string>>) : {};

  const initialParams: CommonPageBaseUrlParams = {
    thirdLevel: thirdLevelParams,
    forthLevel: fourthLevelParams,
  } as CommonPageBaseUrlParams;

  useCommonPageBaseMultiLangRoute(initialParams);
};
