import React from 'react';
import styled from 'styled-components';

import {
  WebTemplateAdvertisementBanner,
} from '@app/types/webTemplateTypes';
import { Devices } from '@app/styles';
import { ContainerMedium } from '@app/components/WebTemplates/components';

interface WebAdvertisementBannerElementProps {
  data: WebTemplateAdvertisementBanner;
  isMediumSize?: boolean;
}

const WebAdvertisementBannerElement: React.FC<WebAdvertisementBannerElementProps> = (
  { data, isMediumSize = false }: WebAdvertisementBannerElementProps,
) => {
  const ContainerWrapper = isMediumSize ? Container : ContainerMedium;

  return (
    <Wrapper>
      <ContainerWrapper>
        <a href={data?.url} target="_blank" rel="noreferrer">
          <DesktopImage
            isMediumSize={isMediumSize}
            src={data?.desktopBannerImage?.url}
            alt={data?.desktopBannerImage?.description}
          />
          <MobileImage
            isMediumSize={isMediumSize}
            src={data?.mobileBannerImage?.url ?? data?.desktopBannerImage?.url}
            alt={data?.mobileBannerImage?.description ?? data.desktopBannerImage?.description}
          />
        </a>
      </ContainerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: transparent;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 10px;

  & > img {
    width: 100%;
  }
`;

const DesktopImage = styled.img<{ isMediumSize: boolean }>`
  display: none;
  margin: ${({ isMediumSize }): string => (isMediumSize ? '20px auto' : '80px auto')};
  max-width: 100%;
  max-height: 250px;

  @media ${Devices.desktop} {
    display: block;
  }
`;

const MobileImage = styled.img<{ isMediumSize: boolean }>`
  display: block;
  margin: ${({ isMediumSize }): string => (isMediumSize ? '0 auto' : '30px auto 45px')};
  max-width: 100%;
  max-height: 250px;
  padding: ${({ isMediumSize }): string => (isMediumSize ? '0' : '0 20px')};

  @media ${Devices.desktop} {
    display: none;
  }
`;

export default WebAdvertisementBannerElement;
