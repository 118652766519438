import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { BaseNewsItem } from '@app/types/newsTypes';
import { Errors } from '@app/types/errorTypes';

import { Devices } from '@app/styles';

import { Modal, SocialSharing } from '@app/components';
import { PremiumAccessNotification } from '@app/components/Inviola';
import { PageWrapper } from '@app/pages/components';
import { CarouselLatestNews } from '@app/components/News';
import { useLanguage, useNewsLoadingState } from '@app/components/Hooks';
import { useIsConnected } from '@app/components/Inviola/InviolaHooks';

type NewsDetailsPageWrapper = React.FC<{
  error: Errors;
  isPremium?: boolean;
  redirectPath: string | null;
  latestNewsHeaderLabelId: string;
  latestNews: BaseNewsItem[];
  headerLinksParams: Record<string, string>;
}>;

const NewsDetailsPageWrapper: NewsDetailsPageWrapper = ({
  error,
  latestNewsHeaderLabelId,
  children,
  latestNews,
  headerLinksParams,
  isPremium = false,
  redirectPath,
}) => {
  const history = useHistory();
  const language = useLanguage();
  const isLoading = useNewsLoadingState();
  const isConnected = useIsConnected();
  const isPrivate = isPremium && !isConnected;

  const onClosePremiumModal = (): void => history.push(`/${language}`);

  return (
    <PageWrapper error={error} isLoading={isLoading}>
      <Container>
        <InnerContainer isPrivate={isPrivate}>
          {children}
          <SocialSharingContainer>
            <SocialSharing url={window.location.href} onlyIcons />
          </SocialSharingContainer>
        </InnerContainer>
      </Container>
      <LatestNewsContainer>
        <LatestNewsInnerContainer>
          <CarouselLatestNews
            items={latestNews}
            headerLabelId={latestNewsHeaderLabelId}
            headerLinksParams={headerLinksParams}
          />
        </LatestNewsInnerContainer>
      </LatestNewsContainer>
      <Modal open={isPrivate} onClose={onClosePremiumModal} contentFullWidth={false} controls={false}>
        <PremiumAccessNotification onCancel={onClosePremiumModal} redirectPath={redirectPath} />
      </Modal>
    </PageWrapper>
  );
};

const Container = styled.div`
  padding: 0 var(--horizontal-padding-mobile);

  @media ${Devices.desktopSmall} {
    padding: 0 var(--horizontal-padding);
  }
`;

const InnerContainer = styled.div<{ isPrivate: boolean }>`
  filter: ${({ isPrivate }): string => (isPrivate ? 'blur(5px)!important' : 'none')};
  max-width: 920px;
  margin: 0 auto;
`;

const SocialSharingContainer = styled.div`
  padding: 50px 0;
`;

const LatestNewsContainer = styled.div`
  background-color: ${(props): string => props.theme.color.whiteSmoke};
  padding: 30px 0;
`;

const LatestNewsInnerContainer = styled.div`
  max-width: 940px;
  margin: auto;
`;

export default NewsDetailsPageWrapper;
