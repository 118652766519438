import { PlayerStatistics, TeamStatistics } from '@app/types/teamsTypes';
import React from 'react';

import {
  ValueAndCaption, BlockContainer, StatsBlock,
} from '@app/components/Statistics';

interface PerformanceStatisticsProps {
  statistics?: PlayerStatistics;
  teamStatistics?: TeamStatistics;
}

const PerformanceStatistics = ({ statistics, teamStatistics }: PerformanceStatisticsProps): React.ReactElement => (
  <BlockContainer>
    <StatsBlock borderWidth="0 1px 0 0">
      <ValueAndCaption value={statistics?.gamesPlayed ?? 0} additionalValue={teamStatistics?.gamesPlayed ?? 0} caption="statistics.matchesPlayed" />
    </StatsBlock>
    <StatsBlock>
      <ValueAndCaption value={`${statistics?.timePlayed ?? 0}'`} caption="statistics.minutesPlayed" />
    </StatsBlock>
  </BlockContainer>
);

export default PerformanceStatistics;
