import { ContentItem, Elements } from '@kentico/kontent-delivery';

import MenuItem from './menu_item';
import CtaButton from './cta_button';
import RelatedMenuItem from './related_menu_item';
import WebPartnerBottom from './web_partner_bottom';
import WebSocial from './web_social';
import Competition from './competition';
import WebCarousel from './web_carousel';
import WebPartner from '@app/services/kentico/types/web_partner';
import { ExternalLinkMenuItem } from "@app/types/configurationTypes";

export default class Configuration extends ContentItem {
  public seoMetadataMetaRobots: Elements.MultipleChoiceElement;
  public seoMetadataSocialImage: Elements.CustomElement;
  public seoMetadataSocialDescription: Elements.TextElement;
  public cookieBannerText: Elements.RichTextElement;
  public legalLinks: Elements.LinkedItemsElement<RelatedMenuItem | ExternalLinkMenuItem>;
  public socialHeader: Elements.LinkedItemsElement<WebSocial>;
  public socialFooter: Elements.LinkedItemsElement<WebSocial>;
  public genericErrorPageText: Elements.TextElement;
  public webEditorialLayoutNews: Elements.MultipleChoiceElement;
  public sections: Elements.LinkedItemsElement<MenuItem>;
  public squadPrimaveraCompetitions: Elements.LinkedItemsElement<Competition>;
  public sequence: Elements.LinkedItemsElement<MenuItem>;
  public social: Elements.LinkedItemsElement<WebSocial>;
  public socialGeneral: Elements.LinkedItemsElement<WebSocial>;
  public squadMenCompetitionsArchive: Elements.LinkedItemsElement<Competition>;
  public squadWomenCompetitions: Elements.LinkedItemsElement<Competition>;
  public cookieBannerCtaPosition: Elements.MultipleChoiceElement;
  public seoMetadataCanonicalTag: Elements.TextElement;
  public cookieBannerAcceptCta: Elements.LinkedItemsElement<CtaButton>;
  public errorSorryButton: Elements.LinkedItemsElement<CtaButton>;
  public cookieBannerDeclineCta: Elements.LinkedItemsElement<CtaButton>;
  public seoMetadataMetaDescription: Elements.TextElement;
  public copyright: Elements.RichTextElement;
  public n404ErrorPageText: Elements.TextElement;
  public socialSharing: Elements.LinkedItemsElement<WebSocial>;
  public seoMetadataSocialTitle: Elements.TextElement;
  public partners: Elements.LinkedItemsElement<WebPartnerBottom>;
  public partnersHeaderSection: Elements.LinkedItemsElement<WebPartner>;
  public webEditorialLayoutVideos: Elements.MultipleChoiceElement;
  public squadMenCompetitions: Elements.LinkedItemsElement<Competition>;
  public seoMetadataMetaTitle: Elements.TextElement;
  public webEditorialLayoutGallery: Elements.MultipleChoiceElement;
  public homeTopElement: Elements.LinkedItemsElement<WebCarousel>;
  public isShowNextMatches: Elements.MultipleChoiceElement;
  public liveMode: Elements.MultipleChoiceElement;
  public liveVideoId: Elements.TextElement;
  public liveSessionId: Elements.TextElement;
  public liveVideoTitle: Elements.TextElement;
  public liveImage: Elements.CustomElement;
  public liveImageVertical: Elements.CustomElement;
  public livePage: Elements.LinkedItemsElement<RelatedMenuItem>;
  public catalogid: Elements.TextElement;
  constructor() {
    super({
      propertyResolver: ((elementName: string) => {
        if (elementName === 'seo_metadata__meta_robots') {
          return 'seoMetadataMetaRobots';
        }
        if (elementName === 'seo_metadata__social_image') {
          return 'seoMetadataSocialImage';
        }
        if (elementName === 'seo_metadata__social_description') {
          return 'seoMetadataSocialDescription';
        }
        if (elementName === 'cookie_banner_text') {
          return 'cookieBannerText';
        }
        if (elementName === 'legal_links') {
          return 'legalLinks';
        }
        if (elementName === 'social_header') {
          return 'socialHeader';
        }
        if (elementName === 'social_footer') {
          return 'socialFooter';
        }
        if (elementName === 'generic_error_page_text') {
          return 'genericErrorPageText';
        }
        if (elementName === 'web_editorial_layout_news') {
          return 'webEditorialLayoutNews';
        }
        if (elementName === 'squad_primavera_competitions') {
          return 'squadPrimaveraCompetitions';
        }
        if (elementName === 'social_footer') {
          return 'social';
        }
        if (elementName === 'social_general') {
          return 'socialGeneral';
        }
        if (elementName === 'squad_men_competitions_archive') {
          return 'squadMenCompetitionsArchive';
        }
        if (elementName === 'squad_women_competitions') {
          return 'squadWomenCompetitions';
        }
        if (elementName === 'cookie_banner_cta_position') {
          return 'cookieBannerCtaPosition';
        }
        if (elementName === 'seo_metadata__canonical_tag') {
          return 'seoMetadataCanonicalTag';
        }
        if (elementName === 'cookie_banner_accept_cta') {
          return 'cookieBannerAcceptCta';
        }
        if (elementName === 'error_sorry_button') {
          return 'errorSorryButton';
        }
        if (elementName === 'cookie_banner_decline_cta') {
          return 'cookieBannerDeclineCta';
        }
        if (elementName === 'seo_metadata__meta_description') {
          return 'seoMetadataMetaDescription';
        }
        if (elementName === 'n404_error_page_text') {
          return 'n404ErrorPageText';
        }
        if (elementName === 'social_sharing') {
          return 'socialSharing';
        }
        if (elementName === 'seo_metadata__social_title') {
          return 'seoMetadataSocialTitle';
        }
        if (elementName === 'web_editorial_layout_videos') {
          return 'webEditorialLayoutVideos';
        }
        if (elementName === 'squad_men_competitions') {
          return 'squadMenCompetitions';
        }
        if (elementName === 'seo_metadata__meta_title') {
          return 'seoMetadataMetaTitle';
        }
        if (elementName === 'web_editorial_layout_gallery') {
          return 'webEditorialLayoutGallery';
        }
        if (elementName === 'partners_header_section') {
          return 'partnersHeaderSection';
        }
        if (elementName === 'home_top_element') {
          return 'homeTopElement';
        }
        if (elementName === 'is_show_next_matches') {
          return 'isShowNextMatches';
        }
        if (elementName === 'live_mode') {
          return 'liveMode';
        }
        if (elementName === 'live_video_id') {
          return 'liveVideoId';
        }
        if (elementName === 'live_session_id') {
          return 'liveSessionId';
        }
        if (elementName === 'live_video_title') {
          return 'liveVideoTitle';
        }
        if (elementName === 'main_image') {
          return 'liveImage';
        }
        if (elementName === 'main_image_vertical_app') {
          return 'liveImageVertical';
        }
        if (elementName === 'live_page') {
          return 'livePage';
        }
        return elementName;
      })
    });
  }
}
