import * as WebTemplateService from '@app/services/kentico/webTemplateService';
import * as PromotionsService from '@app/services/kentico/promotionsService';

import { Action, ActionWithPromiseReturn } from '@app/types/actionTypes';
import {
  LoadingWebTemplateData,
  ResetWebTemplateData,
  ResetWebTemplateDataList,
  ResetWebTemplateError,
  SetWebTemplateData,
  SetWebTemplateDataList,
  SetWebTemplateDataListPayload,
  SetWebTemplateDataPayload,
  SetWebTemplateError,
  WebTemplateActionTypes,
} from '@app/store/actionTypes/webTemplateActionTypes';
import { LocalizedWebTemplateData, WebLineCarouselType } from '@app/types/webTemplateTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { PageError } from '@app/types/errorTypes';

import AppRoutes from '@app/constants/routesConstants';
import { AppLanguages } from '@app/constants/localizationConstants';
import { getCatalogPreviewList } from '@app/helpers/inviolaHelpers';
import { waitForConfigurationDownload } from '@app/store/actions/configurationActions';
import { syncCatalogItems } from '@app/store/actions/inviolaActions';
import { getSecondLevelMenuItem, getThirdLevelMenuItem, getTopLevelMenuItem } from '@app/helpers/menuHelpers';
import { CurrentRouteParams, setCurrentRoute } from '@app/store/actions/currentRouteActions';
import { BaseMenuItem, MenuItemTypes } from '@app/types/configurationTypes';
import { RouteParams } from '@app/types/routerTypes';
import { mapWebTemplateInviolaCatalogItems } from '@app/services/inviola/converters';

const setWebTemplateData = (data: SetWebTemplateDataPayload): SetWebTemplateData => ({
  type: WebTemplateActionTypes.SET_WEB_TEMPLATE_DATA,
  payload: data,
});

export const resetWebTemplateData = (): ResetWebTemplateData => ({
  type: WebTemplateActionTypes.RESET_WEB_TEMPLATE_DATA,
});

const setWebTemplateDataList = (data: SetWebTemplateDataListPayload): SetWebTemplateDataList => ({
  type: WebTemplateActionTypes.SET_WEB_TEMPLATE_DATA_LIST,
  payload: data,
});

export const resetWebTemplateDataList = (): ResetWebTemplateDataList => ({
  type: WebTemplateActionTypes.RESET_WEB_TEMPLATE_DATA_LIST,
});

export const loadingWebTemplateData = (state: boolean): LoadingWebTemplateData => ({
  type: WebTemplateActionTypes.LOADING_WEB_TEMPLATE_DATA,
  payload: state,
});

export const setWebTemplateError = (error: PageError): SetWebTemplateError => ({
  type: WebTemplateActionTypes.SET_WEB_TEMPLATE_ERROR,
  payload: error,
});

export const resetWebTemplateError = (): ResetWebTemplateError => ({
  type: WebTemplateActionTypes.RESET_WEB_TEMPLATE_ERROR,
});

interface GetWebTemplateDataByIdProps {
  id: string;
  language: LanguageType;
}

interface GetWebTemplateDataByIdProps {
  id: string;
  language: LanguageType;
}

export const getWebTemplateDataById = ({ id, language }: GetWebTemplateDataByIdProps): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    dispatch(loadingWebTemplateData(true));
    const webTemplateData = await WebTemplateService.getWebTemplateData(id, language);

    const localizedWebTemplateData: LocalizedWebTemplateData = {};
    localizedWebTemplateData[language] = webTemplateData.templateHierarchy;

    dispatch(setWebTemplateData({
      templates: {
        data: localizedWebTemplateData,
        id,
      },
    }));

    dispatch(loadingWebTemplateData(false));
  }
);

type SetWebTemplatePageMultiLangRoute = (params: RouteParams) => Action
export const setWebTemplatePageMultiLangRoute: SetWebTemplatePageMultiLangRoute = (urlParams) => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();
    const {
      topLevel, secondLevel, thirdLevel = '', language,
    } = urlParams;
    const state = getState();
    const topLevelMenu = getTopLevelMenuItem(state, topLevel, language);
    const secondLevelMenu = getSecondLevelMenuItem(state, topLevel, secondLevel, language);
    const thirdLevelMenu = getThirdLevelMenuItem(state, topLevel, secondLevel, thirdLevel, language);

    const currentRouteParams = {
      topLevel: {}, secondLevel: {}, thirdLevel: {}, forthLevel: {}, fifthLevel: {},
    } as unknown as CurrentRouteParams;

    const params = AppLanguages.reduce((acc, language) => {
      acc.topLevel[language] = topLevelMenu[language]?.data?.url ?? '';
      const secondLevelUrl = secondLevelMenu?.[language]?.data?.type === MenuItemTypes.BaseMenuItem
        ? (secondLevelMenu[language]?.data as BaseMenuItem)?.url : null;
      acc.secondLevel[language] = secondLevelUrl ?? '';
      acc.thirdLevel[language] = thirdLevelMenu?.[language]?.url ?? '';
      acc.forthLevel[language] = '';
      acc.fifthLevel[language] = '';

      return acc;
    }, currentRouteParams);

    dispatch(setCurrentRoute({
      pathId: AppRoutes.CommonPage.path,
      params,
    }));
  }
);

interface GetLineCarouselDataListProps {
  type: WebLineCarouselType;
  limit: number;
  language: LanguageType;
}

export const getLineCarouselDataList = ({
  type, limit, language,
}: GetLineCarouselDataListProps): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    const localizedData: LocalizedWebTemplateData = {};
    dispatch(loadingWebTemplateData(true));

    switch (type) {
      case WebLineCarouselType.InviolaCatalog:
        await dispatch(syncCatalogItems(language));

        localizedData[language] = mapWebTemplateInviolaCatalogItems(
          getCatalogPreviewList(getState().inviola.catalog.items, getState().inviola.catalog.selectedItemId),
        );
        break;
      case WebLineCarouselType.Promotion:
        // eslint-disable-next-line no-case-declarations
        const promotions = await PromotionsService.getPromotionList({ limit: 6, skip: 0, language });

        localizedData[language] = promotions.items;
        break;
      default:
        localizedData[language] = await WebTemplateService
          .getWebTemplateList({ type, language, limit, skip: 0 });
        break;
    }

    dispatch(setWebTemplateDataList({
      templates: {
        data: localizedData,
        type,
      },
    }));

    dispatch(loadingWebTemplateData(false));
  }
);
