import { Locale } from 'date-fns';
import { enUS, it } from 'date-fns/locale';
import trimEnd from 'lodash/trimEnd';

import { NavigationLinkParams } from '@app/types/routerTypes';
import { LanguageType } from '@app/types/localizationTypes';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales';

type LocalizeRouteKey = (data: NavigationLinkParams, locale: LanguageType) => string;

export const localizeRouteKey: LocalizeRouteKey = ({ pathKey, pathParams }, locale) => {
  const formattedPath = Translations[locale]?.[pathKey] || '';

  const formattedPathWithParams = pathParams
    ? Object
      .entries(pathParams)
      .reduce((path, entry) => path
        .replace(new RegExp(`(:${entry[0]})(\\?)?(\\(.*\\))?`, 'g'), entry[1]), formattedPath)
    : formattedPath;

  return `/${locale}${trimEnd(formattedPathWithParams, '/')}`;
};

export const mapLanguageTypeToDateFnsLocale = (languageType: LanguageType): Locale => {
  switch (languageType) {
    case 'en': return enUS;
    case 'it': return it;
    default: return enUS;
  }
};
