import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import noop from 'lodash/noop';
import styled from 'styled-components';

import { LanguageType } from '@app/types/localizationTypes';
import { Link } from 'react-router-dom';

export enum ExpandArrowState {
  Closed,
  Opened,
  Hidden
}

interface LanguageProps {
  onClick?: (e: MouseEvent) => void;
  lang: LanguageType;
  expandArrow: ExpandArrowState;
  className?: string; // passed by styled component automatically
  url?: string;
}

const Language: React.FC<LanguageProps> = ({
  onClick, lang, expandArrow, className, url,
}) => {
  const { formatMessage } = useIntl();
  const linkProps = {
    onClick: onClick || noop,
    to: url,
  };

  return (
    <Lang className={className}>
      <LanguageLink {...linkProps} as={url ? Link : 'div'}>
        <LangIcon src={`/images/${lang}.png`} alt={`${lang} language`} />
        <span>
          <FormattedMessage id={lang} />
        </span>
      </LanguageLink>
      {
        (expandArrow !== ExpandArrowState.Hidden) && (
          <ExpandArrow
            src="/images/arrow.svg"
            alt={formatMessage({ id: 'icon.expandLanguagesList.alt' })}
            isOpened={expandArrow === ExpandArrowState.Opened}
          />
        )
      }
    </Lang>
  );
};

const Lang = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  margin: 0 15px;
  letter-spacing: 0.29px;

  &:last-child {
    border: none;
  }
`;

const LanguageLink = styled(Link)`
  color: white;
`;

const LangIcon = styled.img`
  height: 12px;
  margin: 0 5px 0 0;
`;

const ExpandArrow = styled.img<{isOpened: boolean}>`
  transform: rotate(${({ isOpened }): string => (isOpened ? '180deg' : '0')});
`;

export default Language;
