export enum SearchActionTypes {
  SET_SEARCH_BAR_VISIBILITY = 'SEARCH/SET_SEARCH_BAR_VISIBILITY',
}

export interface SetSearchBarVisibility {
  type: SearchActionTypes.SET_SEARCH_BAR_VISIBILITY;
  payload: boolean;
}

export type SearchActions = SetSearchBarVisibility;
