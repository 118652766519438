export enum MenuActionTypes {
  SET_STATE = 'MENU/SET_STATE',
}

export interface SetMenuState {
  type: MenuActionTypes.SET_STATE;
  payload: boolean;
}

export type MenuActions = SetMenuState;
