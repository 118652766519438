import WebCarouselItem from './web_carousel_item';
import { ContentItem, Elements } from '@kentico/kontent-delivery';
import RelatedMenuItem from '@app/services/kentico/types/related_menu_item';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export default class WebCarousel extends ContentItem {
  public title: Elements.TextElement;
  public items: Elements.LinkedItemsElement<WebCarouselItem>;
  public link: Elements.LinkedItemsElement<RelatedMenuItem>;
}
