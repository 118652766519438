import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';

import { Devices } from '@app/styles';

import { useIsConnected, useInviolaPath } from './InviolaHooks';

interface InviolaProps {
  withBanner?: boolean;
  withoutContainer?: boolean;
  withAuthCheck?: boolean;

  children: ReactNode;
  className?: string;
}

const InviolaWrapper: React.FC<InviolaProps> = ({
  withBanner, withoutContainer, className, children, withAuthCheck = true,
}) => {
  const isConnected = useIsConnected();
  const { authPath } = useInviolaPath();

  const { formatMessage } = useIntl();

  if (withAuthCheck && !isConnected) { return <Redirect to={authPath} />; }

  return (
    <Container className={className} noPadding={withoutContainer}>
      <InnerComponent withoutContainer={withoutContainer}>
        <Content>
          { withBanner && <Banner><Image src="/images/logo-inviola.png" alt={formatMessage({ id: 'mainLogo.alt' })} /></Banner> }
          { children }
        </Content>
      </InnerComponent>
    </Container>
  );
};

interface InnerComponentProps {
  withoutContainer?: boolean;
  children: ReactNode;
}

const InnerComponent = ({ withoutContainer, children }: InnerComponentProps): React.ReactElement => {
  if (withoutContainer) {
    return <div>{ children }</div>;
  }

  return <InnerContainer>{ children }</InnerContainer>;
};

const Container = styled.div<{ noPadding?: boolean }>`
  position: relative;
  padding-top: ${(props): number => (props.noPadding ? 0 : 80)}px;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
`;

const InnerContainer = styled.div`
  margin: auto;
  width: 90%;

  @media ${Devices.tabletLarge} {
    max-width: 920px;
  }
`;

const Image = styled.img`
  max-height: 80px;
  width: auto;
  margin: auto;
`;

const Content = styled.div`
  flex-grow: 1;
  padding-bottom: 25px;
  font-size: 1rem;
`;

const Banner = styled.div`
  margin-bottom: 70px;
  height: 150px;
  object-fit: contain;
  border-radius: 20px;
  background-color: ${(props): string => props.theme.color.purple};
  display: flex;
`;

export default InviolaWrapper;
