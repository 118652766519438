import React from 'react';

import { WebHomeElementBackground, WebTemplateHomeElement } from '@app/types/webTemplateTypes';
import { useHomeDataList, useHomeDataListDownload } from '@app/components/Home/HomeHooks';
import { EditorialContentTypes } from '@app/types/newsTypes';
import styled from 'styled-components';
import { FullSizeNewItem, NewItem } from '@app/components/News';
import HomeTitle from '@app/components/Home/components/HomeTitle';
import { Devices } from '@app/styles';

interface WebHomeNewsElementProps {
  data: WebTemplateHomeElement;
}

const WebHomeNewsElement = ({ data }: WebHomeNewsElementProps): React.ReactElement | null => {
  useHomeDataListDownload(EditorialContentTypes.NEWS, data.category, 6);
  const dataList = useHomeDataList(EditorialContentTypes.NEWS, data.category);
  const latest = dataList?.[0];
  const firstRow = dataList?.slice(1, 3);
  const secondRow = dataList?.slice(3, 6);

  const latestSize = {
    1025: { width: 1280, height: 500 },
    769: { width: 1024, height: 450 },
    376: { width: 768, height: 450 },
    0: { width: 375, height: 400 },
  };

  return (
    <Container background={data.background}>
      <Grid>
        <Latest>
          {latest && (<FullSizeNewItem data={latest} imageWidthMap={latestSize} home />)}
        </Latest>
        <TitleWrapper>
          {data.title && (<HomeTitle title={data.title} link={data.link} background={data.background} showSeeMore />)}
        </TitleWrapper>
        <RowsWrapper>
          <Rows>
            <FirstRow>
              {
            firstRow?.map((item) => (
              <FirstRowItem key={item.id}>
                <NewItem data={item} infoSize="medium" />
              </FirstRowItem>
            ))
          }
            </FirstRow>
            <SecondRow>
              {
            secondRow?.map((item) => (
              <SecondRowItem key={item.id}>
                <NewItem data={item} />
              </SecondRowItem>
            ))
          }
            </SecondRow>
          </Rows>
        </RowsWrapper>
      </Grid>

    </Container>
  );
};

const Container = styled.div<{ background: WebHomeElementBackground }>`
  background-color: ${({ background, theme }): string => (background === WebHomeElementBackground.Purple ? theme.color.purple : 'transparent')};
  background-image: ${({ background }): string => (background === WebHomeElementBackground.Purple ? 'url("/images/block-background.webp")' : 'transparent')};
  background-position: center center;
  background-size: cover;
  padding-bottom: 50px;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
`;

const Latest = styled.div`
  width: 100%;
  position: relative;
`;

const TitleWrapper = styled.div`
  position: relative;
`;

const RowsWrapper = styled.div`
  overflow-x: auto;

  @media ${Devices.desktopSmall} {
    overflow: inherit;
  }
`;

const Rows = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;

  @media ${Devices.desktopSmall} {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

const FirstRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 45px 20px 30px 20px;

  @media ${Devices.desktopSmall} {
    padding: 60px 0 20px;
    display: flex;
    justify-content: center;
  }
`;

const FirstRowItem = styled.div`
  width: 300px;
  height: 170px;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }

  @media ${Devices.desktopSmall} {
    width: 560px;
    height: 310px;
  }
`;

const SecondRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 45px 20px 30px 0;

  @media ${Devices.desktopSmall} {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }
`;

const SecondRowItem = styled.div`
  width: 300px;
  height: 170px;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }

  @media ${Devices.desktopSmall} {
    width: 366px;
    height: 205px;
    margin-right: 21px;
  }
`;

export default WebHomeNewsElement;
