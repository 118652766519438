import React from 'react';
import styled from 'styled-components';

import { getLatestGallery, resetLatestGallery } from '@app/store/actions/newsActions';

import { EditorialContentTypes } from '@app/types/newsTypes';

import { Metadata } from '@app/components';
import { GalleryCarousel } from '@app/components/Gallery';
import { NewsDetailsPageWrapper, NewsItemInfo } from '@app/components/News';
import {
  useLatestNews,
  useNewsDetailsPath,
  useNewsDetailsMultiLangRoute,
  useNewsDetailsPageValidation,
} from '@app/components/Hooks';
import { useAllNewsUrlParams } from '@app/components/Hooks/NewsHooks';

import { useDownloadGallery, useGallery, useGallerySeo } from './PhotoGalleryHooks';

const PhotoGallery = (): React.ReactElement => {
  useDownloadGallery();
  useNewsDetailsMultiLangRoute(EditorialContentTypes.PHOTO);
  const gallery = useGallery();
  const seo = useGallerySeo();
  const latestGallery = useLatestNews({
    type: EditorialContentTypes.PHOTO,
    requestLatestNews: getLatestGallery,
    resetLatestNews: resetLatestGallery,
  });
  const error = useNewsDetailsPageValidation(gallery);
  const headerLinksParams = useAllNewsUrlParams(EditorialContentTypes.PHOTO);
  const redirectPath = useNewsDetailsPath(gallery);

  return (
    <NewsDetailsPageWrapper
      error={error}
      latestNewsHeaderLabelId="gallery.lastPhotos"
      latestNews={latestGallery}
      headerLinksParams={headerLinksParams}
      isPremium={gallery?.isPremium}
      redirectPath={redirectPath}
    >
      {seo && <Metadata seo={seo} />}
      {
        gallery && (
          <>
            {
              !!gallery.photos?.length && (
                <CarouselContainer>
                  <GalleryCarousel slides={gallery?.photos ?? []} />
                </CarouselContainer>
              )
            }
            <NewsItemInfo
              title={gallery.title}
              subtitle={gallery.subtitle}
              category={gallery.categoryCode}
              size="large"
              time={gallery.publicationDate}
              color="black"
              photosNumber={gallery.photos.length}
              isPremium={gallery.isPremium}
            />
          </>
        )
      }
    </NewsDetailsPageWrapper>
  );
};

const CarouselContainer = styled.div`
  margin-bottom: 50px;
`;

export default PhotoGallery;
