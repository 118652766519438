import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { isNumeric } from 'rxjs/internal-compatibility';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { Button, Column, Container, Row } from '@app/components/Inviola/InviolaComponents';
import { Loader } from '@app/components';
import { Input } from '@app/components/Inviola/InviolaFields';
import { sendPoints } from '@app/store/actions/inviolaActions/catalog';
import {
  useError,
  useInputValidations,
  useInviolaCustomer,
  usePointsSyncState,
} from '@app/components/Inviola/InviolaHooks';

interface TransferPointsProps {
  onClose: () => void;
}

interface TransferPointsForm {
  cardNumber: string;
  amount: string;
}

enum TransferPointsStep {
  FillForm,
  Validate,
  Result,
}

const TransferPoints = ({ onClose }: TransferPointsProps): React.ReactElement => {
  const [step, setStep] = useState(TransferPointsStep.FillForm);
  const [formData, setFormData] = useState({
    cardNumber: '',
    amount: '',
  });

  const methods = useForm<TransferPointsForm>({ mode: 'onChange' });
  const { handleSubmit, trigger, formState: { isValid }, watch, setValue, getValues } = methods;

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const syncInProgress = usePointsSyncState();
  const validations = useInputValidations();
  const customer = useInviolaCustomer();
  const error = useError();

  const amount = parseInt(watch('amount', '0'), 10);
  const balance = customer?.balancePoints ?? 0;

  const submit = () => {
    setFormData(getValues());
    setStep(TransferPointsStep.Validate);
  };

  const send = () => {
    dispatch(sendPoints(formData.cardNumber, formData.amount));
    setStep(TransferPointsStep.Result);
  };

  const back = () => {
    setStep(TransferPointsStep.FillForm);
    setValue('amount', formData.amount);
    setValue('cardNumber', formData.cardNumber);
  };

  const addPoints = (addPoints: number) => {
    setValue('amount', addPoints + (isNumeric(amount) ? amount : 0));
    // eslint-disable-next-line no-void
    void trigger('amount');
  };

  const isEnoughPoints = (addPoints: number) => balance >= addPoints + (isNumeric(amount) ? amount : 0);

  return (
    <TransferPointsContainer>
      <OverflowScrollingWrapper>
        {step === TransferPointsStep.FillForm && (
          <>
            <Title>{formatMessage({ id: 'inviola.profile.card.pointsTransfer' })}</Title>
            <Description>{formatMessage({ id: 'inviola.profile.card.transfer.description' })}</Description>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(submit)}>
                <Container>
                  <Row>
                    <Column>
                      <TransferInput
                        name="cardNumber"
                        labelId="inviola.profile.card.cardNumber"
                        placeholderId="inviola.profile.card.cardNumber.placeholder"
                        validations={validations.transferCard}
                      />
                    </Column>
                  </Row>
                  <ButtonRow>
                    <AddPointsColumn>
                      <ButtonAddPoints
                        disabled={!isEnoughPoints(1000)}
                        onClick={() => addPoints(1000)}
                        type="button"
                      >
                        +1000
                      </ButtonAddPoints>
                    </AddPointsColumn>
                    <AddPointsColumn>
                      <ButtonAddPoints
                        disabled={!isEnoughPoints(5000)}
                        onClick={() => addPoints(5000)}
                        type="button"
                      >
                        +5000
                      </ButtonAddPoints>
                    </AddPointsColumn>
                    <InputColumn>
                      <TransferInput
                        name="amount"
                        labelId="inviola.profile.card.transfer.amount"
                        hintId="inviola.profile.card.transfer.amount.condition"
                        placeholderId="inviola.profile.card.transfer.amount.placeholder"
                        validations={validations.points}
                      />
                    </InputColumn>
                    <ButtonColumn>
                      <ButtonValidate type="submit" disabled={!isValid || balance < amount || amount < 999}>
                        {formatMessage({ id: 'inviola.profile.card.transfer.validate' })}
                      </ButtonValidate>
                    </ButtonColumn>
                    <ButtonColumn>
                      <ButtonCancel onClick={onClose} type="button">
                        {formatMessage({ id: 'inviola.profile.card.transfer.cancel' })}
                      </ButtonCancel>
                    </ButtonColumn>
                  </ButtonRow>
                </Container>
              </form>
            </FormProvider>
          </>
        )}
        {step === TransferPointsStep.Validate && (
          <>
            <Title>{formatMessage({ id: 'inviola.profile.card.pointsTransfer' })}</Title>
            <Description>{formatMessage({ id: 'inviola.profile.card.transfer.validate.description' })}</Description>
            <Points>
              {formData.amount}
              <Pts>{formatMessage({ id: 'inviola.catalog.pts' })}</Pts>
            </Points>
            <CardNumberCaption>{formatMessage({ id: 'inviola.profile.card.cardNumber' })}</CardNumberCaption>
            <CardNumber>{formData.cardNumber}</CardNumber>
            <Container>
              <ButtonRow>
                <ButtonColumnLarge>
                  <ButtonValidate disabled={!isValid} onClick={send}>
                    {formatMessage({ id: 'inviola.profile.card.transfer.transfer' })}
                  </ButtonValidate>
                </ButtonColumnLarge>
                <ButtonColumnLarge>
                  <ButtonCancel onClick={back}>
                    {formatMessage({ id: 'inviola.profile.card.transfer.back' })}
                  </ButtonCancel>
                </ButtonColumnLarge>
              </ButtonRow>
            </Container>
          </>
        )}
        {step === TransferPointsStep.Result && (
          <>
            <Title>{formatMessage({ id: 'inviola.profile.card.pointsTransfer' })}</Title>
            {syncInProgress && <Loader />}
            {!syncInProgress && !error && (
              <Success>
                {formatMessage({ id: 'inviola.profile.card.transfer.result.success' }, { ...formData })}
              </Success>
            )}
            {!syncInProgress && error && (
              <Error>
                {formatMessage({ id: `inviola.errors.${error?.code ?? '1'}` })}
              </Error>
            )}
          </>
        )}
      </OverflowScrollingWrapper>
    </TransferPointsContainer>
  );
};

const TransferPointsContainer = styled.div`
  background-color: #fff;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  text-align: left;
  padding: 0;

  max-height: 100vh;
  overflow: auto;

  @media ${Devices.desktop} {
    padding: 30px;
    width: 940px;
  }
`;

const OverflowScrollingWrapper = styled.div`
  padding: 30px;

  overflow: hidden;

  @media ${Devices.desktop} {
    padding: 0;
  }
`;

const Title = styled.h1`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
`;

const Description = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 16px;
  text-align: center;
  color: #000000;
  margin: 10px auto 50px;
`;

const Points = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 52px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 5.2px;
  color: ${(props): string => props.theme.color.purple};
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
`;

const Pts = styled.span`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-weight: bold;
  text-align: center;
  color: ${(props): string => props.theme.color.purple};
  font-size: 17px;
  letter-spacing: 1.7px;
  text-transform: uppercase;
`;

const Success = styled.p`
  margin-top: 50px;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-weight: bold;
  text-align: center;
  color: ${(props): string => props.theme.color.purple};
  font-size: 17px;
  letter-spacing: 1.7px;
  text-transform: uppercase;
`;

const Error = styled(Success)`
  color: ${(props): string => props.theme.color.red};
`;

const CardNumberCaption = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 12px;
  text-align: center;
  color: ${(props): string => props.theme.color.purple};
  text-transform: uppercase;
  margin-top: 23px;
`;

const CardNumber = styled(CardNumberCaption)`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 22px;
  font-weight: bold;
  margin-top: 5px;
`;

const AddPointsColumn = styled.div`
  flex: 0 0 60px;
  padding-right: 10px;
`;

const InputColumn = styled.div`
  position: relative;
  flex: 1;

  & div {
    position: absolute;
  }
`;

const ButtonColumn = styled.div`
  flex: 0 0 100px;
  padding-left: 10px;
`;

const ButtonColumnLarge = styled.div`
  flex: 0 0 220px;
  padding-left: 10px;
`;

const ButtonRow = styled(Row)`
  align-items: flex-end;
  justify-content: center;
  padding: 0 15px;
`;

const ButtonAddPoints = styled(Button)`
  background: ${(props): string => props.theme.color.purple};
  border: solid 2px ${(props): string => props.theme.color.purple};
  color: #ffffff;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;

  &:not(:disabled):hover {
    color: ${(props): string => props.theme.color.purple};
    border: solid 2px ${(props): string => props.theme.color.purple};
    background: transparent;
  }

  @media ${Devices.tabletLarge} {
    font-size: 12px;
    padding: 13px;
  }
`;

const ButtonValidate = styled(ButtonAddPoints)`
  background: ${(props): string => props.theme.color.red};
  border: solid 2px ${(props): string => props.theme.color.red};

  &:not(:disabled):hover {
    color: ${(props): string => props.theme.color.red};
    border: solid 2px ${(props): string => props.theme.color.red};
    background: transparent;
  }
`;

const ButtonCancel = styled(ButtonValidate)`
  background: transparent;
  border: solid 2px ${(props): string => props.theme.color.red};
  color: ${(props): string => props.theme.color.red};

  &:not(:disabled):hover {
    color: #ffffff;
    background: ${(props): string => props.theme.color.red};
    border: solid 2px ${(props): string => props.theme.color.red};
  }
`;

const TransferInput = styled(Input)`
  background-color: ${(props): string => props.theme.color.whiteSmoke};
`;

export default TransferPoints;
