import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { Title } from '@app/components/Inviola/InviolaComponents';
import InviolaWrapper from '@app/components/Inviola/InviolaWrapper';

import { useInviolaSync, useResetPassword } from '@app/components/Inviola/InviolaHooks';
import { InviolaResetSteps } from '@app/services/inviola/types/stepTypes';

import AskResetForm from './components/AskResetForm';
import ConfirmResetForm from './components/ConfirmResetForm';
import SuccessReset from './components/SuccessReset';

const resetStepTypes: Record<InviolaResetSteps, React.FunctionComponent<unknown>> = {
  [InviolaResetSteps.ResetAsked]: AskResetForm,
  [InviolaResetSteps.ResetConfirmation]: ConfirmResetForm,
  [InviolaResetSteps.ResetSuccess]: SuccessReset,
};

const InviolaResetPage = (): React.ReactElement => {
  const { resetStep } = useResetPassword();
  const ActiveResetStep = resetStepTypes[resetStep];

  useInviolaSync();

  return (
    <InviolaWrapper withBanner withAuthCheck={false}>
      <Container>
        {resetStep !== InviolaResetSteps.ResetConfirmation && (
          <Title><FormattedMessage id={`inviola.${resetStep}.title`} /></Title>
        )}
        <Description><FormattedMessage id={`inviola.${resetStep}.description`} /></Description>

        <ActiveResetStep />
      </Container>
    </InviolaWrapper>
  );
};

const Description = styled.p`
  margin-bottom: 50px;
`;

const Container = styled.div`
  max-width: 100%;
  margin: auto;

  & ${Title},
  & ${Description} {
    text-align: center;
  }

  @media ${Devices.largeMobile} {
    max-width: 500px;

    & ${Title},
    & ${Description} {
      text-align: left;
    }
  }
`;

export default InviolaResetPage;
