import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import AppRoutes from '@app/constants/routesConstants';
import { InviolaFlags } from '@app/constants/inviolaConstants';

import { Devices } from '@app/styles';

import { useIsMenuOpened, useToggleMenu, useCloseMenu } from '@app/components/Hooks';
import { useIsConnected, useInviolaExternalUrl } from '@app/components/Inviola/InviolaHooks';

import { LanguageSwitcher, LocalizedLink } from '@app/components/Localization';

import { SearchButton } from './SearchBar';
import Menu from './Menu/Menu';

const MenuHeader = (): React.ReactElement => {
  const { formatMessage } = useIntl();
  const isOpenedMenu = useIsMenuOpened();
  const closeMenu = useCloseMenu();
  const toggleMenu = useToggleMenu();
  const { authPath } = useInviolaExternalUrl();
  const isConnected = useIsConnected();

  return (
    <Container>
      {isOpenedMenu && (
        <Logo
          pathKey={AppRoutes.Home.path}
          onClick={closeMenu}
        >
          <img src="/images/logo.svg" alt={formatMessage({ id: 'mainLogo.alt' })} />
        </Logo>
      )}
      <ControlsContainer>
        <ControlsSection>
          <LocalizedLink
            pathKey={AppRoutes.Home.path}
            onClick={closeMenu}
          >
            <MobileLogo src="/images/logo.svg" alt={formatMessage({ id: 'mainLogo.alt' })} isOpenedMenu={isOpenedMenu} />
          </LocalizedLink>
          <MenuIcon
            onClick={toggleMenu}
            src={isOpenedMenu ? '/images/close.svg' : '/images/menu.svg'}
            alt={isOpenedMenu ? formatMessage({ id: 'icon.closeMenu.alt' }) : formatMessage({ id: 'icon.openMenu.alt' })}
          />
        </ControlsSection>
        {
          !isOpenedMenu && (
            <ControlsSection>
              <SearchButton />
              { InviolaFlags.ShowSearch && <img src="/images/search.svg" alt={formatMessage({ id: 'icon.search.alt' })} /> }
              <LoginButton href={authPath}>
                <LoginIcon
                  src={isConnected ? '/images/icon-profile-logged-in.svg' : '/images/icon-profile.svg'}
                  alt={formatMessage({ id: `inviola.${isConnected ? 'profile' : 'login'}.title` })}
                />
              </LoginButton>
              <LanguageSwitcher />
            </ControlsSection>
          )
        }
      </ControlsContainer>
      {
        !isOpenedMenu && (
          <>
            <FillSpace />
            <Menu />
          </>
        )
      }
    </Container>
  );
};

const LoginButton = styled.a`
  padding: 5px;
`;

const LoginIcon = styled.img`
  width: 15px;
`;

const Container = styled.div`
  height: var(--navigation-header-height);
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding-top: 5px;
  position: relative;

  @media ${Devices.desktopSmall} {
    padding-top: 0;
  }
`;

const Logo = styled(LocalizedLink)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -18px);
  height: 69px;
  display: none;

  & img {
    height: 100%;
  }

  @media ${Devices.desktopSmall} {
    display: block;
  }
`;

const FillSpace = styled.div`
  flex: 1;
`;

const MobileLogo = styled.img<{isOpenedMenu: boolean;}>`
  height: 35px;
  display: block;
  margin-right: 22px;

  @media ${Devices.desktopSmall} {
    display: ${({ isOpenedMenu }): string => (isOpenedMenu ? 'none' : 'block')};
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 17px;

  @media ${Devices.desktopSmall} {
    width: auto;
    padding-top: 15px;
    justify-content: normal;
  }
`;

const ControlsSection = styled.div`
  display: flex;
  align-items: center;
`;

const MenuIcon = styled.img`
  margin-right: 30px;
  cursor: pointer;
`;

export default MenuHeader;
