import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales';
import { AppState } from '@app/types/appStateTypes';
import { Errors, PageError } from '@app/types/errorTypes';
import { Game, MatchResult, MatchComments, MatchLineups, MatchLineupsTeamPlayer } from '@app/types/matchTypes';
import { SeoData } from '@app/types/configurationTypes';
import { useLanguage } from '@app/components/Hooks';
import { useStandingSelector } from '@app/pages/Season/Standings/StandingsHooks';
import {
  getLiveMatchResults, getLocalizedGames, getMatchFeeds, resetMatchCenter, getMatchLineups, getMatchEvents,
  setMatchCenterMultiLangUrl, getMatchResults,
} from '@app/store/actions/matchActions';
import { getStandings } from '@app/store/actions/seasonActions';
import { isTeamFiorentina } from '@app/helpers/teamsHelpers';
import { getGameByUrlSlug, getMatchTeamName } from '@app/helpers/matchHelpers';
import { useLiveMatchResultsSelector } from '@app/components/MatchDay/MatchDayHooks';
import { MatchStatusMap } from '@app/constants/matchConstants';

export const useIsLoadingSelector = (): boolean => {
  const isGamesDownloadCompleted = useSelector<AppState, boolean>(
    (state) => state.matchCenter.isGamesDownloadCompleted, shallowEqual,
  );
  const isMatchFeedsDownloadCompleted = useSelector<AppState, boolean>(
    (state) => state.matchCenter.isMatchFeedsDownloadCompleted, shallowEqual,
  );

  return !(isGamesDownloadCompleted && isMatchFeedsDownloadCompleted);
};

export const useMatchResultsSelector = (): MatchResult | null => {
  const result = useSelector<AppState, MatchResult | null>(
    (state) => state.matchCenter.results, shallowEqual,
  );
  const liveResults = useLiveMatchResultsSelector(result?.gameId ?? '');

  return result ? { ...result, ...liveResults } : null;
};

export const useLineupsSelector = (): MatchLineups | null => useSelector<AppState, MatchLineups | null>(
  (state) => state.matchCenter.lineups,
  shallowEqual,
);

export const useEventsSelector = (): MatchComments[] => useSelector<AppState, MatchComments[]>(
  (state) => state.matchCenter.events ?? [],
  shallowEqual,
);

export const useTeamScoreSelector = (teamId: string): string => {
  const standing = useStandingSelector().find(({ id }) => id === teamId);
  return standing ? `${standing?.position}th - ${standing?.points}pts` : '';
};

export const useGameByOptaIdSelector = (gameOptaId = ''): Game | undefined => {
  const language = useLanguage();

  return useSelector<AppState, Game | undefined>(
    (state) => state
      .matchCenter.games?.[language]
      ?.find((game) => gameOptaId.replace('g', '') === game.gameOptaId.replace('g', '')),
    shallowEqual,
  );
};

export const useGameByUrlSlugSelector = (): Game | undefined => {
  const { thirdLevel = '' } = useParams();
  const language = useLanguage();

  return useSelector<AppState, Game | undefined>(
    (state) => getGameByUrlSlug(state, language, thirdLevel),
    shallowEqual,
  );
};

export const useMatchCenterSeoSelector = (): SeoData => {
  const language = useLanguage();
  const result = useMatchResultsSelector();
  const game = useGameByUrlSlugSelector();

  const formatMatchCenterSeoLabel = (label = ''): string => label
    .replace('{home}', getMatchTeamName(result?.teams.first))
    .replace('{away}', getMatchTeamName(result?.teams.second))
    .replace('{competition}', game?.competition ?? '')
    .replace('{season}', game?.seasonName ?? '');

  return {
    title: formatMatchCenterSeoLabel(Translations[language]?.['matchcenter.meta.title']),
    description: formatMatchCenterSeoLabel(Translations[language]?.['matchcenter.meta.description']),
    canonicalTag: '',
    meta: false,
    socialTitle: formatMatchCenterSeoLabel(Translations[language]?.['matchcenter.meta.title']),
    socialDescription: formatMatchCenterSeoLabel(Translations[language]?.['matchcenter.meta.description']),
    socialImageUrl: '',
  };
};

export const useIsFiorentinaCheckPlayerById = (playerId = ''): boolean => {
  const lineups = useSelector<AppState, MatchLineups | null>(
    (state) => state.matchCenter.lineups, shallowEqual,
  );
  const fiorentinaTeam = isTeamFiorentina(lineups?.home?.teamId) ? lineups?.home : lineups?.away;
  return !!fiorentinaTeam?.players?.find((player) => player.playerId === `p${playerId}`);
};

export const useMatchPlayerByIdSelector = (playerId = ''): MatchLineupsTeamPlayer | undefined => {
  const lineups = useSelector<AppState, MatchLineups | null>(
    (state) => state.matchCenter.lineups, shallowEqual,
  );

  return lineups?.home?.players?.find((player) => player.playerId === `p${playerId}`)
    ?? lineups?.away?.players?.find((player) => player.playerId === `p${playerId}`);
};

export const useTeamLogoByPlayerIdSelector = (playerId = ''): string => useSelector<AppState, string>(
  (state) => (
    state.matchCenter.lineups?.home?.players?.find((player) => player.playerId === `p${playerId}`)
      ? state.matchCenter.lineups?.home?.logoUrl ?? ''
      : state.matchCenter.lineups?.away?.logoUrl ?? ''
  ), shallowEqual,
);

export const useMatchCenterLiveSubscribe = (): void => {
  const dispatch = useDispatch();
  const language = useLanguage();
  const game = useGameByUrlSlugSelector();
  const result = useMatchResultsSelector();
  const { matchStatus } = useLiveMatchResultsSelector(result?.gameId ?? '');
  const { seasonId = '', optaId = '', gameOptaId = '' } = game ?? {};

  useEffect(() => {
    result?.gameId && !matchStatus && dispatch(getLiveMatchResults(result));
    const subscribe = result?.gameId && matchStatus !== MatchStatusMap.finished
      && setInterval(() => {
        dispatch(getLiveMatchResults(result));
        dispatch(getMatchLineups(seasonId, optaId, gameOptaId));
        dispatch(getMatchEvents(seasonId, optaId, gameOptaId, language));
      }, 20000);

    return (): void => { subscribe && clearInterval(subscribe); };
  }, [result, matchStatus]);
};

export const useMatchCenterMultiLangUrl = (): void => {
  const { topLevel = '', secondLevel = '', thirdLevel = '', forthLevel = '' } = useParams();
  const language = useLanguage();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMatchCenterMultiLangUrl({ topLevel, secondLevel, thirdLevel, forthLevel, language }));
  }, [thirdLevel, thirdLevel]);
};

export const useMatchCenterMultiLangEvents = (): void => {
  const dispatch = useDispatch();
  const language = useLanguage();
  const game = useGameByUrlSlugSelector();

  useEffect(() => {
    !!game && dispatch(getMatchEvents(game?.seasonId, game?.optaId, game?.gameOptaId, language));
  }, [language]);
};

export const useMatchCenter = (): void => {
  const dispatch = useDispatch();
  const { topLevel = '', secondLevel = '', thirdLevel = '', forthLevel = '' } = useParams();
  const language = useLanguage();

  useMatchCenterLiveSubscribe();
  useMatchCenterMultiLangEvents();
  useMatchCenterMultiLangUrl();

  useEffect(() => {
    dispatch(getLocalizedGames());
    dispatch(getMatchFeeds({ topLevel, secondLevel, thirdLevel, forthLevel, language }));

    return (): void => { dispatch(resetMatchCenter()); };
  }, []);
};

export const useMatchCenterByGameId = (gameId = ''): void => {
  const dispatch = useDispatch();
  const language = useLanguage();
  const result = useMatchResultsSelector();
  const { matchStatus } = useLiveMatchResultsSelector(result?.gameId ?? '');
  const {
    seasonId = '', optaId = '', gameOptaId = '', urlSlug = '',
  } = useGameByOptaIdSelector(gameId) ?? {};

  useEffect(() => {
    dispatch(getLocalizedGames());
    if (urlSlug) {
      dispatch(getMatchResults(seasonId, optaId, gameOptaId));
      dispatch(getMatchLineups(seasonId, optaId, gameOptaId));
      dispatch(getStandings({ seasonId, optaId, language }));
    }

    return (): void => { dispatch(resetMatchCenter()); };
  }, [gameId, urlSlug]);

  useEffect(() => {
    result?.gameId && !matchStatus && dispatch(getLiveMatchResults(result));
    const subscribe = result?.gameId && matchStatus !== MatchStatusMap.finished
      && setInterval(() => {
        dispatch(getLiveMatchResults(result));
        dispatch(getMatchLineups(seasonId, optaId, gameOptaId));
      }, 20000);

    return (): void => { subscribe && clearInterval(subscribe); };
  }, [result, matchStatus]);
};

export const useMatchCenterButton = (): void => {
  const dispatch = useDispatch();
  useEffect((): void => { dispatch(getLocalizedGames()); }, []);
};

export const useMatchCenterValidation = (): Errors => {
  const { forthLevel = '' } = useParams();
  const language = useLanguage();

  const networkError = useSelector<AppState, Errors>(
    (state) => state.matchCenter.error, shallowEqual,
  );
  const isGamesDownloadCompleted = useSelector<AppState, boolean>(
    (state) => state.matchCenter.isGamesDownloadCompleted, shallowEqual,
  );

  const isUrlSlugValid = Boolean(useGameByUrlSlugSelector());
  const isTabValid = [
    Translations[language]?.['matchcenter.tabs.lineups'].toLowerCase(),
    Translations[language]?.['matchcenter.tabs.statistics'].toLowerCase(),
    // TODO:ACTIVATE Translations[language]?.['matchcenter.tabs.standings'].toLowerCase(),
  ].includes(forthLevel);

  if (networkError) return networkError;
  return isGamesDownloadCompleted && (!isUrlSlugValid || !isTabValid) ? PageError.NotFound : null;
};
