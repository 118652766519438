import React from 'react';
import { ThroneAsset } from '@app/types/configurationTypes';

import { CarouselBase, CarouselItem } from '@app/components/Carousel/CarouselBase';
import GalleryCarouselItem from './GalleryCarouselItem';

const GalleryCarousel: React.FC<{ slides: ThroneAsset[] }> = ({ slides }) => {
  const carouselItems: CarouselItem[] = slides.map((slide) => ({
    item: slide,
    ItemComponent: GalleryCarouselItem,
  }));

  return (
    <CarouselBase
      items={carouselItems ?? []}
      withSlidesCount
      withDots={false}
      withFullWidthSlides={false}
    />
  );
};

export default GalleryCarousel;
