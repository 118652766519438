import {
  NewsList, LocalizedGalleryItem, BaseNewsItem, LocalizedNewsItem, LocalizedVideoItem, EditorialContentTypes,
} from '@app/types/newsTypes';
import { PageError } from '@app/types/errorTypes';

export enum NewsActionTypes {
  SET_NEWS_LIST = 'NEWS/SET_NEWS_LIST',
  RESET_NEWS_LIST = 'NEWS/RESET_NEWS_LIST',
  SET_NEWS_LOADING_STATE = 'NEWS/SET_NEWS_LOADING_STATE',
  SET_NEWS = 'NEWS/SET_NEWS',
  RESET_NEWS = 'NEWS/RESET_NEWS',
  SET_VIDEO = 'NEWS/SET_VIDEO',
  RESET_VIDEO = 'NEWS/RESET_VIDEO',
  SET_GALLERY = 'NEWS/SET_GALLERY',
  RESET_GALLERY = 'NEWS/RESET_GALLERY',
  SET_LATEST_GALLERY = 'NEWS/SET_LATEST_GALLERY',
  RESET_LATEST_GALLERY = 'NEWS/RESET_LATEST_GALLERY',
  SET_LATEST_NEWS = 'NEWS/SET_LATEST_NEWS',
  RESET_LATEST_NEWS = 'NEWS/RESET_LATEST_NEWS',
  SET_LATEST_VIDEO = 'NEWS/SET_LATEST_VIDEO',
  RESET_LATEST_VIDEO = 'NEWS/RESET_LATEST_VIDEO',
  SET_ERROR = 'NEWS/SET_ERROR',
  SET_DATA_LIST = 'NEWS/SET_DATA_LIST',
}

export interface SetDataList {
  type: NewsActionTypes.SET_DATA_LIST;
  payload: Partial<Record<EditorialContentTypes, Record<string, NewsList>>>;
}

export interface SetNewsList {
  type: NewsActionTypes.SET_NEWS_LIST;
  payload: NewsList;
}

export interface ResetNewsList {
  type: NewsActionTypes.RESET_NEWS_LIST;
}

export interface SetNewsLoadingState {
  type: NewsActionTypes.SET_NEWS_LOADING_STATE;
  payload: boolean;
}

export interface SetNews {
  type: NewsActionTypes.SET_NEWS;
  payload: LocalizedNewsItem;
}

export interface ResetNews {
  type: NewsActionTypes.RESET_NEWS;
}

export interface SetVideo{
  type: NewsActionTypes.SET_VIDEO;
  payload: LocalizedVideoItem;
}

export interface ResetVideo {
  type: NewsActionTypes.RESET_VIDEO;
}

export interface SetGallery {
  type: NewsActionTypes.SET_GALLERY;
  payload: LocalizedGalleryItem;
}

export interface ResetGallery {
  type: NewsActionTypes.RESET_GALLERY;
}

export interface SetLatestNews {
  type: NewsActionTypes.SET_LATEST_NEWS;
  payload: BaseNewsItem[];
}

export interface ResetLatestNews {
  type: NewsActionTypes.RESET_LATEST_NEWS;
}

export interface SetLatestVideo {
  type: NewsActionTypes.SET_LATEST_VIDEO;
  payload: BaseNewsItem[];
}

export interface ResetLatestVideo {
  type: NewsActionTypes.RESET_LATEST_VIDEO;
}

export interface SetLatestGallery {
  type: NewsActionTypes.SET_LATEST_GALLERY;
  payload: BaseNewsItem[];
}

export interface ResetLatestGallery {
  type: NewsActionTypes.RESET_LATEST_GALLERY;
}

export interface SetError {
  type: NewsActionTypes.SET_ERROR;
  payload: PageError;
}

export type NewsActions = SetNewsList
  | ResetNewsList
  | SetNewsLoadingState
  | SetNews
  | ResetNews
  | SetVideo
  | ResetVideo
  | SetGallery
  | ResetGallery
  | SetLatestGallery
  | ResetLatestGallery
  | SetLatestVideo
  | ResetLatestVideo
  | SetLatestNews
  | ResetLatestNews
  | SetError
  | SetDataList;
