import React from 'react';

import { WebTemplateData, WebTemplateTypes } from '@app/types/webTemplateTypes';
import WebGoogleMaps from '@app/components/WebGoogleMaps/WebGoogleMaps';

import { InviolaPromotionItem } from '@app/components/Inviola';
import { ContainerMedium } from '@app/components/WebTemplates/components';
import {
  WebCardElement,
  WebDepartmentElement,
  WebLandingElement,
  WebPageImageElement,
  WebPageTitleElement,
  WebShareElement,
  WebSponsor,
  WebCtaButtonElement,
  WebCtaDuoButtonElement,
  WebEditorialElement,
  WebEditorialDuoElement,
  WebPersonElement,
  WebPageVideoElement,
  WebTabsElement,
  WebIframeElement,
  WebPDFElement,
  WebCtaQuatroElement,
  WebLiveVideoElement,
  WebAdvertisementBannerElement,
  WebMatchbox,
  WebInviolaMembershipCardElement,
  WebInviolaCatalogItemElement,
  WebLineCarouselElement, WebEmbeddedElement,
} from './elements';

interface WebTemplateSelectProps {
  data: WebTemplateData;
}

const WebTemplateSelect = ({ data }: WebTemplateSelectProps): React.ReactElement | null => {
  switch (data?.type) {
    case WebTemplateTypes.WebPageImage:
      return (<WebPageImageElement data={data} />);
    case WebTemplateTypes.WebPageVideo:
      return (<WebPageVideoElement data={data} />);
    case WebTemplateTypes.WebPageTitle:
      return (<WebPageTitleElement data={data} />);
    case WebTemplateTypes.WebLanding:
      return (<WebLandingElement data={data} />);
    case WebTemplateTypes.WebCard:
      return (<WebCardElement data={data} />);
    case WebTemplateTypes.WebShare:
      return (<WebShareElement />);
    case WebTemplateTypes.WebDepartment:
      return (<WebDepartmentElement data={data} />);
    case WebTemplateTypes.WebPartner:
      return (<WebSponsor data={data} />);
    case WebTemplateTypes.WebEditorial:
      return (<WebEditorialElement content={data.content} />);
    case WebTemplateTypes.WebEditorialDuo:
      return (<WebEditorialDuoElement data={data} />);
    case WebTemplateTypes.WebCtaButton:
      return (
        <ContainerMedium>
          <WebCtaButtonElement data={data} />
        </ContainerMedium>
      );
    case WebTemplateTypes.WebCtaDuoButton:
      return (
        <ContainerMedium>
          <WebCtaDuoButtonElement data={data} />
        </ContainerMedium>
      );
    case WebTemplateTypes.WebGoogleMaps:
      return (<WebGoogleMaps data={data} />);
    case WebTemplateTypes.WebPerson:
      return (<WebPersonElement data={data} />);
    case WebTemplateTypes.WebTabs:
      return (<WebTabsElement data={data} />);
    case WebTemplateTypes.WebIframe:
      return (<WebIframeElement data={data} />);
    case WebTemplateTypes.PDF:
      return (<WebPDFElement data={data} />);
    case WebTemplateTypes.WebCtaButtonQuatro:
      return (<WebCtaQuatroElement data={data} />);
    case WebTemplateTypes.WebLiveVideo:
      return (<WebLiveVideoElement data={data} />);
    case WebTemplateTypes.WebAdvertisementBanner:
      return (<WebAdvertisementBannerElement data={data} isMediumSize />);
    case WebTemplateTypes.WebMatchbox:
      return (<WebMatchbox data={data} />);
    case WebTemplateTypes.WebInviolaMembershipCard:
      return (<WebInviolaMembershipCardElement data={data} />);
    case WebTemplateTypes.WebInviolaCatalogItem:
      return (<WebInviolaCatalogItemElement data={data} />);
    case WebTemplateTypes.WebLineCarousel:
      return (<WebLineCarouselElement data={data} />);
    case WebTemplateTypes.Promotion:
      return (<InviolaPromotionItem data={data} />);
    case WebTemplateTypes.WebEmbedded:
      return (<WebEmbeddedElement data={data} />);
    default:
      return null;
  }
};

export default WebTemplateSelect;
