import React from 'react';
import styled from 'styled-components';
import { Devices } from '@app/styles';

const Sorry: React.FC = () => (
  <Container>
    <LargeText>SORRY!</LargeText>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-weight: bold;
  color: ${(props): string => props.theme.color.purple};
`;

const LargeText = styled.p`
  font-size: 62px;

  @media ${Devices.tablet} {
    font-size: 80px;
  }
`;

export default Sorry;
