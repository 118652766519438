import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Description } from '@app/components/Inviola/InviolaComponents';

const RegistrationSuccess = (): React.ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Description>{formatMessage({ id: 'inviola.registrationSuccess.description' })}</Description>

      <SuccessMessage>{formatMessage({ id: 'inviola.registrationSuccess.message' })}</SuccessMessage>
    </div>
  );
};

const SuccessMessage = styled.div`
  margin-bottom: 30px;
`;

export default RegistrationSuccess;
