import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Errors, PageError } from '@app/types/errorTypes';
import AppState from '@app/types/appStateTypes';
import { PageType, WebTabItemTemplateElement, WebTemplateData } from '@app/types/webTemplateTypes';
import { useParams } from 'react-router-dom';
import {
  useLastLevelMenuId,
  useSecondLevelMenu,
  useThirdLevelMenu,
  useTopLevelMenu,
} from '@app/pages/CommonPage/CommonPageHooks';
import { getCurrentLocalizedTab, getPageType, getWebTemplateData } from '@app/helpers/webTemplateHelpers';
import { useLanguage } from '@app/components/Hooks';
import { useContext, useEffect } from 'react';
import { getWebTemplateDataById, setWebTemplatePageMultiLangRoute } from '@app/store/actions/webTemplateActions';
import { RouteParams } from '@app/types/routerTypes';
import { useIsConfigurationDownloadCompleted } from '@app/components/Hooks/ConfigurationHooks';
import { MountContext } from '@app/ReactContext';

export function useWebTemplateError(): PageError | null {
  return useSelector<AppState, PageError | null>(
    (state) => state.webTemplate.error,
    shallowEqual,
  );
}

export function useWebTemplateLoading(): boolean {
  return useSelector<AppState, boolean>(
    (state) => state.webTemplate.isLoading,
    shallowEqual,
  );
}

export const usePageType = (): PageType => {
  const { tab = '' } = useParams();
  const secondLevelMenu = useSecondLevelMenu();
  return getPageType(secondLevelMenu, tab) || PageType.Template;
};

export function useWebTemplateMultiLangUrl(): void {
  const dispatch = useDispatch();
  const {
    topLevel = '', secondLevel = '', thirdLevel = '',
  } = useParams();
  const language = useLanguage();
  const topLevelMenu = useTopLevelMenu();

  useEffect(() => {
    if (topLevelMenu) {
      dispatch(setWebTemplatePageMultiLangRoute({
        topLevel,
        secondLevel,
        thirdLevel,
        language,
      }));
    }
  }, [topLevelMenu, secondLevel, thirdLevel]);
}

export function useWebTemplateData(): WebTemplateData[] | null {
  const language = useLanguage();
  const params: RouteParams = useParams();

  return useSelector<AppState, WebTemplateData[] | null>(
    (state) => getWebTemplateData({ state, language, params }),
    shallowEqual,
  );
}

export function useWebTemplatePageValidation(): Errors {
  const { thirdLevel = '', fouthLevel = '' } = useParams();
  const thirdLevelMenu = useThirdLevelMenu();
  const error = useWebTemplateError();

  const isLoading = useWebTemplateLoading();
  const isConfigurationDownloadCompleted = useIsConfigurationDownloadCompleted();
  const currentTab = useCurrentTab();

  if (!isLoading && error) {
    return error;
  }

  const isThirdLevelIncorrect = !!thirdLevel && !fouthLevel && (!thirdLevelMenu && !currentTab);
  const isFourthLevelIncorrect = fouthLevel && !currentTab;

  // 1. Check whether third level route path correct or check tab route on third level if tabs available
  // 2. Check whether tab route on forth level if tabs available
  return !isLoading && isConfigurationDownloadCompleted
    && (isThirdLevelIncorrect || isFourthLevelIncorrect) ? PageError.NotFound : null;
}

export function useWebTemplateDownload(): void {
  const { isInitialMount } = useContext(MountContext);
  const {
    topLevel = '', secondLevel = '', thirdLevel = '',
  } = useParams();
  const language = useLanguage();
  const lastLevelMenuId = useLastLevelMenuId();
  const dispatch = useDispatch();

  const hasTemplateItems = useSelector<AppState, boolean>(
    (state) => !!state.webTemplate.templates[lastLevelMenuId ?? '']?.[language],
    shallowEqual,
  );

  useEffect(() => {
    if (!isInitialMount || !hasTemplateItems) {
      // check if data has been loaded on server before very first render
      !hasTemplateItems && dispatch(getWebTemplateDataById({
        id: lastLevelMenuId ?? '', language,
      }));
    }
  }, [topLevel, secondLevel, thirdLevel, lastLevelMenuId, language]);
}

export function useCurrentTab(): WebTabItemTemplateElement | null {
  const language = useLanguage();
  const params: RouteParams = useParams();

  return useSelector<AppState, WebTabItemTemplateElement | null>(
    (state) => getCurrentLocalizedTab({ state, language, params })[language] ?? null,
    shallowEqual,
  );
}
