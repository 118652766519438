import React from 'react';

import {
  DefenderStatistics, ForwardStatistics, GoalkeeperStatistics, MidfielderStatistics, Player,
  PlayerPosition, PlayerStatistics,
} from '@app/types/teamsTypes';
import { usePlayer, usePlayerStatistics, useTeamStatistics } from '@app/pages/PlayerProfile/PlayersHooks';
import {
  DefenderLayoutStatistics, ForwardLayoutStatistics, GoalkeeperLayoutStatistics, MidfielderLayoutStatistics,
} from './PlayerPositionStatistics';
import { PerformanceStatistics } from './PlayerPositionStatistics/StatisticsSections';

interface PositionComponentProps {
  statistics?: PlayerStatistics;
  player?: Player;
}

const PositionComponent = ({
  statistics, player,
}: PositionComponentProps): React.ReactElement | null => {
  switch (player?.position) {
    case PlayerPosition.Goalkeeper:
      return (<GoalkeeperLayoutStatistics statistics={statistics as GoalkeeperStatistics} />);
    case PlayerPosition.Defender:
      return (<DefenderLayoutStatistics statistics={statistics as DefenderStatistics} />);
    case PlayerPosition.Forward:
      return (<ForwardLayoutStatistics statistics={statistics as ForwardStatistics} />);
    case PlayerPosition.Midfielder:
      return (<MidfielderLayoutStatistics statistics={statistics as MidfielderStatistics} />);
    default:
      return null;
  }
};

const PlayerCommonStatistics = (): React.ReactElement => {
  const teamStatistics = useTeamStatistics();
  const playerStatistics = usePlayerStatistics() as PlayerStatistics;
  const player = usePlayer();

  return (
    <>
      {playerStatistics && (
        <>
          <PerformanceStatistics statistics={playerStatistics} teamStatistics={teamStatistics} />
          <PositionComponent statistics={playerStatistics} player={player} />
        </>
      )}
    </>
  );
};

export default PlayerCommonStatistics;
