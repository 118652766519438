import { isArray } from 'lodash';

import { InviolaCustomerDynamicFields } from '@app/services/inviola/apiConstants';
import {
  InviolaCustomer,
  InviolaCardMovement,
  InviolaShop,
  InviolaCatalogItem,
  InviolaCategory,
  InviolaAward,
} from '@app/types/inviolaTypes';
import { WebTemplateInviolaCatalogItem, WebTemplateTypes } from '@app/types/webTemplateTypes';
import {
  LoginCredentials,
  CustomerInformation,
  ProxyCustomerInformation,
  RegistrationCredentials,
} from './types/requestTypes';
import {
  InviolaDynamicFieldRepresentations,
  InviolaDynamicFields,
  InviolaRawAwards,
  InviolaRawCatalogItem,
  InviolaRawCategory,
  InviolaRawCustomer,
  InviolaRawDynamicField,
  InviolaRawShop,
  InviolaCountry,
} from './types/responseTypes';

const InviolaAssetsUrl = process.env.INVIOLA_ASSETS_URL;

export const getInviolaCustomer = (customer: InviolaRawCustomer): InviolaCustomer => ({
  id: customer.id,
  userName: customer.userName,
  gender: customer.gender,
  address: customer.address,
  addressNumber: customer.addressNumber,
  zip: customer.zip,
  identityCard: customer.identityCard,

  firstName: customer.name,
  lastName: customer.surname,

  cardNumber: customer.card,
  fidelyCode: customer.fidelyCode,
  expirationDate: customer.expiration.substr(0, 10),

  balancePoints: customer.balance_points,
  email: customer.mailContactData,
  mobile: customer.mobileContactData,

  birthDate: customer.birthdate.substr(0, 10),

  country: customer.country.toString(),
  geoLevel1: customer.geoLevel1.toString(),
  geoLevel2: customer.geoLevel2.toString(),
  geoLevel3: customer.geoLevel3.toString(),
  geoLevel4: customer.geoLevel4.toString(),
  geoLevel5: customer.geoLevel5.toString(),

  latitude: customer.geo_lat,
  longitude: customer.geo_long,

  usedForPromotions: customer.privacy.usedForPromotions,
  usedForStatistics: customer.privacy.usedForStatistics,

  customerDynamicFields: getInviolaCustomerDynamicFields(customer.customerDynamicFields),
});

export const getInviolaRegistrationCardCustomer = (id = 0, customer: InviolaRawCustomer): InviolaCustomer => ({
  id,
  userName: customer.userName,
  gender: customer.gender,
  address: customer.address,
  addressNumber: customer.addressNumber,
  zip: customer.zip,
  identityCard: customer.identityCard,

  firstName: customer.name,
  lastName: customer.surname,

  cardNumber: '',
  fidelyCode: '',
  expirationDate: '',

  balancePoints: 0,
  email: customer.mailContactData,
  mobile: customer.mobileContactData,

  birthDate: customer.birthdate.substr(0, 10),

  country: customer.country.toString(),
  geoLevel1: customer.geoLevel1.toString(),
  geoLevel2: customer.geoLevel2.toString(),
  geoLevel3: customer.geoLevel3.toString(),
  geoLevel4: customer.geoLevel4.toString(),
  geoLevel5: customer.geoLevel5.toString(),

  latitude: 0,
  longitude: 0,

  usedForPromotions: customer.privacy.usedForPromotions,
  usedForStatistics: customer.privacy.usedForStatistics,

  customerDynamicFields: getInviolaCustomerDynamicFields(customer.customerDynamicFields),
});

export const getCustomerInformation = (customer: InviolaCustomer): CustomerInformation => ({
  address: customer.address,
  addressNumber: customer.addressNumber,
  email: customer.email,
  gender: customer.gender,
  mobile: customer.mobile,

  name: customer.firstName,
  surname: customer.lastName,
  birthdate: customer.birthDate,
  zipcode: customer.zip,
  dni: customer.identityCard,

  country: customer.country?.toString(),
  geo_level_1: customer.geoLevel1?.toString(),
  geo_level_2: customer.geoLevel2?.toString(),
  geo_level_3: customer.geoLevel3?.toString(),
  geo_level_4: customer.geoLevel4?.toString(),
  geo_level_5: customer.geoLevel5?.toString(),

  usedForPromotions: customer.usedForPromotions ? '1' : '0',
  usedForStatistics: customer.usedForStatistics ? '1' : '0',

  customerdynamicfields: customer.formDynamicFields
    ? getFormDynamicFields(customer.formDynamicFields)
    : getCustomerDynamicFields(customer.customerDynamicFields),
});

export const getProxyCustomerInformation = (customer?: Partial<RegistrationCredentials>): ProxyCustomerInformation => ({
  address: customer?.address,
  addressnumber: customer?.addressNumber,
  username: customer?.email ?? '',
  gender: customer?.gender,
  mobilecontactdata: customer?.mobile,

  name: customer?.name ?? '',
  surname: customer?.surname ?? '',
  birthdate: customer?.birthdate,
  zip: customer?.zipcode,
  identitycard: customer?.dni,

  country: customer?.country?.toString(),
  // eslint-disable-next-line camelcase
  geolevel1: customer?.geo_level_1?.toString(),
  // eslint-disable-next-line camelcase
  geolevel2: customer?.geo_level_2?.toString(),
  // eslint-disable-next-line camelcase
  geolevel3: customer?.geo_level_3?.toString(),
  // eslint-disable-next-line camelcase
  geolevel4: customer?.geo_level_4?.toString(),
  // eslint-disable-next-line camelcase
  geolevel5: customer?.geo_level_5?.toString(),

  usedforpromotions: ['1', 'true'].includes((customer?.usedForPromotions ?? '').toString().toLowerCase()) ? '1' : '0',
  usedforstatistics: ['1', 'true'].includes((customer?.usedForStatistics ?? '').toString().toLowerCase()) ? '1' : '0',

  customerdynamicfields: customer?.customerdynamicfields,
});

const convertDynamicFieldValue = (
  id: string | number, value: string | number | boolean,
): string | number | boolean => {
  const checkBoxValue = ['1', 'true'].includes(value?.toString().toLowerCase()) ? '1' : '0';
  return InviolaCustomerDynamicFields.AcceptanceOfRegulations === +id ? checkBoxValue : value;
};

const getFormDynamicFields = (fields: InviolaCustomer['formDynamicFields']): string => {
  if (!fields) return '[]';

  const fieldsList = Object.keys(fields)
    .map((key) => ({
      id: fields[key].id,
      value: convertDynamicFieldValue(fields[key].id, fields[key].value),
    }));

  return JSON.stringify(fieldsList);
};

const getCustomerDynamicFields = (fields: InviolaCustomer['customerDynamicFields']): string => {
  if (!fields) return '[]';

  const fieldsList = (isArray(fields) ? fields : [fields])
    .map((field) => ({
      id: field.id,
      value: convertDynamicFieldValue(field.id, field.value),
    }))
    .filter((field) => field.value !== '');

  return JSON.stringify(fieldsList);
};

const getInviolaCustomerDynamicFields = (
  fields: InviolaRawCustomer['customerDynamicFields'],
): InviolaRawCustomer['customerDynamicFields'] => {
  if (!fields) return [];

  return (isArray(fields) ? fields : [fields]).map((field) => ({ ...field,
    value: InviolaCustomerDynamicFields.AcceptanceOfRegulations === +field.id
      // if dynamic field is checkbox -> transform string to boolean form value
      ? ['1', 'true'].includes(`${field.value}`.toLowerCase())
      // else return default field value
      : field.value,
  }));
};

export const getInviolaCardMovements = (
  movements: InviolaCardMovement | InviolaCardMovement[],
): InviolaCardMovement[] => (isArray(movements) ? movements : [movements])
  .filter((movement: InviolaCardMovement) => {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 1);
    // Displaying only last year movement of points
    return new Date(movement.localTime).getTime() > oneYearFromNow.getTime();
  })
  .map((movement: InviolaCardMovement): InviolaCardMovement => ({
    id: movement.id,
    localTime: new Date(movement.localTime).toLocaleString('en-GB'),
    kind: movement.kind,
    chargedPoints: movement.chargedPoints,
    dischargedPoints: movement.dischargedPoints,
    totalMoney: movement.totalMoney,
    chargedCredits: movement.chargedCredits,
    dischargedCredits: movement.dischargedCredits,
    chargedGiftCredits: movement.chargedGiftCredits,
    dischargedGiftCredits: movement.dischargedGiftCredits,
    totalBenefits: movement.totalBenefits,

    shopName: movement.shop?.name,
    currencySymbol: movement.shop?.currencySymbol,
  }));

export const getDynamicFields = (
  fields: InviolaRawDynamicField[], countries: InviolaCountry[],
): InviolaDynamicFields => {
  const dynamicFieldsMap: InviolaDynamicFields = {};

  fields.forEach((field) => {
    const isNationalityField = field.id === InviolaCustomerDynamicFields.Nationality;

    dynamicFieldsMap[field.id] = {
      id: field.id,
      name: field.name,
      order: field.order,

      representation: isNationalityField ? InviolaDynamicFieldRepresentations.Select : field.representation,

      type: field.type.dataType,

      enabled: field.flags.isEnabled,
      mandatory: field.flags.isMandatory,
      visible: field.flags.isVisible,

      options: isNationalityField
        ? countries?.map(({ name }) => ({ id: name.toUpperCase(), name: name.toUpperCase() }))
        : field.options?.map((option) => ({ id: option.id, name: option.value })),
    };
  });

  return dynamicFieldsMap;
};

export const getInviolaShops = (shops: InviolaRawShop[]): InviolaShop[] => shops.map((rawShop) => {
  const addressParts = buildShopAddress(rawShop);

  return {
    id: rawShop.id,
    name: rawShop.name,

    shortAddress: addressParts.short,
    fullAddress: addressParts.long,

    distance: rawShop.distance_txt,
    pointsWeight: rawShop?.defaultWeighing ? {
      point: rawShop.defaultWeighing.weightChargePointPoints,
      money: rawShop.defaultWeighing.weightChargePointMoney,
    } : null,

    mail: rawShop.mail,
    phone: rawShop.telephone,
    mobile: rawShop.contactMobile,

    contactName: rawShop.contactName?.toUpperCase().includes('HIDDEN') ? '' : rawShop.contactName,

    lat: rawShop.geoLat,
    lng: rawShop.geoLong,

    imageURL: rawShop.imageURL,
  };
});

export const getInviolaCategory = (item: InviolaRawCategory): InviolaCategory => ({
  id: item.id,
  description: item.description,
  fatherId: item.fatherId,
});

export const getInviolaCatalogItems = (items: InviolaRawCatalogItem[]): InviolaCatalogItem[] => {
  const converted = items?.map(
    (rawItem) => ({
      id: rawItem.id,
      name: rawItem.name,
      category: rawItem.category ? getInviolaCategory(rawItem.category) : {
        id: 0,
        description: '-',
        fatherId: 0,
      },
      description: rawItem.description,
      flags: {
        enabled: rawItem.flags.enabled,
      },
      idCatalog: rawItem.idCatalog,
      issueDate: rawItem.issueDate,
      pathImageAbsolute: rawItem.pathImageAbsolute,
      points: rawItem.points,
      prizeCode: rawItem.prizeCode,
    })) ?? [];
  return converted.sort((itemA, itemB) => {
    const sortA = itemA.points;
    const sortB = itemB.points;
    if (sortA < sortB) {
      return -1;
    }
    if (sortA > sortB) {
      return 1;
    }
    return 0;
  });
};

export const mapWebTemplateInviolaCatalogItems = (
  items: InviolaCatalogItem[],
): WebTemplateInviolaCatalogItem[] => items.map((item) => ({
  id: item?.id?.toString() || '',
  type: WebTemplateTypes.WebInviolaCatalogItem,
  item,
}));

const buildShopAddress = (rawShop: InviolaRawShop) => {
  const number = rawShop.addressNumber ?? '';
  const prefix = rawShop.addressPrefix ? ` ${rawShop.addressPrefix}` : '';
  const address = rawShop.address ? ` ${rawShop.address}` : '';
  const zip = rawShop.zip ? ` ${rawShop.zip}` : '';
  const geoLevel2name = rawShop.geoLevel2name ? ` ${rawShop.geoLevel2name}` : '';
  const geoLevel1name = rawShop.geoLevel1name ? `, ${rawShop.geoLevel1name}` : '';

  const short = number + prefix + address;
  const long = `${short},${zip}${geoLevel2name}${geoLevel1name}`;

  return {
    short,
    long,
  };
};

export const formatLoginCredentials = (creds: LoginCredentials): LoginCredentials => ({
  ...creds,
  rememberme: '1',
});

export const getInviolaAwards = (rawAwards: InviolaRawAwards): InviolaAward[] => {
  let customerPrizes = rawAwards.prizes;

  if (!isArray(customerPrizes)) {
    customerPrizes = [customerPrizes];
  }

  return customerPrizes.filter((prize) => Boolean(prize)).map((prize) => {
    const exchangeDateString = new Date(prize?.prize?.exchangeDate?.slice(0, -6));

    return {
      id: prize?.id ?? '',
      name: prize?.prize?.name ?? '',
      barcode: prize?.code ?? '',

      points: prize?.prize?.points ?? 0,
      quantity: prize?.prize?.quantity ?? 0,

      exchangeDate: exchangeDateString.toLocaleDateString(),

      pathImageAbsolute: `${InviolaAssetsUrl}/${prize?.prize?.prize?.pathImage}`,
    };
  });
};
