import React from 'react';
import styled from 'styled-components';

import { ContainerLarge } from '@app/components/WebTemplates/components';
import { WebTemplatePartner } from '@app/types/webTemplateTypes';
import { Devices } from '@app/styles';
import { useIsPartnersVisible, usePartnersBottomData } from './PartnersHooks';

const Partners = (): React.ReactElement => {
  const partners = usePartnersBottomData();
  const isPartnersVisible = useIsPartnersVisible();

  return (
    <>
      {isPartnersVisible && (
      <Background>
        <ContainerLarge>
          <Section>
            <Title>{partners?.title}</Title>
            {partners?.content?.map((partnerList) => partnerList !== null && (
              <Container key={partnerList.id}>
                <PartnerRow>
                  {partnerList.content.map((partner: WebTemplatePartner) => (
                    <Partner key={partner.id} href={partner.redirectUrl} target="_blank" rel="noopener noreferrer">
                      {partner.photo && (<Logo src={partner.photo.url} alt={partner.photo.description} />)}
                    </Partner>
                  ))}
                </PartnerRow>
                <Divider />
              </Container>
            ))}
          </Section>
        </ContainerLarge>
      </Background>
      )}
    </>
  );
};

const Background = styled.div`
  background-color: #ffffff;
  padding-bottom: 80px;

  @media ${Devices.tablet} {
    padding-bottom: 120px;
  }
`;

const Section = styled.section`
  padding: 20px 10px;

  @media ${Devices.mediumMobile} {
    padding: 20px;
  }

  @media ${Devices.tablet} {
    padding: 40px;
  }

  @media ${Devices.desktopSmall} {
    padding: 50px 70px;
  }
`;

const Title = styled.h2`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.21px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props): string => props.theme.color.purple};
  padding: 30px 0 15px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.1;
  border-bottom: solid 1px #000000;
  margin: 10px auto;

  @media ${Devices.tablet} {
    width: 505px;
    margin: 10px auto;
  }
`;

const Container = styled.div`
  --logo-width: 95px;
  --logo-height: 95px;

  &:first-of-type {
    --logo-width: 185px;
    --logo-height: 105px;
  }

  @media ${Devices.desktop} {
    --logo-width: 105px;
    --logo-height: 105px;

    &:first-of-type {
      --logo-width: 255px;
      --logo-height: 125px;
    }
  }

  &:last-of-type ${Divider} {
    display: none;
  }
`;

const PartnerRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const Partner = styled.a`
  width: var(--logo-width);
  height: var(--logo-height);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
`;

const Logo = styled.img`
  max-width: 90%;
  max-height: 90%;
`;

export default Partners;
