import { ContentItem, Elements } from '@kentico/kontent-delivery';

export class WebLanding extends ContentItem {
    public seoMetadataSocialDescription: Elements.TextElement;
    public seoMetadataMetaRobots: Elements.MultipleChoiceElement;
    public isVisible: Elements.MultipleChoiceElement;
    public id: Elements.TextElement;
    public content: Elements.LinkedItemsElement<ContentItem>;
    public seoMetadataCanonicalTag: Elements.TextElement;
    public seoMetadataMetaDescription: Elements.TextElement;
    public webUrl: Elements.UrlSlugElement;
    public seoMetadataSocialImage: Elements.CustomElement;
    public seoMetadataMetaTitle: Elements.TextElement;
    public name: Elements.TextElement;
    public seoMetadataSocialTitle: Elements.TextElement;
    public size: Elements.MultipleChoiceElement;
    public type: Elements.MultipleChoiceElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'seo_metadata__social_description') {
                    return 'seoMetadataSocialDescription';
                }
                if (elementName === 'seo_metadata__meta_robots') {
                    return 'seoMetadataMetaRobots';
                }
                if (elementName === 'is_visible') {
                    return 'isVisible';
                }
                if (elementName === 'seo_metadata__canonical_tag') {
                    return 'seoMetadataCanonicalTag';
                }
                if (elementName === 'seo_metadata__meta_description') {
                    return 'seoMetadataMetaDescription';
                }
                if (elementName === 'web_url') {
                    return 'webUrl';
                }
                if (elementName === 'seo_metadata__social_image') {
                    return 'seoMetadataSocialImage';
                }
                if (elementName === 'seo_metadata__meta_title') {
                    return 'seoMetadataMetaTitle';
                }
                if (elementName === 'seo_metadata__social_title') {
                    return 'seoMetadataSocialTitle';
                }
                return elementName;
            })
        });
    }
}
