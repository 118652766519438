import { InviolaCountries } from '@app/services/inviola/apiConstants';
import { isServer } from '@app/helpers/serverHelpers';

export const InviolaDefaultCountryId = InviolaCountries.Italia;
export const InviolaAllowedCountryIds = [
  InviolaDefaultCountryId.toString(),
  InviolaCountries.Germany.toString(),
  InviolaCountries.Albania.toString(),
  InviolaCountries.Spain.toString(),
];

// Default location to Florence, Italy
export const InviolaShopsMapDefaults = {
  center: { lat: 43.7696, lng: 11.2558 },
  zoom: 10,
};

const inViolaUsersAgeLimit = new Date();
inViolaUsersAgeLimit.setFullYear(inViolaUsersAgeLimit.getFullYear() - 14);

export const InviolaDates = {
  min: new Date(1900, 1, 1).toISOString().slice(0, 10),
  max: inViolaUsersAgeLimit.toISOString().slice(0, 10),
};

export const InviolaFlags = {
  ShowSearch: false,
  ShowProfile: process.env.API === 'staging' && !isServer(),
  ShowProfileExtras: true,
};

export const InviolaSessionLifetime = 14;

export enum InviolaSpecificErrorCodes {
  InvalidActivationCode = 312, // user without Card
  InvalidVerificationCode = 315, // user with Card
  InvalidPassword = 355, // any new user
}

export const InViolaLocationsPrepositions = [
  /** COUNTY NAME'S APOSTROPHE */
  'of', 'and',
  /** PLACES */
  'del', 'dello', 'della', 'dei', 'degli', 'delle', 'al', 'allo', 'alla', 'ai', 'agli', 'alle', 'sul',
  'sullo', 'sulla', 'sui', 'sugli', 'sulle', 'dal', 'dallo', 'dalla', 'dai', 'dagli', 'dalle', 'nel',
  'nello', 'nella', 'nei', 'negli', 'nelle', 'di', 'a', 'su', 'da', 'in', 'an', 'der', 'la', 'e',
  /** APOSTROPHE */
  'dell', 'nell', 'all', 'dall', 'sull', 'd', 'ne', 'de',
];
