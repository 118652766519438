import React from 'react';
import styled from 'styled-components';
import { WebTempalteCtaButtonQuatro } from '@app/types/webTemplateTypes';
import { WebCtaButtonElement } from '@app/components/WebTemplates/elements/index';
import { ContainerMedium } from '@app/components/WebTemplates/components';
import { Devices } from '@app/styles';

interface WebCtaQuatroParamsProps {
  data: WebTempalteCtaButtonQuatro;
}

const WebCtaQuatroElement = ({ data }: WebCtaQuatroParamsProps): React.ReactElement => (
  <ContainerMedium>
    <Container>
      { data?.buttons.map((el) => (
        <Button key={el.id}>
          <WebCtaButtonElement data={el} />
        </Button>
      )) }
    </Container>
  </ContainerMedium>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px 0;

  @media ${Devices.desktop} {
    flex-wrap: nowrap;
  }
`;

const Button = styled.div`
  width: 100%;

  @media ${Devices.mediumMobile} {
    width: 48%;
  }

  @media ${Devices.tabletLarge} {
    width: 24%;
  }

  & button {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export default WebCtaQuatroElement;
