import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { Modal } from '@app/components';
import { Button, TextError } from '@app/components/Inviola/InviolaComponents';

import { Input } from '@app/components/Inviola/InviolaFields';
import { InviolaErrorMessage } from '@app/components/Inviola/InviolaMessages';
import {
  useSuccess, useInputValidations, useChangePasswordInProgressSelector,
} from '@app/components/Inviola/InviolaHooks';
import { ChangePasswordParams } from '@app/services/inviola/types/requestTypes';
import { changeCustomerPassword } from '@app/store/actions/inviolaActions/customer';
import { InviolaAppSuccess, InviolaAppError } from '@app/services/inviola/types/errorTypes';

interface ChangePasswordModalProps {
  open: boolean;
  onClose: () => void;
}

interface ChangePasswordSuccessProps {
  onClose: () => void;
}

export const ChangePasswordModal = (
  { open, onClose }: ChangePasswordModalProps,
): React.ReactElement => {
  const formSuccess = useSuccess();
  const changePasswordInProgress = useChangePasswordInProgressSelector();
  const passwordChanged = formSuccess && formSuccess === InviolaAppSuccess.PasswordChanged;

  return (
    <Modal open={open} onClose={onClose} contentFullWidth={false} disableClose={changePasswordInProgress}>
      <ModalContainer>
        { passwordChanged ? <ChangePasswordSuccess onClose={onClose} /> : <ChangePasswordForm /> }
      </ModalContainer>
    </Modal>
  );
};

export const ChangePasswordForm = (): React.ReactElement => {
  const methods = useForm<ChangePasswordParams>({ mode: 'onChange' });
  const { errors, handleSubmit, formState: { isValid }, watch } = methods;

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const validations = useInputValidations();

  const submit = (data: ChangePasswordParams) => {
    dispatch(changeCustomerPassword(data));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)}>
        <Input type="password" labelId="inviola.resetConfirmation.newpassword" name="password" validations={validations.required} />

        <Input
          type="password"
          name="confirmpassword"
          labelId="inviola.resetConfirmation.confirmpassword"
          validations={{
            required: true,
            validate: (value: string) => (value === watch('password')),
          }}
        />
        { errors?.confirmpassword && <TextError>{formatMessage({ id: 'inviola.form.requiredAndEqual' })}</TextError> }

        <InviolaErrorMessage errorType={InviolaAppError.ChangePasswordError} />

        <Button disabled={!isValid} type="submit">{formatMessage({ id: 'inviola.settings.submit' })}</Button>
      </form>
    </FormProvider>
  );
};

export const ChangePasswordSuccess = ({ onClose }: ChangePasswordSuccessProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <p>{formatMessage({ id: 'inviola.settings.changePassword.success' })}</p>

      <Button type="button" onClick={onClose}>
        {formatMessage({ id: 'inviola.settings.changePassword.close' })}
      </Button>
    </div>
  );
};

const ModalContainer = styled.div`
  width: 100vw;
  max-width: 95vw;
  margin: auto;
  padding: 40px 20px 20px;

  background-color: ${(props): string => props.theme.color.whiteSmoke};
  color: black;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  text-align: left;

  button {
    margin-bottom: 0;
  }

  @media ${Devices.tablet} {
    width: 450px;
    max-width: 600px;
    padding: 60px 20px 40px;
  }
`;
