import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Button, Description, Container, Row, Column } from '@app/components/Inviola/InviolaComponents';
import { Input } from '@app/components/Inviola/InviolaFields';
import { useInputValidations, useSyncInProgressSelector } from '@app/components/Inviola/InviolaHooks';
import { InviolaErrorMessage } from '@app/components/Inviola/InviolaMessages';
import { InviolaAppError } from '@app/services/inviola/types/errorTypes';
import { validateExistingCard } from '@app/store/actions/inviolaActions';
import { InviolaCardRegistration, InviolaCustomer } from '@app/types/inviolaTypes';

const RegistrationWithCard = (): React.ReactElement => {
  const methods = useForm<InviolaCustomer>({ mode: 'onChange' });
  const { handleSubmit, formState } = methods;

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const validations = useInputValidations();
  const syncInProgress = useSyncInProgressSelector();

  const { isValid } = formState;

  const submit = (data: InviolaCardRegistration) => {
    dispatch(validateExistingCard(data.cardNumber));
  };

  return (
    <FormProvider {...methods}>
      <Description>{formatMessage({ id: 'inviola.registrationWithCard.description' })}</Description>

      <form onSubmit={handleSubmit(submit)}>
        <Container>
          <Row>
            <Column>
              <Input
                name="cardNumber"
                labelId="inviola.registrationWithCard.cardNumber"
                placeholderId="inviola.form.cardNumber.placeholder"
                validations={validations.card}
              />
            </Column>
          </Row>
        </Container>

        <InviolaErrorMessage errorType={InviolaAppError.RegistrationFormError} />

        <Button type="submit" disabled={!isValid || syncInProgress}>
          {formatMessage({ id: 'inviola.registration.submit' })}
        </Button>
      </form>
    </FormProvider>
  );
};

export default RegistrationWithCard;
