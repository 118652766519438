import React from 'react';
import { useIntl } from 'react-intl';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { InviolaAppError } from '@app/services/inviola/types/errorTypes';
import { ResetCredentials } from '@app/services/inviola/types/requestTypes';

import { resetCustomerPassword } from '@app/store/actions/inviolaActions';

import { Button, CenteredBlock } from '@app/components/Inviola/InviolaComponents';
import { useInputValidations, useInviolaPath } from '@app/components/Inviola/InviolaHooks';
import { Input } from '@app/components/Inviola/InviolaFields';
import { InviolaErrorMessage } from '@app/components/Inviola/InviolaMessages';

const AskResetForm = (): React.ReactElement => {
  const { formatMessage } = useIntl();
  const methods = useForm<ResetCredentials>({ mode: 'onChange' });
  const dispatch = useDispatch();
  const { authPath } = useInviolaPath();
  const validations = useInputValidations();

  const { handleSubmit, formState: { isValid } } = methods;

  const submit = (data: ResetCredentials) => {
    dispatch(resetCustomerPassword(data));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)}>
        <Input
          name="email"
          type="text"
          labelId="inviola.resetAsked.email"
          placeholderId="inviola.form.email.placeholder"
          validations={validations.email}
        />

        <InviolaErrorMessage errorType={InviolaAppError.ResetFormError} />

        <Button disabled={!isValid} type="submit">{formatMessage({ id: 'inviola.resetConfirmation.submit' })}</Button>
        <CenteredBlock>
          <Link to={authPath}>{formatMessage({ id: 'inviola.resetConfirmation.backLink' })}</Link>
        </CenteredBlock>
      </form>
    </FormProvider>
  );
};

export default AskResetForm;
