// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales';

import { NewsNavigationTypeMap } from '@app/constants/newsConstants';
import { AppLanguages } from '@app/constants/localizationConstants';

import {
  BaseNewsData, BaseNewsItem, AlgoliaSearchResultHit, EditorialContentTypes,
} from '@app/types/newsTypes';
import { Elements } from '@kentico/kontent-delivery';

import { LanguageType } from '@app/types/localizationTypes';
import { AppState } from '@app/types/appStateTypes';
import { PageType } from '@app/types/commonPageTypes';
import { RouteParams } from '@app/types/routerTypes';

import { mapThronList } from '@app/services/kentico/converters/configurationConverters';
import { CommonPageBaseUrlParams } from '@app/helpers/routeHelpers';
import { getThirdLevelMenuItem } from './menuHelpers';

type GetCategoryUrl= (params: {
  state: AppState;
  newsItem: BaseNewsData;
  language: LanguageType;
}) => string;

export const getCategoryUrl: GetCategoryUrl = ({ state, newsItem, language }) => {
  const subMenuId = NewsNavigationTypeMap[newsItem.type];
  // eslint-disable-next-line camelcase
  const categories = state.configuration[language]?.menu?.web_news?.navigation[subMenuId]?.categories;
  const category = (categories && categories.find((menuItem) => menuItem.editorialCategory === newsItem.categoryCode));

  return category?.url ?? Translations[language]['news.landingThirdLevelUrl'];
};

export const getEditorialTypeByPageType = (pageType: PageType): EditorialContentTypes => {
  switch (pageType) {
    case PageType.News:
      return EditorialContentTypes.NEWS;
    case PageType.Videos:
      return EditorialContentTypes.VIDEO;
    default:
      return EditorialContentTypes.PHOTO;
  }
};

type GetNewsDetailsUrlParams = (params: {
  state: AppState,
  params: RouteParams,
  language: LanguageType,
  type: EditorialContentTypes,
}) => CommonPageBaseUrlParams

export const getNewsDetailsUrlParams: GetNewsDetailsUrlParams = ({
  state, params, language, type,
}) => {
  const {
    topLevel, secondLevel, thirdLevel = '',
  } = params;
  const urlParams = {
    thirdLevel: {}, forthLevel: {}, fifthLevel: {},
  } as unknown as CommonPageBaseUrlParams;
  const thirdLevelMenu = getThirdLevelMenuItem(state, topLevel, secondLevel, thirdLevel, language);
  const localizedNews = state.news[type].selectedItemDetails;

  return AppLanguages
    .reduce((acc, language) => {
      const params = { ...acc };

      params.thirdLevel[language] = thirdLevelMenu?.[language]?.url || Translations[language]?.['news.landingThirdLevelUrl'];
      params.forthLevel[language] = localizedNews?.[language]?.publicationDate ?? '';
      params.fifthLevel[language] = localizedNews?.[language]?.urlSlug ?? '';

      return params;
    }, urlParams);
};

export const getHelmetScript = (source: string): {
  src: string; type: string;
}[] | null => {
  const script = /<script\b[^>]*>([\s\S]*?)<\/script>/gm.exec(source)?.[0];
  const scriptSource = script && /src="(.*?)"/g.exec(script)?.[1];
  return scriptSource ? [{ src: scriptSource, type: 'text/javascript' }] : null;
};

export const getNewsItemFromHitItem = (hit: AlgoliaSearchResultHit): BaseNewsItem => ({
  urlSlug: hit?.urlSlug ?? '',
  publicationDate: hit?.publicationDate?.slice(0, 10) ?? '',
  title: hit?.title ?? '',
  subtitle: '',
  codeName: hit?.kenticoCodename ?? '',
  id: hit?.objectID ?? '',
  categoryName: hit?.categories?.[0] ?? '',
  categoryCode: hit?.categories?.[0] ?? '',
  type: hit?.kenticoType ?? '',
  image: mapThronList({ value: hit?.mainImage } as Elements.CustomElement)?.[0],
  isPremium: !!hit?.premium?.length,
  photosNumber: 0,
});
