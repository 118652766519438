import { shallowEqual, useSelector } from 'react-redux';
import { Footer } from '@app/types/configurationTypes';
import { AppState } from '@app/types/appStateTypes';

import { useLanguage } from '@app/components/Hooks';

export const useFooter = (): Footer | undefined => {
  const language = useLanguage();
  return useSelector<AppState, Footer | undefined>(
    (state) => state.configuration[language]?.footer,
    shallowEqual,
  );
};
