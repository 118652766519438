import { InviolaError, InviolaSuccessTypes } from '@app/services/inviola/types/errorTypes';
import { RegistrationCredentials, ConfirmRegistrationCredentials } from '@app/services/inviola/types/requestTypes';
import {
  InviolaResponse,
  InviolaLoginData,
  InviolaSyncData,
  InviolaMovementsData,
  InviolaModifyData,
  InviolaCountries,
  InviolaRawDynamicFields,
  InviolaRawCategory,
  InviolaRawCatalogItems,
  InviolaRawAwards,
} from '@app/services/inviola/types/responseTypes';
import { InviolaCatalogItemId, InviolaCustomer, InviolaShop } from '@app/types/inviolaTypes';

export enum InviolaActionTypes {
  SET_ERROR = 'INVIOLA/SET_ERROR',
  RESET_ERROR = 'INVIOLA/RESET_ERROR',
  SET_SUCCESS = 'INVIOLA/SET_SUCCESS',
  RESET_CUSTOMER = 'INVIOLA/RESET_CUSTOMER',
  SET_CUSTOMER = 'INVIOLA/SET_CUSTOMER',
  SET_CUSTOMER_REDIRECT_PARAMS = 'INVIOLA/SET_CUSTOMER_REDIRECT_PARAMS', // REDIRECT:TO:ANY:ROURE:AFTER:SUCCESS:LOGIN
  SET_ADDITIONAL_DATA = 'INVIOLA/SET_ADDITIONAL_DATA',
  RESET_PASSWORD_ASKED = 'INVIOLA/RESET_PASSWORD_ASKED',
  RESET_PASSWORD_CONFIRMED = 'INVIOLA/RESET_PASSWORD_CONFIRMED',
  RESET_PASSWORD_DONE = 'INVIOLA/RESET_PASSWORD_DONE',
  SET_SYNC_IN_PROGRESS = 'INVIOLA/SET_SYNC_IN_PROGRESS_STATUS',
  SET_LOGOUT_SYNC_IN_PROGRESS = 'INVIOLA/SET_LOGOUT_SYNC_IN_PROGRESS',
  SET_CHANGE_PASSWORD_IN_PROGRESS = 'INVIOLA/SET_CHANGE_PASSWORD_IN_PROGRESS',
  SYNC_DONE = 'INVIOLA/SYNC_DONE',
  SET_CARD_MOVEMENTS = 'INVIOLA/SET_CARD_MOVEMENTS',
  SET_CARD_MOVEMENTS_SYNC_IN_PROGRESS = 'INVIOLA/SET_CARD_MOVEMENTS_SYNC_IN_PROGRESS',
  POINTS_SYNC_IN_PROGRESS = 'INVIOLA/POINTS_SYNC_IN_PROGRESS',
  SET_CUSTOMER_POINTS = 'INVIOLA/SET_CUSTOMER_POINTS',
  SET_CUSTOMER_UPDATED = 'INVIOLA/SET_CUSTOMER_UPDATED',
  SET_CUSTOMER_SAVED = 'INVIOLA/SET_CUSTOMER_SAVED',
  SET_CUSTOMER_SESSION = 'INVIOLA/SET_CUSTOMER_SESSION',
  SET_CUSTOMER_SESSION_ACCESSED_AT = 'INVIOLA/SET_CUSTOMER_SESSION_ACCESSED_AT',
  REGISTRATION_WIZARD_RESET = 'INVIOLA/REGISTRATION_WIZARD_RESET',
  REGISTRATION_STORE = 'INVIOLA/REGISTRATION_STORE',
  REGISTRATION_CONFIRMATION_STORE = 'INVIOLA/REGISTRATION_CONFIRMATION_STORE',
  REGISTRATION_WITHOUT_CARD_CONFIRMATION = 'INVIOLA/REGISTRATION_WITHOUT_CARD_CONFIRMATION',
  REGISTRATION_CARD_WITHOUT_EMAIL_CONFIRMATION = 'INVIOLA/REGISTRATION_CARD_WITHOUT_EMAIL_CONFIRMATION',
  REGISTRATION_CARD_WITHOUT_EMAIL_VALIDATED = 'INVIOLA/REGISTRATION_CARD_WITHOUT_EMAIL_VALIDATED',
  REGISTRATION_CARD_WITH_EMAIL_CONFIRMATION = 'INVIOLA/REGISTRATION_CARD_WITH_EMAIL_CONFIRMATION',
  REGISTRATION_CARD_WITH_EMAIL_VALIDATED = 'INVIOLA/REGISTRATION_CARD_WITH_EMAIL_VALIDATED',
  REGISTRATION_CONFIRMED = 'INVIOLA/REGISTRATION_CONFIRMED',
  SET_SHOPS = 'INVIOLA/SET_SHOPS',
  SET_SHOPS_CATEGORIES = 'INVIOLA/SET_SHOPS_CATEGORIES',
  SET_SHOPS_SYNC_IN_PROGRESS = 'INVIOLA/SET_SHOPS_SYNC_IN_PROGRESS',
  SET_CATALOG_ITEMS = 'INVIOLA/SET_CATALOG_ITEMS',
  SET_CATALOG_SELECTED_ITEM_ID = 'INVIOLA/SET_CATALOG_SELECTED_ITEM_ID',
  SET_CATALOG_FILTER_PAGE = 'INVIOLA/SET_CATALOG_FILTER_PAGE',
  SET_CATALOG_FILTER_CATEGORY = 'INVIOLA/SET_CATALOG_FILTER_CATEGORY',
  SET_CATALOG_FILTER_MIN_POINTS = 'INVIOLA/SET_CATALOG_FILTER_MIN_POINTS',
  SET_CATALOG_FILTER_MAX_POINTS = 'INVIOLA/SET_CATALOG_FILTER_MAX_POINTS',
  CATALOG_SYNC_IN_PROGRESS = 'INVIOLA/CATALOG_SYNC_IN_PROGRESS',
  SET_AWARDS = 'INVIOLA/SET_AWARDS',
  SET_WITHDRAWN_AWARDS = 'INVIOLA/SET_WITHDRAWN_AWARDS',
  REQUEST_AWARD_DONE = 'INVIOLA/REQUEST_AWARD_DONE',
  SET_ITEMS_TO_WISH_LIST = 'INVIOLA/SET_ITEMS_TO_WISH_LIST',
  ADD_ITEM_TO_WISH_LIST = 'INVIOLA/ADD_ITEM_TO_WISH_LIST',
  REMOVE_ITEM_FROM_WISH_LIST = 'INVIOLA/REMOVE_ITEM_FROM_WISH_LIST',
}

export interface SetError {
  type: InviolaActionTypes.SET_ERROR;
  payload: InviolaError;
}

export interface ResetError {
  type: InviolaActionTypes.RESET_ERROR;
}

export interface SetSuccess {
  type: InviolaActionTypes.SET_SUCCESS;
  payload: InviolaSuccessTypes;
}

export interface ResetCustomer {
  type: InviolaActionTypes.RESET_CUSTOMER;
}

export interface SetCustomer {
  type: InviolaActionTypes.SET_CUSTOMER;
  payload: {
    loginData: InviolaResponse<InviolaLoginData>,
    rememberMe: boolean,
  };
}

export interface ResetPasswordAsked {
  type: InviolaActionTypes.RESET_PASSWORD_ASKED;
  payload: string;
}

export interface ResetPasswordConfirmed {
  type: InviolaActionTypes.RESET_PASSWORD_CONFIRMED;
}

export interface ResetPasswordDone {
  type: InviolaActionTypes.RESET_PASSWORD_DONE;
}

export interface SetSyncDone {
  type: InviolaActionTypes.SYNC_DONE;
  payload: InviolaResponse<InviolaSyncData>;
}

export interface SetSyncInProgress {
  type: InviolaActionTypes.SET_SYNC_IN_PROGRESS;
  payload: boolean;
}

export interface SetLogoutSyncInProgress {
  type: InviolaActionTypes.SET_LOGOUT_SYNC_IN_PROGRESS;
  payload: boolean;
}

export interface SetChangePasswordInProgress {
  type: InviolaActionTypes.SET_CHANGE_PASSWORD_IN_PROGRESS;
  payload: boolean;
}

export interface SetAdditionalData {
  type: InviolaActionTypes.SET_ADDITIONAL_DATA;
  payload: {
    dynamicFieldsData: InviolaResponse<InviolaRawDynamicFields>,
    countries: InviolaResponse<InviolaCountries>,
  };
}

export interface RegistrationCodeSent {
  type: InviolaActionTypes.REGISTRATION_WITHOUT_CARD_CONFIRMATION;
}

export interface RegistrationCardWithoutEmailConfirmation {
  type: InviolaActionTypes.REGISTRATION_CARD_WITHOUT_EMAIL_CONFIRMATION;
}

export interface RegistrationCardWithoutEmailValidated {
  type: InviolaActionTypes.REGISTRATION_CARD_WITHOUT_EMAIL_VALIDATED;
  payload: {
    customerId: string;
    customer: InviolaCustomer;
  };
}

export interface RegistrationCardWithEmailConfirmation {
  type: InviolaActionTypes.REGISTRATION_CARD_WITH_EMAIL_CONFIRMATION;
  payload: {
    customerId: string;
    customer: InviolaCustomer;
  };
}

export interface RegistrationCardWithEmailValidated {
  type: InviolaActionTypes.REGISTRATION_CARD_WITH_EMAIL_VALIDATED;
}

export interface RegistrationConfirmed {
  type: InviolaActionTypes.REGISTRATION_CONFIRMED;
}

export interface RegistrationStore {
  type: InviolaActionTypes.REGISTRATION_STORE;
  payload: RegistrationCredentials;
}

export interface SetRegistrationConfirmationCredentials {
  type: InviolaActionTypes.REGISTRATION_CONFIRMATION_STORE;
  payload: {
    confirmCredentials: ConfirmRegistrationCredentials
  };}

export interface RegistrationWizardReset {
  type: InviolaActionTypes.REGISTRATION_WIZARD_RESET;
}

export interface SetCardMovements {
  type: InviolaActionTypes.SET_CARD_MOVEMENTS;
  payload: InviolaResponse<InviolaMovementsData>;
}

export interface SetCardMovementsSyncInProgress {
  type: InviolaActionTypes.SET_CARD_MOVEMENTS_SYNC_IN_PROGRESS;
  payload: boolean;
}

export interface SetCustomerPoints {
  type: InviolaActionTypes.SET_CUSTOMER_POINTS;
  payload: number;
}

export interface SetCustomerRedirectPath {
  type: InviolaActionTypes.SET_CUSTOMER_REDIRECT_PARAMS;
  payload: string | null;
}

export interface SetPointsSyncInProgress {
  type: InviolaActionTypes.POINTS_SYNC_IN_PROGRESS;
  payload: boolean;
}

export interface SetCustomerUpdated {
  type: InviolaActionTypes.SET_CUSTOMER_UPDATED;
  payload: InviolaResponse<InviolaModifyData>;
}

export interface SetCustomerSaved {
  type: InviolaActionTypes.SET_CUSTOMER_SAVED;
  payload: boolean;
}

export interface SetCustomerSession {
  type: InviolaActionTypes.SET_CUSTOMER_SESSION;
  payload: string;
}

export interface SetCustomerSessionAccessedAt {
  type: InviolaActionTypes.SET_CUSTOMER_SESSION_ACCESSED_AT;
}

export interface SetShops {
  type: InviolaActionTypes.SET_SHOPS;
  payload: InviolaShop[];
}

export interface SetShopsSyncInProgress {
  type: InviolaActionTypes.SET_SHOPS_SYNC_IN_PROGRESS;
  payload: boolean;
}

export interface SetShopsCategories {
  type: InviolaActionTypes.SET_SHOPS_CATEGORIES;
  payload: InviolaRawCategory[];
}

export interface SetCatalogItems {
  type: InviolaActionTypes.SET_CATALOG_ITEMS;
  payload: InviolaRawCatalogItems;
}

export interface SetCatalogSelectedItemId {
  type: InviolaActionTypes.SET_CATALOG_SELECTED_ITEM_ID;
  payload: number | undefined;
}

export interface SetCatalogFilterPage {
  type: InviolaActionTypes.SET_CATALOG_FILTER_PAGE;
  payload: number;
}

export interface SetCatalogFilterCategory {
  type: InviolaActionTypes.SET_CATALOG_FILTER_CATEGORY;
  payload: number;
}

export interface SetCatalogFilterMinPoints {
  type: InviolaActionTypes.SET_CATALOG_FILTER_MIN_POINTS;
  payload: number;
}

export interface SetCatalogFilterMaxPoints {
  type: InviolaActionTypes.SET_CATALOG_FILTER_MAX_POINTS;
  payload: number;
}

export interface SetCatalogSyncInProgress {
  type: InviolaActionTypes.CATALOG_SYNC_IN_PROGRESS;
  payload: boolean;
}

export interface SetItemsToWishList {
  type: InviolaActionTypes.SET_ITEMS_TO_WISH_LIST;
  payload: InviolaCatalogItemId[];
}

export interface AddItemToWishList {
  type: InviolaActionTypes.ADD_ITEM_TO_WISH_LIST;
  payload: InviolaCatalogItemId;
}

export interface RemoveItemFromWishList {
  type: InviolaActionTypes.REMOVE_ITEM_FROM_WISH_LIST;
  payload: InviolaCatalogItemId;
}

export interface SetAwards {
  type: InviolaActionTypes.SET_AWARDS;
  payload: InviolaRawAwards;
}

export interface SetWithdrawnAwards {
  type: InviolaActionTypes.SET_WITHDRAWN_AWARDS;
  payload: InviolaRawAwards;
}

export interface RequestAwardDone {
  type: InviolaActionTypes.REQUEST_AWARD_DONE;
}

export type InviolaActions = SetError
  | ResetError
  | SetSuccess
  | SetCustomer
  | ResetCustomer
  | SetCustomerSession
  | SetCustomerSessionAccessedAt
  | SetCustomerRedirectPath
  | SetCustomerUpdated
  | SetCustomerSaved
  | SetSyncDone
  | SetSyncInProgress
  | SetLogoutSyncInProgress
  | SetChangePasswordInProgress
  | ResetPasswordAsked
  | ResetPasswordConfirmed
  | ResetPasswordDone
  | SetCardMovements
  | SetCardMovementsSyncInProgress
  | RegistrationCodeSent
  | RegistrationCardWithoutEmailValidated
  | RegistrationCardWithoutEmailConfirmation
  | RegistrationCardWithEmailConfirmation
  | RegistrationCardWithEmailValidated
  | RegistrationStore
  | SetRegistrationConfirmationCredentials
  | RegistrationWizardReset
  | RegistrationConfirmed
  | SetAdditionalData
  | SetShops
  | SetShopsSyncInProgress
  | SetShopsCategories
  | SetCatalogSyncInProgress
  | SetCatalogItems
  | SetCatalogSelectedItemId
  | SetCatalogFilterPage
  | SetCatalogFilterCategory
  | SetCatalogFilterMinPoints
  | SetCatalogFilterMaxPoints
  | SetPointsSyncInProgress
  | SetCustomerPoints
  | SetAwards
  | SetWithdrawnAwards
  | RequestAwardDone
  | SetItemsToWishList
  | AddItemToWishList
  | RemoveItemFromWishList
