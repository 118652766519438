import React from 'react';
import styled from 'styled-components';

import { BaseMenuItem } from '@app/types/configurationTypes';
import { PlayerPositionFilter } from '@app/types/teamsTypes';

import {
  useIsLoading,
  usePageValidation, usePlayersDownload,
} from '@app/pages/Teams/TeamsHooks';

import {
  CategoryMenu,
  PageTitle,
} from '@app/components';
import { PageWrapper } from '@app/pages/components';
import {
  useCommonPageBaseMultiLangRoute,
  useSecondLevelMenu,
  useTopLevelMenu,
} from '@app/pages/CommonPage/CommonPageHooks';
import { Devices, Colors } from '@app/styles';
import AppRoutes from '@app/constants/routesConstants';
import TeamPlayersList from './TeamPlayersList';

const Teams: React.FC = () => {
  const topLevel = useTopLevelMenu();
  const subItem = useSecondLevelMenu();
  usePlayersDownload();
  useCommonPageBaseMultiLangRoute();

  const teamName = subItem?.data.name;
  const categoryMenu = subItem?.categories ?? [];
  const pathParams = {
    topLevel: topLevel.data?.url ?? '',
    secondLevel: (subItem?.data as BaseMenuItem)?.url ?? '',
  };
  const isLoading = useIsLoading();
  const error = usePageValidation();

  return (
    <PageWrapper error={error} isLoading={isLoading}>
      <Container>
        <InnerContainer>
          <PageTitle>{teamName}</PageTitle>
          <CategoryMenu
            items={categoryMenu}
            pathKey={AppRoutes.CommonPage.path}
            pathParams={pathParams}
            color={Colors.black}
          />
          {Object.values(PlayerPositionFilter).map((position) => (
            <TeamPlayersList position={position} key={position} />
          ))}
        </InnerContainer>
      </Container>
    </PageWrapper>
  );
};

const Container = styled.div`
  background-color: ${(props): string => props.theme.color.whiteSmoke};
  height: 100%;
  padding: 25px 20px 0;

  @media ${Devices.desktop} {
    padding: 25px 0 0;
  }
`;

const InnerContainer = styled.div`
  max-width: 920px;
  margin: auto;
`;

export default Teams;
