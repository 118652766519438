import React from 'react';
import styled from 'styled-components';
import { WebCtaButtonTemplateType, WebTemplateCtaButton } from '@app/types/webTemplateTypes';
import { MenuItemTypes, RelatedMenuItem } from '@app/types/configurationTypes';
import { LocalizedLink } from '@app/components/Localization';
import { Devices } from '@app/styles';

interface WebCtaButtonParamsProps {
  data: WebTemplateCtaButton;
  onClick?: (e: React.MouseEvent) => void;
  width?: string;
  height?: string;
}

const WebCtaButtonElement = ({ data, onClick, width, height }: WebCtaButtonParamsProps): React.ReactElement => {
  const isPlain = data.template === WebCtaButtonTemplateType.Plain;

  const renderSwitch = () => {
    switch (data.menuItem?.type as MenuItemTypes) {
      case MenuItemTypes.ExternalLink:
        return (
          <LinkWrapper
            as="a"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            href={data.menuItem?.url as string}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button isPlain={isPlain} onClick={onClick} width={width} height={height}>
              {data.label}
            </Button>
          </LinkWrapper>
        );
      case MenuItemTypes.RelatedMenuItem:
        // eslint-disable-next-line no-case-declarations
        const {
          topLevel, secondLevel, thirdLevel,
        } = data.menuItem as RelatedMenuItem;
        return (
          <LocalizedLink
            pathKey="routes.commonPage"
            pathParams={{
              topLevel: topLevel ? topLevel.url : '',
              secondLevel: secondLevel ? secondLevel.url : '',
              thirdLevel: thirdLevel ? thirdLevel.url : '',
              forthLevel: '',
              fifthLevel: '',
            }}
          >
            <Button isPlain={isPlain} onClick={onClick} width={width} height={height}>
              {data.label}
            </Button>
          </LocalizedLink>
        );
      default:
        return (
          <LocalizedLink
            pathKey="routes.commonPage"
            pathParams={{
              topLevel: topLevel ? topLevel.url : '',
              secondLevel: secondLevel ? secondLevel.url : '',
              thirdLevel: thirdLevel ? thirdLevel.url : '',
              forthLevel: '',
              fifthLevel: '',
            }}
          >
            <Button isPlain={isPlain} onClick={onClick} width={width} height={height}>
              {data.label}
            </Button>
          </LocalizedLink>
        );
    }
  };

  return renderSwitch();
};

export default WebCtaButtonElement;

const Button = styled.button<{ isPlain: boolean, width?: string, height?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  cursor: pointer;
  line-height: 1;
  transition: background-color 0.3s ease-in, color 0.3s ease-in, border 0.3s ease-in;
  border: 2px solid #ffffff;
  outline: none;

  background: ${(props) => (props.isPlain ? props.theme.color.red : 'transparent')};
  border-color: ${(props) => props.theme.color.red};
  height: ${(props) => (props.height ?? '35px')};
  width: ${(props) => (props.width ?? 'auto')};
  padding: 0 15px;
  font-weight: bold;
  font-size: 9px;
  color: ${(props) => (props.isPlain ? '#fff' : props.theme.color.red)};
  margin-bottom: 30px;

  @media ${Devices.tablet} {
    font-size: 12px;
  }

  &:hover {
    border-color: ${(props) => props.theme.color.red};
    background-color: ${(props) => (props.isPlain ? 'transparent' : props.theme.color.red)};

    color: ${(props) => (props.isPlain ? props.theme.color.red : '#fff')};
  }
`;

const LinkWrapper = styled(LocalizedLink)``;
