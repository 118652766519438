import { DevicesSize } from '@app/styles';

export enum COVER_IMAGE_TYPE {
  TOP_HIGHLIGHT_DESKTOP = 'TOP_HIGHLIGHT_DESKTOP',
  TOP_CAROUSEL_DESKTOP = 'TOP_CAROUSEL_DESKTOP',
  NEWS_LIST_DESKTOP = 'NEWS_LIST_DESKTOP',
  DEFAULT_TABLET = 'DEFAULT_TABLET',
  DEFAULT_MOBILE = 'DEFAULT_MOBILE',
}

const COVER_STATIC_WIDTH_MAP = {
  [DevicesSize.tabletSmall]: { width: 550, height: 305 },
  [DevicesSize.mobileAboveMedium]: { width: 375, height: 208 },
  0: { width: 335, height: 186 },
};

const COVER_MOBILE_WIDTH_MAP = {
  [DevicesSize.tabletSmall]: { width: 828, height: 1300 },
  [DevicesSize.mobileAboveMedium]: { width: 414, height: 648 },
  0: { width: 375, height: 560 },
};

const COVER_HIGHLIGHT_WIDTH_MAP = {
  [DevicesSize.desktop]: { width: 1280, height: 720 },
  ...COVER_MOBILE_WIDTH_MAP,
};

const COVER_TOP_CAROUSEL_WIDTH_MAP = {
  [DevicesSize.desktop]: { width: 1280, height: 500 },
  ...COVER_MOBILE_WIDTH_MAP,
};

export const COVER_IMAGE_WIDTH_MAP = {
  [COVER_IMAGE_TYPE.DEFAULT_MOBILE]: COVER_MOBILE_WIDTH_MAP,
  [COVER_IMAGE_TYPE.DEFAULT_TABLET]: COVER_MOBILE_WIDTH_MAP,
  [COVER_IMAGE_TYPE.NEWS_LIST_DESKTOP]: COVER_STATIC_WIDTH_MAP,
  [COVER_IMAGE_TYPE.TOP_HIGHLIGHT_DESKTOP]: COVER_HIGHLIGHT_WIDTH_MAP,
  [COVER_IMAGE_TYPE.TOP_CAROUSEL_DESKTOP]: COVER_TOP_CAROUSEL_WIDTH_MAP,
};

export const COVER_IMAGE_ASPECT_RATIO = {
  [COVER_IMAGE_TYPE.TOP_HIGHLIGHT_DESKTOP]: '16/9',
  [COVER_IMAGE_TYPE.TOP_CAROUSEL_DESKTOP]: '13/5',
  [COVER_IMAGE_TYPE.NEWS_LIST_DESKTOP]: '16/9',
  [COVER_IMAGE_TYPE.DEFAULT_TABLET]: '6/7',
  [COVER_IMAGE_TYPE.DEFAULT_MOBILE]: '6/9',
};
