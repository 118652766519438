import React from 'react';
import styled from 'styled-components';

import { PageValidationWrapper } from '@app/pages/components';
import {
  useHomeData,
  useHomeDownload,
  useHomeMultiLangUrl,
  useHomeTopElement,
  useLiveElement,
  useSeoData,
} from '@app/pages/Home/HomeHooks';
import { Metadata } from '@app/components';
import { HomeContainer } from '@app/components/Home';
import { ContainerLarge } from '@app/components/WebTemplates/components';
import { WebTemplateCarousel } from '@app/types/webTemplateTypes';
import { WebHomeCarouselElement, WebLiveElement } from '@app/components/Home/elements';

const Home: React.FC = () => {
  useHomeDownload();
  useHomeMultiLangUrl();
  // const seoData = useSeoData();
  const data = useHomeData();
  const topElement = useHomeTopElement() as WebTemplateCarousel;
  const live = useLiveElement();
  const seo = useSeoData();

  return (
    <PageValidationWrapper error={null}>
      {seo && <Metadata seo={seo} />}
      <ContainerLargePadding>
        {live && (<WebLiveElement data={live} addBottomPadding />)}
        {topElement && (<WebHomeCarouselElement data={topElement} />)}
        <HomeContainer data={data} />
      </ContainerLargePadding>
    </PageValidationWrapper>
  );
};

const ContainerLargePadding = styled(ContainerLarge)`
  margin-top: 0;
`;

export default Home;
