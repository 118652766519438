import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import { useBarcode } from 'react-barcodes';
import { FormattedMessage } from 'react-intl';

import { Button } from '@app/components/Inviola/InviolaComponents';
import { CustomLeftArrow, CustomRightArrow } from '@app/components/Carousel/CarouselArrows';
import { useMaxWidthOfPartialVisibility } from '@app/components/Carousel/CarouselHooks';

import { useAwards, useCarouselConfiguration } from '../AwardsPageHooks';
import { Item, Image, Content, Title, Name, Barcode, Description, Section } from './AwardsElements';
import AwardModal from './AwardModal';

interface AwardProps {
  id: number;
  name: string;
  barcode: string;
  exchangeDate: string;
  imageUrl: string;
  onGetCode: () => void;
}

const Award = React.memo(({
  name, exchangeDate, barcode, imageUrl, onGetCode,
}: AwardProps): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { inputRef } = useBarcode({ value: barcode });
  const barcodeRef = inputRef as React.RefObject<SVGSVGElement>;

  return (
    <Item>
      <Content>
        <Image width="800" height="600" src={imageUrl} />
        <Name>{name}</Name>
        <em>{exchangeDate}</em>
        <Barcode><svg ref={barcodeRef} /></Barcode>

        <Button onClick={onGetCode}>
          <FormattedMessage id="inviola.awards.getCode" />
        </Button>
      </Content>
    </Item>
  );
}, (prev, next) => (prev.id === next.id));

const AwardsSection = (): React.ReactElement => {
  const [activeAwardId, setActiveAwardId] = useState<number>();
  const awards = useAwards();

  const carouselConfiguration = useCarouselConfiguration();
  const maxWidthOfPartialVisibility = useMaxWidthOfPartialVisibility(carouselConfiguration);

  return (
    <Section>
      <Title><FormattedMessage id="inviola.awards.title" /></Title>
      {awards?.length ? (
        <Carousel
          swipeable
          partialVisible
          responsive={carouselConfiguration}
          infinite={false}
          keyBoardControl={false}
          customLeftArrow={<CustomLeftArrow mediaQueryMinWidth={maxWidthOfPartialVisibility} />}
          customRightArrow={<CustomRightArrow mediaQueryMinWidth={maxWidthOfPartialVisibility} />}
        >
          {awards.map((award) => (
            <Award
              id={award.id}
              name={award.name}
              exchangeDate={award.exchangeDate}
              barcode={award.barcode}
              imageUrl={award.pathImageAbsolute}

              onGetCode={() => setActiveAwardId(award.id)}
            />
          ))}
        </Carousel>
      ) : (
        <Description><FormattedMessage id="inviola.awards.noItems" /></Description>
      )}
      <AwardModal
        id={activeAwardId}
        onClose={() => setActiveAwardId(undefined)}
      />
    </Section>
  );
};

export default AwardsSection;
