import { Player, PlayerPosition, TeamNames } from '@app/types/teamsTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { mapImageData, mapSeoData, mapThronData } from '@app/services/kentico/converters/configurationConverters';
import { convertTeamId } from '@app/services/opta/converters/teamsConverter';
import { TeamSquadTypes } from '@app/services/kentico/teamsService';
import { Player as KenticoPlayer } from '../types/player';
import { Team } from '../types/team';

export function convertPlayer(kentico: KenticoPlayer, language: LanguageType): Player {
  return {
    id: kentico?.id?.value,
    firstName: kentico?.firstName?.value,
    lastName: kentico?.lastName?.value,
    number: kentico?.number?.value ?? 0,
    position: kentico?.position?.value[0].codename as PlayerPosition,
    positionName: kentico?.position?.value[0].name,
    role: kentico?.role?.value,
    photoLarge: mapImageData(kentico?.photoLarge),
    photoThron: mapThronData(kentico?.photoThron),
    storeUrl: kentico?.storeUrl?.value,
    birthDate: kentico?.birthDate?.value ?? null,
    birthPlace: kentico?.birthPlace?.value,
    nationality: kentico?.nationality?.value,
    height: kentico?.height?.value,
    weight: kentico?.weight?.value,
    preferredFoot: kentico?.preferredFoot?.value[0]?.name,
    joinDate: kentico?.joinDate?.value?.toString() ?? '',
    biography: kentico?.biography?.value,
    urlSlug: kentico?.url?.value,
    instagram: kentico?.instagram?.value,
    squad: kentico?.squad?.value?.[0]?.codename as TeamSquadTypes,
    sortingOrder: kentico?.sortingOrder?.value ?? 0,
    ...mapSeoData(kentico, language),
  };
}

export function convertTeamNames(teams: Team[]): TeamNames {
  return teams
    .reduce((accumulator, { teamOptaId, teamOptaSdId, regularName, shortName }) => {
      const teamId = teamOptaSdId?.value ? teamOptaSdId?.value : convertTeamId(teamOptaId?.value);

      accumulator[teamId] = {
        teamName: regularName?.value ?? '',
        shortTeamName: shortName?.value ?? '',
      };

      return accumulator;
    }, {} as TeamNames);
}
