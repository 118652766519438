import React from 'react';
import { FormattedMessage } from 'react-intl';

import theme from '@app/styles/theme';
import { MatchStatistics } from '@app/types/matchTypes';
import { useLineupsSelector } from '@app/pages/MatchCenter/MatchCenterHooks';
import {
  BlockContainerColumn, Caption, StatsBlock, ValueAndGraph,
} from '@app/components/Statistics';
import { ValueLarge } from '@app/pages/MatchCenter/components/MatchStatistics/components/MatchBall';

const MatchPasses = (): React.ReactElement => {
  const lineups = useLineupsSelector();

  return (
    <BlockContainerColumn>
      <StatsBlock marginTop="20px" marginBottom="40px">
        <StatsBlock>
          <ValueLarge>{`${lineups?.home?.stats?.[MatchStatistics.STATS_PASSES_TOTAL] ?? 0}`}</ValueLarge>
        </StatsBlock>
        <StatsBlock>
          <Caption isSmall><FormattedMessage id="statistics.passes" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <ValueLarge>{`${lineups?.away?.stats?.[MatchStatistics.STATS_PASSES_TOTAL] ?? 0}`}</ValueLarge>
        </StatsBlock>
      </StatsBlock>
      <StatsBlock marginBottom="40px">
        <StatsBlock>
          <ValueAndGraph
            strokeHomeColor={theme.color.purple}
            caption="statistics.successful"
            progress={
              lineups ? (lineups?.home?.stats?.[MatchStatistics.STATS_SUCCESFULL_PASSES] * 100)
              / lineups?.home?.stats?.[MatchStatistics.STATS_PASSES_TOTAL] : 0
            }
            value={`${
              lineups?.home?.stats?.[MatchStatistics.STATS_PASSES_TOTAL] === 0 ? 0 : (
                Math.round(lineups ? (lineups?.home?.stats?.[MatchStatistics.STATS_SUCCESFULL_PASSES] * 100)
                  / lineups?.home?.stats?.[MatchStatistics.STATS_PASSES_TOTAL] : 0)
              )
            }%`}
          />
        </StatsBlock>
        <StatsBlock>
          <ValueAndGraph
            strokeHomeColor={theme.color.red}
            caption="statistics.successful"
            progress={
              lineups ? (lineups?.away?.stats?.[MatchStatistics.STATS_SUCCESFULL_PASSES] * 100)
              / lineups?.away?.stats?.[MatchStatistics.STATS_PASSES_TOTAL] : 0
            }
            value={`${
              lineups?.away?.stats?.[MatchStatistics.STATS_PASSES_TOTAL] === 0 ? 0 : (
                Math.round(lineups ? (lineups?.away?.stats?.[MatchStatistics.STATS_SUCCESFULL_PASSES] * 100)
                  / lineups?.away?.stats?.[MatchStatistics.STATS_PASSES_TOTAL] : 0)
              )
            }%`}
          />
        </StatsBlock>
      </StatsBlock>
      <StatsBlock marginBottom="40px">
        <StatsBlock>
          <ValueLarge>{`${lineups?.home?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL] ?? 0}`}</ValueLarge>
        </StatsBlock>
        <StatsBlock>
          <Caption isSmall><FormattedMessage id="statistics.crossovers" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <ValueLarge>{`${lineups?.away?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL] ?? 0}`}</ValueLarge>
        </StatsBlock>
      </StatsBlock>
      <StatsBlock marginBottom="20px">
        <StatsBlock>
          <ValueAndGraph
            strokeHomeColor={theme.color.purple}
            caption="statistics.successful"
            progress={
              lineups ? (lineups?.home?.stats?.[MatchStatistics.STATS_SUCCESFULL_CROSES] * 100)
              / lineups?.home?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL] : 0
            }
            value={`${
              lineups?.home?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL] === 0 ? 0 : (
                Math.round(lineups ? (lineups?.home?.stats?.[MatchStatistics.STATS_SUCCESFULL_CROSES] * 100)
                  / lineups?.home?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL] : 0)
              )
            }%`}
          />
        </StatsBlock>
        <StatsBlock>
          <ValueAndGraph
            caption="statistics.successful"
            strokeHomeColor={theme.color.red}
            progress={
              lineups ? (lineups?.away?.stats?.[MatchStatistics.STATS_SUCCESFULL_CROSES] * 100)
              / lineups?.away?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL] : 0
            }
            value={`${
              lineups?.away?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL] === 0 ? 0 : (
                Math.round(lineups ? (lineups?.away?.stats?.[MatchStatistics.STATS_SUCCESFULL_CROSES] * 100)
                  / lineups?.away?.stats?.[MatchStatistics.STATS_CROSSES_TOTAL] : 0)
              )
            }%`}
          />
        </StatsBlock>
      </StatsBlock>
    </BlockContainerColumn>
  );
};

export default MatchPasses;
