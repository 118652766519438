import React from 'react';
import { Container, InvalidVideoPlayer, Overlay, Error } from './VideoComponents';

interface InvalidVideoProps {
  message: string;
  children: JSX.Element;
}

const InvalidVideo: React.FC<InvalidVideoProps> = ({ message, children }) => (
  <Container>
    <InvalidVideoPlayer>{children}</InvalidVideoPlayer>
    <Overlay>
      <Error>{message}</Error>
    </Overlay>
  </Container>
);

export default InvalidVideo;
