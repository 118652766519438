import React from 'react';
import styled from 'styled-components';

import { MenuItemTypes, MenuItem } from '@app/types/configurationTypes';
import { formatSubMenuLinkProps } from '@app/helpers/routeHelpers';
import { LocalizedLink } from '@app/components/Localization';
import { useUrlParamsOutsideSwitch } from '@app/components/Hooks';
import {
  useActiveSeasonIdForCompetitionSeriaA,
  useActiveUrlSlugForCompetitionSeriaA,
} from '@app/pages/Season/Season/SeasonHooks';

interface MenuItemTitleProps {
  item: MenuItem;
  onClick: () => void;
  className?: string; // passed by styled component automatically
  isHeader?: boolean;
}
const MenuItemTitle: React.FC<MenuItemTitleProps> = ({
  item, onClick, className, isHeader,
}) => {
  const { topLevel } = useUrlParamsOutsideSwitch();

  const subItems = Object.values(item.navigation)
    .filter(({ data }) => data.isVisible);
  const topLevelItem = subItems[0] ?? item;
  const seasonData = {
    thirdLevel: useActiveUrlSlugForCompetitionSeriaA(),
    forthLevel: useActiveSeasonIdForCompetitionSeriaA(),
  };

  if (topLevelItem.data.type === MenuItemTypes.ExternalLink) {
    const externalLink = item.relatedItems?.[0];
    if (externalLink?.type === MenuItemTypes.ExternalLink) {
      return (
        <MenuTitle
          onClick={onClick}
          as="a"
          href={externalLink?.url}
          target="_blank"
          rel="noopener noreferrer"
          className={className}
        >
          {externalLink?.name}
        </MenuTitle>
      );
    }
    return (
      <MenuTitle
        onClick={onClick}
        as="a"
        href={topLevelItem.data?.url}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {item.data.name}
      </MenuTitle>
    );
  }

  if (topLevelItem.data.type === MenuItemTypes.BaseMenuItem) {
    return (
      <MenuTitle
        onClick={onClick}
        {...formatSubMenuLinkProps(item.data, subItems[0], seasonData)}
        className={className}
        isActive={item.data?.url === topLevel && isHeader}
      >
        {item.data.name}
      </MenuTitle>
    );
  }

  return null;
};

const MenuTitle = styled(LocalizedLink)<{ isActive?: boolean }>`
  color: white;
  padding: 12px var(--menu-item-left-padding) 6px;
  font-size: 11px;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-weight: bold;
  width: max-content;
  display: block;
  position: relative;

  &::after {
    display: ${({ isActive }): string => (isActive ? 'block' : 'none')};
    content: '';
    position: absolute;
    height: 2px;
    width: 40px;
    background-color: white;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &:hover {
    color: white;
    text-decoration: none;
  }
`;

export default MenuItemTitle;
