import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Devices } from '@app/styles';

import {
  useInviolaCustomer,
  useIsCustomerSavedSelector,
  useLogoutInProgressSelector,
} from '@app/components/Inviola/InviolaHooks';
import { logoutCustomer } from '@app/store/actions/inviolaActions';

import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { pathParamsFor } from '@app/helpers/inviolaHelpers';
import { useLanguage } from '@app/components/Hooks';
import { ProfilePhoto } from '@app/components/Inviola/InviolaComponents';
import InviolaWrapper from '@app/components/Inviola/InviolaWrapper';
import { Loader } from '@app/components';

import RegistrationSuccessModal from './components/RegistrationSuccessModal';
import { useTabs, useActiveTab, useInviolaProfileMultiLangRoute } from './ProfilePageHooks';

const InviolaProfilePage = React.memo((): React.ReactElement | null => {
  const isCustomerSaved = useIsCustomerSavedSelector();

  const dispatch = useDispatch();
  const language = useLanguage();
  const { formatMessage } = useIntl();

  const syncInProgress = useLogoutInProgressSelector();
  const customer = useInviolaCustomer();
  const activeTab = useActiveTab();
  const tabs = useTabs();

  const ActiveTabComponent = activeTab.component;

  const onLogout = () => {
    if (!isCustomerSaved) { // eslint-disable-next-line no-alert
      window.confirm(formatMessage({ id: 'inviola.profile.saveChanges.text' })) && dispatch(logoutCustomer());
    } else {
      dispatch(logoutCustomer());
    }
  };

  useInviolaProfileMultiLangRoute();

  return (
    <InviolaWrapper withoutContainer>
      {syncInProgress && <Loader overflowMode />}
      <LoaderWrapper syncInProgress={syncInProgress}>
        <Banner>
          <BannerContainer>
            <ProfilePhoto>
              {`${customer?.firstName.charAt(0)}${customer?.lastName.charAt(0)}`}
            </ProfilePhoto>
            <BannerTitle>{`${customer?.firstName} ${customer?.lastName}`}</BannerTitle>
            <div>
              <LogoutButton type="button" onClick={onLogout}>
                <FormattedMessage id="inviola.profile.logout" />
              </LogoutButton>
            </div>
          </BannerContainer>
        </Banner>

        <TabsContainer>
          {tabs.map((tab) => (
            <TabItem key={tab.name} active={tab.name === activeTab?.name}>
              <Link to={localizeRouteKey(pathParamsFor('profile', tab.name), language)}>
                <FormattedMessage id={tab.titleKey} />
              </Link>
            </TabItem>
          ))}
        </TabsContainer>

        <Content>
          <ActiveTabComponent />
        </Content>
      </LoaderWrapper>
      <RegistrationSuccessModal />
    </InviolaWrapper>
  );
});

const LoaderWrapper = styled.div<{ syncInProgress: boolean }>`
  opacity: ${({ syncInProgress }): number => (syncInProgress ? 0.69 : 1)};
  transition: all 0.6s;
`;

const Banner = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  color: ${(props): string => props.theme.color.whiteSmoke};
  background-color: ${(props): string => props.theme.color.purple};
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 1rem;
`;

const BannerContainer = styled.div`
  margin: auto;
  width: 90%;
  margin: auto;
  text-align: center;

  @media ${Devices.tabletLarge} {
    max-width: 920px;
  }
`;

const BannerTitle = styled.h2`
  font-size: 1.428rem;
  font-weight: bold;
`;

const LogoutButton = styled.button`
  border: 0;
  background: transparent;
  font-size: 0.928rem;
  color: ${(props): string => props.theme.color.whiteSmoke};

  padding: 0;
  text-decoration: underline;

  opacity: 0.5;
  transition: opacity 500ms ease-out;

  &:hover {
    opacity: 1;
  }
`;

const Content = styled.div`
  padding: 40px 0;

  width: 90%;
  margin: auto;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};

  @media ${Devices.tabletLarge} {
    max-width: 920px;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  font-size: 1rem;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};

  background-color: ${(props): string => props.theme.color.purple};

  color: white;
  text-transform: uppercase;
  font-weight: bold;

  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;

  align-items: start;
  justify-content: safe center;
`;

const TabItem = styled.div<{ active?: boolean }>`
  font-size: 0.857rem;
  padding: 5px 20px 0 20px;
  text-align: center;

  a {
    color: white;
    opacity: ${({ active }): number => (active ? 1 : 0.3)};
    transition: opacity 500ms ease-out;

    &:hover {
      opacity: 1;
    }
  }

  &:after {
    content: "";
    width: 60%;
    height: 4px;
    background: white;
    visibility: ${({ active }): string => (active ? 'visible' : 'hidden')};
    margin: 5px auto 0 auto;
    display: block;
  }

  @media ${Devices.tabletLarge} {
    font-size: 1rem;
    padding: 5px 30px 0 30px;
  }
`;

export default InviolaProfilePage;
