import { confirmResetPassword, resendResetCode, resetPassword } from '@app/services/inviola/inviola';
import { InviolaAppSuccess, InviolaAppError } from '@app/services/inviola/types/errorTypes';
import { ConfirmResetCredentials, ResetCredentials } from '@app/services/inviola/types/requestTypes';
import { ResetPasswordAsked, InviolaActionTypes, ResetPasswordConfirmed, ResetPasswordDone } from '@app/store/actionTypes/inviolaActionTypes';
import { ActionWithPromiseReturn } from '@app/types/actionTypes';
import { handleInviolaError, setSuccess } from './error';
import { getSession } from './session';

export const setResetPasswordAsked = (resetEmail: string): ResetPasswordAsked => ({
  type: InviolaActionTypes.RESET_PASSWORD_ASKED,
  payload: resetEmail,
});

const setResetPasswordConfirmed = (): ResetPasswordConfirmed => ({
  type: InviolaActionTypes.RESET_PASSWORD_CONFIRMED,
});

export const setResetPasswordDone = (): ResetPasswordDone => ({
  type: InviolaActionTypes.RESET_PASSWORD_DONE,
});

export const resetCustomerPassword = (resetCredentials: Partial<ResetCredentials>): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    const syncSessionId = await dispatch(getSession('sync'));
    const credentials = { ...resetCredentials, session: syncSessionId } as ResetCredentials;

    try {
      await resetPassword(credentials);

      dispatch(setResetPasswordAsked(credentials.email));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.ResetFormError));
    }
  }
);

export const confirmResetCustomerPassword = (
  confirmCredentials: Partial<ConfirmResetCredentials>,
): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    const syncSessionId = await dispatch(getSession('sync'));

    try {
      const credentials = { ...confirmCredentials, session: syncSessionId } as ConfirmResetCredentials;
      await confirmResetPassword(credentials);

      dispatch(setResetPasswordConfirmed());
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.ResetFormError));
    }
  }
);

export const resendResetConfirmationCode = (email?: string): ActionWithPromiseReturn | never => (
  async (dispatch, getState): Promise<void> => {
    const state = getState();
    const { resetEmail } = state.inviola;
    const syncSessionId = await dispatch(getSession('sync'));
    const userEmail = email ?? resetEmail;

    if (!userEmail) {
      throw new Error('No means to contact user, aborting');
    }

    try {
      const credentials = { email: userEmail, session: syncSessionId } as ConfirmResetCredentials;
      await resendResetCode(credentials);

      dispatch(setSuccess(InviolaAppSuccess.ResetCodeResent));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.ResetFormError));
    }
  }
);
