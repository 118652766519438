import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useBarcode } from 'react-barcodes';

import { Loader } from '@app/components';
import { useInviolaCustomer, usePointsSyncState } from '@app/components/Inviola/InviolaHooks';
import { useCustomerPointsUpdate } from '../ProfilePageHooks';

interface InviolaCardProps {
  frontSide: boolean;
}

const InviolaCard = ({ frontSide = true }: InviolaCardProps): React.ReactElement | null => {
  const customer = useInviolaCustomer();
  const syncInProgress = usePointsSyncState();

  useCustomerPointsUpdate();

  return customer ? (
    <Card>
      {syncInProgress && (<Loader overflowMode small color="white" />)}
      <Container frontSide={frontSide} syncInProgress={syncInProgress}>
        <FrontSide
          cardNumber={customer.cardNumber}
          balanceCount={customer.balancePoints}
        />
        <BackSide cardNumber={customer.fidelyCode ? customer.fidelyCode : customer.cardNumber} />
      </Container>
    </Card>
  ) : null;
};

interface FrontSideProps {
  cardNumber: string;
  balanceCount: number;
}

const FrontSide = ({ cardNumber, balanceCount }: FrontSideProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <CardFront>
      <CardContent>
        <Logo>
          <img src="/images/logo.svg" alt={formatMessage({ id: 'mainLogo.alt' })} />
        </Logo>

        <Balance>
          <BalanceCount>{balanceCount}</BalanceCount>
          <Text>{formatMessage({ id: 'inviola.profile.card.balanceLegend' })}</Text>
        </Balance>

        <CardInfoFront>
          {!!cardNumber && (<CardNumber frontSide>{cardNumber}</CardNumber>)}
        </CardInfoFront>
      </CardContent>
    </CardFront>
  );
};

interface BackSideProps {
  cardNumber: string;
}

const BackSide = ({ cardNumber }: BackSideProps): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { inputRef } = useBarcode({
    value: cardNumber,
    options: {
      height: 80,
    },
  });

  const barcodeRef = inputRef as React.RefObject<SVGSVGElement>;

  return (
    <CardBack>
      <CardContent>
        <Barcode hidden={!cardNumber}>
          <svg ref={barcodeRef} />
        </Barcode>
      </CardContent>
    </CardBack>
  );
};

const Card = styled.div`
  position: relative;
  background: transparent;
  width: 100%;
  max-width: 370px;
  height: 220px;

  margin: 0 auto;
  perspective: 1000px;
`;

const Container = styled.div<{ frontSide: boolean; syncInProgress: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  transition: all 0.6s;
  transform-style: preserve-3d;
  transform: ${({ frontSide }): string => (frontSide ? 'unset' : 'rotateY(180deg)')};
  opacity: ${({ syncInProgress }): number => (syncInProgress ? 0.69 : 1)};
  z-index: 10;
`;

const CardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: ${(props): string => props.theme.color.purple};
  color: white;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 99;
`;

const CardBack = styled(CardFront)`
  transform: rotateY(180deg);
  color: black;
  background-color: white;
  z-index: 100;
`;

const CardContent = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Logo = styled.div`
  max-width: 62px;

  img {
    width: 100%;
    height: auto;
  }
`;

const Balance = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  text-align: right;
`;

const BalanceCount = styled.p`
  font-size: 42px;
  line-height: normal;
  font-weight: bold;
  margin: 0;
`;

const CardInfoFront = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
`;

const CardNumber = styled.div<{ frontSide?: boolean }>`
  color: ${(props): string => (props.frontSide ? props.theme.color.red : props.theme.color.purple)};

  font-size: 22px;
  font-weight: bold;
`;

const Text = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 12px;
  margin: 0;
`;

const Barcode = styled.div<{ hidden: boolean }>`
  height: auto;
  width: 100%;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  opacity: ${({ hidden }): number => (hidden ? 0 : 1)};

  svg {
    width: 100%;
    height: auto;
  }
`;

export default InviolaCard;
