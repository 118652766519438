import React from 'react';
import styled from 'styled-components';

import { WebTemplateDepartment } from '@app/types/webTemplateTypes';
import { Devices } from '@app/styles';
import { PageTitle } from '@app/components/PageTitle';
import WebTemplateSelect from '../WebTemplateSelect';
import { ContainerMedium } from '../components';

interface WebDepartmentElementProps {
  data: WebTemplateDepartment;
}

const WebDepartmentElement = ({ data }: WebDepartmentElementProps): React.ReactElement => (
  <div>
    {data?.content?.length > 0 && (
    <Container>
      {data?.title && (<DepartmentPageTitle>{data?.title}</DepartmentPageTitle>)}
      {data?.name && (<Name>{data?.name}</Name>)}
      <Content>
        {data?.content?.map((content) => content !== null && (
        <ContentItem key={content.id}>
          <WebTemplateSelect data={content} />
          {data?.showDivider && <Divider />}
        </ContentItem>
        ))}
      </Content>
      <RedDivider />
    </Container>
)}
  </div>
);

const RedDivider = styled.div`
  margin-top: 40px;
  width: 50px;
  height: 3px;
`;

const Container = styled(ContainerMedium)`
  &:last-of-type ${RedDivider} {
    display: none;
  }
`;

const DepartmentPageTitle = styled(PageTitle)`
  background-color: ${(props): string => props.theme.color.purple};
  color: #fff;
  padding: 7px 20px;
  width: fit-content;
`;

const Name = styled.h2`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  background-color: rgb(95 37 159);
  display: inline-block;
  padding: 7px 15px;
  margin-top: 25px;
  font-size: 12px;
  letter-spacing: 0.23px;
  text-transform: uppercase;
  color: #fff;

  @media ${Devices.largeMobile} {
    font-size: 12px
  }

  @media ${Devices.desktop} {
    font-size: 12px
  }
`;

const Divider = styled.div`
  display: none;
  width: 1px;
  height: 80%;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: 20px;

  @media ${Devices.desktop} {
    display: block;
  }
`;

const Content = styled.div`
  margin-top: 27px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ContentItem = styled.div`
  margin: 0 20px 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:nth-of-type(2n) {
    margin-right: 0;
  }

  @media ${Devices.largeMobile} {
    &:nth-of-type(2n) {
      margin-right: 20px;
    }
  }

  @media ${Devices.desktop} {
    &:last-of-type ${Divider}, &:nth-of-type(3n) ${Divider} {
      display: none;
    }
  }
`;

export default WebDepartmentElement;
