import {
  InviolaAppError, InviolaError, InviolaSuccessTypes, InviolaErrorTypes, HttpError,
} from '@app/services/inviola/types/errorTypes';
import {
  InviolaActionTypes, SetError, ResetError, SetSuccess,
} from '@app/store/actionTypes/inviolaActionTypes';
import { Action } from '@app/types/actionTypes';

export const setError = (error: InviolaError): SetError => ({
  type: InviolaActionTypes.SET_ERROR,
  payload: error,
});

export const resetErrors = (): ResetError => ({
  type: InviolaActionTypes.RESET_ERROR,
});

export const setSuccess = (success: InviolaSuccessTypes): SetSuccess => ({
  type: InviolaActionTypes.SET_SUCCESS,
  payload: success,
});

export const handleInviolaError = (error: unknown, type: InviolaErrorTypes): Action => (
  (dispatch): void => {
    if (error instanceof HttpError) {
      dispatch(setError({ code: error.code, type }));
    } else {
      dispatch(setError({ code: 10500, type: InviolaAppError.RegistrationFormError }));
      console.error(error, type);
    }
  }
);
