import React from 'react';

import { EditorialContentTypes } from '@app/types/newsTypes';

import { getLatestVideo, resetLatestVideo } from '@app/store/actions/newsActions';

import { Metadata } from '@app/components';
import { NewsDetailsPageWrapper } from '@app/components/News';
import {
  useLatestNews,
  useNewsDetailsPath,
  useNewsDetailsMultiLangRoute,
  useNewsDetailsPageValidation,
} from '@app/components/Hooks';
import { useAllNewsUrlParams } from '@app/components/Hooks/NewsHooks';
import { useIsConnected } from '@app/components/Inviola/InviolaHooks';

import { useDownloadVideo, useVideo, useVideoSeo } from './VideoDetailsHooks';
import VideoContent from './VideoContent';

const VideoDetails: React.FC = () => {
  useDownloadVideo();
  useNewsDetailsMultiLangRoute(EditorialContentTypes.VIDEO);
  const headerLinksParams = useAllNewsUrlParams(EditorialContentTypes.VIDEO);

  const video = useVideo();
  const seo = useVideoSeo();

  const isConnected = useIsConnected();
  const isPremium = video?.isPremium ?? false;
  const autoPlay = !isPremium || (isPremium && isConnected);

  const latestNews = useLatestNews({
    type: EditorialContentTypes.VIDEO,
    requestLatestNews: getLatestVideo,
    resetLatestNews: resetLatestVideo,
  });

  const error = useNewsDetailsPageValidation(video);
  const redirectPath = useNewsDetailsPath(video);

  return (
    <NewsDetailsPageWrapper
      error={error}
      latestNews={latestNews}
      latestNewsHeaderLabelId="video.lastVideos"
      headerLinksParams={headerLinksParams}
      isPremium={isPremium}
      redirectPath={redirectPath}
    >
      {seo && <Metadata seo={seo} />}
      {video && <VideoContent video={video} category={video.categoryCode} key={video.id} autoPlay={autoPlay} />}
    </NewsDetailsPageWrapper>
  );
};

export default VideoDetails;
