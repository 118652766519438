import React from 'react';
import { Coords } from 'google-map-react';
import styled from 'styled-components';

interface MapMarkerProps extends Coords {
  text: string;
  active: boolean;
  onClick ?: () => void;
}

const markerSizes = {
  width: [25, 50],
  height: [35, 70],
};

const MapMarker = React.memo(({ text, active, onClick }: MapMarkerProps): React.ReactElement | null => {
  const sizeIndex: number = +!!(active);

  return (
    <Marker
      onClick={onClick}
      width={markerSizes.width[sizeIndex]}
      height={markerSizes.height[sizeIndex]}
      active={active}
    >
      <img src="/images/map/marker.png" alt={text} title={text} />
    </Marker>
  );
});

const Marker = styled.div<{ width: number, height: number, active: boolean }>`
  height: ${(props): number => props.height}px;
  width: ${(props): number => props.width}px;

  position: absolute;
  left: -${(props): number => (props.width / 2)}px;
  top: -${(props): number => (props.height / 2)}px;

  cursor: pointer;

  filter: ${(props): string => (props.active ? 'drop-shadow(2px 0 4px #333)' : 'none')};

  z-index: ${(props): number => (props.active ? 2 : 1)};

  & img {
    width: inherit;
    height: inherit;
  }
`;

export default MapMarker;
