import React, { useState } from 'react';
import styled from 'styled-components';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

interface LiveAudioControlsProps {
  volume: number;
  paused: boolean;

  onPlay: () => void;
  onPause: () => void;
  onChangeVolume: (value: number) => void;
  onStop: () => void;
}

const LiveAudioControls = ({
  volume, paused, onPlay, onPause, onStop, onChangeVolume,
}: LiveAudioControlsProps): React.ReactElement => {
  const [currentVolume, setCurrentVolume] = useState(volume);
  const onChangeCurrentVolume = (value: number) => {
    setCurrentVolume(value);
    onChangeVolume(value);
  };

  return (
    <Wrapper>
      <Container>
        <VolumeLow src="/images/liveaudio/volume-low.svg" alt="volume-low" />
        <VolumeRange>
          <InputRange
            maxValue={100}
            minValue={0}
            value={currentVolume}
            onChange={(value) => {
              onChangeCurrentVolume(value as number);
            }}
          />
        </VolumeRange>
        <VolumeHigh src="/images/liveaudio/volume-high.svg" alt="volume-high" />
        {paused && (<PlayPause src="/images/liveaudio/play.svg" alt="play" onClick={onPlay} />)}
        {!paused && (<PlayPause src="/images/liveaudio/pause.svg" alt="pause" onClick={onPause} />)}
        <Stop src="/images/liveaudio/stop.svg" alt="stop" onClick={onStop} />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: block;
  position: absolute;
  top: -70px;
  right: 0;
  padding-bottom: 70px;
`;

const Container = styled.div`
  display: flex;
  height: 48px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.49);
  background-color: ${(props): string => props.theme.color.whiteSmoke};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  & > img {
    height: 18px;
    width: 18px;
  }

`;

const VolumeLow = styled.img`
  margin-right: 12px;
  cursor: default;
`;

const VolumeHigh = styled.img`
  margin-left: 12px;
  cursor: default;
`;

const VolumeRange = styled.div`
  width: 105px;

  .input-range__track--active,
  .input-range__slider {
    background: ${(props): string => props.theme.color.purple};
    border-color: ${(props): string => props.theme.color.purple};
  }
  .input-range__slider {
    width: 12px;
    height: 12px;
    margin-top: -8px;
    margin-left: -6px;
  }
  .input-range__track--background {
    background: rgba(95, 37, 159, 0.38);
  }
  .input-range__label {
    display: none;
  }
`;

const PlayPause = styled.img`
  margin-left: 35px;
`;

const Stop = styled.img`
  margin-left: 35px;
`;

export default LiveAudioControls;
