import { routerMiddleware } from 'connected-react-router';
import {
  createStore, applyMiddleware, compose, Store,
} from 'redux';
import { Persistor, persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory, createMemoryHistory, History } from 'history';

import createRootReducer from '@app/store/reducers';
import AppState from '@app/types/appStateTypes';

/**
  IMPORTANT: this export is deprecated!
  It is required to be able to use subscribe method of Redux store on both client and server. Please use it widely!
*/
export const reduxStore: { store: Store<AppState> } = {
  store: {} as Store<AppState>,
};

export default (isServer = false, url = ''): {
  store: Store;
  history: History;
  persistor?: Persistor;
} => {
  const history = isServer
    ? createMemoryHistory({ initialEntries: [url] })
    : createBrowserHistory();

  let reduxDevTool;
  if (!isServer) {
    /* eslint-disable-next-line */
    reduxDevTool = window.__REDUX_DEVTOOLS_EXTENSION__;
  }

  const middleware = applyMiddleware(routerMiddleware(history), thunkMiddleware);
  const storeWithMiddleware = reduxDevTool
    ? compose(middleware, reduxDevTool())
    : compose(middleware);

  // eslint-disable-next-line
  const initialState = (isServer ? {} : window.__PRELOADED_STATE__) as unknown as AppState;

  if (!isServer) {
    // eslint-disable-next-line
    delete window.__PRELOADED_STATE__;
  }

  const store = createStore(
    createRootReducer(history),
    initialState,
    storeWithMiddleware,
  );

  let persistor;
  if (!isServer) {
    persistor = persistStore(store);
  }

  reduxStore.store = store;

  return { store, history, persistor };
};
