import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { WebTemplateInviolaCatalogItem } from '@app/types/webTemplateTypes';
import { Name, Points } from '@app/pages/Inviola/CatalogPage/components/CatalogItemComponents';
import FavoritesIcon from '@app/pages/Inviola/CatalogPage/components/FavoritesIcon';
import RequestItemButton from '@app/pages/Inviola/CatalogPage/components/RequestItemButton';
import MoreInfoButton from '@app/pages/Inviola/CatalogPage/components/MoreInfoButton';

interface InviolaCatalogItemElementProps {
  data: WebTemplateInviolaCatalogItem;
}

const WebInviolaCatalogItemElement = ({ data }: InviolaCatalogItemElementProps): React.ReactElement => (
  <CatalogItem>
    <FavoritesIcon item={data?.item} />
    <ImageContainer>
      <ItemImage src={data?.item.pathImageAbsolute} alt={data?.item.name} />
    </ImageContainer>
    <WebPoints>
      <span>
        {data?.item.points}
        &nbsp;
        <FormattedMessage id="inviola.catalog.pts" />
      </span>
    </WebPoints>
    <WebName>
      { data?.item.name }
    </WebName>
    <ButtonsContainer>
      <RequestItemButton item={data?.item} />
      <MoreInfoButton item={data?.item} />
    </ButtonsContainer>
  </CatalogItem>
);

const CatalogItem = styled.div`
  position: relative;
  background-color: #fff;
  width: 100%;
  min-height: 330px;
  height: 100%;
  padding: 45px 35px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: auto;
  max-height: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  & > * {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const WebName = styled(Name)`
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 0;
  margin: 0;
`;

export const WebPoints = styled(Points)`
  position: relative;
  top: unset;
  right: unset;
  width: auto;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  padding: 0 20px;
  font-size: 26px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.29px;
`;

export const ItemImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export default WebInviolaCatalogItemElement;
