import React from 'react';

import { WebTemplateData } from '@app/types/webTemplateTypes';
import { ContainerFullWidth } from '@app/components/WebTemplates/components';
import HomeSelect from './HomeSelect';

interface WebTemplateContainerProps {
  data: WebTemplateData[] | null;
}

const HomeContainer = ({ data }: WebTemplateContainerProps): React.ReactElement => (
  <ContainerFullWidth>
    {data?.filter((data) => data !== null).map(
      (data) => (<HomeSelect key={data?.id} data={data} />),
    )}
  </ContainerFullWidth>
);

export default HomeContainer;
