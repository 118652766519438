import { LanguageType, LangListType } from '@app/types/localizationTypes';

export const AppLanguages: LangListType = ['en', 'it'];

export const AppLanguagesMap: {
  [key in LanguageType]: LanguageType;
} = {
  en: 'en',
  it: 'it',
};

export const langCodeToKenticoLangMap: Record<LanguageType, string> = {
  en: 'english',
  it: 'italian',
};

export const langCodeToSalesForceLangMap: Record<LanguageType, string> = {
  en: 'en_US',
  it: 'it',
};

export const langCodeToLanguageTerritory: Record<LanguageType, string> = {
  en: 'en_US',
  it: 'it_IT',
};

export const KenticoLangToLangCodeMap: Record<string, LanguageType> = {
  italian: 'it',
  english: 'en',
};
