import React, { useState } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { InviolaAppError, InviolaAppSuccess } from '@app/services/inviola/types/errorTypes';

import InviolaWrapper from '@app/components/Inviola/InviolaWrapper';

import {
  useInviolaSync, useSyncInProgressSelector, useIsConnected, useSuccess, useInviolaPath,
  useCustomerRedirectionPath, useCustomerRedirectionPathReset,
} from '@app/components/Inviola/InviolaHooks';
import { Container, Column, Row, Title } from '@app/components/Inviola/InviolaComponents';

import { Devices } from '@app/styles';
import { Loader } from '@app/components';
import { InviolaErrorMessage } from '@app/components/Inviola/InviolaMessages';
import { useCommonPageBaseMultiLangRoute } from '@app/pages/CommonPage/CommonPageHooks';

import LoginForm from './components/LoginForm';
import RegistrationWizard from './components/RegistrationWizard';

const InviolaAuthPage = (): React.ReactElement => {
  useCommonPageBaseMultiLangRoute();
  useInviolaSync();
  useCustomerRedirectionPathReset();

  const formSuccess = useSuccess();
  const { formatMessage } = useIntl();

  const isConnected = useIsConnected();
  const syncInProgress = useSyncInProgressSelector();
  const redirectionPath = useCustomerRedirectionPath();
  const { profilePath, resetPath } = useInviolaPath();
  const [registrationActive, setRegistrationActive] = useState(false);

  return (
    <InviolaWrapper withBanner withAuthCheck={false}>
      { isConnected && <Redirect to={redirectionPath ?? profilePath} /> }
      { formSuccess && formSuccess === InviolaAppSuccess.ResetCodeResent && <Redirect to={resetPath} /> }

      <StyledContainer syncInProgress={syncInProgress}>
        {syncInProgress && (<Loader overflowMode />)}
        <Row>
          <StyledColumn>
            <ErrorWrapper>
              <InviolaErrorMessage errorType={InviolaAppError.AuthError} />
            </ErrorWrapper>
          </StyledColumn>
        </Row>
        <Row>
          <TabsContainer>
            <Tab active={!registrationActive} onClick={() => setRegistrationActive(false)}>
              { formatMessage({ id: 'inviola.login.title' }) }
            </Tab>
            <Tab active={registrationActive} onClick={() => setRegistrationActive(true)}>
              { formatMessage({ id: 'inviola.registration.title' }) }
            </Tab>
          </TabsContainer>
        </Row>
        <Row>
          <StyledColumn>
            {registrationActive ? <RegistrationWizard /> : <LoginForm />}
          </StyledColumn>
        </Row>
      </StyledContainer>
    </InviolaWrapper>
  );
};

const StyledContainer = styled(Container)<{ syncInProgress: boolean }>`
  position: relative;
  opacity: ${({ syncInProgress }): number => (syncInProgress ? 0.5 : 1)};
  transition: all 0.6s;
  z-index: 10;
`;

const StyledColumn = styled(Column)`
  max-width: 600px;
  margin: auto;
`;

const ErrorWrapper = styled.div`
  margin-top: -60px;
  margin-bottom: 30px;

  &:empty {
    display: none;
  }
`;

export default InviolaAuthPage;

const TabsContainer = styled.div`
  display: flex;
  flex: 0 0 100%;

  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;

  justify-content: safe center;
`;

const Tab = styled(Title)<{ active?: boolean }>`
  padding: 5px 20px 0 20px;

  opacity: ${({ active }): number => (active ? 1 : 0.7)};
  transition: opacity 500ms ease-out;

  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;

  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:after {
    content: "";
    width: 90%;
    height: 4px;
    background: ${(props): string => props.theme.color.purple};
    visibility: ${({ active }): string => (active ? 'visible' : 'hidden')};
    margin: 5px auto 0 auto;
    display: block;
  }

  @media ${Devices.tabletLarge} {
    font-size: 28px;
    padding: 5px 30px 0 30px;
  }
`;
