// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales';

import ProfilePageCard from '@app/pages/Inviola/ProfilePage/tabs/CardTab';
import ProfileBadges from '@app/pages/Inviola/ProfilePage/tabs/BadgesTab';
import ProfileSettings from '@app/pages/Inviola/SettingsPage/SettingsPage';
import MyAwards from '@app/pages/Inviola/AwardsPage/AwardsPage';
import { NavigationLinkParams } from '@app/types/routerTypes';
import { InviolaCatalogItem } from '@app/types/inviolaTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { InViolaLocationsPrepositions } from '@app/constants/inviolaConstants';

export interface Tab {
  name: string;
  titleKey: string;
  component: () => React.ReactElement;
  isVisible: boolean,
}

export const getProfileTabs = (): Tab[] => ([
  {
    name: 'card',
    titleKey: 'inviola.profile.tabs.card',
    component: ProfilePageCard,
    isVisible: true,
  },
  {
    name: 'my-badges',
    titleKey: 'inviola.profile.tabs.badges',
    component: ProfileBadges,
    isVisible: false,
  },
  {
    name: 'my-profile',
    titleKey: 'inviola.profile.tabs.settings',
    component: ProfileSettings,
    isVisible: true,
  },
  {
    name: 'my-prizes',
    titleKey: 'inviola.profile.tabs.prizes',
    component: MyAwards,
    isVisible: true,
  },
]).filter(({ isVisible }) => isVisible);

export const pathParamsFor = (pageName: string, thirdLevel = ''): NavigationLinkParams => ({
  pathKey: 'routes.commonPage',
  pathParams: {
    topLevel: 'inviola',
    secondLevel: pageName,
    thirdLevel,
    forthLevel: '',
    fifthLevel: '',
  },
});

interface stateOption {
  id: number | string;
  name: string;
}

interface selectOption {
  text: string;
  value: string;
}

export const mapSelectOptions = (options: stateOption[]): selectOption[] => {
  const capitalize = (text: string) => text
    .toLowerCase()
    .replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase())
    .split(' ')
    .map((value) => (
      InViolaLocationsPrepositions.includes(value.toLowerCase()) ? value.toLowerCase() : value
    ))
    .join(' ');

  return options.map((item) => ({
    text: capitalize(item.name),
    value: item.id.toString(),
  }));
};

// InVIOLA:CATALOG:HELPERS
export const getCatalogPreviewList = (items: InviolaCatalogItem[], selectedItemId?: number): InviolaCatalogItem[] => {
  const selectedItem = items
    .find(({ id }) => id === selectedItemId);
  const categoryList = items
    .filter(({ id, category }) => category.id === selectedItem?.category?.id && id !== selectedItemId);

  const list: InviolaCatalogItem[] = [];

  switch (true) {
    case categoryList.length > 12:
      for (let i = 0; i < categoryList.length; i += Math.floor(categoryList.length / 12)) {
        list.push(categoryList[i]);
      }
      return list;
    case categoryList.length === 12:
      return categoryList;
    case categoryList.length > 0:
      // TODO
      return categoryList;
    case categoryList.length === 0: default:
      for (let i = 0; i < items.length; i += Math.floor(items.length / 12)) {
        list.push(items[i]);
      }
      return list;
  }
};

export const validateFiscalCode = (code = '', language: LanguageType): boolean | string => {
  const fiscalCode = code.toUpperCase();
  const setBase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const set1 = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const set2 = 'ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const setPari = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const setDisp = 'BAKPLCQDREVOSFTGUHMINJWZYX';
  const error = `${Translations[language]['inviola.form.invalidFiscalCode']}`;

  let checkSum = 0; let i = 0;

  if (fiscalCode.length === 0) return true;
  if (fiscalCode.length !== 16) return error;

  for (i = 0; i < 16; i++) if (setBase.indexOf(fiscalCode.charAt(i)) === -1) return error;
  for (i = 1; i <= 13; i += 2) checkSum += setPari.indexOf(set2.charAt(set1.indexOf(fiscalCode.charAt(i))));
  for (i = 0; i <= 14; i += 2) checkSum += setDisp.indexOf(set2.charAt(set1.indexOf(fiscalCode.charAt(i))));

  return checkSum % 26 === fiscalCode.charCodeAt(15) - 'A'.charCodeAt(0) ? true : error;
};
