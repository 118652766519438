import styled, { DefaultTheme, ThemeProps } from 'styled-components';
import LocalizedLink from '@app/components/Localization/LocalizedLink';

const Button = (props: ThemeProps<DefaultTheme>): string => `
  width: 150px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background-color: transparent;
  color: white;
  font-family: ${props.theme.fontFamily.commutersSans};
  font-weight: bold;
  font-size: 10px;
  cursor: pointer;
  line-height: 1;
  transition: background-color 0.3s ease-in, color 0.3s ease-in, border 0.3s ease-in;
  border: 2px solid #ffffff;

  & h3 {
    font-size: 11px;
    display: flex;
    align-items: center;
  }

  @media (pointer: fine) {
    &:hover {
      background-color: #ffffff;
      color: ${props.theme.color.purple};
      border: 2px solid #ffffff;
    }
  }
`;

export const CommonButton = styled.button`
  ${Button}
`;

export const CommonButtonLink = styled(LocalizedLink)`
  ${Button}
`;
