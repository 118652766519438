import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import styled, { createGlobalStyle } from 'styled-components';

import { Devices } from '@app/styles';
import { CloseButton } from '@app/components/Buttons';
import { isServer } from '@app/helpers/serverHelpers';

interface ModalProps {
  children?: JSX.Element[] | JSX.Element | null | undefined;
  onClose: () => void;
  controls?: boolean;
  open: boolean;
  disableClose?: boolean;
  contentFullWidth?: boolean
}

const Modal = ({
  children, open, onClose, controls = true, disableClose = false, contentFullWidth = true,
}: ModalProps): React.ReactElement | null => {
  const useOpen = open && !isServer(); // [SSR] DISABLE:MODAL:ON:SERVER:FOR:CORRECT:RENDERING
  const modalRef = useRef<HTMLDivElement>(null);

  const [modalOpen, setModalOpen] = useState(useOpen);

  const onModalClose = (): void => { if (!disableClose) onClose(); };
  const onKeyDownClose = ({ keyCode }: { keyCode: number }): void => { if (keyCode === 27) onModalClose(); };

  const toggleModalWindowMode = (): void => {
    if (useOpen) {
      setModalOpen(true);
      setTimeout(() => {
        if (modalRef.current) disableBodyScroll(modalRef.current);
        document.addEventListener('keydown', onKeyDownClose, true);
      }, 300);
    } else {
      setTimeout(() => {
        setModalOpen(false);
        clearAllBodyScrollLocks();
        document.removeEventListener('keydown', onKeyDownClose, true);
      }, 300);
    }
  };

  useEffect(() => {
    toggleModalWindowMode(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    modalOpen
      ? ReactDOM.createPortal(
        <ModalGlobalWrapper>
          {contentFullWidth && (
            <CloseButtonWrapper contentFullWidth={contentFullWidth}>
              {controls && <CloseButton onClick={onModalClose} />}
            </CloseButtonWrapper>
          )}
          <ModalContainer isModalClosed={!useOpen} contentFullWidth={contentFullWidth} ref={modalRef}>
            {!contentFullWidth && (
              <CloseButtonWrapper contentFullWidth={contentFullWidth}>
                {controls && <CloseButtonInner onClick={onModalClose} src="/images/close-dialog.svg" alt="Close" />}
              </CloseButtonWrapper>
            )}
            {children}
          </ModalContainer>
          <ModalCover onClick={onModalClose} />
          <ModalGlobalPrintStyle />
        </ModalGlobalWrapper>,
        document.body,
      )
      : null
  );
};

export default Modal;

const ModalGlobalPrintStyle = createGlobalStyle`
  @media print {
    body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      width: 100%;
      height: 100%;
      background-color: white;
    }

    #root {
      display: none;
    }
  }
`;

const ModalGlobalWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999999;
  color: white;
  background-color: transparent;
  animation: animateShow 0.5s ease;

  @keyframes animateShow {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ModalCover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  z-index: 998;

  @media print {
    display: none;
  }
`;

const ModalContainer = styled.div<{ contentFullWidth: boolean; isModalClosed: boolean }>`
  position: relative;
  overflow: auto;
  z-index: 999;
  ${({ contentFullWidth }): string => (contentFullWidth ? `
    flex: 0 0 100%;
    height: 100%;
  ` : '')}

  -webkit-animation: ${({ isModalClosed }): string => (isModalClosed
    ? 'scale-out-center .3s cubic-bezier(.55,.085,.68,.53) both'
    : 'scale-in-center .3s cubic-bezier(.25,.46,.45,.94) both'
  )};
  animation: ${({ isModalClosed }): string => (isModalClosed
    ? 'scale-out-center .3s cubic-bezier(.55,.085,.68,.53) both'
    : 'scale-in-center .3s cubic-bezier(.25,.46,.45,.94) both'
  )};
`;

const CloseButtonWrapper = styled.div<{ contentFullWidth: boolean }>`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999999;

  @media ${Devices.tablet} {
    top: 15px;
    right: 15px;
  }

  @media ${Devices.desktop} {
    top: ${({ contentFullWidth }): string => (contentFullWidth ? '15px' : '30px')};
    right: ${({ contentFullWidth }): string => (contentFullWidth ? '15px' : '30px')};
  }

  @media print {
    display: none;
  }
`;

const CloseButtonInner = styled.img`
  cursor: pointer;
`;
