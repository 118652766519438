import * as React from 'react';
import styled, { Keyframes, keyframes } from 'styled-components';
import { theme } from '@app/styles';

interface CircleGraphProps {
  width: number;
  height: number;
  value: number;
  strokeWidth?: number;
  children?: JSX.Element;
  strokeHomeColor?: string;
  strokeAwayColor?: string;
}

export const CircleGraph = ({
  width, height, value, strokeWidth = 5, children,
  strokeHomeColor = 'rgba(157,157,157,0.1)', strokeAwayColor = theme.color.shadow,
}: CircleGraphProps): React.ReactElement => {
  const center = { x: width / 2, y: height / 2 };
  const radius = (center.x < center.y ? center.x : center.y) - strokeWidth / 2;
  return (
    <Wrapper width={width} height={height}>
      <SvgStyled width="100%" height="100%" radius={radius} value={value} viewBox={`0 0 ${width} ${height}`}>
        <circle
          cx={center.x}
          cy={center.y}
          r={radius}
          stroke={strokeHomeColor}
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDashoffset="0"
        />
        <circle
          cx={center.x}
          cy={center.y}
          r={radius}
          stroke={strokeAwayColor}
          strokeWidth={strokeWidth}
          fill="transparent"
          className="animated"
        />
      </SvgStyled>
      <InnerWrapper>
        {children}
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ width: number; height: number }>`
  position: relative;
  z-index: 100;
  font-size: 0;
  padding: 3px;
  max-width: ${({ width }): string => `${width}px`};
  max-height: ${({ height }): string => `${height}px`};
`;

const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface RotateProps {
  radius: number;
  value: number;
}

const rotate = ({ radius, value }: RotateProps): Keyframes => keyframes`
  to {
    stroke-dashoffset: ${2 * Math.PI * radius * (1 - value)};
  }
`;

const SvgStyled = styled.svg<{ radius: number; value: number }>`
  transform: rotate(-90deg);
  stroke-dasharray: ${({ radius }): number => (2 * Math.PI * radius)};
  stroke-dashoffset: ${({ radius }): number => (2 * Math.PI * radius)};

  & .animated {
    animation: ${({ radius, value }): Keyframes => rotate({ radius, value })} 500ms ease-out forwards;
  }
`;
