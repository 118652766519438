export enum InviolaResetSteps {
  ResetAsked = 'resetAsked',
  ResetConfirmation = 'resetConfirmation',
  ResetSuccess = 'resetSuccess'
}

export enum InviolaRegistrationSteps {
  RegistrationInit = 'registration',
  // CASE 1: CARD WITH LINKED EMAIL
  RegistrationCardWithEmailConfirmation = 'registrationCardWithEmailConfirmation',
  RegistrationCardWithEmailValidated = 'registrationCardWithEmailValidated',
  // CASE 2: CARD WITH NO LINKED EMAIL
  RegistrationCardWithoutEmailValidated = 'registrationCardWithoutEmailValidated',
  RegistrationCardWithoutEmailConfirmation = 'registrationCardWithoutEmailConfirmation',
  // CASE 3: NO CARD
  RegistrationWithoutCardConfirmation = 'registrationWithoutCardConfirmation',
  RegistrationSuccess = 'registrationSuccess'
}
