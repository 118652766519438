import React from 'react';
import styled from 'styled-components';

const MatchStandings = (): React.ReactElement => (
  <Container>
    <h3>Match Standings</h3>
  </Container>
);

export default MatchStandings;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;
