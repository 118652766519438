import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { Loader, Modal } from '@app/components';
import { Button, ButtonLink, ButtonOutline } from '@app/components/Inviola/InviolaComponents';

import { useLanguage } from '@app/components/Hooks';
import { useSuccess, useInviolaPath, usePointsSyncState } from '@app/components/Inviola/InviolaHooks';
import { InviolaAppSuccess, InviolaAppError } from '@app/services/inviola/types/errorTypes';
import { InviolaCatalogItem } from '@app/types/inviolaTypes';
import { requestAward, resetErrors } from '@app/store/actions/inviolaActions';
import { InviolaErrorMessage } from '@app/components/Inviola/InviolaMessages';
import { setRequestAwardDone } from '@app/store/actions/inviolaActions/catalog';

interface RequestItemModalModalProps {
  open: boolean;
  onClose: () => void;
  item: InviolaCatalogItem;
}

const RequestItemModal = ({ open, item, onClose }: RequestItemModalModalProps): React.ReactElement => {
  const { awardsPath } = useInviolaPath();
  const requestResult = useSuccess();
  const language = useLanguage();

  const syncInProgress = usePointsSyncState();
  const dispatch = useDispatch();
  const history = useHistory();

  const onRequestItem = (): void => {
    dispatch(resetErrors());
    dispatch(requestAward(item.id, language));
  };

  const onRequestItemModalClose = (): void => {
    dispatch(setRequestAwardDone());
    dispatch(resetErrors());
    onClose();
  };

  const onRequestItemModalCloseWithRedirect = (): void => {
    onRequestItemModalClose();
    setTimeout(() => {
      history.push(awardsPath);
    }, 100);
  };

  return (
    <Modal open={open} onClose={onRequestItemModalClose} disableClose={syncInProgress} contentFullWidth={false}>
      <ModalWrapper>
        {syncInProgress && (<Loader overflowMode />)}
        <ModalContainer syncInProgress={syncInProgress}>
          {requestResult === InviolaAppSuccess.AwardRequested ? (
            <ModalSuccess>
              <p><FormattedMessage id="inviola.catalog.request.successMessage" /></p>
              <Button onClick={onRequestItemModalCloseWithRedirect}>
                <FormattedMessage id="inviola.catalog.request.closeButton" />
              </Button>
            </ModalSuccess>
          ) : (
            <>
              <Message>
                <FormattedMessage id="inviola.catalog.request.confirmMessage" />
              </Message>
              <InviolaErrorMessage errorType={InviolaAppError.RequestAwardError} />
              <ButtonsContainer>
                <Button onClick={onRequestItem}>
                  <FormattedMessage id="inviola.catalog.request.yes" />
                </Button>
                <ButtonOutline onClick={onRequestItemModalClose}>
                  <FormattedMessage id="inviola.catalog.request.no" />
                </ButtonOutline>
              </ButtonsContainer>
            </>
          )}
        </ModalContainer>
      </ModalWrapper>
    </Modal>
  );
};

export default RequestItemModal;

const ModalWrapper = styled.div`
  position: relative;
  width: 100%;

  background-color: ${(props): string => props.theme.color.whiteSmoke};
`;

const ModalContainer = styled.div<{ syncInProgress: boolean }>`
  width: 100%;
  max-width: 95vw;
  margin: auto;
  padding: 60px 40px;

  color: black;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  text-align: left;

  opacity: ${({ syncInProgress }): number => (syncInProgress ? 0.5 : 1)};
  transition: opacity 0.2s ease;

  button {
    margin-bottom: 0;
  }

  @media ${Devices.tablet} {
    max-width: 600px;
    padding: 50px 80px;
  }
`;

const ModalSuccess = styled.div`
  ${ButtonLink} {
    margin-bottom: 0
  }
`;

const ButtonsContainer = styled.div`
  display: inline-flex;
  width: 100%;

  button {
    padding: 10px;
    font-size: .8rem;
    margin: 30px 15px 0;
  }
`;

const Message = styled.p`
  font-size: 1.375rem;
  font-weight: bold;

  text-transform: uppercase;
  text-align: center;
`;
