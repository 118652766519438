import { PlayerStatistics } from '@app/types/teamsTypes';
import React from 'react';

import {
  ValueAndCaption, BlockContainer, StatsBlock,
} from '@app/components/Statistics';

interface PerformanceStatisticsProps {
  statistics?: PlayerStatistics;
}

const DuelsStatistics = ({ statistics }: PerformanceStatisticsProps): React.ReactElement => (
  <BlockContainer>
    <StatsBlock borderWidth="0 1px 0 0">
      <ValueAndCaption value={statistics?.duelsWon ?? 0} caption="statistics.duelsWon" />
    </StatsBlock>
    <StatsBlock>
      <ValueAndCaption value={statistics?.successfulLaunches ?? 0} caption="statistics.successfulLaunches" />
    </StatsBlock>
  </BlockContainer>
);

export default DuelsStatistics;
