import React from 'react';
import styled from 'styled-components';

import {
  WebLandingSize,
  WebLandingType,
  WebTemplateLanding,
} from '@app/types/webTemplateTypes';
import { PageTitle } from '@app/components';
import { Devices } from '@app/styles';

import WebTemplateSelect from '../WebTemplateSelect';
import { ContainerLarge, ContainerMedium } from '../components';

type WebLandingContainerProps = React.PropsWithChildren<{
  size?: WebLandingSize;
}>

const Container = ({ size, children }: WebLandingContainerProps): React.ReactElement | null => {
  switch (size) {
    case WebLandingSize.Big:
      return <ContainerLarge>{children}</ContainerLarge>;
    default:
      return <ContainerMedium>{children}</ContainerMedium>;
  }
};

interface WebLandingElementProps {
  data: WebTemplateLanding;
}

const Title = ({ data }: WebLandingElementProps): React.ReactElement | null => {
  switch (data?.layoutType) {
    case WebLandingType.Covered:
      return (
        <TitleContainer>
          <TitleBlock>
            <PageTitleCovered>{data?.name}</PageTitleCovered>
          </TitleBlock>
        </TitleContainer>
      );
    default:
      return <PageTitle>{data?.name}</PageTitle>;
  }
};

const WebLandingElement = ({ data }: WebLandingElementProps):
  React.ReactElement | null => (
  data?.isVisible ? (
    <Container size={data?.size}>
      {data?.name && (<Title data={data} />)}
      <Content size={data?.size}>
        {data?.content?.map((content) => content !== null && (
          <WebTemplateSelect data={content} key={content.id} />
        ))}
      </Content>
    </Container>
  ) : null);

const Content = styled.div<{ size: WebLandingSize }>`
  margin-top: ${({ size }): string => (size === WebLandingSize.Big ? '0' : '20px')};;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
`;

const TitleContainer = styled.div`
  position: absolute;
  z-index: 5;
  top: -30px;
  left: 0;
  width: 100%;
`;

const TitleBlock = styled.div`
  background-color: ${(props): string => props.theme.color.purple};
  margin: 0 20px;
  height: 50px;
  padding: 0 30px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;

  @media ${Devices.largeMobile} {
    margin: 0 70px;
    height: 60px;
  }
`;

const PageTitleCovered = styled.h1`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: -0.01px;
  color: #ffffff;
  text-transform: uppercase;

  @media ${Devices.largeMobile} {
    font-size: 18px;
    line-height: 2.35;
  }
`;

export default WebLandingElement;
