import { ConfigurationActions, ConfigurationActionTypes } from '@app/store/actionTypes/configurationActionTypes';
import { LocalizedConfiguration } from '@app/types/configurationTypes';

export interface ConfigurationState extends LocalizedConfiguration {
  isConfigurationDownloadCompleted: boolean;
}

const initialState: ConfigurationState = {
  isConfigurationDownloadCompleted: false,
};

export default (
  state = initialState,
  action: ConfigurationActions,
): ConfigurationState => {
  switch (action.type) {
    case ConfigurationActionTypes.SET_CONFIGURATION:
      return {
        ...state,
        ...action.payload,
        isConfigurationDownloadCompleted: true,
      };
    default:
      return state;
  }
};
