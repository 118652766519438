import React from 'react';

import { Social } from '@app/types/configurationTypes';

const SocialIcons: React.FC<{social: Social[]; className?: string;}> = ({ social, className }) => (
  <>
    {
      social.map(({ icon, url, name }) => (
        <li key={name} className={className}>
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={icon?.url ?? ''}
              alt={icon?.description ?? ''}
            />
          </a>
        </li>
      ))
    }
  </>
);

export default SocialIcons;
