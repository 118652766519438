import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { InviolaFlags } from '@app/constants/inviolaConstants';

import { Modal } from '@app/components';
import { ButtonOutline, Column, Container, Row } from '@app/components/Inviola/InviolaComponents';
import { useInviolaCustomer, usePointsSyncState } from '@app/components/Inviola/InviolaHooks';

import InviolaCard from '../components/InviolaCard';
import PointsTable from '../components/PointsTable';
import TransferPoints from './modals/TransferPoints';

const ProfilePageCard = (): React.ReactElement => {
  const [frontSide, setFrontSide] = useState(true);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const { formatMessage } = useIntl();
  const syncInProgress = usePointsSyncState();
  const customer = useInviolaCustomer();
  const balancePoints = customer?.balancePoints ?? 0;

  return (
    <Container>
      <Row>
        <InviolaCardColumn>
          <InviolaCard frontSide={frontSide} />
          <ButtonsBlock>
            <ButtonOutline
              onClick={() => setFrontSide(!frontSide)}
              disabled={syncInProgress}
            >
              {formatMessage({ id: 'inviola.profile.card.toggle' })}
            </ButtonOutline>
            {InviolaFlags.ShowProfileExtras && (
              <ButtonOutline
                onClick={(): void => setIsModalOpened(true)}
                disabled={syncInProgress || balancePoints < 999}
              >
                {formatMessage({ id: 'inviola.profile.card.pointsTransfer' })}
              </ButtonOutline>
            )}
          </ButtonsBlock>
        </InviolaCardColumn>
        {InviolaFlags.ShowProfileExtras && (
          <Column>
            <PointsTable />
          </Column>
        )}
      </Row>
      <Modal
        open={isModalOpened}
        onClose={(): void => setIsModalOpened(false)}
        contentFullWidth={false}
        disableClose={syncInProgress}
      >
        <TransferPoints onClose={(): void => setIsModalOpened(false)} />
      </Modal>
    </Container>
  );
};

const InviolaCardColumn = styled(Column)`
  justify-content: flex-start;
  align-items: flex-start;
`;

const ButtonsBlock = styled.div`
  width: 90%;
  max-width: 315px;

  margin: 20px auto;
  margin-bottom: 2rem;
`;

export default ProfilePageCard;
