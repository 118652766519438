import cloneDeep from 'lodash/cloneDeep';
import { TeamsActions, TeamsActionTypes } from '@app/store/actionTypes/teamsActionTypes';
import { LocalizedTeam, TeamStatistics } from '@app/types/teamsTypes';
import { TeamSubMenuId } from '@app/types/configurationTypes';
import { CareerItem } from '@app/services/careers/types/responseTypes';
import { Errors } from '@app/types/errorTypes';

export type TeamsState = {
  teams: {[propName in TeamSubMenuId]?: LocalizedTeam};
  teamsStatistics: {[propName in TeamSubMenuId]?: TeamStatistics};
  playerCareers: Array<CareerItem>;
  error: Errors;
};

const initialState: TeamsState = {
  teams: {},
  teamsStatistics: {},
  playerCareers: [],
  error: null,
};

export default (
  state: TeamsState = cloneDeep(initialState),
  action: TeamsActions,
): TeamsState => {
  switch (action.type) {
    case TeamsActionTypes.SET_TEAM:
      return {
        ...state,
        teams: {
          ...state.teams,
          [action.payload.type]: {
            ...state.teams[action.payload.type],
            ...action.payload.players,
          },
        },
        error: null,
      };
    case TeamsActionTypes.RESET_ALL_TEAMS:
      return {
        ...state,
        teams: { ...initialState.teams },
        error: null,
      };
    case TeamsActionTypes.SET_TEAM_STATISTICS:
      return {
        ...state,
        teamsStatistics: { ...state.teamsStatistics, ...{ [action.payload.type]: action.payload.statistics } },
        error: null,
      };
    case TeamsActionTypes.RESET_ALL_TEAMS_STATISTICS:
      return {
        ...state,
        teamsStatistics: { ...initialState.teamsStatistics },
        error: null,
      };
    case TeamsActionTypes.SET_CAREER:
      return {
        ...state,
        playerCareers: action.payload,
        error: null,
      };
    case TeamsActionTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
