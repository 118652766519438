import React from 'react';
import styled from 'styled-components';
import { Devices } from '@app/styles';
import { WebTemplatePerson } from '@app/types/webTemplateTypes';

interface PersonParamsProps {
  data: WebTemplatePerson;
}

const WebPersonElement = ({ data }: PersonParamsProps): React.ReactElement => (
  <PersonItem>
    <PersonItemImage img={data.photo.url} />
    <PersonItemBottom>
      <PesonInfo>{data.firstName}</PesonInfo>
      <PesonInfo>{data.lastName}</PesonInfo>
      <JobPosition>{data.position}</JobPosition>
      <ResumeLink href={data.resume}>Download this resume</ResumeLink>
    </PersonItemBottom>
  </PersonItem>
);

export default WebPersonElement;

const PersonItem = styled.div`
  background-color: #fff;
  width: 215px;
`;

const PersonItemImage = styled.div<{img: string}>`
  width: 100%;
  height: 180px;
  background-size: cover;
  background-color: #c6c6c6;
  background-image: url(${(props) => props.img});
  margin-bottom: 13px;

  @media ${Devices.desktop} {
    height: 250px;
  }
`;

const PesonInfo = styled.div`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 20px;
  font-weight: bold;
`;

const PersonItemBottom = styled.div`
  padding-top: 13px;
  padding-bottom: 21px;
  padding-left: 20px;
  padding-right: 20px;
`;

const JobPosition = styled.div`
  font-size: 12px;
  font-weight: bold;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const ResumeLink = styled.a`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 11px;
  font-weight: bold;
  color: #5f259f;
  text-decoration: underline;
`;
