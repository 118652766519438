import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { WebTemplateMatchbox } from '@app/types/webTemplateTypes';
import { MatchResult } from '@app/types/matchTypes';
import { MatchDay } from '@app/components';
import { CarouselBase, CarouselItem } from '@app/components/Carousel/CarouselBase';
import { ContainerLarge } from '@app/components/WebTemplates/components';
import {
  useWidgetResultsSelector, useWidgetResultsDownload, useWidgetResetOnUnmount,
} from '@app/pages/Season/Schedule/ScheduleHooks';

const MatchBoxSlide: React.FC<SlideProps> = ({ data }) => (<MatchDay result={data} isWidget />);

interface SlideProps {
  data: MatchResult;
}

const WebMatchBox = ({ data }: { data: WebTemplateMatchbox }): React.ReactElement | null => {
  const results = useWidgetResultsSelector();
  const slides: CarouselItem[] = results.map((result) => ({
    item: result,
    ItemComponent: MatchBoxSlide,
  }));

  useWidgetResultsDownload(data.games);
  useWidgetResetOnUnmount();

  return results.length ? (
    <Container>
      <Wrapper>
        <CarouselBase
          items={slides}
          withSlidesCount={false}
          withDots={false}
          withFullWidthSlides
          arrowTransparent
        />
      </Wrapper>
    </Container>
  ) : null;
};

const Container = styled(ContainerLarge)`
  padding: 20px 10px;

  @media ${Devices.tablet} {
    padding: 50px 0;
  }
`;

const Wrapper = styled.div`
  background-color: ${({ theme }): string => theme.color.purple};
  overflow: hidden;
`;

export default WebMatchBox;
