import React from 'react';
import { useIntl } from 'react-intl';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { ConfirmResetCredentials } from '@app/services/inviola/types/requestTypes';
import { InviolaAppSuccess, InviolaAppError } from '@app/services/inviola/types/errorTypes';

import { confirmResetCustomerPassword, resendResetConfirmationCode } from '@app/store/actions/inviolaActions';

import { TextError, Button } from '@app/components/Inviola/InviolaComponents';
import { useInputValidations, useResetPassword } from '@app/components/Inviola/InviolaHooks';
import { Input } from '@app/components/Inviola/InviolaFields';
import { InviolaErrorMessage, InviolaSuccessMessage } from '@app/components/Inviola/InviolaMessages';

const ConfirmResetForm = (): React.ReactElement => {
  const { formatMessage } = useIntl();
  const methods = useForm<ConfirmResetCredentials>({ mode: 'onChange' });
  const dispatch = useDispatch();
  const { resetEmail } = useResetPassword();
  const validations = useInputValidations();

  const { register, errors, handleSubmit, formState: { isValid }, watch } = methods;

  const submit = (data: ConfirmResetCredentials) => {
    dispatch(confirmResetCustomerPassword(data));
  };

  const resendCode = () => {
    dispatch(resendResetConfirmationCode());
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)}>
        <input type="hidden" name="email" ref={register} defaultValue={resetEmail} />

        <Input type="text" labelId="inviola.resetConfirmation.verificationcode" name="verificationcode" validations={validations.required} />

        <Input type="password" labelId="inviola.resetConfirmation.newpassword" name="newpassword" validations={validations.required} />

        <Input
          type="password"
          name="confirmpassword"
          labelId="inviola.resetConfirmation.confirmpassword"
          validations={{
            required: true,
            validate: (value: string) => (value === watch('newpassword')),
          }}
        />
        { errors?.confirmpassword && <TextError>{formatMessage({ id: 'inviola.form.requiredAndEqual' })}</TextError> }

        <InviolaSuccessMessage
          type={InviolaAppSuccess.ResetCodeResent}
          translationId="inviola.resetConfirmation.resetcoderesent"
        />
        <InviolaErrorMessage errorType={InviolaAppError.ResetFormError} />

        <Button disabled={!isValid} type="submit">{formatMessage({ id: 'inviola.resetConfirmation.submit' })}</Button>
        <Button onClick={resendCode} type="button">{formatMessage({ id: 'inviola.resetConfirmation.resendCode' })}</Button>
      </form>
    </FormProvider>
  );
};

export default ConfirmResetForm;
