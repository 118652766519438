import { shallowEqual, useSelector } from 'react-redux';

import { useLanguage } from '@app/components/Hooks';
import { WebTemplatePartnerBottom } from '@app/types/webTemplateTypes';
import AppState from '@app/types/appStateTypes';
import { isBaseMenuItem } from '@app/helpers/menuHelpers';
import { useSecondLevelMenu, useThirdLevelMenu, useTopLevelMenu } from '@app/pages/CommonPage/CommonPageHooks';

export function usePartnersBottomData(): WebTemplatePartnerBottom | null | undefined {
  const language = useLanguage();
  return useSelector<AppState, WebTemplatePartnerBottom | null | undefined>(
    (state) => state.configuration[language]?.partners,
    shallowEqual,
  );
}

export function useIsPartnersVisible(): boolean {
  const topLevelMenu = useTopLevelMenu();
  const secondLevelMenu = useSecondLevelMenu();
  const thirdLevelMenu = useThirdLevelMenu();

  return !(thirdLevelMenu?.isPartnersInvisible
    || (secondLevelMenu && isBaseMenuItem(secondLevelMenu?.data) && secondLevelMenu?.data?.isPartnersInvisible)
    || topLevelMenu?.data?.isPartnersInvisible || false);
}
