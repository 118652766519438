import { ContentItem, Elements } from '@kentico/kontent-delivery';

export default class WebPartner extends ContentItem {
    public seoMetadataSocialDescription: Elements.TextElement;
    public seoMetadataMetaRobots: Elements.MultipleChoiceElement;
    public redirectUrl: Elements.TextElement;
    public seoMetadataCanonicalTag: Elements.TextElement;
    public id: Elements.TextElement;
    public seoMetadataMetaDescription: Elements.TextElement;
    public description: Elements.TextElement;
    public seoMetadataSocialImage: Elements.CustomElement;
    public title: Elements.TextElement;
    public photoLarge: Elements.AssetsElement;
    public seoMetadataMetaTitle: Elements.TextElement;
    public seoMetadataSocialTitle: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'seo_metadata__social_description') {
                    return 'seoMetadataSocialDescription';
                }
                if (elementName === 'seo_metadata__meta_robots') {
                    return 'seoMetadataMetaRobots';
                }
                if (elementName === 'redirect_url') {
                    return 'redirectUrl';
                }
                if (elementName === 'additional_sections') {
                    return 'additionalSections';
                }
                if (elementName === 'seo_metadata__canonical_tag') {
                    return 'seoMetadataCanonicalTag';
                }
                if (elementName === 'seo_metadata__meta_description') {
                    return 'seoMetadataMetaDescription';
                }
                if (elementName === 'seo_metadata__social_image') {
                    return 'seoMetadataSocialImage';
                }
                if (elementName === 'photo_large') {
                    return 'photoLarge';
                }
                if (elementName === 'seo_metadata__meta_title') {
                    return 'seoMetadataMetaTitle';
                }
                if (elementName === 'seo_metadata__social_title') {
                    return 'seoMetadataSocialTitle';
                }
                return elementName;
            })
        });
    }
}
