import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Normalize } from 'styled-normalize';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle, Animation, theme } from '@app/styles';
import { MountContext } from '@app/ReactContext';

import { getConfiguration } from '@app/store/actions/configurationActions';

import { WrappedIntlProvider } from '@app/components/Localization';
import ViewPort from './ViewPort';

const App = (): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { pathname, state } = useLocation();
  const [isInitialMount, setInitialMount] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  useEffect((): void => { !state?.params?.isTab && window.scrollTo(0, 0); }, [pathname]);
  useEffect((): void => { setInitialMount(false); }, []);

  return (
    <ThemeProvider theme={theme}>
      <MountContext.Provider value={{ isInitialMount }}>
        <>
          <Normalize />
          <Animation />
          <GlobalStyle />
          <WrappedIntlProvider>
            <ViewPort />
          </WrappedIntlProvider>
        </>
      </MountContext.Provider>
    </ThemeProvider>
  );
};

App.serverFetch = [getConfiguration];

export default App;
