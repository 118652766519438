export enum MethodEnum {
	options = 'OPTIONS',
	get = 'GET',
	put = 'PUT',
	post = 'POST',
	delete = 'DELETE',
	head = 'HEAD',
	connect = 'CONNECT',
	trace = 'TRACE',
	patch = 'PATCH'
}