import useWindowSize from 'react-use/lib/useWindowSize';
import { DevicesSize } from '@app/styles';

const SPONSOR_ELEMENT_WIDTH = 160;

export const useNumberItemsPerColumn = (numberOfItems: number, numberOfSponsors: number): number => {
  const { width: screenWidth } = useWindowSize();
  const horizontalPadding = (window.getComputedStyle(document.documentElement)
    .getPropertyValue(screenWidth < DevicesSize.desktopSmall ? '--horizontal-padding-mobile' : '--horizontal-padding')
    .match(/\d+/g) || [])[0] || 0;

  const menuWidth = screenWidth < DevicesSize.desktopSmall
    ? screenWidth - Number(horizontalPadding) * 2
    : DevicesSize.desktopSmall - Number(horizontalPadding) * 2 - SPONSOR_ELEMENT_WIDTH * numberOfSponsors;
  const columnsNumber = Math.min(Math.floor(menuWidth / 190), 4);

  return numberOfItems && Math.ceil(numberOfItems / columnsNumber);
};
