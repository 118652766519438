import { Errors } from '@app/types/errorTypes';
import {
  MatchResult, LiveMatchResults, LocalizedGames, MatchComments, MatchLineups, MatchLiveAudio,
} from '@app/types/matchTypes';
import {
  MatchCenterActions,
  MatchActionTypes,
} from '@app/store/actionTypes/matchActionTypes';

export type matchCenterState = {
  isGamesDownloadCompleted: boolean;
  isMatchFeedsDownloadCompleted: boolean;
  games: LocalizedGames;
  live: LiveMatchResults;
  lineups: MatchLineups | null;
  events: MatchComments[];
  results: MatchResult | null;
  liveAudio: MatchLiveAudio | null;
  error: Errors;
};

const initialState: matchCenterState = {
  isGamesDownloadCompleted: false,
  isMatchFeedsDownloadCompleted: false,
  games: null,
  live: {},
  lineups: null,
  events: [],
  results: null,
  liveAudio: null,
  error: null,
};

export default (
  state = initialState,
  action: MatchCenterActions,
): matchCenterState => {
  switch (action.type) {
    case MatchActionTypes.SET_GAMES:
      return {
        ...state,
        games: { ...state.games, ...action.payload },
        error: null,
      };
    case MatchActionTypes.SET_MATCH_LINEUPS:
      return {
        ...state,
        lineups: action.payload,
      };
    case MatchActionTypes.SET_MATCH_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case MatchActionTypes.SET_MATCH_RESULTS:
      return {
        ...state,
        results: action.payload,
      };
    case MatchActionTypes.SET_LIVE_MATCH:
      return {
        ...state,
        live: { ...state.live, [action.payload?.gameId]: action.payload },
      };
    case MatchActionTypes.RESET_LIVE_MATCH: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [action.payload]: removedMatch, ...restMatches } = state.live;
      return {
        ...state,
        live: restMatches,
      };
    }
    case MatchActionTypes.RESET_MATCH_CENTER: {
      return {
        ...initialState,
        games: state.games,
        isGamesDownloadCompleted: state.isGamesDownloadCompleted,
        liveAudio: state.liveAudio,
      };
    }
    case MatchActionTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case MatchActionTypes.SET_GAMES_DOWNLOAD_COMPLETED:
      return {
        ...state,
        isGamesDownloadCompleted: true,
      };
    case MatchActionTypes.SET_MATCH_FEEDS_DOWNLOAD_COMPLETED:
      return {
        ...state,
        isMatchFeedsDownloadCompleted: true,
      };
    case MatchActionTypes.SET_LIVE_AUDIO:
      return {
        ...state,
        liveAudio: action.payload,
      };
    case MatchActionTypes.RESET_LIVE_AUDIO:
      return {
        ...state,
        liveAudio: null,
      };
    case MatchActionTypes.PAUSE_LIVE_AUDIO:
      return {
        ...state,
        liveAudio: state.liveAudio ? {
          ...state.liveAudio,
          paused: action.payload,
        } : null,
      };
    default:
      return state;
  }
};
