import { LanguageType } from '@app/types/localizationTypes';
import { format, parseISO } from 'date-fns';
import { mapLanguageTypeToDateFnsLocale } from '@app/helpers/localizationHelper';

type FormatDate = (
  originalDate: Date | string| number | null,
  dateFormat: string,
  language: LanguageType,
) => string

export const formatDate: FormatDate = (originalDate, dateFormat, language) => {
  if (!originalDate) return '';

  const date = typeof originalDate === 'string' ? parseISO(originalDate) : originalDate;

  return format(date, dateFormat, {
    locale: mapLanguageTypeToDateFnsLocale(language),
  });
};
