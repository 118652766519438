import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { useHeader } from '@app/components/Header/HeaderHooks';

type Sponsors = React.FC<{
  showDivider: boolean;
  isOpenedMenu: boolean;
  isMenu?: boolean;
}>

const Sponsors: Sponsors = ({ showDivider, isOpenedMenu, isMenu = false }) => {
  const header = useHeader();

  return (
    <SponsorsSection showDivider={showDivider} isOpenedMenu={isOpenedMenu} isMenu={isMenu}>
      {
        header?.partners?.map(({ id, url, photo }) => (
          <SponsorIcon key={id} isMenu={isMenu}>
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={photo?.url} alt={photo?.description} />
            </a>
          </SponsorIcon>
        ))
      }
    </SponsorsSection>
  );
};

const SponsorsSection = styled.ul<{ showDivider?: boolean; isOpenedMenu: boolean; isMenu: boolean }>`
  padding-left: 10px;
  display: ${({ isMenu }): string => (isMenu ? 'none' : 'flex')};
  align-items: center;
  position: relative;

  @media ${Devices.belowLargeMobile} {
    padding-left: 25px;
  }

  @media ${Devices.desktopSmall} {
    display: ${({ isOpenedMenu, isMenu }): string => (isMenu || !isOpenedMenu ? 'flex' : 'none')};
    justify-content: ${({ isMenu }): string => (isMenu ? 'flex-end' : 'flex-start')};
    bottom: ${({ isMenu }): string => (isMenu ? '30px' : '0')};
  }

  ${({ showDivider }): string => (showDivider ? `
    &:last-child::before {
      content: "";
      border-left: 1px solid rgba(255,255,255,.2);
      left: 0;
      height: 25px;
      display: block;
      position: absolute;
      bottom: -6px;
    }
  ` : '')};
`;

const SponsorIcon = styled.li<{isMenu: boolean }>`
  height: 20px;

  & img {
    height: 100%;
  }

  @media ${Devices.desktopSmall} {
    height: ${({ isMenu }): string => (isMenu ? '35px' : '20px')};
  }
`;

export default Sponsors;
