import { LanguageType } from '@app/types/localizationTypes';
import { EditorialContentTypes } from '@app/types/newsTypes';
import { WebTemplateCtaButton, WebTemplateData, WebTemplatePartnerBottom } from '@app/types/webTemplateTypes';
import { RemoteSubtitles } from './videoPlayerTypes';

// Todo: update with all possible codenames
export enum SubMenuId {
  NEWS = 'web_news_all',
  VIDEOS = 'web_news_videos',
  PHOTOGALLERY = 'web_news_photogallery',
  NEWS_SEARCH = 'web_news_search',
  FIRST_TEAM = 'web_men_first_team',
  WOMEN_TEAM = 'web_women_team',
  PRIMAVERA_TEAM = 'squadre_primavera',
  YOUTH_TEAM = 'web_youth_teams',
  SCHEDULE = 'web_calendar_and_results',
  STANDINGS = 'web_standings',
  MATCHCENTER = 'web_match_center_game',
  BUY_TICKETS = 'web_buy_tickets',
  PRIVACY = 'privacy_and_cookie_policy',
  AUTH = 'web_inviola_login',
  RESET_PASSWORD = 'web_inviola_reset',
  PROFILE = 'web_inviola_profile',
  SETTINGS = 'web_inviola_settings',
  CATALOG_ITEMS = 'web_inviola_catalog',
  AWARDS = 'web_inviola_awards',
  SHOPS = 'web_inviola_stores',
  PROMOTIONS = 'web_inviola_promotions',
}

export type NewsSubMenuId = SubMenuId.NEWS | SubMenuId.VIDEOS | SubMenuId.PHOTOGALLERY;
export type TeamSubMenuId = SubMenuId.FIRST_TEAM | SubMenuId.WOMEN_TEAM | SubMenuId.PRIMAVERA_TEAM;
export type SeasonSubMenuId = SubMenuId.SCHEDULE | SubMenuId.STANDINGS;
export type InviolaSubMenuId = SubMenuId.AUTH | SubMenuId.PROFILE | SubMenuId.RESET_PASSWORD | SubMenuId.SETTINGS

export enum MenuIds {
  News = 'web_news',
  Teams = 'web_teams',
  Season = 'web_season',
  MatchCenter = 'web_match_center',
  Tickets = 'web_tickets',
  Others = 'web_others',
  Inviola = 'web_inviola',
}

export enum OthersSubitemIds {
  AffilatedCompanies = 'web_affilated_companies',
  Esports = 'web_esports',
  FiorentinaCamp = 'web_fiorentina_camp',
  FiorentinaBaby = 'web_fiorentina_baby',
  SchoolProject = 'web_school_project',
  FiorentinaSchool = 'web_fiorentina_school',
  PrivacyPolicy = 'privacy_and_cookie_policy'
}

export enum MenuItemTypes {
  BaseMenuItem = 'menu_item',
  ExternalLink = 'external_link',
  RelatedMenuItem = 'related_menu_item',
}

export type LayoutType = 'layout_2x2' | 'layout_2x3' | 'layout_3x3';

export enum ThronAssetTypes {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

export interface Image {
  id?: string;
  url: string;
  description: string;
}

export interface ThroneImage extends Image {
  type: ThronAssetTypes;
}

export interface ThroneVideo extends Image {
  type: ThronAssetTypes;
  subtitles: RemoteSubtitles;
}

export type ThroneAsset = ThroneImage | ThroneVideo;

export enum SeoSocialTypes {
  WEBSITE = 'website',
  ARTICLE = 'article',
  VIDEO = 'video.other',
}

export interface SeoData {
  title: string;
  description: string;
  canonicalTag: string;
  meta: boolean;
  socialTitle: string;
  socialDescription: string;
  socialImageUrl: string;
  socialType?: SeoSocialTypes;
}

export interface Seo {
  seo: SeoData;
}

export type LocalizedConfiguration = Partial<Record<LanguageType, Configuration>>

interface MenuItemCommonData {
  id: string;
  name: string;
  type: MenuItemTypes;
  isVisible: boolean;
  isPrivate: boolean | null;
}

export interface BaseMenuItem extends Seo, MenuItemCommonData {
  isPartnersInvisible: boolean;
  optaId: string;
  type: MenuItemTypes.BaseMenuItem;
  url: string;
  isShownInHeader: boolean;
  editorialCategory: string;
}

export interface ExternalLinkMenuItem extends MenuItemCommonData {
  url: string;
  type: MenuItemTypes.ExternalLink;
}

export interface RelatedMenuItem extends MenuItemCommonData {
  topLevel: BaseMenuItem;
  secondLevel?: BaseMenuItem;
  thirdLevel?: BaseMenuItem;
  type: MenuItemTypes.RelatedMenuItem;
}

export type MenuItemType = BaseMenuItem | ExternalLinkMenuItem
export type RelatedItemsType = RelatedMenuItem | ExternalLinkMenuItem;

export type CategoryMenu = BaseMenuItem[];
export type LocalizedCategoryMenu = Record<LanguageType, CategoryMenu>;
export type LocalizedBaseMenuItem = Record<LanguageType, BaseMenuItem>;

export interface SubMenuItem {
  data: MenuItemType
  categories: CategoryMenu;
}

export type LocalizedSubMenuItem = Record<LanguageType, SubMenuItem>;

export interface MenuItem {
  data: BaseMenuItem;
  navigation: Record<SubMenuId, SubMenuItem>;
  relatedItems: RelatedItemsType[];
}

export type LocalizedMenuItem = Record<LanguageType, MenuItem>;

export type Menu = Record<MenuIds, MenuItem>;

export interface Partner {
  id: string;
  title: string;
  photo: Image;
  url: string;
}

export interface Social {
  name: string;
  url: string;
  icon: Image;
}

export interface Header {
  social: Social[];
  partners: Partner[];
}

export type Layout = Record<EditorialContentTypes, LayoutType>

export interface ErrorPagesData {
  titleSorry: string;
  titleNotFound: string;
  cta: WebTemplateCtaButton[];
}

export type Competitions = {
  [CompetitionId: string]: Competition;
};

export type CompetitionTypes = 'cup' | 'league' | 'tournament';

export interface CompetitionSeason {
  seasonId: string;
  seasonName: string;
  isActive: boolean;
}

export interface Competition {
  id: string;
  url: string;
  type: CompetitionTypes;
  name: string;
  optaId: string;
  categoryId: string;
  seasonIds: CompetitionSeason[];
  statisticsOptaId: string;
  statisticsSeasonId: string;
}

export enum ShareIconsType {
  Facebook = 'facebook',
  Twitter = 'twitter',
  WhatsApp = 'whatsapp',
}

export interface ShareIconsItem {
  type: ShareIconsType;
  icon: Image;
}

export type ShareIcons = ShareIconsItem[] | null;

export type Partners = WebTemplatePartnerBottom | null;

export interface Footer {
  copyrightContent: string,
  social: Array<Social>,
  legalLinks: Array<RelatedMenuItem | ExternalLinkMenuItem>,
}

export interface HomeItem {
  id: string;
  seo: Seo;
  topElement: WebTemplateData;
}

export interface CookieBannerItem {
  text: string;
  cta: {
    position: string;
    accept: WebTemplateCtaButton;
    decline: WebTemplateCtaButton;
  };
}

export type Home = HomeItem | null;

export interface LiveItem {
  isEnabled: boolean;
  videoId: string;
  sessionId: string;
  title: string;
  image: ThroneImage;
  imageVertical: ThroneImage;
  cta: RelatedMenuItem | null;
}

export type Live = LiveItem | null;

export interface InviolaItem {
  catalogId: number;
}

export type Inviola = InviolaItem | null;

export interface Configuration {
  menu: Menu | null;
  header: Header;
  competitions: Competitions;
  layout: Layout;
  shareIcons: ShareIcons;
  errorPagesData: ErrorPagesData;
  partners: Partners;
  footer: Footer;
  home: Home;
  cookie: CookieBannerItem;
  live: Live;
  inviola: Inviola;
}
