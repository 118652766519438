import { OptaSDCompetitionsMap } from '@app/services/opta/constants/competitionConstants';

export const FiorentinaTeamId = '125';
export const WomenFiorentinaTeamId = '7665';

export const OptaSDFiorentinaTeamIdMap: {
  [value in OptaSDCompetitionsMap]: string
} = {
  [OptaSDCompetitionsMap.WomenSeriaA]: 'p2dsrpus5eckpz2h16svaour',
  [OptaSDCompetitionsMap.Primavera]: 'b8l9gg4jg3u83bzixmfb3j1hh',
  [OptaSDCompetitionsMap.Primavera2]: 'b8l9gg4jg3u83bzixmfb3j1hh',
};
