import { getImageUrl } from '@app/helpers/thronHelper';

import {
  GetResponsiveWidthImageByIdConfig,
  GetStaticImageByIdConfig,
  GetStaticImageConfig,
} from '@app/types/imageTypes';

export const getResponsiveImageByIdConfig: GetResponsiveWidthImageByIdConfig = ({
  id, screeWidthToImageWidthMap, scaleMode, getterFn = getImageUrl,
}) => {
  let src = getterFn({ id, scaleMode });

  const sources = Object.entries(screeWidthToImageWidthMap)
    .sort(([screenWidth1], [screenWidth2]) => Number(screenWidth2) - Number(screenWidth1))
    .map((source, index) => {
      const { width, height } = source[1];
      if (index === 0) {
        src = getterFn({ id, width, height, scaleMode });
      }
      const srcSet = `${getterFn({ id, width, height, scaleMode })} 1x, ${getterFn({ id, width: 2 * width, height: 2 * height, scaleMode })} 2x,  ${getterFn({ id, width: 3 * width, height: 3 * height, scaleMode })} 3x`;
      return {
        sizes: `(min-width:${source[0]}px)`,
        srcSet,
      };
    });

  return {
    src,
    sources,
  };
};

export const getStaticImageConfig: GetStaticImageConfig = ({ url, width, height = 0 }) => ({
  src: `${url}&auto=format`,
  srcSet: `
    ${url}?w=${width}&h=${height}&dpr=1&auto=format,
    ${url}?w=${width}&h=${height}&dpr=2&auto=format 2x,
    ${url}?w=${width}&h=${height}&dpr=3&auto=format 3x
  `,
});

export const getStaticImageByIdConfig: GetStaticImageByIdConfig = ({ id, width, height }) => ({
  src: getImageUrl({ id, width, height, dpr: 100 }),
  srcSet: `
    ${getImageUrl({ id, width, height, dpr: 100 })},
    ${getImageUrl({ id, width, height, dpr: 200 })} 2x,
    ${getImageUrl({ id, width, height, dpr: 300 })} 3x
  `,
});
