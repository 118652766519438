import React from 'react';
import useMedia from 'react-use/lib/useMedia';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { ThroneAsset } from '@app/types/configurationTypes';
import { COVER_IMAGE_ASPECT_RATIO, COVER_IMAGE_TYPE, COVER_IMAGE_WIDTH_MAP } from '@app/constants/imageConstants';

import { ThronImage } from '@app/components/Image';

interface CoverImageProps {
  image: ThroneAsset,
  imageVertical?: ThroneAsset,
  coverType?: COVER_IMAGE_TYPE,
  useLazyLoading?: boolean,
  useGradient?: boolean,
}

const CoverImage = ({
  image,
  imageVertical,
  coverType = COVER_IMAGE_TYPE.NEWS_LIST_DESKTOP,
  useLazyLoading = true,
  useGradient = true,
}: CoverImageProps): React.ReactElement => {
  const isWideScreen = useMedia(Devices.tabletLarge);
  const thronImage = isWideScreen ? image || imageVertical : imageVertical || image;
  const { id, description } = thronImage;

  return (
    <Container coverType={coverType}>
      {id && (
        <ThronImage
          thronId={id}
          alt={description}
          imageWidthMap={COVER_IMAGE_WIDTH_MAP[coverType]}
          useLazyLoading={useLazyLoading}
          scaleMode="auto"
        />
      )}
      {useGradient && (<Gradient />)}
    </Container>
  );
};

export default CoverImage;

const Container = styled.div<{ coverType: COVER_IMAGE_TYPE }>`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100px;
  overflow: hidden;
  background: #351056;
  aspect-ratio: ${({ coverType }): string => ([
    COVER_IMAGE_TYPE.TOP_HIGHLIGHT_DESKTOP, COVER_IMAGE_TYPE.TOP_CAROUSEL_DESKTOP,
  ].includes(coverType) ? COVER_IMAGE_ASPECT_RATIO.DEFAULT_MOBILE : COVER_IMAGE_ASPECT_RATIO.NEWS_LIST_DESKTOP)};


  @media ${Devices.tablet} {
    aspect-ratio: ${({ coverType }): string => ([COVER_IMAGE_TYPE.TOP_HIGHLIGHT_DESKTOP, COVER_IMAGE_TYPE.TOP_CAROUSEL_DESKTOP].includes(coverType) ? COVER_IMAGE_ASPECT_RATIO.DEFAULT_TABLET : COVER_IMAGE_ASPECT_RATIO.NEWS_LIST_DESKTOP)};
  }

  @media ${Devices.desktop} {
    aspect-ratio: ${({ coverType }): string => COVER_IMAGE_ASPECT_RATIO[coverType]};
  }

  & picture {
    position: unset;
  }

  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100px;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }
`;

export const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, #000), linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
`;
