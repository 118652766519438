import { LocalizedConfiguration } from '@app/types/configurationTypes';

export enum ConfigurationActionTypes {
  SET_CONFIGURATION = 'CONFIGURATION/SET_CONFIGURATION',
}

export interface SetConfiguration{
  type: ConfigurationActionTypes.SET_CONFIGURATION;
  payload: LocalizedConfiguration;
}

export type ConfigurationActions = SetConfiguration;
