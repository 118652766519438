import React from 'react';

import { Errors } from '@app/types/errorTypes';

import { Loader } from '@app/components';
import PageValidationWrapper from './PageValidationWrapper';

type PageWrapper = React.FC<{
  error: Errors;
  isLoading?: boolean;
}>

const PageWrapper: PageWrapper = ({ children, error, isLoading }) => (
  <PageValidationWrapper error={error}>
    { isLoading ? (<Loader />) : children }
  </PageValidationWrapper>
);

export default PageWrapper;
