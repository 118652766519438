import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Team extends ContentItem {
    public teamOptaId: Elements.TextElement;
    public teamOptaSdId: Elements.TextElement;
    public shortName: Elements.TextElement;
    public teams: Elements.TaxonomyElement;
    public flag: Elements.AssetsElement;
    public regularName: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'team_opta_id') {
                    return 'teamOptaId';
                }
                if (elementName === 'team_opta_sd_id') {
                    return 'teamOptaSdId';
                }
                if (elementName === 'regular_name') {
                    return 'regularName';
                }
                if (elementName === 'shortname') {
                    return 'shortName';
                }
                if (elementName === 'flag') {
                    return 'flag';
                }
                if (elementName === 'teams') {
                    return 'teams';
                }
                return elementName;
            })
        });
    }
}
