import React from 'react';
import { Devices } from '@app/styles';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { StatsContainer } from '@app/components/Statistics';
import { LocalizedLink } from '@app/components/Localization';
import AppRoutes from '@app/constants/routesConstants';
import {
  Tab, useActiveTab, useTabs,
} from '../PlayersHooks';

const PlayerTabs = (): React.ReactElement => {
  const tabs = useTabs();
  const activeTab = useActiveTab() as Tab;
  const params = useParams();
  const ActiveComponent = activeTab?.component;

  return (
    <>
      <Container>
        {tabs.map((tab) => (
          <TabLocalizedLink
            pathKey={AppRoutes.CommonPage.path}
            pathParams={{ ...params, fifthLevel: tab.caption }}
            key={tab.name}
            isTab
          >
            <TabItem active={tab.name === activeTab?.name}>
              <h2><FormattedMessage id={tab.name} /></h2>
              <Line active={tab.name === activeTab?.name} />
            </TabItem>
          </TabLocalizedLink>
        ))}
      </Container>
      <StatsContainer>
        {ActiveComponent && <ActiveComponent />}
      </StatsContainer>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  margin-top: -23px;
  color: #ffffff;
  letter-spacing: 0.21px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 20;

  @media ${Devices.tabletLarge} {
    margin-top: -27px;
  }
`;

const TabLocalizedLink = styled(LocalizedLink)`
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }

  @media ${Devices.largeMobile} {
    margin-right: 90px;
  }
  @media ${Devices.tabletLarge} {
    margin-right: 190px;
  }
`;

const TabItem = styled.div<{ active: boolean }>`
  opacity: ${({ active }): number => (active ? 1 : 0.3)};
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & h2 {
    font-family: ${(props): string => props.theme.fontFamily.commutersSans};
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;

    @media ${Devices.tabletLarge} {
      font-size: 16px;
    }
  }
`;

const Line = styled.span<{ active: boolean }>`
  opacity: ${({ active }): number => (active ? 1 : 0)};
  margin-top: 7px;
  width: 40px;
  height: 2px;
  border-radius: 1px;
  background-color: #ffffff;
`;

export default PlayerTabs;
