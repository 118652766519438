import styled from 'styled-components';
import { CommonButton } from './CommonButton';

export const LoadMoreButton = styled(CommonButton)`
  border-color: ${(props): string => props.theme.color.red};
  color: ${(props): string => props.theme.color.red};
  height: 35px;
  margin: 0 auto;
  width: auto;
  padding: 0 25px;

  &:hover {
    border-color: ${(props): string => props.theme.color.red};
    color: ${(props): string => props.theme.color.red};
  }
`;
