import { useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AppState from '@app/types/appStateTypes';
import { NewsListComponent } from '@app/types/newsTypes';
import { Errors, PageError } from '@app/types/errorTypes';

import { getNewsList, resetNewsList } from '@app/store/actions/newsActions';

import { MountContext } from '@app/ReactContext';
import { useLanguage, useNewsType, useError } from '@app/components/Hooks';
import { useThirdLevelMenu } from '@app/pages/CommonPage/CommonPageHooks';

export const useNewsDownload = (): void => {
  const { topLevel = '', secondLevel = '', thirdLevel = '' } = useParams();
  const { isInitialMount } = useContext(MountContext);
  const language = useLanguage();
  const type = useNewsType();

  const hasItems = useSelector<AppState, boolean>(
    (state) => !!state.news.newsList.items.length,
    shallowEqual,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    // check if data has been loaded on server before very first render
    if (!isInitialMount || !hasItems) {
      dispatch(getNewsList({
        topLevel,
        secondLevel,
        thirdLevel,
        language,
        type,
        withTopNews: true,
      }));
    }

    return (): void => { dispatch(resetNewsList()); };
  }, [secondLevel, thirdLevel, language]);
};

export const useNewsList = (): NewsListComponent => {
  const language = useLanguage();
  const type = useNewsType();

  return useSelector<AppState, NewsListComponent>(
    (state) => ({
      ...state.news.newsList,
      layout: state.configuration[language]?.layout?.[type] ?? 'layout_3x3',
      isLoading: state.news.isLoading,
    }),
    shallowEqual,
  );
};

export const useLoadMoreNews = (): () => void => {
  const dispatch = useDispatch();
  const language = useLanguage();
  const { topLevel = '', secondLevel = '', thirdLevel = '' } = useParams();
  const type = useNewsType();

  return (): void => {
    dispatch(getNewsList({
      topLevel,
      secondLevel,
      thirdLevel,
      language,
      type,
      withTopNews: false,
    }));
  };
};

export const usePageValidation = (): Errors => {
  const error = useError();

  const thirdLevelMenu = useThirdLevelMenu();

  if (error) {
    return error;
  }

  return thirdLevelMenu ? null : PageError.NotFound;
};
