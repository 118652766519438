import { ImageThron } from '@app/services/kentico/types/image_thron';
import { ThroneVideo, ThronAssetTypes, ThroneImage, ThroneAsset } from '@app/types/configurationTypes';
import { ThronImageGetterProps } from '@app/types/imageTypes';
import { RemoteSubtitlesFormat } from '@app/types/videoPlayerTypes';

const thronConfig = {
  cdnRoot: 'https://acffiorentina-cdn.thron.com/delivery/public',
  deliveryRoot: 'https://acffiorentina-view.thron.com/api/xcontents/resources/delivery',
  clientId: 'acffiorentina',
  pkey: 'lelbgt',
};

export const getImageUrl = (
  { id, mode = 'image', width = 0, height = 0, quality = 100, dpr = 100, fill = 'zoom', scaleMode = 'auto' }: ThronImageGetterProps,
): string => (
  `${thronConfig.cdnRoot}/${mode}/${thronConfig.clientId}/${id}/${thronConfig.pkey}/std/${width}x${height}/image.jpg?scalemode=${scaleMode}&quality=${quality}&dpr=${dpr}&fill=${fill}`
);

export const getThumbnailUrl = ({ mode = 'thumbnail', quality = 30, dpr = 30, ...rest }: ThronImageGetterProps): string => (
  getImageUrl({ mode, quality, dpr, ...rest })
);

export const getVideoUrl = (id: string, name: string, format = 'mp4'): string => (
  `${thronConfig.cdnRoot}/video/${thronConfig.clientId}/${id}/${thronConfig.pkey}/WEBHD/${name}.${format}`
);

export const getSubtitlesUrl = (contentId: string, lang: string): string => (
  `${thronConfig.deliveryRoot}/getSubTitles?clientId=${thronConfig.clientId}&xcontentId=${contentId}&locale=${lang}&pkey=${thronConfig.pkey}`
);

const buildThronVideo = (video: ImageThron): ThroneVideo => {
  const lang = 'en';

  return {
    id: video.thronId ?? '',
    url: getVideoUrl(video.thronId, video.name),
    subtitles: {
      url: getSubtitlesUrl(video.thronId, lang),
      lang,
      format: RemoteSubtitlesFormat.SRT,
    },
    description: video.name ?? '',
    type: ThronAssetTypes.VIDEO,
  };
};

const buildThronImage = (image: ImageThron): ThroneImage => ({
  id: image?.thronId ?? '',
  url: image?.url ?? '',
  description: image?.name ?? '',
  type: ThronAssetTypes.IMAGE,
});

const availableThronAssetBuilders = {
  [ThronAssetTypes.VIDEO]: buildThronVideo,
  [ThronAssetTypes.IMAGE]: buildThronImage,
};

export const buildThronAsset = (asset: ImageThron): ThroneAsset => {
  const builder = availableThronAssetBuilders[asset.type] ?? buildThronImage;

  return builder(asset);
};
