import React from 'react';
import styled from 'styled-components';
import { Devices } from '@app/styles';

import { getStaticImageConfig } from '@app/helpers/imageHelper';

import { usePlayer } from '@app/pages/PlayerProfile/PlayersHooks';
import { usePhotoLarge } from '@app/pages/Teams/TeamsHooks';
import { useLanguage } from '@app/components/Hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { TeamSquadTypes } from '@app/services/kentico/teamsService';
import { CommonButton } from '@app/components/Buttons';
import { formatDate } from '@app/helpers/dateHelpers';

const PlayerInfo = (): React.ReactElement => {
  const language = useLanguage();
  const player = usePlayer();
  const photoLarge = usePhotoLarge(player);
  const { formatMessage } = useIntl();

  const birthDate = player?.birthDate ? formatDate(player.birthDate, 'dd/MM/yyyy', language) : '';

  const buyJersey = player?.squad === TeamSquadTypes.WomenSquad ? 'player.info.buyHerJersey' : 'player.info.buyHisJersey';

  const buyJerseyProps = {
    href: player?.storeUrl || '',
    target: '_blank',
  };

  return (
    <Container>
      <TextLarge>
        <NumberLarge>{player?.number}</NumberLarge>
      </TextLarge>
      <LinksBlock>
        <BuyJerseyWrapper>
          <CommonButton
            as="a"
            {...buyJerseyProps}
            onClick={(): void => {
              window.dataLayer && window.dataLayer.push({
                event: 'buy_jersey',
                event_type: 'player_card',
                player_name: `${player?.firstName}_${player?.lastName}`,
                CD1: language,
              });
            }}
          >
            <FormattedMessage id={buyJersey} />
          </CommonButton>
          {player?.instagram && (
          <PlayerInstagramLink
            href={player?.instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/team/player-instagram.svg" alt={formatMessage({ id: 'statistics.match.yellowCards' })} />
          </PlayerInstagramLink>
          )}
        </BuyJerseyWrapper>
      </LinksBlock>
      <Photo
        {...getStaticImageConfig({ url: photoLarge, height: 498 })}
        alt={player?.photoLarge?.description}
      />
      <TextSmall>
        <FirstNameSmall>
          {player?.firstName}
        </FirstNameSmall>
        <LastNameSmall>
          {player?.lastName}
        </LastNameSmall>
        <InfoSmall>
          {`${player?.nationality}${birthDate && player?.nationality ? ' - ' : ''}${birthDate}`}
        </InfoSmall>
      </TextSmall>
      <Gradient />
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props): string => props.theme.color.purple};
  width: 100%;
  height: 498px;
  position: relative;
  margin: 0;
  color: #ffffff;
  letter-spacing: 0.21px;
  overflow: hidden;

  @media ${Devices.desktopSmall} {
    width: 1280px;
  }
`;

const Photo = styled.img`
  position: absolute;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  top: 60px;
  bottom: 0;
  height: 100%;
  overflow: hidden;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 22%, rgba(0, 0, 0, 0.65));
  z-index: 10;
`;

const LinksBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  z-index: 15;
`;

const TextLarge = styled(LinksBlock)`
  z-index: 3;
`;

const TextSmall = styled(LinksBlock)`
  z-index: 11;
  top: 357px;
`;

const NumberLarge = styled.div`
  position: absolute;
  left: -10px;
  top: 64px;
  font-size: 150px;
  font-weight: bold;
  line-height: 0.75;
  color: #ffffff;
  opacity: 0.2;
  overflow: hidden;
  text-align: left;

  @media ${Devices.tabletLarge} {
    right: calc(50% + 79px);
    font-size: 160px;
    text-align: right;
  }
`;

const LastNameSmall = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;

  @media ${Devices.tabletLarge} {
    font-size: 28px;
  }

`;

const FirstNameSmall = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 22px;
  text-align: center;
  color: #ffffff;
`;

const InfoSmall = styled.p`
  text-align: center;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 15px;
  font-weight: 300;
  line-height: 2;
  color: #ffffff;
`;

const BuyJerseyWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 251px;
  height: 61px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media ${Devices.tabletLarge} {
    top: 86px;
    right: 96px;
    border-radius: 8px;
    border: solid 1px rgba(255, 255, 255, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const PlayerInstagramLink = styled.a`
  margin-left: 30px;
  display: flex;
`;

export default PlayerInfo;
