import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import AppState from '@app/types/appStateTypes';
import configuration from './configurationReducer';
import currentRoute from './currentRouteReducer';
import teams from './teamsReducer';
import isOpenedMenu from './menuReducer';
import webTemplate from './webTemplateReducer';
import news from './newsReducer';
import season from './seasonReducer';
import matchCenter from './matchReducer';
import inviola from './inviolaReducer';
import promotions from './promotionsReducer';
import search from './searchReducer';

const persistConfig = {
  key: 'inviola',
  storage,
  blacklist: [
    // COMMON InVIOLA
    'syncSessionId', 'error', 'success', 'resetStep',
    'syncInProgress', 'logoutSyncInProgress', 'changePasswordInProgress',
    // CATALOG
    'catalog', 'catalogItems', 'pointsSyncInProgress',
    // CUSTOMER REGISTRATION
    'registrationCustomerId', 'registrationCustomer', 'registrationStep',
    'registrationCredentials', 'confirmCredentials',
    // CUSTOMER PROFILE
    'card', 'awards', 'withdrawnAwards', 'wishList',
  ],
};

const isServer = (typeof window === 'undefined');
const inviolaReducer = isServer ? inviola : persistReducer(persistConfig, inviola);

export default (history: History): Reducer<AppState> => combineReducers({
  router: connectRouter(history),
  configuration,
  currentRoute,
  teams,
  isOpenedMenu,
  news,
  search,
  webTemplate,
  season,
  matchCenter,
  inviola: inviolaReducer,
  promotions,
});
