import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { COVER_IMAGE_TYPE } from '@app/constants/imageConstants';
import { WebCardLayoutSize, WebTemplateCard, WebTemplateTypes } from '@app/types/webTemplateTypes';
import { findAndFormatMenuLinkProps } from '@app/helpers/menuHelpers';
import { useMenu } from '@app/components/Hooks';
import { BaseMenuItem, MenuItemTypes } from '@app/types/configurationTypes';
import { LocalizedLink } from '@app/components/Localization';
import { CommonButton } from '@app/components/Buttons';
import { ImageWidthMap } from '@app/types/imageTypes';
import { CoverImage } from '@app/components/Image';
import { ImageElement } from '@app/components/Image/LazyImage';

interface HomeWebCardElementProps {
  data: WebTemplateCard;
  imageWidthMap?: ImageWidthMap;
  shiftInfo?: boolean;
  current?: boolean;
}

const HomeWebCardElement = ({ data, shiftInfo }: HomeWebCardElementProps): React.ReactElement => {
  const menu = useMenu();

  const layoutSize = data?.layoutSize ?? WebCardLayoutSize.Small;
  const link = data?.link?.type === MenuItemTypes.ExternalLink ? {
    href: data?.link?.url,
    as: 'a',
    target: '_blank',
    rel: 'noopener noreferrer',
  } : findAndFormatMenuLinkProps(
    Object.values(menu ?? {}),
    data?.link?.id ?? null,
    (data?.link as BaseMenuItem)?.url,
  );
  return (
    <ContainerLarge>
      <CoverImage
        coverType={COVER_IMAGE_TYPE.TOP_CAROUSEL_DESKTOP}
        image={data?.backgroundImage}
        useLazyLoading={false}
      />
      <Info layoutSize={layoutSize} shiftInfo={shiftInfo}>
        { /* @ts-ignore */ }
        <Link {...link}>
          <Title layoutSize={layoutSize}>
            {data?.title}
          </Title>
        </Link>
        {data?.subTitle && (
          <SubTitle layoutSize={layoutSize}>
            {data?.subTitle}
          </SubTitle>
            )}
        {data?.link && data?.link?.type !== WebTemplateTypes.WebCtaButton && [
          WebCardLayoutSize.Large, WebCardLayoutSize.Banner,
        ].includes(layoutSize) && (
          <>
            { /* @ts-ignore */ }
            <Link {...link}>
              <Button>
                {data?.link?.name}
              </Button>
            </Link>
          </>
        )}
      </Info>
    </ContainerLarge>
  );
};

const ContainerCommon = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;

const ContainerLarge = styled(ContainerCommon)`
  margin-bottom: 0;

  ${ImageElement} {
    width: 100%;
    height: auto;
  }

  @media ${Devices.tablet} {
    width: 100%;
  }
`;

const Info = styled.div<{ layoutSize: WebCardLayoutSize; shiftInfo?: boolean; }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: ${({ layoutSize }): string => ([WebCardLayoutSize.Large, WebCardLayoutSize.Banner].includes(layoutSize) ? 'center' : 'flex-start')};
  justify-content: ${({ layoutSize }): string => ([WebCardLayoutSize.Large, WebCardLayoutSize.Banner].includes(layoutSize) ? 'center' : 'flex-end')};
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-image: ${({ layoutSize }): string => ([WebCardLayoutSize.Large, WebCardLayoutSize.Banner].includes(layoutSize) ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4) 24%), linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))' : 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 57%, #000000), linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))')};
  padding: 24px;
  padding-bottom: ${({ shiftInfo }): string => (shiftInfo ? '44px' : '24px')};

  & > a {
    width: 100%;
  }

  @media ${Devices.tablet} {
    padding: 30px;
    padding-bottom: ${({ shiftInfo }): string => (shiftInfo ? '50px' : '30px')};
  }
`;

const Title = styled.h2<{ layoutSize: WebCardLayoutSize }>`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding-bottom: 20px;
  white-space: normal;

  @media ${Devices.tablet} {
    font-size: ${({ layoutSize }): string => ([WebCardLayoutSize.Small].includes(layoutSize) ? '16px' : '22px')};
  }
`;

const SubTitle = styled.p<{ layoutSize: WebCardLayoutSize }>`
  display: block;
  width: 100%;
  min-height: 35px;
  margin: 5px auto 0;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;
  white-space: normal;

  @media ${Devices.tablet} {
    font-size: ${({ layoutSize }): string => ([WebCardLayoutSize.Small, WebCardLayoutSize.Medium].includes(layoutSize) ? '11px' : '16px')};
  }

  @media ${Devices.desktop} {
    font-size: ${({ layoutSize }): string => ([WebCardLayoutSize.Small, WebCardLayoutSize.Medium].includes(layoutSize) ? '11px' : '16px')};
}
`;

const Button = styled(CommonButton)`
  border-color: ${(props): string => props.theme.color.shadow};
  background-color: ${(props): string => props.theme.color.shadow};
  color: #ffffff;
  height: 35px;
  margin: 40px auto;
  width: auto;
  padding: 0 25px;
  font-weight: bold;

  &:hover {
    border-color: #ffffff;
    background-color: #ffffff;
    color: ${(props): string => props.theme.color.shadow};
  }
`;

const Link = styled(LocalizedLink)``;

export default HomeWebCardElement;
