import React from 'react';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { DevicesSize } from '@app/styles';
import CatalogItem from '@app/pages/Inviola/CatalogPage/components/CatalogItem';
import { getAwards } from '@app/store/actions/inviolaActions/catalog';
import { useCatalogItems, useWishListItems } from '@app/pages/Inviola/CatalogPage/CatalogPageHooks';
import { CustomLeftArrow, CustomRightArrow } from '@app/components/Carousel/CarouselArrows';
import { useMaxWidthOfPartialVisibility } from '@app/components/Carousel/CarouselHooks';
import { Title, Description, Section } from './AwardsElements';

const MyWishesSection = (): React.ReactElement => {
  const dispatch = useDispatch();

  const catalogItems = useCatalogItems();
  const wishList = useWishListItems();
  const itemsToDisplay = catalogItems
    ?.filter((item) => wishList.find((wishItem) => wishItem.id === item.id)) ?? [];

  const carouselConfiguration: ResponsiveType = {
    desktop: {
      breakpoint: { max: Infinity, min: DevicesSize.tabletMedium },
      items: 2,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: DevicesSize.desktop, min: DevicesSize.tabletSmall },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: DevicesSize.tabletSmall, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },
  };
  const maxWidthOfPartialVisibility = useMaxWidthOfPartialVisibility(carouselConfiguration);

  return (
    <Section>
      <Title><FormattedMessage id="inviola.awards.myWishes" /></Title>
      {itemsToDisplay?.length ? (
        <Carousel
          swipeable
          partialVisible
          responsive={carouselConfiguration}
          infinite={false}
          keyBoardControl={false}
          customLeftArrow={<CustomLeftArrow mediaQueryMinWidth={maxWidthOfPartialVisibility} />}
          customRightArrow={<CustomRightArrow mediaQueryMinWidth={maxWidthOfPartialVisibility} />}
        >
          { itemsToDisplay?.map((item) => (
            <CatalogItem
              key={item.id}
              item={item}
              onRequestDone={() => dispatch(getAwards())}
            />
          ))}
        </Carousel>
      ) : (
        <Description><FormattedMessage id="inviola.awards.noItems" /></Description>
      )}
    </Section>
  );
};

export default MyWishesSection;
