import { InviolaHttpErrors } from '../apiConstants';

export type InviolaErrorTypes = InviolaAppError | InviolaAPIError;

export interface InviolaError {
  code: InviolaHttpErrors,
  type: InviolaErrorTypes
}

export enum InviolaAppError {
  LoginFormError = 'LoginFormError',
  ResetFormError = 'ResetFormError',
  LogoutFormError = 'LogoutFormError',
  RegistrationFormError = 'RegistrationFormError',
  SettingsFormError = 'SettingsFormError',
  AuthError = 'AuthError',
  ChangePasswordError = 'ChangePasswordError',
  RequestAwardError = 'RequestAwardError',
  DeleteAccountError = 'DeleteAccountError',
}

export enum InviolaAPIError {
  SyncError = 'SyncError',
  ResetResendCodeError = 'ResetResendCodeError',
  GetMovementsError = 'GetMovementsError',
  GetAdditionalDataError = 'GetAdditionalDataError',
  GetShopsError = 'GetShopsError',
  LoginWithTokenError = 'LoginWithTokenError',
  GetCatalogError = 'GetCatalogError',
  SendPointsError = 'SendPointsError',
  GetAwardsError = 'GetAwardsError',
  GetCatalogWishListError = 'GetCatalogWishListError',
}

export class HttpError extends Error {
  constructor(public code: InviolaHttpErrors, message: string) {
    super(message);
    Object.setPrototypeOf(this, HttpError.prototype);
  }
}

export interface HttpErrorBody {
  code: number;
  message: string;
}

export enum InviolaAppSuccess {
  CustomerCardIsAccepted = 'CustomerCardIsAccepted',
  VerificationCodeResent = 'VerificationCodeResent',
  AuthorizationCodeResent = 'AuthorizationCodeResent',
  ResetCodeResent = 'ResetCodeResent',
  UserModified = 'UserModified',
  AccountDeleted = 'AccountDeleted',
  PasswordChanged = 'PasswordChanged',
  AwardRequested = 'AwardRequested',
}

export type InviolaSuccessTypes = InviolaAppSuccess;
