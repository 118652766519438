import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import AppRoutes from '@app/constants/routesConstants';
import { AppState } from '@app/types/appStateTypes';
import { getStandings, resetSeason } from '@app/store/actions/seasonActions';
import { CompetitionTypesMap, MenCompetitionsMap } from '@app/services/opta/constants/competitionConstants';
import { HOME_STANDINGS_TEAMS_NUMBER } from '@app/constants/configurationConstants';
import { StandingsItem } from '@app/types/standingsTypes';
import { SubMenuId } from '@app/types/configurationTypes';
import { MountContext } from '@app/ReactContext';
import { isTeamFiorentina } from '@app/helpers/teamsHelpers';
import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { useLanguage } from '@app/components/Hooks';
import {
  useCompetitionSelector, useCompetitionIdSelector, useOptaSeasonId, useOptaSeasonIdCheck,
} from '@app/pages/Season/Season/SeasonHooks';

export const useStandingSelector = (): StandingsItem => (
  useSelector<AppState, StandingsItem>(
    (state) => state.season.standings,
    shallowEqual,
  )
);

export const useWidgetStandingSelector = (): StandingsItem => {
  const standings = useStandingSelector();
  const fiorentinaIndex = standings.findIndex((team) => isTeamFiorentina(team.id));

  if (fiorentinaIndex === -1 || fiorentinaIndex < HOME_STANDINGS_TEAMS_NUMBER) {
    return standings.slice(0, HOME_STANDINGS_TEAMS_NUMBER);
  }
  if (fiorentinaIndex - 1 > standings.length - HOME_STANDINGS_TEAMS_NUMBER) {
    return standings.slice(standings.length - HOME_STANDINGS_TEAMS_NUMBER);
  }
  // show Fiorentina on the 2d row
  return standings.slice(fiorentinaIndex - 2, fiorentinaIndex + HOME_STANDINGS_TEAMS_NUMBER - 2);
};

export const useStandingsDownload = (): void => {
  const { isInitialMount } = useContext(MountContext);
  const language = useLanguage();

  const dispatch = useDispatch();
  const competitionId = useCompetitionIdSelector();
  const competition = useCompetitionSelector();
  const seasonId = useOptaSeasonId();
  const standings = useStandingSelector();
  const isSeasonIdValid = useOptaSeasonIdCheck();

  useEffect(() => {
    if (isInitialMount) {
      // check if data has been loaded on server before very first render
      !standings.length && isSeasonIdValid && dispatch(getStandings({ competitionId, seasonId, language }));
    } else if (isSeasonIdValid) {
      dispatch(resetSeason());
      dispatch(getStandings({ competitionId, seasonId, language }));
    }
  }, [competition, seasonId]);
};

export const useWidgetStandingsDownload = (): void => {
  const dispatch = useDispatch();
  const language = useLanguage();

  useEffect(() => {
    dispatch(getStandings({ competitionId: MenCompetitionsMap.SeriaA, language }));

    return (): void => { dispatch(resetSeason()); };
  }, [language]);
};

export const useStandingsCompetitionValidation = (): void => {
  const dispatch = useDispatch();
  const language = useLanguage();
  const { topLevel = '', thirdLevel = '', forthLevel = '' } = useParams();
  const competition = useCompetitionSelector();
  const scheduleUrl = useSelector<AppState, string>(
    // eslint-disable-next-line camelcase
    (state) => state.configuration?.[language]?.menu?.web_season?.navigation?.[SubMenuId.SCHEDULE]?.data?.url ?? '',
    shallowEqual,
  );

  (competition?.type && ![
    CompetitionTypesMap.league,
    CompetitionTypesMap.tournament,
  ].includes(competition?.type)) && dispatch(replace(localizeRouteKey({
    pathKey: AppRoutes.CommonPage.path,
    pathParams: {
      topLevel,
      secondLevel: scheduleUrl,
      thirdLevel,
      forthLevel,
      fifthLevel: '',
    },
  }, language)));
};
