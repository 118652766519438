import React from 'react';
import { useIntl } from 'react-intl';
import { ArrowProps } from 'react-multi-carousel/lib/types';
import styled from 'styled-components';

interface CustomArrowProps extends ArrowProps {
  mediaQueryMinWidth: number;
}
export const CustomLeftArrow: React.FC<CustomArrowProps> = ({ onClick, mediaQueryMinWidth }) => {
  const { formatMessage } = useIntl();

  return (
    <ArrowLeftContainer mediaQueryMinWidth={mediaQueryMinWidth} onClick={(): void => onClick && onClick()}>
      <img src="/images/gallery-arrow.svg" alt={formatMessage({ id: 'carousel.leftArrow.alt' })} />
    </ArrowLeftContainer>
  );
};

export const CustomRightArrow: React.FC<CustomArrowProps> = ({ onClick, mediaQueryMinWidth }) => {
  const { formatMessage } = useIntl();

  return (
    <ArrowRightContainer mediaQueryMinWidth={mediaQueryMinWidth} onClick={(): void => onClick && onClick()}>
      <img src="/images/gallery-arrow.svg" alt={formatMessage({ id: 'carousel.rightArrow.alt' })} />
    </ArrowRightContainer>
  );
};

const ArrowContainer = styled.div`
  cursor: pointer;
  user-select: none;
  z-index: 1;
  position: absolute;

  & > img {
    width: 30px;
    height: 30px;
  }
`;

const ArrowLeftContainer = styled(ArrowContainer)<{mediaQueryMinWidth: number}>`
  left: 0;
  transform: rotate(180deg);

  @media ${({ mediaQueryMinWidth }): string => `(min-width: ${mediaQueryMinWidth}px)`} {
    left: 10px;
  }
`;

const ArrowRightContainer = styled(ArrowContainer)<{mediaQueryMinWidth: number}>`
  right: 0;

  @media ${({ mediaQueryMinWidth }): string => `(min-width: ${mediaQueryMinWidth}px)`} {
    right: 10px;
  }
`;
