import React from 'react';
import styled from 'styled-components';

import { useFooter } from '@app/components/Footer/FooterHooks';
import { useIntl } from 'react-intl';
import { Devices } from '@app/styles';
import LocalizedLink from '@app/components/Localization/LocalizedLink';
import { MenuItemTypes } from '@app/types/configurationTypes';

const Footer = (): React.ReactElement => {
  const footer = useFooter();
  const { formatMessage } = useIntl();

  const legalLinks = footer?.legalLinks.map((link) => {
    if (link.type === MenuItemTypes.RelatedMenuItem) {
      const {
        topLevel, secondLevel, thirdLevel, name,
      } = link;
      return (
        <LocalizedLink
          key={name}
          pathKey="routes.commonPage"
          pathParams={{
            topLevel: topLevel ? topLevel.url : '',
            secondLevel: secondLevel ? secondLevel.url : '',
            thirdLevel: thirdLevel ? thirdLevel.url : '',
            forthLevel: '',
            fifthLevel: '',
          }}
        >
          {name}
        </LocalizedLink>
      );
    }

    if (link.type === MenuItemTypes.ExternalLink) {
      const { url, name } = link;
      return (
        <LinkWrapper
          key={name}
          as="a"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </LinkWrapper>
      );
    }
  });

  return (
    <Container>
      <FooterContent>
        <SocialMedias>
          <FooterHeading>{formatMessage({ id: 'footer.findus' })}</FooterHeading>
          <FooterSocialContainer>
            { footer?.social.map(({ name, url, icon }) => (
              <a key={`#footer-icon-${name}`} href={url}>
                <img src={icon.url} alt={name} />
              </a>
            ))}
          </FooterSocialContainer>
        </SocialMedias>
        <Divider />
        <FooterBottom>
          <div className="left-side">
            <img src="/images/logo.svg" alt={formatMessage({ id: 'mainLogo.alt' })} />
            {/* eslint-disable-next-line react/no-danger */}
            { footer?.copyrightContent && <div dangerouslySetInnerHTML={{ __html: footer?.copyrightContent }} /> }
          </div>
          <ul className="right-side">
            { legalLinks?.map((link, i) => <li key={`$footer-link-${i}`}>{link}</li>) }
          </ul>
        </FooterBottom>
      </FooterContent>
    </Container>
  );
};

export default Footer;

const LinkWrapper = styled(LocalizedLink)``;

const FooterContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;

  @media ${Devices.tabletLarge} {
      padding-left: 70px;
      padding-right: 70px;
    }
`;

const Container = styled.footer`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  color: white;
  padding-top: 40px;
  padding-bottom: 46px;
  background-color: ${(props): string => props.theme.color.purple};
  background-image: url("/images/block-background.webp");
  background-position: center center;
  background-size: cover;
`;

const FooterHeading = styled.h3`
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
`;

const SocialMedias = styled.div`
  margin: 0 auto;

  @media ${Devices.tablet} {
    width: 40vw;
  }
`;

const Divider = styled.div`
  display: block;
  width: 100%;
  max-width: 505px;
  margin: 40px auto;
  border-bottom: 1px solid rgb(255 255 255 / 0.1);
`;

const FooterSocialContainer = styled.ul`
  text-align: center;

  a {
    line-height: 35px;
  }

  a:not(:last-child) {
    padding-right: 2.5vw;
  }

  img {
    height: 25px;
  }

  img:hover path {
    fill: red;
  }
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .left-side {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    p {
      font-size: 12px;
      line-height: 22px;
      font-family: ${(props): string => props.theme.fontFamily.arialNova};
      padding-right: 30px;
    }

    p:not(:last-child) {
      padding-bottom: 5px;
    }

    img {
      height: 92px;
      margin-right: 30px;
    }
  }

  .right-side {
    max-width: 340px;
    flex-wrap: wrap;
    display: flex;

    li {
      height: 29px;
      flex: 1 1 150px;
      margin-bottom: 10px;
    }

    a {
      font-size: 14px;
      letter-spacing: 1.8px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
    }
  }

  @media ${Devices.tablet} {
        flex-direction: row;

        .left-side {
          margin-bottom: 0;
        }
    }
`;
