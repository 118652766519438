import React from 'react';
import styled from 'styled-components';

import { ThroneContentItem, NewsContentType } from '@app/types/newsTypes';

import { VideoPlayer } from '@app/components';
import { GalleryCarousel } from '@app/components/Gallery';
import { ThronImage } from '@app/components/Image';
import { ThroneVideo } from '@app/types/configurationTypes';
import { Devices } from '@app/styles';

const NewsTopElement: React.FC<{ item: ThroneContentItem }> = ({ item }) => {
  let TopNewsElement;
  switch (item.type) {
    case NewsContentType.IMAGE:
      TopNewsElement = (
        <ThronImage
          width={900}
          height={600}
          thronId={item.value?.id}
          alt={item.value?.description}
          imageWidthMap={{
            0: { width: 900, height: 520 },
          }}
        />
      );
      break;
    case NewsContentType.GALLERY:
      TopNewsElement = <GalleryCarousel slides={item.value ?? []} />;
      break;
    case NewsContentType.VIDEO: {
      const video = item.value as ThroneVideo;
      TopNewsElement = <VideoPlayer src={video?.url ?? ''} remoteSubtitles={video?.subtitles ?? ''} />;
      break;
    }
    default:
      TopNewsElement = null;
  }

  return (
    <Container>
      {TopNewsElement}
    </Container>
  );
};

const Container = styled.div`
  margin: 0 -20px 50px;

  @media ${Devices.responsiveContent} {
    margin: 0 0 50px;
  }
`;

export default NewsTopElement;
