import React from 'react';
import { ImageWidthMap } from '@app/types/imageTypes';
import LazyImage from './LazyImage';
import { useResponsiveImages, useThronImages } from './ThronImageHook';

interface ThronImageProps {
  thronId: string | undefined;
  alt: string;

  width?: number;
  height?: number;

  imageWidthMap?: ImageWidthMap

  useLazyLoading?: boolean;
  scaleMode?: 'auto' | 'none';
}

const ThronImage: React.FC<ThronImageProps> = ({
  thronId, alt, width, height, imageWidthMap, scaleMode = 'auto', useLazyLoading,
}) => {
  const [computedWidth, computedHeight] = [width ?? 0, height ?? 0];
  const thronImages = useThronImages(thronId, computedWidth, computedHeight, scaleMode);
  const [additionalImageProps] = useResponsiveImages(thronId ?? '', imageWidthMap, {
    0: {
      width: width ?? 0,
      height: height ?? 0,
    },
  }, scaleMode);

  if (!thronImages) {
    return null;
  }
  const [thumbSrc, imageSrc] = thronImages;

  return (
    <LazyImage
      src={imageSrc}
      thumbnailSrc={thumbSrc}
      imageProps={additionalImageProps}

      useLazyLoading={useLazyLoading}

      width={imageWidthMap ? undefined : width?.toString()}
      height={imageWidthMap ? undefined : height?.toString()}

      alt={alt}
    />
  );
};

export default ThronImage;
