
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export default class WebCarouselItem extends ContentItem {
    public id: Elements.TextElement;
    public content: Elements.LinkedItemsElement<ContentItem>;
    public cta: Elements.LinkedItemsElement<ContentItem>;
}
