import React from 'react';
import { FormattedMessage } from 'react-intl';

import theme from '@app/styles/theme';
import { MatchStatistics } from '@app/types/matchTypes';
import { useLineupsSelector } from '@app/pages/MatchCenter/MatchCenterHooks';
import { CircleGraph } from '@app/components/Graphs/CircleGraph';
import {
  BlockContainerColumn, Caption, CircleCaption, StatsBlock, StatsBlockColumn,
  FieldInsideImage, FieldOutsideImage, ValueAndGraph,
} from '@app/components/Statistics';
import { ValueLarge } from '@app/pages/MatchCenter/components/MatchStatistics/components/MatchBall';

const MatchShots = (): React.ReactElement => {
  const lineups = useLineupsSelector();

  return (
    <BlockContainerColumn>
      <StatsBlock marginTop="20px" marginBottom="40px">
        <StatsBlock>
          <ValueLarge>{`${lineups?.home?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] ?? 0}`}</ValueLarge>
        </StatsBlock>
        <StatsBlock>
          <Caption isSmall><FormattedMessage id="statistics.totalShots" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <ValueLarge>{`${lineups?.away?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] ?? 0}`}</ValueLarge>
        </StatsBlock>
      </StatsBlock>
      <StatsBlock marginBottom="20px">
        <StatsBlockColumn>
          <StatsBlock marginBottom="20px">
            <ValueAndGraph
              strokeHomeColor={theme.color.purple}
              caption="statistics.onTarget"
              progress={
                lineups ? (lineups?.home?.stats?.[MatchStatistics.STATS_SHOTS_ON_TARGET] * 100)
                / lineups?.home?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] : 0
              }
              value={lineups?.home?.stats?.[MatchStatistics.STATS_SHOTS_ON_TARGET]}
            />
          </StatsBlock>
          <StatsBlock marginBottom="20px">
            <ValueAndGraph
              strokeHomeColor={theme.color.purple}
              caption="statistics.offTarget"
              progress={
                lineups ? (lineups?.home?.stats?.[MatchStatistics.STATS_SHOTS_OF_TARGET] * 100)
                / lineups?.home?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] : 0
              }
              value={lineups?.home?.stats?.[MatchStatistics.STATS_SHOTS_OF_TARGET]}
            />
          </StatsBlock>
          <StatsBlock>
            <ValueAndGraph
              strokeHomeColor={theme.color.purple}
              caption="statistics.blocked"
              progress={
                lineups ? (lineups?.home?.stats?.[MatchStatistics.STATS_BLOCKED_SHOTS] * 100)
                / lineups?.home?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] : 0
              }
              value={lineups?.home?.stats?.[MatchStatistics.STATS_BLOCKED_SHOTS]}
            />
          </StatsBlock>
        </StatsBlockColumn>
        <StatsBlockColumn>
          <StatsBlock marginBottom="20px">
            <ValueAndGraph
              strokeHomeColor={theme.color.red}
              caption="statistics.onTarget"
              progress={
                lineups ? (lineups?.away?.stats?.[MatchStatistics.STATS_SHOTS_ON_TARGET] * 100)
                / lineups?.away?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] : 0
              }
              value={lineups?.away?.stats?.[MatchStatistics.STATS_SHOTS_ON_TARGET]}
            />
          </StatsBlock>
          <StatsBlock marginBottom="20px">
            <ValueAndGraph
              strokeHomeColor={theme.color.red}
              caption="statistics.offTarget"
              progress={
                lineups ? (lineups?.away?.stats?.[MatchStatistics.STATS_SHOTS_OF_TARGET] * 100)
                / lineups?.away?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] : 0
              }
              value={lineups?.away?.stats?.[MatchStatistics.STATS_SHOTS_OF_TARGET]}
            />
          </StatsBlock>
          <StatsBlock>
            <ValueAndGraph
              strokeHomeColor={theme.color.red}
              caption="statistics.blocked"
              progress={
                lineups ? (lineups?.away?.stats?.[MatchStatistics.STATS_BLOCKED_SHOTS] * 100)
                / lineups?.away?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] : 0
              }
              value={lineups?.away?.stats?.[MatchStatistics.STATS_BLOCKED_SHOTS]}
            />
          </StatsBlock>
        </StatsBlockColumn>
      </StatsBlock>
      <StatsBlock marginTop="20px" marginBottom="20px">
        <Caption><FormattedMessage id="statistics.shotsDistribution" /></Caption>
      </StatsBlock>
      <StatsBlock marginBottom="20px">
        <StatsBlock>
          <FieldInsideImage />
        </StatsBlock>
        <StatsBlock>
          <FieldOutsideImage />
        </StatsBlock>
        <StatsBlock />
        <StatsBlock>
          <FieldInsideImage />
        </StatsBlock>
        <StatsBlock>
          <FieldOutsideImage />
        </StatsBlock>
      </StatsBlock>
      <StatsBlock marginBottom="20px">
        <StatsBlock>
          <CircleGraph
            strokeAwayColor={theme.color.purple}
            width={50}
            height={50}
            value={lineups ? (lineups?.home?.stats?.[MatchStatistics.STATS_SHOTS_FROM_BOX] * 100)
            / (lineups?.home?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] * 100) : 0}
          >
            <CircleCaption>{lineups?.home?.stats?.[MatchStatistics.STATS_SHOTS_FROM_BOX]}</CircleCaption>
          </CircleGraph>
        </StatsBlock>
        <StatsBlock>
          <CircleGraph
            strokeAwayColor={theme.color.purple}
            width={50}
            height={50}
            value={lineups ? (lineups?.home?.stats?.[MatchStatistics.STATS_SHOTS_OUT_BOX] * 100)
            / (lineups?.home?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] * 100) : 0}
          >
            <CircleCaption>{lineups?.home?.stats?.[MatchStatistics.STATS_SHOTS_OUT_BOX]}</CircleCaption>
          </CircleGraph>
        </StatsBlock>
        <StatsBlock />
        <StatsBlock>
          <CircleGraph
            strokeAwayColor={theme.color.red}
            width={50}
            height={50}
            value={lineups ? (lineups?.away?.stats?.[MatchStatistics.STATS_SHOTS_FROM_BOX] * 100)
            / (lineups?.away?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] * 100) : 0}
          >
            <CircleCaption>{lineups?.away?.stats?.[MatchStatistics.STATS_SHOTS_FROM_BOX]}</CircleCaption>
          </CircleGraph>
        </StatsBlock>
        <StatsBlock>
          <CircleGraph
            strokeAwayColor={theme.color.red}
            width={50}
            height={50}
            value={lineups ? (lineups?.away?.stats?.[MatchStatistics.STATS_SHOTS_OUT_BOX] * 100)
            / (lineups?.away?.stats?.[MatchStatistics.STATS_TOTAL_SHOTS] * 100) : 0}
          >
            <CircleCaption>{lineups?.away?.stats?.[MatchStatistics.STATS_SHOTS_OUT_BOX]}</CircleCaption>
          </CircleGraph>
        </StatsBlock>
      </StatsBlock>
    </BlockContainerColumn>
  );
};

export default MatchShots;
