import React from 'react';
import styled from 'styled-components';
import { Devices } from '@app/styles';
import { WebTemplateInviolaMembershipCard } from '@app/types/webTemplateTypes';
import { WebCtaButtonElement } from '@app/components/WebTemplates/elements/index';

interface InviolaMembershipCardProps {
  data: WebTemplateInviolaMembershipCard;
}

const WebInviolaMembershipCardElement = ({ data }: InviolaMembershipCardProps): React.ReactElement => {
  const hasCta = !!(data?.ctaButton && data?.ctaButton?.label);
  return (
    <CardItem>
      <CardItemImage src={data?.cardImage?.url} alt="card" />
      <Title>{data?.title}</Title>
      <Price>{data?.price}</Price>
      {/* eslint-disable-next-line react/no-danger */}
      <Description dangerouslySetInnerHTML={{ __html: data?.description ?? '' }} />
      {hasCta && (<WebCtaButtonElement data={data.ctaButton} width="100%" />)}
    </CardItem>
  );
};

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
  padding: 150px 15px 15px;
  margin: 100px auto 10px;

  &:last-child {
    margin-top: 50px;
  }

  @media ${Devices.tablet} {
    margin: 100px 10px 10px;

    &:last-child {
      margin-top: 100px;
    }
  }

  @media ${Devices.responsiveContent} {
    max-width: 340px;
    padding: 160px 15px 15px;
    margin: 110px 40px 20px;

    &:last-child {
      margin-top: 110px;
    }
  }
`;

const CardItemImage = styled.img`
  position: absolute;
  top: -30px;
  left: 15px;
  width: 270px;
  height: auto;

  @media ${Devices.responsiveContent} {
    width: 290px;
    top: -40px;
    left: 25px;
  }
`;

const Title = styled.div`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;

const Price = styled.div`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${(props): string => props.theme.color.purple};
  margin-top: 20px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.44;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  padding: 25px 0;
`;

export default WebInviolaMembershipCardElement;
