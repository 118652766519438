import React from 'react';
import { ControlContainer, ControlButton, ControlImg } from './VideoComponents';

interface ControlProps {
  iconPath: string;
  altText?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Control: React.FC<ControlProps> = ({ iconPath, altText, onClick }) => (
  <ControlContainer>
    <ControlButton title={altText} onClick={onClick}>
      <ControlImg src={iconPath} alt={altText} />
    </ControlButton>
  </ControlContainer>
);

export default Control;
