import React from 'react';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales/index';
import { useLanguage } from '@app/components/Hooks';
import { MatchLineupsTeam } from '@app/types/matchTypes';
import { getLineupFormation } from '@app/helpers/matchHelpers';
import { isTeamFiorentina } from '@app/helpers/teamsHelpers';
import { useLineupsSelector } from '../../MatchCenterHooks';
import { FormationGrid } from './components';

const MatchLineups = (): React.ReactElement => {
  const lineups = useLineupsSelector();
  const arePlayersLoaded = !!(lineups?.home?.players?.length && lineups?.away?.players?.length);
  const language = useLanguage();

  const renderStartLineups = (team: MatchLineupsTeam | undefined, isAway = false): React.ReactElement => (
    getLineupFormation(team?.formation).map((lineup) => {
      const player = team?.players?.find(({ place }) => place === lineup);
      return (
        <div key={`${player?.number}${player?.position}`}>
          {!isAway && (<PlayerNumber isFiorentina={isTeamFiorentina(team?.teamId)}>{(`0${player?.number}`).slice(-2)}</PlayerNumber>)}
          <PlayerName>{player?.name}</PlayerName>
          {isAway && (<PlayerNumber isFiorentina={isTeamFiorentina(team?.teamId)}>{(`0${player?.number}`).slice(-2)}</PlayerNumber>)}
        </div>
      );
    }) as unknown as React.ReactElement
  );

  return (
    <Container>
      <Heading>{Translations[language]?.['matchcenter.tabs.lineups']}</Heading>
      <FormationGrid />
      {arePlayersLoaded && (
        <>
          <Title>Starting Lineups</Title>
          <Lineups>
            <div>{renderStartLineups(lineups?.home)}</div>
            <div>{renderStartLineups(lineups?.away, true)}</div>
          </Lineups>
          <Divider />
          <Title>Substitutes</Title>
          <Lineups>
            <div>
              {lineups?.home?.players?.filter(({ place }) => place === 0).map((player) => (
                <div key={`${player?.number}${player?.position}`}>
                  <PlayerNumber isFiorentina={isTeamFiorentina(lineups?.home.teamId)}>
                    {(`0${player?.number}`).slice(-2)}
                  </PlayerNumber>
                  <PlayerName>{player?.name}</PlayerName>
                </div>
              ))}
            </div>
            <div>
              {lineups?.away?.players?.filter(({ place }) => place === 0).map((player) => (
                <div key={`${player?.number}${player?.position}`}>
                  <PlayerName>{player?.name}</PlayerName>
                  <PlayerNumber isFiorentina={isTeamFiorentina(lineups?.away.teamId)}>
                    {(`0${player?.number}`).slice(-2)}
                  </PlayerNumber>
                </div>
              ))}
            </div>
          </Lineups>
        </>
      )}
    </Container>
  );
};

export default MatchLineups;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  background-color: white;
  padding: 20px;
  border-radius: 8px;

  & > img {
    margin-bottom: 30px;
  }
`;

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  border-bottom: 1px solid rgba(0,0,0,.1);
  margin-bottom: 40px;
`;

const Heading = styled.h2`
  display: none;
`;

const PlayerNumber = styled.span<{ isFiorentina?: boolean }>`
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 15px;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  background-color: ${(props): string => (props.isFiorentina ? props.theme.color.purple : props.theme.color.red)};
  font-size: 12px;
  font-weight: bold;
  color: white;
`;

const PlayerName = styled.span`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: capitalize;
`;

const Title = styled.h2`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.46;
  letter-spacing: 0;
  text-align: center;
  color: ${(props): string => props.theme.color.shadow};
  text-transform: uppercase;
  margin-bottom: 15px;
`;

const Lineups = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:last-of-type {
    margin-top: 10px;
    margin-bottom: 0;
  }

  & > div {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    justify-content: flex-start;

    &:last-child {
      text-align: right;

      & > div {
        justify-content: flex-end;

        & > ${PlayerName} {
          padding-right: 15px;
          padding-left: 0;
        }
      }
    }

    & > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-right: 10px;
      padding-bottom: 20px;

      & > ${PlayerName} {
        padding-right: 0;
        padding-left: 15px;
      }

      & div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
`;
