import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales';
import AppRoutes from '@app/constants/routesConstants';
import { Devices } from '@app/styles';
import { useLanguage } from '@app/components/Hooks';
import LocalizedLink from '@app/components/Localization/LocalizedLink';

import MatchLineups from './MatchLineups/MatchLineups';
import MatchStatistics from './MatchStatistics/MatchStatistics';
import MatchStandings from './MatchStandings';

const MatchCenterTabs = (): React.ReactElement | null => {
  const { topLevel = '', secondLevel = '', thirdLevel = '', forthLevel = '' } = useParams();
  const language = useLanguage();
  const tabs = [
    Translations[language]?.['matchcenter.tabs.lineups'],
    Translations[language]?.['matchcenter.tabs.statistics'],
  ];

  const renderActiveTab = (): React.ReactElement | null => {
    switch (forthLevel) {
      case Translations[language]?.['matchcenter.tabs.lineups'].toLowerCase():
        return (<MatchLineups />);
      case Translations[language]?.['matchcenter.tabs.statistics'].toLowerCase():
        return (<MatchStatistics />);
      case Translations[language]?.['matchcenter.tabs.standings'].toLowerCase():
        return (<MatchStandings />);
      default:
        return null;
    }
  };

  return (
    <Container>
      <Tabs>
        {tabs.map((tab) => (
          <Tab key={tab} selected={tab.toLowerCase() === forthLevel}>
            <LocalizedLink
              pathKey={AppRoutes.CommonPage.path}
              pathParams={{
                topLevel,
                secondLevel,
                thirdLevel,
                forthLevel: tab.toLowerCase(),
                fifthLevel: '',
              }}
              isTab
            >
              {tab}
            </LocalizedLink>
          </Tab>
        ))}
      </Tabs>
      {renderActiveTab()}
    </Container>
  );
};

export default MatchCenterTabs;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  margin: 10px;
  justify-content: flex-start;

  @media ${Devices.tabletLarge} {
    margin: 0;
    flex: 0 0 360px;
    padding-top: 15px;
    padding-right: 15px;
  }

  @media ${Devices.desktopSmall} {
    padding-top: 30px;
    padding-right: 0;
  }
`;

const Tabs = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 10px 30px;
  margin-bottom: 10px;
`;

const Tab = styled.li<{ selected: boolean }>`
  margin-right: 30px;
  height: 100%;
  width: min-content;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.85;
  letter-spacing: -0.01px;
  text-transform: uppercase;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  display: inline-block;

  & a {
    color: #000;
    opacity: ${({ selected }): string => (selected ? '1' : '0.2')};
  }

  &:last-of-type {
    margin-right: 0;
  }
`;
