import { IContentItemsContainer, SortOrder } from '@kentico/kontent-delivery';

import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';

import { kenticoClient } from '@app/services/kentico/kentico';

import { LanguageType } from '@app/types/localizationTypes';
import { Promotion as KenticoPromotion } from '@app/services/kentico/types/promotion';
import { PromotionList, Promotion } from '@app/types/promotionsTypes';
import { mapPromotions } from '@app/services/kentico/converters/promotionsConverter';
import { convertPromotion } from '@app/services/kentico/converters/webTemplateConverter';
import { linkedItems } from '@app/services/kentico/converters/newsConverters';

type GetPromotionsList = (params: {
  skip: number;
  limit: number;
  language: LanguageType;
}) => Promise<PromotionList>

export const getPromotionList: GetPromotionsList = async (requestParams) => {
  const language = langCodeToKenticoLangMap[requestParams.language];
  const now = new Date();

  const request = kenticoClient()
    .items<KenticoPromotion>()
    .type('promotion')
    .languageParameter(language)
    .equalsFilter('system.language', language)
    .greaterThanOrEqualFilter('elements.expiration_date', now.toISOString())
    .orderParameter('elements.publication_date', SortOrder.desc)
    .limitParameter(requestParams.limit)
    .skipParameter(requestParams.skip);

  const response = await request.toPromise();
  return mapPromotions(response);
};

type GetPromotionDetailsByUrlSlugFunc = (params: {
  urlSlug?: string;
  id?: string;
  publicationDate?: string;
  language: LanguageType;
}) => Promise<Promotion | null>

export const getPromotionDetails: GetPromotionDetailsByUrlSlugFunc = async (requestParams) => {
  const {
    urlSlug, id, publicationDate, language,
  } = requestParams;

  const filterName = urlSlug ? 'elements.url' : 'system.id';
  const filterData = urlSlug || id;

  const query = kenticoClient()
    .items<KenticoPromotion>().type('promotion')
    .languageParameter(langCodeToKenticoLangMap[language])
    .equalsFilter('system.language', langCodeToKenticoLangMap[language])
    .equalsFilter(filterName, filterData ?? '');

  if (publicationDate) {
    query.greaterThanOrEqualFilter('elements.publication_date', `${publicationDate}T00:00:00Z`)
      .lessThanOrEqualFilter('elements.publication_date', `${publicationDate}T23:59:59Z`);
  }

  const response = await query.toPromise();

  const item = response.items[0];

  if (!item) {
    return null;
  }

  return convertPromotion(item, item, response?.linkedItems as IContentItemsContainer<linkedItems>);
};
