import styled from 'styled-components';

import { Devices } from '@app/styles';

export const PageTitle = styled.h1`
  font-size: 14px;
  letter-spacing: 0.14px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  color: #000;
  margin-top: 30px;

  @media ${Devices.tablet} {
    font-size: 16px;
    letter-spacing: 0.16px;
    margin-top: 45px;
  }
`;

export default PageTitle;
