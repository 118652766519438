// Todo: update with all pages
export enum PageType {
  Template,
  News,
  NewsDetails,
  NewsSearch,
  Videos,
  VideosDetails,
  PhotoGalleries,
  PhotoGalleriesDetails,
  Team,
  PlayerProfile,
  MatchCenter,
  Season,
  Schedule,
  Standings,
  Tickets,
  Privacy,
  InviolaAuth,
  InviolaReset,
  InviolaProfile,
  InviolaSettings,
  InviolaCalatog,
  InviolaAwards,
  InviolaShops,
  InviolaPromotions,
  InviolaPromotionsDetails,
}
