import React from 'react';
import { MidfielderStatistics } from '@app/types/teamsTypes';
import styled from 'styled-components';

import {
  ValueAndCaption, BlockContainerColumn, StatsBlock, StatsColumn, StatsColumns, ValueAndGraph,
} from '@app/components/Statistics';
import {
  DisciplineStatistics,
  DuelsStatistics,
  PassesStatistics,
} from '@app/pages/PlayerProfile/Profiles/PlayerPositionStatistics/StatisticsSections';

interface MidfielderStatisticsProps {
  statistics?: MidfielderStatistics;
}

const MidfielderPlayerShotStatistics = ({ statistics }: MidfielderStatisticsProps): React.ReactElement => (
  <BlockContainerColumn>
    <Line><ValueAndCaption value={statistics?.totalShots ?? 0} caption="statistics.shots" /></Line>
    <Line>
      <ValueAndGraph
        caption="statistics.accuracy"
        progress={
          statistics?.shotsOnTarget && statistics?.totalShots
            ? ((statistics.shotsOnTarget * 100) / statistics.totalShots)
            : 0
        }
        value={
          statistics?.shotsOnTarget && statistics?.totalShots
            ? `${Math.round((statistics.shotsOnTarget * 100) / statistics.totalShots)}%`
            : '0%'
        }
        width={200}
      />
    </Line>
    <Line>
      <ValueAndGraph
        caption="statistics.onTarget"
        progress={
          statistics?.shotsOnTarget && statistics?.totalShots
            ? ((statistics.shotsOnTarget * 100) / statistics.totalShots)
            : 0
        }
        value={statistics?.shotsOnTarget ?? 0}
        width={200}
      />
    </Line>
    <Line>
      <ValueAndGraph
        caption="statistics.offTarget"
        progress={
          statistics?.shotsOffTarget && statistics?.totalShots
            ? ((statistics.shotsOffTarget * 100) / statistics.totalShots)
            : 0
        }
        value={statistics?.shotsOffTarget ?? 0}
        width={200}
      />
    </Line>
    <Line>
      <ValueAndGraph
        caption="statistics.blocked"
        progress={
          statistics?.blockedShots && statistics?.totalShots
            ? ((statistics.blockedShots * 100) / statistics.totalShots)
            : 0
        }
        value={statistics?.blockedShots ?? 0}
        width={200}
      />
    </Line>
  </BlockContainerColumn>
);

const MidfielderPlayerRecoveryStatistics = ({ statistics }: MidfielderStatisticsProps): React.ReactElement => (
  <BlockContainerColumn>
    <StatsBlock borderWidth="0 0 1px 0">
      <StatsBlock marginBottom="25px">
        <ValueAndCaption value={statistics?.recoveries ?? 0} caption="statistics.recoveries" />
      </StatsBlock>
    </StatsBlock>
    <StatsBlock marginTop="25px">
      <StatsBlock><ValueAndCaption value={statistics?.totalTackles ?? 0} caption="statistics.tackles" isSmall /></StatsBlock>
      <StatsBlock><ValueAndCaption value={statistics?.totalFoulsConceded ?? 0} caption="statistics.foulsCommitted" isSmall /></StatsBlock>
      <StatsBlock><ValueAndCaption value={statistics?.totalFoulsWon ?? 0} caption="statistics.foulsSustained" isSmall /></StatsBlock>
    </StatsBlock>
  </BlockContainerColumn>
);

const MidfielderLayoutStatistics = ({ statistics }: MidfielderStatisticsProps): React.ReactElement => (
  <>
    <StatsColumns>
      <MidfielderPlayerShotStatistics statistics={statistics} />
      <StatsColumn>
        <MidfielderPlayerRecoveryStatistics statistics={statistics} />
        <PassesStatistics statistics={statistics} />
      </StatsColumn>
    </StatsColumns>
    <StatsColumns>
      <DuelsStatistics statistics={statistics} />
      <DisciplineStatistics statistics={statistics} />
    </StatsColumns>
  </>
);

const Line = styled.div`
  margin-bottom: 15px;
`;

export default MidfielderLayoutStatistics;
