import React from 'react';
import styled from 'styled-components';

import { WebTemplateCarousel, WebTemplateTypes } from '@app/types/webTemplateTypes';
import { CarouselBase, CarouselItem } from '@app/components/Carousel/CarouselBase';
import { HomeVideoPreviewElement, HomeWebCardElement } from '@app/components/Home/components';
import HomeTitle from '@app/components/Home/components/HomeTitle';
import { FullSizeNewItem } from '@app/components/News';
import { EditorialContentTypes } from '@app/types/newsTypes';

interface WebCardElementProps {
  data: WebTemplateCarousel;
}

const WebHomeCarouselElement = ({ data }: WebCardElementProps): React.ReactElement => {
  const items = data.items.map((item) => item.content[0]).filter((item) => !!item);
  const slides: CarouselItem[] = items.map((item) => {
    let component;
    switch (item?.type) {
      case EditorialContentTypes.NEWS:
      case EditorialContentTypes.PHOTO:
        component = FullSizeNewItem;
        break;
      case EditorialContentTypes.VIDEO:
        component = HomeVideoPreviewElement;
        break;
      case WebTemplateTypes.WebCard:
        component = HomeWebCardElement;
        break;
      default:
        component = HomeWebCardElement;
    }
    return {
      ItemComponent: component,
      item,
    };
  });

  const imageWidthMap = {
    1025: { width: 1280, height: 500 },
    769: { width: 1024, height: 1500 },
    376: { width: 768, height: 1200 },
    0: { width: 375, height: 660 },
  };

  return (
    <Container>
      {data.title && (<HomeTitle title={data.title} link={data.link} />)}
      <CarouselBaseStyle items={slides} imageWidthMap={imageWidthMap} shiftInfo />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0;
`;

const CarouselBaseStyle = styled(CarouselBase)`
  width: 100%;
`;

export default WebHomeCarouselElement;
