import React from 'react';

import {
  NewsContentItem, BaseNewsItem, NewsContentType,
} from '@app/types/newsTypes';

import { WebAdvertisementBannerElement, WebEditorialTextElement } from '@app/components/WebTemplates/elements';
import { ThroneAsset, ThroneVideo } from '@app/types/configurationTypes';
import { WebTemplateAdvertisementBanner } from '@app/types/webTemplateTypes';

import { News, Image, Video, Gallery } from './Types';

interface NewsContentProps {
  content: (NewsContentItem | null)[];
}

export const NewsContent = ({ content }: NewsContentProps): React.ReactElement => (
  <>
    {
      content.map((item, key) => {
        if (!item) return null;
        const { type, value, isPremium = false } = item;
        if (!value) return null;
        const keygen = `${type}${key}`;
        switch (type) {
          case NewsContentType.TEXT:
            return <WebEditorialTextElement content={value as ''} key={keygen} />;
          case NewsContentType.NEWS:
            return <News news={value as BaseNewsItem} key={keygen} isPremium={isPremium} />;
          case NewsContentType.VIDEO:
            return <Video video={value as ThroneVideo} key={keygen} isPremium={isPremium} />;
          case NewsContentType.IMAGE:
            return <Image image={value as ThroneAsset} key={keygen} scaleMode="none" />;
          case NewsContentType.GALLERY:
            return <Gallery photos={value as ThroneAsset[]} key={keygen} isPremium={isPremium} />;
          case NewsContentType.BANNER:
            return <WebAdvertisementBannerElement data={value as WebTemplateAdvertisementBanner} key={keygen} />;
          default:
            return null;
        }
      })
    }
  </>
);

export default NewsContent;
