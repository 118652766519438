import React from 'react';
import { useIntl } from 'react-intl';
import { useBarcode } from 'react-barcodes';
import styled from 'styled-components';

import { Devices } from '@app/styles';

import { Button, ButtonOutline } from '@app/components/Inviola/InviolaComponents';
import { Modal } from '@app/components';

import { useInviolaCustomer } from '@app/components/Inviola/InviolaHooks';
import { useAward } from '../AwardsPageHooks';

interface AwardModalProps {
  id?: number;
  onClose: () => void;
}

const AwardBarCode = ({ barcode = '' }): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { inputRef } = useBarcode({ value: barcode });
  const barcodeRef = inputRef as React.RefObject<SVGSVGElement>;

  return (
    <BarcodeContainer><svg ref={barcodeRef} /></BarcodeContainer>
  );
};

const AwardModal = ({ id, onClose }: AwardModalProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  const customer = useInviolaCustomer();
  const award = useAward(id);

  const printAward = () => {
    try {
      // Print for Safari browser
      document.execCommand('print', false);
    } catch {
      window.print();
    }
  };

  return (
    <Modal open={!!id} onClose={onClose} contentFullWidth={false}>
      <ModalContainer>
        <OverflowScrollingWrapper>
          <div id="award-modal">
            <TextCenter>
              <InviolaLogo
                width="42"
                height="63"
                src="/images/logo.svg"
                alt={formatMessage({ id: 'mainLogo.alt' })}
              />
            </TextCenter>

            <ContentBlock>
              <CustomerPresentation>
                <p>{ `${customer?.firstName} ${customer?.lastName}` }</p>
                <p>{ `${formatMessage({ id: 'inviola.awards.cardNumber' })} ${customer?.cardNumber}` }</p>
                <p>{ `${formatMessage({ id: 'inviola.awards.exchangeDate' })} ${award?.exchangeDate}` }</p>
              </CustomerPresentation>
            </ContentBlock>

            <ContentBlock>
              <Image width="800" height="600" alt="asset" src={award?.pathImageAbsolute} />
              <Name>{award?.name}</Name>
            </ContentBlock>

            <ContentBlock>
              {award?.barcode && (<AwardBarCode barcode={award.barcode} />)}
            </ContentBlock>

            <ContentBlock>
              { formatMessage({ id: 'inviola.awards.details' }) }
            </ContentBlock>
          </div>
          <ButtonBlock>
            <Button onClick={printAward}>
              {formatMessage({ id: 'inviola.awards.print' })}
            </Button>
            <ButtonOutline onClick={onClose}>
              {formatMessage({ id: 'inviola.profile.card.transfer.back' })}
            </ButtonOutline>
          </ButtonBlock>
        </OverflowScrollingWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default AwardModal;

const ModalContainer = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 0;

  background-color: ${(props): string => props.theme.color.whiteSmoke};
  color: black;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  text-align: left;

  max-height: 100vh;
  overflow: auto;

  button {
    width: 100%;
    max-width: 300px;
    margin: 10px auto;

    font-size: 0.857rem;
  }

  @media ${Devices.tablet} {
    button {
      margin: 10px;
    }
  }

  @media ${Devices.desktop} {
    padding: 30px;
  }
`;

const OverflowScrollingWrapper = styled.div`
  padding: 30px;
  overflow: hidden;

  @media ${Devices.desktop} {
    padding: 0;
  }
`;

const InviolaLogo = styled.img`
  margin-bottom: 15px;
`;

const BarcodeContainer = styled.div`
  text-align: center;
  max-width: 176px;
  width: 100%;
  height: auto;

  margin: auto;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const ContentBlock = styled.div`
  background-color: white;
  padding: 10px 20px;
  margin: 10px 0;

  align-items: center;
  display: flex;
`;

const ButtonBlock = styled.div`
  background-color: white;
  padding: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Devices.tablet} {
    flex-direction: row;
  }

  @media print {
    display: none;
  }
`;

const CustomerPresentation = styled.div`
  flex-wrap: wrap;
  display: flex;
  width: 100%;

  p:first-child {
    flex: 0 0 100%;
  }

  p {
    flex: 1;
  }
`;

const Image = styled.img`
  max-width: 110px;
  width: 100%;
  height: auto;

  margin-right: 10px;
`;

const Name = styled.strong`
  font-size: 1rem;
  text-transform: uppercase;
`;

const TextCenter = styled.div`
  text-align: center;
`;
