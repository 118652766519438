import React from 'react';
import { useParams } from 'react-router-dom';

import { PlayerPositionFilter } from '@app/types/teamsTypes';

import { PlayersCategory } from '@app/components';
import { PlayerCard } from '@app/pages/Teams/Player/Player';

import {
  useFilteredPlayers,
  getCategoryName,
  useCategoryMenu,
} from '@app/pages/Teams/TeamsHooks';

const TeamPlayersList: React.FC<{position: PlayerPositionFilter}> = ({ position }) => {
  const { secondLevel = '' } = useParams();
  const categoryMenu = useCategoryMenu();
  const players = useFilteredPlayers(position);

  if (!players || !players.length) return null;

  return (
    <PlayersCategory
      type={getCategoryName({ categoryMenu, position })}
      key={position}
    >
      {
        players.map((item, index) => (
          <PlayerCard item={item} key={item.id !== '0' ? item.id : index} teamType={secondLevel} />
        ))
      }
    </PlayersCategory>
  );
};

export default TeamPlayersList;
