import React from 'react';
import styled from 'styled-components';

import { WebTemplateLiveVideo } from '@app/types/webTemplateTypes';
import { WebLiveElement } from '@app/components/Home/elements';
import { useLiveElement } from '@app/pages/Home/HomeHooks';
import { NewsType } from '@app/components/News/NewsItemInfo';
import { FormattedMessage } from 'react-intl';
import { Devices } from '@app/styles';

import { ContainerMedium } from '../components';

interface WebLandingElementProps {
  data: WebTemplateLiveVideo;
}

const WebLiveVideoElement = ({ data }: WebLandingElementProps): React.ReactElement | null => {
  const live = useLiveElement();
  return data && live && (
    <ContainerMedium>
      <Content>
        <WebLiveElement data={live} showTitle={false} />
        <TitleWrapper>
          <NewsType size="large">
            <FormattedMessage id="liveItem.label" />
          </NewsType>
          { live.title && (
            <div>{live.title}</div>
          )}
        </TitleWrapper>
      </Content>
    </ContainerMedium>
  );
};

const Content = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const TitleWrapper = styled.div`
  position: relative;
  z-index: 100;
  margin-top: 20px;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 19px;
  font-weight: bold;
  line-height: 1.46;
  text-transform: uppercase;

  @media ${Devices.largeMobile} {
    font-size: 23px;
  }

  @media ${Devices.desktop} {
    margin-top: 50px;
    font-size: 26px;
  }
`;

export default WebLiveVideoElement;
