import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import AppRoutes from '@app/constants/routesConstants';
import AppState from '@app/types/appStateTypes';
import { PageError } from '@app/types/errorTypes';
import { getConfiguration } from '@app/store/actions/configurationActions';

import { LocalizedSwitch } from '@app/components/Localization';
import {
  Header, MobileMenu, Partners, Footer, CookieBanner, Loader, LiveAudio,
} from '@app/components';
import { useIsMenuOpened } from '@app/components/Hooks';
import { isServer } from '@app/helpers/serverHelpers';

const ViewPort = (): React.ReactElement => {
  const host = 'acffiorentina.com';
  const isLiveHost = [host, `www.${host}`].includes(
    document.location.hostname ? document.location.hostname : window.location.hostname,
  );

  const dispatch = useDispatch();
  const isMenuOpened = useIsMenuOpened();

  const isConfigurationDownloaded = useSelector<AppState, boolean>(
    (state) => state.configuration.isConfigurationDownloadCompleted,
    shallowEqual,
  );

  useEffect((): void => {
    !isConfigurationDownloaded && dispatch(getConfiguration());
  }, []);

  return (
    <Container>
      <Helmet>
        {!isLiveHost && (<meta name="robots" content="noindex, nofollow" />)}
      </Helmet>
      <Header />
      <MobileMenu />
      <HideOnMenuOpened isOpened={isMenuOpened} isConfigurationDownloaded={isConfigurationDownloaded}>
        {!isConfigurationDownloaded ? (<Loader />) : (
          <>
            <Main>
              <LocalizedSwitch>
                <Route path={AppRoutes.Home.path} component={AppRoutes.Home.component} exact />
                <Route path={AppRoutes.CommonPage.path} component={AppRoutes.CommonPage.component} exact />
                <Route exact path="*">
                  {/* eslint-disable-next-line react/jsx-pascal-case */}
                  <AppRoutes.NotFound.component error={PageError.NotFound} />
                </Route>
              </LocalizedSwitch>
            </Main>
            <Partners />
          </>
        )}
        <Footer />
      </HideOnMenuOpened>
      <CookieBanner />
      {!isServer() && <LiveAudio />}
    </Container>
  );
};

export default ViewPort;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
`;

const Main = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const HideOnMenuOpened = styled.div<{ isOpened: boolean; isConfigurationDownloaded: boolean }>`
  padding-top: ${({ isConfigurationDownloaded }): string => (isConfigurationDownloaded ? '0' : '150px')};
  display: ${({ isOpened }): string => (isOpened ? 'none' : 'flex')};
  flex-direction: column;
  flex-grow: 2;
`;
