import React from 'react';

import { ThroneAsset } from '@app/types/configurationTypes';

import { GalleryPreview } from '@app/components/Gallery';

interface ImageProps {
  photos: ThroneAsset[];
  isPremium: boolean;
}

const Gallery = ({ photos, isPremium }: ImageProps): React.ReactElement => (
  <GalleryPreview photos={photos} isPremium={isPremium} />
);

export default Gallery;
