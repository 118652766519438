import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { useInviolaCustomer } from '@app/components/Inviola/InviolaHooks';

interface PointsStatusProps {
  itemPoints: number;
}

export const PointsStatusBar = ({ itemPoints }: PointsStatusProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const customer = useInviolaCustomer();

  const balancePoints = customer?.balancePoints ?? 0;
  const canRequestItem = balancePoints >= itemPoints;
  const missingPoints = canRequestItem ? 0 : (itemPoints - balancePoints);

  const balancePointsPercent = canRequestItem ? 100 : (balancePoints / itemPoints) * 100;
  const missingPointsPercent = 100 - balancePointsPercent;

  const authorizationStatus = formatMessage({ id: 'inviola.catalogDetails.registrationRequired' });
  const pointsStatus = canRequestItem
    ? formatMessage({ id: 'inviola.catalogDetails.hasPointsStatus' })
    : formatMessage({ id: 'inviola.catalogDetails.requiredPointsStatus' })
      .replace('{requiredPoints}', `${missingPoints}`);

  const status = customer ? pointsStatus : authorizationStatus;

  return (
    <Section>
      <Status // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: status }}
        textAlign={customer ? 'left' : 'center'}
      />
      {customer && (
        <PointsBar>
          <PointsBalance percent={balancePointsPercent}>
            {canRequestItem ? itemPoints : (balancePoints > 0) && balancePoints}
          </PointsBalance>
          <PointsRequired percent={missingPointsPercent}>
            {canRequestItem ? '' : (itemPoints - balancePoints)}
          </PointsRequired>
        </PointsBar>
      )}
    </Section>
  );
};

export default PointsStatusBar;

const Section = styled.section`
  width: 100%;
  margin: 15px 0;
`;

const Status = styled.p<{ textAlign: string }>`
  text-align: center;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  color: #1d1d26;

  span {
    color: ${(props): string => props.theme.color.red};
  }

  @media ${Devices.tabletLarge} {
    text-align: ${({ textAlign }): string => textAlign};
  }
`;

const PointsBar = styled.section`
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: row;

  height: 18px;
  flex: 6.4;

  margin-top: 5px;
  margin-bottom: 15px;

  background-color: #d0d0d0;
  border: none;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: center;
`;

const PointsBalance = styled.div<{ percent: number }>`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 ${(props): number => props.percent}%;
  justify-content: center;
  align-items: center;

  padding: 1px ${(props): number => (props.percent ? 3 : 0)}px;;

  color: white;
  background-color: ${(props): string => props.theme.color.red};
`;

const PointsRequired = styled(PointsBalance)`
  color: ${(props): string => props.theme.color.red};
  background-color: transparent;
`;
