import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { PageTitle } from '@app/components';
import { CommonButton } from '@app/components/Buttons';
import { ExpandArrow } from '@app/components/Icons';
import { ContainerMedium } from '@app/components/WebTemplates/components';
import { InviolaCatalogItem } from '@app/types/inviolaTypes';
import CatalogItemsList from '@app/pages/Inviola/CatalogPage/CatalogItemsList';
import {
  setCatalogFilterPage,
  setCatalogFilterCategory,
  setCatalogFilterMinPoints,
  setCatalogFilterMaxPoints,
} from '@app/store/actions/inviolaActions';

import { useCatalogFilters, useCatalogScrollToSelectedItem } from './CatalogPageHooks';

export interface InviolaCatalogItemsListWithFiltersProps {
  items: InviolaCatalogItem[];
}

const CatalogItemsListWithFilters = ({ items = [] }: InviolaCatalogItemsListWithFiltersProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { pageCount, category, minPoints, maxPoints } = useCatalogFilters();
  const points = [1000, 2000, 5000, 10000, 20000, 50000, 100000];
  const defaultPoints = -1;
  const defaultCategory = {
    id: -1,
    idFather: 0,
    description: 'All type of items',
  };
  const categories = items
    .map((item) => item.category)
    .filter((value, index, self) => self.map((selfItem) => selfItem.id)
      .indexOf(value.id) === index);

  const itemsFiltered = items?.filter((item) => {
    let result = true;

    if (category !== defaultCategory.id) result = item.category.id === category;
    if (minPoints !== defaultPoints && result) result = item.points >= minPoints;
    if (maxPoints !== defaultPoints && result) result = item.points <= maxPoints;

    return result;
  }) ?? [];

  const itemsToDisplay = itemsFiltered.slice(0, 10 * pageCount) ?? [];
  const hasMoreData = itemsToDisplay.length < itemsFiltered.length;

  const onCategorySelection = (e: { target: { value: string; }; }): void => {
    dispatch(setCatalogFilterCategory(parseInt(e.target.value, 10)));
  };
  const onMinPointsSelection = (e: { target: { value: string; }; }): void => {
    dispatch(setCatalogFilterMinPoints(parseInt(e.target.value, 10)));
  };
  const onMaxPointsSelection = (e: { target: { value: string; }; }): void => {
    dispatch(setCatalogFilterMaxPoints(parseInt(e.target.value, 10)));
  };

  useCatalogScrollToSelectedItem();

  return (
    <ContainerMedium>
      <FiltersSection>
        <PageTitle>{formatMessage({ id: 'inviola.catalog.title' })}</PageTitle>
        <FiltersContainer>
          <SelectContainer>
            <SelectWrapper>
              <Select value={category} onChange={onCategorySelection}>
                <option key={defaultCategory.id} value={defaultCategory.id}>{defaultCategory.description}</option>
                {categories
                  ?.map(({ id, description }) => <option key={id} value={id}>{description}</option>)}
              </Select>
              <ExpandArrow />
            </SelectWrapper>
            <SelectWrapper>
              <Select value={minPoints} onChange={onMinPointsSelection}>
                <option key={defaultPoints} value={defaultPoints}>Min. Points</option>
                {points?.map((point) => <option key={point} value={point}>{point}</option>)}
              </Select>
              <ExpandArrow />
            </SelectWrapper>
            <SelectWrapper>
              <Select value={maxPoints} onChange={onMaxPointsSelection}>
                <option key={defaultPoints} value={defaultPoints}>Max. Points</option>
                {points?.map((point) => <option key={point} value={point}>{point}</option>)}
              </Select>
              <ExpandArrow />
            </SelectWrapper>
          </SelectContainer>
        </FiltersContainer>
      </FiltersSection>
      <CatalogItemsList items={itemsToDisplay} />
      { hasMoreData && (
        <LoadMoreContainer>
          <LoadMoreButton onClick={() => dispatch(setCatalogFilterPage(pageCount + 1))}>
            <FormattedMessage id="buttons.loading" />
          </LoadMoreButton>
        </LoadMoreContainer>
      ) }
    </ContainerMedium>
  );
};

const LoadMoreContainer = styled.div`
  padding: 20px 0 40px;
`;

const LoadMoreButton = styled(CommonButton)`
  border-color: ${(props): string => props.theme.color.red};
  color: ${(props): string => props.theme.color.red};
  height: 35px;
  margin: 0 auto;
  width: auto;
  padding: 0 25px;

  &:hover {
    background-color: ${(props): string => props.theme.color.red};
    color: white;
  }
`;

const FiltersSection = styled.section`
  max-width: 920px;
  padding: 0 10px;
  margin: auto auto 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  & h1 {
    font-size: 18px;
  }

  @media ${Devices.tablet} {
    & h1 {
      font-size: 28px;
    }
  }

  @media ${Devices.tabletLarge} {
    flex-direction: row;
    align-items: flex-end;
  }

  @media ${Devices.desktop} {
    padding: 0;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${Devices.tabletLarge} {
    flex-direction: row;
  }
`;

const SelectContainer = styled.div`
  display: flex;
`;

const SelectWrapper = styled.div`
  position: relative;
  width: auto;
  height: auto;
  margin: 10px 0 0 10px;

  &:first-child {
    margin: 10px 0 0 0;
  }

  &:first-of-type {
    & > select {
      min-width: 100px;
    }
  }

  & > svg {
    position: absolute;
    z-index: 9;
    right: 10px;
    bottom: 10px;
  }

  @media ${Devices.tabletLarge} {
    margin: 0 0 0 20px;

    &:first-child {
      margin: 0 0 0 20px;
    }
  }
`;

const Select = styled.select`
  position: relative;
  z-index: 10;
  width: 100%;
  min-width: 100px;
  max-width: 160px;
  height: 30px;
  padding: 0 25px 0 15px;
  border-radius: 15px;
  border: solid 1px #dfdbdb;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  letter-spacing: 0.23px;
  font-size: 14px;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
`;

export default CatalogItemsListWithFilters;
