import React from 'react';

const LiveAudioIcon = (): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="39" height="22" viewBox="0 0 39 22">
    <g fill="none" fillRule="evenodd">
      <g className="strokes" fill="#5F259F" fillRule="nonzero">
        <g>
          <g>
            <path d="M24.5-1.25c.647 0 1.18.492 1.244 1.122L25.75 0v18c0 .69-.56 1.25-1.25 1.25-.647 0-1.18-.492-1.244-1.122L23.25 18V0c0-.69.56-1.25 1.25-1.25zm-18 3c.647 0 1.18.492 1.244 1.122L7.75 3v12c0 .69-.56 1.25-1.25 1.25-.647 0-1.18-.492-1.244-1.122L5.25 15V3c0-.69.56-1.25 1.25-1.25zm12 0c.647 0 1.18.492 1.244 1.122L19.75 3v12c0 .69-.56 1.25-1.25 1.25-.647 0-1.18-.492-1.244-1.122L17.25 15V3c0-.69.56-1.25 1.25-1.25zm12 0c.647 0 1.18.492 1.244 1.122L31.75 3v12c0 .69-.56 1.25-1.25 1.25-.647 0-1.18-.492-1.244-1.122L29.25 15V3c0-.69.56-1.25 1.25-1.25zm-30 3c.647 0 1.18.492 1.244 1.122L1.75 6v6c0 .69-.56 1.25-1.25 1.25-.647 0-1.18-.492-1.244-1.122L-.75 12V6c0-.69.56-1.25 1.25-1.25zm12 0c.647 0 1.18.492 1.244 1.122L13.75 6v6c0 .69-.56 1.25-1.25 1.25-.647 0-1.18-.492-1.244-1.122L11.25 12V6c0-.69.56-1.25 1.25-1.25zm24 0c.647 0 1.18.492 1.244 1.122L37.75 6v6c0 .69-.56 1.25-1.25 1.25-.647 0-1.18-.492-1.244-1.122L35.25 12V6c0-.69.56-1.25 1.25-1.25z" transform="translate(-528.000000, -502.000000) translate(515.000000, 490.000000) translate(14.000000, 14.000000)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LiveAudioIcon;
