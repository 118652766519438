import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const PointsIcon = ({ points }: { points: number }): React.ReactElement => (
  <Icon>
    <Points>{points}</Points>
    <Label><FormattedMessage id="inviola.catalog.pts" /></Label>
  </Icon>
);

export default PointsIcon;

export const Icon = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50px;
  height: 50px;

  border-radius: 50%;
  color: #ffffff;
  background-color: ${(props): string => props.theme.color.red};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  cursor: default;
`;

const Points = styled.p`
  padding-top: 1px;
`;

const Label = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 12px;
  font-weight: 300;
`;
