import cloneDeep from 'lodash/cloneDeep';

import { Errors } from '@app/types/errorTypes';
import { LocalizedPromotionItem, PromotionList } from '@app/types/promotionsTypes';
import { PromotionsActions, PromotionsActionTypes } from '@app/store/actionTypes/promotionsActionTypes';

export type PromotionsState = {
  list: PromotionList;
  selected: {
    item: LocalizedPromotionItem;
  };
  error: Errors;
  isLoading: boolean;
};

const initialState: PromotionsState = {
  isLoading: false,
  list: {
    items: [],
    hasMoreData: false,
  },
  selected: {
    item: {},
  },
  error: null,
};

export default (
  state: PromotionsState = cloneDeep(initialState),
  action: PromotionsActions,
): PromotionsState => {
  switch (action.type) {
    case PromotionsActionTypes.SET_PROMOTIONS_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          items: [...state.list.items, ...action.payload.items],
          hasMoreData: action.payload.hasMoreData,
        },
      };
    case PromotionsActionTypes.SET_PROMOTIONS_LOADING_STATE:
      return {
        ...state,
        isLoading: action.payload,
      };
    case PromotionsActionTypes.RESET_PROMOTIONS_LIST:
      return {
        ...state,
        list: { ...initialState.list },
        error: null,
      };
    case PromotionsActionTypes.SET_PROMOTION:
      return {
        ...state,
        selected: {
          ...state.selected,
          item: {
            ...state.selected.item,
            ...action.payload,
          },
        },
        error: null,
      };
    case PromotionsActionTypes.RESET_PROMOTION:
      return {
        ...state,
        selected: {
          ...state.selected,
          item: initialState.selected.item,
        },
        error: null,
      };
    case PromotionsActionTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
