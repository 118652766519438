import React from 'react';
import { useIntl } from 'react-intl';
import { useFormContext, ValidationRules, FieldError } from 'react-hook-form';
import get from 'lodash/get';

import {
  Hint,
  Label,
  CheckboxLabel,
  Input as InputField,
  Select as SelectField,
  Checkbox as CheckboxField,
  TextError,
  CheckboxError,
} from '@app/components/Inviola/InviolaComponents';

interface HiddenInputProps {
  name: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [rest:string]: any
}

interface InputProps {
  name: string;
  labelId: string;
  hintId?: string;
  placeholderId?: string;
  type?: string;
  validations?: ValidationRules

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [rest:string]: any
}

interface SelectOption {
  text: string;
  value: string;
}

interface SelectProps extends InputProps {
  options?: SelectOption[],
  emptyOption?: boolean,
  disabled?: boolean
}

export const Input = React.memo(({ name, labelId, hintId, placeholderId, type = 'text', validations = {}, ...rest }: InputProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const { register, errors } = useFormContext();

  const fieldError = get(errors, name, null) as FieldError;

  return (
    <>
      <Label htmlFor={name}>
        { formatMessage({ id: labelId }) }
        { validations?.required && <sup>*</sup> }
      </Label>
      <InputField
        type={type}
        name={name}
        id={name}
        ref={register(validations)}
        placeholder={placeholderId && formatMessage({ id: placeholderId })}

        {...rest}
      />
      { hintId && !fieldError && <Hint>{ formatMessage({ id: hintId }) }</Hint> }
      { fieldError && <TextError>{fieldError?.message}</TextError> }
    </>
  );
});

export const HiddenInput = React.memo(({ name, ...rest }: HiddenInputProps): React.ReactElement => {
  const { register } = useFormContext();

  return (
    <InputField
      type="hidden"
      name={name}
      ref={register()}

      {...rest}
    />
  );
});

export const Select = React.memo((
  { name, labelId, validations = {}, options = [], emptyOption = false, disabled = false, ...rest }: SelectProps,
): React.ReactElement => {
  const { formatMessage } = useIntl();
  const { register, errors } = useFormContext();

  const fieldError = get(errors, name, null) as FieldError;

  return (
    <>
      <Label htmlFor={name}>
        {formatMessage({ id: labelId })}
        { validations?.required && <sup>*</sup> }
      </Label>
      <SelectField
        name={name}
        id={name}
        ref={register(validations)}
        disabled={disabled}

        {...rest}
      >
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        { emptyOption && <option /> }
        { options.map((option) => <option key={option.value} value={option.value}>{option.text}</option>)}
      </SelectField>
      { fieldError && <TextError>{fieldError?.message}</TextError> }
    </>
  );
});

export const Checkbox = React.memo(({ name, labelId, validations = {}, ...rest }: InputProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const { register, errors } = useFormContext();

  const fieldError = get(errors, name, null) as FieldError;

  return (
    <>
      <CheckboxLabel required={validations?.required && true}>
        <CheckboxField
          type="checkbox"
          name={name}
          ref={register(validations)}

          {...rest}
        />
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: formatMessage({ id: labelId }) }} />
        { validations?.required && <sup>*</sup> }
      </CheckboxLabel>
      { fieldError && <CheckboxError>{fieldError?.message}</CheckboxError> }
    </>
  );
});
