import { ContentItem, Elements } from '@kentico/kontent-delivery';

import CtaButton from './cta_button';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class WebInviolaMembershipCard extends ContentItem {
    public cardImage: Elements.AssetsElement;
    public id: Elements.TextElement;
    public ctaButton: Elements.LinkedItemsElement<CtaButton>;
    public title: Elements.TextElement;
    public price: Elements.TextElement;
    public description: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'card_image') {
                    return 'cardImage';
                }
                if (elementName === 'cta_button') {
                    return 'ctaButton';
                }
                return elementName;
            })
        });
    }
}
