import { ThroneAsset, LayoutType } from '@app/types/configurationTypes';
import { LanguageType } from '@app/types/localizationTypes';
import Metadata from '@app/components/Seo/Metadata';
import { WebTemplateAdvertisementBanner } from '@app/types/webTemplateTypes';

export enum EditorialContentTypes {
  NEWS = 'news',
  VIDEO = 'video',
  PHOTO = 'photo_gallery',
}

export interface BaseNewsData {
  urlSlug: string;
  publicationDate: string;
  title: string;
  subtitle: string;
  codeName: string;
  id: string;
  categoryName: string;
  categoryCode: string;
  type: EditorialContentTypes;
  isPremium: boolean;
}

export interface BaseNewsItem extends BaseNewsData {
  image: ThroneAsset;
  imageVertical?: ThroneAsset;
  photosNumber: number;
}

export interface NewsList {
  hasMoreData: boolean;
  items: BaseNewsItem[];
}

export interface NewsListComponent {
  items: BaseNewsItem[];
  hasMoreData: boolean;
  isLoading: boolean;
  layout: LayoutType;
}

export interface Gallery extends BaseNewsData, Metadata {
  photos: ThroneAsset[];
}

export type LocalizedGalleryItem = Partial<Record<LanguageType, Gallery | null>>

export interface Video extends BaseNewsData, Metadata {
  image: ThroneAsset;
  video: ThroneAsset;
  isLive: boolean
}

export type LocalizedVideoItem = Partial<Record<LanguageType, Video | null>>

export enum NewsContentType {
  TEXT = 'TEXT',
  GALLERY = 'GALLERY',
  VIDEO = 'VIDEO',
  NEWS = 'NEWS',
  IMAGE = 'IMAGE',
  BANNER = 'BANNER',
}

interface TextContentItem {
  type: NewsContentType.TEXT;
  value: string;
  isPremium?: boolean;
}

interface NewsDetailsContentItem {
  type: NewsContentType.NEWS;
  value: BaseNewsItem;
  isPremium?: boolean;
}

interface VideoContentItem {
  type: NewsContentType.VIDEO;
  value: ThroneAsset;
  isPremium?: boolean;
}

interface ImageContentItem {
  type: NewsContentType.IMAGE;
  value: ThroneAsset;
  isPremium?: boolean;
}

interface GalleryContentItem {
  type: NewsContentType.GALLERY;
  value: ThroneAsset[];
  isPremium?: boolean;
}

interface BannerContentItem {
  type: NewsContentType.BANNER;
  value: WebTemplateAdvertisementBanner;
  isPremium?: boolean;
}

export type NewsContentItem = TextContentItem
  | NewsDetailsContentItem
  | VideoContentItem
  | GalleryContentItem
  | ImageContentItem
  | BannerContentItem

export type ThroneContentItem = VideoContentItem | GalleryContentItem | ImageContentItem

export interface NewsDetails extends BaseNewsData, Metadata {
  topElement: ThroneContentItem;
  content: (NewsContentItem | null)[];
}

export type LocalizedNewsItem = Partial<Record<LanguageType, NewsDetails | null>>

/** NEWS SEARCH */
export enum SearchCategories {
  ALL = 'all',
  NEWS = 'news',
  VIDEO = 'video',
  PHOTO = 'photo_gallery',
}

export interface SearchParams {
  category: string;
  query: string;
}

export interface SearchConfig {
  appId: string;
  apiKey: string;
}

export interface AlgoliaSearchResultHit {
  title: string;
  urlSlug: string;
  kenticoType: EditorialContentTypes;
  kenticoCodename: string;
  publicationDate: string;
  objectID: string;
  categories: string[];
  premium: string[];
  mainImage: string; // ThroneAsset encoded into string;
}

export interface AlgoliaSearchResult {
  hits: AlgoliaSearchResultHit[];
  nbHits: number;
  nbPages: number;
  page: number;
}
