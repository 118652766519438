import { cloneDeep, isEmpty } from 'lodash';

import {
  getDynamicFields,
  getInviolaAwards,
  getInviolaCardMovements,
  getInviolaCatalogItems,
  getInviolaCustomer,
} from '@app/services/inviola/converters';
import { ConfirmRegistrationCredentials, RegistrationCredentials } from '@app/services/inviola/types/requestTypes';
import { InviolaCampaign, InviolaCountry, InviolaDynamicFields } from '@app/services/inviola/types/responseTypes';
import { InviolaAppSuccess, InviolaError, InviolaSuccessTypes } from '@app/services/inviola/types/errorTypes';
import { InviolaRegistrationSteps, InviolaResetSteps } from '@app/services/inviola/types/stepTypes';
import { InviolaActions, InviolaActionTypes } from '@app/store/actionTypes/inviolaActionTypes';
import {
  InviolaAward,
  InviolaCardMovement,
  InviolaCatalogFilters,
  InviolaCatalogItem,
  InviolaCatalogItemId,
  InviolaCategory,
  InviolaCustomer,
  InviolaShop,
} from '@app/types/inviolaTypes';

export interface InviolaState {
  syncInProgress: boolean,
  pointsSyncInProgress: boolean,
  logoutSyncInProgress: boolean,
  changePasswordInProgress: boolean,
  error?: InviolaError;
  success?: InviolaSuccessTypes;

  resetEmail?: string;
  resetStep: InviolaResetSteps;

  registrationStep: InviolaRegistrationSteps;
  registrationCustomerId?: string;
  registrationCustomer?: InviolaCustomer;
  registrationCredentials?: Partial<RegistrationCredentials>;
  confirmCredentials?: ConfirmRegistrationCredentials;

  syncSession?: InviolaStateSession;
  customerSession?: InviolaCustomerSession;
  customerRedirectPath?: string | null;
  customerIsSaved?: boolean;

  customer?: InviolaCustomer;
  campaign?: InviolaCampaign;
  dynamicFields?: InviolaDynamicFields;
  countries?: InviolaCountry[];

  card: {
    syncInProgress: boolean,
    movements: InviolaCardMovement[]
  },
  shops: {
    syncInProgress: boolean,
    stores: InviolaShop[];
    categories: InviolaCategory[];
  }
  catalog: {
    cache: number | null;
    syncInProgress: boolean;
    selectedItemId?: number;
    items: InviolaCatalogItem[];
    filters: InviolaCatalogFilters;
  }
  wishList: InviolaCatalogItemId[];
  awards?: InviolaAward[];
  withdrawnAwards?: InviolaAward[];
}

export interface InviolaStateSession {
  id: string;
  createdAt: Date;
}

export interface InviolaCustomerSession extends InviolaStateSession {
  rememberToken?: string;
  rememberMe?: boolean;
  accessedAt: Date;
}

const initialState: InviolaState = {
  syncInProgress: false,
  pointsSyncInProgress: false,
  logoutSyncInProgress: false,
  changePasswordInProgress: false,
  resetStep: InviolaResetSteps.ResetAsked,

  registrationStep: InviolaRegistrationSteps.RegistrationInit,

  wishList: [],
  shops: {
    syncInProgress: false,
    stores: [],
    categories: [],
  },
  catalog: {
    cache: null,
    syncInProgress: false,
    items: [],
    filters: {
      pageCount: 1,
      category: -1,
      minPoints: -1,
      maxPoints: -1,
    },
  },
  card: {
    syncInProgress: false,
    movements: [],
  },
};

export default (
  state: InviolaState = cloneDeep(initialState),
  action: InviolaActions,
): InviolaState => {
  const createdAt = new Date();

  switch (action.type) {
    case InviolaActionTypes.SET_CUSTOMER: {
      const { data } = action.payload.loginData;
      const rememberToken = data.token;

      return {
        ...state,

        customer: {
          ...state.customer,
          ...getInviolaCustomer(data.customer),
        },
        campaign: {
          ...state.campaign,
          ...data.campaign,
        },

        customerIsSaved: true,
        customerSession: {
          id: action.payload.loginData.sessionid,
          createdAt,
          accessedAt: createdAt,
          rememberMe: action.payload.rememberMe,
          ...!isEmpty(rememberToken) && { rememberToken },
        },

        error: initialState.error,

        resetEmail: initialState.resetEmail,
        resetStep: initialState.resetStep,
        registrationCustomer: initialState.registrationCustomer,
        registrationCustomerId: initialState.registrationCustomerId,
        registrationCredentials: initialState.registrationCredentials,
        confirmCredentials: initialState.confirmCredentials,
      };
    }
    case InviolaActionTypes.SET_CUSTOMER_SAVED:
      return {
        ...state,
        customerIsSaved: action.payload,
      };
    case InviolaActionTypes.SET_CUSTOMER_UPDATED:
      return {
        ...state,

        customerIsSaved: true,
        customer: {
          ...state.customer,
          ...getInviolaCustomer(action.payload.data.customer),
        },
      };
    case InviolaActionTypes.SET_CUSTOMER_SESSION:
      return {
        ...state,

        customerSession: {
          ...state.customerSession,
          id: action.payload,
          createdAt,
          accessedAt: createdAt,
        },
      };
    case InviolaActionTypes.SET_CUSTOMER_SESSION_ACCESSED_AT:
      return {
        ...state,

        customerSession: state.customerSession && {
          ...state.customerSession,
          accessedAt: createdAt,
        },
      };
    case InviolaActionTypes.SET_CUSTOMER_REDIRECT_PARAMS:
      return {
        ...state,

        customerRedirectPath: action.payload,
      };
    case InviolaActionTypes.RESET_CUSTOMER:
      return {
        ...state,

        customer: initialState.customer,
        campaign: initialState.campaign,
        customerSession: initialState.customerSession,

        error: initialState.error,
        success: initialState.success,

        resetEmail: initialState.resetEmail,
        resetStep: initialState.resetStep,
        registrationCustomerId: initialState.registrationCustomerId,

        registrationStep: initialState.registrationStep,
        registrationCredentials: initialState.registrationCredentials,
        registrationCustomer: initialState.registrationCustomer,
        confirmCredentials: initialState.confirmCredentials,

        card: initialState.card,
        awards: initialState.awards,
        withdrawnAwards: initialState.withdrawnAwards,
        wishList: initialState.wishList,
      };
    case InviolaActionTypes.SET_ERROR:
      return {
        ...state,

        error: action.payload,
        success: state.success,
      };
    case InviolaActionTypes.SET_SUCCESS:
      return {
        ...state,

        error: state.error,
        success: action.payload,
      };
    case InviolaActionTypes.RESET_ERROR:
      return {
        ...state,

        error: initialState.error,
        success: initialState.success,
      };
    case InviolaActionTypes.RESET_PASSWORD_ASKED:
      return {
        ...state,

        resetEmail: action.payload,
        resetStep: InviolaResetSteps.ResetConfirmation,
      };
    case InviolaActionTypes.RESET_PASSWORD_CONFIRMED:
      return {
        ...state,

        resetEmail: initialState.resetEmail,
        resetStep: InviolaResetSteps.ResetSuccess,
      };

    case InviolaActionTypes.RESET_PASSWORD_DONE:
      return {
        ...state,

        resetEmail: initialState.resetEmail,
        resetStep: initialState.resetStep,

        error: initialState.error,
        success: initialState.success,
      };
    case InviolaActionTypes.SYNC_DONE:
      return {
        ...state,

        syncSession: {
          id: action.payload.data.session,
          createdAt,
        },
      };
    case InviolaActionTypes.SET_ADDITIONAL_DATA:
      return {
        ...state,

        dynamicFields: action.payload.dynamicFieldsData
          ? getDynamicFields(
            action.payload.dynamicFieldsData.data.dynamicFields,
            action.payload.countries?.data.countries ?? state.countries,
          )
          : state.dynamicFields,

        countries: action.payload.countries?.data.countries ?? state.countries,
      };
    case InviolaActionTypes.SET_CARD_MOVEMENTS_SYNC_IN_PROGRESS:
      return {
        ...state,
        card: {
          syncInProgress: action.payload,
          movements: state.card.movements,
        },
      };
    case InviolaActionTypes.SET_CARD_MOVEMENTS:
      return {
        ...state,
        card: {
          syncInProgress: state.card.syncInProgress,
          movements: getInviolaCardMovements(action.payload.data.movements),
        },
      };
    case InviolaActionTypes.REGISTRATION_WITHOUT_CARD_CONFIRMATION:
      return {
        ...state,

        registrationStep: InviolaRegistrationSteps.RegistrationWithoutCardConfirmation,

        error: initialState.error,
      };
    case InviolaActionTypes.REGISTRATION_CARD_WITHOUT_EMAIL_CONFIRMATION:
      return {
        ...state,

        registrationStep: InviolaRegistrationSteps.RegistrationCardWithoutEmailConfirmation,

        error: initialState.error,
      };
    case InviolaActionTypes.REGISTRATION_CARD_WITHOUT_EMAIL_VALIDATED:
      return {
        ...state,

        registrationStep: InviolaRegistrationSteps.RegistrationCardWithoutEmailValidated,
        registrationCustomer: action.payload.customer,
        registrationCustomerId: action.payload.customerId,

        error: initialState.error,
        success: InviolaAppSuccess.CustomerCardIsAccepted,
      };
    case InviolaActionTypes.REGISTRATION_CARD_WITH_EMAIL_CONFIRMATION:
      return {
        ...state,

        registrationStep: InviolaRegistrationSteps.RegistrationCardWithEmailConfirmation,
        registrationCustomerId: action.payload.customerId,
        registrationCustomer: action.payload.customer,
        registrationCredentials: {
          email: action.payload.customer.email,
        },

        error: initialState.error,
        success: InviolaAppSuccess.CustomerCardIsAccepted,
      };
    case InviolaActionTypes.REGISTRATION_CARD_WITH_EMAIL_VALIDATED:
      return {
        ...state,

        registrationStep: InviolaRegistrationSteps.RegistrationCardWithEmailValidated,
        error: initialState.error,
      };
    case InviolaActionTypes.REGISTRATION_STORE:
      return {
        ...state,

        registrationCredentials: action.payload,
      };
    case InviolaActionTypes.REGISTRATION_CONFIRMATION_STORE:
      return {
        ...state,

        confirmCredentials: action.payload.confirmCredentials,
      };
    case InviolaActionTypes.REGISTRATION_CONFIRMED:
      return {
        ...state,

        confirmCredentials: initialState.confirmCredentials,
        registrationCredentials: initialState.registrationCredentials,
        registrationCustomer: initialState.registrationCustomer,
        registrationCustomerId: initialState.registrationCustomerId,
        registrationStep: InviolaRegistrationSteps.RegistrationSuccess,

        error: initialState.error,
      };
    case InviolaActionTypes.REGISTRATION_WIZARD_RESET:
      return {
        ...state,

        registrationStep: initialState.registrationStep,
      };
    case InviolaActionTypes.SET_SHOPS:

      return {
        ...state,

        shops: {
          syncInProgress: false,
          stores: action.payload,
          categories: state.shops.categories,
        },
      };
    case InviolaActionTypes.SET_SHOPS_SYNC_IN_PROGRESS:

      return {
        ...state,

        shops: {
          syncInProgress: action.payload,
          stores: state.shops.stores,
          categories: state.shops.categories,
        },
      };
    case InviolaActionTypes.SET_SHOPS_CATEGORIES:

      return {
        ...state,
        shops: {
          syncInProgress: state.shops.syncInProgress,
          stores: state.shops.stores,
          categories: action.payload,
        },
      };
    case InviolaActionTypes.CATALOG_SYNC_IN_PROGRESS:
      return {
        ...state,
        catalog: {
          cache: action.payload ? null : state.catalog.cache,
          items: action.payload ? [] : state.catalog.items,
          selectedItemId: state.catalog.selectedItemId,
          syncInProgress: action.payload,
          filters: initialState.catalog.filters,
        },
      };
    case InviolaActionTypes.SET_CATALOG_ITEMS:
      return {
        ...state,
        catalog: {
          cache: new Date().getTime(),
          items: getInviolaCatalogItems(action.payload.prizes),
          selectedItemId: state.catalog.selectedItemId,
          syncInProgress: state.catalog.syncInProgress,
          filters: initialState.catalog.filters,
        },
      };
    case InviolaActionTypes.SET_CATALOG_SELECTED_ITEM_ID:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedItemId: action.payload,
        },
      };
    case InviolaActionTypes.SET_CATALOG_FILTER_PAGE:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          filters: {
            ...state.catalog.filters,
            pageCount: action.payload,
          },
        },
      };
    case InviolaActionTypes.SET_CATALOG_FILTER_CATEGORY:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          filters: {
            ...state.catalog.filters,
            category: action.payload,
          },
        },
      };
    case InviolaActionTypes.SET_CATALOG_FILTER_MIN_POINTS:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          filters: {
            ...state.catalog.filters,
            minPoints: action.payload,
          },
        },
      };
    case InviolaActionTypes.SET_CATALOG_FILTER_MAX_POINTS:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          filters: {
            ...state.catalog.filters,
            maxPoints: action.payload,
          },
        },
      };
    case InviolaActionTypes.SET_ITEMS_TO_WISH_LIST:
      return {
        ...state,
        wishList: action.payload,
      };
    case InviolaActionTypes.ADD_ITEM_TO_WISH_LIST:
      return {
        ...state,
        wishList: [...state.wishList, action.payload],
      };
    case InviolaActionTypes.REMOVE_ITEM_FROM_WISH_LIST:
      return {
        ...state,
        wishList: state.wishList.filter((item) => item.id !== action.payload.id),
      };
    case InviolaActionTypes.POINTS_SYNC_IN_PROGRESS:
      return {
        ...state,
        pointsSyncInProgress: action.payload,
      };
    case InviolaActionTypes.SET_CUSTOMER_POINTS:
      return {
        ...state,
        customer: state.customer && {
          ...state.customer,
          balancePoints: action.payload,
        },
      };
    case InviolaActionTypes.SET_AWARDS:
      return {
        ...state,

        awards: getInviolaAwards(action.payload),
      };
    case InviolaActionTypes.REQUEST_AWARD_DONE:
      return {
        ...state,

        success: initialState.success,
      };
    case InviolaActionTypes.SET_WITHDRAWN_AWARDS:
      return {
        ...state,

        withdrawnAwards: getInviolaAwards(action.payload),
      };
    case InviolaActionTypes.SET_SYNC_IN_PROGRESS:
      return {
        ...state,

        syncInProgress: action.payload,
      };
    case InviolaActionTypes.SET_LOGOUT_SYNC_IN_PROGRESS:
      return {
        ...state,

        logoutSyncInProgress: action.payload,
      };
    case InviolaActionTypes.SET_CHANGE_PASSWORD_IN_PROGRESS:
      return {
        ...state,

        changePasswordInProgress: action.payload,
      };
    default:
      return state;
  }
};
