import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { Modal } from '@app/components';

import { ThroneAsset } from '@app/types/configurationTypes';
import { NewsPremium } from '@app/components/News/NewsItemInfo';
import { useIsConnected } from '@app/components/Inviola/InviolaHooks';
import { useLanguage, useCurrentRoute } from '@app/components/Hooks';

import { PremiumAccessNotification } from '@app/components/Inviola';

import ThronImage from '../Image/ThronImage';
import GalleryCarousel from './GalleryCarousel';
import { ImageElement } from '../Image/LazyImage';

interface GalleryPreviewProps {
  photos: ThroneAsset[];
  isPremium?: boolean;
}

const GalleryPreview = ({ photos, isPremium = false }: GalleryPreviewProps): React.ReactElement => {
  const [isGalleryViewModalOpened, setIsGalleryViewModalOpened] = useState(false);
  const [isPremiumModalOpened, setIsPremiumModalOpened] = useState(false);

  const currentRoute = useCurrentRoute();
  const isConnected = useIsConnected();
  const language = useLanguage();

  const mainImage = photos?.[0];

  const onClosePremiumModal = (): void => setIsPremiumModalOpened(false);
  const onGalleryViewClick = (event: React.MouseEvent): void => {
    if (isPremium && !isConnected) {
      setIsPremiumModalOpened(true);
      event.preventDefault(); // STOP:REDIRECT:TO:NEWS:DETAILS:PAGE
    } else {
      setIsGalleryViewModalOpened(true);
    }
  };

  if (photos.length === 0) return <div />;
  return (
    <>
      <GalleryPreviewSection onClick={onGalleryViewClick}>
        <MainImageContainer>
          <ThronImage
            thronId={mainImage.id}
            alt={mainImage?.description ?? ''}
            scaleMode="none"
          />
        </MainImageContainer>
        <InfoSection>
          <ImageContainer>
            <ThronImage
              thronId={photos?.[1]?.id}
              alt={photos?.[1]?.description}
              scaleMode="none"
            />
          </ImageContainer>
          <Info count={photos?.length}>
            <ImageContainer>
              <ThronImage
                thronId={photos?.[2]?.id}
                alt={photos?.[2]?.description}
                scaleMode="none"
              />
            </ImageContainer>
          </Info>
        </InfoSection>
        {isPremium && (
          <GalleryPremiumLabel>
            <NewsPremium size="small">
              <FormattedMessage id="premium.tag" />
            </NewsPremium>
          </GalleryPremiumLabel>
        )}
      </GalleryPreviewSection>
      <Modal
        open={isGalleryViewModalOpened}
        onClose={(): void => setIsGalleryViewModalOpened(false)}
      >
        <GalleryModalContainer>
          <GalleryCarousel slides={photos} />
        </GalleryModalContainer>
      </Modal>
      <Modal
        open={isPremiumModalOpened}
        onClose={onClosePremiumModal}
        contentFullWidth={false}
        controls={false}
      >
        <PremiumAccessNotification
          onLogin={onClosePremiumModal}
          onCancel={onClosePremiumModal}
          redirectPath={currentRoute[language]}
        />
      </Modal>
    </>
  );
};

export default GalleryPreview;

const ImageContainer = styled.div`
  height: 100%;
  overflow: hidden;

  ${ImageElement} {
    width: 100%;
    position: relative;
    top: 0px;
    z-index: 0;
    min-height: 1px;
    min-width: 1px;
    transition: transform 2000ms ease-out;
    pointer-events: none;
  }
`;

const MainImageContainer = styled(ImageContainer)`
  ${ImageElement} {
    height: 100%;
    left: 50%;
    transform: translateX(-50%);

    object-fit: cover;
    object-position: center;
  }
`;

const GalleryPreviewSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  height: calc(100vw * 9/16);

  @media ${Devices.responsiveContent} {
    height: 505px;
  }

  & > * {
    flex: 0 0 50%;
  }

  &:hover ${ImageElement} {
    transform: scale(1.1,1.1);
  }

  &:hover ${MainImageContainer} ${ImageElement} {
    transform: translateX(-50%) scale(1.1,1.1);
  }
`;

const GalleryPremiumLabel = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
`;

const InfoSection = styled.div`
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > * {
    flex: 0 0 50%;
  }
`;

const Info = styled.div<{ count: number }>`
  position: relative;
  margin-top: 5px;
  overflow: hidden;

  &::after {
    content: "+${({ count }): number => count}";
    position: absolute;
    top: -5px;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20px;
    font-family: ${(props): string => props.theme.fontFamily.commutersSans};

    @media ${Devices.tablet} {
      font-size: 26px;
    }

    @media ${Devices.responsiveContent} {
      font-size: 36px;
    }
  }

`;

const GalleryModalContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
