import { useEffect, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MountContext } from '@app/ReactContext';

import { Live, SeoData } from '@app/types/configurationTypes';
import { useLanguage } from '@app/components/Hooks';
import AppState from '@app/types/appStateTypes';
import {
  getWebTemplateDataById,
  resetWebTemplateData,
  resetWebTemplateError,
} from '@app/store/actions/webTemplateActions';
import { getConfiguration } from '@app/store/actions/configurationActions';
import { LocalizedWebTemplateData, WebTemplateData } from '@app/types/webTemplateTypes';
import { setCurrentRoute } from '@app/store/actions/currentRouteActions';
import AppRoutes from '@app/constants/routesConstants';

export function useHomeMenuId(): string {
  const language = useLanguage();

  return useSelector<AppState, string>(
    (state) => state.configuration[language]?.home?.id ?? '',
    shallowEqual,
  );
}

export function useHomeDownload(): void {
  const { isInitialMount } = useContext(MountContext);
  const language = useLanguage();
  const dispatch = useDispatch();

  const homeMenuId = useHomeMenuId();

  const hasTemplateItems = useSelector<AppState, boolean>(
    (state) => !!state.webTemplate.templates[homeMenuId ?? '']?.[language],
    shallowEqual,
  );

  useEffect(() => {
    if (!hasTemplateItems && homeMenuId) {
      dispatch(resetWebTemplateData());
      dispatch(getWebTemplateDataById({
        id: homeMenuId, language,
      }));
    }
    // If first load (from SSR) - do nothing. In other case load config again
    // TODO:UPDATE:AFTER:MOVING:CMS_HOME:TO:SEPARATE:OBJECT
    if (!isInitialMount) dispatch(getConfiguration());

    return (): void => {
      dispatch(resetWebTemplateError());
      dispatch(resetWebTemplateData());
    };
  }, [homeMenuId, language]);
}

export function useHomeMultiLangUrl(): void {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(setCurrentRoute({
      pathId: AppRoutes.Home.path,
      params,
    }));
  });
}

export function useHomeData(): WebTemplateData[] | null {
  const language = useLanguage();

  const homeMenuId = useHomeMenuId();

  const localizedWebTemplateData = useSelector<AppState, LocalizedWebTemplateData | null>(
    (state) => state.webTemplate.templates[homeMenuId ?? ''] ?? null,
    shallowEqual,
  );

  return localizedWebTemplateData?.[language] ?? null;
}

export const useSeoData = (): SeoData | null => {
  const language = useLanguage();

  return useSelector<AppState, SeoData | null>(
    (state) => state.configuration[language]?.home?.seo?.seo ?? null,
    shallowEqual,
  );
};

export const useHomeTopElement = (): WebTemplateData | undefined => {
  const language = useLanguage();
  return useSelector<AppState, WebTemplateData | undefined>(
    (state) => state.configuration[language]?.home?.topElement,
    shallowEqual,
  );
};

export const useLiveElement = (): Live => {
  const language = useLanguage();
  return useSelector<AppState, Live>(
    (state) => state.configuration[language]?.live ?? null,
    shallowEqual,
  );
};
