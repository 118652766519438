import React from 'react';

import { WebHomeElementBackground, WebTemplateHomeElement } from '@app/types/webTemplateTypes';
import { useHomeDataList, useHomeDataListDownload } from '@app/components/Home/HomeHooks';
import { EditorialContentTypes } from '@app/types/newsTypes';
import styled from 'styled-components';
import { NewItem } from '@app/components/News';
import { Devices } from '@app/styles';
import { HomeTitleLink } from '@app/components/Home/components';

interface WebHomeGalleriesElementProps {
  data: WebTemplateHomeElement;
}

const WebHomeGalleriesElement = ({ data }: WebHomeGalleriesElementProps): React.ReactElement | null => {
  useHomeDataListDownload(EditorialContentTypes.PHOTO, data.category, 3);
  const dataList = useHomeDataList(EditorialContentTypes.PHOTO, data.category);
  const { link, title } = data;

  return (
    <Container background={data.background}>
      <HomeTitleLink title={title} link={link} Component={Title} background={data.background} showSeeMore />
      <GridWrapper>
        <Grid>
          {
          dataList?.map((item) => (
            <ItemWrapper key={item.id}>
              <Item>
                <NewItem data={item} />
              </Item>
            </ItemWrapper>
          ))
          }
        </Grid>
      </GridWrapper>
    </Container>
  );
};

const Container = styled.div<{ background: WebHomeElementBackground }>`
  background-color: ${({ background, theme }): string => (background === WebHomeElementBackground.Purple ? theme.color.purple : 'transparent')};
  background-image: ${({ background }): string => (background === WebHomeElementBackground.Purple ? 'url("/images/block-background.webp")' : 'transparent')};
  background-position: center center;
  background-size: cover;
  padding: 15px 20px 45px;

  @media ${Devices.desktopSmall} {
    padding: 30px 70px 60px;
  }
`;

const Title = styled.h1<{ background?: WebHomeElementBackground }>`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 18px;
  font-weight: bold;
  line-height: 2.35;
  letter-spacing: -0.01px;
  color: ${({ background, theme }): string => (background === WebHomeElementBackground.Transparent ? theme.color.purple : '#ffffff')};
  text-transform: uppercase;
  padding-left: 10px;
`;

const GridWrapper = styled.div`
  margin-top: 15px;
  overflow-x: auto;

  @media ${Devices.desktopSmall} {
    overflow: inherit;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const ItemWrapper = styled.div`
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }

  @media ${Devices.desktopSmall} {
    margin-right: 22px;
  }

`;

const Item = styled.div`
  width: 300px;
  height: 170px;

  @media ${Devices.desktopSmall} {
    width: 365px;
    height: 205px;
  }
`;

export default WebHomeGalleriesElement;
