import React from 'react';
import { FormattedMessage } from 'react-intl';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styled from 'styled-components';

import { BaseNewsItem } from '@app/types/newsTypes';
import { NewItem } from '@app/components/News';

import AppRoutes from '@app/constants/routesConstants';
import { Devices, DevicesSize } from '@app/styles';
import { LocalizedLink } from '@app/components/Localization';
import { CustomLeftArrow, CustomRightArrow } from '@app/components/Carousel/CarouselArrows';
import { useMaxWidthOfPartialVisibility } from '@app/components/Carousel/CarouselHooks';

const getConfiguration = (): ResponsiveType => ({
  desktop: {
    breakpoint: { max: Infinity, min: DevicesSize.tabletMedium },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: DevicesSize.tabletMedium, min: DevicesSize.mobileLarge },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 20,
  },
  mobile: {
    breakpoint: { max: DevicesSize.mobileLarge, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 20,
  },
});

type CarouselLatestNews = React.FC<{
  items: BaseNewsItem[];
  headerLabelId: string;
  headerLinksParams: Record<string, string>;
}>

const CarouselLatestNews: CarouselLatestNews = ({ items, headerLabelId, headerLinksParams }) => {
  const latestNewsConfiguration = getConfiguration();
  const maxWidthOfPartialVisibility = useMaxWidthOfPartialVisibility(latestNewsConfiguration);

  return (
    <>
      <CarouselTitle
        pathKey={AppRoutes.CommonPage.path}
        pathParams={headerLinksParams}
      >
        <FormattedMessage id={headerLabelId} />
      </CarouselTitle>
      <Carousel
        swipeable
        partialVisible
        responsive={latestNewsConfiguration}
        infinite={false}
        keyBoardControl={false}
        ssr
        customLeftArrow={<CustomLeftArrow mediaQueryMinWidth={maxWidthOfPartialVisibility} />}
        customRightArrow={<CustomRightArrow mediaQueryMinWidth={maxWidthOfPartialVisibility} />}
      >
        {
          items.map((item) => (
            <CarouselItemContainer key={item.id} mediaQueryMinWidth={maxWidthOfPartialVisibility}>
              <NewItem data={item} />
            </CarouselItemContainer>
          ))
        }
      </Carousel>
    </>
  );
};

const CarouselItemContainer = styled.div<{mediaQueryMinWidth: number}>`
  margin: 0 8px;
  height: 100%;

  @media ${({ mediaQueryMinWidth }): string => `(min-width: ${mediaQueryMinWidth}px)`} {
    margin: 0 10px;
  }
`;

export const CarouselTitle = styled(LocalizedLink)`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.29px;
  text-transform: uppercase;
  margin: 30px 8px 10px;
  color: ${(props): string => props.theme.color.purple};
  display: block;

  @media ${Devices.tablet} {
    margin: 30px 10px 20px;
  }
`;

export default CarouselLatestNews;
