import React, { useEffect } from 'react';
import { ResponsiveType } from 'react-multi-carousel';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { DevicesSize } from '@app/styles';
import AppState from '@app/types/appStateTypes';
import { getAwards } from '@app/store/actions/inviolaActions';
import { InviolaAward } from '@app/types/inviolaTypes';
import { getWithdrawnAwards } from '@app/store/actions/inviolaActions/catalog';
import {
  useCatalogItems, useWishListItems, useCatalogItemsCacheDate,
} from '@app/pages/Inviola/CatalogPage/CatalogPageHooks';
import { useIsConnected } from '@app/components/Inviola/InviolaHooks';
import { AwardsSection, MyWishesSection, WithdrawnAwardsSection } from './components';

export const useAwardsDownload = (): void => {
  const dispatch = useDispatch();
  const isConnected = useIsConnected();

  useEffect(() => {
    if (isConnected) dispatch(getAwards());
  }, []);
};

export const useWithdrawnAwardsDownload = (): void => {
  const dispatch = useDispatch();
  const isConnected = useIsConnected();

  useEffect(() => {
    if (isConnected) dispatch(getWithdrawnAwards());
  }, []);
};

export const useAwards = (): InviolaAward[] | undefined => (
  useSelector((state: AppState) => state.inviola.awards, shallowEqual)
);

export const useAward = (id?: number): InviolaAward | undefined => (
  useSelector((state: AppState) => state.inviola.awards?.find((award) => award.id === id), shallowEqual)
);

export const useWithdrawnAwards = (): InviolaAward[] | undefined => (
  useSelector((state: AppState) => state.inviola.withdrawnAwards, shallowEqual)
);

export const useIsAwardsDownloaded = (): boolean => {
  const awards = useAwards();
  const withdrawnAwards = useWithdrawnAwards();
  const wishList = useWishListItems();

  const catalogItems = useCatalogItems();
  const catalogItemsCache = useCatalogItemsCacheDate();

  return Array.isArray(awards)
    && Array.isArray(withdrawnAwards)
    && (Array.isArray(catalogItems) && !!catalogItemsCache)
    && Array.isArray(wishList);
};

interface AwardsSectionsInterface {
  Section: () => React.ReactElement;
  priority: number;
  hasItems: boolean;
}

export const useAwardsSections = (): AwardsSectionsInterface[] => {
  const awards = useAwards();
  const withdrawnAwards = useWithdrawnAwards();
  const catalogItems = useCatalogItems();
  const wishList = useWishListItems();
  const wishListToDisplay = catalogItems
    ?.filter((item) => wishList.find((wishItem) => wishItem.id === item.id)) ?? [];

  return [
    {
      Section: AwardsSection,
      priority: 1,
      hasItems: Boolean(awards?.length),
    },
    {
      Section: WithdrawnAwardsSection,
      priority: 2,
      hasItems: Boolean(withdrawnAwards?.length),
    },
    {
      Section: MyWishesSection,
      priority: 3,
      hasItems: Boolean(catalogItems?.length) && Boolean(wishListToDisplay?.length),
    },
  ].sort((a, b) => {
    switch (true) {
      case a.hasItems && b.hasItems: return a.priority - b.priority;
      case a.hasItems && !b.hasItems: return -1;
      case !a.hasItems && b.hasItems: return 1;
      default: return a.priority < b.priority ? -1 : 1;
    }
  });
};

export const useCarouselConfiguration = (): ResponsiveType => ({
  desktop: {
    breakpoint: { max: Infinity, min: DevicesSize.tabletMedium },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: DevicesSize.tabletMedium, min: DevicesSize.mobileLarge },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: DevicesSize.mobileLarge, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
});
