export const setCookie = (name: string, value: string): void => {
  const date = new Date();
  const expireTimeInMilliseconds = 365 * 24 * 60 * 60 * 1000; // 12 Month

  date.setTime(date.getTime() + expireTimeInMilliseconds);
  document.cookie = `${name}=${value}; expires=${date.toUTCString()};path=/`;
};

export const getCookie = (name: string): string | null => {
  const nameQuery = `${name}=`;
  const cookieArray = document.cookie.split(';');

  for (let i = 0; i < cookieArray.length; i += 1) {
    let cookie = cookieArray[i];

    while (cookie.startsWith(' ')) cookie = cookie.substring(1, cookie.length);
    if (cookie.startsWith(nameQuery)) return cookie.substring(nameQuery.length, cookie.length);
  }
  return null;
};

export const setCookieAcceptedStatus = (status: boolean): void => setCookie('cookie_accepted', `${status}`);

export const getCookieAcceptedStatus = (): boolean | null => {
  switch (getCookie('cookie_accepted')) {
    case 'true': return true;
    case 'false': return false;
    default: return null;
  }
};

export const setCookieClosedStatus = (status: boolean): void => window.sessionStorage.setItem('cookie_closed', `${status}`);

export const getCookieClosedStatus = (): boolean => window.sessionStorage.getItem('cookie_closed') === 'true';

/**
 * Google DoubleClick ads personalization ON/OFF
 * */
export const setGoogleAdsPersonalization = (active: boolean): void => {
  /* eslint-disable  */
  // @ts-ignore
  window.gtag && window.gtag('set', 'allow_ad_personalization_signals', active);
  /* eslint-enable  */
};

/** Check and Install cookie allowed resources directly on App start
 * */
export const initCookie = (): void => {
  if (getCookieAcceptedStatus()) {
    setGoogleAdsPersonalization(true);
  } else {
    setGoogleAdsPersonalization(false);
  }
};
