import {
  BaseMenuItem,
  CategoryMenu, LocalizedBaseMenuItem,
  LocalizedMenuItem,
  LocalizedSubMenuItem,
  MenuItem,
  MenuItemType,
  MenuItemTypes, RelatedMenuItem,
  SubMenuId,
  SubMenuItem,
} from '@app/types/configurationTypes';
import { AppLanguages } from '@app/constants/localizationConstants';
import { SubMenuIdToDetailsPageType, SubMenuIdToPageType } from '@app/constants/commonPageConstants';

import { AppState } from '@app/types/appStateTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { PageType } from '@app/types/commonPageTypes';
import { NavigationLinkParams, RouteParams } from '@app/types/routerTypes';
import AppRoutes from '@app/constants/routesConstants';

export const isMenuItemVisible = (item: MenuItemType, isConnected: boolean): boolean => (
  item.isVisible && (
    item.isPrivate === null ? true : ((item.isPrivate && isConnected) || (!item.isPrivate && !isConnected))
  )
);

export const isBaseMenuItem = (menuItem: MenuItemType)
  : menuItem is BaseMenuItem => (
  menuItem.type === MenuItemTypes.BaseMenuItem
);

type FindMenuItemBySlug = (
  menuItems: MenuItem[],
  slug: string,
) => MenuItem | undefined

export const findMenuItemBySlug: FindMenuItemBySlug = (menuItems, slug) => (
  menuItems.find((item) => item.data?.url === decodeURIComponent(slug))
);

type FindSubMenuItemBySlug = (
  menuItems: SubMenuItem[],
  slug: string,
) => SubMenuItem | undefined

export const findSubMenuItemBySlug: FindSubMenuItemBySlug = (menuItems, slug) => (
  menuItems.find((item) => isBaseMenuItem(item.data) && item.data?.url === decodeURIComponent(slug))
);

type FindCategoryMenuItemBySlug = (
  menuItems: CategoryMenu,
  slug: string,
) => BaseMenuItem | undefined

export const findCategoryMenuItemBySlug: FindCategoryMenuItemBySlug = (menuItems, slug) => (
  menuItems.find((item) => isBaseMenuItem(item) && item?.url === decodeURIComponent(slug))
) as BaseMenuItem;

type FindMenuItemById = (
  menuItems: MenuItem[],
  id: string,
) => MenuItem | undefined

export const findMenuItemById: FindMenuItemById = (menuItems, id) => (
  menuItems.find((item) => item.data?.id === id)
);

type FindSubMenuItemById = (
  menuItems: SubMenuItem[],
  id: string,
) => SubMenuItem | undefined

export const findSubMenuItemById: FindSubMenuItemById = (menuItems, id) => (
  menuItems.find((item) => item.data?.id === id)
);

type FindCategoryMenuItemById = (
  menuItems: CategoryMenu,
  id: string,
) => BaseMenuItem | undefined

export const findCategoryMenuItemById: FindCategoryMenuItemById = (menuItems, id) => (
  menuItems?.find((item) => isBaseMenuItem(item) && item.id === decodeURIComponent(id))
) as BaseMenuItem;

type GetTopLevelMenu = (
  state: AppState,
  topLevel: string,
  language: LanguageType,
) => LocalizedMenuItem

export const getTopLevelMenuItem: GetTopLevelMenu = (state, topLevel, language): LocalizedMenuItem => {
  const { configuration } = state;
  const menuItems = Object.values(configuration[language]?.menu ?? {});
  const topLevelMenuId = findMenuItemBySlug(menuItems, topLevel)?.data?.id ?? '';

  return AppLanguages.reduce((acc, language) => {
    const localizedMenuItem = { ...acc };
    const menu = configuration[language]?.menu;
    const menuItem = findMenuItemById(Object.values(menu ?? {}), topLevelMenuId);

    if (menuItem) {
      localizedMenuItem[language] = menuItem;
    }

    return localizedMenuItem;
  }, {} as LocalizedMenuItem);
};

type GetSecondLevelMenu = (
  state: AppState,
  topLevel: string,
  secondLevel: string,
  language: LanguageType,
) => LocalizedSubMenuItem | undefined

export const getSecondLevelMenuItem: GetSecondLevelMenu = (state, topLevel, secondLevel, language) => {
  const topLevelMenu = getTopLevelMenuItem(state, topLevel, language);

  if (!topLevelMenu[language]?.navigation) return;

  const navigation = Object.values(topLevelMenu[language]?.navigation ?? {});
  const secondLevelMenuId = findSubMenuItemBySlug(navigation, secondLevel)?.data?.id ?? '';

  return AppLanguages.reduce((acc, language) => {
    const localizedSubMenuItem = { ...acc };
    const topLevelMenuItems = Object.values(topLevelMenu?.[language]?.navigation ?? {});
    const subMenuItem = findSubMenuItemById(topLevelMenuItems, secondLevelMenuId) ?? null;

    if (subMenuItem) {
      localizedSubMenuItem[language] = subMenuItem;
    }

    return localizedSubMenuItem;
  }, {} as LocalizedSubMenuItem);
};

type GetThirdLevelMenu = (
  state: AppState,
  topLevel: string,
  secondLevel: string,
  thirdLevel: string,
  language: LanguageType,
) => LocalizedBaseMenuItem | undefined

export const getThirdLevelMenuItem: GetThirdLevelMenu = (state, topLevel, secondLevel, thirdLevel = '', language) => {
  const secondLevelMenu = getSecondLevelMenuItem(state, topLevel, secondLevel, language);
  if (!secondLevelMenu?.[language]?.categories?.length) return;

  const thirdLevelMenuId = findCategoryMenuItemBySlug(secondLevelMenu[language].categories, thirdLevel)?.id ?? '';

  return AppLanguages.reduce((acc, language) => {
    const localizedSubMenuItem = { ...acc };
    const thirdMenuItem = findCategoryMenuItemById(secondLevelMenu?.[language]?.categories, thirdLevelMenuId) ?? null;

    if (thirdMenuItem) {
      localizedSubMenuItem[language] = thirdMenuItem;
    }

    return localizedSubMenuItem;
  }, {} as LocalizedBaseMenuItem);
};

type GetLastLevelMenuId = (params: {
  state: AppState;
  language: LanguageType;
  params: RouteParams;
}) => string | null

export const getLastLevelMenuId: GetLastLevelMenuId = ({ state, language, params }) => {
  const { topLevel = '', secondLevel = '', thirdLevel = '' } = params;
  const topLevelMenu = getTopLevelMenuItem(state, topLevel, language)[language];
  const secondLevelMenu = getSecondLevelMenuItem(state, topLevel, secondLevel, language)?.[language];
  const thirdLevelMenu = getThirdLevelMenuItem(state, topLevel, secondLevel, thirdLevel, language)?.[language];

  return thirdLevelMenu?.id ?? secondLevelMenu?.data?.id ?? topLevelMenu?.data?.id ?? null;
};

export const getPageType = (secondLevelMenu?: SubMenuItem, forthLevel?: string): PageType => {
  if (forthLevel) {
    return SubMenuIdToDetailsPageType[secondLevelMenu?.data?.id as SubMenuId] || PageType.Template;
  }

  return SubMenuIdToPageType[secondLevelMenu?.data?.id as SubMenuId] || PageType.Template;
};

export const getMenuLinkProps = (
  topMenuItem: MenuItem, secondMenuItem?: SubMenuItem, thirdMenuItem?: MenuItemType,
): Record<string, string> => {
  const secondLevelMenu = secondMenuItem ?? Object.values(topMenuItem.navigation)?.[0];
  const thirdLevelMenu = thirdMenuItem ?? secondLevelMenu?.categories?.[0];
  return {
    topLevel: topMenuItem.data?.url ?? '',
    secondLevel: secondLevelMenu?.data?.url ?? '',
    thirdLevel: thirdLevelMenu?.url ?? '',
  };
};

interface FindAndFormatSubMenuLinkPropsFunc {
  (menu: MenuItem[], urlId: string | null, urlSlug: string): NavigationLinkParams;
}

export const findAndFormatMenuLinkProps: FindAndFormatSubMenuLinkPropsFunc = (menu, urlId, urlSlug) => {
  let menuLinks: Record<string, string> | null = null;
  menu.forEach((topMenuItem) => {
    if (!menuLinks) {
      if (topMenuItem.data?.id === urlId || (!urlId && topMenuItem.data?.url === urlSlug)) {
        menuLinks = getMenuLinkProps(topMenuItem);
      } else {
        Object.values(topMenuItem.navigation).forEach((secondMenuItem) => {
          if (secondMenuItem?.data?.id === urlId || (!urlId && secondMenuItem?.data?.url === urlSlug)) {
            menuLinks = getMenuLinkProps(topMenuItem, secondMenuItem);
          } else {
            secondMenuItem.categories.forEach((thirdMenuItem) => {
              if (thirdMenuItem.id === urlId || (!urlId && thirdMenuItem.url === urlSlug)) {
                menuLinks = getMenuLinkProps(topMenuItem, secondMenuItem, thirdMenuItem);
              }
            });
          }
        });
      }
    }
  });

  return {
    pathKey: AppRoutes.CommonPage.path,
    pathParams: {
      ...(menuLinks ?? {
        topLevel: '',
        secondLevel: '',
        thirdLevel: '',
      }),
      forthLevel: '',
      fifthLevel: '',
    },
  };
};

interface FormatRelatedItemPropsFunc {
  (item: RelatedMenuItem): NavigationLinkParams;
}

export const formatRelatedItemLinkProps: FormatRelatedItemPropsFunc = (item) => ({
  pathKey: AppRoutes.CommonPage.path,
  pathParams: {
    topLevel: item.topLevel?.url ?? '',
    secondLevel: item.secondLevel?.url ?? '',
    thirdLevel: item.thirdLevel?.url ?? '',
    categoryName: item.thirdLevel?.url ?? '',
    forthLevel: '',
    fifthLevel: '',
  },
});

interface FormatRelatedItemWithSeasonPropsFunc {
  (item: RelatedMenuItem, seasonId: string, seasonUrlSlug: string): NavigationLinkParams;
}

export const formatRelatedItemWithSeasonLinkProps: FormatRelatedItemWithSeasonPropsFunc = (
  item, seasonId, seasonUrlSlug: string,
) => ({
  pathKey: AppRoutes.CommonPage.path,
  pathParams: {
    topLevel: item.topLevel?.url ?? '',
    secondLevel: item.secondLevel?.url ?? '',
    thirdLevel: item.thirdLevel?.url ?? '',
    categoryName: item.thirdLevel?.url ?? '',
    seasonUrlSlug,
    seasonId,
  },
});
