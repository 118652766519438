import React from 'react';

import { ContainerMedium } from '@app/components/WebTemplates/components';
import { SocialSharing } from '@app/components';
import styled from 'styled-components';

const WebShareElement = (): React.ReactElement | null => (
  <ContainerMedium>
    <SocialSharingContainer>
      <SocialSharing url={window.location.href} onlyIcons />
    </SocialSharingContainer>
  </ContainerMedium>
);

const SocialSharingContainer = styled.section`
  padding-top: 40px;
`;

export default WebShareElement;
