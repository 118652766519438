import React, { useState } from 'react';
import styled from 'styled-components';

import 'react-multi-carousel/lib/styles.css';
import { Swipeable } from 'react-swipeable';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEvent } from 'react-use';
import { ImageWidthMap } from '@app/types/imageTypes';

export interface CarouselItem {
  ItemComponent: React.FunctionComponent<{
    data: unknown;
    imageWidthMap?: ImageWidthMap;
    shiftInfo?: boolean;
    current?: boolean;
  }>;
  item: unknown;
}

interface CarouselItemsProps {
  items: CarouselItem[],
  imageWidthMap?: ImageWidthMap;
  shiftInfo?: boolean;

  withDots?: boolean;
  arrowTransparent?: boolean;
  withSlidesCount?: boolean;
  withFullWidthSlides?: boolean;
}

export const CarouselBase = ({
  items,
  imageWidthMap,
  shiftInfo,

  withDots: showDots = true,
  arrowTransparent = false,
  withFullWidthSlides = true,
  withSlidesCount: showSlidesCount,
}: CarouselItemsProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const selectPreviousImage = (): void => setSelectedItemIndex(selectedItemIndex - 1);
  const selectNextImage = (): void => setSelectedItemIndex(selectedItemIndex + 1);

  const canSelectPreviousItem = !!selectedItemIndex;
  const canSelectNextItem = selectedItemIndex + 1 < items.length;

  const handlers = {
    onSwipedLeft: (): false | void => canSelectNextItem && selectNextImage(),
    onSwipedRight: (): false | void => canSelectPreviousItem && selectPreviousImage(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  };

  const handleKeyDown = ({ keyCode }: { keyCode: number }): void => {
    switch (keyCode) {
      case 37:
        canSelectPreviousItem && selectPreviousImage();
        break;
      case 39:
        canSelectNextItem && selectNextImage();
        break;
      default:
        break;
    }
  };
  useEvent('keydown', handleKeyDown);

  const arrow = arrowTransparent ? '/images/gallery-arrow-transparent.svg' : '/images/gallery-arrow.svg';

  return (
    <GlobalWrapper>
      <ControlLeft isVisible={canSelectPreviousItem} onClick={selectPreviousImage}>
        <img src={arrow} alt={formatMessage({ id: 'gallery.swipePhotoLeft' })} />
      </ControlLeft>
      <ControlRight isVisible={canSelectNextItem} onClick={selectNextImage}>
        <img src={arrow} alt={formatMessage({ id: 'gallery.swipePhotoRight' })} />
      </ControlRight>
      <CarouselSection withFullWidthSlides={withFullWidthSlides}>
        <Swipeable {...handlers}>
          <Carousel selectedItemIndex={selectedItemIndex}>
            {
              items.map(({ item, ItemComponent }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Slide key={index}>
                  <ItemComponent
                    data={item}
                    imageWidthMap={imageWidthMap}
                    shiftInfo={shiftInfo}
                    current={selectedItemIndex === index}
                  />
                </Slide>
              ))
            }
          </Carousel>
        </Swipeable>
        { showSlidesCount && (
        <SlidesCount>
          <FormattedMessage
            id="gallery.photo"
            values={{
              number: selectedItemIndex + 1,
              count: items.length,
            }}
          />
        </SlidesCount>
        ) }
      </CarouselSection>
      { showDots && (
      <SlidesDots>
        {
          items.length > 1 && items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Dot key={index} active={selectedItemIndex === index} />
          ))
        }
      </SlidesDots>
      )}
    </GlobalWrapper>
  );
};

const GlobalWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ControlRight = styled.div<{ isVisible: boolean }>`
  position: absolute;
  display: ${({ isVisible }): string => (isVisible ? 'block' : 'none')};
  right: 20px;
  top: calc(50% - 8px);
  z-index: 1;
  cursor: pointer;
`;

const ControlLeft = styled(ControlRight)`
  left: 20px;
  right: unset;
  transform: rotate(180deg);
`;

const CarouselSection = styled.section<{ withFullWidthSlides: boolean}>`
  position: relative;
  overflow: hidden;
  max-width: ${({ withFullWidthSlides }): string => (withFullWidthSlides ? '100%' : 'var(--gallery-photo-max-width)')};
  flex: 0 0 100%;
`;

const Carousel = styled.ul<{ selectedItemIndex: number }>`
  transform: ${({ selectedItemIndex }): string => `translateX(-${selectedItemIndex * 100}%)`};
  transition: transform 250ms ease-out 0s;
  white-space: nowrap;
  width: 100%;

  &::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
`;

const Slide = styled.li`
  display: inline-block;
  vertical-align: middle;
  width: 100%;
`;

const SlidesCount = styled.div`
  position: absolute;
  height: 25px;
  opacity: 0.8;
  background-color: black;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: -0.01px;
  color: #ffffff;
  bottom: 0;
  width: 100%;
  padding: 5px 20px;
`;

const SlidesDots = styled.div`
  position: absolute;
  font-size: 0;
  bottom: 29px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Dot = styled.div<{ active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 8px;
  object-fit: contain;
  opacity: ${({ active }): string => (active ? '1' : '0.3')};
  background-color: #fff;

  &:last-of-type {
    margin-right: 0;
  }
`;
