import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { CommonButton } from '@app/components/Buttons';

import { LiveAudioIcon } from '@app/components/Icons';
import { Game } from '@app/types/matchTypes';
import MatchLiveAudioModal from '@app/pages/MatchCenter/components/MatchLiveAudioModal';
import { useLanguage } from '@app/components/Hooks';
import { setMatchLiveAudio } from '@app/store/actions/matchActions';
import { AppLanguagesMap } from '@app/constants/localizationConstants';

import { KenticoLanguages } from '../../../../server/src/types/Enums';

interface MatchLiveAudioButtonProps {
  game: Game;
  isWidget?: boolean;
}

const MatchLiveAudioButton = React.memo(({ game, isWidget }: MatchLiveAudioButtonProps): React.ReactElement => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const language = useLanguage();
  const dispatch = useDispatch();

  const onClickLiveAudio = () => {
    if (game.streamLanguage === KenticoLanguages.italian && language === AppLanguagesMap.en) {
      setModalVisible(true);
    } else {
      game && dispatch(setMatchLiveAudio({
        audioStreamUrl: game.audioStreamUrl,
        additionalAudioStreamUrl: game.additionalAudioStreamUrl,
        paused: false,
      }));
    }
  };

  return (
    <>
      <LiveAudioButton onClick={onClickLiveAudio} isWidget={isWidget}>
        <LiveAudioIcon />
        <FormattedMessage id="match.listenLiveAudio" />
      </LiveAudioButton>
      <MatchLiveAudioModal
        open={isModalVisible}
        game={game}
        onClose={() => {
          setModalVisible(false);
        }}
      />
    </>
  );
});

export const LiveAudioButton = styled(CommonButton)<{ isWidget?: boolean }>`
  position: relative;
  background-color: ${(props): string => props.theme.color.whiteSmoke};
  border-color: ${(props): string => props.theme.color.whiteSmoke};
  color: ${(props): string => props.theme.color.purple};
  height: ${({ isWidget }): string => (isWidget ? '30px' : '45px')};
  width: ${({ isWidget }): string => (isWidget ? 'auto' : '250px')};
  min-width: 140px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;

  font-size: ${({ isWidget }): string => (isWidget ? '12px' : '15px')};
  font-weight: bold;

  & > * {
    margin-right: 16px;
  }

  & g.strokes {
    transition: fill 0.3s ease-in;
  }

  &:hover {
    background-color: transparent;
    color: white;
    & g.strokes {
      fill:  ${(props): string => props.theme.color.whiteSmoke};
    }
  }
`;

export default MatchLiveAudioButton;
