import * as React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { Player } from '@app/types/teamsTypes';
import LocalizedLink from '@app/components/Localization/LocalizedLink';
import { usePhotoLarge } from '@app/pages/Teams/TeamsHooks';
import { useLanguage } from '@app/components/Hooks';
import AppRoutes from '@app/constants/routesConstants';
import { getStaticImageConfig } from '@app/helpers/imageHelper';
import { Devices } from '@app/styles';
import { useTabs } from '@app/pages/PlayerProfile/PlayersHooks';
import { useIntl } from 'react-intl';

interface PlayerCardProps {
  item: Player;
  teamType: string;
}

const PlayerCardContent = ({ item }: PlayerCardProps): React.ReactElement => {
  const photoLarge = usePhotoLarge(item);
  return (
    <>
      {item.number > 0 && (<Number>{item.number}</Number>)}
      <Photo>
        <img
          {...getStaticImageConfig({ url: photoLarge, width: 220 })}
          alt={item.photoLarge.description}
        />
      </Photo>
      <Text>
        <FirstName>{item.firstName}</FirstName>
        <LastName>{item.lastName}</LastName>
        {item.role && (<FirstName>{item.role}</FirstName>)}
      </Text>
    </>
  );
};

export const PlayerCard = ({ item, teamType }: PlayerCardProps): React.ReactElement => {
  const { topLevel = '' } = useParams();
  const tab = useTabs()[0].caption.toLocaleLowerCase();
  const language = useLanguage();
  const { formatMessage } = useIntl();

  return (
    <Container>
      { item.id === '0' ? (
        <PlayerCardContent item={item} teamType={teamType} />
      ) : (
        <ContainerLink
          pathKey={AppRoutes.CommonPage.path}
          pathParams={{
            topLevel, secondLevel: teamType, thirdLevel: formatMessage({ id: 'players' }), forthLevel: item.urlSlug, fifthLevel: tab,
          }}
          onClick={(): void => {
            window.dataLayer && window.dataLayer.push({
              event: 'click_player',
              event_type: 'player_card',
              team_category: teamType,
              player_name: item.urlSlug,
              CD1: language,
            });
          }}
        >
          <PlayerCardContent item={item} teamType={teamType} />
        </ContainerLink>
      )}
    </Container>
  );
};

const Container = styled.li`
  background-color: #ffffff;
  width: 155px;
  height: 220px;
  position: relative;
  margin: 0 25px 25px 0;
  color: #ffffff;
  letter-spacing: 0.21px;
  overflow: hidden;

  &:nth-of-type(2n) {
     margin-right: 0;
  }

  @media ${Devices.largeMobile} {
    margin: 0 20px 20px 0;
    width: 215px;
    height: 365px;

    &:nth-of-type(2n) {
     margin-right: 20px;
    }
  }

  @media ${Devices.desktop} {
    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }
`;

const ContainerLink = styled(LocalizedLink)`
  color: white;

  &:hover,
  &:active,
  &:visited {
    color: white;
  }
`;

const Photo = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 25px;
  width: 100%;
  height: 136px;
  z-index: 5;

  & > img {
    max-width: 120px;
    height: 100%;
    border-bottom: 3px solid ${(props): string => props.theme.color.shadow};
  }

  @media ${Devices.largeMobile} {
    top: 50px;
    height: 206px;

    & > img {
      max-width: 160px;
    }
  }
`;

const Text = styled.div`
  position: absolute;
  top: 171px;
  left: 0;
  right: 0;
  z-index: 15;
  color: #ffffff;

  @media ${Devices.largeMobile} {
    top: 281px;
  }
`;

const NoMarginH3 = styled.h3`
  margin: 0;
  color: #000000;
  text-align: center;
`;

const Number = styled.p`
  position: absolute;
  z-index: 10;
  top: 25px;
  left: -5px;
  margin: 0;
  opacity: 0.08;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.6;
  letter-spacing: normal;
  color: #000000;

  @media ${Devices.largeMobile} {
    top: 44px;
    left: -8px;
    font-size: 80px;
  }
`;

const FirstName = styled(NoMarginH3)`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 12px;
  font-weight: normal;

  @media ${Devices.largeMobile} {
    font-size: 16px;
    line-height: 2.08;
  }
`;

const LastName = styled(NoMarginH3)`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;

  @media ${Devices.largeMobile} {
    font-size: 18px;
  }
`;
