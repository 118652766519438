import styled from 'styled-components';

export const ImageContainer = styled.div`
  width: auto;
  height: 100%;
  margin: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  cursor: default;
`;

export const Name = styled.div`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.14;
  letter-spacing: 1.4px;
  color: #000000;
  margin-left: 10px;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: default;
  overflow: hidden;
`;

export const Points = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #e12319;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  cursor: default;

  & > span {
    padding-top: 1px;
  }

  & .pts {
    font-weight: normal;
  }
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const HeartContainer = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;

  & > img {
    cursor: pointer;
  }
`;
