import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';

import { useMenu } from '@app/components/Hooks';
import { useIsConnected } from '@app/components/Inviola/InviolaHooks';
import { isMenuItemVisible } from '@app/helpers/menuHelpers';

import MenuItem from './MenuItem';

const Menu: React.FC = () => {
  const menuItems = useMenu();
  const isConnected = useIsConnected();

  const menu = (menuItems && Object.values(menuItems)
    .filter(({ data }) => isMenuItemVisible(data, isConnected) && data.isShownInHeader)) || [];

  return (
    <Container>
      <MenuItemsList>
        {
          menu.map((item) => (
            <MenuItem item={item} key={item.data.id} isHeader />))
        }
      </MenuItemsList>
    </Container>
  );
};

export default Menu;

const Container = styled.nav`
  display: none;

  @media ${Devices.desktopSmall} {
    display: block;
    position: relative;
    right: -15px;
    min-width: calc(50% - 75px);
    margin-left: 40px;
  }
`;

const MenuItemsList = styled.ul`
  width: 100%;
  font-size: 16px;
  height: 100%;
  display: flex;
`;
