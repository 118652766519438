import { Competitions } from '@app/types/configurationTypes';
import { MatchResult, ScheduleResults } from '@app/types/matchTypes';
import { TeamStats, StandingsItem } from '@app/types/standingsTypes';
import { OptaStandingsResponse, OptaSDStandingsResponse } from '@app/services/opta/types/responseTypes';
import {
  FiorentinaTeamId, WomenFiorentinaTeamId, OptaSDFiorentinaTeamIdMap,
} from '@app/services/opta/constants/teamConstants';
import { convertTeamId } from '@app/services/opta/converters/teamsConverter';
import { getMenTeamLogoUrl, getWomenTeamLogoUrl } from '@app/helpers/sourceHelpers';
import theme from '@app/styles/theme';

export function findCompetitionIdByUrlSlug(competitions: Competitions | undefined, urlSlug: string): string {
  return Object.values(competitions ?? {}).find(({ url }) => url === urlSlug)?.id ?? '';
}

export function findCompetitionCategoryIdByUrlSlug(competitions: Competitions | undefined, urlSlug: string): string {
  return Object.values(competitions ?? {}).find(({ url }) => url === urlSlug)?.categoryId ?? '';
}

export function mapStandings(data: OptaStandingsResponse): StandingsItem {
  const teams = data?.SoccerFeed?.SoccerDocument?.Team || [];
  const standingsSource = data.SoccerFeed?.SoccerDocument?.Competition?.TeamStandings;
  const standings = Array.isArray(standingsSource)
    ? standingsSource.find((round) => !!round?.TeamRecord?.find((team) => [
      FiorentinaTeamId, WomenFiorentinaTeamId,
    ].includes(convertTeamId(team['@attributes'].TeamRef ?? ''))))?.TeamRecord ?? []
    : standingsSource?.TeamRecord ?? [];

  return standings.map((item, index): TeamStats => {
    const standing = item.Standing || {};
    const teamId = convertTeamId(item['@attributes'].TeamRef ?? '');
    return {
      id: teamId,
      logoUrl: getMenTeamLogoUrl(teamId),
      position: +standing.Position,
      points: +standing.Points,
      played: +standing.Played,
      wons: +standing.Won,
      drawn: +standing.Drawn,
      lost: +standing.Lost,
      goalsFor: +standing.For,
      goalsAgainst: +standing.Against,
      goalsDiff: +standing.For - (+standing.Against),
      teamName: teams && teams[index].Name,
    };
  });
}

export function mapSDStandings(data: OptaSDStandingsResponse): StandingsItem {
  const stage = (data?.stage ?? [])
    .find((stage) => (stage?.division[0]?.ranking ?? [])
      .find(({ contestantId }) => [
        OptaSDFiorentinaTeamIdMap.competition_women_seriea,
        OptaSDFiorentinaTeamIdMap.primavera_2,
        OptaSDFiorentinaTeamIdMap.competition_youth_campionato_primavera_1,
      ].includes(contestantId)));
  return (stage?.division[0]?.ranking ?? [])
    .map((standing) => ({
      id: standing?.contestantId ?? '',
      logoUrl: getWomenTeamLogoUrl(standing?.contestantId),
      position: standing?.rank ?? '',
      points: standing?.points ?? '',
      played: standing?.matchesPlayed ?? '',
      wons: standing?.matchesWon ?? '',
      drawn: standing?.matchesDrawn ?? '',
      lost: standing?.matchesLost ?? '',
      goalsFor: standing?.goalsFor ?? '',
      goalsAgainst: standing?.goalsAgainst ?? '',
      goalsDiff: (standing?.goalsFor - standing?.goalsAgainst) ?? '',
      teamName: standing?.contestantShortName ?? '',
    }));
}

export function mapSchedule(matchResults: MatchResult[]): ScheduleResults {
  return matchResults
    .reduce((accumulator, matchResult) => {
      const matchDate = new Date(matchResult.matchDate);
      const matchDateId = `${matchDate.getFullYear()}/${matchDate.getMonth() + 1}/1`;

      if (!accumulator[matchDateId]) accumulator[matchDateId] = [];
      accumulator[matchDateId].push(matchResult);

      return accumulator;
    }, {} as ScheduleResults);
}

export function getTeamColor(isFiorentina: boolean, index?: number): string {
  const defaultColor = isFiorentina ? theme.color.purple : 'white';
  if (!index) {
    return defaultColor;
  }
  if (index < 5) {
    return '#00c906';
  }
  if (index === 5) {
    return '#000e88';
  }
  if (index === 6) {
    return '#8087be';
  }
  return defaultColor;
}
