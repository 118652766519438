import { PlayerStatistics } from '@app/types/teamsTypes';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
  ValueAndCaption, BlockContainer, StatsBlock, StatsBlockColumn, ValueAndGraph,
} from '@app/components/Statistics';
import { CircleGraph } from '@app/components/Graphs/CircleGraph';

interface PerformanceStatisticsProps {
  statistics?: PlayerStatistics;
}

const PassesStatistics = ({ statistics }: PerformanceStatisticsProps): React.ReactElement => (
  <BlockContainer>
    <StatsBlockColumn>
      <ValueAndCaption value={statistics?.totalPasses ?? 0} caption="statistics.passes" />
      <Line>
        <ValueAndGraph
          caption="statistics.short"
          progress={
            statistics?.successfulShortPasses && statistics?.unsuccessfulShortPasses && statistics?.totalPasses
              ? (((statistics.successfulShortPasses + statistics.unsuccessfulShortPasses) * 100)
              / (statistics.totalPasses)) : 0
          }
          value={
            statistics?.successfulShortPasses && statistics?.unsuccessfulShortPasses
              ? statistics.successfulShortPasses + statistics.unsuccessfulShortPasses
              : 0
          }
        />
        <ValueAndGraph
          caption="statistics.long"
          progress={
            statistics?.successfulLongPasses && statistics?.unsuccessfulLongPasses && statistics?.totalPasses
              ? (((statistics.successfulLongPasses + statistics.unsuccessfulLongPasses) * 100)
              / (statistics.totalPasses)) : 0
          }
          value={
            statistics?.successfulLongPasses && statistics?.unsuccessfulLongPasses
              ? statistics.successfulLongPasses + statistics.unsuccessfulLongPasses
              : 0
          }
        />
      </Line>
    </StatsBlockColumn>
    <StatsBlock>
      <PassAccuracyWrapper>
        <CircleGraph
          width={120}
          height={120}
          value={(
            statistics?.totalSuccessfulPasses && statistics?.totalPasses
              ? (statistics.totalSuccessfulPasses / statistics.totalPasses)
              : 0
          )}
        >
          <PassAccuracyCaptionBlock>
            <p>
              <PassAccuracyValue>
                {Math.round(
                  statistics?.totalSuccessfulPasses && statistics?.totalPasses
                    ? (statistics.totalSuccessfulPasses / statistics.totalPasses) * 100
                    : 0,
                )}
              </PassAccuracyValue>
              %
            </p>
            <PassAccuracyCaption>
              <FormattedMessage id="statistics.passAccuracy" />
            </PassAccuracyCaption>
          </PassAccuracyCaptionBlock>
        </CircleGraph>
      </PassAccuracyWrapper>
    </StatsBlock>
  </BlockContainer>
);

const Line = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
`;

const PassAccuracyWrapper = styled.div`
  position: relative;
`;

const PassAccuracyCaptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 16px;
  font-weight: bold;

  & p {
    margin: 0;
  }
`;

const PassAccuracyValue = styled.span`
  font-size: 32px;
  font-weight: bold;
`;

const PassAccuracyCaption = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 12px;
  font-weight: 300;
`;

export default PassesStatistics;
