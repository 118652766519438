import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import AppRoutes from '@app/constants/routesConstants';
import { Devices } from '@app/styles';
import { SubMenuId } from '@app/types/configurationTypes';
import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { findCompetitionCategoryIdByUrlSlug, findCompetitionIdByUrlSlug } from '@app/helpers/seasonHelpers';
import { isOptaSDCompetition } from '@app/services/opta/helpers/competitionHelpers';
import { ExpandArrow } from '@app/components/Icons';
import { PageTitle, Metadata } from '@app/components';
import { PageWrapper } from '@app/pages/components';
import { useLanguage } from '@app/components/Hooks';
import { useSecondLevelMenu } from '@app/pages/CommonPage/CommonPageHooks';
import {
  useSeasonPageTitle,
  useSeasonSeoSelector,
  useCompetitionSelector,
  useSeasonPageErrorReset,
  useSeasonResetOnUnmount,
  useCompetitionsSelector,
  useSeasonRoutesValidation,
  useCompetitionsSelectOptions,
  useActiveSeasonIdForCompetitionSeriaA,
} from './SeasonHooks';
import Standings from '../Standings/Standings';
import Schedule from '../Schedule/Schedule';

const Season = (): React.ReactElement => {
  const dispatch = useDispatch();
  const language = useLanguage();
  const { formatMessage } = useIntl();
  const { topLevel = '', secondLevel = '', thirdLevel = '', forthLevel = '' } = useParams();

  const title = useSeasonPageTitle();
  const seo = useSeasonSeoSelector();
  const secondLevelMenu = useSecondLevelMenu();
  const competitions = useCompetitionsSelector();
  const competition = useCompetitionSelector();
  const competitionsOptions = useCompetitionsSelectOptions();
  const defaultSeasonForthLevel = useActiveSeasonIdForCompetitionSeriaA();
  const competitionCategoryId = findCompetitionCategoryIdByUrlSlug(competitions, thirdLevel);
  const validationError = useSeasonRoutesValidation();

  useSeasonPageErrorReset();
  useSeasonResetOnUnmount();

  const onCategorySelection = (e: { target: { value: string; }; }): void => {
    const categoryCompetition = Object.values(competitions ?? {})
      .find(({ categoryId }) => categoryId === e.target.value);

    dispatch(push(localizeRouteKey({
      pathKey: AppRoutes.CommonPage.path,
      pathParams: {
        urlSlug: e.target.value,
        topLevel,
        secondLevel,
        thirdLevel: categoryCompetition?.url ?? '',
        forthLevel: isOptaSDCompetition(categoryCompetition?.categoryId ?? '')
          ? formatMessage({ id: 'season.active' })
          : categoryCompetition?.seasonIds?.find(({ isActive }) => isActive)?.seasonId ?? defaultSeasonForthLevel,
        fifthLevel: '',
      },
    }, language)));
  };

  const onCompetitionSelection = (e: { target: { value: string; }; }): void => {
    const selectedCompetitionId = findCompetitionIdByUrlSlug(competitions, e.target.value);
    const selectedCompetitionCategoryId = findCompetitionCategoryIdByUrlSlug(competitions, e.target.value);

    dispatch(push(localizeRouteKey({
      pathKey: AppRoutes.CommonPage.path,
      pathParams: {
        topLevel,
        secondLevel,
        thirdLevel: e.target.value ?? '',
        forthLevel: isOptaSDCompetition(selectedCompetitionCategoryId)
          ? formatMessage({ id: 'season.active' })
          : competitions?.[selectedCompetitionId]?.seasonIds?.find(({ isActive }) => isActive)?.seasonId ?? '',
        fifthLevel: '',
      },
    }, language)));
  };

  const onSeasonSelection = (e: { target: { value: string; }; }): void => {
    dispatch(push(localizeRouteKey({
      pathKey: AppRoutes.CommonPage.path,
      pathParams: {
        topLevel,
        secondLevel,
        thirdLevel,
        forthLevel: e.target.value ?? '',
        fifthLevel: '',
      },
    }, language)));
  };

  const renderSubPage = () : React.ReactElement | null => {
    switch (secondLevelMenu?.data?.id) {
      case SubMenuId.SCHEDULE:
        return <Schedule />;
      case SubMenuId.STANDINGS:
        return <Standings />;
      default:
        return null;
    }
  };

  return (
    <SeasonPage error={validationError}>
      {!!seo && <Metadata seo={seo} />}
      <FiltersSection>
        <PageTitle>{title}</PageTitle>
        <FiltersContainer>
          <SelectContainer>
            <SelectWrapper>
              <Select defaultValue={competitionCategoryId} value={competitionCategoryId} onChange={onCategorySelection}>
                {secondLevelMenu?.categories
                  ?.map(({ id, name }) => <option key={id} value={id}>{name}</option>)}
              </Select>
              <ExpandArrow />
            </SelectWrapper>
            <SelectWrapper>
              <Select defaultValue={thirdLevel} value={thirdLevel} onChange={onCompetitionSelection}>
                {competitionsOptions
                  ?.map(({ id, name, url }) => <option key={id} value={url}>{name}</option>)}
              </Select>
              <ExpandArrow />
            </SelectWrapper>
            <SelectWrapper>
              <Select value={forthLevel} onChange={onSeasonSelection}>
                {(competition?.seasonIds ?? [])
                  .map(({ seasonId, seasonName }) => (
                    <option key={`${seasonId}${seasonName}`} value={seasonId}>{seasonName}</option>))}
              </Select>
              <ExpandArrow />
            </SelectWrapper>
          </SelectContainer>
        </FiltersContainer>
      </FiltersSection>
      {renderSubPage()}
    </SeasonPage>
  );
};

export default Season;

const SeasonPage = styled(PageWrapper)`
  height: 100%;
  background-color: ${(props): string => props.theme.color.whiteSmoke};
`;

const FiltersSection = styled.section`
  max-width: 920px;
  padding: 0 10px;
  margin: auto auto 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  & h1 {
    font-size: 18px;
  }

  @media ${Devices.tablet} {
    & h1 {
      font-size: 28px;
    }
  }

  @media ${Devices.tabletLarge} {
    flex-direction: row;
    align-items: flex-end;
  }

  @media ${Devices.desktop} {
    padding: 0;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${Devices.tabletLarge} {
    flex-direction: row;
  }
`;

const SelectContainer = styled.div`
  display: flex;
`;

const SelectWrapper = styled.div`
  position: relative;
  width: auto;
  height: auto;
  margin: 10px 0 0 10px;

  &:first-child {
    margin: 10px 0 0 0;
  }

  &:first-of-type {
    & > select {
      min-width: 100px;
    }
  }

  & > svg {
    position: absolute;
    z-index: 9;
    right: 10px;
    bottom: 10px;
  }

  @media ${Devices.tabletLarge} {
    margin: 0 0 0 20px;

    &:first-child {
      margin: 0 0 0 20px;
    }
  }
`;

const Select = styled.select`
  position: relative;
  z-index: 10;
  width: 100%;
  min-width: 100px;
  max-width: 160px;
  height: 30px;
  padding: 0 25px 0 15px;
  border-radius: 15px;
  border: solid 1px #dfdbdb;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  letter-spacing: 0.23px;
  font-size: 14px;
  font-weight: 300;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};

  @media ${Devices.tabletLarge} {
    font-size: 12px;
  }
`;
