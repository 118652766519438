import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';

import AppRoutes from '@app/constants/routesConstants';
import { COVER_IMAGE_TYPE } from '@app/constants/imageConstants';
import { BaseNewsItem, EditorialContentTypes } from '@app/types/newsTypes';

import { useNewsUrlParams, useNewsDetailsPath } from '@app/components/Hooks';
import { useIsConnected } from '@app/components/Inviola/InviolaHooks';

import { Modal } from '@app/components';
import { PremiumAccessNotification } from '@app/components/Inviola';
import { CoverImage } from '@app/components/Image';
import { LocalizedLink } from '@app/components/Localization';
import { ImageWidthMap } from '@app/types/imageTypes';
import NewsItemInfo from './NewsItemInfo';

interface NewsItemProps {
  data: BaseNewsItem;
  coverType?: COVER_IMAGE_TYPE;
  onClick?: (item: BaseNewsItem) => void;
  imageWidthMap?: ImageWidthMap;
  home?: boolean;
  infoSize?: 'large' | 'medium' | 'small';
  shiftInfo?: boolean;
  current?: boolean;
}

export const NewItem: React.FC<NewsItemProps> = React.memo(({
  data,
  onClick,
  infoSize = 'small',
  shiftInfo,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const { formatMessage } = useIntl();
  const { image, title, publicationDate, isPremium } = data;
  const linksParams = useNewsUrlParams(data);
  const redirectPath = useNewsDetailsPath(data);

  const isConnected = useIsConnected();
  const isPrivate = isPremium && !isConnected;

  const onCloseModal = (): void => setIsModalOpened(false);
  const onNewsItemClick = (event: React.MouseEvent) => {
    if (isPrivate) {
      setIsModalOpened(true);
      event.preventDefault(); // STOP:REDIRECT:TO:NEWS:DETAILS:PAGE
    }

    onClick && onClick(data);
  };

  return (
    <>
      <NewsItemLinkContainer
        pathKey={AppRoutes.CommonPage.path}
        pathParams={linksParams}
        onClick={onNewsItemClick}
      >
        <NewsItemImageContainer>
          <CoverImage image={image} />
          <NewsInfoContainer shiftInfo={shiftInfo}>
            {data.type === EditorialContentTypes.VIDEO && (
              <ContentIcon src="/images/play.svg" alt={formatMessage({ id: 'icon.playVideo.alt' })} />
            )}
            {data.type === EditorialContentTypes.PHOTO && (
              <GalleryIconContainer>
                <ContentIcon src="/images/gallery.svg" alt={formatMessage({ id: 'icon.gallery.alt' })} />
                {!!data.photosNumber && (
                  <PhotoNumber color="white">
                    <FormattedMessage
                      id="gallery.photos"
                      values={{ number: data.photosNumber }}
                    />
                  </PhotoNumber>
                )}
              </GalleryIconContainer>
            )}
            <NewsItemInfo
              time={publicationDate}
              title={title}
              size={infoSize}
              isPremium={isPremium}
              category={data.categoryCode}
            />
          </NewsInfoContainer>
        </NewsItemImageContainer>
      </NewsItemLinkContainer>
      <Modal open={isModalOpened} onClose={onCloseModal} contentFullWidth={false} controls={false}>
        <PremiumAccessNotification onLogin={onCloseModal} onCancel={onCloseModal} redirectPath={redirectPath} />
      </Modal>
    </>
  );
});

export const FullSizeNewItem: React.FC<NewsItemProps> = ({
  data,
  onClick,
  coverType = COVER_IMAGE_TYPE.TOP_CAROUSEL_DESKTOP,
  home = false,
  shiftInfo,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const { formatMessage } = useIntl();
  const { image, imageVertical, title, categoryCode, publicationDate, isPremium } = data;
  const linksParams = useNewsUrlParams(data);
  const redirectPath = useNewsDetailsPath(data);

  const isConnected = useIsConnected();
  const isPrivate = isPremium && !isConnected;

  const onCloseModal = (): void => setIsModalOpened(false);
  const onNewsItemClick = (event: React.MouseEvent) => {
    if (isPrivate) {
      setIsModalOpened(true);
      event.preventDefault(); // STOP:REDIRECT:TO:NEWS:DETAILS:PAGE
    }

    onClick && onClick(data);
  };

  return (
    <>
      <FullSizeNewItemContainer
        pathKey={AppRoutes.CommonPage.path}
        pathParams={linksParams}
        onClick={onNewsItemClick}
      >
        <FullSizeNewsItemImageContainer>
          <CoverImage image={image} imageVertical={imageVertical} coverType={coverType} />
          <FullSizeNewsItemDataContainer home={home}>
            {data.type === EditorialContentTypes.VIDEO && (
              <MainNewsContentIcon src="/images/play.svg" alt={formatMessage({ id: 'icon.playVideo.alt' })} />
            )}
            {data.type === EditorialContentTypes.PHOTO && (
              <MainNewsContentIcon src="/images/gallery.svg" alt={formatMessage({ id: 'icon.gallery.alt' })} />
            )}
            <MainNewsInnerContainer home={home}>
              <NewsInfoContainer shiftInfo={shiftInfo}>
                <NewsItemInfo
                  time={publicationDate}
                  category={categoryCode}
                  title={title}
                  size="large"
                  isPremium={isPremium}
                />
              </NewsInfoContainer>
            </MainNewsInnerContainer>
          </FullSizeNewsItemDataContainer>
        </FullSizeNewsItemImageContainer>
      </FullSizeNewItemContainer>
      <Modal open={isModalOpened} onClose={onCloseModal} contentFullWidth={false} controls={false}>
        <PremiumAccessNotification onLogin={onCloseModal} onCancel={onCloseModal} redirectPath={redirectPath} />
      </Modal>
    </>
  );
};

const NewsItemImageContainer = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 150px; // required for correct lazy-load on tablets and desktop
  height: 100%;
  color: white;
`;

const FullSizeNewsItemImageContainer = styled(NewsItemImageContainer)`
  border-radius: 0;
  position: relative;
  padding-bottom: 0;
  max-width: 1280px;
  margin: auto;
`;

const GalleryIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 10px;
`;

const PhotoNumber = styled.span<{color: string}>`
  color: ${({ color }): string => color};
  display: inline-block;
  font-size: 13px;
  letter-spacing: 0.36px;
  margin-left: 10px;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-weight: normal;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, #000), linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  z-index: 1;
`;

export const NewsItemLinkContainer = styled(LocalizedLink)`
  cursor: pointer;
  height: 100%;
  overflow: hidden;
`;

export const NewsInfoContainer = styled.div<{ shiftInfo?: boolean }>`
  position: absolute;
  z-index: 2;
  padding: 20px;
  bottom: ${({ shiftInfo }): string => (shiftInfo ? '50px' : '0')};
`;

const FullSizeNewItemContainer = styled(NewsItemLinkContainer)`
  max-width: 1280px;
  height: auto;
  margin: auto;
  overflow: hidden;
  position: relative;
`;

const FullSizeNewsItemDataContainer = styled.div<{ home: boolean }>`
  position: absolute;
  bottom: ${({ home }): string => (home ? '50px' : '0')};
  top: 0;
  width: 100%;
`;

const MainNewsInnerContainer = styled.div<{ home: boolean }>`
  max-width: 1180px;
  margin: ${({ home }): string => (home ? '0 0 50px' : 'auto')};

  @media ${Devices.desktopSmall} {
    margin: ${({ home }): string => (home ? '0 0 50px 50px' : 'auto')};
  }
`;

const ContentIcon = styled.img`
  display: block;
  width: 25px;
  z-index: 5;
  padding-bottom: 10px;
`;

const MainNewsContentIcon = styled.img`
  display: block;
  width: 30px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 5;

  @media ${Devices.tablet} {
    width: 50px;
    top: 50%;
  }

  @media ${Devices.desktopSmall} {
    width: 70px;
  }
`;
