import {
  addCatalogItemToWishList, removeCatalogItemFromWishList, exchangePrize,
  fetchCatalogItemsFromWishList, getNotExchangedPrizes, getExchangedPrizes,
} from '@app/services/inviola/inviola';
import { InviolaAPIError, InviolaAppError, InviolaAppSuccess } from '@app/services/inviola/types/errorTypes';
import {
  InviolaRawCatalogItems, InviolaRawCatalogItem, InviolaResponse, InviolaRawAwards, InviolaRawCategories,
} from '@app/services/inviola/types/responseTypes';
import {
  AddItemToWishList,
  InviolaActionTypes,
  RemoveItemFromWishList,
  RequestAwardDone,
  SetPointsSyncInProgress,
  SetAwards,
  SetCatalogItems,
  SetCatalogSyncInProgress,
  SetCatalogSelectedItemId,
  SetCatalogFilterPage,
  SetCatalogFilterCategory,
  SetCatalogFilterMinPoints,
  SetCatalogFilterMaxPoints,
  SetItemsToWishList,
  SetWithdrawnAwards,
} from '@app/store/actionTypes/inviolaActionTypes';
import { ActionWithPromiseReturn } from '@app/types/actionTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { InviolaCatalogItemId } from '@app/types/inviolaTypes';
import {
  fetchProxyCatalogCategories,
  fetchProxyCatalogItems,
  proxySendPoints,
} from '@app/services/inviola/inviolaProxy';
import { getCustomerPoints, getCardMovements, updateCustomerPoints } from '@app/store/actions/inviolaActions/customer';
import { handleInviolaError, setSuccess } from './error';
import { getSession } from './session';

const InviolaAssetsUrl = process.env.INVIOLA_ASSETS_URL;

const setCatalogItems = (
  data: InviolaRawCatalogItems,
): SetCatalogItems => ({
  type: InviolaActionTypes.SET_CATALOG_ITEMS,
  payload: data,
});

export const setCatalogSelectedItemId = (id?: number): SetCatalogSelectedItemId => ({
  type: InviolaActionTypes.SET_CATALOG_SELECTED_ITEM_ID,
  payload: id,
});

export const setCatalogFilterPage = (page: number): SetCatalogFilterPage => ({
  type: InviolaActionTypes.SET_CATALOG_FILTER_PAGE,
  payload: page,
});

export const setCatalogFilterCategory = (category: number): SetCatalogFilterCategory => ({
  type: InviolaActionTypes.SET_CATALOG_FILTER_CATEGORY,
  payload: category,
});

export const setCatalogFilterMinPoints = (min: number): SetCatalogFilterMinPoints => ({
  type: InviolaActionTypes.SET_CATALOG_FILTER_MIN_POINTS,
  payload: min,
});

export const setCatalogFilterMaxPoints = (max: number): SetCatalogFilterMaxPoints => ({
  type: InviolaActionTypes.SET_CATALOG_FILTER_MAX_POINTS,
  payload: max,
});

const setCatalogSyncInProgress = (status: boolean): SetCatalogSyncInProgress => ({
  type: InviolaActionTypes.CATALOG_SYNC_IN_PROGRESS,
  payload: status,
});

export const setPointsSyncInProgress = (
  status: boolean,
): SetPointsSyncInProgress => ({
  type: InviolaActionTypes.POINTS_SYNC_IN_PROGRESS,
  payload: status,
});

const setItemsToWithList = (
  data: InviolaCatalogItemId[],
): SetItemsToWishList => ({
  type: InviolaActionTypes.SET_ITEMS_TO_WISH_LIST,
  payload: data,
});

const addItemToWithList = (
  data: InviolaCatalogItemId,
): AddItemToWishList => ({
  type: InviolaActionTypes.ADD_ITEM_TO_WISH_LIST,
  payload: data,
});

const removeItemFromWithList = (
  data: InviolaCatalogItemId,
): RemoveItemFromWishList => ({
  type: InviolaActionTypes.REMOVE_ITEM_FROM_WISH_LIST,
  payload: data,
});

export const syncCatalogItems = (language: LanguageType): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    const state = getState();
    const { cache, syncInProgress, items } = state.inviola.catalog;

    const now = new Date().getTime();
    const interval = 30 * 60 * 1000; // 30:MINUTES: min * seconds in min * milliseconds in second

    if (syncInProgress) return Promise.resolve();
    if (!items.length || !cache || (now - cache > interval)) await dispatch(getCatalogItems(language));
  }
);

export const getCatalogItems = (language: LanguageType): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    const catalogId = getState().configuration[language]?.inviola?.catalogId ?? 0;
    const syncSessionId = await dispatch(getSession('sync'));

    dispatch(setCatalogSyncInProgress(true));

    try {
      const categories = await fetchProxyCatalogCategories(syncSessionId);
      const response = await fetchProxyCatalogItems(syncSessionId, catalogId);

      response.prizes = response.prizes.map((prize) => updateProxyPrize(prize, categories));

      dispatch(setCatalogItems(response));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAPIError.GetCatalogError));
    } finally {
      dispatch(setCatalogSyncInProgress(false));
    }
  }
);

export const updateProxyPrize = (
  prize: InviolaRawCatalogItem, availableCategories: InviolaRawCategories,
): InviolaRawCatalogItem => ({
  ...prize,

  category: availableCategories.categories.find((category) => category.id === prize.categoryId),
  pathImageAbsolute: `${InviolaAssetsUrl}/${prize.pathImage}`,
});

export const sendPoints = (targetCardNumber: string, pointsAmount: string): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    dispatch(setPointsSyncInProgress(true));
    const customerSessionId = await dispatch(getSession('customer'));

    try {
      const { customer } = getState().inviola;

      if (customerSessionId && customer) {
        const response = await proxySendPoints(
          customerSessionId,
          customer.id,
          customer.birthDate,
          targetCardNumber,
          pointsAmount,
        );
        dispatch(updateCustomerPoints(response.remainingPoints));
        await dispatch(getCardMovements());
      }
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAPIError.SendPointsError));
    } finally {
      dispatch(setPointsSyncInProgress(false));
    }
  }
);

const setAwards = (awards: InviolaResponse<InviolaRawAwards>): SetAwards => ({
  type: InviolaActionTypes.SET_AWARDS,
  payload: awards.data,
});

const setWithdrawnAwards = (awards: InviolaResponse<InviolaRawAwards>): SetWithdrawnAwards => ({
  type: InviolaActionTypes.SET_WITHDRAWN_AWARDS,
  payload: awards.data,
});

export const getAwards = (): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    const customerSessionId = await dispatch(getSession('customer'));

    try {
      const notExchangedPrizes = await getNotExchangedPrizes(customerSessionId);

      dispatch(setAwards(notExchangedPrizes));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAPIError.GetAwardsError));
    }
  }
);

export const getWithdrawnAwards = (): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    const customerSessionId = await dispatch(getSession('customer'));

    try {
      const exchangedPrizes = await getExchangedPrizes(customerSessionId);

      dispatch(setWithdrawnAwards(exchangedPrizes));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAPIError.GetAwardsError));
    }
  }
);

export const requestAward = (prizeId: number, language: LanguageType): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    dispatch(setPointsSyncInProgress(true));

    const customerSessionId = await dispatch(getSession('customer'));
    const catalogId = getState().configuration[language]?.inviola?.catalogId ?? 0;

    try {
      await exchangePrize(customerSessionId, { catalogId, prizeId });
      await dispatch(getCustomerPoints());

      dispatch(setSuccess(InviolaAppSuccess.AwardRequested));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.RequestAwardError));
      dispatch(setPointsSyncInProgress(false));
    }
  }
);

export const setRequestAwardDone = (): RequestAwardDone => ({
  type: InviolaActionTypes.REQUEST_AWARD_DONE,
});

export const getCatalogItemsFromWishList = (language: LanguageType): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    const currentState = getState();
    const isConnected = !!currentState?.inviola?.customerSession?.id;

    try {
      if (isConnected) {
        const customerSessionId = await dispatch(getSession('customer'));

        const catalogId = currentState.configuration[language]?.inviola?.catalogId ?? 0;
        const customerId = getState()?.inviola?.customer?.id ?? 0;

        const wishList = await fetchCatalogItemsFromWishList(customerSessionId, customerId, catalogId);

        dispatch(setItemsToWithList(wishList.data.map((item) => ({ id: parseInt(item.prize_id, 10) }))));
      }
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAPIError.GetCatalogWishListError));
    }
  }
);

export const addToWishList = (language: LanguageType, prizeId: number): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    const currentState = getState();
    const isConnected = !!currentState?.inviola?.customerSession?.id;
    try {
      if (isConnected) {
        const customerSessionId = await dispatch(getSession('customer'));

        const catalogId = currentState.configuration[language]?.inviola?.catalogId ?? 0;
        const customerId = getState()?.inviola?.customer?.id ?? 0;
        const result = await addCatalogItemToWishList(customerSessionId, customerId, catalogId, prizeId);
        if (result.data) {
          dispatch(addItemToWithList({ id: prizeId }));
        }
      }
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAPIError.GetCatalogWishListError));
    }
  }
);

export const deleteFromWishList = (language: LanguageType, prizeId: number): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    const currentState = getState();
    const isConnected = !!currentState?.inviola?.customerSession?.id;

    try {
      if (isConnected) {
        const customerSessionId = await dispatch(getSession('customer'));

        const catalogId = currentState.configuration[language]?.inviola?.catalogId ?? 0;
        const customerId = getState()?.inviola?.customer?.id ?? 0;
        const result = await removeCatalogItemFromWishList(customerSessionId, customerId, catalogId, prizeId);
        if (result.data) {
          dispatch(removeItemFromWithList({ id: prizeId }));
        }
      }
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAPIError.GetCatalogWishListError));
    }
  }
);
