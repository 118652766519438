import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useVideo } from 'react-use';

import { AllowedVideosExtension } from '@app/constants/videoConstants';
import { RemoteSubtitles } from '@app/types/videoPlayerTypes';
import { pauseMatchLiveAudio } from '@app/store/actions/matchActions';

import { NewsPremium } from '@app/components/News/NewsItemInfo';

import { useIsConnected } from '@app/components/Inviola/InviolaHooks';
import { useLanguage, useCurrentRoute } from '@app/components/Hooks';

import { Modal } from '@app/components';
import { PremiumAccessNotification } from '@app/components/Inviola';

import {
  useFullScreen,
  useOverlayAutoHide,
  useRemoteSubtitles,
  useSubtitlesToggle,
  useVideoControls,
  useVideoErrorMessages,
} from './VideoPlayerHooks';

import ControlsBar from './ControlsBar';
import InvalidVideo from './InvalidVideo';
import InlineTrack from './RemoteTrack';

import { Container, Video, Overlay, Title, Badge, GlobalStyle, VideoContainer } from './VideoComponents';

interface VideoPlayerProps {
  src: string;
  title?: string;
  remoteSubtitles?: RemoteSubtitles;
  badge?: string;
  badgeColor?: string;
  isPremium?: boolean;
  autoPlay?: boolean;
  showControls?: boolean;
  preview?: boolean;
  startPlay?: boolean;
}

const isValidVideo = (videoSrc: string): boolean => videoSrc.substr(-3, 3) in AllowedVideosExtension;

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  title,
  remoteSubtitles,
  badge,
  badgeColor,
  showControls = true,
  isPremium = false,
  autoPlay = false,
  preview = false,
  startPlay = false,
}) => {
  const videoContainerRef = useRef(null);
  const language = useLanguage();
  const dispatch = useDispatch();

  const { invalidVideo, invalidBrowser } = useVideoErrorMessages();

  const [subtitlesLoaded, subtitlesSrcData] = useRemoteSubtitles(remoteSubtitles);
  const [isPremiumModalOpened, setIsPremiumModalOpened] = useState(false);

  const currentRoute = useCurrentRoute();
  const isConnected = useIsConnected();

  const isLocked = isPremium && !isConnected;

  useEffect(() => {
    autoPlay && dispatch(pauseMatchLiveAudio(true));
  }, [autoPlay]);

  const videoElement = (
    <Video controls={false} autoPlay={autoPlay} muted={preview} loop={preview} playsInline={preview} cover>
      <source src={src} />

      {subtitlesLoaded && <InlineTrack srcData={subtitlesSrcData} srcLang={remoteSubtitles?.lang ?? language} />}

      <p>{invalidBrowser}</p>
    </Video>
  );

  const [video, state, controls, videoRef] = useVideo(videoElement);
  const { onPlay, onPause, onMute, onUnMute, onSeek } = useVideoControls(controls);
  const { fullScreenEnabled, isFullScreen, toggleFullScreen } = useFullScreen({
    target: videoContainerRef.current,
    active: showControls,
  });
  const [overlayVisible, onOverlayHover] = useOverlayAutoHide(state.paused);
  const [controlHover, onControlHover] = useState(false);
  const [subtitlesVisible, onSubtitlesToggle] = useSubtitlesToggle(videoRef);

  const onClosePremiumModal = (): void => {
    if (isPremiumModalOpened) setIsPremiumModalOpened(false);
  };

  if (!isValidVideo(src)) {
    return <InvalidVideo message={invalidVideo}>{video}</InvalidVideo>;
  }

  const togglePlayState = (): Promise<void> | void => (state.paused ? onPlay() : onPause());

  if (videoRef && videoRef.current && preview) {
    if (startPlay) {
      // eslint-disable-next-line no-void
      void videoRef.current.play();
    } else {
      // eslint-disable-next-line no-void
      void videoRef.current.pause();
    }
  }

  return (
    <>
      <Container
        ref={videoContainerRef}
        onMouseMove={onOverlayHover}
        onClick={() => isLocked && setIsPremiumModalOpened(true)}
        fullScreenMode={isFullScreen}
        isLocked={isLocked}
      >
        <GlobalStyle hideOverflow={isFullScreen} />

        <VideoContainer
          onClick={togglePlayState}
          onDoubleClick={toggleFullScreen}
        >
          {video}
        </VideoContainer>

        <Overlay
          visible={overlayVisible || controlHover}
          onMouseOver={() => onControlHover(true)}
          onMouseOut={() => onControlHover(false)}
        >
          {badge && (<Badge badgeColor={badgeColor}>{badge}</Badge>)}
          {isPremium && (
            <NewsPremium size="small">
              <FormattedMessage id="premium.tag" />
            </NewsPremium>
          )}
          {title && <Title>{title}</Title>}

          {showControls && (
            <ControlsBar
              isPaused={state.paused}
              isMuted={state.muted}
              withFullScreen={fullScreenEnabled}
              isFullScreen={isFullScreen}

              currentTime={state.time}
              totalTime={state.duration}

              onPlay={onPlay}
              onPause={onPause}
              onMute={onMute}
              onUnMute={onUnMute}
              onFullScreen={toggleFullScreen}
              onSeek={onSeek}

              withSubtitles={subtitlesLoaded}
              subtitlesVisible={subtitlesLoaded && subtitlesVisible}
              onSubtitlesToggle={onSubtitlesToggle}
            />
          )}
        </Overlay>
      </Container>
      <Modal
        open={isPremiumModalOpened}
        onClose={onClosePremiumModal}
        contentFullWidth={false}
        controls={false}
      >
        <PremiumAccessNotification
          onLogin={onClosePremiumModal}
          onCancel={onClosePremiumModal}
          redirectPath={currentRoute[language]}
        />
      </Modal>
    </>
  );
};

export default VideoPlayer;
