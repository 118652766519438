import { Action } from '@app/types/actionTypes';
import { LocalizedConfiguration } from '@app/types/configurationTypes';
import { ConfigurationActionTypes, SetConfiguration } from '@app/store/actionTypes/configurationActionTypes';

import * as ConfigurationServices from '@app/services/kentico/configurationService';

import { reduxStore } from '@app/store';

import { AppLanguages } from '@app/constants/localizationConstants';

function setConfiguration(configuration: LocalizedConfiguration): SetConfiguration {
  return {
    type: ConfigurationActionTypes.SET_CONFIGURATION,
    payload: configuration,
  };
}

export const getConfiguration = (): Action => (
  async (dispatch): Promise<void> => {
    try {
      const languages = Object.values(AppLanguages);
      const configurationRequests = languages.map((lang) => (
        ConfigurationServices.getInitialConfiguration(lang)
      ));
      const configurations = await Promise.all(configurationRequests);
      const langToConfigMap = languages.reduce((acc, lang, index) => {
        acc[lang] = configurations[index];
        return acc;
      }, {} as LocalizedConfiguration);
      dispatch(setConfiguration(langToConfigMap));
    } catch (e) {
      console.error('Error on fetching configuration', e);
    }
  }
);

export function waitForConfigurationDownload(): Promise<void> {
  const state = reduxStore.store.getState();
  if (state.configuration.isConfigurationDownloadCompleted) return Promise.resolve();

  return new Promise((resolve): void => {
    const unsubscribe = reduxStore.store.subscribe(() => {
      const state = reduxStore.store.getState();

      if (state.configuration.isConfigurationDownloadCompleted) {
        resolve();
        unsubscribe && unsubscribe();
      }
    });
  });
}
