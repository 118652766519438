
import { ContentItem, Elements } from '@kentico/kontent-delivery';
import RelatedMenuItem from '@app/services/kentico/types/related_menu_item';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class WebLineCarousel extends ContentItem {
    public type: Elements.MultipleChoiceElement;
    public background: Elements.MultipleChoiceElement;
    public link: Elements.LinkedItemsElement<RelatedMenuItem>;
    public title: Elements.TextElement;
}
