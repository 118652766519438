import React from 'react';

import { AlgoliaSearchResultHit } from '@app/types/newsTypes';
import { getNewsItemFromHitItem } from '@app/helpers/newsHelpers';

import { ThreeColumnGrid } from '@app/components';
import { NewItem } from '@app/components/News';

interface ResultsListProps {
  hits: AlgoliaSearchResultHit[];
}

const ResultsList = React.memo(({ hits }: ResultsListProps): React.ReactElement => (
  <ThreeColumnGrid>
    {hits?.map((hit) => (
      <NewItem
        data={getNewsItemFromHitItem(hit)}
        imageWidthMap={{ 0: { width: 450, height: 250 } }}
        key={hit.objectID}
      />
    ))}
  </ThreeColumnGrid>
));

export default ResultsList;
