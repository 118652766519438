import { useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getPositionByFilter, LocalizedTeam, Player, PlayerPositionFilter, Team,
} from '@app/types/teamsTypes';
import { LanguageType } from '@app/types/localizationTypes';
import {
  CategoryMenu,
  TeamSubMenuId,
} from '@app/types/configurationTypes';
import { getPlayers } from '@app/store/actions/teamsActions';
import { MountContext } from '@app/ReactContext';

import { useLanguage } from '@app/components/Hooks';
import { TeamSubMenuTypeMap } from '@app/constants/teamsConstants';
import AppState from '@app/types/appStateTypes';
import { findCategoryMenuItemBySlug } from '@app/helpers/menuHelpers';
import { useSecondLevelMenu, useThirdLevelMenu } from '@app/pages/CommonPage/CommonPageHooks';
import { Errors, PageError } from '@app/types/errorTypes';

export function useLocalizedTeam(teamType: TeamSubMenuId): LocalizedTeam | undefined {
  return useSelector<AppState, LocalizedTeam | undefined>(
    (state) => state.teams.teams?.[teamType],
    shallowEqual,
  );
}

export function useTeam(teamType: TeamSubMenuId): Team | undefined {
  const language = useLanguage();
  return useLocalizedTeam(teamType)?.[language];
}

export function getFilter(
  categoryMenu: CategoryMenu,
  locale: LanguageType,
  categoryName?: string,
): PlayerPositionFilter {
  const baseMenuItem = findCategoryMenuItemBySlug(categoryMenu, categoryName ?? '');

  return baseMenuItem?.id as PlayerPositionFilter;
}

export function useTeamSubMenuId(): TeamSubMenuId {
  const subMenuItem = useSecondLevelMenu();
  return subMenuItem?.data.id as TeamSubMenuId;
}

export function usePlayersDownload(): void {
  const { isInitialMount } = useContext(MountContext);
  const language = useLanguage();
  const { topLevel = '', secondLevel = '' } = useParams();
  const teamSubMenuId = useTeamSubMenuId();
  const dispatch = useDispatch();

  const hasItems = useSelector<AppState, boolean>(
    (state) => !!state.teams.teams[teamSubMenuId],
    shallowEqual,
  );

  useEffect(() => {
    if (secondLevel && (!isInitialMount || !hasItems)) {
      // check if data has been loaded on server before very first render
      !hasItems && dispatch(getPlayers({ topLevel, teamType: secondLevel, language }));
    }
  }, [secondLevel, language]);
}

export function useCategoryMenu(): CategoryMenu {
  const subItem = useSecondLevelMenu();
  return subItem?.categories || [];
}

export function useFilteredPlayers(filter: PlayerPositionFilter): Player[] | undefined {
  const { thirdLevel = '' } = useParams();
  const language = useLanguage();
  const teamSubMenuId = useSecondLevelMenu()?.data.id as TeamSubMenuId;
  const categoryMenu = useCategoryMenu();
  const team = useTeam(teamSubMenuId);
  const showOnFilter = getFilter(categoryMenu, language, thirdLevel);
  const type = categoryMenu.find((item) => item.id === filter)?.name;
  return type && [PlayerPositionFilter.All, PlayerPositionFilter.AllWomen,
    PlayerPositionFilter.AllPrimavera, filter].includes(showOnFilter)
    ? team?.filter((player) => player.position === getPositionByFilter(filter)) : [];
}

interface GetCategoryParams {
  position: PlayerPositionFilter;
  categoryMenu: CategoryMenu;
}

export function getCategoryName({ categoryMenu, position }: GetCategoryParams): string {
  return categoryMenu.find((item) => item.id === position)?.name ?? '';
}

export function usePhotoLarge(player?: Player | null): string {
  const teamSubMenuId = useTeamSubMenuId();
  return player?.photoThron?.url || player?.photoLarge?.url || (teamSubMenuId === TeamSubMenuTypeMap.women
    ? '/images/team/default-woman-player.png' : '/images/team/default-man-player.png');
}

export function useError(): Errors {
  return useSelector<AppState, Errors>(
    (state) => state.teams.error,
    shallowEqual,
  );
}

export function usePageValidation(): Errors {
  const error = useError();
  const thirdLevelMenu = useThirdLevelMenu();

  if (error) {
    return error;
  }

  return thirdLevelMenu ? null : PageError.NotFound;
}

export function useIsLoading(): boolean {
  const teamSubMenuId = useSecondLevelMenu()?.data.id as TeamSubMenuId;
  return !useTeam(teamSubMenuId);
}
