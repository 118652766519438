import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { InviolaCatalogItem } from '@app/types/inviolaTypes';
import {
  WebLineCarouselBackground,
  WebLineCarouselType,
  WebTemplateLineCarousel,
  WebTemplateTypes,
} from '@app/types/webTemplateTypes';
import { WebLineCarouselElement } from '@app/components/WebTemplates/elements';
import { ContainerFullWidth, ContainerLarge } from '@app/components/WebTemplates/components';
import { Title } from '@app/pages/Inviola/AwardsPage/components/AwardsElements';
import InviolaWrapper from '@app/components/Inviola/InviolaWrapper';

import { Devices } from '@app/styles';
import { useInviolaPath } from '@app/components/Inviola/InviolaHooks';
import { setCatalogSelectedItemId } from '@app/store/actions/inviolaActions';
import { CommonButton } from '@app/components/Buttons';
import { ItemImage } from './components/CatalogItemComponents';

import RequestItemButton from './components/RequestItemButton';
import FavoritesIcon from './components/FavoritesIcon';
import PointsStatusBar from './components/PointsStatusBar';
import PointsIcon from './components/PointsIcon';

interface InviolaCatalogItemPageProps {
  item: InviolaCatalogItem;
}

const CatalogItemDetails = ({ item }: InviolaCatalogItemPageProps): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { catalogPath } = useInviolaPath();

  const webCarouselData: WebTemplateLineCarousel = {
    id: `${WebTemplateTypes.WebLineCarousel}`,
    type: WebTemplateTypes.WebLineCarousel,
    background: WebLineCarouselBackground.Transparent,
    carouselType: WebLineCarouselType.InviolaCatalog,
    title: '',
    link: null,
  };

  useEffect((): void => { dispatch(setCatalogSelectedItemId(item.id)); }, [item.id]);

  return (
    <InviolaWrapper withoutContainer withAuthCheck={false}>
      <Section>
        <Container>
          <Heading>{item.category.description}</Heading>
          <Row>
            <FavoritesIcon item={item} />
            <PointsIcon points={item.points} />
            <Column>
              <ItemImage src={item.pathImageAbsolute} alt={item.name} />
            </Column>
            <InfoColumn>
              <Name>{ item.name }</Name>
              <Description>{ item.description }</Description>
              <PointsStatusBar itemPoints={item.points} />
              <ButtonContainer>
                <RequestItemButton item={item} />
                <BackToCatalogButton onClick={() => history.push(catalogPath)}>
                  <FormattedMessage id="inviola.catalogDetails.back" />
                </BackToCatalogButton>
              </ButtonContainer>
            </InfoColumn>
          </Row>
        </Container>
      </Section>

      <Section>
        <ContainerLarge>
          <Title><FormattedMessage id="inviola.catalogDetails.suggestionsTitle" /></Title>
        </ContainerLarge>
        <CarouselContainer>
          <WebLineCarouselElement data={webCarouselData} />
        </CarouselContainer>
      </Section>
    </InviolaWrapper>
  );
};

const Section = styled.div`
  padding: 30px 10px;
  background-color: transparent;

  &:first-child {
    background-color: white;
  }

  @media ${Devices.tabletLarge} {
    padding: 50px 10px;
  }
`;

const Heading = styled.h3`
  font-size: 24px;
  letter-spacing: 0.14px;
  font-weight: bold;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  text-transform: uppercase;
  margin-bottom: 10px;

  @media ${Devices.tabletLarge} {
    font-size: 28px;
    margin-bottom: 30px;
  }
`;

const Container = styled.div`
  width: 100%;
  margin: auto;

  @media ${Devices.desktopSmall} {
    max-width: 920px;
    // max-width: 1280px;
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${Devices.tabletLarge} {
    flex-direction: row;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;

  @media ${Devices.tabletLarge} {
    flex: 1 1 50%;
  }
`;

const InfoColumn = styled(Column)`
  text-align: center;
  padding-top: 20px;

  @media ${Devices.tabletLarge} {
    text-align: left;
    padding-top: 0;
    padding-left: 20px;
  }
`;

export const Name = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.14;
  letter-spacing: 1.4px;
  color: #000000;
  margin: 0 0 10px;
  padding: 0;
  cursor: default;
`;

export const Description = styled.p`
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 0 5px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const BackToCatalogButton = styled(CommonButton)`
  border-color: ${(props): string => props.theme.color.red};
  color: ${(props): string => props.theme.color.red};
  height: 30px;
  width: 200px;
  margin: 0 7px;

  &:hover {
    border-color: ${(props): string => props.theme.color.red};
    background-color: ${(props): string => props.theme.color.red};
    color: white;
  }
`;

const CarouselContainer = styled(ContainerFullWidth)`
  & > * {
    margin: 0!important;
    padding: 0!important;
  }
`;

export default CatalogItemDetails;
