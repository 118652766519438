import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { Checkbox } from '@app/components/Inviola/InviolaFields';
import RegistrationWithoutCard from './RegistrationWithoutCard';
import RegistrationWithCard from './RegistrationWithCard';

const RegistrationInit = (): React.ReactElement => {
  const methods = useForm({ mode: 'onChange' });
  const [withCard, setWithCard] = useState(false);

  return (
    <>
      <FormContainer>
        <FormProvider {...methods}>
          <Form>
            <Checkbox
              type="radio"
              name="registrationWith"
              labelId="inviola.registration.withoutCard"
              checked={!withCard}
              onChange={() => setWithCard(false)}
            />
            <Checkbox
              type="radio"
              name="registrationWith"
              labelId="inviola.registration.withCard"
              checked={withCard}
              onChange={() => setWithCard(true)}
            />
          </Form>
        </FormProvider>
      </FormContainer>
      <div>
        { withCard ? <RegistrationWithCard /> : <RegistrationWithoutCard /> }
      </div>
    </>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};

  color: ${(props): string => props.theme.color.purple};
  text-transform: uppercase;
  font-weight: bold;

  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;

  margin-top: -1.785rem;
  padding-bottom: 1.785rem;

  align-items: start;

  @media ${Devices.tabletLarge} {
    justify-content: safe center;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media ${Devices.tabletLarge} {
    justify-content: safe center;
  }

  & *:hover {
    cursor:pointer;
  }
`;

export default RegistrationInit;
