import { LanguageType } from '@app/types/localizationTypes';
import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';
import { convertWebTemplate, convertWebTemplateElement } from '@app/services/kentico/converters/webTemplateConverter';
import { WebLineCarouselType, WebTemplate, WebTemplateData } from '@app/types/webTemplateTypes';
import { kenticoClient } from '@app/services/kentico/kentico';
import MenuItem from '@app/services/kentico/types/menu_item';
import { ContentItem, SortOrder } from '@kentico/kontent-delivery';
import { indexingWebTemplates } from '@app/helpers/webTemplateHelpers';

export async function getWebTemplateData(
  codename: string, language: LanguageType,
): Promise<WebTemplate> {
  if (codename && language) {
    const lang = langCodeToKenticoLangMap[language];
    const response = await kenticoClient()
      .item<MenuItem>(codename)
      .languageParameter(lang)
      .withParameter('system.language', lang)
      .depthParameter(5)
      .toPromise();
    const webTemplate = convertWebTemplate(response.item, response.linkedItems);
    indexingWebTemplates(webTemplate.templateHierarchy);
    return webTemplate;
  }
  return {
    templateHierarchy: [],
  };
}

type GetWebTemplateList = (params: {
  type: WebLineCarouselType;
  skip: number;
  limit: number;
  language: LanguageType;
}) => Promise<WebTemplateData[]>

export const getWebTemplateList: GetWebTemplateList = async (requestParams) => {
  const language = langCodeToKenticoLangMap[requestParams.language];

  const request = kenticoClient()
    .items<ContentItem>()
    .type(requestParams.type)
    .languageParameter(language)
    .equalsFilter('system.language', language)
    .orderParameter('elements.publication_date', SortOrder.desc)
    .limitParameter(requestParams.limit)
    .skipParameter(requestParams.skip);

  const response = await request.toPromise();
  return response.items.map((item) => convertWebTemplateElement(item, item, response.linkedItems));
};
