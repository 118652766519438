import React, { memo, useEffect, useState } from 'react';
import { Helmet, HelmetTags } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import AppRoutes from '@app/constants/routesConstants';
import { NewsType } from '@app/components/News/NewsItemInfo';
import { COVER_IMAGE_TYPE } from '@app/constants/imageConstants';
import { CoverImage } from '@app/components/Image';
import { LiveItem } from '@app/types/configurationTypes';
import { formatRelatedItemLinkProps } from '@app/helpers/menuHelpers';

import { LocalizedLink } from '@app/components/Localization';

interface WebLiveProps {
  data: LiveItem;
  showTitle?: boolean;
  addBottomPadding?: boolean;
}

const WebLiveElement = memo(({
  data, showTitle = true, addBottomPadding = false,
}: WebLiveProps): React.ReactElement | null => {
  const scriptUrl = 'https://acffiorentina-cdn.thron.com/shared/ce/bootstrap/1/scripts/embeds-min.js';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  const [scriptLoaded, setScriptLoaded] = useState(!!Helmet?.peek()?.scriptTags?.find((tag) => tag.src === scriptUrl));
  const [videoReady, setVideoReady] = useState(false);
  const [liveId] = useState(`live${Math.floor((Math.random() * 1000000) + 1)}`);

  const { image, imageVertical, isEnabled, title, cta, videoId, sessionId } = data;
  const link = cta ? formatRelatedItemLinkProps(cta) : {
    pathKey: AppRoutes.Home.path,
  };

  const options = {
    clientId: 'acffiorentina',
    xcontentId: videoId ?? '',
    sessId: sessionId ?? '',
    autoplay: false,
    muted: false,
    poster: '',
  };

  const handleChangeClientState = (newState: HelmetTags, addedTags: HelmetTags) => {
    if (addedTags && addedTags.scriptTags) {
      const foundScript = addedTags.scriptTags.find(({ src }) => src === scriptUrl);
      if (foundScript) {
        foundScript.addEventListener('load', () => setScriptLoaded(true), { once: true });
      }
    } else if (!scriptLoaded) {
      const interval = setInterval(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (typeof THRONContentExperience !== 'undefined') {
          setScriptLoaded(true);
          clearInterval(interval);
        }
      }, 250);
    }
  };

  useEffect(() => {
    if (scriptLoaded) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
      const player = THRONContentExperience(liveId, options);
      const onReady = () => {
        // Stop listening
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        player.off('ready', onReady);
        setVideoReady(true);
      };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      player.on('ready', onReady);
    }
  }, [scriptLoaded]);

  return isEnabled ? (
    <Container addBottomPadding={addBottomPadding}>
      <Helmet onChangeClientState={handleChangeClientState}>
        <script async src={scriptUrl} type="text/javascript" />
      </Helmet>
      <VideoWrapper videoReady={videoReady}>
        <Video id={liveId} />
      </VideoWrapper>
      <CoverImage
        image={image}
        imageVertical={imageVertical}
        coverType={COVER_IMAGE_TYPE.TOP_CAROUSEL_DESKTOP}
        useGradient={false}
      />
      {showTitle && (
        <TitleWrapper {...link}>
          <NewsType size="large">
            <FormattedMessage id="liveItem.label" />
          </NewsType>
          { title && (<Title>{title}</Title>)}
        </TitleWrapper>
      )}
    </Container>
  ) : null;
});

const Container = styled.div<{ addBottomPadding: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0;
  margin-bottom: ${({ addBottomPadding }): string => (addBottomPadding ? '20px' : '0')};
`;

const VideoWrapper = styled.div<{ videoReady: boolean }>`
  display: ${({ videoReady }): string => (videoReady ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
`;

const Video = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;

  /* Player customize */
  &.th-player {
    position: absolute;

    & .th-liveevent,
    & .th-main-container {
      & .th-media-bg-color,
      & .th-media-container.th-media-bg-color {
        background-color: transparent;
      }

      & .th-live-player {
        & .th-video-element {
          display: flex;

          & video {
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            object-fit: cover;
          }
        }

        & .th-poster-element {
          display: none;
          opacity: 0;
          // background-size: cover;
        }
      }
    }
  }
`;

const TitleWrapper = styled(LocalizedLink)`
  position: absolute;
  z-index: 100;
  left: 20px;
  bottom: 60px;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 19px;
  font-weight: bold;
  line-height: 1.46;
  color: #ffffff;
  text-transform: uppercase;

  @media ${Devices.largeMobile} {
    left: 40px;
    bottom: 80px;
    font-size: 23px;
  }

  @media ${Devices.desktop} {
    left: 70px;
    bottom: 130px;
    font-size: 26px;
  }
`;

const Title = styled.div``;

export default WebLiveElement;
