import { useLocation, useRouteMatch } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import AppState from '@app/types/appStateTypes';
import AppRoutes from '@app/constants/routesConstants';
import { SearchCategories, SearchParams, SearchConfig } from '@app/types/newsTypes';
import { setCurrentRoute } from '@app/store/actions/currentRouteActions';
import { useLanguage } from '@app/components/Hooks';

type searchBarVisibilityType = boolean | null;

const useSearchBarVisibilitySelector = (): searchBarVisibilityType => useSelector<AppState, searchBarVisibilityType>(
  (state) => state.search.isSearchBarVisible,
  shallowEqual,
);

export const useSearchPagePath = (): string => {
  const language = useLanguage();
  return `/${language}/news/search`;
};

export const useIsSearchPageValidation = (): boolean => {
  const searchPagePath = useSearchPagePath();

  return Boolean(useRouteMatch({ path: searchPagePath, exact: true }));
};

export const useIsSearchBarVisible = (): boolean | null => {
  const isSearchBarVisible = useSearchBarVisibilitySelector();
  const isSearchPage = useIsSearchPageValidation();

  if (isSearchPage) return true;
  return isSearchBarVisible;
};

export function useSearchMultiLangUrl(): void {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentRoute({
      pathId: AppRoutes.Home.path,
      params: {},
    }));
  }, []);
}

export const useSearchParams = (): SearchParams => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  return {
    category: params.get('category') ?? SearchCategories.ALL,
    query: params.get('query') ?? '',
  };
};

export const useSearchConfig = (): SearchConfig => ({
  appId: process.env.API === 'staging'
    ? 'WO6C7NLP82' // DEV access key
    : 'EIVGQ2Q3N9', // PROD access key
  apiKey: process.env.API === 'staging'
    ? '7852187c693f5f800e0cf0b65cb409f4' // DEV access key
    : '6d31dbb2f7d07370825041a2fda73594', // PROD access key
});

export const useSearchFilters = (): string => {
  const { category } = useSearchParams();

  switch (category) {
    case SearchCategories.ALL: return 'kenticoType:video OR kenticoType:news OR kenticoType:photo_gallery';
    case SearchCategories.NEWS: return 'kenticoType:news';
    case SearchCategories.VIDEO: return 'kenticoType:video';
    case SearchCategories.PHOTO: return 'kenticoType:photo_gallery';
    default: return '';
  }
};

export const useSearchIndex = (): string => {
  const language = useLanguage();

  switch (language) {
    case 'en': return 'kenticoEnglish';
    default: case 'it': return 'kenticoItalian';
  }
};
