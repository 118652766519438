import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { Modal, Loader } from '@app/components';
import { Button, ButtonOutline } from '@app/components/Inviola/InviolaComponents';
import { InviolaErrorMessage, InviolaSuccessMessage } from '@app/components/Inviola/InviolaMessages';

import { InviolaAppError, InviolaAppSuccess } from '@app/services/inviola/types/errorTypes';

import { deleteCustomerProfile, resetErrors, logoutCustomer } from '@app/store/actions/inviolaActions';
import { useSyncInProgressSelector, useSuccess, useError } from '@app/components/Inviola/InviolaHooks';
import { useLanguage } from '@app/components/Hooks';

const DeleteAccountButton = (): React.ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const language = useLanguage();
  const history = useHistory();

  const inviolaSuccess = useSuccess();
  const inviolaError = useError();

  const syncIsComplete = Boolean(inviolaSuccess) || Boolean(inviolaError);
  const syncInProgress = useSyncInProgressSelector();

  const showConfirmation = (): void => {
    setIsModalOpen(true);
    dispatch(resetErrors());
  };

  const finalizeAccountDeactivation = () => setTimeout(() => {
    history.replace(`/${language}`);
    dispatch(logoutCustomer());
  }, 2500);

  if (inviolaSuccess) finalizeAccountDeactivation();

  return (
    <>
      <ButtonOutline type="button" onClick={showConfirmation}>
        <FormattedMessage id="inviola.profile.deactivate.cta" />
      </ButtonOutline>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        disableClose={syncInProgress || Boolean(inviolaSuccess)}
        controls={Boolean(inviolaError)}
        contentFullWidth={false}
      >
        <ModalContainer syncInProgress={syncInProgress}>
          <Title>
            <FormattedMessage id="inviola.profile.deactivate.title" />
          </Title>
          <Text syncIsComplete={syncIsComplete}>
            <FormattedMessage id="inviola.profile.deactivate.text" />
          </Text>

          <ButtonBlock syncIsComplete={syncIsComplete}>
            <Button onClick={() => dispatch(deleteCustomerProfile())}>
              <FormattedMessage id="inviola.catalog.request.yes" />
            </Button>
            <ButtonOutline onClick={() => setIsModalOpen(false)}>
              <FormattedMessage id="inviola.catalog.request.no" />
            </ButtonOutline>
          </ButtonBlock>

          <InviolaSuccessMessage
            type={InviolaAppSuccess.AccountDeleted}
            translationId="inviola.profile.deactivate.success"
          />
          <InviolaErrorMessage errorType={InviolaAppError.DeleteAccountError} />

        </ModalContainer>
        {syncInProgress ? (<Loader overflowMode small />) : (<div />)}
      </Modal>
    </>
  );
};

export default DeleteAccountButton;

const ModalContainer = styled.div<{ syncInProgress: boolean }>`
  position: relative;
  width: 100vw;
  max-width: 95vw;
  margin: auto;
  padding: 20px;

  background-color: ${(props): string => props.theme.color.whiteSmoke};
  color: black;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  text-align: left;

  & > * {
    opacity: ${({ syncInProgress }): number => (syncInProgress ? 0.33 : 1)};
  }

  button {
    margin-bottom: 0;
  }

  @media ${Devices.tablet} {
    width: 450px;
    max-width: 600px;
    padding: 60px 20px 40px;
  }
`;

const Title = styled.p`
  font-size: 1.3rem;
  font-weight: bold;

  text-transform: uppercase;
  text-align: center;
`;

const Text = styled(Title)<{ syncIsComplete: boolean }>`
  display: ${({ syncIsComplete }): string => (syncIsComplete ? 'none' : 'inline-block')};

  font-size: 1.4rem;
  font-weight: normal;
  text-transform: none;


  margin: 20px auto;
`;

const ButtonBlock = styled.div<{ syncIsComplete: boolean }>`
  display: ${({ syncIsComplete }): string => (syncIsComplete ? 'none' : 'flex')};

  flex-direction: row;
  align-items: center;
  padding: 10px;

  @media ${Devices.tablet} {
    flex-direction: row;
  }

  @media print {
    display: none;
  }

  & > * {
    margin: 5px;
  }
`;
