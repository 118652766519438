import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { CommonButton } from '@app/components/Buttons';

interface LoadMoreButtonProps {
  onClick: () => void;
  isVisible: boolean;
  isLoading: boolean;
}

const LoadMoreButton = React.memo(({
  onClick, isLoading, isVisible,
}: LoadMoreButtonProps): React.ReactElement | null => (isVisible ? (
  <LoadButton onClick={onClick} isLoading={isLoading} disabled={isLoading}>
    <FormattedMessage id="buttons.loadingNews" />
  </LoadButton>
) : null));

export default LoadMoreButton;

const LoadButton = styled(CommonButton)<{ isLoading: boolean }>`
  border-color: ${(props): string => props.theme.color.red};
  color: ${(props): string => props.theme.color.red};
  height: 35px;
  margin: 40px auto 0;
  width: auto;
  padding: 0 25px;

  &:hover {
    border-color: ${(props): string => props.theme.color.red};
    background: ${(props): string => props.theme.color.red};
    color: white;
  }
`;
