import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { AppState } from '@app/types/appStateTypes';
import { Menu as MenuInterface } from '@app/types/configurationTypes';

import { disableScrolling, enableScrolling } from '@app/helpers/documentHelper';

import { useLanguage } from '@app/components/Hooks';
import { setMenuState } from '@app/store/actions/menuActions';

export const useMenu = (): MenuInterface | undefined | null => {
  const language = useLanguage();
  return useSelector<AppState, MenuInterface | null>(
    (state) => state.configuration[language]?.menu ?? null,
    shallowEqual,
  );
};

export const useIsMenuOpened = (): boolean => (
  useSelector<AppState, boolean>(
    (state) => state.isOpenedMenu,
    shallowEqual,
  )
);

export const useCloseMenu = (): () => void => {
  const dispatch = useDispatch();
  const isOpened = useIsMenuOpened();

  return () => {
    if (!isOpened) return;
    dispatch(setMenuState(false));
    enableScrolling();
  };
};

export const useToggleMenu = (): () => void => {
  const dispatch = useDispatch();
  const isOpened = useIsMenuOpened();

  return () => {
    dispatch(setMenuState(!isOpened));

    if (!isOpened) {
      disableScrolling();
    } else {
      enableScrolling();
    }
  };
};
