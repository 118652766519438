import React from 'react';
import styled from 'styled-components';

import { CommonButtonLink } from '@app/components/Buttons';
import { InviolaCatalogItem } from '@app/types/inviolaTypes';
import CatalogItem from '@app/pages/Inviola/CatalogPage/components/CatalogItem';

interface InviolaCatalogItemsListProps {
  items: InviolaCatalogItem[];
}

const CatalogItemsList = ({ items = [] }: InviolaCatalogItemsListProps): React.ReactElement => (
  <ItemsContainer>
    { items?.map((item) => (
      <CatalogItem key={item.id} item={item} />
    ))}
  </ItemsContainer>
);

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const MoreInfosButton = styled(CommonButtonLink)`
  border-color: ${(props): string => props.theme.color.red};
  color: ${(props): string => props.theme.color.red};
  height: 30px;
  width: 200px;
  margin: 0 7px;

  &:hover {
    border-color: ${(props): string => props.theme.color.red};
    color: ${(props): string => props.theme.color.red};
  }
`;

export default CatalogItemsList;
