import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import '@app/styles/fonts.css';
import configureStore from '@app/store';
import { App } from '@app/components';
import { PersistGate } from 'redux-persist/integration/react';

const { store, history, persistor } = configureStore();

interface ConditionalPersistGateProps {
  children: React.ReactChild;
}

const ConditionalPersistGate = ({ children }: ConditionalPersistGateProps): JSX.Element => {
  if (!persistor) {
    return <>{children}</>;
  }

  return (
    <PersistGate loading={null} persistor={persistor}>{children}</PersistGate>
  );
};

const Main = () => (
  <Provider store={store}>
    <ConditionalPersistGate>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </ConditionalPersistGate>
  </Provider>
);

ReactDOM.hydrate(<Main />, document.getElementById('root'));

console.log(
  `%cbuild version: ${process.env.COMMIT_HASH}\nbuild time: ${new Date(process.env.BUILD_TIME || Date.now())}`,
  'border: solid 1px blue; padding: 8px; font-weight: bold;',
);
