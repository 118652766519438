import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { RelatedMenuItem } from '@app/types/configurationTypes';
import { WebHomeElementBackground, WebLineCarouselBackground } from '@app/types/webTemplateTypes';
import { HomeTitleLink } from '@app/components/Home/components/index';

interface HomeTitleProps {
  title: string;
  link?: RelatedMenuItem | null;
  showSeeMore?: boolean;
  background?: WebHomeElementBackground | WebLineCarouselBackground;
}

const HomeTitle = ({ title, link, showSeeMore, background }: HomeTitleProps): React.ReactElement => (
  <TitleContainer>
    <Title>
      <HomeTitleLink
        title={title}
        link={link}
        Component={TitleText}
        background={background}
        showSeeMore={showSeeMore}
      />
    </Title>
  </TitleContainer>
);

const TitleContainer = styled.div`
  position: absolute;
  z-index: 5;
  top: -30px;
  left: 0;
  width: 100%;
`;

const Title = styled.div`
  background-color: #ffffff;
  margin: 0 20px;
  height: 50px;
  padding: 0 30px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;

  @media ${Devices.largeMobile} {
    margin: 0 70px;
    height: 60px;
  }
`;

const TitleText = styled.h1<{ background?: WebHomeElementBackground }>`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: -0.01px;
  color: ${(props): string => props.theme.color.purple};
  text-transform: uppercase;

  @media ${Devices.largeMobile} {
    font-size: 18px;
    line-height: 2.35;
  }
`;

export default HomeTitle;
