import React from 'react';
import { useIntl } from 'react-intl';

import Control from './Control';
import ProgressBar from './ProgressBar';

import { ControlsBarContainer } from './VideoComponents';

interface ControlsBarProps {
  isPaused: boolean;
  isMuted: boolean;
  withFullScreen: boolean;
  isFullScreen: boolean;
  withSubtitles: boolean;
  subtitlesVisible: boolean;

  currentTime: number;
  totalTime: number;

  onFullScreen: () => void;
  onPlay: () => void;
  onPause: () => void;
  onMute: () => void;
  onUnMute: () => void;
  onSeek: (time: number) => void;
  onSubtitlesToggle: () => void;
}

const ControlsBar: React.FC<ControlsBarProps> = ({
  isPaused,
  isMuted,
  withFullScreen,
  isFullScreen,
  withSubtitles = false,
  subtitlesVisible,

  currentTime,
  totalTime,

  onFullScreen,
  onPlay,
  onPause,
  onMute,
  onUnMute,
  onSeek,
  onSubtitlesToggle,
}) => {
  const { formatMessage } = useIntl();

  return (
    <ControlsBarContainer>
      <Control
        onClick={isPaused ? onPlay : onPause}
        iconPath={`/images/${isPaused ? 'play' : 'pause'}.svg`}
        altText={formatMessage({ id: `icon.${isPaused ? 'playVideo' : 'pauseVideo'}.alt` })}
      />

      <ProgressBar currentTime={currentTime} totalTime={totalTime} onClick={onSeek} />

      <Control
        onClick={isMuted ? onUnMute : onMute}
        iconPath={`/images/sound-${isMuted ? 'off' : 'on'}.svg`}
        altText={formatMessage({ id: 'icon.toggleVideoSound.alt' })}
      />

      { withSubtitles && (
        <Control
          onClick={onSubtitlesToggle}
          iconPath={`/images/subs-${subtitlesVisible ? 'on' : 'off'}.svg`}
          altText={formatMessage({ id: 'icon.toggleVideoSubtitles.alt' })}
        />
      )}

      { withFullScreen && (
        <Control
          onClick={onFullScreen}
          iconPath={`/images/fullscreen-${isFullScreen ? 'off' : 'on'}.svg`}
          altText={formatMessage({ id: 'icon.enlargeVideo.alt' })}
        />
      )}
    </ControlsBarContainer>
  );
};

export default ControlsBar;
