import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import { FormattedMessage } from 'react-intl';

import { CustomLeftArrow, CustomRightArrow } from '@app/components/Carousel/CarouselArrows';
import { useMaxWidthOfPartialVisibility } from '@app/components/Carousel/CarouselHooks';

import { useWithdrawnAwards, useCarouselConfiguration } from '../AwardsPageHooks';
import { Item, Image, Content, Title, Name, Description, Section } from './AwardsElements';
import AwardModal from './AwardModal';

interface WithdrawnAwardProps {
  id: number;
  name: string;
  barcode: string;
  exchangeDate: string;
  imageUrl: string;
  onGetCode: () => void;
}

const WithdrawnAward = React.memo(({ name, exchangeDate, imageUrl }: WithdrawnAwardProps): React.ReactElement => (
  <Item>
    <Content>
      <Image width="800" height="600" src={imageUrl} />
      <Name>{name}</Name>
      <em>{exchangeDate}</em>
    </Content>
  </Item>
), (prev, next) => (prev.id === next.id));

const WithdrawnAwardsSection = (): React.ReactElement => {
  const [activeAwardId, setActiveAwardId] = useState<number>();
  const withdrawnAwards = useWithdrawnAwards();

  const carouselConfiguration = useCarouselConfiguration();
  const maxWidthOfPartialVisibility = useMaxWidthOfPartialVisibility(carouselConfiguration);

  return (
    <Section>
      <Title><FormattedMessage id="inviola.awards.withdrawned" /></Title>
      {withdrawnAwards?.length ? (
        <Carousel
          swipeable
          partialVisible
          responsive={carouselConfiguration}
          infinite={false}
          keyBoardControl={false}
          customLeftArrow={<CustomLeftArrow mediaQueryMinWidth={maxWidthOfPartialVisibility} />}
          customRightArrow={<CustomRightArrow mediaQueryMinWidth={maxWidthOfPartialVisibility} />}
        >
          {withdrawnAwards?.map((award) => (
            <WithdrawnAward
              key={award.id}
              id={award.id}
              name={award.name}
              exchangeDate={award.exchangeDate}
              barcode={award.barcode}
              imageUrl={award.pathImageAbsolute}

              onGetCode={() => setActiveAwardId(award.id)}
            />
          ))}
        </Carousel>
      ) : (
        <Description><FormattedMessage id="inviola.awards.noItems" /></Description>
      )}
      <AwardModal
        id={activeAwardId}
        onClose={() => setActiveAwardId(undefined)}
      />
    </Section>
  );
};

export default WithdrawnAwardsSection;
