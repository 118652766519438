import { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { ValidationRules } from 'react-hook-form';
import { Coords } from 'google-map-react';
import { useIntl } from 'react-intl';
import { isArray } from 'lodash';

import AppState from '@app/types/appStateTypes';
import { RegistrationCredentials } from '@app/services/inviola/types/requestTypes';
import { InviolaRegistrationSteps, InviolaResetSteps } from '@app/services/inviola/types/stepTypes';
import { InviolaError, InviolaSuccessTypes } from '@app/services/inviola/types/errorTypes';
import { InviolaCustomer, InviolaShop } from '@app/types/inviolaTypes';
import {
  InviolaCountry, InviolaDynamicField, InviolaGeoLevel, InviolaRawCustomerDynamicField,
} from '@app/services/inviola/types/responseTypes';

import { InviolaGender } from '@app/services/inviola/apiConstants';
import { InviolaAllowedCountryIds, InviolaDates, InviolaShopsMapDefaults } from '@app/constants/inviolaConstants';

import { useLanguage } from '@app/components/Hooks/LocalizationHooks';

import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { pathParamsFor, validateFiscalCode } from '@app/helpers/inviolaHelpers';

import { getGeoLevels } from '@app/services/inviola/inviola';
import {
  getShops, getShopsByCategory, getShopsCategories, syncWithInviola, setCustomerRedirectPath,
} from '@app/store/actions/inviolaActions';

export function useError(): InviolaError | undefined {
  return useSelector(
    (state: AppState) => state.inviola.error,
    shallowEqual,
  );
}

export function useSuccess(): InviolaSuccessTypes | undefined {
  return useSelector(
    (state: AppState) => state.inviola.success,
    shallowEqual,
  );
}

interface InviolaPath {
  profilePath: string;
  resetPath: string;
  authPath: string;
  settingsPath: string;
  catalogPath: string;
  awardsPath: string;
}

export const useInviolaPath = (): InviolaPath => {
  const language = useLanguage();

  return {
    profilePath: localizeRouteKey(pathParamsFor('profile'), language),
    resetPath: localizeRouteKey(pathParamsFor('reset'), language),
    authPath: localizeRouteKey(pathParamsFor('login'), language),
    settingsPath: localizeRouteKey(pathParamsFor('profile', 'my-profile'), language),
    catalogPath: localizeRouteKey(pathParamsFor('catalog'), language),
    awardsPath: localizeRouteKey(pathParamsFor('profile', 'my-prizes'), language),
  };
};

export const useInviolaExternalUrl = (): InviolaPath => {
  const language = useLanguage();

  const BASE_URL = 'https://inviola.acffiorentina.com';
  const LANG_URL = language === 'en' ? '/en-us' : '';
  const SERVICE_URL = BASE_URL + LANG_URL;

  return {
    profilePath: `${SERVICE_URL}/account/login`,
    resetPath: `${SERVICE_URL}/account/login`,
    authPath: `${SERVICE_URL}/account/login`,
    settingsPath: `${SERVICE_URL}/account/login`,
    catalogPath: `${SERVICE_URL}/catalog/index`,
    awardsPath: `${SERVICE_URL}/account/login`,
  };
};

export const useCustomerRedirectionPathReset = (): void => {
  const dispatch = useDispatch();

  useEffect(() => (): void => {
    dispatch(setCustomerRedirectPath(null));
  }, []);
};

export const useCustomerRedirectionPath = (): string | null => useSelector(
  (state: AppState) => state.inviola.customerRedirectPath ?? null,
  shallowEqual,
);

export const useIsConnected = (): boolean => {
  const sessionId = useSelector((state: AppState) => state.inviola.customerSession?.id);

  return !!sessionId;
};

export const useLogoutInProgressSelector = (): boolean => useSelector(
  (state: AppState) => state.inviola.logoutSyncInProgress,
);

export const useSyncInProgressSelector = (): boolean => useSelector(
  (state: AppState) => state.inviola.syncInProgress,
);

export const useChangePasswordInProgressSelector = (): boolean => useSelector(
  (state: AppState) => state.inviola.changePasswordInProgress,
);

type InputType = 'email' | 'required' | 'date' | 'fiscal' | 'card' | 'transferCard' | 'points';

export const useInputValidations = (): Record<InputType, ValidationRules> => {
  const { formatMessage } = useIntl();
  const language = useLanguage();

  return {
    email: {
      required: {
        value: true,
        message: formatMessage({ id: 'inviola.form.required' }),
      },
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: formatMessage({ id: 'inviola.form.invalidEmail' }),
      },
    },
    required: {
      required: {
        value: true,
        message: formatMessage({ id: 'inviola.form.required' }),
      },
    },
    date: {
      required: {
        value: true,
        message: formatMessage({ id: 'inviola.form.required' }),
      },
      min: {
        value: InviolaDates.min,
        message: formatMessage({ id: 'inviola.form.minDate' }),
      },
      max: {
        value: InviolaDates.max,
        message: formatMessage({ id: 'inviola.form.maxDate' }),
      },
    },
    fiscal: {
      validate: (code: string) => validateFiscalCode(code, language),
    },
    card: {
      required: {
        value: true,
        message: formatMessage({ id: 'inviola.form.required' }),
      },
      pattern: {
        value: /^[0-9]{12,}$/i,
        message: formatMessage({ id: 'inviola.form.invalidCardNumber' }),
      },
    },
    transferCard: {
      required: {
        value: true,
        message: formatMessage({ id: 'inviola.form.required' }),
      },
      pattern: {
        value: /^[0-9]+$/i,
        message: formatMessage({ id: 'inviola.form.invalidCardNumber' }),
      },
    },
    points: {
      required: {
        value: true,
        message: formatMessage({ id: 'inviola.form.required' }),
      },
      pattern: {
        value: /^[0-9]{4,}$/i,
        message: formatMessage({ id: 'inviola.form.invalidPoints' }),
      },
    },
  };
};

export const useInviolaSync = (): void => {
  const dispatch = useDispatch();

  dispatch(syncWithInviola());
};

interface ResetPasswordProps {
  resetEmail: string | undefined;
  resetStep: InviolaResetSteps;
}

export const useResetPassword = (): ResetPasswordProps => {
  const resetEmail = useSelector((state: AppState) => state.inviola.resetEmail);
  const resetStep = useSelector((state: AppState) => state.inviola.resetStep);

  return {
    resetEmail,
    resetStep,
  };
};

interface RegistrationProps {
  registrationCustomer?: InviolaCustomer;
  registrationCredentials: Partial<RegistrationCredentials> | undefined;
  registrationStep: InviolaRegistrationSteps;
}

export const useRegistration = (): RegistrationProps => ({
  registrationCustomer: useSelector((state: AppState) => state.inviola.registrationCustomer),
  registrationCredentials: useSelector((state: AppState) => state.inviola.registrationCredentials),
  registrationStep: useSelector((state: AppState) => state.inviola.registrationStep)
    ?? InviolaRegistrationSteps.RegistrationInit,
});

export const useRegistrationOptionalFieldsVisibility = (): boolean => {
  const customer = useSelector((state: AppState) => state.inviola.registrationCustomer);

  return (!!customer?.country?.length && customer?.country !== '0')
    || !!customer?.customerDynamicFields?.length
    || !!customer?.gender
    || !!customer?.address
    || !!customer?.addressNumber
    || !!customer?.fidelyCode
    || !!customer?.mobile
    || !!customer?.zip;
};

export const useInviolaCustomer = (): InviolaCustomer | undefined => (
  useSelector<AppState, InviolaCustomer | undefined>((state) => state.inviola.customer)
);

export const useIsCustomerSavedSelector = (): boolean => (
  useSelector<AppState, boolean>((state) => !!state.inviola.customerIsSaved)
);

export const useDynamicField = (id: number): InviolaDynamicField | undefined => (
  useSelector<AppState, InviolaDynamicField | undefined>((state) => state.inviola.dynamicFields?.[id])
);

export const useCustomerDynamicFieldValue = (id: number): InviolaRawCustomerDynamicField['value'] | undefined => {
  // DETECT - AUTHORIZED CUSTOMER OR REGISTRATION WITH CARD CUSTOMER
  const customer = useSelector<AppState, InviolaCustomer | undefined>(
    (state) => state.inviola.customer ?? state.inviola.registrationCustomer,
  );

  return customer?.customerDynamicFields?.find((field) => field.id === id)?.value;
};

export const useCountries = (): InviolaCountry[] | undefined => (
  useSelector<AppState, InviolaCountry[] | undefined>((state) => state.inviola.countries)
);

export const useGeoLevelState = (
  level: number, countryID?: string, parentID?: string,
): [boolean, InviolaGeoLevel[]] => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [geoLevelEntries, setGeoLevelEntries] = useState<InviolaGeoLevel[]>([]);
  const syncSessionId = useSelector((state: AppState) => state.inviola.syncSession?.id);

  useEffect(() => {
    const fetchLevels = async () => {
      if (!syncSessionId) {
        return;
      }

      const levelParentID = level === 1 ? countryID : parentID;
      const allowedCountry = countryID && InviolaAllowedCountryIds.includes(countryID);

      if (!countryID || !allowedCountry || levelParentID === '0') {
        setGeoLevelEntries([]);
        setIsLoaded(true);

        return;
      }

      if (levelParentID) {
        const response = await getGeoLevels(syncSessionId, level, countryID, levelParentID);
        const geoLevels = response.data.geoLevel ?? [];

        if (isArray(geoLevels)) {
          setGeoLevelEntries(geoLevels);
        } else {
          setGeoLevelEntries([geoLevels]);
        }

        setIsLoaded(true);
      }
    };

    // eslint-disable-next-line no-void
    void fetchLevels();
  }, [syncSessionId, countryID, level, parentID]);

  return [isLoaded, geoLevelEntries];
};

interface AvailableGender {
  value: string;
  text: string;
}

export const useGenders = (): AvailableGender[] => {
  const { formatMessage } = useIntl();

  return (Object.values(InviolaGender).map((gender) => ({
    value: gender.toString(),
    text: formatMessage({ id: `inviola.registration.gender.${gender}` }),
  })));
};

export const useUserLocation = (): Coords => {
  const [isLoading, coordinates] = useCurrentPosition();
  const customer = useInviolaCustomer();

  let defaultLocation = InviolaShopsMapDefaults.center;
  if (customer && customer.latitude && customer.longitude) {
    defaultLocation = { lat: customer.latitude, lng: customer.longitude };
  }

  if (isLoading) {
    return defaultLocation;
  }

  return coordinates
    ? { lat: coordinates.lat, lng: coordinates.lng }
    : defaultLocation;
};

export const useShopsDownload = (categoryId: number): void => {
  const dispatch = useDispatch();
  const { lat, lng }: Coords = useUserLocation();

  useEffect(() => {
    switch (categoryId) {
      case -1: dispatch(getShops(lat, lng)); break;
      // case 0: dispatch(getShops(lat, lng)); break; //TODO GET ALL FROM MOBILE
      default: dispatch(getShopsByCategory(categoryId)); break;
    }
  }, [lat, lng, categoryId]);
};

export const useShopsCategoryDownload = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getShopsCategories());
  }, []);
};

export const useShops = (): InviolaShop[] => (
  useSelector((state: AppState) => state.inviola.shops.stores) ?? []
);

export const useShop = (shopId: string | undefined): InviolaShop | undefined => (
  useSelector((state: AppState) => state.inviola.shops.stores?.find((shop) => shop.id === shopId))
);

const useCurrentPosition = (): [boolean, Coords | undefined] => {
  const [isLoading, setLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<Coords>();

  useEffect(() => {
    const { geolocation } = navigator;

    if (!geolocation) {
      return;
    }

    setLoading(true);

    geolocation.getCurrentPosition(
      (location) => {
        const { coords: { latitude, longitude } } = location;

        setCurrentLocation({
          lat: latitude,
          lng: longitude,
        });

        setLoading(false);
      },
      () => {
        setLoading(false);
      },
    );
  }, []);

  return [isLoading, currentLocation];
};

export const usePointsSyncState = (): boolean => (
  useSelector((state: AppState) => state.inviola.pointsSyncInProgress) ?? false
);
