import { ContentItem, Elements } from '@kentico/kontent-delivery';

export default class WebSocial extends ContentItem {
  public socialName: Elements.TextElement;
  public socialIcon: Elements.AssetsElement;
  public socialIconHeader: Elements.AssetsElement;
  public socialIconFooter: Elements.AssetsElement;
  public linkUrl: Elements.TextElement;
  constructor() {
    super({
      propertyResolver: ((elementName: string) => {
        if (elementName === 'social_name') {
          return 'socialName';
        }
        if (elementName === 'social_icon') {
          return 'socialIcon';
        }
        if (elementName === 'social_icon_header') {
          return 'socialIconHeader';
        }
        if (elementName === 'social_icon_footer') {
          return 'socialIconFooter';
        }
        if (elementName === 'link_url') {
          return 'linkUrl';
        }
        return elementName;
      })
    });
  }
}
