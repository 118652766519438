import React from 'react';
import styled from 'styled-components';

import { ThroneAsset } from '@app/types/configurationTypes';
import ThronImage from '../Image/ThronImage';
import { ImageElement } from '../Image/LazyImage';

interface NewsItemProps {
  data: ThroneAsset;
}

const GalleryCarouselItem: React.FC<NewsItemProps> = ({ data }) => (
  <Container>
    <ThronImage
      thronId={data.id}
      alt={data.description}
      scaleMode="none"
    />
  </Container>
);

const Container = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  ${ImageElement} {
    max-height: 600px;
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: contain;
    user-select: none;
  }
`;

export default GalleryCarouselItem;
