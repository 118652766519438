import { sha512 } from 'js-sha512';

import { HttpError } from './types/errorTypes';
import {
  LoginCredentials,
  ResetCredentials,
  ConfirmResetCredentials,
  RegistrationCredentials,
  VerifyCredentials,
  CustomerInformation,
  LoginWithTokenCredentials,
  PrizeRequest,
} from './types/requestTypes';
import {
  InviolaLoginData,
  InviolaResponse,
  InviolaSyncData,
  InviolaMovementsData,
  InviolaModifyData,
  InviolaCountries,
  InviolaGeoLevels,
  InviolaRawDynamicFields,
  InviolaRawAwards,
  InviolaRawWishListItem,
} from './types/responseTypes';

const InviolaConfig = {
  customerServiceUrl: process.env.INVIOLA_CUSTOMER_SERVICE_URL,
  campaignid: process.env.INVIOLA_CAMPAIGN_ID,
  ambiente: process.env.INVIOLA_AMBIENTE,
  terminalserial: process.env.INVIOLA_TERMINAL_SERIAL,
  publicKey: process.env.INVIOLA_PUBLIC_KEY,

  devicetype: 1,
};

const inviolaRequest = async <T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  method: string, az: string, userBody: any, checkResponseCode = true,
): Promise<InviolaResponse<T>> => {
  const { ambiente, terminalserial, devicetype } = InviolaConfig;

  const defaultBody = {
    az,
    ambiente,
    terminalserial,
    devicetype,
  };

  if (!InviolaConfig.customerServiceUrl) {
    throw new Error('No inviola API URL set');
  }

  const response = await fetch(InviolaConfig.customerServiceUrl, {
    method,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      ...defaultBody,
      ...userBody,
    }),
  });

  if (!response.ok) {
    throw new HttpError(response.status, response.statusText);
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const body: InviolaResponse<T> = await response.json();
  if (checkResponseCode && (body.returncode !== 0 && body.returncode !== '0')) {
    throw new HttpError(body.returncode as number, response.statusText);
  }
  return body;
};

export const login = async (user: LoginCredentials): Promise<InviolaResponse<InviolaLoginData>> => (
  inviolaRequest('POST', 'login', { ...user, campaignid: InviolaConfig.campaignid })
);

export const loginWithToken = async (
  syncSessionId: string, rememberToken: string,
): Promise<InviolaResponse<InviolaModifyData>> => (
  inviolaRequest('POST', 'tokenbasedlogin', {
    sessionid: syncSessionId,
    ...buildLoginWithTokenCredentials(rememberToken),
  })
);

export const sync = async (): Promise<InviolaResponse<InviolaSyncData>> => (
  inviolaRequest('POST', 'synchro', { campaignid: InviolaConfig.campaignid })
);

export const logout = async (customerSessionId: string): Promise<InviolaResponse<Response>> => (
  inviolaRequest('POST', 'logout', { sessionid: customerSessionId }, false)
);

export const resetPassword = async (reset: ResetCredentials): Promise<InviolaResponse<void>> => (
  inviolaRequest('POST', 'generaterecoverycode', reset)
);

export const confirmResetPassword = async (reset: ConfirmResetCredentials): Promise<InviolaResponse<void>> => (
  inviolaRequest('POST', 'changepasswordbyrecovery', reset)
);

export const resendResetCode = async (reset: ResetCredentials): Promise<InviolaResponse<void>> => (
  inviolaRequest('POST', 'resendrecoverycodetoemail', reset)
);

export const verifyCustomerByEmail = async (credentials: VerifyCredentials): Promise<InviolaResponse<void>> => (
  inviolaRequest('POST', 'verifyemail', { ...credentials, campaignid: InviolaConfig.campaignid })
);

export const registerWithCredentials = async (credentials: RegistrationCredentials): Promise<InviolaResponse<void>> => (
  inviolaRequest('POST', 'registrationvcwithverification', { ...credentials, campaignid: InviolaConfig.campaignid })
);

export const getCustomer = async (customerSessionId: string): Promise<InviolaResponse<InviolaLoginData>> => (
  inviolaRequest('POST', 'getcustomer', {
    session: customerSessionId,
  })
);

export const modifyCustomer = async (
  customerSessionId: string, customer: CustomerInformation,
): Promise<InviolaResponse<InviolaModifyData>> => (
  inviolaRequest('POST', 'modifycustomer', {
    ...customer,
    session: customerSessionId,
  })
);

export const deleteCustomer = async (
  customerSessionId: string, customer: CustomerInformation,
): Promise<InviolaResponse<InviolaModifyData>> => (
  inviolaRequest('POST', 'removecustomer', {
    ...customer,
    session: customerSessionId,
    campaignid: InviolaConfig.campaignid,
  })
);

export const getMovements = async (
  customerSessionId: string, rowLimit = 50,
): Promise<InviolaResponse<InviolaMovementsData>> => (
  inviolaRequest('POST', 'getmovements', {
    session: customerSessionId,
    initlimit: 0,
    rowcount: rowLimit,
  })
);

export const getDynamicFields = async (
  syncSessionId: string, rowLimit = 500,
): Promise<InviolaResponse<InviolaRawDynamicFields>> => (
  inviolaRequest('POST', 'getdynamicfields', {
    session: syncSessionId,
    initlimit: 0,
    rowcount: rowLimit,
  })
);

export const getCountries = async (
  syncSessionId: string, rowLimit = 500,
): Promise<InviolaResponse<InviolaCountries>> => (
  inviolaRequest('POST', 'getcountries', {
    session: syncSessionId,
    initlimit: 0,
    rowcount: rowLimit,
  })
);

export const getGeoLevels = async (
  syncSessionId: string, level: number, countryid: string, fatherid: string, rowLimit = 500,
): Promise<InviolaResponse<InviolaGeoLevels>> => (
  inviolaRequest('POST', 'getgeolevels', {
    session: syncSessionId,
    level,
    fatherid,
    countryid,
    initlimit: 0,
    rowcount: rowLimit,
  })
);

export const addCatalogItemToWishList = async (
  customerSessionId: string, customerId: number, catalogId: number, prizeId: number,
): Promise<InviolaResponse<boolean>> => (
  inviolaRequest('POST', 'insertwishlist', {
    session: customerSessionId,
    customer_id: customerId,
    catalog_id: catalogId,
    prize_id: prizeId,
  })
);

export const removeCatalogItemFromWishList = async (
  customerSessionId: string, customerId: number, catalogId: number, prizeId: number,
): Promise<InviolaResponse<boolean>> => (
  inviolaRequest('POST', 'removewishlist', {
    session: customerSessionId,
    customer_id: customerId,
    catalog_id: catalogId,
    prize_id: prizeId,
  })
);

export const fetchCatalogItemsFromWishList = async (
  customerSessionId: string, customerId: number, catalogId: number,
): Promise<InviolaResponse<InviolaRawWishListItem[]>> => (
  inviolaRequest('POST', 'getwislistprize', {
    session: customerSessionId,
    customer_id: customerId,
    catalog_id: catalogId,
  })
);

export const modifyPassword = async (
  customerSessionId: string, password: string,
): Promise<InviolaResponse<void>> => (
  inviolaRequest('POST', 'changepassword', {
    session: customerSessionId,
    password,
  })
);

export const getExchangedPrizes = async (
  customerSessionId: string, rowLimit = 50,
): Promise<InviolaResponse<InviolaRawAwards>> => (
  inviolaRequest('POST', 'getexchangedprizes', {
    session: customerSessionId,
    initlimit: 0,
    rowcount: rowLimit,
  })
);

export const getNotExchangedPrizes = async (
  customerSessionId: string, rowLimit = 50,
): Promise<InviolaResponse<InviolaRawAwards>> => (
  inviolaRequest('POST', 'getnotexchangedprizes', {
    session: customerSessionId,
    initlimit: 0,
    rowcount: rowLimit,
  })
);

export const exchangePrize = async (
  customerSessionId: string, prizeRequest: PrizeRequest,
): Promise<InviolaResponse<InviolaRawAwards>> => (
  inviolaRequest('POST', 'exchangeprizes', {
    session: customerSessionId,
    prizeid: prizeRequest.prizeId,
    quantity: prizeRequest.quantity ?? 1,
    catalogid: prizeRequest.catalogId,
  })
);

const buildLoginWithTokenCredentials = (
  rememberToken: string,
): LoginWithTokenCredentials => {
  const currentTime = new Date().toISOString().slice(0, -5);
  const hash = buildTokenHash(rememberToken, currentTime);

  return {
    token: rememberToken,
    datetime: currentTime,
    hash,
  };
};

const buildTokenHash = (token: string, currentTime: string): string => {
  const payload = [token, currentTime.replace('T', ' '), InviolaConfig.publicKey].join('|');

  return sha512(payload);
};
