import React from 'react';

import { InviolaRegistrationSteps } from '@app/services/inviola/types/stepTypes';
import { useRegistration } from '@app/components/Inviola/InviolaHooks';

import RegistrationConfirmation from './RegistrationConfirmation';
import RegistrationSuccess from './RegistrationSuccess';
import RegistrationInit from './RegistrationInit';
import RegistrationWithoutCard from './RegistrationWithoutCard';

const registrationStepTypes: Record<InviolaRegistrationSteps, React.FunctionComponent<unknown>> = {
  [InviolaRegistrationSteps.RegistrationInit]: RegistrationInit,
  // CASE 1: CARD WITH LINKED EMAIL
  [InviolaRegistrationSteps.RegistrationCardWithEmailConfirmation]: RegistrationConfirmation,
  [InviolaRegistrationSteps.RegistrationCardWithEmailValidated]: RegistrationWithoutCard,
  // CASE 2: CARD WITH NO LINKED EMAIL
  [InviolaRegistrationSteps.RegistrationCardWithoutEmailValidated]: RegistrationWithoutCard,
  [InviolaRegistrationSteps.RegistrationCardWithoutEmailConfirmation]: RegistrationConfirmation,
  // CASE 3: NO CARD
  [InviolaRegistrationSteps.RegistrationWithoutCardConfirmation]: RegistrationConfirmation,
  // SUCCESS screen (not visible in auto-login was correct)
  [InviolaRegistrationSteps.RegistrationSuccess]: RegistrationSuccess,
};

const RegistrationWizard = (): React.ReactElement => {
  const { registrationStep } = useRegistration();
  const ActiveRegistrationStep = registrationStepTypes[registrationStep];

  return (<ActiveRegistrationStep />);
};

export default RegistrationWizard;
