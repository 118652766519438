import { ContentItem, Elements, IContentItemsContainer } from '@kentico/kontent-delivery';
import {
  WebCardLayoutSize,
  WebCardLayoutType,
  WebCardOptions,
  WebPageImageSizeType,
  WebTemplate,
  WebTemplateCard,
  WebTemplateData,
  WebTemplateDepartment,
  WebTemplateLanding,
  WebTemplatePageImage,
  WebTemplatePageTitle,
  WebTemplatePartner,
  WebTemplateShare,
  WebTemplateTypes,
  WebTemplateCarousel,
  WebTemplateCarouselItem,
  WebTemplateCtaButton,
  WebCtaButtonTemplateType,
  WebTemplateCarouselItemContentTypes,
  WebTemplateHomeElement,
  WebHomeElementType,
  WebHomeElementBackground,
  WebEditorial as WebTemplateEditorial,
  WebTemplateEditorialDuo,
  WebGoogleMaps,
  WebTemplateCtaDuoButton,
  WebTemplatePerson,
  WebTemplatePageVideo,
  WebPageVideoSize,
  WebTabsTemplateElement,
  WebTabItemTemplateElement,
  WebIframeTemplateElement,
  WebTemplatePDF,
  WebTempalteCtaButtonQuatro,
  WebTemplateLiveVideo,
  WebTemplateAdvertisementBanner,
  WebTemplateMatchbox,
  WebCardCtaPosition,
  WebLandingSize,
  WebLandingType,
  WebTemplateInviolaMembershipCard,
  WebTemplateLineCarousel, WebLineCarouselBackground, WebLineCarouselType, WebEmbedded,
} from '@app/types/webTemplateTypes';
import { WebPageImage } from '@app/services/kentico/types/web_page_image';
import { WebPageTitle } from '@app/services/kentico/types/web_page_title';
import {
  BaseMenuItem, ExternalLinkMenuItem, Image, MenuItemTypes, RelatedMenuItem,
} from '@app/types/configurationTypes';
import { WebLanding } from '@app/services/kentico/types/web_landing';
import { WebCard } from '@app/services/kentico/types/web_card';
import {
  mapBaseMenuItem,
  mapExternalLink,
  mapRelatedItem,
  mapSeoData,
  mapThronData,
} from '@app/services/kentico/converters/configurationConverters';
import KenticoRelatedMenuItem from '@app/services/kentico/types/related_menu_item';
import MenuItem from '@app/services/kentico/types/menu_item';
import WebDepartment from '@app/services/kentico/types/web_department';
import WebPartner from '@app/services/kentico/types/web_partner';
import WebCarousel from '@app/services/kentico/types/web_carousel';
import WebCarouselItem from '@app/services/kentico/types/web_carousel_item';
import { WebHomeElement } from '@app/services/kentico/types/web_home_element';
import {
  linkedItems as linkedItemsType,
  mapBaseNewsItem,
  mapEditorialContent,
  mapVideoNoSeo,
} from '@app/services/kentico/converters/newsConverters';
import News from '@app/services/kentico/types/news';
import { BaseNewsItem, Video as BaseVideo } from '@app/types/newsTypes';
import { GOOGLE_MAPS_ZOOM } from '@app/constants/googleMapsConstants';
import ExternalLink from '@app/services/kentico/types/external_link';
import { WebEditorial } from '@app/services/kentico/types/web_editorial';
import { WebEditorialDuo } from '@app/services/kentico/types/web_editorial_duo';
import WebPerson from '@app/services/kentico/types/web_person';
import CtaButton from '@app/services/kentico/types/cta_button';
import { WebCtaDuo } from '@app/services/kentico/types/web_cta_duo';
import { ImageThron } from '@app/services/kentico/types/image_thron';
import { WebPageVideo } from '@app/services/kentico/types/web_page_video';
import { WebTabs } from '@app/services/kentico/types/web_tabs';
import { WebTabItem } from '@app/services/kentico/types/web_tab_item';
import { KenticoLangToLangCodeMap } from '@app/constants/localizationConstants';
import { WebIframe } from '@app/services/kentico/types/web_iframe';
import Video from '@app/services/kentico/types/video';
import { Pdf } from '@app/services/kentico/types/pdf';
import { WebCtaQuatro } from '@app/services/kentico/types/web_cta_quatro';
import PhotoGallery from '@app/services/kentico/types/photo_gallery';
import { WebAdvertisementBanner } from '@app/services/kentico/types/web_advertisement_banner';
import { WebMatchbox } from '@app/services/kentico/types/web_matchbox';
import { WebInviolaMembershipCard } from '@app/services/kentico/types/web_inviola_membership_card';
import { WebLineCarousel } from '@app/services/kentico/types/web_line_carousel';
import { Promotion as KenticoPromotion } from '@app/services/kentico/types/promotion';
import { Embedded } from '@app/services/kentico/types/embedded';
import { Promotion } from '@app/types/promotionsTypes';
import format from 'date-fns/format';
import { utcToZonedTime } from 'date-fns-tz';
import { convertGames } from './gamesConverter';

interface ConvertWebTemplateOptions {
  options?: WebCardOptions;
  filter?: (value: WebTemplateData) => boolean;
}

type ConvertWebTemplateElement = (
  data: ContentItem,
  value: ContentItem,
  linkedItems: IContentItemsContainer,
  options?: ConvertWebTemplateOptions,
) => WebTemplateData | null

function convertImage(asset: Elements.AssetsElement): Image {
  return {
    url: asset?.value?.[0]?.url ?? '',
    description: asset?.value?.[0]?.description ?? '',
  };
}

export const convertWebPageImage: ConvertWebTemplateElement = (
  data: ContentItem, value: ContentItem,
): WebTemplatePageImage => {
  const element = value as WebPageImage;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const imagesThron = JSON.parse(element?.thronImageGallery?.value ?? '[]') as ImageThron[];

  return {
    type: WebTemplateTypes.WebPageImage,
    id: value?.system?.codename ?? '',
    image: imagesThron[0],
    size: (element?.imageSize?.value?.[0]?.codename ?? '') as WebPageImageSizeType,
  };
};

const convertWebPageVideo: ConvertWebTemplateElement = (
  data: ContentItem, value: ContentItem,
): WebTemplatePageVideo => {
  const element = value as WebPageVideo;
  return {
    type: WebTemplateTypes.WebPageVideo,
    id: value?.system?.codename ?? '',
    video: mapThronData(element?.thronVideoThron),
    size: (element?.videoSize?.value?.[0]?.codename ?? '') as WebPageVideoSize,
  };
};

const convertWebPageTitle: ConvertWebTemplateElement = (
  data: ContentItem, value: ContentItem,
): WebTemplatePageTitle => {
  const element = value as WebPageTitle;
  return {
    type: WebTemplateTypes.WebPageTitle,
    id: value?.system?.codename ?? '',
    title: element?.pageTitle?.value ?? '',
    subTitle: element?.pageSubtitle?.value ?? '',
  };
};

const convertWebLanding: ConvertWebTemplateElement = (data, value, linkedItems): WebTemplateLanding => {
  const element = value as WebLanding;
  return {
    type: WebTemplateTypes.WebLanding,
    id: value?.system?.codename ?? '',
    name: element?.name?.value ?? '',
    isVisible: (element?.isVisible?.value[0]?.name ?? '').toLowerCase() === 'true',
    content: element?.content?.value?.map((value) => convertWebTemplateElement(data, value, linkedItems)),
    url: element?.webUrl?.value ?? '',
    size: (element?.size?.value?.[0]?.name ?? '') as WebLandingSize,
    layoutType: (element?.type?.value?.[0]?.name ?? '') as WebLandingType,
  };
};

const formatButtonMenuItem = (data: ContentItem): BaseMenuItem | RelatedMenuItem | ExternalLinkMenuItem | null => {
  const actionType = data?.system?.type ?? '';

  switch (actionType) {
    case MenuItemTypes.ExternalLink:
      return mapExternalLink(data as ExternalLink);
    case MenuItemTypes.BaseMenuItem:
      return mapBaseMenuItem(data as MenuItem);
    case MenuItemTypes.RelatedMenuItem:
      return mapRelatedItem(data as KenticoRelatedMenuItem);
    default:
      return null;
  }
};

const convertWebCard: ConvertWebTemplateElement = (
  data, value, options,
): WebTemplateCard => {
  const element = value as WebCard;
  const linkType = element?.link?.value?.[0]?.system?.type;
  return {
    type: WebTemplateTypes.WebCard,
    id: value?.system?.codename ?? '',
    title: element?.title?.value ?? '',
    subTitle: element?.subTitle?.value ?? '',
    layoutSize: (options?.options?.layoutSize ?? element?.layoutSize?.value?.[0]?.name ?? '') as WebCardLayoutSize,
    layoutType: (options?.options?.layoutType ?? element?.layoutType?.value?.[0]?.name ?? '') as WebCardLayoutType,
    ctaPosition: (
      options?.options?.ctaPosition ?? element?.ctaPosition?.value?.[0]?.name ?? WebCardCtaPosition.None
    ) as WebCardCtaPosition,
    backgroundImage: mapThronData(element?.backgroundImage),
    link: linkType === WebTemplateTypes.WebCtaButton
      ? convertWebCtaButton(data, element?.link?.value?.[0])
      : formatButtonMenuItem(element?.link?.value?.[0]),
  };
};

const convertWebShare: ConvertWebTemplateElement = (data, value): WebTemplateShare => ({
  type: WebTemplateTypes.WebShare,
  id: value?.system?.codename ?? '',
});

const convertWebPartner: ConvertWebTemplateElement = (data, value): WebTemplatePartner => {
  const element = value as WebPartner;
  return {
    type: WebTemplateTypes.WebPartner,
    id: value?.system?.codename ?? '',
    title: element?.title?.value ?? '',
    description: element?.description?.value ?? '',
    photo: convertImage(element?.photoLarge),
    redirectUrl: element?.redirectUrl?.value ?? '',
  };
};

export const convertWebDepartment: ConvertWebTemplateElement = (
  data, value, linkedItems,
): WebTemplateDepartment => {
  const element = value as WebDepartment;
  return {
    type: WebTemplateTypes.WebDepartment,
    id: value?.system?.codename ?? '',
    title: element?.title?.value ?? '',
    name: element?.name?.value ?? '',
    showDivider: (element?.showDivider?.value?.[0]?.name ?? '').toLowerCase() === 'true',
    content: element?.content?.value?.map((value) => convertWebTemplateElement(data, value, linkedItems)),
  };
};

const convertWebCarouselItem: ConvertWebTemplateElement = (
  data, value, linkedItems,
): WebTemplateCarouselItem => {
  const element = value as WebCarouselItem;
  return {
    type: WebTemplateTypes.WebCarouselItem,
    id: value?.system?.codename ?? '',
    content: element?.content?.value?.map(
      (value) => convertWebTemplateElement(data, value, linkedItems) as WebTemplateCarouselItemContentTypes,
    ),
  };
};

export const convertWebCarousel: ConvertWebTemplateElement = (
  data, value, linkedItems,
): WebTemplateCarousel => {
  const element = value as WebCarousel;
  const link = element?.link?.value?.[0];
  return {
    type: WebTemplateTypes.WebCarousel,
    id: value?.system?.codename ?? '',
    title: element?.title?.value ?? '',
    items: element?.items?.value?.map(
      (value) => convertWebCarouselItem(data, value, linkedItems) as WebTemplateCarouselItem
    ),
    link: link ? mapRelatedItem(link) : null,
  };
};

export const convertWebHomeElement: ConvertWebTemplateElement = (
  data, value,
): WebTemplateHomeElement => {
  const element = value as WebHomeElement;
  const link = element?.link?.value?.[0];
  return {
    type: WebTemplateTypes.WebHomeElement,
    id: value?.system?.codename ?? '',
    title: element?.title?.value ?? '',
    elementType: (element?.type?.value?.[0]?.codename ?? '') as WebHomeElementType,
    background: (element?.background?.value?.[0]?.codename ?? '') as WebHomeElementBackground,
    link: link ? mapRelatedItem(link) : null,
    category: element?.editorialCategories?.value?.[0]?.codename ?? '',
  };
};

export const convertNewsElement: ConvertWebTemplateElement = (
  data, value,
): BaseNewsItem => {
  const element = value as News;
  return mapBaseNewsItem(element);
};

export const convertVideoElement: ConvertWebTemplateElement = (
  data, value,
): BaseVideo => {
  const element = value as Video;
  return mapVideoNoSeo(element);
};

export const convertPhotoGalleryElement: ConvertWebTemplateElement = (
  data, value,
): BaseNewsItem => {
  const element = value as PhotoGallery;
  return mapBaseNewsItem(element);
};

const convertWebEditorial: ConvertWebTemplateElement = (data, value, linkedItems): WebTemplateEditorial => {
  const element = value as WebEditorial;

  return ({
    type: WebTemplateTypes.WebEditorial,
    id: value?.system?.codename ?? '',
    content: mapEditorialContent(element?.content?.value ?? '', linkedItems as IContentItemsContainer<linkedItemsType>),
  });
};

const convertWebEditorialDuo = (data: ContentItem, value: ContentItem): WebTemplateEditorialDuo => {
  const element = value as WebEditorialDuo;

  return ({
    id: value?.system?.codename ?? '',
    type: WebTemplateTypes.WebEditorialDuo,
    isContentFirst: !!element?.displayOrder?.value?.[0]?.codename,
    content: element?.content?.value ?? '',
    image: convertImage(value?.image),
  });
};

export const convertWebCtaButton = (data: ContentItem, value: ContentItem): WebTemplateCtaButton => {
  const element = value as CtaButton;
  const dataToFormat = element?.actionType?.value?.[0];
  return {
    type: WebTemplateTypes.WebCtaButton,
    id: value?.system?.codename ?? '',
    label: element?.label?.value ?? '',
    template: (element?.uiTemplate?.value?.[0]?.name ?? '').toLowerCase() as WebCtaButtonTemplateType,
    menuItem: formatButtonMenuItem(dataToFormat),
  };
};

export const convertWebPerson: ConvertWebTemplateElement = (
  data, value,
): WebTemplatePerson => {
  const element = value as WebPerson;

  return ({
    type: WebTemplateTypes.WebPerson,
    id: value?.system?.codename ?? '',
    firstName: element?.firstName?.value ?? '',
    lastName: element?.lastName?.value ?? '',
    position: element?.position?.value ?? '',
    quote: element?.quote?.value ?? '',
    photo: convertImage(element?.photoLarge),
    resume: element?.resume?.value?.[0]?.url ?? '',
    url: element?.url?.value ?? '',
  });
};

export const convertWebPDF: ConvertWebTemplateElement = (
  data, value,
): WebTemplatePDF => {
  const element = value as Pdf;

  return ({
    id: value?.system?.codename ?? '',
    type: WebTemplateTypes.PDF,
    pdf: convertImage(element?.pdf),
  });
};

export const convertWebTemplateCtaDuoButton = (data: ContentItem, value: ContentItem): WebTemplateCtaDuoButton => {
  const element = value as WebCtaDuo;

  return ({
    id: value?.system?.codename ?? '',
    type: WebTemplateTypes.WebCtaDuoButton,
    content: element?.cta?.value?.map((cta: ContentItem) => convertWebCtaButton(data, cta)),
  });
};

// TODO: to impl gmaps shape?
const convertWebGoogleMaps = (data: ContentItem, value: ContentItem): WebGoogleMaps => ({
  type: WebTemplateTypes.WebGoogleMaps,
  id: value?.system?.codename ?? '',
  map: {
    zoom: GOOGLE_MAPS_ZOOM,
    position: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      lat: Number(value?.latitude?.value ?? ''),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      lng: Number(value?.longitude?.value ?? ''),
    },
  },
});

const convertWebTabs = (
  data: ContentItem, value: ContentItem, linkedItems: IContentItemsContainer,
): WebTabsTemplateElement => {
  const element = value as WebTabs;

  return {
    type: WebTemplateTypes.WebTabs,
    id: value?.system?.codename ?? '',
    tabs: element?.tabs?.value?.map(
      (tab) => convertWebTemplateElement(data, tab, linkedItems) as WebTabItemTemplateElement
    ) ?? [],
  };
};

const convertWebTabItem = (
  data: ContentItem, value: ContentItem, linkedItems: IContentItemsContainer,
): WebTabItemTemplateElement => {
  const element = value as WebTabItem;

  return {
    type: WebTemplateTypes.WebTabItem,
    id: element?.system?.codename ?? '',
    name: element?.name?.value ?? '',
    isVisible: (element?.isVisible?.value?.[0]?.name ?? '').toLowerCase() === 'true',
    url: element?.url?.value ?? '',
    content: element?.content?.value?.map((content) => convertWebTemplateElement(data, content, linkedItems)),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    ...mapSeoData(element, KenticoLangToLangCodeMap[element?.system?.language]),
  };
};

const convertWebIframe = (data: ContentItem, value: ContentItem): WebIframeTemplateElement => {
  const element = value as WebIframe;

  return {
    id: element?.system?.id,
    type: WebTemplateTypes.WebIframe,
    name: element?.iframe?.name,
    iframeInnerHtml: element?.iframe?.value,
  };
};

const convertWebCtaButtonQuatro = (data: ContentItem, value: ContentItem): WebTempalteCtaButtonQuatro => {
  const element = value as WebCtaQuatro;

  return {
    id: element?.system?.id,
    type: WebTemplateTypes.WebCtaButtonQuatro,
    buttons: element?.cta?.value?.map((button: ContentItem) => convertWebCtaButton(data, button)) ?? [],
  };
};

const convertWebLiveVideo = (data: ContentItem, value: ContentItem): WebTemplateLiveVideo => {
  const element = value as WebCtaQuatro;

  return {
    id: element?.system?.id,
    type: WebTemplateTypes.WebLiveVideo,
  };
};

export const convertWebAdvertisementBanner = (
  data: ContentItem, value: ContentItem,
): WebTemplateAdvertisementBanner => {
  const element = value as WebAdvertisementBanner;

  return {
    id: element?.system?.id,
    type: WebTemplateTypes.WebAdvertisementBanner,
    desktopBannerImage: convertImage(element?.desktopBannerImage),
    mobileBannerImage: convertImage(element?.mobileBannerImage),
    url: element?.url?.value,
  };
};

export const convertWebMatchbox = (
  data: ContentItem, value: ContentItem,
): WebTemplateMatchbox => {
  const element = value as WebMatchbox;
  return {
    id: element?.system?.codename ?? element?.system?.id,
    type: WebTemplateTypes.WebMatchbox,
    games: convertGames(element?.games?.value),
  };
};

export const convertWebInviolaMembershipCard = (
  data: ContentItem, value: ContentItem,
): WebTemplateInviolaMembershipCard => {
  const element = value as WebInviolaMembershipCard;
  return {
    id: element?.system?.codename ?? element?.system?.id,
    type: WebTemplateTypes.WebInviolaMembershipCard,
    title: element?.title?.value ?? '',
    price: element?.price?.value ?? '',
    description: (element?.description?.value ?? '').split('\n').join('<br />'),
    cardImage: convertImage(element?.cardImage),
    ctaButton: convertWebCtaButton(data, element?.ctaButton?.value?.[0]),
  };
};

export const convertWebLineCarousel = (
  data: ContentItem, value: ContentItem,
): WebTemplateLineCarousel => {
  const element = value as WebLineCarousel;
  const link = element?.link?.value?.[0];
  return {
    id: element?.system?.codename ?? element?.system?.id,
    type: WebTemplateTypes.WebLineCarousel,
    title: element?.title?.value ?? '',
    background: (element?.background?.value?.[0]?.codename ?? '') as WebLineCarouselBackground,
    carouselType: (element?.type?.value?.[0]?.codename ?? '') as WebLineCarouselType,
    link: link ? mapRelatedItem(link) : null,
  };
};

export const convertWebEmbedded = (
  data: ContentItem, value: ContentItem,
): WebEmbedded => {
  const element = value as Embedded;
  return {
    id: element?.system?.codename ?? element?.system?.id,
    type: WebTemplateTypes.WebEmbedded,
    content: encodeURI(element?.html?.value ?? ''),
  };
};

export const convertPromotion = (
  data: ContentItem, value: ContentItem, linkedItems: IContentItemsContainer,
): Promotion => {
  const element = value as KenticoPromotion;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const mainImageThron = JSON.parse(element?.mainImage?.value ?? '[]') as ImageThron[];
  const thumbnailThron = JSON.parse(element?.thumbnail?.value ?? '[]') as ImageThron[];

  return {
    id: element?.system?.codename ?? element?.system?.id,
    systemId: element?.system?.id ?? '',
    type: WebTemplateTypes.Promotion,
    title: element?.title?.value ?? '',
    content: linkedItems ? mapEditorialContent(element?.content?.value ?? '', linkedItems as IContentItemsContainer<linkedItemsType>) : [],
    mainImage: mainImageThron?.[0],
    thumbnail: thumbnailThron?.[0],
    urlSlug: element?.url?.value ?? '',
    publicationDate: format(
      utcToZonedTime(element?.publicationDate?.value ?? 0, 'Europe/London'), 'yyyy-MM-dd',
    ),
    expirationDate: format(
      utcToZonedTime(element?.expirationDate?.value ?? 0, 'Europe/London'), 'yyyy-MM-dd',
    ),
    ...mapSeoData(element, KenticoLangToLangCodeMap[element?.system?.language], true),
  };
};

const converters: {[propName: string]: ConvertWebTemplateElement} = {
  [WebTemplateTypes.WebPageImage]: convertWebPageImage,
  [WebTemplateTypes.WebPageTitle]: convertWebPageTitle,
  [WebTemplateTypes.WebLanding]: convertWebLanding,
  [WebTemplateTypes.WebCard]: convertWebCard,
  [WebTemplateTypes.WebShare]: convertWebShare,
  [WebTemplateTypes.WebPartner]: convertWebPartner,
  [WebTemplateTypes.WebDepartment]: convertWebDepartment,
  [WebTemplateTypes.WebCarousel]: convertWebCarousel,
  [WebTemplateTypes.WebHomeElement]: convertWebHomeElement,
  [WebTemplateTypes.News]: convertNewsElement,
  [WebTemplateTypes.Video]: convertVideoElement,
  [WebTemplateTypes.PhotoGallery]: convertPhotoGalleryElement,
  [WebTemplateTypes.WebCtaButton]: convertWebCtaButton,
  [WebTemplateTypes.WebPerson]: convertWebPerson,
  [WebTemplateTypes.WebEditorial]: convertWebEditorial,
  [WebTemplateTypes.WebEditorialDuo]: convertWebEditorialDuo,
  [WebTemplateTypes.WebCtaButton]: convertWebCtaButton,
  [WebTemplateTypes.WebCtaDuoButton]: convertWebTemplateCtaDuoButton,
  [WebTemplateTypes.WebGoogleMaps]: convertWebGoogleMaps,
  [WebTemplateTypes.WebPageVideo]: convertWebPageVideo,
  [WebTemplateTypes.WebTabs]: convertWebTabs,
  [WebTemplateTypes.WebTabItem]: convertWebTabItem,
  [WebTemplateTypes.WebIframe]: convertWebIframe,
  [WebTemplateTypes.PDF]: convertWebPDF,
  [WebTemplateTypes.WebCtaButtonQuatro]: convertWebCtaButtonQuatro,
  [WebTemplateTypes.WebLiveVideo]: convertWebLiveVideo,
  [WebTemplateTypes.WebAdvertisementBanner]: convertWebAdvertisementBanner,
  [WebTemplateTypes.WebMatchbox]: convertWebMatchbox,
  [WebTemplateTypes.WebInviolaMembershipCard]: convertWebInviolaMembershipCard,
  [WebTemplateTypes.WebLineCarousel]: convertWebLineCarousel,
  [WebTemplateTypes.Promotion]: convertPromotion,
  [WebTemplateTypes.WebEmbedded]: convertWebEmbedded,
};

export function convertWebTemplateElement(
  data: ContentItem, value: ContentItem, linkedItems: IContentItemsContainer,
): WebTemplateData {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const converter = converters[value.system.type];

  return converter ? converter(data, value, linkedItems) : null;
}

type ConvertWebTemplate = (
  data: MenuItem,
  linkedItems: IContentItemsContainer,
) => WebTemplate

export const convertWebTemplate: ConvertWebTemplate = (data, linkedItems) => {
  const values = data?.webTemplate?.value || [];
  const templateTitle = data?.name?.value || '';

  return {
    templateHierarchy: values.map((value) => convertWebTemplateElement(data, value, linkedItems)),
    templateTitle,
    newData: [],
  };
};
