import { shallowEqual, useSelector } from 'react-redux';

import { useLanguage } from '@app/components/Hooks';
import AppState from '@app/types/appStateTypes';
import { ErrorPagesData } from '@app/types/configurationTypes';

export const useErrorPageData = (): ErrorPagesData | null => {
  const language = useLanguage();

  return (
    useSelector<AppState, ErrorPagesData | null>(
      (state) => state.configuration[language]?.errorPagesData ?? null,
      shallowEqual,
    )
  );
};
