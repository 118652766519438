import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { InviolaRegistrationSteps } from '@app/services/inviola/types/stepTypes';

import { useRegistration } from '@app/components/Inviola/InviolaHooks';
import { resetCustomerRegistrationWizard } from '@app/store/actions/inviolaActions';

import { Modal } from '@app/components';
import { Button, Description, Title } from '@app/components/Inviola/InviolaComponents';

const RegistrationSuccessModal = (): React.ReactElement => {
  const { registrationStep } = useRegistration();
  const dispatch = useDispatch();

  const onModalClose = () => dispatch(resetCustomerRegistrationWizard());

  return (
    <Modal
      open={registrationStep === InviolaRegistrationSteps.RegistrationSuccess}
      onClose={onModalClose}
      contentFullWidth={false}
    >
      <ModalContainer>
        <Title>
          <FormattedMessage id="inviola.resetConfirmation.title" />
        </Title>
        <Description>
          <FormattedMessage id="inviola.registrationSuccess.description" />
        </Description>

        <Button onClick={onModalClose}>OK</Button>
      </ModalContainer>
    </Modal>
  );
};

export default RegistrationSuccessModal;

const ModalContainer = styled.div`
  width: 100%;
  max-width: 95vw;
  margin: auto;
  padding: 60px 40px;

  background-color: ${(props): string => props.theme.color.whiteSmoke};
  color: black;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  text-align: left;

  button {
    margin-bottom: 0;
  }

  @media ${Devices.tablet} {
    max-width: 600px;
    padding: 50px 80px;
  }
`;
