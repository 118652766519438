import { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { SeoData } from '@app/types/configurationTypes';
import { EditorialContentTypes, Gallery } from '@app/types/newsTypes';
import { AppState } from '@app/types/appStateTypes';
import { RouteParams } from '@app/types/routerTypes';

import { getNewsByUrlSlug, resetGallery } from '@app/store/actions/newsActions';

import { useIsTheSamePage, useLanguage } from '@app/components/Hooks';

import { MountContext } from '@app/ReactContext';

export const useGallery = (): Gallery | null => {
  const language = useLanguage();

  return useSelector<AppState, Gallery | null>(
    (state) => state.news[EditorialContentTypes.PHOTO].selectedItemDetails?.[language] ?? null,
    isEqual,
  );
};

export const useDownloadGallery = (): void => {
  const gallery = useGallery();
  const isTheSameGallery = useIsTheSamePage();
  const dispatch = useDispatch();
  /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
  const params = useParams() as RouteParams;
  const language = useLanguage();
  const { isInitialMount } = useContext(MountContext);

  useEffect(() => {
    if (!isInitialMount && (!gallery || !isTheSameGallery)) {
      dispatch(resetGallery());
      dispatch(getNewsByUrlSlug({
        ...params,
        language,
        type: EditorialContentTypes.PHOTO,
      }));
    }

    return (): void => {
      dispatch(resetGallery());
    };
  }, [params.forthLevel, params.fifthLevel]);
};

export const useGallerySeo = (): SeoData | undefined => {
  const gallery = useGallery();
  const socialImageUrl = gallery?.seo?.socialImageUrl ? gallery?.seo?.socialImageUrl : gallery?.photos?.[0]?.url ?? '';
  return gallery?.seo && { ...gallery.seo, socialImageUrl };
};
