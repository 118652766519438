import React from 'react';
import styled from 'styled-components';

import { PageError, Errors } from '@app/types/errorTypes';

import { Devices } from '@app/styles';
import { HttpStatus } from '@app/components';
import { Error404, Sorry } from '@app/components/Icons';
import { WebCtaButtonElement } from '@app/components/WebTemplates/elements';

import { ContainerLarge } from '@app/components/WebTemplates/components';
import { useErrorPageData } from './ErrorHooks';

const PageValidationWrapper: React.FC<{ error: Errors }> = ({ error, children }) => {
  const data = useErrorPageData();
  if (!error) return <>{children}</>;

  const isNotFound = error !== PageError.NotFound;
  const status = isNotFound ? 404 : 503;
  const title = isNotFound ? data?.titleNotFound : data?.titleSorry;

  return (
    <HttpStatus status={status}>
      <Container>
        <div>
          <LogoContainer isNotFound={isNotFound}>
            {
              isNotFound ? <Error404 /> : <Sorry />
            }
          </LogoContainer>
          <Caption>{title}</Caption>
          <Buttons>
            {data?.cta?.map((cta) => (
              <WebCtaButtonElement data={cta} key={cta.id} />
            ))}
          </Buttons>
        </div>
      </Container>
    </HttpStatus>
  );
};

const Container = styled(ContainerLarge)`
  background-color: ${(props): string => props.theme.color.whiteSmoke};
  background-image: url("/images/block-background-light.webp");
  background-size: cover;
  height: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoContainer = styled.div<{isNotFound: boolean}>`
  width: ${({ isNotFound }): string => (isNotFound ? '135px' : '235px')};
  margin: ${({ isNotFound }): string => (isNotFound ? '110px auto 15px' : '125px auto 45px')};

  & svg {
    width: 100%;
  }

  @media ${Devices.tablet} {
    width: ${({ isNotFound }): string => (isNotFound ? '250px' : '400px')};
    margin: ${({ isNotFound }): string => (isNotFound ? '200px auto 45px' : '245px auto 10px')};
  }
`;

const Caption = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin-bottom: 100px;

  @media ${Devices.tablet} {
    margin-bottom: 45px;
  }
`;

const Buttons = styled.div`
  margin: 0 auto 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > * {
    width: 250px;
    margin-right: 20px;
    & > * {
      width: 100%
    }
  }

  & > *:last-of-type {
    margin-right: 0;
  }

  @media ${Devices.tablet} {
    margin: 0 auto 240px;
  }
`;

export default PageValidationWrapper;
