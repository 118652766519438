import { MatchResult, ScheduleResults } from '@app/types/matchTypes';
import { StandingsItem } from '@app/types/standingsTypes';
import { PageError } from '@app/types/errorTypes';

export enum SeasonActionTypes {
  SET_LOADING = 'SEASON/SET_LOADING',
  SET_STANDINGS = 'SEASON/SET_STANDINGS',
  SET_SCHEDULE = 'SEASON/SET_SCHEDULE',
  SET_WIDGET_RESULTS = 'SEASON/SET_WIDGET_RESULTS',
  RESET_WIDGET_RESULTS = 'SEASON/RESET_WIDGET_RESULTS',
  RESET_SEASON = 'SEASON/RESET_SEASON',
  SET_SEASON_ERROR = 'SEASON/SET_SEASON_ERROR',
  RESET_SEASON_ERROR = 'SEASON/RESET_SEASON_ERROR',
}

export interface SetLoading {
  type: SeasonActionTypes.SET_LOADING;
  payload: boolean;
}

export interface SetStandings {
  type: SeasonActionTypes.SET_STANDINGS;
  payload: StandingsItem;
}

export interface SetSchedule {
  type: SeasonActionTypes.SET_SCHEDULE;
  payload: ScheduleResults;
}

export interface SetWidgetResults {
  type: SeasonActionTypes.SET_WIDGET_RESULTS;
  payload: MatchResult[];
}

export interface ResetWidgetResults {
  type: SeasonActionTypes.RESET_WIDGET_RESULTS;
}

export interface ResetSeason {
  type: SeasonActionTypes.RESET_SEASON;
}

export interface SetSeasonError {
  type: SeasonActionTypes.SET_SEASON_ERROR;
  payload: PageError;
}

export interface ResetSeasonError {
  type: SeasonActionTypes.RESET_SEASON_ERROR;
}

export type SeasonActions = SetLoading
  | SetStandings
  | SetSchedule
  | SetWidgetResults
  | ResetWidgetResults
  | ResetSeason
  | SetSeasonError
  | ResetSeasonError;
