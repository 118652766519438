import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { SeoData, SeoSocialTypes } from '@app/types/configurationTypes';
import { EditorialContentTypes, NewsContentType, NewsDetails } from '@app/types/newsTypes';
import { AppState } from '@app/types/appStateTypes';
import { RouteParams } from '@app/types/routerTypes';

import { getNewsByUrlSlug, resetNews } from '@app/store/actions/newsActions';

import { useLanguage, useIsTheSamePage } from '@app/components/Hooks';

import { MountContext } from '@app/ReactContext';
import { getHelmetScript } from '@app/helpers/newsHelpers';

export const useNews = (): NewsDetails | null => {
  const language = useLanguage();

  return useSelector<AppState, NewsDetails | null>(
    (state) => state.news[EditorialContentTypes.NEWS].selectedItemDetails?.[language] ?? null,
    isEqual,
  );
};

export const useDownloadNews = (): void => {
  const news = useNews();
  const isTheSameNews = useIsTheSamePage();
  const dispatch = useDispatch();
  const params: RouteParams = useParams();
  const language = useLanguage();
  const { isInitialMount } = useContext(MountContext);

  useEffect(() => {
    if (!isInitialMount && (!news || !isTheSameNews)) {
      dispatch(resetNews());
      dispatch(getNewsByUrlSlug({
        ...params,
        language,
        type: EditorialContentTypes.NEWS,
      }));
    }
  }, [params.forthLevel, params.fifthLevel]);
};

const useNewsSocialImage = (): string => {
  const news = useNews();

  if (news?.seo?.socialImageUrl) return news.seo.socialImageUrl;
  if (news?.topElement?.type === NewsContentType.IMAGE) return news.topElement.value?.url;
  if (news?.topElement?.type === NewsContentType.GALLERY) return news.topElement.value?.[0]?.url;
  return '';
};

export const useNewsSeo = (): SeoData | undefined => {
  const news = useNews();
  const socialImageUrl = useNewsSocialImage();
  return news?.seo && { ...news.seo, socialType: SeoSocialTypes.ARTICLE, socialImageUrl };
};

export const useEmbeddedScripts = (content: string): void => {
  useEffect(() => {
    const helmetScripts = getHelmetScript(content);
    const scripts = helmetScripts?.map(({ src }) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      if (src.indexOf('instagram')) {
        script.onload = () => window?.instgrm?.Embeds?.process();
      }
      document.body.appendChild(script);
      return script;
    }) ?? [];
    return () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const script of scripts) {
        document.body.removeChild(script);
      }
    };
  }, [content]);
};
