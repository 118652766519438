import { TeamStatistics, SetTeamPayload } from '@app/types/teamsTypes';
import { TeamSubMenuId } from '@app/types/configurationTypes';
import { CareerItem } from '@app/services/careers/types/responseTypes';
import { Errors } from '@app/types/errorTypes';

export enum TeamsActionTypes {
  SET_TEAM = 'TEAMS/SET_TEAM',
  RESET_ALL_TEAMS = 'TEAMS/RESET_ALL_TEAMS',
  SET_TEAM_STATISTICS = 'TEAMS/SET_TEAM_STATISTICS',
  RESET_ALL_TEAMS_STATISTICS = 'TEAMS/RESET_ALL_TEAMS_STATISTICS',
  SET_CAREER = 'TEAMS/SET_CAREERS',
  SET_ERROR = 'TEAMS/SET_ERROR'
}

interface ActionTypes {
  type: TeamsActionTypes;
  payload?: SetTeamPayload | { type: TeamSubMenuId; statistics: TeamStatistics } | Array<CareerItem> | Errors;
}

export interface SetTeam extends ActionTypes {
  type: TeamsActionTypes.SET_TEAM;
  payload: SetTeamPayload;
}

export interface ResetAllTeams extends ActionTypes {
  type: TeamsActionTypes.RESET_ALL_TEAMS;
}

export interface SetTeamStatistics extends ActionTypes {
  type: TeamsActionTypes.SET_TEAM_STATISTICS;
  payload: { type: TeamSubMenuId; statistics: TeamStatistics };
}

export interface ResetAllTeamsStatistics extends ActionTypes {
  type: TeamsActionTypes.RESET_ALL_TEAMS_STATISTICS;
}

export interface SetCareers extends ActionTypes {
  type: TeamsActionTypes.SET_CAREER;
  payload: Array<CareerItem>;
}

export interface SetError extends ActionTypes {
  type: TeamsActionTypes.SET_ERROR;
  payload: Errors;
}

export type TeamsActions = SetTeam
  | ResetAllTeams
  | SetTeamStatistics
  | ResetAllTeamsStatistics
  | SetCareers
  | SetError;
