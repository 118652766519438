import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AppState from '@app/types/appStateTypes';
import { InviolaCardMovement } from '@app/types/inviolaTypes';

import { getCardMovements, getCustomerPoints } from '@app/store/actions/inviolaActions';
import { getProfileTabs, Tab } from '@app/helpers/inviolaHelpers';
import { LanguageType } from '@app/types/localizationTypes';
import { AppLanguages } from '@app/constants/localizationConstants';
import { CommonPageBaseUrlParams } from '@app/helpers/routeHelpers';
import { useCommonPageBaseMultiLangRoute } from '@app/pages/CommonPage/CommonPageHooks';

export const useTabs = (): Tab[] => getProfileTabs();

interface ParamTypes {
  thirdLevel: string
}

export const useActiveTab = (): Tab => {
  const tabs = useTabs();
  const { thirdLevel } = useParams<ParamTypes>();

  return tabs.find((tab) => tab.name === thirdLevel) ?? tabs[0];
};

export const useInviolaCardMovementsDownload = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCardMovements());
  }, []);
};

export const useCustomerPointsUpdate = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomerPoints());
  }, []);
};

export const useInviolaCardMovements = (): InviolaCardMovement[] => (
  useSelector<AppState, InviolaCardMovement[]>((state) => state.inviola.card.movements)
);

export const useInviolaCardMovementsSyncState = (): boolean => (
  useSelector<AppState, boolean>((state) => state.inviola.card.syncInProgress)
);

export const useInviolaProfileMultiLangRoute = (): void => {
  const activeTab = useActiveTab();

  const thirdLevelParams:Partial<Record<LanguageType, string>> = activeTab
    ? AppLanguages.reduce((accumulator, entry) => {
      accumulator[entry] = activeTab.name;
      return accumulator;
    }, {} as Partial<Record<LanguageType, string>>) : {};

  const initialParams: CommonPageBaseUrlParams = {
    thirdLevel: thirdLevelParams,
  } as CommonPageBaseUrlParams;

  useCommonPageBaseMultiLangRoute(initialParams);
};
