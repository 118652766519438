import { ContentItem, Elements } from '@kentico/kontent-delivery';

export class Player extends ContentItem {
    public seoMetadataSocialDescription: Elements.TextElement;
    public seoMetadataMetaRobots: Elements.MultipleChoiceElement;
    public number: Elements.NumberElement;
    public photoMedium: Elements.AssetsElement;
    public storeUrl: Elements.TextElement;
    public preferredFoot: Elements.MultipleChoiceElement;
    public socialMarkupSocialTitle: Elements.TextElement;
    public instagram: Elements.TextElement;
    public seoMetadataCanonicalTag: Elements.TextElement;
    public socialMarkupSocialDescription: Elements.TextElement;
    public nationality: Elements.TextElement;
    public photoSmall: Elements.AssetsElement;
    public height: Elements.TextElement;
    public seoMetadataMetaDescription: Elements.TextElement;
    public birthPlace: Elements.TextElement;
    public teams: Elements.TaxonomyElement;
    public seoMetadataSocialImage: Elements.CustomElement;
    public id: Elements.TextElement;
    public biography: Elements.RichTextElement;
    public photoLarge: Elements.AssetsElement;
    public photoThron: Elements.CustomElement;
    public position: Elements.MultipleChoiceElement;
    public joinDate: Elements.DateTimeElement;
    public seoMetadataMetaTitle: Elements.TextElement;
    public squad: Elements.MultipleChoiceElement;
    public lastName: Elements.TextElement;
    public socialMarkupThronImage: Elements.CustomElement;
    public seoMetadataSocialTitle: Elements.TextElement;
    public url: Elements.UrlSlugElement;
    public firstName: Elements.TextElement;
    public birthDate: Elements.DateTimeElement;
    public weight: Elements.TextElement;
    public role: Elements.TextElement;
    public sortingOrder: Elements.NumberElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'seo_metadata__social_description') {
                    return 'seoMetadataSocialDescription';
                }
                if (elementName === 'seo_metadata__meta_robots') {
                    return 'seoMetadataMetaRobots';
                }
                if (elementName === 'photo_medium') {
                    return 'photoMedium';
                }
                if (elementName === 'store_url') {
                    return 'storeUrl';
                }
                if (elementName === 'preferred_foot') {
                    return 'preferredFoot';
                }
                if (elementName === 'social_markup__social_title') {
                    return 'socialMarkupSocialTitle';
                }
                if (elementName === 'seo_metadata__canonical_tag') {
                    return 'seoMetadataCanonicalTag';
                }
                if (elementName === 'photo_thron') {
                    return 'photoThron';
                }
                if (elementName === 'social_markup__social_description') {
                    return 'socialMarkupSocialDescription';
                }
                if (elementName === 'photo_small') {
                    return 'photoSmall';
                }
                if (elementName === 'seo_metadata__meta_description') {
                    return 'seoMetadataMetaDescription';
                }
                if (elementName === 'birth_place') {
                    return 'birthPlace';
                }
                if (elementName === 'seo_metadata__social_image') {
                    return 'seoMetadataSocialImage';
                }
                if (elementName === 'photo_large') {
                    return 'photoLarge';
                }
                if (elementName === 'photo_thron') {
                    return 'photoThron';
                }
                if (elementName === 'join_date') {
                    return 'joinDate';
                }
                if (elementName === 'seo_metadata__meta_title') {
                    return 'seoMetadataMetaTitle';
                }
                if (elementName === 'last_name') {
                    return 'lastName';
                }
                if (elementName === 'social_markup__thron_image') {
                    return 'socialMarkupThronImage';
                }
                if (elementName === 'seo_metadata__social_title') {
                    return 'seoMetadataSocialTitle';
                }
                if (elementName === 'first_name') {
                    return 'firstName';
                }
                if (elementName === 'birth_date') {
                    return 'birthDate';
                }
                if (elementName === 'sorting_order') {
                    return 'sortingOrder';
                }
                return elementName;
            })
        });
    }
}
