import { ContentItem, Elements } from '@kentico/kontent-delivery';

export default class News extends ContentItem {
  public premium: Elements.MultipleChoiceElement;
  public push: Elements.MultipleChoiceElement;
  public seoMetadataMetaRobots: Elements.MultipleChoiceElement;
  public content: Elements.RichTextElement;
  public seoMetadataSocialImage: Elements.CustomElement;
  public seoMetadataSocialDescription: Elements.TextElement;
  public platform: Elements.MultipleChoiceElement;
  public socialMarkupSocialTitle: Elements.TextElement;
  public seoMetadataCanonicalTag: Elements.TextElement;
  public topElement: Elements.CustomElement;
  public socialMarkupSocialDescription: Elements.TextElement;
  public pushTitle: Elements.TextElement;
  public editorialCategory: Elements.TaxonomyElement;
  public title: Elements.TextElement;
  public seoMetadataMetaDescription: Elements.TextElement;
  public shareUrl: Elements.TextElement;
  public subtitle: Elements.TextElement;
  public mainImage: Elements.CustomElement;
  public mainImageVertical: Elements.CustomElement;
  public url: Elements.UrlSlugElement;
  public seoMetadataSocialTitle: Elements.TextElement;
  public publicationDate: Elements.DateTimeElement;
  public tags: Elements.TaxonomyElement;
  public seoMetadataMetaTitle: Elements.TextElement;
  public pushSubtitle: Elements.TextElement;
  public socialMarkupThronImage: Elements.CustomElement;
  constructor() {
    super({
      propertyResolver: ((elementName: string) => {
        if (elementName === 'editorial_category') {
          return 'editorialCategory';
        }
        if (elementName === 'seo_metadata__meta_robots') {
          return 'seoMetadataMetaRobots';
        }
        if (elementName === 'seo_metadata__social_image') {
          return 'seoMetadataSocialImage';
        }
        if (elementName === 'seo_metadata__social_description') {
          return 'seoMetadataSocialDescription';
        }
        if (elementName === 'social_markup__social_title') {
          return 'socialMarkupSocialTitle';
        }
        if (elementName === 'seo_metadata__canonical_tag') {
          return 'seoMetadataCanonicalTag';
        }
        if (elementName === 'thron_object__top_element') {
          return 'topElement';
        }
        if (elementName === 'social_markup__social_description') {
          return 'socialMarkupSocialDescription';
        }
        if (elementName === 'push_title') {
          return 'pushTitle';
        }
        if (elementName === 'seo_metadata__meta_description') {
          return 'seoMetadataMetaDescription';
        }
        if (elementName === 'share_url') {
          return 'shareUrl';
        }
        if (elementName === 'main_image') {
          return 'mainImage';
        }
        if (elementName === 'main_image_vertical_app') {
          return 'mainImageVertical';
        }
        if (elementName === 'seo_metadata__social_title') {
          return 'seoMetadataSocialTitle';
        }
        if (elementName === 'publication_date') {
          return 'publicationDate';
        }
        if (elementName === 'seo_metadata__meta_title') {
          return 'seoMetadataMetaTitle';
        }
        if (elementName === 'push_subtitle') {
          return 'pushSubtitle';
        }
        if (elementName === 'social_markup__thron_image') {
          return 'socialMarkupThronImage';
        }
        return elementName;
      })
    });
  }
}
