import { ItemResponses, SortOrder } from '@kentico/kontent-delivery';

import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';

import { kenticoClient } from '@app/services/kentico/kentico';

import { LanguageType } from '@app/types/localizationTypes';
import News from '@app/services/kentico/types/news';
import VideoKentico from '@app/services/kentico/types/video';
import PhotoGallery from '@app/services/kentico/types/photo_gallery';
import {
  NewsList, EditorialContentTypes, Gallery, NewsDetails, Video,
} from '@app/types/newsTypes';

import {
  mapNewsItems, mapGallery, mapNewsDetails, mapVideo,
} from '@app/services/kentico/converters/newsConverters';

type GetNewsList = (params: {
  type: EditorialContentTypes;
  category?: string | string[];
  tags?: string[];
  skip: number;
  limit: number;
  language: LanguageType;
}) => Promise<NewsList>

export const getNewsList: GetNewsList = async (requestParams) => {
  const { category, tags } = requestParams;
  const language = langCodeToKenticoLangMap[requestParams.language];

  const request = kenticoClient()
    .items<News | PhotoGallery | VideoKentico>()
    .type(requestParams.type)
    .languageParameter(language)
    .equalsFilter('system.language', language)
    .orderParameter('elements.publication_date', SortOrder.desc)
    .limitParameter(requestParams.limit)
    .skipParameter(requestParams.skip);

  if (category) {
    const categoryArray = Array.isArray(category) ? category : [category];
    request.containsFilter('elements.editorial_category', categoryArray);
  }

  if (tags) {
    request.allFilter('elements.tags', tags);
  }

  request.containsFilter('elements.platform', ['web']);

  const response = await request.toPromise();
  return mapNewsItems(response);
};

type GetNewsDetailsByUrlSlugFunc = (params: {
  type: EditorialContentTypes;
  urlSlug?: string;
  id?: string;
  publicationDate: string;
  language: LanguageType;
}) => Promise<Gallery | NewsDetails | Video | null>

export const getNewsDetails: GetNewsDetailsByUrlSlugFunc = async (requestParams) => {
  const {
    urlSlug, id, publicationDate, type, language,
  } = requestParams;

  const filterName = urlSlug ? 'elements.url' : 'system.id';
  const filterData = urlSlug || id;

  const response = await kenticoClient()
    .items<News | PhotoGallery | VideoKentico>().type(type)
    .languageParameter(langCodeToKenticoLangMap[language])
    .equalsFilter('system.language', langCodeToKenticoLangMap[language])
    .equalsFilter(filterName, filterData ?? '')
    .containsFilter('elements.platform', ['web'])
    .greaterThanOrEqualFilter('elements.publication_date', `${publicationDate}T00:00:00Z`)
    .lessThanOrEqualFilter('elements.publication_date', `${publicationDate}T23:59:59Z`)
    .toPromise();

  if (!response.items[0]) {
    return null;
  }

  switch (type) {
    case EditorialContentTypes.NEWS:
      return mapNewsDetails(response as ItemResponses.ListContentItemsResponse<News>, language);
    case EditorialContentTypes.PHOTO:
      return mapGallery(response.items[0] as PhotoGallery, language);
    default:
      return mapVideo(response.items[0] as VideoKentico, language);
  }
};
