import cloneDeep from 'lodash/cloneDeep';

import { NewsActions, NewsActionTypes } from '@app/store/actionTypes/newsActionTypes';
import {
  BaseNewsItem, EditorialContentTypes, LocalizedGalleryItem, LocalizedNewsItem, LocalizedVideoItem, NewsList,
} from '@app/types/newsTypes';
import { Errors } from '@app/types/errorTypes';

interface NewsBaseInterface {
  latestItems: BaseNewsItem[];
}

interface ArticlesStateInterface extends NewsBaseInterface {
  selectedItemDetails: LocalizedNewsItem;
}
interface VideosStateInterface extends NewsBaseInterface {
  selectedItemDetails: LocalizedVideoItem;
}
interface PicturesStateInterface extends NewsBaseInterface {
  selectedItemDetails: LocalizedGalleryItem;
}

export type NewsState = {
  newsList: {
    items: BaseNewsItem[];
    hasMoreData: boolean;
  }
  [EditorialContentTypes.NEWS]: ArticlesStateInterface;
  [EditorialContentTypes.VIDEO]: VideosStateInterface;
  [EditorialContentTypes.PHOTO]: PicturesStateInterface;
  error: Errors;
  isLoading: boolean;
  dataList: Partial<Record<EditorialContentTypes, Record<string, NewsList>>>;
};

const initialState: NewsState = {
  isLoading: false,
  newsList: {
    items: [],
    hasMoreData: false,
  },
  [EditorialContentTypes.NEWS]: {
    selectedItemDetails: {},
    latestItems: [],
  },
  [EditorialContentTypes.VIDEO]: {
    selectedItemDetails: {},
    latestItems: [],
  },
  [EditorialContentTypes.PHOTO]: {
    selectedItemDetails: {},
    latestItems: [],
  },
  error: null,
  dataList: {},
};

export default (
  state: NewsState = cloneDeep(initialState),
  action: NewsActions,
): NewsState => {
  switch (action.type) {
    case NewsActionTypes.SET_DATA_LIST:
      return {
        ...state,
        dataList: {
          ...state.dataList,
          ...action.payload,
        },
      };
    case NewsActionTypes.SET_NEWS_LIST:
      return {
        ...state,
        newsList: {
          ...state.newsList,
          items: [...state.newsList.items, ...action.payload.items],
          hasMoreData: action.payload.hasMoreData,
        },
      };
    case NewsActionTypes.SET_NEWS_LOADING_STATE:
      return {
        ...state,
        isLoading: action.payload,
      };
    case NewsActionTypes.RESET_NEWS_LIST:
      return {
        ...state,
        newsList: { ...initialState.newsList },
        error: null,
      };
    case NewsActionTypes.SET_GALLERY:
      return {
        ...state,
        [EditorialContentTypes.PHOTO]: {
          ...state[EditorialContentTypes.PHOTO],
          selectedItemDetails: {
            ...state[EditorialContentTypes.PHOTO].selectedItemDetails,
            ...action.payload,
          },
        },
        error: null,
      };
    case NewsActionTypes.SET_VIDEO:
      return {
        ...state,
        [EditorialContentTypes.VIDEO]: {
          ...state[EditorialContentTypes.VIDEO],
          selectedItemDetails: {
            ...state[EditorialContentTypes.VIDEO].selectedItemDetails,
            ...action.payload,
          },
        },
        error: null,
      };
    case NewsActionTypes.RESET_VIDEO:
      return {
        ...state,
        [EditorialContentTypes.VIDEO]: {
          ...state[EditorialContentTypes.VIDEO],
          selectedItemDetails: initialState[EditorialContentTypes.VIDEO].selectedItemDetails,
        },
        error: null,
      };
    case NewsActionTypes.SET_NEWS:
      return {
        ...state,
        [EditorialContentTypes.NEWS]: {
          ...state[EditorialContentTypes.NEWS],
          selectedItemDetails: {
            ...state[EditorialContentTypes.NEWS].selectedItemDetails,
            ...action.payload,
          },
        },
        error: null,
      };
    case NewsActionTypes.RESET_NEWS:
      return {
        ...state,
        [EditorialContentTypes.NEWS]: {
          ...state[EditorialContentTypes.NEWS],
          selectedItemDetails: initialState[EditorialContentTypes.NEWS].selectedItemDetails,
        },
        error: null,
      };
    case NewsActionTypes.RESET_GALLERY:
      return {
        ...state,
        [EditorialContentTypes.PHOTO]: {
          ...state[EditorialContentTypes.PHOTO],
          selectedItemDetails: initialState[EditorialContentTypes.PHOTO].selectedItemDetails,
        },
        error: null,
      };
    case NewsActionTypes.SET_LATEST_GALLERY:
      return {
        ...state,
        [EditorialContentTypes.PHOTO]: {
          ...state[EditorialContentTypes.PHOTO],
          latestItems: [...action.payload],
        },
      };
    case NewsActionTypes.RESET_LATEST_GALLERY:
      return {
        ...state,
        [EditorialContentTypes.PHOTO]: {
          ...state[EditorialContentTypes.PHOTO],
          latestItems: [],
        },
      };
    case NewsActionTypes.SET_LATEST_VIDEO:
      return {
        ...state,
        [EditorialContentTypes.VIDEO]: {
          ...state[EditorialContentTypes.VIDEO],
          latestItems: [...action.payload],
        },
      };
    case NewsActionTypes.RESET_LATEST_VIDEO:
      return {
        ...state,
        [EditorialContentTypes.VIDEO]: {
          ...state[EditorialContentTypes.VIDEO],
          latestItems: [],
        },
      };
    case NewsActionTypes.SET_LATEST_NEWS:
      return {
        ...state,
        [EditorialContentTypes.NEWS]: {
          ...state[EditorialContentTypes.NEWS],
          latestItems: [...action.payload],
        },
      };
    case NewsActionTypes.RESET_LATEST_NEWS:
      return {
        ...state,
        [EditorialContentTypes.NEWS]: {
          ...state[EditorialContentTypes.NEWS],
          latestItems: [],
        },
      };
    case NewsActionTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
