import {
  TeamSubMenuId, Image, ThroneAsset, Seo,
} from '@app/types/configurationTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { TeamSquadTypes } from '@app/services/kentico/teamsService';

export enum PlayerPositionFilter {
  All = 'web_men_first_team_all',
  Goalkeepers = 'web_men_first_team_goalkeepers',
  Defenders = 'web_men_first_team_defenders',
  Midfielders = 'web_men_first_team_midfielders',
  Forwards = 'web_men_first_team_forwards',
  Staff = 'web_men_first_team_staff',
  AllWomen = 'web_women_first_team_all',
  GoalkeepersWomen = 'web_women_first_team_goalkeepers',
  DefendersWomen = 'web_women_first_team_defenders',
  MidfieldersWomen = 'web_women_first_team_midfielders',
  ForwardsWomen = 'web_women_first_team_forwards',
  StaffWomen = 'web_women_first_team_staff',
  AllPrimavera = 'squadre_primavera_all',
  GoalkeepersPrimavera = 'squadre_primavera_portieri',
  DefendersPrimavera = 'squadre_primavera_difensori',
  MidfieldersPrimavera = 'squadre_primavera_centrocampisti',
  ForwardsPrimavera = 'squadre_primavera_attaccanti',
  StaffPrimavera = 'squadre_primavera_staff',
}

export enum PlayerPosition {
  Goalkeeper = 'goalkeeper',
  Defender = 'defender',
  Midfielder = 'midfielder',
  Forward = 'forward',
  Staff = 'staff'
}

export interface Player extends Seo {
  id: string;
  firstName: string;
  lastName: string;
  number: number;
  position: PlayerPosition;
  positionName: string;
  role: string;
  photoLarge: Image;
  photoThron: ThroneAsset;
  storeUrl: string;
  birthDate: Date | null;
  birthPlace: string;
  nationality: string;
  height: string;
  weight: string;
  preferredFoot: string;
  joinDate: string;
  biography: string;
  urlSlug: string;
  instagram: string;
  squad: TeamSquadTypes;
  sortingOrder: number;
}

export type Team = Player[];

export type LocalizedTeam = {
  [key in LanguageType]?: Team;
}

export interface SetTeamPayload {
  type: TeamSubMenuId;
  players: LocalizedTeam;
}

export const getPositionByFilter = (filter: PlayerPositionFilter): PlayerPosition | null => {
  switch (filter) {
    case PlayerPositionFilter.Goalkeepers: return PlayerPosition.Goalkeeper;
    case PlayerPositionFilter.Defenders: return PlayerPosition.Defender;
    case PlayerPositionFilter.Forwards: return PlayerPosition.Forward;
    case PlayerPositionFilter.Midfielders: return PlayerPosition.Midfielder;
    case PlayerPositionFilter.Staff: return PlayerPosition.Staff;
    case PlayerPositionFilter.GoalkeepersWomen: return PlayerPosition.Goalkeeper;
    case PlayerPositionFilter.DefendersWomen: return PlayerPosition.Defender;
    case PlayerPositionFilter.ForwardsWomen: return PlayerPosition.Forward;
    case PlayerPositionFilter.MidfieldersWomen: return PlayerPosition.Midfielder;
    case PlayerPositionFilter.StaffWomen: return PlayerPosition.Staff;
    case PlayerPositionFilter.GoalkeepersPrimavera: return PlayerPosition.Goalkeeper;
    case PlayerPositionFilter.DefendersPrimavera: return PlayerPosition.Defender;
    case PlayerPositionFilter.ForwardsPrimavera: return PlayerPosition.Forward;
    case PlayerPositionFilter.MidfieldersPrimavera: return PlayerPosition.Midfielder;
    case PlayerPositionFilter.StaffPrimavera: return PlayerPosition.Staff;
    default: return null;
  }
};

export interface TeamNames {
  [teamId: string]: {
    teamName: string;
    shortTeamName: string;
  };
}

export interface TeamStatistics {
  gamesPlayed: number;
  players: { [playerId: string]: PlayerStatistics };
}

export interface PlayerStatistics {
  position?: PlayerPosition;
  playerId: string;
  gamesPlayed: number;
  timePlayed: number;
  yellowCards: number;
  redCards: number;
  duelsWon: number;
  totalPasses: number;
  successfulShortPasses: number;
  unsuccessfulShortPasses: number;
  successfulLongPasses: number;
  unsuccessfulLongPasses: number;
  totalSuccessfulPasses: number;
  successfulLaunches: number;
}

export interface GoalkeeperStatistics extends PlayerStatistics{
  position: PlayerPosition.Goalkeeper;
  cleanSheets: number;
  goalsConceded: number;
  savesMade: number;
  punches: number;
  catches: number;
}

export interface DefenderStatistics extends PlayerStatistics{
  position: PlayerPosition.Defender;
  recoveries: number;
  totalTackles: number;
  blocks: number;
  totalClearances: number;
  totalFoulsConceded: number;
  totalFoulsWon: number;
}

export interface MidfielderStatistics extends PlayerStatistics{
  position: PlayerPosition.Midfielder;
  totalShots: number;
  shotsOnTarget: number;
  shotsOffTarget: number;
  blockedShots: number;
  // additional
  recoveries: number;
  totalTackles: number;
  totalFoulsConceded: number;
  totalFoulsWon: number;
}

export interface ForwardStatistics extends PlayerStatistics{
  position: PlayerPosition.Forward;
  goals: number;
  rightFootGoals: number;
  leftFootGoals: number;
  headedGoals: number;
  goalsFromInsideBox: number;
  goalsFromOutsideBox: number;
  totalShots: number;
  shotsOnTarget: number;
  shotsOffTarget: number;
  blockedShots: number;
}

export type LocalizedPlayer = {
  [key in LanguageType]: Player | undefined
}
