import { ContentItem, Elements } from '@kentico/kontent-delivery';

import RelatedMenuItem from './related_menu_item';
import ExternalLink from './external_link';

export default class CtaButton extends ContentItem {
    public id: Elements.TextElement;
    public actionType: Elements.LinkedItemsElement<RelatedMenuItem | ExternalLink>;
    public uiTemplate: Elements.MultipleChoiceElement;
    public label: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'action_type') {
                    return 'actionType';
                }
                if (elementName === 'ui_template') {
                    return 'uiTemplate';
                }
                return elementName;
            })
        });
    }
}
