import React from 'react';
import styled from 'styled-components';

import { MenuItemTypes, SubMenuItem as SubMenuItemInterface, MenuItem } from '@app/types/configurationTypes';
import { formatSubMenuLinkProps } from '@app/helpers/routeHelpers';
import { LocalizedLink } from '@app/components/Localization';
import {
  useActiveSeasonIdForCompetitionSeriaA, useActiveUrlSlugForCompetitionSeriaA,
} from '@app/pages/Season/Season/SeasonHooks';

interface MenuItemProps {
  item: MenuItem
  subItem: SubMenuItemInterface;
  onClick: () => void
  className?: string; // passed by styled component automatically
}

const SubMenuItem: React.FC<MenuItemProps> = ({
  item, subItem, onClick, className,
}) => {
  let subItemProps = {};
  const seasonData = {
    thirdLevel: useActiveUrlSlugForCompetitionSeriaA(),
    forthLevel: useActiveSeasonIdForCompetitionSeriaA(),
  };

  if (subItem.data.type === MenuItemTypes.ExternalLink) {
    subItemProps = {
      href: subItem.data?.url,
      as: 'a',
    };
  } else if (subItem.data.type === MenuItemTypes.BaseMenuItem) {
    subItemProps = {
      ...formatSubMenuLinkProps(item.data, subItem, seasonData),
    };
  }

  return subItem.data.isVisible ? (
    <li
      key={subItem.data.id}
    >
      { /* @ts-ignore */ }
      <SubMenuItemTitle {...subItemProps} onClick={onClick} className={className}>
        {subItem.data.name}
      </SubMenuItemTitle>
    </li>
  ) : null;
};

export default SubMenuItem;

const SubMenuItemTitle = styled(LocalizedLink)`
  color: white;
  display: block;
  padding-bottom: 22px;
  text-transform: capitalize;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-weight: normal;
  font-size: 11px;

  &:hover {
    color: white;
    text-decoration: none;
  }
`;
