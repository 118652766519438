import React from 'react';
import styled from 'styled-components';

import { MenuItem as MenuItemInterface } from '@app/types/configurationTypes';

import MenuItemTitle from '@app/components/MenuItemTitle';
import SubMenuItem from '@app/components/SubMenuItem';
import { useCloseMenu } from '@app/components/Hooks';
import { useIsConnected } from '@app/components/Inviola/InviolaHooks';
import { isMenuItemVisible } from '@app/helpers/menuHelpers';

const MenuItem: React.FC<{ item: MenuItemInterface }> = ({ item }) => {
  const closeMenu = useCloseMenu();
  const isConnected = useIsConnected();

  return (
    <MenuItemContainer>
      <Title
        item={item}
        onClick={closeMenu}
      />
      <ul>
        {Object.values(item.navigation)
          .filter(({ data }) => isMenuItemVisible(data, isConnected))
          .map((subItem) => (
            <SubMenuItemTitle
              item={item}
              subItem={subItem}
              onClick={closeMenu}
              key={subItem.data.id}
            />
          ))}
      </ul>
    </MenuItemContainer>
  );
};

const MenuItemContainer = styled.div`
  width: 190px;
  margin-bottom: 15px;
`;

const Title = styled(MenuItemTitle)`
  padding: 8px 0;
  font-size: 14px;
`;

const SubMenuItemTitle = styled(SubMenuItem)`
  font-size: 12px;
  text-transform: uppercase;
  padding: 4px 0;
  text-align: left;
  position: relative;
  z-index: 20;
`;

export default MenuItem;
