import React from 'react';
import styled from 'styled-components';

import { getMenTeamLogoUrl } from '@app/helpers/sourceHelpers';
import { usePlayerCareer } from '@app/pages/PlayerProfile/PlayersHooks';
import { CareerItem, Season } from '@app/services/careers/types/responseTypes';
import { Devices } from '@app/styles';

const PlayerCareer = (): React.ReactElement => {
  const career: CareerItem | undefined = usePlayerCareer();

  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            <YearsTd>Years</YearsTd>
            <NamesTd>Clubs</NamesTd>
            <MatchesTd>Matches.</MatchesTd>
            <GoalsTd>Goals.</GoalsTd>
          </tr>
        </thead>
        <tbody>
          { career?.seasons.map((season: Season) => (
            <tr key={`#${season.team_id}-${season.season_name}`}>
              <SeasonNameTd className="bigger">{season.season_name}</SeasonNameTd>
              <TeamsTd className="bigger team-name">
                { season.team_id
                  ? <img alt={season.team_name} src={getMenTeamLogoUrl(season.team_id)} />
                  : <img alt={season.team_name} src="/images/team/blank-team-logo.svg" />}
                <span>{season.team_name}</span>
              </TeamsTd>
              <td>{season.matches_played}</td>
              <td>{season.goals}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default PlayerCareer;

const SeasonNameTd = styled.td`
  font-weight: bold;
  white-space: nowrap;
`;

const TeamsTd = styled.td`
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const Table = styled.table`
    width: 100%;
    color: #000;
    font-family: ${(props): string => props.theme.fontFamily.arialNova};
    border-spacing:0 5px;
    border-collapse: separate;
    table-layout: fixed;

    td:first-child {
        border-left: 5px solid #fff;
    }

    th {
      padding: 17px 20px;
      background: transparent;
      font-weight: normal;
      @media ${Devices.tabletLarge} {
        background: #fff;
      }
    }


    tbody {
      td {
        font-family: ${(props): string => props.theme.fontFamily.arialNova};

        img {
          height: 36px;
        }
      }
    }

    thead {
        font-size: 11px;
        text-align: left;
        font-family: ${(props): string => props.theme.fontFamily.arialNova};
        font-weight: normal;

        td {
          font-size: 11px;
        }
    }

    td {
      padding: 7px 20px;
      background: #fff;
      font-size: 12px;
      @media ${Devices.tabletLarge} {
        padding: 17px 20px;
      }
    }
    ${SeasonNameTd} {
      font-family: ${(props): string => props.theme.fontFamily.commutersSans};
      font-size: 13px;
        @media ${Devices.tabletLarge} {
          font-size: 16px;
        }
    }
    ${TeamsTd} {
      font-family: ${(props): string => props.theme.fontFamily.commutersSans};
      font-size: 12px;
      font-weight: normal;
        @media ${Devices.tabletLarge} {
          font-size: 16px;
        }
    }
`;

const YearsTd = styled.th`
  width: 26%;
`;

const NamesTd = styled.th`
  width: 40%;
`;

const MatchesTd = styled.th`
  width: 17%;
`;

const GoalsTd = styled.th`
  width: 17%;
`;

const TableContainer = styled.div`
  overflow: auto;

  @media ${Devices.tabletLarge} {
    overflow: normal;
  }
`;
