import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { InviolaCatalogItem } from '@app/types/inviolaTypes';
import { addToWishList, deleteFromWishList } from '@app/store/actions/inviolaActions/catalog';
import { useWishListItems } from '@app/pages/Inviola/CatalogPage/CatalogPageHooks';
import { useIsConnected } from '@app/components/Inviola/InviolaHooks';
import { useLanguage } from '@app/components/Hooks';

const FavoritesIcon = ({ item }: { item: InviolaCatalogItem }): React.ReactElement => {
  const dispatch = useDispatch();
  const language = useLanguage();
  const wishList = useWishListItems();
  const isConnected = useIsConnected();

  const isInWishList = !!wishList
    .find((listItem) => listItem.id === item.id);

  const syncItemWithWishList = (item: InviolaCatalogItem) => {
    if (!isConnected) return;
    if (isInWishList) {
      dispatch(deleteFromWishList(language, item.id));
    } else {
      dispatch(addToWishList(language, item.id));
    }
  };

  return (
    <HeartContainer onClick={() => syncItemWithWishList(item)}>
      <HeartIcon
        isConnected={isConnected}
        isInWishList={isInWishList}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 17"
      >
        <g fill="none" fillRule="evenodd">
          <g id="heartColor">
            <g>
              <path
                d="M426.79 15c-1.233 0-2.466.51-3.396 1.535-1.86 2.05-1.858 5.338 0 7.391l7.101 7.848c.13.144.311.226.501.226s.37-.082.501-.226c2.37-2.612 4.74-5.228 7.108-7.84 1.86-2.051 1.86-5.34 0-7.392-1.86-2.05-4.93-2.051-6.79 0l-.816.889-.814-.896c-.93-1.026-2.163-1.535-3.396-1.535z"
                transform="translate(-602 -502) translate(180 487)"
              />
            </g>
          </g>
        </g>
      </HeartIcon>
    </HeartContainer>
  );
};

export default FavoritesIcon;

const HeartContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 999;
  padding: 0 !important;
  margin: 0 !important;
`;

const HeartIcon = styled.svg<{ isInWishList: boolean; isConnected: boolean }>`
  width: 18px;
  height: 17px;
  cursor: ${({ isConnected }): string => (isConnected ? 'default' : 'not-allowed')};
  transition: all 0.3s ease;

  #heartColor {
    cursor: ${({ isConnected }): string => (isConnected ? 'default' : 'not-allowed')};
    fill: ${({ isInWishList, theme }): string => (isInWishList ? '#5F259F' : theme.color.gray)};
    transition: all 0.3s ease;
  }

  &:hover {
    cursor: ${({ isConnected }): string => (isConnected ? 'pointer' : 'not-allowed')};
    transition: all 0.3s ease;

    #heartColor {
      cursor: ${({ isConnected }): string => (isConnected ? 'pointer' : 'not-allowed')};
      fill: #5F259F;
      opacity: .8;
      transition: all 0.3s ease;
    }
  }
`;
