import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from 'styled-components';

import 'react-lazy-load-image-component/src/effects/blur.css';

import { ImageConfiguration } from '@app/types/imageTypes';

export interface LazyImageProps {
  src: string;
  alt: string;

  thumbnailSrc?: string;
  width?: string;
  height?: string;

  imageProps?: ImageConfiguration;

  className?: string; // passed by styled component automatically
  useLazyLoading?: boolean;
}

const LazyImage = React.memo(({
  src, thumbnailSrc, imageProps, className, alt, useLazyLoading,
}: LazyImageProps): React.ReactElement => {
  const [useThumbnail, setThumbnailState] = useState(!!thumbnailSrc);
  // const [useThumbnail, setThumbnailState] = useState(false);
  const [usePicture, setPictureState] = useState(!thumbnailSrc);
  const [useImage, setImageState] = useState(false);

  const onThumbnailLoad = (): void => {
    setPictureState(true);
  };

  const onImageLoad = (): void => {
    setThumbnailState(false);
    setImageState(true);
  };
  const onImageError = (): void => {
    setThumbnailState(false);
    setPictureState(false);
    setImageState(false);
  };

  return (
    <>
      {useThumbnail && (
        <Thumbnail
          src={thumbnailSrc}
          onLoad={onThumbnailLoad}
          onError={onThumbnailLoad}
          className={className}
          as={useLazyLoading ? LazyLoadImage : 'img'}
          alt=""
        />
      )}
      {usePicture && (
        <Picture>
          {imageProps?.sources.map(({ sizes, srcSet }, index) => (
            <source media={sizes} srcSet={srcSet} key={index} />
          ))}
          <FullImage
            src={src}
            onLoad={onImageLoad}
            onError={onImageError}
            className={className}
            visible={useImage}
            as={useLazyLoading ? LazyLoadImage : 'img'}
            alt={alt}
          />
        </Picture>
      )}
    </>
  );
});

const Thumbnail = styled.img`
  width: 100%;
  position: relative;
  filter: blur(10px);
  // z-index: 1;
  object-fit: cover;
`;

const Picture = styled.picture`
  height: 100%;
  width: 100%;
`;

export const FullImage = styled.img<{ visible?: boolean }>`
  width: 100%;
  position: ${({ visible }): string => (visible ? 'relative' : 'absolute')};
  top: 0;
  z-index: ${({ visible }): number => (visible ? 0 : -1)};
  min-height: 1px; // required for correct lazy-load on tablets and desktop
  min-width: 1px; // required for correct lazy-load on tablets and desktop

  object-fit: cover;
`;

export const ImageElement = styled(FullImage)``;

export default LazyImage;
