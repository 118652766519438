import {
  MatchResult,
  LiveMatchResult,
  LocalizedGames,
  MatchComments,
  MatchLineups,
  MatchLiveAudio,
} from '@app/types/matchTypes';
import { Errors } from '@app/types/errorTypes';

export enum MatchActionTypes {
  SET_ERROR = 'MATCHCENTER/SET_ERROR',
  SET_GAMES = 'MATCHCENTER/SET_GAMES',
  SET_GAMES_DOWNLOAD_COMPLETED = 'MATCHCENTER/SET_GAMES_DOWNLOAD_COMPLETED',
  SET_MATCH_FEEDS_DOWNLOAD_COMPLETED = 'MATCHCENTER/SET_MATCH_FEEDS_DOWNLOAD_COMPLETED',
  SET_MATCH_LINEUPS = 'MATCHCENTER/SET_MATCH_LINEUPS',
  SET_MATCH_EVENTS = 'MATCHCENTER/SET_MATCH_EVENTS',
  SET_MATCH_RESULTS = 'MATCHCENTER/SET_MATCH_RESULTS',
  SET_LIVE_MATCH = 'MATCHCENTER/SET_LIVE_MATCH',
  SET_LIVE_AUDIO = 'MATCHCENTER/SET_LIVE_AUDIO',
  RESET_LIVE_MATCH = 'MATCHCENTER/RESET_LIVE_MATCH',
  RESET_MATCH_CENTER = 'MATCHCENTER/RESET_MATCH_CENTER',
  RESET_LIVE_AUDIO = 'MATCHCENTER/RESET_LIVE_AUDIO',
  PAUSE_LIVE_AUDIO = 'MATCHCENTER/PAUSE_LIVE_AUDIO',
}

export interface SetError {
  type: MatchActionTypes.SET_ERROR;
  payload: Errors;
}

export interface SetGames {
  type: MatchActionTypes.SET_GAMES;
  payload: LocalizedGames;
}

export interface SetMatchLineups {
  type: MatchActionTypes.SET_MATCH_LINEUPS;
  payload: MatchLineups | null;
}

export interface SetMatchEvents {
  type: MatchActionTypes.SET_MATCH_EVENTS;
  payload: MatchComments[];
}

export interface SetMatchResults {
  type: MatchActionTypes.SET_MATCH_RESULTS;
  payload: MatchResult | null;
}

export interface SetLiveMatch {
  type: MatchActionTypes.SET_LIVE_MATCH;
  payload: LiveMatchResult;
}

export interface ResetLiveMatch {
  type: MatchActionTypes.RESET_LIVE_MATCH;
  payload: string;
}

export interface ResetMatchCenter {
  type: MatchActionTypes.RESET_MATCH_CENTER;
  payload: null;
}

export interface SetGamesDownloadCompleted {
  type: MatchActionTypes.SET_GAMES_DOWNLOAD_COMPLETED;
  payload: boolean;
}

export interface SetMatchFeedsDownloadCompleted {
  type: MatchActionTypes.SET_MATCH_FEEDS_DOWNLOAD_COMPLETED;
  payload: boolean;
}

export interface SetMatchLiveAudio {
  type: MatchActionTypes.SET_LIVE_AUDIO;
  payload: MatchLiveAudio;
}

export interface ResetMatchLiveAudio {
  type: MatchActionTypes.RESET_LIVE_AUDIO;
}

export interface PauseMatchLiveAudio {
  type: MatchActionTypes.PAUSE_LIVE_AUDIO;
  payload: boolean;
}

export type MatchCenterActions = SetGamesDownloadCompleted
  | SetMatchFeedsDownloadCompleted
  | SetError
  | SetGames
  | SetMatchEvents
  | SetMatchLineups
  | SetMatchResults
  | SetLiveMatch
  | ResetMatchCenter
  | ResetLiveMatch
  | SetMatchLiveAudio
  | ResetMatchLiveAudio
  | PauseMatchLiveAudio;
