import React from 'react';

interface InlineTrackProps {
  srcData: string;
  srcLang: string;
  kind?: 'subtitles' | 'captions'
}

const InlineTrack: React.FC<InlineTrackProps> = ({ srcData, srcLang, kind = 'subtitles' }) => {
  if (!srcData || srcData.length === 0) { return null; }

  return (
    <track
      default
      src={srcData}
      srcLang={srcLang}
      kind={kind}
    />
  );
};

export default InlineTrack;
