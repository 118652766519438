import React from 'react';
import styled from 'styled-components';
import GoogleMap from 'google-map-react';

import { GOOGLE_MAPS_KEY } from '@app/constants/googleMapsConstants';
import { InviolaShopsMapDefaults } from '@app/constants/inviolaConstants';
import { useShop, useShops, useUserLocation } from '@app/components/Inviola/InviolaHooks';

import MapMarker from '../../ProfilePage/components/MapMarker';

interface ShopsMapProps {
  activeShopId?: string;

  setActiveShop: (id: string) => unknown;
}

const mapOptions = {
  clickableIcons: false,
  scaleControl: false,
  fullscreenControl: false,
  zoomControlOptions: { position: 3 },
};

const ShopsMap = ({ activeShopId, setActiveShop }: ShopsMapProps): React.ReactElement => {
  const shops = useShops();
  const activeShop = useShop(activeShopId);
  const defaultMapCenter = useUserLocation();

  const curentMapCenter = activeShop ? { lat: activeShop.lat, lng: activeShop.lng } : defaultMapCenter;

  return (
    <MapArea>
      <MapWrapper>
        <GoogleMap
          bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
          defaultZoom={InviolaShopsMapDefaults.zoom}
          defaultCenter={InviolaShopsMapDefaults.center}
          center={curentMapCenter}
          zoom={activeShop ? 15 : InviolaShopsMapDefaults.zoom}
          options={mapOptions}
        >
          { shops.map((shop) => (
            <MapMarker
              key={shop.id}
              lat={shop.lat}
              lng={shop.lng}
              text={shop.name}
              active={activeShopId === shop.id}
              onClick={() => setActiveShop(shop.id)}
            />
          )) }

        </GoogleMap>
      </MapWrapper>
    </MapArea>
  );
};

const MapArea = styled.div`
  margin-bottom: 30px;
`;

const MapWrapper = styled.div`
  width: 100%;
  height: 500px;

  position: relative;
`;

export default ShopsMap;
