import { InviolaHttpErrors } from '@app/services/inviola/apiConstants';
import { InviolaSpecificErrorCodes } from '@app/constants/inviolaConstants';
import {
  formatLoginCredentials,
  getCustomerInformation,
  getInviolaRegistrationCardCustomer,
  getProxyCustomerInformation,
} from '@app/services/inviola/converters';
import {
  getCustomer,
  getMovements,
  login,
  logout,
  deleteCustomer,
  modifyCustomer,
  modifyPassword,
  registerWithCredentials,
  verifyCustomerByEmail,
} from '@app/services/inviola/inviola';
import {
  registerCustomerProxy,
  validateCardProxy,
  verifyCardValidationCodeProxy,
  verifyCustomerByEmailProxy,
  verifyCustomerEmailUniquenessProxy,
} from '@app/services/inviola/inviolaProxy';
import { HttpError, InviolaAPIError, InviolaAppError, InviolaAppSuccess } from '@app/services/inviola/types/errorTypes';
import {
  ChangePasswordParams,
  ConfirmRegistrationCredentials,
  LoginCredentials,
  RegistrationCredentials,
} from '@app/services/inviola/types/requestTypes';
import {
  InviolaLoginData,
  InviolaModifyData,
  InviolaMovementsData,
  InviolaResponse,
} from '@app/services/inviola/types/responseTypes';
import { InviolaRegistrationSteps } from '@app/services/inviola/types/stepTypes';
import {
  InviolaActionTypes,
  RegistrationCardWithEmailConfirmation,
  RegistrationCardWithEmailValidated,
  RegistrationCardWithoutEmailConfirmation,
  RegistrationCardWithoutEmailValidated,
  RegistrationCodeSent,
  RegistrationConfirmed,
  RegistrationWizardReset,
  ResetCustomer,
  SetCardMovements,
  SetCardMovementsSyncInProgress,
  SetChangePasswordInProgress,
  SetCustomer,
  SetCustomerPoints,
  SetCustomerRedirectPath,
  SetCustomerSaved,
  SetCustomerUpdated,
  SetRegistrationConfirmationCredentials,
} from '@app/store/actionTypes/inviolaActionTypes';
import { Action, ActionWithPromiseReturn } from '@app/types/actionTypes';
import { InviolaCustomer } from '@app/types/inviolaTypes';
import { handleInviolaError, resetErrors, setSuccess } from './error';
import { resendResetConfirmationCode, setResetPasswordAsked } from './reset';
import { setPointsSyncInProgress } from './catalog';
import { setSyncInProgress, setLogoutSyncInProgress } from './public';
import { getSession } from './session';

const setCustomer = (
  loginData: InviolaResponse<InviolaLoginData>,
  rememberMe: boolean,
): SetCustomer => ({
  type: InviolaActionTypes.SET_CUSTOMER,
  payload: {
    loginData,
    rememberMe,
  },
});

export const resetCustomer = (): ResetCustomer => ({
  type: InviolaActionTypes.RESET_CUSTOMER,
});

export const setChangePasswordInProgress = (status: boolean): SetChangePasswordInProgress => ({
  type: InviolaActionTypes.SET_CHANGE_PASSWORD_IN_PROGRESS,
  payload: status,
});

export const resetCustomerRegistrationWizard = (): RegistrationWizardReset => ({
  type: InviolaActionTypes.REGISTRATION_WIZARD_RESET,
});

const storeCustomerRegistration = (customerCredentials: Partial<RegistrationCredentials>) => ({
  type: InviolaActionTypes.REGISTRATION_STORE,
  payload: customerCredentials,
});

const setRegistrationWithoutCardConfirmation = (): RegistrationCodeSent => ({
  type: InviolaActionTypes.REGISTRATION_WITHOUT_CARD_CONFIRMATION,
});

const setRegistrationCardWithoutEmailConfirmation = (): RegistrationCardWithoutEmailConfirmation => ({
  type: InviolaActionTypes.REGISTRATION_CARD_WITHOUT_EMAIL_CONFIRMATION,
});

const setRegistrationCardWithoutEmailValidated = (
  customerId: string, customer: InviolaCustomer,
): RegistrationCardWithoutEmailValidated => ({
  type: InviolaActionTypes.REGISTRATION_CARD_WITHOUT_EMAIL_VALIDATED,
  payload: { customerId, customer },
});

const setRegistrationCardWithEmailConfirmation = (
  customerId: string, customer: InviolaCustomer,
): RegistrationCardWithEmailConfirmation => ({
  type: InviolaActionTypes.REGISTRATION_CARD_WITH_EMAIL_CONFIRMATION,
  payload: { customerId, customer },
});

const setRegistrationCardWithEmailValidated = (): RegistrationCardWithEmailValidated => ({
  type: InviolaActionTypes.REGISTRATION_CARD_WITH_EMAIL_VALIDATED,
});

const setRegistrationConfirmationCredentials = (
  confirmCredentials: ConfirmRegistrationCredentials,
): SetRegistrationConfirmationCredentials => ({
  type: InviolaActionTypes.REGISTRATION_CONFIRMATION_STORE,
  payload: { confirmCredentials },
});

export const setRegistrationConfirmed = (): RegistrationConfirmed => ({
  type: InviolaActionTypes.REGISTRATION_CONFIRMED,
});

export const setCustomerSaved = (isSaved = false): SetCustomerSaved => ({
  type: InviolaActionTypes.SET_CUSTOMER_SAVED,
  payload: isSaved,
});

export const setCustomerUpdated = (userData: InviolaResponse<InviolaModifyData>): SetCustomerUpdated => ({
  type: InviolaActionTypes.SET_CUSTOMER_UPDATED,
  payload: userData,
});

const setCardMovements = (movements: InviolaResponse<InviolaMovementsData>): SetCardMovements => ({
  type: InviolaActionTypes.SET_CARD_MOVEMENTS,
  payload: movements,
});

export const setCardMovementsSyncInProgress = (status = false): SetCardMovementsSyncInProgress => ({
  type: InviolaActionTypes.SET_CARD_MOVEMENTS_SYNC_IN_PROGRESS,
  payload: status,
});

const setCustomerPoints = (points: number): SetCustomerPoints => ({
  type: InviolaActionTypes.SET_CUSTOMER_POINTS,
  payload: points,
});

export const setCustomerRedirectPath = (params: string | null): SetCustomerRedirectPath => ({
  type: InviolaActionTypes.SET_CUSTOMER_REDIRECT_PARAMS,
  payload: params,
});

export const logoutCustomer = (): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    dispatch(setLogoutSyncInProgress(true));
    const customerSessionId = await dispatch(getSession('customer'));

    try {
      await logout(customerSessionId);
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.LogoutFormError));
    } finally {
      dispatch(resetCustomer());
      dispatch(setLogoutSyncInProgress(false));
    }
  }
);

export const loginCustomer = (customerCredentials: LoginCredentials): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    dispatch(setSyncInProgress(true));

    try {
      const formattedCredentials = formatLoginCredentials(customerCredentials);
      const loginResponse = await login(formattedCredentials);

      dispatch(setCustomer(loginResponse, customerCredentials.rememberme === '1'));
      dispatch(setSyncInProgress(false));
    } catch (error) {
      if (error instanceof HttpError && error.code === InviolaHttpErrors.OPERATOR_DISABLED) {
        const userEmail = customerCredentials.username;

        dispatch(setResetPasswordAsked(userEmail));
        await dispatch(resendResetConfirmationCode(userEmail));
        dispatch(setSyncInProgress(false));
        return;
      }

      dispatch(handleInviolaError(error, InviolaAppError.LoginFormError));
      dispatch(setSyncInProgress(false));
    }
  }
);

export const validateExistingCard = (cardNumber: string): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    dispatch(setSyncInProgress(true));

    const syncSessionId = await dispatch(getSession('sync'));

    try {
      const response = await validateCardProxy({ syncSessionId, cardNumber });
      const { customerId, email, personalInfo } = response;
      const customer = getInviolaRegistrationCardCustomer(+customerId, personalInfo);

      if (email?.length) {
        await verifyCustomerByEmailProxy({ syncSessionId, customerId, email });

        dispatch(setRegistrationCardWithEmailConfirmation(customerId, customer));
      } else {
        dispatch(setRegistrationCardWithoutEmailValidated(customerId, customer));
      }

      dispatch(setSyncInProgress(false));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.RegistrationFormError));
      dispatch(setSyncInProgress(false));
    }
  }
);

export const verifyExistingCardWithValidationCode = (
  confirmCredentials: ConfirmRegistrationCredentials,
): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    const { registrationCustomerId = '', registrationCredentials, registrationStep } = getState().inviola;
    const syncSessionId = await dispatch(getSession('sync'));

    try {
      const { customer: { campaignId } } = await verifyCardValidationCodeProxy({
        username: registrationCredentials?.email ?? '',
        customerId: registrationCustomerId,
        code: confirmCredentials.verificationcode,
        password: confirmCredentials.passwordnew,
        syncSessionId,
      });
      dispatch(setRegistrationConfirmationCredentials({
        ...confirmCredentials, campaignId,
      }));
      if (registrationStep === InviolaRegistrationSteps.RegistrationCardWithEmailConfirmation) {
        dispatch(setRegistrationCardWithEmailValidated());
      }
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.RegistrationFormError));
      await dispatch(verifyErrorCodeAndResendVerificationCode());
    }
  }
);

export const resendVerificationCode = (): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    const syncSessionId = await dispatch(getSession('sync'));
    const { registrationCustomerId = '', registrationCredentials, registrationStep } = getState().inviola;

    dispatch(setSyncInProgress(true));

    try {
      switch (registrationStep) {
        case InviolaRegistrationSteps.RegistrationCardWithEmailConfirmation:
        case InviolaRegistrationSteps.RegistrationCardWithoutEmailConfirmation:
          await verifyCustomerByEmailProxy({
            syncSessionId,
            customerId: registrationCustomerId,
            email: registrationCredentials?.email ?? '',
          });
          break;
        case InviolaRegistrationSteps.RegistrationInit: default:
          await verifyCustomerByEmail({
            session: syncSessionId,
            email: registrationCredentials?.email ?? '',
          });
          break;
      }
      dispatch(setSuccess(InviolaAppSuccess.AuthorizationCodeResent));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.RegistrationFormError));
    } finally {
      dispatch(setSyncInProgress(false));
    }
  }
);

export const verifyErrorCodeAndResendVerificationCode = (): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    const syncSessionId = await dispatch(getSession('sync'));
    const {
      registrationCustomerId = '', registrationCredentials, registrationStep, error,
    } = getState().inviola;

    try { /** SPECIAL CASE FOR CARDS - VERIFICATION CODE IS EXPIRED  */
      if (InviolaSpecificErrorCodes.InvalidPassword === Number(error?.code) && [
        InviolaRegistrationSteps.RegistrationCardWithoutEmailConfirmation,
        InviolaRegistrationSteps.RegistrationCardWithEmailConfirmation,
      ].includes(registrationStep)) {
        await verifyCustomerByEmailProxy({
          syncSessionId,
          customerId: registrationCustomerId,
          email: registrationCredentials?.email ?? '',
        });
        dispatch(setSuccess(InviolaAppSuccess.VerificationCodeResent));
      }
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.RegistrationFormError));
    }
  }
);

export const registerConfirmedCustomer = (
  confirmCredentials: ConfirmRegistrationCredentials,
): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    const { registrationCustomerId = '', registrationCredentials, registrationStep } = getState().inviola;
    const syncSessionId = await dispatch(getSession('sync'));

    dispatch(setSyncInProgress(true));

    try {
      switch (registrationStep) {
        case InviolaRegistrationSteps.RegistrationCardWithEmailConfirmation:
          // eslint-disable-next-line no-case-declarations
          await dispatch(verifyExistingCardWithValidationCode(confirmCredentials));
          break;
        case InviolaRegistrationSteps.RegistrationCardWithoutEmailConfirmation:
          await dispatch(verifyExistingCardWithValidationCode(confirmCredentials));
          await dispatch(registerCustomerWithCard(
            syncSessionId,
            registrationCustomerId,
            getState().inviola.confirmCredentials as ConfirmRegistrationCredentials,
            registrationCredentials,
          ));
          break;
        case InviolaRegistrationSteps.RegistrationWithoutCardConfirmation: default:
          await dispatch(registerCustomerWithoutCard(syncSessionId, confirmCredentials, registrationCredentials));
          break;
      }
      dispatch(setSyncInProgress(false));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.RegistrationFormError));
      dispatch(setSyncInProgress(false));
    }
  }
);

const registerCustomerWithCard = (
  syncSessionId: string,
  customerId: string,
  confirmCredentials: ConfirmRegistrationCredentials,
  registrationCredentials?: Partial<RegistrationCredentials>,
): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    try {
      await registerCustomerProxy({
        customerId,
        syncSessionId,
        campaignId: confirmCredentials?.campaignId,
        ...getProxyCustomerInformation(registrationCredentials),
      });
      dispatch(setRegistrationConfirmed());

      await dispatch(loginCustomer({
        password: confirmCredentials.passwordnew,
        username: registrationCredentials?.email ?? '',
        rememberme: '',
      }));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.RegistrationFormError));
      await dispatch(verifyErrorCodeAndResendVerificationCode());
    }
  }
);

const registerCustomerWithoutCard = (
  syncSessionId: string,
  confirmCredentials: ConfirmRegistrationCredentials,
  registrationCredentials?: Partial<RegistrationCredentials>,
): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    const credentials = {
      ...confirmCredentials,
      ...registrationCredentials,
      session: syncSessionId,
    } as RegistrationCredentials;

    try {
      await registerWithCredentials(credentials);
      dispatch(setRegistrationConfirmed());

      await dispatch(loginCustomer({
        password: confirmCredentials.passwordnew,
        username: registrationCredentials?.email ?? '',
        rememberme: '',
      }));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.RegistrationFormError));
    }
  }
);

export const registerNewCustomer = (customer: InviolaCustomer): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    dispatch(setSyncInProgress(true));

    const syncSessionId = await dispatch(getSession('sync'));
    const {
      registrationCustomerId = '', confirmCredentials, registrationStep, registrationCredentials,
    } = getState().inviola;
    const isCardWithEmailValidated = registrationStep === InviolaRegistrationSteps.RegistrationCardWithEmailValidated;
    const usePreDefinedEmail = isCardWithEmailValidated ? { email: registrationCredentials?.email } : {};
    const newCustomer = { ...getCustomerInformation(customer), ...usePreDefinedEmail };

    try {
      dispatch(storeCustomerRegistration(newCustomer));

      if (isCardWithEmailValidated && confirmCredentials) {
        await dispatch(registerCustomerWithCard(
          syncSessionId, registrationCustomerId, confirmCredentials, newCustomer,
        ));
      } else {
        await dispatch(verifyCustomerBySendCodeOnEmail(customer.email));
      }
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.RegistrationFormError));
    } finally {
      dispatch(setSyncInProgress(false));
    }
  }
);

export const verifyCustomerBySendCodeOnEmail = (email: string): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    const { registrationCustomerId = '', registrationStep } = getState().inviola;
    const syncSessionId = await dispatch(getSession('sync'));

    try {
      const { isExist } = await verifyCustomerEmailUniquenessProxy(email);

      if (isExist) {
        return Promise.reject(new HttpError(1099, InviolaAppError.RegistrationFormError));
      }

      switch (registrationStep) {
        case InviolaRegistrationSteps.RegistrationCardWithoutEmailValidated:
          await verifyCustomerByEmailProxy({ syncSessionId, customerId: registrationCustomerId, email });
          dispatch(setRegistrationCardWithoutEmailConfirmation());
          break;
        case InviolaRegistrationSteps.RegistrationInit:
          await verifyCustomerByEmail({ session: syncSessionId, email });
          dispatch(setRegistrationWithoutCardConfirmation());
          break;
        default: break;
      }
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.RegistrationFormError));
    }
  }
);

export const modifyCustomerProfile = (customer: InviolaCustomer): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    dispatch(setSyncInProgress(true));

    const customerSessionId = await dispatch(getSession('customer'));

    try {
      const response = await modifyCustomer(customerSessionId, getCustomerInformation(customer));

      dispatch(setCustomerUpdated(response));
      dispatch(setSuccess(InviolaAppSuccess.UserModified));
      dispatch(setSyncInProgress(false));
      setTimeout(() => dispatch(resetErrors()), 3000);
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.SettingsFormError));
      dispatch(setSyncInProgress(false));
    }
  }
);

export const deleteCustomerProfile = (): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    dispatch(setSyncInProgress(true));

    const customerSessionId = await dispatch(getSession('customer'));

    try {
      const { customer } = getState().inviola;
      const profile = customer ? getCustomerInformation(customer) : null;

      if (profile) {
        await deleteCustomer(customerSessionId, { ...profile, card: customer?.cardNumber });
        dispatch(setSuccess(InviolaAppSuccess.AccountDeleted));
      } else {
        const profileError = new HttpError(881, InviolaAppError.DeleteAccountError);
        dispatch(handleInviolaError(profileError, InviolaAppError.DeleteAccountError));
      }
    } catch (error) {
      const apiError = new HttpError(881, InviolaAppError.DeleteAccountError);
      dispatch(handleInviolaError(apiError, InviolaAppError.DeleteAccountError));
    } finally {
      dispatch(setSyncInProgress(false));
    }
  }
);

export const getCardMovements = (): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    dispatch(setCardMovementsSyncInProgress(true));

    const customerSessionId = await dispatch(getSession('customer'));

    try {
      const response = await getMovements(customerSessionId);

      dispatch(setCardMovements(response));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAPIError.GetMovementsError));
    } finally {
      dispatch(setCardMovementsSyncInProgress(false));
    }
  }
);

export const getCustomerProfile = (): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    dispatch(setSyncInProgress(true));

    const customerSessionId = await dispatch(getSession('customer'));

    try {
      const response = await getCustomer(customerSessionId);

      dispatch(setCustomerUpdated(response));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAPIError.SyncError));
    } finally {
      dispatch(setSyncInProgress(false));
    }
  }
);

export const getCustomerPoints = (): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    dispatch(setPointsSyncInProgress(true));

    const customerSessionId = await dispatch(getSession('customer'));

    try {
      const response = await getCustomer(customerSessionId);
      // eslint-disable-next-line camelcase
      dispatch(updateCustomerPoints(response?.data?.customer?.balance_points ?? 0));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAPIError.LoginWithTokenError));
    } finally {
      dispatch(setPointsSyncInProgress(false));
    }
  }
);

export const changeCustomerPassword = (params: ChangePasswordParams): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    dispatch(setChangePasswordInProgress(true));

    const customerSessionId = await dispatch(getSession('customer'));

    try {
      await modifyPassword(customerSessionId, params.password);

      dispatch(setSuccess(InviolaAppSuccess.PasswordChanged));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAppError.ChangePasswordError));
    } finally {
      dispatch(setChangePasswordInProgress(false));
    }
  }
);

export const updateCustomerPoints = (points: number): Action => (
  (dispatch): void => {
    try {
      dispatch(setCustomerPoints(points));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAPIError.GetMovementsError));
    }
  }
);
