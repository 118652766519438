import { DeliveryClient, IQueryConfig, TypeResolver } from '@kentico/kontent-delivery';
import Configuration from '@app/services/kentico/types/configuration';
import MenuItem from '@app/services/kentico/types/menu_item';
import WebPartner from '@app/services/kentico/types/web_partner';
import WebPartnerBottom from '@app/services/kentico/types/web_partner_bottom';
import WebDepartment from '@app/services/kentico/types/web_department';
import WebSocial from '@app/services/kentico/types/web_social';
import ExternalLink from '@app/services/kentico/types/external_link';
import RelatedMenuItem from '@app/services/kentico/types/related_menu_item';
import News from '@app/services/kentico/types/news';
import Video from '@app/services/kentico/types/video';
import PhotoGallery from '@app/services/kentico/types/photo_gallery';
import { IHeader } from '@kentico/kontent-core';
import { Player } from '@app/services/kentico/types/player';
import { Team } from '@app/services/kentico/types/team';
import { WebPageTitle } from '@app/services/kentico/types/web_page_title';
import { WebLanding } from '@app/services/kentico/types/web_landing';
import { WebCard } from '@app/services/kentico/types/web_card';
import { WebShare } from '@app/services/kentico/types/web_share';
import WebCarousel from '@app/services/kentico/types/web_carousel';
import WebCarouselItem from '@app/services/kentico/types/web_carousel_item';
import { WebHomeElement } from '@app/services/kentico/types/web_home_element';
import WebPerson from '@app/services/kentico/types/web_person';
import CtaButton from '@app/services/kentico/types/cta_button';
import { WebPageImage } from '@app/services/kentico/types/web_page_image';
import { WebPageVideo } from '@app/services/kentico/types/web_page_video';
import { WebTabs } from '@app/services/kentico/types/web_tabs';
import { WebTabItem } from '@app/services/kentico/types/web_tab_item';
import { WebIframe } from '@app/services/kentico/types/web_iframe';
import Competition from '@app/services/kentico/types/competition';
import { Game } from '@app/services/kentico/types/game';
import { WebLiveVideo } from '@app/services/kentico/types/web_live_video';
import { WebAdvertisementBanner } from '@app/services/kentico/types/web_advertisement_banner';
import { WebMatchbox } from '@app/services/kentico/types/web_matchbox';
import { WebInviolaMembershipCard } from '@app/services/kentico/types/web_inviola_membership_card';
import { WebLineCarousel } from '@app/services/kentico/types/web_line_carousel';
import { Promotion } from '@app/services/kentico/types/promotion';

/** Kentico API Configuration */
const API_PROJECT_ID = process.env.API === 'staging'
  ? '3bc863ac-c0b1-0107-f06d-518abde3e680' // DEV project id
  : 'cd3284b5-8825-0108-20f0-25adabeb43a9'; // PROD project id
const API_ACCOUNT_KEY = process.env.API === 'staging'
  ? 'rymDzGebw' // DEV access key
  : 'rymDzGebw'; // PROD access key
const API_SOURCE_URL = process.env.API === 'staging'
  ? 'https://dev-api-gateway.onrewind.tv/cms-service-api' // DEV source url
  : 'https://api-gateway.onrewind.tv/cms-service-api'; // PROD source url

export const kenticoClient = (): DeliveryClient => {
  // todo: get projectId from config
  const client = new DeliveryClient({
    projectId: API_PROJECT_ID,
    typeResolvers: [
      new TypeResolver('configuration', () => new Configuration()),
      new TypeResolver('menu_item', () => new MenuItem()),
      new TypeResolver('web_page_title', () => new WebPageTitle()),
      new TypeResolver('web_page_video', () => new WebPageVideo()),
      new TypeResolver('web_landing', () => new WebLanding()),
      new TypeResolver('web_card', () => new WebCard()),
      new TypeResolver('web_partner', () => new WebPartner()),
      new TypeResolver('web_partner_bottom', () => new WebPartnerBottom()),
      new TypeResolver('web_department', () => new WebDepartment()),
      new TypeResolver('web_social', () => new WebSocial()),
      new TypeResolver('web_share', () => new WebShare()),
      new TypeResolver('web_carousel', () => new WebCarousel()),
      new TypeResolver('web_carousel_item', () => new WebCarouselItem()),
      new TypeResolver('web_home_element', () => new WebHomeElement()),
      new TypeResolver('external_link', () => new ExternalLink()),
      new TypeResolver('related_menu_item', () => new RelatedMenuItem()),
      new TypeResolver('player', () => new Player()),
      new TypeResolver('team', () => new Team()),
      new TypeResolver('news', () => new News()),
      new TypeResolver('video', () => new Video()),
      new TypeResolver('photo_gallery', () => new PhotoGallery()),
      new TypeResolver('cta_button', () => new CtaButton()),
      new TypeResolver('web_person', () => new WebPerson()),
      new TypeResolver('web_page_image', () => new WebPageImage()),
      new TypeResolver('web_tabs', () => new WebTabs()),
      new TypeResolver('web_tab_item', () => new WebTabItem()),
      new TypeResolver('web_iframe', () => new WebIframe()),
      new TypeResolver('web_live_video', () => new WebLiveVideo()),
      new TypeResolver('competition', () => new Competition()),
      new TypeResolver('game', () => new Game()),
      new TypeResolver('web_advertisement_banner', () => new WebAdvertisementBanner()),
      new TypeResolver('web_matchbox', () => new WebMatchbox()),
      new TypeResolver('web_inviola_membership_card', () => new WebInviolaMembershipCard()),
      new TypeResolver('web_line_carousel', () => new WebLineCarousel()),
      new TypeResolver('promotion', () => new Promotion()),
    ],
    globalHeaders: (queryConfig: IQueryConfig): IHeader[] => {
      let headers = queryConfig.customHeaders;
      if (!headers) {
        headers = [];
      }
      if (API_ACCOUNT_KEY) {
        headers.push({
          header: 'x-account-key',
          value: API_ACCOUNT_KEY,
        });
      }
      return headers;
    },
    proxy: {
      advancedProxyUrlResolver: (data): string => {
        const {
          domain, projectId, action, queryString,
        } = data;
        return (API_SOURCE_URL || `${domain}/${projectId}`) + action + queryString;
      },
    },
  });
  return client;
};
