import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';

import AppRoutes from '@app/constants/routesConstants';
import { ImageWidthMap } from '@app/types/imageTypes';
import { EditorialContentTypes, Video } from '@app/types/newsTypes';
import { COVER_IMAGE_TYPE } from '@app/constants/imageConstants';

import { useNewsDetailsPath, useNewsUrlParams } from '@app/components/Hooks';
import { useIsConnected } from '@app/components/Inviola/InviolaHooks';

import { CoverImage } from '@app/components/Image';
import { VideoPlayer, Modal } from '@app/components';
import { LocalizedLink } from '@app/components/Localization';
import { PremiumAccessNotification } from '@app/components/Inviola';
import { ImageElement } from '@app/components/Image/LazyImage';
import NewsItemInfo from '@app/components/News/NewsItemInfo';

interface HomeVideoPreviewElementProps {
  data: Video;
  imageWidthMap?: ImageWidthMap;
  shiftInfo?: boolean;
  current?: boolean;
}

const HomeVideoPreviewElement = ({
  data, shiftInfo = false, current = false,
}: HomeVideoPreviewElementProps): React.ReactElement => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const { image, video, title, categoryCode, publicationDate, isPremium } = data;
  const { formatMessage } = useIntl();
  const linksParams = useNewsUrlParams(data);
  const redirectPath = useNewsDetailsPath(data);

  const isConnected = useIsConnected();
  const isPrivate = isPremium && !isConnected;

  const startPlay = current && (!isPremium || !isPrivate);

  const onCloseModal = (): void => setIsModalOpened(false);
  const onVideoItemClick = (event: React.MouseEvent): void => {
    if (isPrivate) {
      setIsModalOpened(true);
      event.preventDefault(); // STOP:REDIRECT:TO:VIDEO:DETAILS:PAGE
    }
  };

  return (
    <>
      <VideoPreviewContainer
        pathKey={AppRoutes.CommonPage.path}
        pathParams={linksParams}
        onClick={onVideoItemClick}
      >
        <VideoPreviewImageContainer>
          <CoverImage image={image} useLazyLoading={false} coverType={COVER_IMAGE_TYPE.TOP_CAROUSEL_DESKTOP} />
          <VideoWrapper>
            <VideoPlayer src={video.url} startPlay={startPlay} showControls={false} preview />
          </VideoWrapper>
          <VideoPreviewDataContainer home={shiftInfo}>
            {data.type === EditorialContentTypes.VIDEO && (
              <MainNewsContentIcon src="/images/play.svg" alt={formatMessage({ id: 'icon.playVideo.alt' })} />
            )}
            <MainNewsInnerContainer home={shiftInfo}>
              <MainNewsInfoContainer shiftInfo={shiftInfo}>
                <NewsItemInfo
                  time={publicationDate}
                  category={categoryCode}
                  title={title}
                  size="large"
                  isPremium={isPremium}
                />
              </MainNewsInfoContainer>
            </MainNewsInnerContainer>
          </VideoPreviewDataContainer>
        </VideoPreviewImageContainer>
      </VideoPreviewContainer>
      <Modal open={isModalOpened} onClose={onCloseModal} contentFullWidth={false} controls={false}>
        <PremiumAccessNotification onLogin={onCloseModal} onCancel={onCloseModal} redirectPath={redirectPath} />
      </Modal>
    </>
  );
};

const NewsItemImageContainer = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 50px; // required for correct lazy-load on tablets and desktop
  height: 100%;
  border-radius: 5px;
  color: white;
`;

const VideoPreviewImageContainer = styled(NewsItemImageContainer)`
  border-radius: 0;
  position: relative;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 32%, #000000);
  z-index: 1;
`;

export const NewsItemLinkContainer = styled(LocalizedLink)`
  cursor: pointer;
  height: 100%;
  display: block;

  ${ImageElement} {
    transition: transform 2000ms ease-out;
    width: 100%;
  }

  &:hover ${ImageElement} {
    transform: scale(1.1,1.1);
  }
`;

export const NewsInfoContainer = styled.div<{ shiftInfo?: boolean }>`
  position: absolute;
  z-index: 2;
  padding: 20px !important;
  bottom: ${({ shiftInfo }): string => (shiftInfo ? '30px' : '0')};
`;

export const MainNewsInfoContainer = styled(NewsInfoContainer)`
  padding: 0;

  @media ${Devices.tablet} {
    padding-bottom: 60px;
  }
`;

const VideoPreviewContainer = styled(NewsItemLinkContainer)`
  max-width: 1280px;
  height: auto;
  margin: auto;
  overflow: hidden;
  position: relative;
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0; width: 100%;
  left: 0; height: 100%;
  overflow: hidden;
  z-index: 5;
`;

const VideoPreviewDataContainer = styled.div<{ home: boolean }>`
  position: absolute;
  bottom: ${({ home }): string => (home ? '50px' : '0')};
  top: 0;
  width: 100%;
  z-index: 6;
`;

const MainNewsInnerContainer = styled.div<{ home: boolean }>`
  max-width: 920px;
  margin: ${({ home }): string => (home ? '0 0 50px' : 'auto')};

  @media ${Devices.desktopSmall} {
    margin: ${({ home }): string => (home ? '0 0 50px 50px' : 'auto')};
  }
`;

const MainNewsContentIcon = styled.img`
  display: block;
  width: 30px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 5;

  @media ${Devices.tablet} {
    width: 50px;
    top: 50%;
  }

  @media ${Devices.desktopSmall} {
    width: 70px;
  }
`;

export default HomeVideoPreviewElement;
