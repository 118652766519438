import styled, { createGlobalStyle } from 'styled-components';
import { Devices } from '@app/styles';

export const GlobalStyle = createGlobalStyle<{ hideOverflow: boolean }>`
  body {
    overflow: ${(props) => (props.hideOverflow ? 'hidden' : 'auto')};
  }
`;

export const Container = styled.div<{ fullScreenMode?: boolean; isLocked?: boolean }>`
  position: relative;
  display: flex;
  min-width: 100%;
  min-height: 100%;

  ${({ fullScreenMode }) => fullScreenMode && `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    overflow: hidden;

    ${ProgressTime} {
      font-size: 1.6rem;
    }

    ${ControlButton} {
      height: 2rem;
      width: 2rem;
    }

    ${Title} {
      font-size: 2.625rem;
      line-height: 3.375rem;
    }
  `}

  &:after {
    display: ${({ isLocked }) => (isLocked ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    background: transparent;
    cursor: pointer;

    z-index: 10;
  }
`;

export const InvalidVideoPlayer = styled.div`
  width: 100%;
  max-height: 460px;
  background-color: black;
`;

export const Overlay = styled.div<{ visible?: boolean }>`
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};

  position: absolute;
  bottom: 0;

  width: 100%;

  padding: 25px;

  opacity: 0;
  visibility: hidden;

  transition: visibility 0s 500ms, opacity 500ms ease-out;

  ${({ visible }) => visible && `
    opacity: 1;
    visibility: visible;

    transition: opacity 500ms ease-out;
  `}

  @media ${Devices.tabletLarge} {
    padding: 50px;
  }
`;

export const Video = styled.video<{ cover?: boolean }>`
  width: 100%;
  height: auto;
  ${({ cover }) => cover && `
    object-fit: cover;
    width: auto;
    min-width: 100%;
    min-height: 100%;
  `}
  `;

export const VideoContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  background-color: black;
`;

export const Title = styled.div`
  color: white;

  font-weight: bold;
  text-transform: uppercase;

  font-size: 1.4rem;

  max-width: 86%;

  /* Line clamping: https://caniuse.com/#search=line-clamp */
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;

  @media ${Devices.tabletLarge} {
    font-size: 1.625rem;
    line-height: 2.375rem;
  }
`;

export const Error = styled.div`
  color: white;

  font-size: 1.4rem;

  @media ${Devices.tabletLarge} {
    font-size: 1.625rem;
    line-height: 2.375rem;
  }
`;

export const Badge = styled.div<{ badgeColor: string | undefined }>`
  background-color: ${(props) => props.badgeColor || props.theme.color.purple};

  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 10px;

  color: white;
  text-transform: uppercase;

  font-size: 0.75rem;
  font-weight: bold;
`;

export const ControlsBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 25px;
  justify-content: left;
  align-items: center;

  margin-top: 25px;

  & div:first-child {
    margin-left: 0;
  }

  & div:last-child {
    margin-right: 0;
  }

  @media ${Devices.tabletLarge} {
    margin-top: 50px;
  }
`;

export const ControlContainer = styled.div`
  display: flex;

  margin: 0 15px;

  @media ${Devices.tabletLarge} {
    margin: 0 25px;
  }
`;

export const ControlButton = styled.button`
  cursor: pointer;
  user-select: none;

  display: flex;

  height: 18px;
  width: 18px;

  opacity: 0.8;
  transition: opacity 300ms ease-out;

  background: transparent;
  border: 0;
  padding: 0;

  &:hover {
    opacity: 1;
  }

  @media ${Devices.tabletLarge} {
    width: 23px;
    height: 23px;
  }
`;

export const ControlImg = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const ProgressTime = styled.span`
  display: none;

  color: white;

  font-size: 14px;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};

  text-align: center;
  width: 9%;

  margin-left: 5px;
  margin-right: 10px;

  @media ${Devices.tabletLarge} {
    display: block;
  }
`;

const progressBarHandleSize = 15;
export const ProgressBarBar = styled.div`
  position: relative;

  height: 8px;
  flex: 6.4;

  border-radius: 4px;

  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 15px;

  @media ${Devices.tabletLarge} {
    margin: 0 25px;
  }
`;

export const ProgressBarBlock = styled.div`
  position: absolute;
  bottom: -50px;
  top: -200px;
  left: 0;
  right: 0;
`;

export const ProgressBarProgress = styled.div<{ percent: number }>`
    content: '';
    position: absolute;
    left: 0;
    height: 100%;

    border-radius: 4px;
    background-color: ${(props): string => props.theme.color.purple};

    width: ${(props) => props.percent}%;
`;

export const ProgressBarCurrent = styled.div<{ percent: number }>`
    content: '';

    position: absolute;
    top: -${progressBarHandleSize / 4}px;
    height: ${progressBarHandleSize}px;
    width: ${progressBarHandleSize}px;

    left: calc(${(props) => props.percent}% - ${progressBarHandleSize / 2}px);

    display: block;

    background-image: url('/images/oval.svg');
`;

export const ProgressBarControl = styled.div`
    content: '';
    position: absolute;
    top: 0;left:0;right:0;bottom:0;
    display: block;
`;

export const ProgressSeparator = styled.span`
  padding: 0 0.31rem;
`;
