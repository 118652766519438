import { CurrentRouteActions, CurrentRouteActionTypes } from '@app/store/actionTypes/currentRouteActionTypes';
import { LanguageType } from '@app/types/localizationTypes';

import { AppLanguages } from '@app/constants/localizationConstants';

export type CurrentRouteState = Record<LanguageType, string>;

const initialState: CurrentRouteState = AppLanguages.reduce((acc, lang) => {
  const state = { ...acc };
  state[lang] = `/${lang}`;
  return state;
}, {} as CurrentRouteState);

export default (
  state = initialState,
  action: CurrentRouteActions,
): CurrentRouteState => (
  action.type === CurrentRouteActionTypes.SET_CURRENT_ROUTE ? action.payload : state
);
