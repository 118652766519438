import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useContext, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { MountContext } from '@app/ReactContext';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales';

import AppRoutes from '@app/constants/routesConstants';
import { NewsNavigationTypeMap } from '@app/constants/newsConstants';

import { BaseNewsData, BaseNewsItem, EditorialContentTypes } from '@app/types/newsTypes';
import { PageType } from '@app/types/commonPageTypes';
import AppState from '@app/types/appStateTypes';
import { RouteParams } from '@app/types/routerTypes';
import { Errors, PageError } from '@app/types/errorTypes';

import { GetLatestNews, ResetLatestNewsAction } from '@app/store/actions/newsActions';

import { getCategoryUrl, getEditorialTypeByPageType, getNewsDetailsUrlParams } from '@app/helpers/newsHelpers';
import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { CommonPageBaseUrlParams } from '@app/helpers/routeHelpers';

import { useLanguage } from '@app/components/Hooks';
import { useCommonPageBaseMultiLangRoute, usePageType, useThirdLevelMenu } from '@app/pages/CommonPage/CommonPageHooks';

export const useCategoryUrl = (newsItem?: BaseNewsData | null): string | null => {
  const language = useLanguage();

  return useSelector<AppState, string | null>(
    (state) => (newsItem ? getCategoryUrl({ state, language, newsItem }) : null),
    isEqual,
  );
};

export const useNewsUrlParams = (item: BaseNewsData | null): Record<string, string> => {
  const language = useLanguage();
  const category = useCategoryUrl(item);
  const { formatMessage } = useIntl();

  return useSelector<AppState, Record<string, string>>(
    (state) => {
      // eslint-disable-next-line camelcase
      const menuItem = state.configuration[language]?.menu?.web_news;
      const subItem = menuItem?.navigation[NewsNavigationTypeMap[item?.type ?? EditorialContentTypes.NEWS]];

      return {
        topLevel: menuItem?.data?.url ?? '',
        secondLevel: subItem?.data?.url ?? '',
        thirdLevel: category || formatMessage({ id: 'news.landingThirdLevelUrl' }),
        forthLevel: item?.publicationDate ?? '',
        fifthLevel: item?.urlSlug ?? '',
      };
    },
    isEqual,
  );
};

export const useNewsDetailsPath = (item: BaseNewsData | null): string | null => {
  const language = useLanguage();
  const redirectParams = useNewsUrlParams(item);

  return redirectParams ? localizeRouteKey({
    pathKey: AppRoutes.CommonPage.path,
    pathParams: redirectParams,
  }, language) : null;
};

export const useAllNewsUrlParams = (type: EditorialContentTypes): Record<string, string> => {
  const language = useLanguage();

  return useSelector<AppState, Record<string, string>>(
    (state) => {
      // eslint-disable-next-line camelcase
      const menuItem = state.configuration[language]?.menu?.web_news;
      const subItem = menuItem?.navigation[NewsNavigationTypeMap[type]];
      const category = subItem?.categories?.[0];

      return {
        topLevel: menuItem?.data?.url ?? '',
        secondLevel: subItem?.data?.url ?? '',
        thirdLevel: category?.url ?? '',
        forthLevel: '',
        fifthLevel: '',
      };
    },
    isEqual,
  );
};

export const useNewsType = (): EditorialContentTypes => {
  const pageType = usePageType() as PageType;
  return getEditorialTypeByPageType(pageType);
};

export const useNewsDetailsMultiLangRoute = (type: EditorialContentTypes): void => {
  const params: RouteParams = useParams();
  const language = useLanguage();

  const newsRouteParams = useSelector<AppState, CommonPageBaseUrlParams>(
    (state) => getNewsDetailsUrlParams({
      state,
      params,
      language,
      type,
    }),
    isEqual,
  );

  useCommonPageBaseMultiLangRoute(newsRouteParams);
};

type UseLatestNews = (params: {
  type: EditorialContentTypes;
  requestLatestNews: GetLatestNews;
  resetLatestNews: ResetLatestNewsAction;
}) => BaseNewsItem[]

export const useLatestNews: UseLatestNews = ({ type, requestLatestNews, resetLatestNews }) => {
  const dispatch = useDispatch();
  const language = useLanguage();
  const { isInitialMount } = useContext(MountContext);

  const latestNews = useSelector<AppState, BaseNewsItem[]>(
    (state) => state.news[type].latestItems,
    shallowEqual,
  );

  const codeName = useSelector<AppState, string>(
    (state) => state.news[type].selectedItemDetails?.[language]?.codeName ?? '',
    isEqual,
  );

  useEffect(() => {
    if ((!isInitialMount || !latestNews?.length) && codeName) {
      dispatch(requestLatestNews({ codeName, language }));
    }

    return (): void => { dispatch(resetLatestNews()); };
  }, [language, codeName]);

  return latestNews;
};

export const useNewsLoadingState = (): boolean => (
  useSelector<AppState, boolean>(
    (state) => {
      const { isLoading, newsList } = state.news;
      return isLoading && !newsList.items.length;
    },
    shallowEqual,
  )
);

export const useError = (): Errors => (
  useSelector<AppState, Errors>(
    (state) => state.news.error,
    shallowEqual,
  )
);

export const useNewsDetailsPageValidation = (news: BaseNewsData | null): Errors => {
  const { thirdLevel = '' } = useParams();
  const language = useLanguage();
  const error = useError();
  const isLoading = useNewsLoadingState();
  const category = useThirdLevelMenu();
  const categoryUrl = useCategoryUrl(news);

  if (error) {
    return error;
  }
  const thirdLevelCalc: string = categoryUrl ?? Translations[language]['news.landingThirdLevelUrl'];
  const isIncorrectThirdLevelUrl = news && (category?.editorialCategory !== news?.categoryCode
    && thirdLevel !== thirdLevelCalc);
  return !isLoading && (news && isIncorrectThirdLevelUrl) ? PageError.NotFound : null;
};
