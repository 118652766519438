import { CareerItem, Season } from '@app/services/careers/types/responseTypes';

export function prepareCareerList(careerList: Array<CareerItem>): Array<CareerItem> {
  return careerList.map((careerItem: CareerItem) => ({
    ...careerItem,
    seasons: careerItem.seasons.map((season: Season) => ({
      ...season, season_name: season.season_name.replace(/\//, ' - '),
    })).sort((a, b) => Number(b.season_name.split(' - ')[0]) - Number(a.season_name.split(' - ')[0])),
  }));
}
