import React from 'react';
import styled from 'styled-components';

import { ContainerMedium } from '@app/components/WebTemplates/components';
import { NewsContentItem } from '@app/types/newsTypes';
import NewsContent from '@app/pages/NewsDetails/NewsContent';

interface WebEditorialElementProps {
  content: (NewsContentItem | null)[]
}

const WebEditorialElement = ({ content }: WebEditorialElementProps): React.ReactElement => (
  <WebEditorialElementContainer>
    <NewsContent content={content ?? []} />
  </WebEditorialElementContainer>
);

export const WebEditorialElementContainer = styled(ContainerMedium)`
  position: relative;
  width: 100%;
  overflow-x: auto;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.23px;
  line-height: 1.5;
  text-align: justify;
  color: black;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export default WebEditorialElement;
