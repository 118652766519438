import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { InviolaAppError } from '@app/services/inviola/types/errorTypes';
import { LoginCredentials } from '@app/services/inviola/types/requestTypes';

import { loginCustomer, resetErrors } from '@app/store/actions/inviolaActions';

import { Button, TextHint } from '@app/components/Inviola/InviolaComponents';
import { useInputValidations, useInviolaPath, useSyncInProgressSelector } from '@app/components/Inviola/InviolaHooks';
import { Checkbox, Input } from '@app/components/Inviola/InviolaFields';
import { InviolaErrorMessage } from '@app/components/Inviola/InviolaMessages';
import styled from 'styled-components';

const LoginForm = (): React.ReactElement => {
  const methods = useForm<LoginCredentials>({ mode: 'onChange' });
  const { handleSubmit, formState: { isValid } } = methods;

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { resetPath } = useInviolaPath();
  const validations = useInputValidations();
  const syncInProgress = useSyncInProgressSelector();

  const submit = (data: LoginCredentials) => {
    dispatch(resetErrors());
    dispatch(loginCustomer(data));
  };

  useEffect(() => (): void => { dispatch(resetErrors()); }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)}>
        <Input
          name="username"
          labelId="inviola.login.email"
          placeholderId="inviola.form.email.placeholder"
          type="email"
          validations={validations.email}
        />

        <Input
          type="password"
          name="password"
          labelId="inviola.login.password"
          placeholderId="inviola.form.password.placeholder"
          validations={validations.required}
        />
        <TextHint>
          <ForgotLink to={resetPath}>
            {formatMessage({ id: 'inviola.form.forgotPassword' })}
          </ForgotLink>
        </TextHint>

        <Checkbox name="rememberme" labelId="inviola.login.rememberMe" defaultValue="1" />

        <InviolaErrorMessage errorType={InviolaAppError.LoginFormError} />

        <Button type="submit" disabled={!isValid || syncInProgress}>
          {formatMessage({ id: 'inviola.login.submit' })}
        </Button>
      </form>
    </FormProvider>
  );
};

const ForgotLink = styled(Link)`
  color: ${(props): string => props.theme.color.purple};
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 12px;
  font-weight: 300;
`;

export default LoginForm;
