import { ContentItem, Elements } from '@kentico/kontent-delivery';

export default class Competition extends ContentItem {
    public competitionType: Elements.MultipleChoiceElement;
    public url: Elements.UrlSlugElement;
    public tags: Elements.TaxonomyElement;
    public logo: Elements.AssetsElement;
    public seasonOptaId: Elements.TextElement;
    public optaSdSeasonId: Elements.TextElement;
    public seasonName: Elements.TextElement;
    public competitionName: Elements.TextElement;
    public liveTextFeed: Elements.MultipleChoiceElement;
    public competitionOptaId: Elements.TextElement;
    public optaSdCompetitionId: Elements.TextElement;
    public teams: Elements.TaxonomyElement;
    public isActive: Elements.MultipleChoiceElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'competition_type') {
                    return 'competitionType';
                }
                if (elementName === 'season_opta_id') {
                    return 'seasonOptaId';
                }
                if (elementName === 'opta_sd_season_id') {
                    return 'optaSdSeasonId';
                }
                if (elementName === 'season_name') {
                    return 'seasonName';
                }
                if (elementName === 'competition_name') {
                    return 'competitionName';
                }
                if (elementName === 'live_text_feed') {
                    return 'liveTextFeed';
                }
                if (elementName === 'competition_opta_id') {
                    return 'competitionOptaId';
                }
                if (elementName === 'opta_sd_competition_id') {
                    return 'optaSdCompetitionId';
                }
                if (elementName === 'is_active') {
                    return 'isActive';
                }
                return elementName;
            })
        });
    }
}
