import { waitForConfigurationDownload } from '@app/store/actions/configurationActions';
import { setCurrentRoute } from '@app/store/actions/currentRouteActions';
import { getPlayerCareers, getPlayers, getPlayersStatistics } from '@app/store/actions/teamsActions';
import { getWebTemplateDataById, setWebTemplatePageMultiLangRoute } from '@app/store/actions/webTemplateActions';
import { getNewsByUrlSlug, getNewsList } from '@app/store/actions/newsActions';
import { setSeasonMultiLangUrl, getSchedule, getStandings } from '@app/store/actions/seasonActions';
import { setMatchCenterMultiLangUrl, getLocalizedGames, getMatchFeeds } from '@app/store/actions/matchActions';

import { Action } from '@app/types/actionTypes';
import { PageType } from '@app/types/commonPageTypes';
import { RouteParams } from '@app/types/routerTypes';
import { EditorialContentTypes } from '@app/types/newsTypes';
import { SubMenuId } from '@app/types/configurationTypes';

import AppRoutes from '@app/constants/routesConstants';

import { getPromotionByUrlSlug } from '@app/store/actions/promotionsActions';
import { getLastLevelMenuId, getPageType, getSecondLevelMenuItem } from '@app/helpers/menuHelpers';
import { CommonPageBaseUrlParams, getCommonPageBaseUrlParams } from '@app/helpers/routeHelpers';
import { getPlayerUrlParams } from '@app/helpers/teamsHelpers';
import { getEditorialTypeByPageType, getNewsDetailsUrlParams } from '@app/helpers/newsHelpers';

type CommonPageAction = (params: RouteParams, initialParams?: CommonPageBaseUrlParams) => Action

export const setCommonPageBaseMultiLangRoute: CommonPageAction = (urlParams, initialParams) => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();
    const state = getState();
    const params = getCommonPageBaseUrlParams(state, urlParams, initialParams);

    return dispatch(setCurrentRoute({
      pathId: AppRoutes.CommonPage.path,
      params,
    }));
  }
);

type RequestNewsDetails = (routeParams: RouteParams, type: EditorialContentTypes) => Action

const requestNewsDetails: RequestNewsDetails = (routeParams, type) => (
  async (dispatch, getState): Promise<unknown | unknown[]> => {
    const { language } = routeParams;
    return dispatch(getNewsByUrlSlug({
      ...routeParams,
      type,
    }))
      .then(() => {
        const state = getState();
        return dispatch(setCommonPageBaseMultiLangRoute(
          routeParams,
          getNewsDetailsUrlParams({
            state,
            params: routeParams,
            language,
            type,
          }),
        ));
      });
  }
);

export const getCommonPageDataForSsr: CommonPageAction = (params) => (
  async (dispatch, getState): Promise<unknown | unknown[]> => {
    if (!params.topLevel) return;

    await waitForConfigurationDownload();

    const {
      topLevel, secondLevel, thirdLevel = '', forthLevel = '', language,
    } = params;

    const state = getState();
    const secondLevelMenu = getSecondLevelMenuItem(state, topLevel, secondLevel, language);
    const pageType = getPageType(secondLevelMenu?.[language], forthLevel);

    switch (pageType) {
      case PageType.News:
        return Promise.all([
          dispatch(getNewsList({
            language,
            topLevel,
            secondLevel,
            thirdLevel,
            type: getEditorialTypeByPageType(pageType),
            withTopNews: true,
          })),
          dispatch(setCommonPageBaseMultiLangRoute(params)),
        ]);
      case PageType.NewsDetails:
        return dispatch(requestNewsDetails(params, EditorialContentTypes.NEWS));
      case PageType.PhotoGalleriesDetails:
        return dispatch(requestNewsDetails(params, EditorialContentTypes.PHOTO));
      case PageType.VideosDetails:
        return dispatch(requestNewsDetails(params, EditorialContentTypes.VIDEO));
      case PageType.Template:
        return Promise.all([
          dispatch(getWebTemplateDataById({
            id: getLastLevelMenuId({ state, language, params }) ?? '', language,
          })).then(() => {
            setWebTemplatePageMultiLangRoute(params);
          }),
        ]);
      case PageType.Team:
        return Promise.all([
          dispatch(setCommonPageBaseMultiLangRoute(params)),
          dispatch(getPlayers({ topLevel, teamType: secondLevel, language })).then(),
        ]);
      case PageType.PlayerProfile:
        return Promise.all([
          dispatch(getPlayers({ topLevel, teamType: secondLevel, language })).then(() => {
            dispatch(setCommonPageBaseMultiLangRoute(params, getPlayerUrlParams(state, params, language)));
          }),
          dispatch(getPlayersStatistics({ topLevel, teamType: secondLevel, language })),
          dispatch(getPlayerCareers()),
        ]);
      case PageType.Season:
        return Promise.all([
          dispatch(setSeasonMultiLangUrl(params)),
          secondLevelMenu?.[language]?.data?.id === SubMenuId.STANDINGS
            ? dispatch(getStandings(params))
            : dispatch(getSchedule(params)),
        ]);
      case PageType.MatchCenter:
        return Promise.all([
          dispatch(setMatchCenterMultiLangUrl(params)),
          dispatch(getLocalizedGames()),
          dispatch(getMatchFeeds(params)),
        ]);
      case PageType.InviolaPromotionsDetails:
        return dispatch(getPromotionByUrlSlug(params));
      default:
        return Promise.resolve();
    }
  }
);
