import { useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import AppState from '@app/types/appStateTypes';

import { MountContext } from '@app/ReactContext';
import { useLanguage } from '@app/components/Hooks';
import { getPromotions, resetPromotionsList } from '@app/store/actions/promotionsActions';
import { Promotion, PromotionListComponent } from '@app/types/promotionsTypes';
import { SubMenuId } from '@app/types/configurationTypes';

export const usePromotionsDownload = (): void => {
  const { isInitialMount } = useContext(MountContext);
  const language = useLanguage();

  const hasItems = useSelector<AppState, boolean>(
    (state) => !!state.promotions.list.items.length,
    shallowEqual,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    // check if data has been loaded on server before very first render
    if (!isInitialMount || !hasItems) {
      dispatch(getPromotions({
        language,
      }));
    }

    return (): void => { dispatch(resetPromotionsList()); };
  }, [language]);
};

export const usePromotionsList = (): PromotionListComponent => useSelector<AppState, PromotionListComponent>(
  (state) => ({
    ...state.promotions.list,
    isLoading: state.promotions.isLoading,
  }),
  shallowEqual,
);

export const useLoadMorePromotions = (): () => void => {
  const dispatch = useDispatch();
  const language = useLanguage();

  return (): void => {
    dispatch(getPromotions({
      language,
    }));
  };
};

export const usePromotionsLoadingState = (): boolean => (
  useSelector<AppState, boolean>(
    (state) => {
      const { isLoading, list } = state.promotions;
      return isLoading && !list.items.length;
    },
    shallowEqual,
  )
);

export const usePromotionUrlParams = (item: Promotion): Record<string, string> => {
  const language = useLanguage();

  return useSelector<AppState, Record<string, string>>(
    (state) => {
      // eslint-disable-next-line camelcase
      const menuItem = state.configuration[language]?.menu?.web_inviola;
      const subItem = menuItem?.navigation[SubMenuId.PROMOTIONS];

      return {
        topLevel: menuItem?.data?.url ?? '',
        secondLevel: subItem?.data?.url ?? '',
        thirdLevel: item.publicationDate,
        forthLevel: item.urlSlug,
        fifthLevel: '',
      };
    },
    isEqual,
  );
};
