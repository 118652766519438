import React from 'react';
import { useFormContext } from 'react-hook-form';

import { InviolaAllowedCountryIds } from '@app/constants/inviolaConstants';
import { mapSelectOptions } from '@app/helpers/inviolaHelpers';
import { InviolaCustomer } from '@app/types/inviolaTypes';
import { InviolaCustomerDynamicFields } from '@app/services/inviola/apiConstants';
import { Select } from './InviolaFields';
import { useGeoLevelState, useInputValidations } from './InviolaHooks';
import { Column, Row } from './InviolaComponents';
import InviolaDynamicField from './InviolaDynamicField';

interface InviolaGeoLevelSelectorProps {
  selectedCountry?: string
}

const InviolaGeoLevelSelector = ({ selectedCountry }: InviolaGeoLevelSelectorProps): React.ReactElement | null => {
  const { watch } = useFormContext<InviolaCustomer>();
  const validations = useInputValidations();

  const selectedGeoLevel1 = watch('geoLevel1');
  const selectedGeoLevel2 = watch('geoLevel2');
  const selectedGeoLevel3 = watch('geoLevel3');

  const [level1Loaded, level1Options] = useGeoLevelState(1, selectedCountry);
  const [level2Loaded, level2Options] = useGeoLevelState(2, selectedCountry, selectedGeoLevel1);
  const [level3Loaded, level3Options] = useGeoLevelState(3, selectedCountry, selectedGeoLevel2);
  const [level4Loaded, level4Options] = useGeoLevelState(4, selectedCountry, selectedGeoLevel3);

  if (!selectedCountry) return null;
  if (!InviolaAllowedCountryIds.includes(selectedCountry)) {
    return <InviolaDynamicField id={InviolaCustomerDynamicFields.ForeignCity} />;
  }

  return (
    <>
      <Row>
        { level1Loaded && (
          <Column>
            <Select
              name="geoLevel1"
              labelId="inviola.registration.geoLevel1"
              options={mapSelectOptions(level1Options)}
              validations={validations.required}
              emptyOption
            />
          </Column>
        )}
        { level2Loaded && (
          <Column>
            <Select
              name="geoLevel2"
              labelId="inviola.registration.geoLevel2"
              options={mapSelectOptions(level2Options)}
              validations={validations.required}
              emptyOption
            />
          </Column>
        )}
      </Row>
      <Row>
        { level3Loaded && (
          <Column>
            <Select
              name="geoLevel3"
              labelId="inviola.registration.geoLevel3"
              options={mapSelectOptions(level3Options)}
              validations={validations.required}
              emptyOption
            />
          </Column>
        )}
        { level4Loaded && level4Options.length > 0 && (
          <Column>
            <Select
              name="geoLevel4"
              labelId="inviola.registration.geoLevel4"
              options={mapSelectOptions(level4Options)}
              validations={validations.required}
              emptyOption
            />
          </Column>
        )}
      </Row>
    </>
  );
};

export default InviolaGeoLevelSelector;
