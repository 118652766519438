import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import AppRoutes from '@app/constants/routesConstants';
import { BaseNewsItem } from '@app/types/newsTypes';
import { Devices } from '@app/styles';

import { Modal } from '@app/components';
import { ThronImage } from '@app/components/Image';
import { NewsPremium } from '@app/components/News/NewsItemInfo';
import { LocalizedLink } from '@app/components/Localization';
import { PremiumAccessNotification } from '@app/components/Inviola';

import { useIsConnected } from '@app/components/Inviola/InviolaHooks';
import { useNewsUrlParams, useLanguage, useCurrentRoute } from '@app/components/Hooks';

interface NewsProps {
  news: BaseNewsItem;
  isPremium: boolean;
}

const News = ({ news, isPremium }: NewsProps): React.ReactElement => {
  const [isPremiumModalOpened, setIsPremiumModalOpened] = useState(false);
  const onClosePremiumModal = (): void => setIsPremiumModalOpened(false);

  const currentRoute = useCurrentRoute();
  const isConnected = useIsConnected();
  const language = useLanguage();

  const imageUrl = news?.image?.url;
  const linksParams = useNewsUrlParams(news);

  const onNewsClick = (event: React.MouseEvent): void => {
    if (isPremium && !isConnected) {
      setIsPremiumModalOpened(true);
      event.preventDefault(); // STOP:REDIRECT:TO:NEWS:DETAILS:PAGE
    }
  };

  return (
    <NewsContentLinkSection>
      <NewsContentLocalizedLink
        onClick={onNewsClick}
        pathKey={AppRoutes.CommonPage.path}
        pathParams={linksParams}
      >
        <NewsContentLinkImageContainer>
          {
            imageUrl && (
              <ThronImage useLazyLoading thronId={news.image.id} alt={news.image.description} />
            )
          }
        </NewsContentLinkImageContainer>
        <NewsContentLinkInfoContainer>
          <NewsContentLinkInfoHeading>
            <FormattedMessage id="news.additionalNews.title" />
          </NewsContentLinkInfoHeading>
          <NewsContentLinkInfoTitle>
            {news?.title ?? ''}
          </NewsContentLinkInfoTitle>
        </NewsContentLinkInfoContainer>
      </NewsContentLocalizedLink>
      {isPremium && (
        <NewsContentPremiumLabel>
          <NewsPremium size="small">
            <FormattedMessage id="premium.tag" />
          </NewsPremium>
        </NewsContentPremiumLabel>
      )}
      <Modal
        open={isPremiumModalOpened}
        onClose={onClosePremiumModal}
        contentFullWidth={false}
        controls={false}
      >
        <PremiumAccessNotification
          onLogin={onClosePremiumModal}
          onCancel={onClosePremiumModal}
          redirectPath={currentRoute[language]}
        />
      </Modal>
    </NewsContentLinkSection>
  );
};

export default News;

const NewsContentLinkImageContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const NewsContentLinkInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 0;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};

  @media ${Devices.mediumMobile} {
    padding: 10px 0 10px 10px;
  }
`;

const NewsContentLinkInfoHeading = styled.h4`
  text-transform: uppercase;
  padding-bottom: 20px;
  color: ${(props): string => props.theme.color.purple};
  font-size: 14px;
`;

const NewsContentLinkInfoTitle = styled.h3`
  text-transform: uppercase;
  overflow: hidden;
  font-size: 14px;
`;

const NewsContentLinkImage = styled.img`
  transition: transform 2000ms ease-out;
`;

const NewsContentLinkSection = styled.section`
  position: relative;
  border-top: 1px solid rgba(128,128,128,0.5);
  border-bottom: 1px solid rgba(128,128,128,0.5);
  padding: 10px 0;

  &:hover ${NewsContentLinkImage} {
    transform: scale(1.1,1.1);
  }
`;

const NewsContentPremiumLabel = styled.div`
  position: absolute;
  bottom: 20px;
  left: 10px;
`;

const NewsContentLocalizedLink = styled(LocalizedLink)`
  display: flex;
  flex-direction: column;
  color: inherit; // Use defined on page text color

  @media ${Devices.mediumMobile} {
    flex-direction: row;
  }

  & > * {
    flex: 0 0 50%;
  }
`;
