import React, { useState, useEffect } from 'react';
import { Prompt, useLocation, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { Button, ButtonOutline } from '@app/components/Inviola/InviolaComponents';
import { Modal } from '@app/components';

interface SaveChangesModalProps {
  open: boolean;
}

interface LocationProps {
  pathname: string;
}

const SaveChangesModal = ({ open }: SaveChangesModalProps): React.ReactElement => {
  const { pathname } = useLocation<LocationProps>();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [isUnloadAllowed, setIsUnloadAllowed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lastLocation, setLastLocation] = useState(pathname);

  const onNavigationBlock = ({ pathname }: LocationProps) => {
    if (!confirmedNavigation && open) {
      setLastLocation(pathname);
      setIsModalOpen(true);

      return false;
    }
    return true;
  };

  const onNavigationConfirm = () => {
    setIsModalOpen(false);
    setConfirmedNavigation(true);
  };

  const onPageUnload = (event: { returnValue: string }) => {
    setIsModalOpen(false);
    // eslint-disable-next-line no-param-reassign
    if (open && !isUnloadAllowed) event.returnValue = formatMessage({ id: 'inviola.profile.saveChanges.text' });
    // eslint-disable-next-line no-param-reassign
    if (isUnloadAllowed) event.returnValue = '';
  };

  useEffect(() => { // Block react routes
    if (confirmedNavigation && lastLocation) {
      setIsUnloadAllowed(true);
      history.push(lastLocation);
    }
  }, [confirmedNavigation, lastLocation, history]);

  useEffect(() => { // Block non-react routes
    window.addEventListener('beforeunload', onPageUnload);
    return () => window.removeEventListener('beforeunload', onPageUnload);
  }, [open, isUnloadAllowed]);

  return (
    <>
      <Prompt when message={onNavigationBlock} />
      <Modal
        open={isModalOpen}
        onClose={() => setIsUnloadAllowed(false)}
        contentFullWidth={false}
        controls={false}
        disableClose
      >
        <ModalContainer>
          <Title><FormattedMessage id="inviola.profile.saveChanges.title" /></Title>
          <Text><FormattedMessage id="inviola.profile.saveChanges.text" /></Text>

          <ButtonBlock>
            <Button onClick={onNavigationConfirm}>
              <FormattedMessage id="inviola.catalog.request.yes" />
            </Button>
            <ButtonOutline onClick={() => setIsModalOpen(false)}>
              <FormattedMessage id="inviola.catalog.request.no" />
            </ButtonOutline>
          </ButtonBlock>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default SaveChangesModal;

const ModalContainer = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 20px;

  background-color: ${(props): string => props.theme.color.whiteSmoke};
  color: black;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  text-align: left;

  max-height: 100vh;
  overflow: auto;

  button {
    width: 100%;
    max-width: 300px;
    margin: 10px auto;

    font-size: 0.857rem;
  }

  @media ${Devices.tablet} {
    button {
      margin: 10px;
    }
  }

  @media ${Devices.desktop} {
    padding: 30px;
  }
`;

const Title = styled.p`
  font-size: 1.3rem;
  font-weight: bold;

  text-transform: uppercase;
  text-align: center;
`;

const Text = styled(Title)`
  font-size: 1.4rem;
  font-weight: normal;
  text-transform: none;


  margin: 20px auto;
`;

const ButtonBlock = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Devices.tablet} {
    flex-direction: row;
  }

  @media print {
    display: none;
  }
`;
