import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { getLineupFormation, getPlayerNumberByFormation } from '@app/helpers/matchHelpers';
import { useLineupsSelector } from '@app/pages/MatchCenter/MatchCenterHooks';
import { MatchLineupsTeam, MatchLineupsTeamPlayer } from '@app/types/matchTypes';
import { isTeamFiorentina } from '@app/helpers/teamsHelpers';

interface LineInterface {
  dots: number[]
  players?: MatchLineupsTeamPlayer[];
}
const Line = ({ dots, players }: LineInterface): React.ReactElement => (
  <GridLine>
    {dots.map((number) => <GridNumber key={number}>{getPlayerNumberByFormation(players, number)}</GridNumber>)}
  </GridLine>
);

const Formation = ({ team }: {team?: MatchLineupsTeam}): React.ReactElement => {
  const numbers = getLineupFormation(team?.formation ?? '');

  return (
    <>
      {`1${team?.formation}`.split('').map((indexPlus, i) => {
        const nextIndex = `1${team?.formation}`
          .slice(0, i)
          .split('')
          .reduce((a, b) => Number(a) + Number(b), 0);
        return (
          <Line
            players={team?.players} // eslint-disable-next-line react/no-array-index-key
            key={`${indexPlus}${i}`}
            dots={numbers.slice(nextIndex, nextIndex + Number(indexPlus))}
          />
        );
      })}
    </>
  );
};

const FormationGrid = (): React.ReactElement => {
  const lineups = useLineupsSelector();
  const arePlayersLoaded = !!(lineups?.home?.players?.length && lineups?.away?.players?.length);
  return (
    <Container>
      <img src="/images/field.svg" alt="formation" width="100%" />
      {arePlayersLoaded && (
        <Grid>
          <Side isFiorentina={isTeamFiorentina(lineups?.home?.teamId)}>
            <Formation team={lineups?.home} />
          </Side>
          <Side isFiorentina={isTeamFiorentina(lineups?.away?.teamId)} away>
            <Formation team={lineups?.away} />
          </Side>
        </Grid>
      )}
    </Container>
  );
};

export default FormationGrid;

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 200px;
  background-color: #00985b;
  padding: 1px;
  margin-bottom: 30px;
`;

const Grid = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const GridLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 20px;
  height: 100%;
`;

const GridNumber = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.12px;

  @media ${Devices.tablet} {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }

  @media ${Devices.tabletLarge} {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
`;

const Side = styled.div<{ away?: boolean, isFiorentina: boolean }>`
  display: flex;
  flex: 0 0 50%;
  flex-direction: ${({ away = false }): string => (away ? 'row-reverse' : 'row')};
  align-items: center;
  justify-content: space-around;

  & ${GridLine} {
    flex-direction: ${({ away = false }): string => (away ? 'column' : 'column-reverse')};
  }

  & ${GridNumber} {
    background-color: ${({ isFiorentina, theme }): string => (isFiorentina ? theme.color.purple : theme.color.red)};
  }
`;
