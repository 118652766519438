export interface Badge {
  badgeText: string
  badgeColor?: string
}

export interface VideoErrorMessages {
  invalidVideo: string;
  invalidBrowser: string;
}

export interface VideoFullScreenHook {
  fullScreenEnabled: boolean;
  isFullScreen: boolean;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleFullScreen: () => void;
}

export interface VideoControlsHook {
  onPlay: () => Promise<void> | void;
  onPause: () => void;
  onMute: () => void;
  onUnMute: () => void;
  onSeek: (time: number) => void;
}

export enum RemoteSubtitlesFormat {
  SRT = 'srt',
  VTT = 'vtt',
}

export interface RemoteSubtitles {
  url: string;
  lang: string;
  format: RemoteSubtitlesFormat;
}
