import React from 'react';
import styled from 'styled-components';

import { Loader } from '@app/components';
import { ContainerMedium } from '@app/components/WebTemplates/components';
import { useInviolaSync } from '@app/components/Inviola/InviolaHooks';
import { useCatalogSync, useWishListDownload } from '@app/pages/Inviola/CatalogPage/CatalogPageHooks';
import {
  useIsAwardsDownloaded, useAwardsDownload, useWithdrawnAwardsDownload, useAwardsSections,
} from './AwardsPageHooks';

const AwardsPage = (): React.ReactElement => {
  const isDownloadCompleted = useIsAwardsDownloaded();
  const sections = useAwardsSections();

  useInviolaSync();
  useCatalogSync();
  useAwardsDownload();
  useWithdrawnAwardsDownload();
  useWishListDownload();

  return (
    <PageContainer>
      {isDownloadCompleted ? sections.map(({ Section }) => (<Section />)) : (<Loader />)}
    </PageContainer>
  );
};

const PageContainer = styled(ContainerMedium)`
  padding: 0;
`;

export default AwardsPage;
