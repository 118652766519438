import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { MatchComments } from '@app/types/matchTypes';

import {
  useEventsSelector,
  useMatchPlayerByIdSelector,
  useIsFiorentinaCheckPlayerById,
  useTeamLogoByPlayerIdSelector,
} from '../MatchCenterHooks';

const MatchEvent = ({ event }: { event: MatchComments }): React.ReactElement => {
  const isAutoGoal = ['own_goal'].includes(event?.type);
  const isStandardGoal = ['goal', 'penalty_goal'].includes(event?.type);
  const isGoal = isStandardGoal || isAutoGoal;

  const matchPlayer = useMatchPlayerByIdSelector(event?.playerId1 ?? '');
  const teamLogoUrl = useTeamLogoByPlayerIdSelector(event?.playerId1 ?? '');
  const isFiorentinaPlayer = useIsFiorentinaCheckPlayerById(event?.playerId1 ?? '');
  const isFiorentinaGoal = (isStandardGoal && isFiorentinaPlayer) || (isAutoGoal && !isFiorentinaPlayer);

  return (
    <Event isFiorentinaGoal={isFiorentinaGoal}>
      <Time isFiorentinaGoal={isFiorentinaGoal}>
        {event?.time?.length && !['end_14', 'start'].includes(event?.type) && (<span>{event.time}</span>)}
      </Time>
      <Message>
        {!['contentious_referee_decisions', 'var_cancelled_goal'].includes(event?.type) && (
          <Title isGoal={isGoal} isFiorentinaGoal={isFiorentinaGoal}>
            <FormattedMessage id={`matchcenter.events.type_${event?.type}`} />
            {isFiorentinaGoal && (<span>&nbsp;&#33;&#33;&#33;&#33;&#33;</span>)}
          </Title>
        )}
        {isGoal && (
          <PlayerInfo>
            <Number>{matchPlayer?.number}</Number>
            <div>
              {matchPlayer?.knownName ? (
                <LastName>{matchPlayer?.knownName}</LastName>
              ) : (
                <>
                  <FirstName>{matchPlayer?.firstName}</FirstName>
                  <LastName>{matchPlayer?.lastName}</LastName>
                </>
              )}
            </div>
          </PlayerInfo>
        )}
        <Text>{event?.comment}</Text>
      </Message>
      {!!matchPlayer && <TeamLogo src={teamLogoUrl} />}
    </Event>
  );
};

const MatchEvents = (): React.ReactElement => {
  const events = useEventsSelector();
  return (
    <Container>
      {// eslint-disable-next-line react/no-array-index-key
        events.map((event, i) => <MatchEvent key={`${event?.time}${event?.type}${i}`} event={event} />)
      }
    </Container>
  );
};

export default MatchEvents;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vh;
  padding: 10px 10px 0;
  overflow: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 12px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  @media ${Devices.tabletLarge} {
    height: 100%;
    padding: 15px 15px 0;
    overflow: visible;
  }

  @media ${Devices.desktopSmall} {
    padding: 30px 30px 0 0;
  }
`;

const Event = styled.div<{ isFiorentinaGoal: boolean }>`
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 25px 15px;
  color: ${({ isFiorentinaGoal }): string => (isFiorentinaGoal ? 'white' : 'black')};
  background-color: ${({ isFiorentinaGoal, theme }): string => (isFiorentinaGoal ? theme.color.purple : 'white')};

  &:last-of-type {
    margin-bottom: 0;
  }

  @media ${Devices.tablet} {
    padding: 25px;
  }
`;

const Time = styled.div<{ isFiorentinaGoal: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 auto;
  margin-right: 20px;
  min-height: ${({ isFiorentinaGoal }): string => (isFiorentinaGoal ? '100px' : 'auto')};

  & span {
    display: inline-block;
    color: ${({ isFiorentinaGoal, theme }): string => (isFiorentinaGoal ? 'white' : theme.color.red)};
    font-family: ${(props): string => props.theme.fontFamily.commutersSans};
    font-size: 14px;
    font-weight: bold;
  }
`;

const PlayerInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Number = styled.span`
  opacity: 0.2;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 38px;
  font-weight: bold;
  line-height: 1.5;
  padding-right: 10px;
`;

const FirstName = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 12px;
  line-height: 1.1;
`;

const LastName = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 20px;
  line-height: 1.3;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 50px;
  width: 100%;
`;

const Title = styled.h3<{ isGoal: boolean, isFiorentinaGoal: boolean }>`
  margin-bottom: 5px;
  color: ${(props): string => (props.isFiorentinaGoal ? 'white' : props.theme.color.purple)};
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 15px;
  font-weight: bold;
  text-transform: ${({ isGoal }): string => (isGoal ? 'uppercase' : 'unset')};
`;

const Text = styled.p`
  margin: 0;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 10px;
  font-weight: normal;
  line-height: 1.3;
  letter-spacing: normal;
`;

const TeamLogo = styled.img`
  width: auto;
  height: 30px;
`;
