import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { Loader } from '@app/components';
import { ContainerMedium } from '@app/components/WebTemplates/components';
import { useMatchCenterButton } from '@app/pages/MatchCenter/MatchCenterHooks';
import { MatchCalendar, MatchMonth } from './components';
import { useCalendarSelector, useScheduleResultsDownload } from './ScheduleHooks';
import { useIsLoadingSelector } from '../Season/SeasonHooks';

const Schedule = (): React.ReactElement => {
  const calendar = useCalendarSelector();
  const isLoading = useIsLoadingSelector();

  useMatchCenterButton();
  useScheduleResultsDownload();

  return (
    <Container>
      <MatchCalendar />
      {isLoading ? (<Loader />) : (
        <Results>
          { calendar.map((date, i) => <MatchMonth key={date} matchDate={date} showAds={i === 0} />) }
        </Results>
      )}
    </Container>
  );
};

export default Schedule;

const Results = styled.div`
  max-width: 960px;
  padding: 0 10px;
  margin: auto;

  @media ${Devices.responsiveContent} {
    padding: 0;
  }
`;
const Container = styled(ContainerMedium)`
  padding: 0;
`;
