import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';
import { Player, TeamNames } from '@app/types/teamsTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { kenticoClient } from '@app/services/kentico/kentico';
import { convertPlayer, convertTeamNames } from './converters/teamsConverter';
import { Player as KenticoPlayer } from './types/player';
import { Team } from './types/team';

export enum TeamSquadTypes {
  MenSquad = 'men_squad',
  WomenSquad = 'women_squad',
  PrimaveraSquad = 'primavera_squad'
}

export interface GetPlayersParamsInterface {
  language: LanguageType;
  teamType: TeamSquadTypes;
}

export async function getPlayersList(requestParams: GetPlayersParamsInterface): Promise<Player[]> {
  const response = await kenticoClient().items<KenticoPlayer>().type('player')
    .containsFilter('elements.squad', [requestParams.teamType])
    .languageParameter(langCodeToKenticoLangMap[requestParams.language])
    .equalsFilter('system.language', langCodeToKenticoLangMap[requestParams.language])
    .toPromise();

  return response?.items?.map((item) => convertPlayer(item, requestParams.language)) || [];
}

export async function getTeamNamesData(language: LanguageType): Promise<TeamNames> {
  const response = await kenticoClient().items<Team>().type('team')
    .languageParameter(langCodeToKenticoLangMap[language])
    .equalsFilter('system.language', langCodeToKenticoLangMap[language])
    .toPromise();

  return convertTeamNames(response?.items ?? []);
}
