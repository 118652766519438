import React from 'react';
import styled from 'styled-components';
import { Devices } from '@app/styles';
import { SocialSharing } from '@app/components';
import WebTemplate from '@app/pages/WebTemplate/WebTemplate';
import { ContainerMedium } from '@app/components/WebTemplates/components';

const PrivacyPage = (): React.ReactElement => (
  <Container>
    <Content>
      <WebTemplate />
      <ContainerMedium>
        <SocialSharing url={window.location.href} onlyIcons />
      </ContainerMedium>
    </Content>
  </Container>
);

const Container = styled.div`
  background-color: ${(props): string => props.theme.color.whiteSmoke};
`;

const Content = styled.div`
  flex-grow: 1;
  padding-bottom: 25px;
  font-size: 32px;


  max-width: 1280px;
  margin: auto;
  position: relative;
  padding: 50px var(--horizontal-padding-mobile);
  line-height: 1.5;

  @media ${Devices.desktopSmall} {
    padding: 50px var(--horizontal-padding);
  }

  h1 {
    font-size: 28px;
    color: #000;
    font-family: ${(props): string => props.theme.fontFamily.commutersSans};
    letter-spacing: 0.16px;
    margin-bottom: 20px;
  }

  h2, h3 {
    font-size: 14px;
    font-weight: bold;
    font-family: ${(props): string => props.theme.fontFamily.commutersSans};
    margin-bottom: 20px;
  }

  p, ol, ul, table {
    font-family: ${(props): string => props.theme.fontFamily.arialNova};
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.2px;
    margin-bottom: 0;
  }

  b, strong {
    font-weight: bold;
`;

export default PrivacyPage;
