import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { useHeader } from '@app/components/Header/HeaderHooks';
import { useIsMenuOpened } from '@app/components/Hooks';
import SocialIcons from '@app/components/SocialIcons';
import Sponsors from '@app/components/Sponsors';

const TopHeader: React.FC = () => {
  const header = useHeader();
  const hasSocial = !!header?.social?.length;
  const hasPartners = !!header?.partners?.length;
  const isOpenedMenu = useIsMenuOpened();

  return (
    <Container>
      <SocialSection hasRightPadding={hasPartners} isHidden={isOpenedMenu}>
        { hasSocial && <StyledSocialIcons social={header?.social || []} /> }
      </SocialSection>
      {
        hasPartners && <Sponsors showDivider={hasSocial} isOpenedMenu={isOpenedMenu} />
      }
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0 35px;
  height: var(--top-header-height);

  @media ${Devices.aboveMediumMobile} {
    align-items: flex-end;
  }

  @media ${Devices.belowLargeMobile} {
    padding: 25px 0 15px;
  }
`;

const SocialSection = styled.ul<{hasRightPadding: boolean; isHidden: boolean}>`
  padding: 0;
  padding-right: ${({ hasRightPadding }): string => (hasRightPadding ? '10px' : '0')};
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;

  @media ${Devices.belowLargeMobile} {
    padding-right: ${({ hasRightPadding }): string => (hasRightPadding ? '25px' : '0')};
  }

  @media ${Devices.desktopSmall} {
    display: ${({ isHidden }): string => (isHidden ? 'none' : 'flex')};
  }
`;

const StyledSocialIcons = styled(SocialIcons)`
  height: 16px;
  padding: 1px 5px;

  @media ${Devices.belowLargeMobile} {
    height: 14px;
    padding: 0 10px;
  }

  & img {
    height: 100%;
  }
`;

export default TopHeader;
