import { PageError } from '@app/types/errorTypes';
import { LocalizedPromotionItem, Promotion, PromotionList } from '@app/types/promotionsTypes';

export enum PromotionsActionTypes {
  SET_PROMOTIONS_LIST = 'PROMOTIONS/SET_PROMOTIONS_LIST',
  RESET_PROMOTIONS_LIST = 'PROMOTIONS/RESET_PROMOTIONS_LIST',
  SET_PROMOTIONS_LOADING_STATE = 'PROMOTIONS/SET_PROMOTIONS_LOADING_STATE',
  SET_PROMOTION = 'PROMOTIONS/SET_PROMOTION',
  RESET_PROMOTION = 'PROMOTIONS/RESET_PROMOTION',
  SET_LATEST_PROMOTIONS = 'PROMOTIONS/SET_LATEST_PROMOTIONS',
  RESET_LATEST_PROMOTIONS = 'PROMOTIONS/RESET_LATEST_PROMOTIONS',
  SET_ERROR = 'PROMOTIONS/SET_ERROR',
}

export interface SetPromotionsList {
  type: PromotionsActionTypes.SET_PROMOTIONS_LIST;
  payload: PromotionList;
}

export interface ResetPromotionsList {
  type: PromotionsActionTypes.RESET_PROMOTIONS_LIST;
}

export interface SetPromotionsLoadingState {
  type: PromotionsActionTypes.SET_PROMOTIONS_LOADING_STATE;
  payload: boolean;
}

export interface SetPromotion {
  type: PromotionsActionTypes.SET_PROMOTION;
  payload: LocalizedPromotionItem;
}

export interface ResetPromotion {
  type: PromotionsActionTypes.RESET_PROMOTION;
}

export interface SetLatestPromotions {
  type: PromotionsActionTypes.SET_LATEST_PROMOTIONS;
  payload: Promotion[];
}

export interface ResetLatestPromotions {
  type: PromotionsActionTypes.RESET_LATEST_PROMOTIONS;
}

export interface SetError {
  type: PromotionsActionTypes.SET_ERROR;
  payload: PageError;
}

export type PromotionsActions = SetPromotionsList
  | ResetPromotionsList
  | SetPromotionsLoadingState
  | SetPromotion
  | ResetPromotion
  | SetLatestPromotions
  | ResetLatestPromotions
  | SetError;
