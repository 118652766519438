import { getCountries, getDynamicFields } from '@app/services/inviola/inviola';
import {
  getProxyShopsCategories, getProxyShopsByCategory, getProxyShopsByDistance,
} from '@app/services/inviola/inviolaProxy';
import { InviolaAPIError } from '@app/services/inviola/types/errorTypes';
import { InviolaShop } from '@app/types/inviolaTypes';
import {
  InviolaResponse,
  InviolaRawCategory,
  InviolaCountries,
  InviolaRawDynamicFields,
} from '@app/services/inviola/types/responseTypes';
import {
  SetShops,
  SetShopsCategories,
  InviolaActionTypes,
  SetAdditionalData,
  SetSyncInProgress,
  SetShopsSyncInProgress,
  SetLogoutSyncInProgress,
} from '@app/store/actionTypes/inviolaActionTypes';
import { InviolaState } from '@app/store/reducers/inviolaReducer';
import { ActionWithPromiseReturn } from '@app/types/actionTypes';
import { getInviolaShops } from '@app/services/inviola/converters';
import { handleInviolaError } from './error';
import { getSession } from './session';

export const setSyncInProgress = (status: boolean): SetSyncInProgress => ({
  type: InviolaActionTypes.SET_SYNC_IN_PROGRESS,
  payload: status,
});

export const setLogoutSyncInProgress = (status: boolean): SetLogoutSyncInProgress => ({
  type: InviolaActionTypes.SET_LOGOUT_SYNC_IN_PROGRESS,
  payload: status,
});

export const setShopsSyncInProgress = (status: boolean): SetShopsSyncInProgress => ({
  type: InviolaActionTypes.SET_SHOPS_SYNC_IN_PROGRESS,
  payload: status,
});

const setAdditionalData = (
  dynamicFieldsData: InviolaResponse<InviolaRawDynamicFields>,
  countries: InviolaResponse<InviolaCountries>,
): SetAdditionalData => ({
  type: InviolaActionTypes.SET_ADDITIONAL_DATA,
  payload: {
    dynamicFieldsData,
    countries,
  },
});

const setShops = (shops: InviolaShop[]): SetShops => ({
  type: InviolaActionTypes.SET_SHOPS,
  payload: shops,
});

const setShopsCategories = (categories: InviolaRawCategory[]): SetShopsCategories => ({
  type: InviolaActionTypes.SET_SHOPS_CATEGORIES,
  payload: categories.sort((a, b) => {
    const nameA = a.description.toUpperCase();
    const nameB = b.description.toUpperCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  }),
});

export const syncWithInviola = (): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    const state = getState();

    const syncSessionId = await dispatch(getSession('sync'));

    if (requireAdditionalSync(state.inviola)) {
      await dispatch(getAdditionalSyncData(syncSessionId));
    }
  }
);

const requireAdditionalSync = (state: InviolaState): boolean => {
  const { countries, dynamicFields } = state;

  return !(countries && dynamicFields);
};

const getAdditionalSyncData = (syncSessionId: string): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    try {
      const dynamicFieldsData = await getDynamicFields(syncSessionId);
      const countries = await getCountries(syncSessionId);

      dispatch(setAdditionalData(dynamicFieldsData, countries));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAPIError.GetAdditionalDataError));
    }
  }
);

export const getShops = (latitude: number, longitude: number): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    dispatch(setShopsSyncInProgress(true));

    const syncSessionId = await dispatch(getSession('sync'));

    try {
      const response = await getProxyShopsByDistance(syncSessionId, latitude, longitude);
      const shops = getInviolaShops(response?.netsAndShops ?? []);

      dispatch(setShops(shops));
    } catch (error) {
      dispatch(setShopsSyncInProgress(false));
      dispatch(handleInviolaError(error, InviolaAPIError.GetShopsError));
    }
  }
);

export const getShopsByCategory = (categoryId: number): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    dispatch(setShopsSyncInProgress(true));

    const syncSessionId = await dispatch(getSession('sync'));

    try {
      const response = await getProxyShopsByCategory(syncSessionId, categoryId);
      const shops = getInviolaShops(response?.netsAndShops ?? []);

      dispatch(setShops(shops));
    } catch (error) {
      dispatch(setShopsSyncInProgress(false));
      dispatch(handleInviolaError(error, InviolaAPIError.GetShopsError));
    }
  }
);

export const getShopsCategories = (): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    const syncSessionId = await dispatch(getSession('sync'));

    try {
      const response = await getProxyShopsCategories(syncSessionId);
      dispatch(setShopsCategories(response.categories));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAPIError.GetShopsError));
    }
  }
);
