import React from 'react';
import styled from 'styled-components';
import { WebTemplateCtaButton, WebTemplateCtaDuoButton } from '@app/types/webTemplateTypes';
import { ContainerMedium } from '@app/components/WebTemplates/components';
import WebCtaButtonElement from '@app/components/WebTemplates/elements/WebCtaButtonElement';

interface WebCtaDuoButtonParamsProps {
  data: WebTemplateCtaDuoButton;
}

const WebCtaDuoButtonElement = ({ data }: WebCtaDuoButtonParamsProps): React.ReactElement => (
  <Container>
    {data.content.map((data: WebTemplateCtaButton) => (
      <ButtonContainer key={data.id}>
        <WebCtaButtonElement data={data} />
      </ButtonContainer>
    ))}
  </Container>
);

export default WebCtaDuoButtonElement;

const ButtonContainer = styled.div`
  display: inline-block;
  
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const Container = styled(ContainerMedium)`
  padding: 0;
`;
