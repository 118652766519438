import React from 'react';

import { ThroneAsset } from '@app/types/configurationTypes';
import { ThronImage } from '@app/components/Image';

interface ImageProps {
  image: ThroneAsset;
  scaleMode?: 'auto' | 'none';
}

const Image = ({ image, scaleMode }: ImageProps): React.ReactElement => (
  <ThronImage thronId={image.id} alt={image.description} scaleMode={scaleMode ?? 'auto'} />
);

export default Image;
