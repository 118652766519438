import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { WebGoogleMaps } from '@app/types/webTemplateTypes';
import { ContainerMedium } from '@app/components/WebTemplates/components';
import { useGoogleMaps } from './WebGoogleMapsHooks';

interface WebGoogleMapsProps {
  data: WebGoogleMaps;
}

const WebTemplateGoogleMaps = ({ data }: WebGoogleMapsProps): React.ReactElement => {
  useGoogleMaps(data.map);

  return (
    <ContainerMedium>
      <Container>
        <Map id={`map.${data.map.position.lat}.${data.map.position.lat}`} />
      </Container>
    </ContainerMedium>
  );
};

export default WebTemplateGoogleMaps;

const Container = styled.section`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 20px;
  height: 0;
  z-index: 0;
  overflow: hidden;

  @media ${Devices.desktop} {
    padding-bottom: 590px;
    padding-top: 0;
  }
`;

const Map = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 590px;
  z-index: 1;
`;
