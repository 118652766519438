import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Button } from '@app/components/Inviola/InviolaComponents';
import { useDispatch } from 'react-redux';
import { setResetPasswordDone } from '@app/store/actions/inviolaActions';
import { useInviolaPath } from '@app/components/Inviola/InviolaHooks';

const SuccessReset = (): React.ReactElement => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { authPath } = useInviolaPath();

  const submit = () => {
    dispatch(setResetPasswordDone());
    history.push(authPath);
  };

  return (
    <div>
      <Button onClick={submit}>{formatMessage({ id: 'inviola.resetConfirmation.backLink' })}</Button>
    </div>
  );
};

export default SuccessReset;
