import { Elements } from '@kentico/kontent-delivery';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales';
import KenticoConfig from '@app/services/kentico/types/configuration';
import KenticoBaseMenuItem from '@app/services/kentico/types/menu_item';
import KenticoExternalLink from '@app/services/kentico/types/external_link';
import KenticoRelatedMenuItem from '@app/services/kentico/types/related_menu_item';
import KenticoWebSocial from '@app/services/kentico/types/web_social';
import WebPartner from '@app/services/kentico/types/web_partner';
import Competition from '@app/services/kentico/types/competition';
import { EditorialContentTypes } from '@app/types/newsTypes';

import {
  BaseMenuItem,
  Menu,
  SubMenuId,
  SubMenuItem,
  RelatedItemsType,
  ExternalLinkMenuItem,
  Seo,
  Image,
  ThroneAsset,
  RelatedMenuItem,
  Configuration,
  MenuIds,
  MenuItemTypes,
  CategoryMenu,
  Header,
  Social,
  Partner,
  Competitions,
  CompetitionTypes,
  CompetitionSeason,
  Layout,
  LayoutType,
  ShareIconsItem,
  ShareIconsType,
  ErrorPagesData,
  Partners,
  Home,
  CookieBannerItem, Live, ThroneImage, Inviola,
} from '@app/types/configurationTypes';
import ItemWithSeo from '@app/services/kentico/types/seo';
import { LanguageType } from '@app/types/localizationTypes';
import { ImageThron } from '@app/services/kentico/types/image_thron';

import { KenticoLangToLangCodeMap } from '@app/constants/localizationConstants';
import {
  mapSeasonCategoryIdToConfigCategoryIdMap,
} from '@app/constants/seasonConstants';
import {
  WebTemplateDepartment, WebTemplatePartnerBottom, WebTemplateTypes,
} from '@app/types/webTemplateTypes';
import WebPartnerBottom from '@app/services/kentico/types/web_partner_bottom';
import {
  convertWebCtaButton,
  convertWebCarousel,
  convertWebDepartment,
} from '@app/services/kentico/converters/webTemplateConverter';
import { buildThronAsset } from '@app/helpers/thronHelper';
import { isOptaSDCompetition } from '@app/services/opta/helpers/competitionHelpers';
import { OptaSDCompetitionsMap } from '@app/services/opta/constants/competitionConstants';

const isRelatedMenuItem = (menuItem: KenticoRelatedMenuItem | KenticoExternalLink | KenticoBaseMenuItem)
  : menuItem is KenticoRelatedMenuItem => (
  menuItem?.system?.type === MenuItemTypes.RelatedMenuItem
);

const isBaseMenuItem = (menuItem: KenticoRelatedMenuItem | KenticoExternalLink | KenticoBaseMenuItem)
  : menuItem is KenticoBaseMenuItem => (
  menuItem?.system?.type === MenuItemTypes.BaseMenuItem
);

export const mapSeoData = (data: ItemWithSeo, language: LanguageType, hasDefaultMetadata = false): Seo => {
  const meta = (data?.seoMetadataMetaRobots?.value ?? [])
    .some(({ codename }) => codename);
  const defaultTitle = `${Translations[language]['meta.defaultTitle']}`;
  const socialImage = data?.socialMarkupThronImage?.value
    ? mapThronData(data?.socialMarkupThronImage)
    : data?.mainImage && mapThronData(data.mainImage);

  return {
    seo: {
      title: data?.seoMetadataMetaTitle?.value || (hasDefaultMetadata ? (data?.title?.value ?? '') : defaultTitle),
      description: data?.seoMetadataMetaDescription?.value || (hasDefaultMetadata ? (data?.subtitle?.value ?? '') : ''),
      canonicalTag: data?.seoMetadataCanonicalTag?.value ?? '',
      meta,
      socialTitle: data?.socialMarkupSocialTitle?.value ?? data?.seoMetadataSocialTitle?.value ?? '',
      socialDescription: data?.socialMarkupSocialDescription?.value ?? data?.seoMetadataSocialDescription?.value ?? '',
      socialImageUrl: socialImage?.url ?? '',
    },
  };
};

export const mapImageData = (image: Elements.AssetsElement): Image => ({
  url: image?.value[0]?.url ?? '',
  description: image?.value[0]?.description ?? '',
});

export const mapThronData = (image: Elements.CustomElement): ThroneAsset => {
  const thronImagesList = mapThronList(image);
  return thronImagesList[thronImagesList.length - 1];
};

export const mapThronList = (image: Elements.CustomElement): ThroneAsset[] => {
  const imagesThron = JSON.parse(image?.value ?? '[]') as ImageThron[];
  return imagesThron?.map((data) => buildThronAsset(data)) ?? [];
};

export const mapBaseMenuItem = (menuItem: KenticoBaseMenuItem): BaseMenuItem => (
  {
    id: menuItem?.system?.codename,
    name: menuItem?.name?.value ?? '',
    url: menuItem?.url?.value ?? '',
    isVisible: (menuItem?.isVisible?.value?.[0]?.name ?? '').toLowerCase() === 'true'
      || [MenuItemTypes.RelatedMenuItem, MenuItemTypes.ExternalLink].includes(menuItem?.system?.type as MenuItemTypes),
    isPrivate: menuItem?.isPrivate?.value?.[0]?.name?.length
      ? (menuItem?.isPrivate?.value?.[0]?.name ?? '').toLowerCase() === 'true' : null,
    isPartnersInvisible: !!menuItem?.partnersBottomSection?.value?.[0],
    optaId: menuItem?.squadOptaId?.value ?? '',
    type: menuItem?.system?.type as MenuItemTypes.BaseMenuItem,
    isShownInHeader: menuItem?.displayInHeaderMenu?.value?.[0]?.codename === 'display',
    ...mapSeoData(menuItem, KenticoLangToLangCodeMap[menuItem?.system?.language]),
    editorialCategory: menuItem?.news?.value?.[0]?.codename ?? '',
  }
);

export const mapExternalLink = (menuItem: KenticoExternalLink): ExternalLinkMenuItem => ({
  type: MenuItemTypes.ExternalLink,
  id: menuItem?.system?.codename,
  name: menuItem?.linkName?.value ?? '',
  url: menuItem?.linkUrl?.value ?? '',
  isVisible: true,
  isPrivate: null,
});

export const mapRelatedItem = (data: KenticoRelatedMenuItem): RelatedMenuItem => ({
  id: data?.system?.codename ?? '',
  name: data?.title?.value ?? '',
  topLevel: mapBaseMenuItem(data?.topLevel?.value[0]),
  secondLevel: data?.secondLevel?.value?.[0] && mapBaseMenuItem(data?.secondLevel?.value?.[0]),
  thirdLevel: data?.thirdLevel?.value?.[0] && mapBaseMenuItem(data?.thirdLevel?.value?.[0]),
  type: MenuItemTypes.RelatedMenuItem,
  isVisible: true,
  isPrivate: null,
});

const mapSubMenuItemCategories = (data: KenticoBaseMenuItem): CategoryMenu => (
  (data?.subitems?.value ?? [])
    .filter((menuItem) => isBaseMenuItem(menuItem))
    .map((menuItem) => mapBaseMenuItem(menuItem as KenticoBaseMenuItem))
);

const mapRelatedItems = (data: KenticoBaseMenuItem): RelatedItemsType[] => (
  (data?.relatedItems?.value ?? [])
    .map((menuItem) => (
      isRelatedMenuItem(menuItem)
        ? mapRelatedItem(menuItem)
        : mapExternalLink(menuItem)
    ))
);

const mapSubMenu = (data: KenticoBaseMenuItem): Record<SubMenuId, SubMenuItem> => (
  (data?.subitems?.value ?? [])
    .reduce((accumulator, menuItem) => {
      const id = menuItem?.system?.codename as SubMenuId;
      const submenu = { ...accumulator };
      let data;
      let categories: CategoryMenu = [];

      if (isBaseMenuItem(menuItem)) {
        data = mapBaseMenuItem(menuItem);
        categories = menuItem?.subitems ? mapSubMenuItemCategories(menuItem) : [];
      } else if (isRelatedMenuItem(menuItem)) {
        return accumulator;
      } else {
        data = mapExternalLink(menuItem);
      }

      submenu[id] = {
        data,
        categories: categories || [],
      };

      return submenu;
    }, {} as Record<SubMenuId, SubMenuItem>)
);

export const mapMenuItems = (data: KenticoConfig): Menu => (
  (data?.sections?.value ?? [])
    .reduce((accumulator, menuItem) => {
      const id = menuItem?.system?.codename as MenuIds;
      const menu = { ...accumulator };

      menu[id] = {
        data: mapBaseMenuItem(menuItem),
        navigation: mapSubMenu(menuItem),
        relatedItems: !isRelatedMenuItem(menuItem) && !isBaseMenuItem(menuItem)
          ? [mapExternalLink(menuItem)] : mapRelatedItems(menuItem),
      };

      return menu;
    }, {} as Menu)
);

const mapSocial = (data: KenticoWebSocial[]): Social[] => (
  data
    .map((social) => ({
      name: social?.socialName?.value ?? '',
      url: social?.linkUrl?.value ?? '',
      // todo: make it common to use on footer
      icon: mapImageData(social.socialIconHeader),
    }))
);

const mapPartners = (data: WebPartner[]): Partner[] => (
  data.map((item: WebPartner) => ({
    id: item?.id?.value ?? '',
    title: item?.title?.value ?? '',
    photo: mapImageData(item?.photoLarge),
    url: item?.redirectUrl?.value ?? '',
  }))
);

const mapHeader = (data: KenticoConfig): Header => ({
  social: mapSocial(data?.socialHeader?.value ?? []),
  partners: mapPartners(data?.partnersHeaderSection?.value ?? []),
});

const mapCompetitionArchive = (archives: Competition[], optaId: string): CompetitionSeason[] => {
  const archive = archives.find(({ competitionOptaId }) => competitionOptaId?.value === optaId);
  const seasonNames = archive?.seasonName?.value?.split('-') ?? [];

  return archive?.seasonOptaId?.value?.split('-')
    .map((seasonId, index) => ({
      seasonId,
      seasonName: seasonNames[index] ?? seasonId,
      isActive: false,
    })) ?? [];
};

const mapCompetitions = (data: KenticoConfig, menu: Menu): Competitions => {
  // eslint-disable-next-line camelcase
  const categories = menu?.web_season?.navigation?.[SubMenuId.SCHEDULE]?.categories ?? [];
  return categories
    // .filter(({ isVisible }) => isVisible)
    .reduce((accumulator, { id }) => {
      const { competitions, archives } = mapSeasonCategoryIdToConfigCategoryIdMap(data, id);

      competitions.forEach((competition) => {
        accumulator[competition?.system?.codename] = {
          id: competition?.system?.codename,
          url: competition?.url?.value ?? '',
          type: (competition?.competitionType?.value[0]?.codename ?? '') as CompetitionTypes,
          categoryId: id,
          optaId: isOptaSDCompetition(id as OptaSDCompetitionsMap)
            ? competition?.optaSdCompetitionId?.value ?? ''
            : competition?.competitionOptaId?.value ?? '',
          name: competition?.competitionName?.value || '',
          seasonIds: [{
            seasonId: isOptaSDCompetition(id as OptaSDCompetitionsMap)
              ? competition?.optaSdSeasonId?.value ?? ''
              : competition?.seasonOptaId?.value ?? '',
            seasonName: competition?.seasonName?.value ?? '',
            isActive: true,
          }, ...mapCompetitionArchive(archives, competition?.competitionOptaId?.value ?? '')],
          statisticsOptaId: competition?.competitionOptaId?.value ?? '',
          statisticsSeasonId: competition?.seasonOptaId?.value ?? '',
        };
      });
      return accumulator;
    }, {} as Competitions);
};

const mapLayout = (data: KenticoConfig): Layout => ({
  [EditorialContentTypes.NEWS]: (data?.webEditorialLayoutNews?.value?.[0]?.codename ?? 'layout_3x3') as LayoutType,
  [EditorialContentTypes.VIDEO]: (data?.webEditorialLayoutVideos?.value?.[0]?.codename ?? 'layout_3x3') as LayoutType,
  [EditorialContentTypes.PHOTO]: (data?.webEditorialLayoutGallery?.value?.[0]?.codename ?? 'layout_3x3') as LayoutType,
});

export const mapShareIcons = (data: KenticoConfig): ShareIconsItem[] => {
  const socialSharing: KenticoWebSocial[] = data?.socialSharing?.value ?? [];
  return socialSharing.map((socialIconType) => ({
    type: (socialIconType?.socialName?.value?.toLowerCase() ?? '') as ShareIconsType,
    icon: mapImageData(socialIconType?.socialIcon),
  })).filter((socialIcon) => Object.values(ShareIconsType).includes(socialIcon?.type));
};

const mapErrorPagesData = (data: KenticoConfig): ErrorPagesData => ({
  // const ctaButtons = data?.errorSorryButton?.value;
  titleSorry: data?.genericErrorPageText?.value ?? '',
  titleNotFound: data?.n404ErrorPageText?.value ?? '',
  cta: data?.errorSorryButton?.value?.map((button) => convertWebCtaButton(button, button)),
});

const mapPartnerBottom = (
  data: KenticoConfig, value: WebPartnerBottom,
): WebTemplatePartnerBottom => ({
  type: WebTemplateTypes.WebPartnerBottom,
  id: value?.system?.codename ?? '',
  title: value?.title?.value ?? '',
  content: (value?.content?.value
      ?.map((department) => convertWebDepartment(data, department, {}),)
      ?.filter((value) => !!(value as WebTemplateDepartment)?.content.length) as WebTemplateDepartment[]) ?? [],
});

const mapFooter = (data: KenticoConfig) => {
  const legalLinks: Array<RelatedMenuItem | ExternalLinkMenuItem> = data.legalLinks.value
    .map((el: KenticoRelatedMenuItem) => (isRelatedMenuItem(el) ? mapRelatedItem(el) : mapExternalLink(el)));

  return ({
    copyrightContent: data?.copyright?.value,
    legalLinks,
    social: data?.socialFooter?.value?.map(({ linkUrl, socialIconFooter, socialName }) => ({
      url: linkUrl?.value,
      icon: {
        url: socialIconFooter?.value?.[0]?.url,
        description: '',
      },
      name: socialName?.value,
    })),
  });
};

const mapLive = (data: KenticoConfig): Live => {
  const cta = data?.livePage?.value?.[0];
  return {
    isEnabled: data?.liveMode?.value?.[0]?.codename === 'active',
    videoId: data?.liveVideoId?.value ?? '',
    sessionId: data?.liveSessionId?.value ?? '',
    title: data?.liveVideoTitle?.value ?? '',
    image: mapThronData(data?.liveImage) as ThroneImage,
    imageVertical: mapThronData(data?.liveImageVertical) as ThroneImage,
    cta: cta ? mapRelatedItem(cta) : null,
  };
};

const mapInviola = (data: KenticoConfig): Inviola => ({
  catalogId: +data?.catalogid?.value ?? 0,
});

const mapPartnersBottom = (data: KenticoConfig): Partners => {
  const partnersBottom = data.partners?.value?.[0] ?? [];
  return mapPartnerBottom(data, partnersBottom);
};

const mapHome = (data: KenticoConfig): Home => {
  const homeMenuItem = data?.sequence?.value[0];
  const topElement = data?.homeTopElement?.value?.[0];

  return {
    id: homeMenuItem?.system?.codename,
    seo: mapSeoData(homeMenuItem, KenticoLangToLangCodeMap[homeMenuItem?.system?.language]),
    topElement: topElement ? convertWebCarousel(topElement, topElement, {}) : null,
  };
};

export function mapCookieBanner(data: KenticoConfig): CookieBannerItem {
  const acceptElement = data?.cookieBannerAcceptCta?.value?.[0] ?? '';
  const declineElement = data?.cookieBannerDeclineCta?.value?.[0] ?? '';

  return {
    text: data?.cookieBannerText?.value ?? '',
    cta: {
      // eslint-disable-next-line
      position: data?.item?.elements?.cookieBannerCtaPosition?.value?.[0]?.codename ?? '',
      accept: convertWebCtaButton(data, acceptElement),
      decline: convertWebCtaButton(data, declineElement),
    },
  };
}

export const mapConfig = (data: KenticoConfig): Configuration => {
  const menu = mapMenuItems(data);
  return {
    menu,
    header: mapHeader(data),
    competitions: mapCompetitions(data, menu),
    layout: mapLayout(data),
    shareIcons: mapShareIcons(data),
    errorPagesData: mapErrorPagesData(data),
    partners: mapPartnersBottom(data),
    footer: mapFooter(data),
    home: mapHome(data),
    cookie: mapCookieBanner(data),
    live: mapLive(data),
    inviola: mapInviola(data),
  };
};
