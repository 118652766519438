import uniqWith from 'lodash/uniqWith';

import { Action, ActionWithPromiseReturn } from '@app/types/actionTypes';

import AppRoutes from '@app/constants/routesConstants';
import { AppLanguages } from '@app/constants/localizationConstants';
import { getTeamNamesData } from '@app/services/kentico/teamsService';
import { getMatchResultsData, getSDMatchResultsData } from '@app/services/opta/matchService';
import { getStandingsData, getSDStandingsData } from '@app/services/opta/standingsService';
import { MenCompetitionsMap } from '@app/services/opta/constants/competitionConstants';
import { getSecondLevelMenuItem } from '@app/helpers/menuHelpers';
import {
  mapSchedule, findCompetitionCategoryIdByUrlSlug, findCompetitionIdByUrlSlug,
} from '@app/helpers/seasonHelpers';
import { isOptaSDCompetition } from '@app/services/opta/helpers/competitionHelpers';
import { PageError } from '@app/types/errorTypes';
import { RouteParams } from '@app/types/routerTypes';
import { StandingsItem } from '@app/types/standingsTypes';
import { ScheduleResults, MatchResult, Game } from '@app/types/matchTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { CurrentRouteParams, setCurrentRoute } from '@app/store/actions/currentRouteActions';
import { waitForConfigurationDownload } from '@app/store/actions/configurationActions';
import { getLocalizedGames } from '@app/store/actions/matchActions';
import {
  SetLoading,
  ResetSeason,
  SetSchedule,
  SetStandings,
  SeasonActionTypes,
  SetSeasonError,
  ResetSeasonError,
  SetWidgetResults,
  ResetWidgetResults,
} from '@app/store/actionTypes/seasonActionTypes';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales';

const setLoadings = (isLoading: boolean): SetLoading => ({
  type: SeasonActionTypes.SET_LOADING,
  payload: isLoading,
});

const setStandings = (standings: StandingsItem): SetStandings => ({
  type: SeasonActionTypes.SET_STANDINGS,
  payload: standings,
});

export const resetSeason = (): ResetSeason => ({ type: SeasonActionTypes.RESET_SEASON });

const setSeasonError = (error: PageError): SetSeasonError => ({
  type: SeasonActionTypes.SET_SEASON_ERROR,
  payload: error,
});

export const resetSeasonError = (): ResetSeasonError => ({
  type: SeasonActionTypes.RESET_SEASON_ERROR,
});

type GetStandings = (args: {
  thirdLevel?: string;
  forthLevel?: string;
  competitionId?: string;
  seasonId?: string;
  optaId?: string;
  language: LanguageType;
}) => ActionWithPromiseReturn;

export const getStandings: GetStandings = ({
  competitionId = MenCompetitionsMap.SeriaA,
  thirdLevel = '',
  seasonId,
  optaId,
  language,
}) => (
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoadings(true));
    await waitForConfigurationDownload();

    try {
      const state = getState();
      const competitions = state.configuration[language]?.competitions;
      const activeCompetitionId = thirdLevel ? findCompetitionIdByUrlSlug(competitions, thirdLevel) : competitionId;
      const competition = state.configuration[language]?.competitions[activeCompetitionId];
      const defaultSeasonId = competition?.seasonIds.find(({ isActive }) => isActive)?.seasonId ?? '';
      const teamNames = await getTeamNamesData(language);
      const standings = isOptaSDCompetition(competition?.categoryId)
        ? await getSDStandingsData(defaultSeasonId)
        : await getStandingsData(seasonId ?? defaultSeasonId, optaId ?? competition?.optaId ?? '');

      dispatch(setStandings(standings.map((stats) => ({
        ...stats, teamName: teamNames?.[stats.id]?.teamName ?? stats.teamName,
      }))));
    } catch (e) {
      console.error('Error on fetching Standings Opta data', e);
      dispatch(setSeasonError(PageError.Sorry));
    } finally {
      dispatch(setLoadings(false));
    }
  }
);

const setSchedule = (schedule: ScheduleResults): SetSchedule => ({
  type: SeasonActionTypes.SET_SCHEDULE,
  payload: schedule,
});

type GetSchedule = (args: {
  thirdLevel?: string;
  forthLevel?: string;
  competitionId?: string;
  seasonId?: string;
  language: LanguageType;
}) => Action;

export const getSchedule: GetSchedule = ({
  thirdLevel, forthLevel, competitionId = MenCompetitionsMap.SeriaA, seasonId, language,
}) => (
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoadings(true));
    await waitForConfigurationDownload();

    try {
      const state = getState();
      const competitions = state.configuration[language]?.competitions;
      const activeCompetitionId = thirdLevel ? findCompetitionIdByUrlSlug(competitions, thirdLevel) : competitionId;
      const competition = competitions?.[activeCompetitionId];
      const matchResults = isOptaSDCompetition(competition?.categoryId)
        ? await getSDMatchResultsData({
          id: competition?.id ?? '',
          optaId: competition?.optaId ?? '',
          seasonId: competition?.seasonIds.find(({ isActive }) => isActive)?.seasonId ?? '',
          language,
        }) : await getMatchResultsData({
          seasonId: forthLevel ?? seasonId ?? '',
          optaId: competition?.optaId ?? '',
          language,
        });

      dispatch(setSchedule(mapSchedule(matchResults)));
    } catch (e) {
      console.error('Error on fetching Schedule Opta data', e);
      dispatch(setSeasonError(PageError.Sorry));
    } finally {
      dispatch(setLoadings(false));
    }
  }
);

export const resetWidgetResults = (): ResetWidgetResults => ({ type: SeasonActionTypes.RESET_WIDGET_RESULTS });

const setWidgetResults = (results: MatchResult[]): SetWidgetResults => ({
  type: SeasonActionTypes.SET_WIDGET_RESULTS,
  payload: results,
});

type GetWidgetResultsData = (args: {
  games: Game[];
  language: LanguageType;
}) => Action;

export const getWidgetResultsData: GetWidgetResultsData = ({
  games, language,
}) => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();
    dispatch(getLocalizedGames());

    try {
      const state = getState();
      const uniqCompetitions = uniqWith(games, (game1, game2) => (
        game1.competitionId === game2.competitionId && game1.seasonId === game2.seasonId
      ));
      const matchRequests = uniqCompetitions.reduce((acc, { competitionId, seasonId }) => {
        const competitionOptaId = state.configuration[language]?.competitions[competitionId]?.optaId;

        if (competitionOptaId) {
          const matchRequest = isOptaSDCompetition(competitionId)
            ? getSDMatchResultsData({ id: competitionId, seasonId, optaId: competitionOptaId, language })
            : getMatchResultsData({ seasonId, optaId: competitionOptaId, language });

          acc.push(matchRequest);
        }
        return acc;
      }, [] as Promise<MatchResult[]>[]);

      const allMatches = await Promise.all(matchRequests);

      const results = games.reduce((acc, { gameOptaId }) => {
        const match = allMatches
          .flat()
          .find((game) => game.gameOptaId.replace('g', '') === gameOptaId.replace('g', ''));
        if (match) acc.push(match);

        return acc;
      }, [] as MatchResult[]);

      dispatch(setWidgetResults(results));
    } catch (e) {
      console.error('Error on fetching widget data', e);
    }
  }
);

type SetSeasonMultiLangUrl = (args: RouteParams) => Action;

export const setSeasonMultiLangUrl: SetSeasonMultiLangUrl = ({
  topLevel, secondLevel = '', thirdLevel = '', forthLevel = '', language,
}) => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();

    const state = getState();
    const { configuration } = state;
    const competitionId = findCompetitionIdByUrlSlug(configuration[language]?.competitions, thirdLevel);
    const competitionCategoryId = findCompetitionCategoryIdByUrlSlug(configuration[language]?.competitions, thirdLevel);
    const secondLevelMenu = getSecondLevelMenuItem(state, topLevel, secondLevel, language);
    const params = AppLanguages.reduce((acc, locale) => {
      // eslint-disable-next-line camelcase
      acc.topLevel[locale] = configuration[locale]?.menu?.web_season?.data?.url ?? '';
      acc.secondLevel[locale] = secondLevelMenu?.[locale]?.data?.url ?? '';
      acc.thirdLevel[locale] = configuration[locale]?.competitions?.[competitionId]?.url ?? '';
      acc.forthLevel[locale] = isOptaSDCompetition(competitionCategoryId)
        ? Translations[locale]?.['season.active']
        : forthLevel;
      acc.fifthLevel[locale] = '';

      return acc;
    }, {
      topLevel: {}, secondLevel: {}, thirdLevel: {}, forthLevel: {}, fifthLevel: {},
    } as unknown as CurrentRouteParams);

    dispatch(setCurrentRoute({
      pathId: AppRoutes.CommonPage.path,
      params,
    }));
  }
);
