import React from 'react';
import { FormattedDate } from 'react-intl';
import styled from 'styled-components';

import { MatchDay } from '@app/components';
import { useResultsSelector } from '../ScheduleHooks';

interface MatchMonthProps {
  matchDate: string;
  showAds?: boolean;
}
const MatchMonth = ({ matchDate }: MatchMonthProps): React.ReactElement => {
  const results = useResultsSelector(matchDate);

  return (
    <Section id={matchDate}>
      <Title>
        <FormattedDate
          value={new Date(matchDate)}
          year="numeric"
          month="long"
        />
      </Title>
      { results.map((result) => <MatchDay key={result.matchDate} result={result} />) }
    </Section>
  );
};

export default MatchMonth;

const Section = styled.section`
  padding-bottom: 35px;
`;

const Title = styled.h2`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  background-color: ${(props): string => props.theme.color.purple};
  display: inline-block;
  padding: 10px 25px;
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.23px;
  text-transform: uppercase;
  color: #fff;
`;
