
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class WebAdvertisementBanner extends ContentItem {
    public desktopBannerImage: Elements.AssetsElement;
    public url: Elements.TextElement;
    public mobileBannerImage: Elements.AssetsElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'desktop_banner_image') {
                    return 'desktopBannerImage';
                }
                if (elementName === 'mobile_banner_image') {
                    return 'mobileBannerImage';
                }
                return elementName;
            })
        });
    }
}
