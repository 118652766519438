import React from 'react';
import { FormattedMessage } from 'react-intl';

import { MatchStatistics } from '@app/types/matchTypes';
import { useLineupsSelector } from '@app/pages/MatchCenter/MatchCenterHooks';
import {
  BlockContainerColumn, StatsBlock, Caption, YellowCardImage, RedCardImage,
} from '@app/components/Statistics';
import { ValueLarge } from '@app/pages/MatchCenter/components/MatchStatistics/components/MatchBall';

const MatchDisciplines = (): React.ReactElement => {
  const lineups = useLineupsSelector();

  return (
    <BlockContainerColumn>
      <StatsBlock>
        <StatsBlock marginTop="20px" marginBottom="20px">
          <YellowCardImage />
          <ValueLarge>{`${lineups?.home?.stats?.[MatchStatistics.STATS_YELLOW_CARDS] ?? 0}`}</ValueLarge>
        </StatsBlock>
        <StatsBlock>
          <Caption isSmall><FormattedMessage id="statistics.match.yellowCards" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <ValueLarge>{`${lineups?.away?.stats?.[MatchStatistics.STATS_YELLOW_CARDS] ?? 0}`}</ValueLarge>
          <YellowCardImage />
        </StatsBlock>
      </StatsBlock>
      <StatsBlock marginTop="20px" marginBottom="20px">
        <StatsBlock>
          <RedCardImage />
          <ValueLarge>{`${lineups?.home?.stats?.[MatchStatistics.STATS_RED_CARDS] ?? 0}`}</ValueLarge>
        </StatsBlock>
        <StatsBlock>
          <Caption isSmall><FormattedMessage id="statistics.match.redCards" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <ValueLarge>{`${lineups?.away?.stats?.[MatchStatistics.STATS_RED_CARDS] ?? 0}`}</ValueLarge>
          <RedCardImage />
        </StatsBlock>
      </StatsBlock>
    </BlockContainerColumn>
  );
};

export default MatchDisciplines;
