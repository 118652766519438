import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { InviolaCatalogItem } from '@app/types/inviolaTypes';
import { CommonButton } from '@app/components/Buttons';
import { setCustomerRedirectPath } from '@app/store/actions/inviolaActions/customer';
import {
  useInviolaCustomer, useInviolaPath, useIsConnected,
} from '@app/components/Inviola/InviolaHooks';

import RequestItemModal from './RequestItemModal';

interface RequestItemButtonProps {
  item: InviolaCatalogItem;
  onRequestDone?: () => void;
}

const RequestItemButton = React.memo(({ item, onRequestDone }: RequestItemButtonProps): React.ReactElement => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const customer = useInviolaCustomer();
  const isConnected = useIsConnected();
  const { authPath } = useInviolaPath();

  const onRegistrationClick = (): void => {
    dispatch(setCustomerRedirectPath(pathname));
    history.push(authPath);
  };

  return isConnected ? (
    <>
      <RequestButton
        disabled={(customer?.balancePoints ?? 0) < item.points}
        onClick={() => setModalVisible(true)}
      >
        <FormattedMessage id="buttons.request" />
      </RequestButton>
      <RequestItemModal
        open={isModalVisible}
        item={item}
        onClose={() => {
          setModalVisible(false);
          if (onRequestDone) onRequestDone();
        }}
      />
    </>
  ) : (
    <RequestButton onClick={onRegistrationClick}>
      <FormattedMessage id="inviola.registration.submit" />
    </RequestButton>
  );
});

export const RequestButton = styled(CommonButton)<{ disabled?: boolean; }>`
  border-color: ${({ theme }): string => theme.color.red};
  background-color: ${({ theme }): string => theme.color.red};

  font-family: ${({ theme }): string => theme.fontFamily.commutersSans};
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;

  color: #ffffff;
  height: 30px;
  width: 200px;
  margin: 0 7px;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }): string => theme.color.red};
    background-color: #ffffff;
    color: ${({ theme }): string => theme.color.red};
  }

  &:disabled {
    border-color: ${({ theme }): string => theme.color.gray};
    background-color: ${({ theme }): string => theme.color.gray};
    color: #ffffff;
    cursor: not-allowed;
  }
`;

export default RequestItemButton;
