import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { EditorialContentTypes, Video } from '@app/types/newsTypes';
import { SeoData, SeoSocialTypes } from '@app/types/configurationTypes';
import { AppState } from '@app/types/appStateTypes';
import { RouteParams } from '@app/types/routerTypes';
import { Badge } from '@app/types/videoPlayerTypes';

import { getNewsByUrlSlug, resetNews } from '@app/store/actions/newsActions';

import { useLanguage, useIsTheSamePage } from '@app/components/Hooks';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales';

import { MountContext } from '@app/ReactContext';

import { Colors } from '@app/styles';

export const useVideo = (): Video | null => {
  const language = useLanguage();

  return useSelector<AppState, Video | null>(
    (state) => state.news[EditorialContentTypes.VIDEO].selectedItemDetails?.[language] ?? null,
    isEqual,
  );
};

export const useDownloadVideo = (): void => {
  const video = useVideo();
  const isTheSameVideo = useIsTheSamePage();
  const dispatch = useDispatch();
  const params: RouteParams = useParams();
  const language = useLanguage();
  const { isInitialMount } = useContext(MountContext);

  useEffect(() => {
    if (!isInitialMount && (!video || !isTheSameVideo)) {
      dispatch(resetNews());
      dispatch(getNewsByUrlSlug({
        ...params,
        language,
        type: EditorialContentTypes.VIDEO,
      }));
    }

    return (): void => {
      dispatch(resetNews());
    };
  }, [params.forthLevel, params.fifthLevel]);
};

export function useBadge(category: string, isLive: boolean): Badge {
  const locale = useLanguage();
  const badgeTextId = `news.taxonomy.${category}`;

  let badgeText = Translations[locale][badgeTextId] as string;
  let badgeColor = '';

  if (isLive) {
    badgeText = Translations[locale]['videoDetails.badge.live'] || 'live';
    badgeColor = Colors.red;
  }

  return { badgeText, badgeColor };
}

export const useVideoSeo = (): SeoData | undefined => {
  const video = useVideo();
  const socialImageUrl = video?.seo?.socialImageUrl ? video?.seo?.socialImageUrl : video?.image?.url ?? '';
  return video?.seo && { ...video.seo, socialType: SeoSocialTypes.VIDEO, socialImageUrl };
};
