import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { PageTitle, ThreeColumnGrid } from '@app/components';
import {
  useCommonPageBaseMultiLangRoute,
} from '@app/pages/CommonPage/CommonPageHooks';
import { LoadMoreButton } from '@app/components/Buttons';
import { ContainerLarge } from '@app/components/WebTemplates/components';
import { InviolaPromotionItem } from '@app/components/Inviola';
import {
  usePromotionsList, useLoadMorePromotions, usePromotionsDownload, usePromotionsLoadingState,
} from './PromotionsListPageHooks';

const InviolaPromotionsListPage: React.FC = () => {
  useCommonPageBaseMultiLangRoute();
  const loadMorePromotions = useLoadMorePromotions();
  const { hasMoreData, items } = usePromotionsList();
  const isLoading = usePromotionsLoadingState();
  usePromotionsDownload();

  const sizes = {
    0: { width: 450, height: 450 },
  };

  return (
    <Container>
      <PageTitle>Promotions</PageTitle>
      <GridContainer>
        <ThreeColumnGrid>
          {
              items.map((item) => (
                <InviolaPromotionItem data={item} key={item.id} imageWidthMap={sizes} />
              ))
            }
        </ThreeColumnGrid>
      </GridContainer>
      { hasMoreData && (
        <ButtonContainer>
          <LoadMoreButton disabled={isLoading} onClick={loadMorePromotions}>
            <FormattedMessage id={isLoading ? 'buttons.loading.inProgress' : 'buttons.loadingNews'} />
          </LoadMoreButton>
        </ButtonContainer>
      ) }
    </Container>
  );
};

const Container = styled(ContainerLarge)`
  max-width: 920px;
  // padding: 0 var(--horizontal-padding);

  & h1 {
    margin-bottom: 30px;
  }
`;

const ButtonContainer = styled.div`
  padding: 0 0 40px;
`;

const GridContainer = styled.div`
  padding-bottom: 40px;
`;

export default InviolaPromotionsListPage;
