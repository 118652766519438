import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { InviolaAppError, InviolaAppSuccess } from '@app/services/inviola/types/errorTypes';
import { InviolaRegistrationSteps } from '@app/services/inviola/types/stepTypes';
import { InviolaCustomerDynamicFields } from '@app/services/inviola/apiConstants';
import { InviolaCustomer } from '@app/types/inviolaTypes';

import { InviolaDates } from '@app/constants/inviolaConstants';
import { registerNewCustomer, resetErrors } from '@app/store/actions/inviolaActions';

import {
  Button,
  Column,
  Row,
  Container,
  CenteredBlock,
  Description,
  MessageContainerTop,
} from '@app/components/Inviola/InviolaComponents';
import {
  useCountries,
  useGenders,
  useInputValidations,
  useRegistration,
  useSyncInProgressSelector,
  useRegistrationOptionalFieldsVisibility,
} from '@app/components/Inviola/InviolaHooks';
import { Input, Select, Checkbox } from '@app/components/Inviola/InviolaFields';
import InviolaGeoLevelSelector from '@app/components/Inviola/InviolaGeoLevelSelector';
import InviolaDynamicField from '@app/components/Inviola/InviolaDynamicField';
import { InviolaErrorMessage, InviolaSuccessMessage } from '@app/components/Inviola/InviolaMessages';

import { mapSelectOptions } from '@app/helpers/inviolaHelpers';
import { ExpandArrow } from '@app/components/Icons';

const pointsAmount = 100;

const RegistrationWithoutCard = (): React.ReactElement => {
  const { registrationCredentials, registrationCustomer, registrationStep } = useRegistration();

  const isEmailFieldDisabled = [
    InviolaRegistrationSteps.RegistrationCardWithEmailValidated,
  ].includes(registrationStep) && registrationCredentials?.email?.length;

  const isRegistrationWithCardValidated = [
    InviolaRegistrationSteps.RegistrationCardWithoutEmailValidated,
    InviolaRegistrationSteps.RegistrationCardWithEmailValidated,
  ].includes(registrationStep);

  const use100PointsVisibility = false;
  const useOptionalFieldsVisibility = useRegistrationOptionalFieldsVisibility() && isRegistrationWithCardValidated;
  const useDefaultValues = isRegistrationWithCardValidated ? { defaultValues: registrationCustomer } : {};

  const methods = useForm<InviolaCustomer>({ mode: 'onChange', ...useDefaultValues });
  const { handleSubmit, trigger, formState, watch } = methods;
  const { isValid } = formState;

  // REQUIRED FIELDS TO WATCH
  const selectedFirstName = watch('firstName');
  const selectedLastName = watch('lastName');
  const selectedBirthDate = watch('birthDate');
  // OPTIONAL FIELDS TO WATCH
  const selectedCountry = watch('country');

  const [showOptionalFields, setShowOptionalFields] = useState(use100PointsVisibility && useOptionalFieldsVisibility);

  const dispatch = useDispatch();

  const validations = useInputValidations();

  const countries = useCountries();
  const availableGenders = useGenders();
  const syncInProgress = useSyncInProgressSelector();

  const getDescription = (): string => {
    switch (registrationStep) {
      case InviolaRegistrationSteps.RegistrationCardWithoutEmailValidated:
        return 'inviola.registrationWithCard.hasNoEmail.description';
      case InviolaRegistrationSteps.RegistrationCardWithEmailValidated:
        return 'inviola.registrationWithCard.hasEmail.description';
      default:
        return 'inviola.registration.description';
    }
  };

  const submit = (data: InviolaCustomer) => {
    dispatch(resetErrors());
    dispatch(registerNewCustomer(data));
  };

  useEffect((): void => {
    /** NOTE: use trigger() to validate REQUIRED Card pre-filled data and show errors if any */
    if (isRegistrationWithCardValidated) {
      // eslint-disable-next-line no-void
      if (selectedFirstName) void trigger('firstName');
      // eslint-disable-next-line no-void
      if (selectedLastName) void trigger('lastName');
      // eslint-disable-next-line no-void
      if (selectedBirthDate) void trigger('birthDate');
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <MessageContainerTop>
        <InviolaSuccessMessage
          type={InviolaAppSuccess.CustomerCardIsAccepted}
          translationId="inviola.registrationNotification.cardAccepted"
        />
      </MessageContainerTop>

      <TextContainer>
        <Description><FormattedMessage id={getDescription()} /></Description>
        <Notice><FormattedMessage id="inviola.registration.notice" /></Notice>
      </TextContainer>

      <form onSubmit={handleSubmit(submit)}>
        <Container>
          <Row>
            <Column>
              <Input
                name="firstName"
                labelId="inviola.registration.name"
                placeholderId="inviola.form.name.placeholder"
                validations={validations.required}
              />
            </Column>
            <Column>
              <Input
                name="lastName"
                labelId="inviola.registration.surname"
                placeholderId="inviola.form.surname.placeholder"
                validations={validations.required}
              />
            </Column>
          </Row>

          <Row>
            <Column>
              <Input
                name="email"
                labelId="inviola.registration.email"
                placeholderId="inviola.form.email.placeholder"
                validations={validations.email}
                defaultValue={registrationCredentials?.email}
                disabled={isEmailFieldDisabled}
              />
            </Column>
            <Column>
              <Input
                name="birthDate"
                type="date"
                labelId="inviola.registration.birthdate"
                min={InviolaDates.min}
                max={InviolaDates.max}
                validations={validations.date}
              />
            </Column>
          </Row>
          {use100PointsVisibility && (
            <Row>
              <Column>
                <ToggleButton type="button" onClick={() => setShowOptionalFields(!showOptionalFields)}>
                  <ToggleButtonPoints>
                    <strong>{pointsAmount}</strong>
                    <span>pts</span>
                  </ToggleButtonPoints>
                  <ToggleButtonText>
                    <FormattedMessage id="inviola.registration.promotion" />
                  </ToggleButtonText>
                  <ToggleButtonToggle open={showOptionalFields} />
                </ToggleButton>
              </Column>
            </Row>
          )}
          <ToggleContent open={showOptionalFields}>
            <Row>
              <Column width="30%">
                <Select
                  name="gender"
                  labelId="inviola.registration.gender"
                  placeholderId="inviola.form.gender.placeholder"
                  options={availableGenders}
                  emptyOption
                />
              </Column>

              <Column>
                <InviolaDynamicField id={InviolaCustomerDynamicFields.BirthPlace} />
              </Column>
            </Row>
            <Row>
              <Column>
                <InviolaDynamicField id={InviolaCustomerDynamicFields.Nationality} />
              </Column>
            </Row>

            <Row>
              <Column>
                { countries && (
                  <Select
                    name="country"
                    labelId="inviola.registration.country"
                    options={mapSelectOptions(countries)}
                    emptyOption
                  />
                ) }
              </Column>
            </Row>

            <InviolaGeoLevelSelector selectedCountry={selectedCountry} />

            <Row>
              <Column>
                <Input
                  name="address"
                  labelId="inviola.registration.address"
                  placeholderId="inviola.form.address.placeholder"
                />
              </Column>

              <Column width="30%">
                <Input
                  name="addressNumber"
                  labelId="inviola.registration.addressNumber"
                  placeholderId="inviola.form.addressNumber.placeholder"
                />
              </Column>
            </Row>

            <Row>
              <Column width="40%">
                <Input
                  name="zip"
                  labelId="inviola.registration.zip"
                  placeholderId="inviola.form.zip.placeholder"
                />
              </Column>

              <Column>
                <Input
                  name="identityCard"
                  labelId="inviola.registration.identityCard"
                  placeholderId="inviola.form.dni.placeholder"
                  validations={validations.fiscal}
                />
              </Column>
            </Row>

            <Row>
              <Column>
                <InviolaDynamicField id={InviolaCustomerDynamicFields.Job} />
              </Column>

              <Column>
                <Input
                  name="mobile"
                  labelId="inviola.registration.mobile"
                  placeholderId="inviola.form.phone.placeholder"
                />
              </Column>
            </Row>
          </ToggleContent>

          <Row>
            <Column>
              <InviolaDynamicField id={InviolaCustomerDynamicFields.AcceptanceOfRegulations} />

              <Checkbox name="usedForPromotions" type="checkbox" labelId="inviola.registration.usedForPromotions" />
              <Checkbox name="usedForStatistics" type="checkbox" labelId="inviola.registration.usedForStatistics" />

            </Column>
          </Row>
        </Container>

        <InviolaErrorMessage errorType={InviolaAppError.RegistrationFormError} />

        <Button type="submit" disabled={!isValid || syncInProgress}>
          <FormattedMessage id="inviola.registration.submit" />
        </Button>
      </form>
    </FormProvider>
  );
};

const TextContainer = styled.div`
  & * { text-align: left!important; }
`;

const Notice = styled(CenteredBlock)`
  color: ${(props): string => props.theme.color.purple};
  padding-bottom: 2.857rem;
`;

const ToggleButton = styled.button`
  display: flex;
  align-items: center;

  margin: 15px 0;

  border: 0;
  outline: 0;
  padding: 0;

  background: transparent;

  width: 100%;
`;

const ToggleButtonPoints = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  height: 40px;
  width: 40px;

  line-height: 12px;
  font-size: 12px;

  background-color: ${(props): string => props.theme.color.shadow};
  color: white;
`;

const ToggleButtonText = styled.div`
  margin: 0 20px;

  color: ${(props): string => props.theme.color.purple};

  text-align: left;

  width: 100%;
`;

const ToggleButtonToggle = styled(ExpandArrow)<{ open?: boolean }>`
  width: 12px;
  margin-right: 5px;

  rotate: ${(props): string => (props.open ? '-180deg' : 'none')};
`;

const ToggleContent = styled.div<{ open?: boolean }>`
  height: ${(props): string => (props.open ? 'auto' : '0')};
  margin-top: 30px;

  overflow: ${(props): string => (props.open ? 'visible' : 'hidden')};
`;

export default RegistrationWithoutCard;
