import { ResponsiveType } from 'react-multi-carousel';

import { DevicesSize } from '@app/styles';
import { WebTemplatePagesSecondLevelMenuId } from '@app/constants/configurationConstants';
import { SubMenuItem } from '@app/types/configurationTypes';
import {
  LocalizedWebTemplateData,
  PageType,
  WebLineCarouselType,
  WebTabItemTemplateElement,
  WebTabsTemplateElement,
  WebTemplateData,
  WebTemplateTypes,
  WebTemplateTypesWithContent,
} from '@app/types/webTemplateTypes';
import AppState from '@app/types/appStateTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { getLastLevelMenuId } from '@app/helpers/menuHelpers';
import { RouteParams } from '@app/types/routerTypes';
import { AppLanguages } from '@app/constants/localizationConstants';
import { EditorialContentTypes } from '@app/types/newsTypes';

type IsQuestion = (secondLevelMenuId: string, questionSlug: string) => boolean;

export const isQuestion: IsQuestion = (secondLevelMenuId, questionSlug) => (
  Boolean(secondLevelMenuId === WebTemplatePagesSecondLevelMenuId.FAQ_MENU && questionSlug)
);

type IsHallOfFame = (secondLevelMenuId: string) => boolean;

export const isHallOfFame: IsHallOfFame = (secondLevelMenuId) => (
  secondLevelMenuId === WebTemplatePagesSecondLevelMenuId.HALL_OF_FAME
);

type IsHallOfFamePlayer = (secondLevelMenuId: string, playerSlug: string) => boolean;

export const isHallOfFamePlayer: IsHallOfFamePlayer = (secondLevelMenuId, playerSlug) => (
  Boolean(secondLevelMenuId === WebTemplatePagesSecondLevelMenuId.HALL_OF_FAME && playerSlug)
);

export const getPageType = (secondLevelMenu: SubMenuItem | null | undefined, tab: string): PageType => {
  const isQuestionPage = isQuestion(secondLevelMenu?.data?.id ?? '', tab);
  const isHallOfFamePage = isHallOfFame(secondLevelMenu?.data?.id ?? '');
  const isHallOfFamePlayerPage = isHallOfFamePlayer(secondLevelMenu?.data?.id ?? '', tab);

  if (isQuestionPage) {
    return PageType.Question;
  }

  if (isHallOfFamePlayerPage) {
    return PageType.HallOfFamePlayer;
  }

  if (isHallOfFamePage) {
    return PageType.HallOfFame;
  }

  return PageType.Template;
};

interface LocalizedWebTemplateDataParams {
  state: AppState;
  language: LanguageType;
  params: RouteParams;
}

type GetLocalizedWebTemplateData = (params: LocalizedWebTemplateDataParams) => LocalizedWebTemplateData | null
export const getLocalizedWebTemplateData: GetLocalizedWebTemplateData = ({ language, state, params }) => {
  const lastLevelMenuId = getLastLevelMenuId({ state, language, params });
  const data = state.webTemplate.templates[lastLevelMenuId ?? ''];
  return data ?? null;
};

type GetWebTemplateData = (params: LocalizedWebTemplateDataParams) => WebTemplateData[] | null
export const getWebTemplateData: GetWebTemplateData = (params) => {
  const localizedWebTemplateData = getLocalizedWebTemplateData(params);
  return localizedWebTemplateData?.[params.language] ?? null;
};

export const getWebLineCarouselConfiguration = (type: WebLineCarouselType): ResponsiveType => {
  let items;
  switch (type) {
    case WebLineCarouselType.InviolaCatalog:
      items = [4, 3, 2, 1];
      break;
    default:
      items = [3, 3, 2, 1];
  }
  return {
    largeDesktop: {
      breakpoint: { max: Infinity, min: DevicesSize.desktop },
      items: items[0],
      slidesToSlide: items[0],
    },
    desktop: {
      breakpoint: { max: DevicesSize.desktop, min: DevicesSize.tabletMedium },
      items: items[1],
      slidesToSlide: items[1],
    },
    tablet: {
      breakpoint: { max: DevicesSize.tabletMedium, min: DevicesSize.mobileLarge },
      items: items[2],
      slidesToSlide: items[2],
      partialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: DevicesSize.mobileLarge, min: 0 },
      items: items[3],
      slidesToSlide: items[3],
      partialVisibilityGutter: 0,
    },
  };
};

function findTabs(templateData: WebTemplateData[]): WebTabsTemplateElement | null {
  const tabs = templateData?.find((data) => data?.type === WebTemplateTypes.WebTabs
    || (data?.type === WebTemplateTypes.WebLanding && findTabs(data.content)));
  return tabs ? tabs as WebTabsTemplateElement : null;
}

type GetCurrentTab = (params: {
  state: AppState;
  language: LanguageType;
  params: RouteParams;
}) => Partial<Record<LanguageType, WebTabItemTemplateElement | null>>
export const getCurrentLocalizedTab: GetCurrentTab = ({ language, state, params }) => {
  const { thirdLevel = '', forthLevel = '' } = params;
  const localizedWebTemplateData = getLocalizedWebTemplateData({ state, language, params });
  const tabUrl = thirdLevel || forthLevel;
  const webTemplateData = localizedWebTemplateData?.[language];
  const tabs = webTemplateData && findTabs(webTemplateData);
  const currentTabId = tabs?.tabs?.find((tabItem) => tabItem?.url === tabUrl)?.id;

  return AppLanguages.reduce((acc, language) => {
    const webTemplateData = localizedWebTemplateData?.[language];
    const tabs = webTemplateData && findTabs(webTemplateData);

    acc[language] = tabs?.tabs?.find((tabItem) => tabItem.id === currentTabId)
      ?? null;
    return acc;
  }, {} as unknown as Partial<Record<LanguageType, WebTabItemTemplateElement | null>>);
};

export const indexingWebTemplates = (dataList: WebTemplateData[]): void => {
  let index = 0;
  let indexOfType = 0;
  let type: WebTemplateTypes | EditorialContentTypes;
  dataList.forEach((data) => {
    if (data) {
      index++;
      if (data.type && data.type !== type) {
        type = data.type;
        indexOfType = 0;
      }
      indexOfType++;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      data.indexOfType = indexOfType;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      data.index = index;
      if ('content' in data && data?.type && WebTemplateTypesWithContent.includes(data?.type)) {
        indexingWebTemplates(data.content as WebTemplateData[]);
      }
    }
  });
};

export const decodeURISafe = (source: string): string => {
  if (!source) {
    return source;
  }
  try {
    return decodeURI(source.replace(/%(?![0-9a-fA-F][0-9a-fA-F]+)/g, '%25'));
  } catch (e) {
    console.error(`Error on embedded content decoding: + ${e}`);
  }
  return '';
};
