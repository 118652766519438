import { encodeQueryData } from '../helpers/requestHelpers';

const USE_API_RECORDER = false;
const API_RECORDER_OPTA_SOURCE = 'http://10.50.28.36:8080/redirectApi/opta/';
const API_RECORDER_PARAMS = USE_API_RECORDER ? { user: 'acf', psw: 'netco0202' } : {};

const GetTokenURL = (Host: string): string => `${Host.endsWith('/') ? Host.replace(/\/$/, '') : Host}/api/auth/statistic`;
const BaseURL = process.env.API === 'production'
  ? 'https://acffiorentina-api-prod.netcosports.com'
  : 'https://acffiorentina-api-integ.netcosports.com';
const OPTA_SOURCE_URL = USE_API_RECORDER ? API_RECORDER_OPTA_SOURCE : `${BaseURL}/api/proxy/opta/`;
const OPTA_SD_SOURCE_URL = `${BaseURL}/api/proxy/optasd/`;
const OPTA_SD_AUTH_TOKEN = 'outletAuthKey';

export interface RequestParamsInterface {
  [name: string]: string | number | boolean | undefined;
}

interface RequestInterface {
  params?: RequestParamsInterface;
  path?: string;
  isOptaSD?: boolean;
}

interface TokenResponse {
  code: number;
  message: string;
  data?: TokenData;
}

interface TokenData {
  user: string;
  token: string;
}

async function sendRequestInternal<T>(request: RequestInterface): Promise<T> {
  const serverLocation = `${window.location.protocol}//${window.location.hostname}:${window.location.hostname === 'localhost' ? '5005' : window.location.port}`;
  const { path, params, isOptaSD } = request;
  const query = params ? `?${encodeQueryData({ ...params, ...API_RECORDER_PARAMS })}` : '';
  const requestUrl = `${isOptaSD ? OPTA_SD_SOURCE_URL : OPTA_SOURCE_URL}${path || ''}${isOptaSD ? `/${OPTA_SD_AUTH_TOKEN}` : ''}${query}`;

  const token = await fetch(GetTokenURL(serverLocation))
    .then((response) => response.json())
    .then((response: TokenResponse) => response.data?.token || '');

  const headers = {
    'x-authorization': token,
  };

  const response = await fetch(
    requestUrl,
    {
      method: 'GET',
      headers,
    },
  );

  if (response.ok) return response.json() as Promise<T>;

  throw Error(`Request rejected with status ${response.status}. ${response.statusText}`);
}

export default async function sendRequest(request: RequestInterface): Promise<{[propName: string]: unknown}> {
  return sendRequestInternal<{ [propName: string]: unknown }>(request);
}

export async function sendRequestTyped<T>(request: RequestInterface): Promise<T> {
  return sendRequestInternal<T>(request);
}
