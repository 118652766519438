export const disableScrolling = (): void => {
  const rootElement = document.getElementById('root');

  if (rootElement) {
    rootElement.style.overflow = 'hidden';
  }
};

export const enableScrolling = (): void => {
  const rootElement = document.getElementById('root');

  if (rootElement) {
    rootElement.style.overflow = 'initial';
  }
};

export const scrollToElement = (id: string, animated?: boolean): void => {
  const element = document.getElementById(id);
  const scrollBehavior = animated ? 'smooth' : 'auto';
  element && element.scrollIntoView({ behavior: scrollBehavior, block: 'center', inline: 'center' });
};
