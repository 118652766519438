interface ScreenParams {
  top: number;
  left: number;
  systemZoom: number;
}

const getMultiScreenParams = (w: number, h: number): ScreenParams => {
  const multiScreensLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const multiScreensTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
  const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
  const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height;
  const systemZoom = width / window.screen.availWidth;

  return {
    top: (height - h) / 2 / systemZoom + multiScreensTop,
    left: (width - w) / 2 / systemZoom + multiScreensLeft,
    systemZoom,
  };
};

export function openCenteredPopup(url: string, title: string, w: number, h: number): void {
  const { top, left, systemZoom } = getMultiScreenParams(w, h);
  const newWindow = window.open(url, title, `scrollbars=yes, width=${w / systemZoom}, height=${h / systemZoom}, top=${top}, left=${left}`);

  if (window.focus && newWindow) newWindow.focus();
}
