import { RemoteSubtitlesFormat } from '@app/types/videoPlayerTypes';

const getRemoteSubtitles = async (url: string, format: RemoteSubtitlesFormat): Promise<string | void> => {
  let subtitles = await fetchSubtitles(url);

  if (!subtitles) { return; }

  if (format === RemoteSubtitlesFormat.SRT) {
    subtitles = convertSrtToVtt(subtitles);
  }

  return asBase64(subtitles);
};

const fetchSubtitles = async (url: string): Promise<string> => {
  const response = await fetch(url);

  if (!response.ok) {
    throw Error(`Request rejected with status ${response.status}. ${response.statusText}`);
  }

  return response.text();
};

// Inspired from https://github.com/silviapfeiffer/silviapfeiffer.github.io
function convertSrtToVtt(data: string): string {
  // remove dos newlines
  let srt = data.replace(/\r+/g, '');
  // trim white space start and end
  srt = srt.replace(/^\s+|\s+$/g, '');

  // get cues
  const cuelist = srt.split('\n\n');
  let result = '';

  if (cuelist.length > 0) {
    result += 'WEBVTT\n\n';
    for (let i = 0; i < cuelist.length; i += 1) {
      result += convertToVttCue(cuelist[i]);
    }
  }

  return result;
}

function asBase64(vttLines: string): string {
  const safeSubtitles = btoa(unescape(encodeURIComponent(vttLines)));
  return `data:text/vtt;base64,${safeSubtitles}`;
}

function convertToVttCue(srtCaption: string): string {
  let cue = '';
  const s = srtCaption.split(/\n/);

  // concatenate muilt-line string separated in array into one
  while (s.length > 3) {
    for (let i = 3; i < s.length; i++) {
      s[2] += `\n${s[i]}`;
    }
    s.splice(3, s.length - 3);
  }

  let line = 0;

  const timeCodeRegexp = /\d+:\d+:\d+/;
  const cueRegexp = /\w+/;
  const timePartsRegexp = /(\d+):(\d+):(\d+)(?:,(\d+))?\s*--?>\s*(\d+):(\d+):(\d+)(?:,(\d+))?/;

  // detect identifier
  if (!timeCodeRegexp.test(s[0]) && timeCodeRegexp.test(s[1])) {
    cue += `${cueRegexp.exec(s[0])}\n`;
    line += 1;
  }

  // get time strings
  if (!timeCodeRegexp.test(s[line])) {
    // file format error or comment lines
    return '';
  }

  // convert time string
  const m = timePartsRegexp.exec(s[1]);

  if (!m) {
    // Unrecognized timestring
    return '';
  }

  cue += `${m[1]}:${m[2]}:${m[3]}.${m[4]} --> ${m[5]}:${m[6]}:${m[7]}.${m[8]}\n`;
  line += 1;

  // get cue text
  if (s[line]) {
    cue += `${s[line]}\n\n`;
  }

  return cue;
}

export { getRemoteSubtitles };
