import { CareerItem } from '@app/services/careers/types/responseTypes';
import { prepareCareerList } from '@app/services/careers/helpers/requestHelpers';

const CAREER_SOURCE_URL = 'https://storage.googleapis.com/static-production.netcosports.com/acf/feeds/acf_career_opta.json';

export async function getCareersRequest(): Promise<Array<CareerItem>> {
  const response = await fetch(CAREER_SOURCE_URL);

  if (response.ok) {
    const careerList = await response.json() as Array<CareerItem>;
    return new Promise((resolve) => resolve(prepareCareerList(careerList)));
  }

  throw Error(`Request rejected with status ${response.status}. ${response.statusText}`);
}
