import { useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { AppState } from '@app/types/appStateTypes';
import { getSchedule, resetSeason, getWidgetResultsData, resetWidgetResults } from '@app/store/actions/seasonActions';
import { MatchResult, Game } from '@app/types/matchTypes';
import { useLanguage } from '@app/components/Hooks';
import {
  useCompetitionsSelector, useOptaSeasonIdCheck, useOptaSeasonId, useCompetitionIdSelector,
} from '@app/pages/Season/Season/SeasonHooks';
import { MountContext } from '@app/ReactContext';

export const useCalendarSelector = (): string[] => (
  useSelector<AppState, string[]>(
    (state) => Object.keys(state.season.schedule),
    shallowEqual,
  )
);

export const useResultsSelector = (id: string): MatchResult[] => (
  useSelector<AppState, MatchResult[]>(
    (state) => state.season.schedule[id] ?? [],
    isEqual,
  )
);

export const useCheckResultsSelector = (): boolean => (
  useSelector<AppState, boolean>(
    (state) => Boolean(Object.keys(state.season.schedule ?? {}).length),
    shallowEqual,
  )
);

export const useWidgetResultsSelector = (): MatchResult[] => (
  useSelector<AppState, MatchResult[]>(
    (state) => state.season.widget,
    shallowEqual,
  )
);

export const useWidgetResultsDownload = (games: Game[]): void => {
  const language = useLanguage();
  const dispatch = useDispatch();

  useEffect((): void => {
    dispatch(getWidgetResultsData({ games, language }));
  }, [language]);
};

export const useWidgetResetOnUnmount = (): void => {
  const dispatch = useDispatch();

  useEffect(() => (): void => {
    dispatch(resetWidgetResults());
  }, []);
};

export const useScheduleResultsDownload = (): void => {
  const { isInitialMount } = useContext(MountContext);
  const language = useLanguage();

  const dispatch = useDispatch();
  const competitionId = useCompetitionIdSelector();
  const seasonId = useOptaSeasonId();
  const areResultsLoaded = useCheckResultsSelector();
  const isSeasonIdValid = useOptaSeasonIdCheck();
  const competitions = useCompetitionsSelector();
  // const isSeasonIdActive = useSeasonIdActiveStateCheck();// TODO:ENABLE

  useEffect((): void => {
    if (isInitialMount) {
      // check if data has been loaded on server before very first render
      if (!areResultsLoaded && isSeasonIdValid) {
        dispatch(getSchedule({ competitionId, seasonId, language }));
        // if (isSeasonIdActive) dispatch(getTicketingSingle(language)); // TODO:ENABLE
      }
    } else if (isSeasonIdValid) {
      dispatch(resetSeason());
      // dispatch(resetTicketingSingle()); // TODO:ENABLE
      dispatch(getSchedule({ competitionId, seasonId, language }));
      // if (isSeasonIdActive) dispatch(getTicketingSingle(language)); // TODO:ENABLE
    }
  }, [competitionId, seasonId, competitions]);
};
