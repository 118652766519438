import React from 'react';
import styled from 'styled-components';

import { VideoPlayer } from '@app/components';
import { Video } from '@app/types/newsTypes';
import { ThroneVideo } from '@app/types/configurationTypes';
import { useBadge } from './VideoDetailsHooks';

interface VideoContentProps {
  video: Video;
  category: string;
  autoPlay: boolean;
}

const VideoContent: React.FC<VideoContentProps> = ({ video, category, autoPlay }) => {
  const { badgeText, badgeColor } = useBadge(category, video.isLive);
  const throneVideo = video.video as ThroneVideo;

  return (
    <>
      <VideoPlayer
        src={throneVideo?.url ?? ''}
        title={video?.title ?? ''}
        badge={badgeText}
        badgeColor={badgeColor}
        showControls={!video.isLive}
        remoteSubtitles={throneVideo?.subtitles ?? ''}
        autoPlay={autoPlay}
      />

      <Description>{video.subtitle}</Description>
    </>
  );
};

const Description = styled.p`
  margin-top: 50px;

  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  letter-spacing: 0.21px;
  text-align: justify;
`;

export default VideoContent;
