import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import trimEnd from 'lodash/trimEnd';
import { useEffect, useState } from 'react';

import { Header } from '@app/types/configurationTypes';
import { AppState } from '@app/types/appStateTypes';

import { useLanguage } from '@app/components/Hooks';

export const useHeader = (): Header | undefined => {
  const language = useLanguage();
  return useSelector<AppState, Header| undefined>(
    (state) => state.configuration[language]?.header,
    shallowEqual,
  );
};

export function useIsHomeDetection(): boolean {
  const { formatMessage } = useIntl();
  const language = useLanguage();
  const homeUrl = formatMessage({ id: 'routes.home' });

  return !!useRouteMatch({
    path: `${trimEnd(homeUrl, '/')}/${language}`,
    exact: true,
  });
}

export const useMenuPosition = (): { isUpScroll: boolean; isOnTop: boolean; isNotScrolled: boolean; } => {
  const [scrollPos, setScrollPos] = useState(window.pageYOffset);
  const [isUpScroll, setIsUpScroll] = useState(false);

  const windowHeight = window.innerHeight;

  useEffect(() => {
    const onScroll = (): void => {
      const currentScrollPos = window.pageYOffset;

      if (scrollPos > currentScrollPos) {
        setIsUpScroll(true);
      } else {
        setIsUpScroll(false);
      }

      setScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', onScroll);

    return (): void => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [scrollPos]);

  // required for correct ssr work
  if (!windowHeight) {
    return {
      isUpScroll: false,
      isOnTop: true,
      isNotScrolled: true,
    };
  }

  return {
    isUpScroll,
    isOnTop: scrollPos < windowHeight * 0.2,
    isNotScrolled: scrollPos < 5,
  };
};
