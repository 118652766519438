import React from 'react';
import styled from 'styled-components';
import { SponsorParams } from '@app/types/webTemplateTypes';

interface SponsorParamsProps {
  data: SponsorParams;
}

const WebSponsor = ({ data }: SponsorParamsProps): React.ReactElement => (
  <a target="_blank" href={data.redirectUrl} rel="noreferrer">
    <SponsorItem>
      <PhotoBlock>
        <img src={data.photo.url} alt={data.photo.description} />
      </PhotoBlock>
      <Title>{data.title}</Title>
    </SponsorItem>
  </a>
);

export default WebSponsor;

const SponsorItem = styled.div`
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 155px;
  height: 194px;
  text-align: center;
  padding-bottom: 22px;
  transition: all 0.3s ease;

  img {
    max-width: 90%;
    max-height: 75%;
  }

  @media only screen and (min-width: 520px) {
    width: 215px;
    height: 270px;
    padding-bottom: 32px;
  }

  &:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2);
    transition: all 0.3s ease;
  }
`;

const Title = styled.h3`
  font-size: 15px;
  font-weight: bold;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  padding-bottom: 15px;
  color: #000;
  max-width: 90%;
  margin: 0 auto;

  @media only screen and (min-width: 520px) {
      padding-bottom: 48px;
      font-size: 20px;
  }
`;

const PhotoBlock = styled.div`
  display: flex;
  justify-content: center;
  height: 170px;
  margin: 10px 30px 0;
  align-items: center;
`;
