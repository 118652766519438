import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { isTeamFiorentina } from '@app/helpers/teamsHelpers';
import { Loader } from '@app/components';
import {
  useStandingSelector, useStandingsDownload, useStandingsCompetitionValidation,
} from '@app/pages/Season/Standings/StandingsHooks';
import { useIsLoadingSelector } from '@app/pages/Season/Season/SeasonHooks';
import { getTeamColor } from '@app/helpers/seasonHelpers';

const Standings = (): React.ReactElement => {
  const standings = useStandingSelector();
  const isLoading = useIsLoadingSelector();

  useStandingsDownload();
  useStandingsCompetitionValidation();

  return isLoading ? (<Loader />) : (
    <StandingsContainer>
      <Table>
        <TableThead>
          <TableHeadRow>
            <TableHead />
            <TableHeadPosition><FormattedMessage id="standings.position" /></TableHeadPosition>
            <TableHead> </TableHead>
            <TableHead><FormattedMessage id="standings.points" /></TableHead>
            <TableHead><FormattedMessage id="standings.played" /></TableHead>
            <TableHead><FormattedMessage id="standings.wons" /></TableHead>
            <TableHead><FormattedMessage id="standings.drawn" /></TableHead>
            <TableHead><FormattedMessage id="standings.lost" /></TableHead>
            <TableHead><FormattedMessage id="standings.goalsFor" /></TableHead>
            <TableHead><FormattedMessage id="standings.goalsAgainst" /></TableHead>
            <TableHead><FormattedMessage id="standings.goalsDiff" /></TableHead>
          </TableHeadRow>
        </TableThead>
        <tbody>
          {standings.map((team) => (
            <TableBodyRow key={team.id} isFiorentina={isTeamFiorentina(team.id)}>
              <TableDataColor isFiorentina={isTeamFiorentina(team.id)} index={team.position} />
              <TableDataPosition>{team.position}</TableDataPosition>
              <TableDataName>
                <TeamNameContainer>
                  <Logo src={team.logoUrl} alt={team.teamName} />
                  {team.teamName}
                </TeamNameContainer>
              </TableDataName>
              <TableDataStats>{team.points}</TableDataStats>
              <TableDataStats>{team.played}</TableDataStats>
              <TableDataStats>{team.wons}</TableDataStats>
              <TableDataStats>{team.drawn}</TableDataStats>
              <TableDataStats>{team.lost}</TableDataStats>
              <TableDataStats>{team.goalsFor}</TableDataStats>
              <TableDataStats>{team.goalsAgainst}</TableDataStats>
              <TableDataStats>{team.goalsDiff}</TableDataStats>
            </TableBodyRow>
          ))}
        </tbody>
      </Table>
    </StandingsContainer>
  );
};

export default Standings;

export const StandingsContainer = styled.div`
  max-width: 920px;
  margin: auto;
  padding: 0 10px;

  @media ${Devices.responsiveContent} {
    padding: 0;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
`;

export const TableThead = styled.thead`
  border-radius: 8.6px;
`;

export const TableHeadRow = styled.tr`
  background-color: white;
  color: black;
  height: 60px;

  & th:first-child {
    border-left-width: 3px;
  }

  & th:last-child {
    border-right-width: 3px;
    padding-right: 10px;
  }
`;

export const TableHead = styled.th`
  padding: 15px 0;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.38px;
  line-height: 1.3;

  &:nth-child(8), &:nth-child(9) {
    display: none;
  }
  @media ${Devices.tabletLarge} {
    &:nth-child(8), &:nth-child(9) {
      display: table-cell;
    }
  }
`;

export const TableHeadPosition = styled(TableHead)`
  padding-left: 5px;

  @media ${Devices.tabletLarge} {
    text-align: left;
    padding-left: 15px;
  }
`;

export const TableBodyRow = styled.tr<{ isFiorentina: boolean;}>`
  background-color: ${(props): string => (props.isFiorentina ? props.theme.color.purple : 'white')};
  color: ${(props): string => (props.isFiorentina ? 'white' : 'inherit')};

  & td:first-child {
    border-left-width: 3px;
  }

  & td:last-child {
    border-right-width: 3px;
  }
`;

export const TableData = styled.td`
  text-align: center;
  padding: 10px 0;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 12px;
  font-weight: 300;
  vertical-align: middle;
  letter-spacing: 0.35px;
  line-height: normal;

  &:nth-child(1), &:nth-child(2), &:nth-child(3) {
    font-weight: bold;
    font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  }
`;

export const TableDataColor = styled.td<{ isFiorentina: boolean; index?: number; }>`
  width: 3px;
  background-color: ${({ isFiorentina, index }): string => (getTeamColor(isFiorentina, index))};;
`;

export const TableDataPosition = styled(TableData)`
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.47px;
  padding-left: 5px;

  @media ${Devices.tabletLarge} {
    text-align: left;
    padding-left: 15px;
  }
`;

export const TableDataName = styled(TableData)`
  text-align: left;
  padding-left: 5px;
  vertical-align: middle;
  @media ${Devices.tablet} {
    padding-left: 20px;
  }
`;

export const TeamNameContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
`;

export const Logo = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  @media ${Devices.tablet} {
    margin-right: 30px;
  }
`;

export const TableDataStats = styled(TableData)`
  width: 7%;
  &:nth-child(6), &:nth-child(7) {
    display: none;
  }
  @media ${Devices.tabletLarge} {
    &:nth-child(6), &:nth-child(7) {
      display: table-cell;
    }
  }
  @media ${Devices.tablet} {
    width: 8%;
  }
`;
