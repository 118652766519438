import { useContext, useEffect } from 'react';
import { MountContext } from '@app/ReactContext';
import { useLanguage } from '@app/components/Hooks';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AppState from '@app/types/appStateTypes';
import { getDataList } from '@app/store/actions/newsActions';
import { BaseNewsItem, EditorialContentTypes } from '@app/types/newsTypes';

export const useHomeDataListDownload = (type: EditorialContentTypes, category: string, limit = 5): void => {
  const { isInitialMount } = useContext(MountContext);
  const language = useLanguage();

  const hasItems = useSelector<AppState, boolean>(
    (state) => !!state.news.dataList?.[type]?.[category]?.items?.length,
    shallowEqual,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    // check if data has been loaded on server before very first render
    if (!isInitialMount || !hasItems) {
      dispatch(getDataList({
        language,
        type,
        category,
        limit,
      }));
    }
  }, [type, category, language]);
};

export const useHomeDataList = (
  type: EditorialContentTypes, category: string,
): BaseNewsItem[] => useSelector<AppState, BaseNewsItem[]>(
  (state) => state.news.dataList?.[type]?.[category]?.items ?? [],
  shallowEqual,
);
