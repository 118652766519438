import { DefaultTheme } from 'styled-components';

export enum Colors {
  white = 'white',
  purple = '#5f259f',
  shadow = '#84754e',
  whiteSmoke = '#f5f5f5',
  lightBlack = '#323232',
  red = '#e12319',
  black = '#000',
  gray = '#d0d0d0',
}

const theme: DefaultTheme = {
  color: Colors,
  fontFamily: {
    arialNova: 'ArialNova, Helvetica Neue, Helvetica, Arial, sans-serif',
    arialNovaCondensed: 'ArialNovaCondensed, Helvetica Neue, Helvetica, Arial, sans-serif',
    commutersSans: 'CommutersSans, Helvetica Neue, Helvetica, Arial, sans-serif',
    verlag: 'Verlag, Helvetica Neue, Helvetica, Arial, sans-serif',
    twCenMT: 'TwCenMT, Helvetica Neue, Helvetica, Arial, sans-serif',
    verlagBook: 'VerlagBook, Helvetica Neue, Helvetica, Arial, sans-serif',
  },
};

export default theme;
