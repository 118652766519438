
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class WebPageVideo extends ContentItem {
  public videoSize: Elements.MultipleChoiceElement;
  public thronVideoThron: Elements.CustomElement;
  constructor() {
    super({
      propertyResolver: ((elementName: string) => {
        if (elementName === 'video_size') {
          return 'videoSize';
        }
        if (elementName === 'thron_video__thron') {
          return 'thronVideoThron';
        }
        return elementName;
      })
    });
  }
}
