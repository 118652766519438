import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { AppState } from '@app/types/appStateTypes';
import { NavigationLinkParams } from '@app/types/routerTypes';
import { Competition, Competitions, BaseMenuItem, SubMenuId, SeoData } from '@app/types/configurationTypes';
import { MenCompetitionsMap } from '@app/services/opta/constants/competitionConstants';
import { isOptaSDCompetition } from '@app/services/opta/helpers/competitionHelpers';
import { formatSubMenuLinkProps } from '@app/helpers/routeHelpers';
import { setSeasonMultiLangUrl, resetSeason, resetSeasonError } from '@app/store/actions/seasonActions';
import { Errors, PageError } from '@app/types/errorTypes';
import { useLanguage, useMenu } from '@app/components/Hooks';
import { useSecondLevelMenu } from '@app/pages/CommonPage/CommonPageHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales';

const useSchedulePageLocalizedCheck = (): boolean => {
  const secondLevelMenu = useSecondLevelMenu();
  return secondLevelMenu?.data?.id === SubMenuId.SCHEDULE;
};

const useStandingsPageLocalizedCheck = (): boolean => {
  const secondLevelMenu = useSecondLevelMenu();
  return secondLevelMenu?.data?.id === SubMenuId.STANDINGS;
};

export const useCompetitionsSelector = (): Competitions | undefined => {
  const language = useLanguage();
  return useSelector<AppState, Competitions | undefined>(
    (state) => state.configuration[language]?.competitions,
    shallowEqual,
  );
};

export const useActiveSeasonIdForCompetitionSeriaA = (): string => {
  const competitions = useCompetitionsSelector();
  return competitions?.[MenCompetitionsMap.SeriaA]?.seasonIds
    .find(({ isActive }) => isActive)?.seasonId ?? '';
};

export const useActiveUrlSlugForCompetitionSeriaA = (): string => {
  const competitions = useCompetitionsSelector();
  return competitions?.[MenCompetitionsMap.SeriaA]?.url ?? '';
};

export const useStandingsMenuTitle = (): string => (
  // eslint-disable-next-line camelcase
  useMenu()?.web_season?.navigation?.[SubMenuId.STANDINGS].data?.name ?? ''
);

export const useActiveStandingLinkForCompetitionSeriaA = (): NavigationLinkParams | undefined => {
  // eslint-disable-next-line camelcase
  const seasonMenu = useMenu()?.web_season;
  const standingsMenu = seasonMenu?.navigation?.[SubMenuId.STANDINGS];
  const seasonData = {
    thirdLevel: useActiveUrlSlugForCompetitionSeriaA(),
    forthLevel: useActiveSeasonIdForCompetitionSeriaA(),
  };
  return seasonMenu && standingsMenu && formatSubMenuLinkProps(
    seasonMenu.data, standingsMenu, seasonData,
  );
};

export const useIsLoadingSelector = (): boolean => useSelector<AppState, boolean>(
  (state) => state.season.isLoading,
  shallowEqual,
);

export const useSeasonPageTitle = (): string => {
  const secondLevelMenu = useSecondLevelMenu();
  return secondLevelMenu?.data?.name ?? '';
};

export const useCompetitionSelector = (): Competition | undefined => {
  const { thirdLevel = '' } = useParams();
  const competitions = useCompetitionsSelector();
  return Object.values(competitions ?? {}).find(({ url }) => url === thirdLevel);
};

export const useCompetitionIdSelector = (): string => useCompetitionSelector()?.id ?? '';

export const useCompetitionsSelectOptions = (): Competition[] => {
  const competitions = useCompetitionsSelector();
  const competitionId = useCompetitionIdSelector();
  return Object.values(competitions ?? {})
    .filter(({ categoryId }) => categoryId === competitions?.[competitionId]?.categoryId);
};

export const useSelectedCategory = (): BaseMenuItem | undefined => {
  const competition = useCompetitionSelector();
  const secondLevelMenu = useSecondLevelMenu();

  return secondLevelMenu?.categories
    .find((category) => category?.id === decodeURIComponent(competition?.categoryId ?? ''));
};

export const useSeasonSeoSelector = (): SeoData => {
  const { forthLevel = '' } = useParams();
  const language = useLanguage();
  const categoryId = useSelectedCategory()?.id;
  const competition = useCompetitionSelector();
  const isSchedulePage = useSchedulePageLocalizedCheck();
  const pageKey = isSchedulePage ? 'schedule' : 'standings';

  const formatSeasonSeoLabel = (label = ''): string => label
    .replace('{competition}', competition?.name ?? '')
    .replace('{season}', isOptaSDCompetition(competition?.categoryId)
      ? competition?.seasonIds?.[0]?.seasonName ?? Translations[language]?.['season.active']
      : competition?.seasonIds?.find((season) => season.seasonId === forthLevel)?.seasonName ?? forthLevel);

  return {
    title: formatSeasonSeoLabel(Translations[language]?.[`${pageKey}.${categoryId}.meta.title`]),
    description: formatSeasonSeoLabel(Translations[language]?.[`${pageKey}.${categoryId}.meta.description`]),
    canonicalTag: '',
    meta: false,
    socialTitle: formatSeasonSeoLabel(Translations[language]?.[`${pageKey}.${categoryId}.meta.title`]),
    socialDescription: formatSeasonSeoLabel(Translations[language]?.[`${pageKey}.${categoryId}.meta.description`]),
    socialImageUrl: '',
  };
};

export const useSeasonPageErrorSelector = (): Errors => useSelector<AppState, Errors>(
  (state) => state.season.error,
  shallowEqual,
);

/** Hook is used only for seasonId route param validation */
export const useOptaSeasonIdCheck = (): boolean => {
  const { formatMessage } = useIntl();
  const { forthLevel = '' } = useParams();
  const competition = useCompetitionSelector();

  return isOptaSDCompetition(competition?.categoryId)
    ? forthLevel === formatMessage({ id: 'season.active' })
    : !!competition?.seasonIds.find((season) => season.seasonId === forthLevel);
};

/** Hook is used only in Standing/Schedule DownloadResults Hooks */
export const useOptaSeasonId = (): string => {
  const { forthLevel = '' } = useParams();
  const competitionId = useCompetitionIdSelector();
  const competition = useCompetitionSelector();
  const competitions = useCompetitionsSelector();

  return isOptaSDCompetition(competition?.categoryId)
    // Use SD seasonId from competition config
    ? competitions?.[competitionId]?.seasonIds?.find(({ isActive }) => isActive)?.seasonId ?? ''
    // Use seasonID directly from URL
    : forthLevel;
};

export const useSeasonIdActiveStateCheck = (): boolean => {
  const { forthLevel = '' } = useParams();
  const competition = useCompetitionSelector();

  return !!competition?.seasonIds
    .find((season) => season.seasonId === forthLevel && season.isActive);
};

export const useSeasonRoutesValidation = (): Errors => {
  const language = useLanguage();
  const dispatch = useDispatch();
  const { topLevel = '', secondLevel = '', thirdLevel = '', forthLevel = '' } = useParams();
  const seasonPageError = useSeasonPageErrorSelector();
  const competitions = useCompetitionsSelector();

  const isSchedulePage = useSchedulePageLocalizedCheck();
  const isStandingsPage = useStandingsPageLocalizedCheck();
  const isSeasonIdValid = useOptaSeasonIdCheck();

  /** Network Error - return 503 */
  if (seasonPageError) return seasonPageError;

  /** Stop validation if no competitions */
  if (!(Object.values(competitions ?? {}).length)) return null;

  /** Valid - set current Multi-lang url */
  if ((isSchedulePage || isStandingsPage) && isSeasonIdValid) {
    dispatch(setSeasonMultiLangUrl({
      topLevel,
      secondLevel,
      thirdLevel,
      forthLevel,
      fifthLevel: '',
      language,
    }));
    return null;
  }

  /** Not Valid - default - return 404 */
  return PageError.NotFound;
};

export const useSeasonResetOnUnmount = (): void => {
  const dispatch = useDispatch();

  useEffect(() => (): void => {
    dispatch(resetSeason());
    dispatch(resetSeasonError());
  }, []);
};

export const useSeasonPageErrorReset = (): void => {
  const dispatch = useDispatch();
  const { secondLevel = '', thirdLevel = '', forthLevel = '' } = useParams();

  useEffect(() => (): void => {
    dispatch(resetSeasonError());
  }, [secondLevel, thirdLevel, forthLevel]);
};
