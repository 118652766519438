import { Game } from '@app/types/matchTypes';
import { Game as KenticoGame } from '../types/game';
import { KenticoLanguages } from '../../../../server/src/types/Enums';

export const convertGames = (games: KenticoGame[]): Game[] => games?.map((game) => ({
  gameOptaId: game?.gameOptaId?.value ?? '',
  competition: game?.competition?.value?.[0]?.competitionName?.value ?? '',
  competitionId: game?.competition?.value?.[0]?.system?.codename ?? '',
  optaId: game?.competition?.value?.[0]?.competitionOptaId?.value ?? '',
  seasonId: game?.season?.value?.[0]?.name ?? '',
  seasonName: game?.competition?.value?.[0]?.seasonName?.value ?? '',
  urlSlug: game?.url?.value ?? '',
  streamLanguage: game?.streamLanguage?.value?.[0]?.codename as KenticoLanguages,
  audioStreamUrl: game?.audioStreamUrl?.value ?? '',
  additionalAudioStreamUrl: game?.additionalAudioStreamUrl?.value ?? '',
})) ?? [];
