import trimEnd from 'lodash/trimEnd';

import { Action } from '@app/types/actionTypes';
import { RoutePath } from '@app/types/routerTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { SetCurrentRoute, CurrentRouteActionTypes } from '@app/store/actionTypes/currentRouteActionTypes';
import { CurrentRouteState } from '@app/store/reducers/currentRouteReducer';

import { AppLanguages } from '@app/constants/localizationConstants';
import { localizeRouteKey } from '@app/helpers/localizationHelper';

export function setCurrentRouteActionCreator(route: CurrentRouteState): SetCurrentRoute {
  return {
    type: CurrentRouteActionTypes.SET_CURRENT_ROUTE,
    payload: route,
  };
}

export type CurrentRouteParams = Record<string, Record<LanguageType, string>>

interface SetCurrentRouteParams {
  pathId: RoutePath;
  params?: CurrentRouteParams;
}

export const setCurrentRoute = ({ pathId, params }: SetCurrentRouteParams): Action => (
  (dispatch): void => {
    // get current page params for provided language
    const langToRouteMap = AppLanguages.reduce((langToRouteMap, lang) => {
      const pathParams = params && Object
        .entries(params)
        .reduce((pathParams, [paramName, value]) => ({
          ...pathParams,
          [paramName]: value[lang],
        }), {});

      // format current page link for provided language
      const path = localizeRouteKey({
        pathKey: pathId,
        pathParams,
      }, lang);

      return {
        ...langToRouteMap,
        [lang]: trimEnd(path, '/'),
      };
    }, {} as CurrentRouteState);

    dispatch(setCurrentRouteActionCreator(langToRouteMap));
  }
);
