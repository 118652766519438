import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import AppState from '@app/types/appStateTypes';
import { Devices } from '@app/styles';
import { Loader, PageTitle } from '@app/components';
import { ExpandArrow } from '@app/components/Icons';
import { useShopsDownload, useShopsCategoryDownload, useInviolaSync } from '@app/components/Inviola/InviolaHooks';
import { PageWrapper } from '@app/pages/components';
import { Column } from '@app/components/Inviola/InviolaComponents';
import { ContainerMedium } from '@app/components/WebTemplates/components';
import { useCommonPageBaseMultiLangRoute } from '@app/pages/CommonPage/CommonPageHooks';

import { ShopsList, ShopsMap } from './components';

const InviolaShopsPage = (): React.ReactElement => {
  const { formatMessage } = useIntl();

  const [activeShopId, setActiveShopId] = useState<string>();
  const [activeOptionId, setActiveOptionId] = useState<number>(-1);

  const categories = useSelector((state: AppState) => state.inviola.shops.categories);
  const syncInProgress = useSelector((state: AppState) => state.inviola.shops.syncInProgress);

  const onCategoryChange = (e: { target: { value: string; }; }): void => setActiveOptionId(+e.target.value);

  const isNewChar = (description: string, index: number): boolean => {
    const char = description.substring(0, 1).toUpperCase();
    const prevChar = categories[index > 0 ? index - 1 : index]?.description.substring(0, 1).toUpperCase();
    return index === 0 || char !== prevChar;
  };

  useCommonPageBaseMultiLangRoute();
  useShopsDownload(activeOptionId);
  useShopsCategoryDownload();
  useInviolaSync();

  return (
    <PageWrapper error={null} isLoading={false}>
      <Heading>
        <HeadingColumn>
          <PageTitle>{formatMessage({ id: 'inviola.stores.title' })}</PageTitle>
        </HeadingColumn>
        <HeadingColumn>
          <SelectWrapper>
            <Select value={activeOptionId} onChange={onCategoryChange} disabled={syncInProgress}>
              <option disabled>
                &nbsp;&nbsp;&nbsp;
                {formatMessage({ id: 'inviola.stores.filter.geolocation' })}
              </option>
              <option value={-1}>
                {formatMessage({ id: 'inviola.stores.filter.closeToMe' })}
              </option>
              <option disabled>
                &nbsp;&nbsp;&nbsp;
                {formatMessage({ id: 'inviola.stores.filter.category' })}
              </option>
              {categories?.map(({ id, description }, index) => (
                <>
                  {isNewChar(description, index) && (
                    <option key={`${id}-disabled`} disabled>
                      &nbsp;&nbsp;&nbsp;
                      {description.substring(0, 1).toUpperCase()}
                      ...
                    </option>
                  )}
                  <option key={id} value={id} title={description}>
                    {description.substring(0, 200)}
                  </option>
                </>
              ))}
            </Select>
            <ExpandArrow />
          </SelectWrapper>
        </HeadingColumn>
      </Heading>
      <Section>
        <Column>
          <ShopsMap
            activeShopId={activeShopId}
            setActiveShop={(id) => setActiveShopId(id)}
          />
        </Column>
        <ShopsColumn syncInProgress={syncInProgress}>
          {syncInProgress && (<Loader overflowMode />)}
          <ShopsList
            activeShopId={activeShopId}
            setActiveShop={(id) => setActiveShopId(id)}
            removeActiveShop={() => setActiveShopId('')}
            syncInProgress={syncInProgress}
          />
        </ShopsColumn>
      </Section>
    </PageWrapper>
  );
};

export default InviolaShopsPage;

const Section = styled(ContainerMedium)`
  display: flex;
  flex-direction: column;
  padding: 0 0 20px;

  @media ${Devices.tabletLarge} {
    max-width: 950px;
    flex-direction: row;
    padding-bottom: 20px;
  }
`;

const Heading = styled(Section)`
  flex-direction: column;

  @media ${Devices.tablet} {
    max-width: 950px;
    flex-direction: row;
    padding-bottom: 20px;
  }
`;

const HeadingColumn = styled(Column)`
  justify-content: flex-end;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  margin-top: 15px;

  & > svg {
    position: absolute;
    z-index: 9;
    right: 10px;
    bottom: 10px;
  }
`;

const Select = styled.select`
  flex: 1 1 100%;
  position: relative;
  z-index: 10;
  max-width: calc(100vw - 30px);
  height: 30px;
  padding: 0 25px 0 15px;
  border-radius: 15px;
  border: solid 1px #dfdbdb;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  letter-spacing: 0.23px;
  font-size: 14px;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  text-transform: uppercase;
  overflow: hidden;

  @media ${Devices.largeMobile} {
    max-width: 445px;
  }

  & option {
    white-space: normal;
  }
`;

const ShopsColumn = styled(Column)<{ syncInProgress: boolean }>`
  position: relative;
  opacity: ${({ syncInProgress }): number => (syncInProgress ? 0.5 : 1)};
  transition: all 0.6s;
  z-index: 10;
`;
