import styled from 'styled-components';

import { Button } from '@app/components/Inviola/InviolaComponents';

export const Section = styled.section`
  margin: 0 0 30px;
`;

export const Item = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  background-color: white;

  padding: 45px 30px;

  ${Button} {
    font-size: 0.857rem;
    margin-bottom: 0;
    padding: 10px 15px;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  width: 100%;
`;

export const Name = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  font-size: 1rem;

  margin: 10px 0 15px;

  text-transform: uppercase;
  text-align: center;
`;

export const Barcode = styled.div`
  max-width: 140px;
  height: 80px;
  width: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Title = styled.h3`
  margin: 0 0 20px;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.12px;
  color: #000000;
  text-transform: uppercase;
`;

export const Description = styled.p`
  margin: 0;
  padding: 30px 10px 50px;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.12px;
  color: #000000;
  text-transform: uppercase;
`;
