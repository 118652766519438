import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { InviolaCustomerDynamicFields } from '@app/services/inviola/apiConstants';
import { InviolaAppError, InviolaAppSuccess } from '@app/services/inviola/types/errorTypes';
import { InviolaCustomer } from '@app/types/inviolaTypes';
import { InviolaDates } from '@app/constants/inviolaConstants';

import { modifyCustomerProfile, getCustomerProfile, resetErrors, setCustomerSaved } from '@app/store/actions/inviolaActions';

import { Button, CenteredBlock, Column, Container, Row } from '@app/components/Inviola/InviolaComponents';
import { Checkbox, Input, Select } from '@app/components/Inviola/InviolaFields';
import InviolaWrapper from '@app/components/Inviola/InviolaWrapper';
import InviolaGeoLevelSelector from '@app/components/Inviola/InviolaGeoLevelSelector';
import InviolaDynamicField from '@app/components/Inviola/InviolaDynamicField';
import { InviolaErrorMessage, InviolaSuccessMessage } from '@app/components/Inviola/InviolaMessages';
import { Loader } from '@app/components';

import {
  useInputValidations,
  useInviolaCustomer,
  useInviolaSync,
  useInviolaPath,
  useCountries,
  useGenders,
  useSyncInProgressSelector,
  useIsCustomerSavedSelector,
} from '@app/components/Inviola/InviolaHooks';

import { mapSelectOptions } from '@app/helpers/inviolaHelpers';

import { Devices } from '@app/styles';
import { ChangePasswordModal, SaveChangesModal, DeleteAccountButton } from './components';

const InviolaSettingsPage = (): React.ReactElement => {
  useInviolaSync();

  const { profilePath } = useInviolaPath();

  const customer = useInviolaCustomer();
  const isCustomerSaved = useIsCustomerSavedSelector();
  const methods = useForm<InviolaCustomer>({ mode: 'onChange', defaultValues: customer });
  const { handleSubmit, trigger, formState: { isValid, isDirty, isSubmitted }, watch } = methods;

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const validations = useInputValidations();
  const syncInProgress = useSyncInProgressSelector();

  const countries = useCountries();
  const availableGenders = useGenders();

  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(isDirty);
  const [isFormSubmitted, setIsFormSubmitted] = useState(isSubmitted);

  const selectedCountry = watch('country');

  const submit = (data: InviolaCustomer) => {
    dispatch(resetErrors());
    dispatch(modifyCustomerProfile({
      ...data,
      // LOCKED:FIELDS - always re-store values from customer profile
      email: customer?.email ?? '',
      firstName: customer?.firstName ?? '',
      lastName: customer?.lastName ?? '',
    }));
    setIsFormSubmitted(true);
    setIsFormDirty(false);
  };

  /** NOTE: use trigger() to validate user data and show errors before form edit */
  useEffect((): void => { // eslint-disable-next-line no-void
    void trigger();
    dispatch(getCustomerProfile());
  }, []);

  useEffect((): void => {
    if (isDirty !== isFormDirty) {
      setIsFormDirty(isDirty);
      setIsFormSubmitted(false);
    }
  }, [isDirty]);

  useEffect((): void => {
    if (isFormDirty && !isFormSubmitted) {
      dispatch(setCustomerSaved(false));
    } else {
      dispatch(setCustomerSaved(true));
    }
  }, [isFormDirty, isFormSubmitted]);

  return (
    <InviolaWrapper withoutContainer className="position-relative">
      {syncInProgress && (<Loader overflowMode />)}
      <StyledContainer syncInProgress={syncInProgress}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submit)}>
            <Container>
              <Row>
                <Column>
                  <Input
                    name="firstName"
                    labelId="inviola.registration.name"
                    validations={validations.required}
                    disabled
                  />
                </Column>

                <Column>
                  <Input
                    name="lastName"
                    labelId="inviola.registration.surname"
                    validations={validations.required}
                    disabled
                  />
                </Column>
              </Row>

              <Row>
                <Column>
                  <Input
                    name="email"
                    labelId="inviola.registration.email"
                    validations={validations.email}
                    disabled
                  />
                </Column>

                <Column width="25%">
                  <Input
                    name="birthDate"
                    type="date"
                    labelId="inviola.registration.birthdate"
                    validations={validations.date}
                    min={InviolaDates.min}
                    max={InviolaDates.max}
                  />
                </Column>

                <Column width="25%">
                  <Select name="gender" labelId="inviola.registration.gender" options={availableGenders} />
                </Column>
              </Row>

              <Row>
                <Column>
                  <InviolaDynamicField id={InviolaCustomerDynamicFields.BirthPlace} />
                </Column>

                <Column>
                  <InviolaDynamicField id={InviolaCustomerDynamicFields.Nationality} />
                </Column>

                {countries && (
                  <Column>
                    <Select
                      name="country"
                      labelId="inviola.registration.country"
                      options={mapSelectOptions(countries)}
                      emptyOption
                    />
                  </Column>
                )}
              </Row>

              <InviolaGeoLevelSelector selectedCountry={selectedCountry} />

              <Row>
                <Column>
                  <Input name="address" labelId="inviola.registration.address" />
                </Column>

                <Column width="25%">
                  <Input name="addressNumber" labelId="inviola.registration.addressNumber" />
                </Column>

                <Column width="25%">
                  <Input name="zip" labelId="inviola.registration.zip" />
                </Column>
              </Row>

              <Row>
                <Column>
                  <Input
                    name="identityCard"
                    labelId="inviola.registration.identityCard"
                    validations={validations.fiscal}
                  />
                </Column>
                <Column>
                  <InviolaDynamicField id={InviolaCustomerDynamicFields.Job} />
                </Column>
                <Column>
                  <Input name="mobile" labelId="inviola.registration.mobile" />
                </Column>
              </Row>

              <Row>
                <Column>
                  <InviolaDynamicField id={InviolaCustomerDynamicFields.AcceptanceOfRegulations} />
                  <Checkbox name="usedForPromotions" type="checkbox" labelId="inviola.registration.usedForPromotions" />
                  <Checkbox name="usedForStatistics" type="checkbox" labelId="inviola.registration.usedForStatistics" />
                </Column>
              </Row>
            </Container>

            <ButtonsContainer>
              <InviolaErrorMessage errorType={InviolaAppError.SettingsFormError} />
              <InviolaSuccessMessage
                type={InviolaAppSuccess.UserModified}
                translationId="inviola.settings.usermodified"
              />

              <Button disabled={!isFormDirty || !isValid} type="submit">
                {formatMessage({ id: 'inviola.settings.submit' })}
              </Button>

              <Button type="button" onClick={() => setPasswordModalOpen(true)}>
                {formatMessage({ id: 'inviola.settings.changePassword.button' })}
              </Button>

              <DeleteAccountButton />
            </ButtonsContainer>

            <CenteredBlock>
              <Link to={profilePath}>{formatMessage({ id: 'inviola.settings.backLink' })}</Link>
            </CenteredBlock>
          </form>
        </FormProvider>

        <ChangePasswordModal open={passwordModalOpen} onClose={() => setPasswordModalOpen(false)} />
        <SaveChangesModal open={!isCustomerSaved} />
      </StyledContainer>
    </InviolaWrapper>
  );
};

const StyledContainer = styled(Container)<{ syncInProgress: boolean }>`
  position: relative;
  opacity: ${({ syncInProgress }): number => (syncInProgress ? 0.5 : 1)};
  transition: all 0.6s;
  z-index: 10;
`;

const ButtonsContainer = styled.div`
  max-width: 90%;
  margin: auto;

  @media ${Devices.largeMobile} {
    max-width: 420px;
  }
`;

export default InviolaSettingsPage;
