import { sendRequestTyped } from '@app/services/opta/request';
import { FeedTypes } from '@app/services/opta/constants/feedTypes';
import { OptaSDFiorentinaTeamIdMap } from '@app/services/opta/constants/teamConstants';
import { LanguageType } from '@app/types/localizationTypes';
import { MatchResult } from '@app/types/matchTypes';
import { getTeamNamesData } from '@app/services/kentico/teamsService';
import { mapMatchResults, mapSDMatchResults } from '@app/helpers/matchHelpers';
import {
  OptaScheduleLiveResponse, OptaScheduleStaticResponse, OptaSDScheduleStaticResponse,
} from '@app/services/opta/types/responseTypes';
import { OptaSDCompetitionsMap } from '@app/services/opta/constants/competitionConstants';

interface RequestParamsProps {
  gameId?: string;
  optaId: string;
  seasonId: string;
  language: LanguageType
}

/** MEN Match Results use Classic OPTA API and are built in 4 steps:
 * 1. get opta F26 feed - live competition matches data. THIS IS 1-st PRIORITY DATA
 * 2. get opta F1 feed - season competition matches data. THIS IS 2-nd PRIORITY DATA
 * 3. get kentico Team Names data and apply on feed's Teams data
 * */
export const getMatchResultsData = (requestParams: RequestParamsProps): Promise<MatchResult[]> => {
  const { seasonId, optaId, language } = requestParams;

  const liveData = sendRequestTyped<OptaScheduleLiveResponse>({
    path: 'competition.php',
    params: {
      feed_type: FeedTypes.live,
      json: true,
      season_id: seasonId,
      competition: optaId,
    },
  });

  const staticData = sendRequestTyped<OptaScheduleStaticResponse>({
    path: 'competition.php',
    params: {
      feed_type: FeedTypes.schedule,
      json: true,
      season_id: seasonId,
      competition: optaId,
    },
  });

  const teamNamesData = getTeamNamesData(language);

  return Promise
    .all([liveData, staticData, teamNamesData])
    .then((data) => mapMatchResults(data, { seasonId, optaId }));
};

interface SDRequestParamsProps extends RequestParamsProps {
  id: string;
}

export const getSDMatchResultsData = (
  { id, seasonId, optaId, language }: SDRequestParamsProps,
): Promise<MatchResult[]> => {
  const teamNamesData = getTeamNamesData(language);
  const resultsData = sendRequestTyped<OptaSDScheduleStaticResponse>({
    path: 'soccerdata/match',
    isOptaSD: true,
    params: {
      _rt: 'b',
      _fmt: 'json',
      _pgSz: '1000',
      live: 'yes',
      status: 'all',
      tmcl: seasonId,
      comp: optaId,
      ctst: OptaSDFiorentinaTeamIdMap[id as OptaSDCompetitionsMap] ?? '',
    },
  });

  return Promise
    .all([resultsData, teamNamesData])
    .then((data) => mapSDMatchResults(data, { seasonId, optaId }));
};
