import React from 'react';

import { SubMenuId } from '@app/types/configurationTypes';
import { PageType } from '@app/types/commonPageTypes';

import NewsSearch from '@app/pages/Search/Search';
import NewsListPage from '@app/pages/NewsListPage/NewsListPage';
import Teams from '@app/pages/Teams/Teams';
import PlayerProfile from '@app/pages/PlayerProfile/PlayerProfile';
import WebTemplate from '@app/pages/WebTemplate/WebTemplate';
import PhotoGallery from '@app/pages/PhotoGallery/PhotoGallery';
import NewsDetails from '@app/pages/NewsDetails/NewsDetails';
import VideoDetails from '@app/pages/VideoDetails/VideoDetails';
import MatchCenter from '@app/pages/MatchCenter/MatchCenter';
import PrivacyPage from '@app/pages/PrivacyPage/PrivacyPage';
import { Season } from '@app/pages/Season';
import {
  InviolaAuthPage,
  InviolaCatalogPage,
  InviolaProfilePage,
  InviolaResetPage,
  InviolaSettingsPage,
  InviolaAwardsPage,
  InviolaShopsPage,
  InviolaPromotionsListPage,
  InviolaPromotionsDetails,
} from '@app/pages/Inviola';

export const SubMenuIdToPageType: Record<SubMenuId, PageType> = {
  [SubMenuId.NEWS]: PageType.News,
  [SubMenuId.VIDEOS]: PageType.Videos,
  [SubMenuId.PHOTOGALLERY]: PageType.PhotoGalleries,
  [SubMenuId.NEWS_SEARCH]: PageType.NewsSearch,
  [SubMenuId.FIRST_TEAM]: PageType.Team,
  [SubMenuId.WOMEN_TEAM]: PageType.Team,
  [SubMenuId.PRIMAVERA_TEAM]: PageType.Team,
  [SubMenuId.YOUTH_TEAM]: PageType.Template,
  [SubMenuId.SCHEDULE]: PageType.Season,
  [SubMenuId.STANDINGS]: PageType.Season,
  [SubMenuId.MATCHCENTER]: PageType.MatchCenter,
  [SubMenuId.BUY_TICKETS]: PageType.Tickets,
  [SubMenuId.PRIVACY]: PageType.Privacy,
  [SubMenuId.AUTH]: PageType.InviolaAuth,
  [SubMenuId.RESET_PASSWORD]: PageType.InviolaReset,
  [SubMenuId.PROFILE]: PageType.InviolaProfile,
  [SubMenuId.SETTINGS]: PageType.InviolaSettings,
  [SubMenuId.CATALOG_ITEMS]: PageType.InviolaCalatog,
  [SubMenuId.AWARDS]: PageType.InviolaAwards,
  [SubMenuId.SHOPS]: PageType.InviolaShops,
  [SubMenuId.PROMOTIONS]: PageType.InviolaPromotions,
};

export const SubMenuIdToDetailsPageType: Partial<Record<SubMenuId, PageType>> = {
  [SubMenuId.NEWS]: PageType.NewsDetails,
  [SubMenuId.VIDEOS]: PageType.VideosDetails,
  [SubMenuId.PHOTOGALLERY]: PageType.PhotoGalleriesDetails,
  [SubMenuId.FIRST_TEAM]: PageType.PlayerProfile,
  [SubMenuId.WOMEN_TEAM]: PageType.PlayerProfile,
  [SubMenuId.PRIMAVERA_TEAM]: PageType.PlayerProfile,
  [SubMenuId.PRIVACY]: PageType.Privacy,
  [SubMenuId.YOUTH_TEAM]: PageType.Template,
  [SubMenuId.SCHEDULE]: PageType.Season,
  [SubMenuId.STANDINGS]: PageType.Season,
  [SubMenuId.MATCHCENTER]: PageType.MatchCenter,
  [SubMenuId.PROMOTIONS]: PageType.InviolaPromotionsDetails,
};

export const PageTypeToComponent: Partial<Record<PageType, React.FunctionComponent<unknown> | string>> = {
  [PageType.News]: NewsListPage,
  [PageType.Videos]: NewsListPage,
  [PageType.PhotoGalleries]: NewsListPage,
  [PageType.PhotoGalleriesDetails]: PhotoGallery,
  [PageType.NewsDetails]: NewsDetails,
  [PageType.VideosDetails]: VideoDetails,
  [PageType.NewsSearch]: NewsSearch,
  [PageType.Team]: Teams,
  [PageType.PlayerProfile]: PlayerProfile,
  [PageType.Template]: WebTemplate,
  [PageType.Privacy]: PrivacyPage,
  [PageType.Season]: Season,
  [PageType.MatchCenter]: MatchCenter,
  [PageType.InviolaAuth]: InviolaAuthPage,
  [PageType.InviolaReset]: InviolaResetPage,
  [PageType.InviolaProfile]: InviolaProfilePage,
  [PageType.InviolaSettings]: InviolaSettingsPage,
  [PageType.InviolaCalatog]: InviolaCatalogPage,
  [PageType.InviolaAwards]: InviolaAwardsPage,
  [PageType.InviolaShops]: InviolaShopsPage,
  [PageType.InviolaPromotions]: InviolaPromotionsListPage,
  [PageType.InviolaPromotionsDetails]: InviolaPromotionsDetails,
};
