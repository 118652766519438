export enum HttpClientErrorCodeEnum {
    BadRequest = 400, // The server cannot or will not process the request due to an apparent client error (e.g., malformed request syntax, size too large, invalid request message framing, or deceptive request routing).
	Unauthorized = 401, // (RFC 7235) Similar to 403 Forbidden, but specifically for use when authentication is required and has failed or has not yet been provided. The response must include a WWW-Authenticate header field containing a challenge applicable to the requested resource. See Basic access authentication and Digest access authentication. 401 semantically means "unauthorised", the user does not have valid authentication credentials for the target resource. Note: Some sites incorrectly issue HTTP 401 when an IP address is banned from the website (usually the website domain) and that specific address is refused permission to access a website.
	PaymentRequired = 402, // Reserved for future use. The original intention was that this code might be used as part of some form of digital cash or micropayment scheme, as proposed, for example, by GNU Taler, but that has not yet happened, and this code is not widely used. Google Developers API uses this status if a particular developer has exceeded the daily limit on requests. Sipgate uses this code if an account does not have sufficient funds to start a call. Shopify uses this code when the store has not paid their fees and is temporarily disabled. Stripe uses this code for failed payments where parameters were correct, for example blocked fraudulent payments.
	Forbidden = 403, // The request contained valid data and was understood by the server, but the server is refusing action. This may be due to the user not having the necessary permissions for a resource or needing an account of some sort, or attempting a prohibited action (e.g. creating a duplicate record where only one is allowed). This code is also typically used if the request provided authentication via the WWW-Authenticate header field, but the server did not accept that authentication. The request should not be repeated.
	NotFound = 404, // The requested resource could not be found but may be available in the future. Subsequent requests by the client are permissible.
	MethodNotAllowed = 405, // A request method is not supported for the requested resource; for example, a GET request on a form that requires data to be presented via POST, or a PUT request on a read-only resource.
	NotAcceptable = 406, // The requested resource is capable of generating only content not acceptable according to the Accept headers sent in the request. See Content negotiation.
	ProxyAuthenticationRequired = 407, // (RFC 7235) The client must first authenticate itself with the proxy.
	RequestTimeout = 408, // The server timed out waiting for the request. According to HTTP specifications: "The client did not produce a request within the time that the server was prepared to wait. The client MAY repeat the request without modifications at any later time."
	Conflict = 409, // Indicates that the request could not be processed because of conflict in the current state of the resource, such as an edit conflict between multiple simultaneous updates.
	Gone = 410, // Indicates that the resource requested is no longer available and will not be available again. This should be used when a resource has been intentionally removed and the resource should be purged. Upon receiving a 410 status code, the client should not request the resource in the future. Clients such as search engines should remove the resource from their indices. Most use cases do not require clients and search engines to purge the resource, and a "404 Not Found" may be used instead.
	LengthRequired = 411, // The request did not specify the length of its content, which is required by the requested resource.
	PreconditionFailed = 412, // (RFC 7232) The server does not meet one of the preconditions that the requester put on the request header fields.
	PayloadTooLarge = 413, // (RFC 7231) The request is larger than the server is willing or able to process. Previously called "Request Entity Too Large".
	URITooLong = 414, // (RFC 7231) The URI provided was too long for the server to process. Often the result of too much data being encoded as a query-string of a GET request, in which case it should be converted to a POST request. Called "Request-URI Too Long" previously.
	UnsupportedMediaType = 415, // (RFC 7231) The request entity has a media type which the server or resource does not support. For example, the client uploads an image as image/svg+xml, but the server requires that images use a different format.
	RangeNotSatisfiable = 416, // (RFC 7233) The client has asked for a portion of the file (byte serving), but the server cannot supply that portion. For example, if the client asked for a part of the file that lies beyond the end of the file. Called "Requested Range Not Satisfiable" previously.
	ExpectationFailed = 417, // The server cannot meet the requirements of the Expect request-header field.
	ImATeapot = 418, // (RFC 2324, RFC 7168) This code was defined in 1998 as one of the traditional IETF April Fools' jokes, in RFC 2324, Hyper Text Coffee Pot Control Protocol, and is not expected to be implemented by actual HTTP servers. The RFC specifies this code should be returned by teapots requested to brew coffee. This HTTP status is used as an Easter egg in some websites, including Google.com.
	MisdirectedRequest = 421, // (RFC 7540) The request was directed at a server that is not able to produce a response (for example because of connection reuse).
	UnprocessableEntity = 422, // (WebDAV; RFC 4918) The request was well-formed but was unable to be followed due to semantic errors.
	Locked = 423, // (WebDAV; RFC 4918) The resource that is being accessed is locked.
	FailedDependency = 424, // (WebDAV; RFC 4918) The request failed because it depended on another request and that request failed (e.g., a PROPPATCH).
	TooEarly = 425, // (RFC 8470) Indicates that the server is unwilling to risk processing a request that might be replayed.
	UpgradeRequired = 426, // The client should switch to a different protocol such as TLS/1.0, given in the Upgrade header field.
	PreconditionRequired = 428, // (RFC 6585) The origin server requires the request to be conditional. Intended to prevent the 'lost update' problem, where a client GETs a resource's state, modifies it, and PUTs it back to the server, when meanwhile a third party has modified the state on the server, leading to a conflict.
	TooManyRequests = 429, // (RFC 6585) The user has sent too many requests in a given amount of time. Intended for use with rate-limiting schemes.
	RequestHeaderFieldsTooLarge = 431, // (RFC 6585) The server is unwilling to process the request because either an individual header field, or all the header fields collectively, are too large.
	UnavailableForLegalReasons = 451, // (RFC 7725) A server operator has received a legal demand to deny access to a resource or to a set of resources that includes the requested resource. The code 451 was chosen as a reference to the novel Fahrenheit 451 (see the Acknowledgements in the RFC).
}