import React from 'react';
import styled from 'styled-components';
import { WebTemplatePageImage, WebPageImageSizeType } from '@app/types/webTemplateTypes';
import { ThronImage } from '@app/components/Image';

import { ContainerLarge, ContainerMedium } from '../components';

interface WebPageImageElementProps {
  data: WebTemplatePageImage;
}

const WebPageImageElement: React.FC<WebPageImageElementProps> = ({ data }: WebPageImageElementProps) => {
  let Container;

  switch (data?.size) {
    case WebPageImageSizeType.Big:
      Container = ContainerLarge;
      break;
    case WebPageImageSizeType.Medium:
      Container = ContainerMedium;
      break;
    default:
      Container = ContainerMedium;
  }

  return (
    data?.image ? (
      <Container>
        <ContainerWithPadding>
          <ThronImage useLazyLoading thronId={data?.image?.thronId} alt={data.image.name} />
        </ContainerWithPadding>
      </Container>
    ) : null
  );
};

const ContainerWithPadding = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 10px;

  & > img {
    width: 100%;
  }
`;

export default WebPageImageElement;
