import React from 'react';
import { useIntl } from 'react-intl';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { InviolaAppError, InviolaAppSuccess } from '@app/services/inviola/types/errorTypes';
import { ConfirmRegistrationCredentials } from '@app/services/inviola/types/requestTypes';

import { registerConfirmedCustomer, resendVerificationCode, resetErrors } from '@app/store/actions/inviolaActions';

import { Button, Description, TextError, MessageContainerTop } from '@app/components/Inviola/InviolaComponents';
import { Input } from '@app/components/Inviola/InviolaFields';
import { InviolaErrorMessage, InviolaSuccessMessage } from '@app/components/Inviola/InviolaMessages';
import { useInputValidations, useSyncInProgressSelector } from '@app/components/Inviola/InviolaHooks';

const RegistrationConfirmation = (): React.ReactElement => {
  const methods = useForm<ConfirmRegistrationCredentials>({ mode: 'onChange' });

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const syncInProgress = useSyncInProgressSelector();
  const validations = useInputValidations();

  const { handleSubmit, watch, errors, formState: { isValid } } = methods;

  const submit = (data: ConfirmRegistrationCredentials) => {
    dispatch(resetErrors());
    dispatch(registerConfirmedCustomer(data));
  };

  const resentCode = () => {
    dispatch(resetErrors());
    dispatch(resendVerificationCode());
  };

  return (
    <FormProvider {...methods}>
      <MessageContainerTop>
        <InviolaSuccessMessage
          type={InviolaAppSuccess.CustomerCardIsAccepted}
          translationId="inviola.registrationNotification.cardWithEmailAccepted"
        />
      </MessageContainerTop>

      <Description>{formatMessage({ id: 'inviola.registrationConfirmation.description' })}</Description>

      <form onSubmit={handleSubmit(submit)}>
        <Input
          type="text"
          labelId="inviola.resetConfirmation.verificationcode"
          placeholderId="inviola.form.verificationCode.placeholder"
          name="verificationcode"
          validations={validations.required}
        />

        <Input
          type="password"
          labelId="inviola.registrationConfirmation.password"
          placeholderId="inviola.form.password.placeholder"
          name="passwordnew"
          validations={validations.required}
        />

        <Input
          type="password"
          name="confirmpassword"
          labelId="inviola.resetConfirmation.confirmpassword"
          placeholderId="inviola.form.passwordConfirm.placeholder"
          validations={{
            required: true,
            validate: (value: string) => (value === watch('passwordnew')),
          }}
        />
        { errors?.confirmpassword && <TextError>{formatMessage({ id: 'inviola.form.requiredAndEqual' })}</TextError> }

        <InviolaErrorMessage errorType={InviolaAppError.RegistrationFormError} />
        <InviolaSuccessMessage
          type={InviolaAppSuccess.VerificationCodeResent}
          translationId="inviola.registrationNotification.codeExpired"
        />
        <InviolaSuccessMessage
          type={InviolaAppSuccess.AuthorizationCodeResent}
          translationId="inviola.resetConfirmation.resetcoderesent"
        />
        <Button type="submit" disabled={!isValid || syncInProgress}>
          {formatMessage({ id: 'inviola.resetConfirmation.submit' })}
        </Button>

        <Button onClick={resentCode} disabled={syncInProgress}>
          {formatMessage({ id: 'inviola.resetConfirmation.resendCode' })}
        </Button>
      </form>
    </FormProvider>
  );
};

export default RegistrationConfirmation;
