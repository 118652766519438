import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { remove, isEqual } from 'lodash';

import {
  Player, PlayerStatistics, TeamStatistics, LocalizedPlayer,
} from '@app/types/teamsTypes';

import { getPlayerCareers, getPlayersStatistics } from '@app/store/actions/teamsActions';
import { useLanguage } from '@app/components/Hooks';

import { useIsLoading, useTeamSubMenuId } from '@app/pages/Teams/TeamsHooks';
import { MountContext } from '@app/ReactContext';
import { Errors, PageError } from '@app/types/errorTypes';
import AppState from '@app/types/appStateTypes';
import {
  getLocalizedPlayer, getPlayerTabs, getPlayerUrlParams,
} from '@app/helpers/teamsHelpers';
import { useCommonPageBaseMultiLangRoute } from '@app/pages/CommonPage/CommonPageHooks';
import { CommonPageBaseUrlParams } from '@app/helpers/routeHelpers';
import { LanguageType } from '@app/types/localizationTypes';
import { RouteParams } from '@app/types/routerTypes';
import { CareerItem } from '@app/services/careers/types/responseTypes';
import { getPlayerCareer } from '@app/helpers/playerHelpers';

export function usePlayersStatisticsDownload(): void {
  const { isInitialMount } = useContext(MountContext);
  const { locale } = useIntl();
  const language = useLanguage();
  const { topLevel = '', secondLevel = '' } = useParams();
  const teamSubMenuId = useTeamSubMenuId();

  const dispatch = useDispatch();

  const hasItems = useSelector<AppState, boolean>(
    (state) => !!state.teams.teamsStatistics[teamSubMenuId],
    shallowEqual,
  );

  useEffect(() => {
    if (secondLevel && (!isInitialMount || !hasItems)) {
      // check if data has been loaded on server before very first render
      dispatch(getPlayersStatistics({ topLevel, teamType: secondLevel, language }));
    }
  }, [secondLevel, locale]);
}

export function useCareerDownload(): void {
  const dispatch = useDispatch();

  const hasItems = useSelector<AppState, boolean>(
    (state) => !!state.teams.playerCareers.length,
    shallowEqual,
  );

  useEffect(() => {
    if (!hasItems) {
      dispatch(getPlayerCareers());
    }
  });
}

export function useTeamStatistics(): TeamStatistics | undefined {
  const teamSubMenuId = useTeamSubMenuId();
  return useSelector<AppState, TeamStatistics | undefined>(
    (state) => state.teams.teamsStatistics[teamSubMenuId],
    shallowEqual,
  );
}

function useLocalizedPlayer(): LocalizedPlayer {
  const { forthLevel = '' } = useParams();
  const language = useLanguage();
  const teamSubMenuId = useTeamSubMenuId();

  return useSelector<AppState, LocalizedPlayer>(
    (state) => getLocalizedPlayer(state, teamSubMenuId, language, forthLevel),
    shallowEqual,
  );
}

export function usePlayer(): Player | undefined {
  const language = useLanguage();
  return useLocalizedPlayer()[language];
}

export function usePlayerStatistics(): PlayerStatistics | undefined | PageError {
  const player = usePlayer();
  const playerId = player?.id ?? '';
  const teamStatistics = useTeamStatistics();
  const statistics = teamStatistics?.players[playerId.startsWith('p') ? playerId.substr(1) : playerId];
  if (teamStatistics?.players && !statistics) return PageError.NotFound;
  return statistics;
}

export interface Tab {
  name: string;
  component: () => React.ReactElement;
  caption: string;
}

export function useTabs(defaultLocale?: LanguageType): Tab[] {
  // const subItemId = useTeamSubMenuId();
  const locale = useLanguage();
  /*  const competitions = useSelector<AppState, Competitions>(
    (state) => state.configuration[locale]?.competitions ?? {},
    shallowEqual,
  );
  const competition = mapTeamTypeToCompetition(subItemId, competitions);
  const tabs = getPlayerTabs(locale); */

  /*  if (!competition) {
    remove(tabs, (tab) => tab.name === 'player.tabs.statistics');
  } */

  const career: CareerItem | undefined = usePlayerCareer();
  const tabs = getPlayerTabs(defaultLocale ?? locale);

  if (!career) {
    remove(tabs, (tab) => tab.name === 'player.tabs.career');
  }

  return tabs;
}

export function useActiveTab(defaultLocale?: LanguageType): Tab | undefined {
  const locale = useLanguage();
  const localeTabs = useTabs(locale);
  const tabs = useTabs(defaultLocale);
  const { fifthLevel = '' } = useParams();
  const activeTab = localeTabs.find((item) => item.caption === fifthLevel);

  return tabs.find((item) => item.name === activeTab?.name);
}

export function useRouterValidation(): Errors {
  const { formatMessage } = useIntl();
  const { thirdLevel = '', forthLevel = '', fifthLevel = '' } = useParams();
  const player = usePlayer();
  const activeTab = useActiveTab() as Tab;
  const isLoading = useIsLoading();

  const thirdLevelMenu = thirdLevel === formatMessage({ id: 'players' });
  const forthLevelMenu = forthLevel === player?.urlSlug;
  const fifthLevelMenu = activeTab && fifthLevel.toLowerCase() === formatMessage({ id: activeTab.name }).toLowerCase();

  return isLoading || (thirdLevelMenu && forthLevelMenu && fifthLevelMenu) ? null : PageError.NotFound;
}

export function usePlayerProfileMultiLangRoute(): void {
  const params: RouteParams = useParams();
  const language = useLanguage();

  const playerRouteParams = useSelector<AppState, CommonPageBaseUrlParams>(
    (state) => getPlayerUrlParams(state, params, language),
    isEqual,
  );

  useCommonPageBaseMultiLangRoute(playerRouteParams);
}

export function usePlayerCareer(): CareerItem | undefined {
  const player = usePlayer();
  const playerId = player?.id || '';

  const playerCareer = useSelector<AppState, CareerItem | undefined>(
    (state) => getPlayerCareer(state, playerId),
  );

  return playerCareer;
}
