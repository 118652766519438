import { useLocation } from 'react-router-dom';

import { RouteParams } from '@app/types/routerTypes';
import { useLanguage } from '@app/components/Hooks';

export const useUrlParamsOutsideSwitch = (): RouteParams => {
  const location = useLocation();
  const language = useLanguage();
  const locationParts = location.pathname.split('/');
  const topMenuPosition = locationParts.findIndex((part) => part === language) + 1;

  return {
    topLevel: locationParts[topMenuPosition] ?? '',
    secondLevel: locationParts[topMenuPosition + 1] ?? '',
    thirdLevel: locationParts[topMenuPosition + 2] ?? '',
    language,
  };
};
