import React from 'react';
import { FormattedMessage } from 'react-intl';

import theme from '@app/styles/theme';
import { MatchStatistics } from '@app/types/matchTypes';
import { CircleGraph } from '@app/components/Graphs/CircleGraph';
import { LineGraph } from '@app/components/Graphs/LineGraph';
import {
  BlockContainerColumn, Caption, StatsBlock, StatsBlockColumn, Value,
} from '@app/components/Statistics';
import { useLineupsSelector } from '@app/pages/MatchCenter/MatchCenterHooks';
import styled from 'styled-components';

const MatchBall = (): React.ReactElement => {
  const lineups = useLineupsSelector();

  return (
    <BlockContainerColumn>
      <StatsBlock marginTop="20px" marginBottom="40px">
        <StatsBlock>
          <ValueLarge>{`${Math.floor(lineups?.home?.stats?.[MatchStatistics.STATS_POSSESSION_PERCENTAGE] ?? 0)}%`}</ValueLarge>
        </StatsBlock>
        <StatsBlock>
          <CircleGraph
            strokeHomeColor={theme.color.purple}
            strokeAwayColor={theme.color.red}
            width={150}
            height={150}
            value={
              (lineups && lineups?.away?.stats?.[MatchStatistics.STATS_POSSESSION_PERCENTAGE] !== 0
              && lineups?.home?.stats?.[MatchStatistics.STATS_POSSESSION_PERCENTAGE] !== 0)
                ? (100 - Math.floor(lineups?.home?.stats?.[MatchStatistics.STATS_POSSESSION_PERCENTAGE]) / 100) : 0
            }
          >
            <Caption isSmall><FormattedMessage id="statistics.possession" /></Caption>
          </CircleGraph>
        </StatsBlock>
        <StatsBlock>
          <ValueLarge>
            {`${
              (lineups && (lineups?.away?.stats?.[MatchStatistics.STATS_POSSESSION_PERCENTAGE] !== 0
                && lineups?.home?.stats?.[MatchStatistics.STATS_POSSESSION_PERCENTAGE] !== 0))
                ? (100 - Math.floor(lineups?.home?.stats?.[MatchStatistics.STATS_POSSESSION_PERCENTAGE])) : 0
            }%`}
          </ValueLarge>
        </StatsBlock>
      </StatsBlock>
      <StatsBlockColumn marginBottom="20px">
        <StatsBlock>
          <Caption isSmall><FormattedMessage id="statistics.duelsWon" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <StatsBlock>
            <ValueSmall>
              {`${
                (lineups && (lineups?.away?.stats?.[MatchStatistics.STATS_DUELS_WON] !== 0
                  || lineups?.home?.stats?.[MatchStatistics.STATS_DUELS_WON] !== 0))
                  ? (100 - Math.ceil((lineups?.away?.stats?.[MatchStatistics.STATS_DUELS_WON] * 100)
                    / (lineups?.home?.stats?.[MatchStatistics.STATS_DUELS_WON]
                    + lineups?.away?.stats?.[MatchStatistics.STATS_DUELS_WON]))) : 0
              }%`}
            </ValueSmall>
          </StatsBlock>
          <StatsBlock>
            <LineGraph
              strokeHomeColor={theme.color.purple}
              strokeAwayColor={theme.color.red}
              value={
                (lineups && (lineups?.away?.stats?.[MatchStatistics.STATS_DUELS_WON] !== 0
                  || lineups?.home?.stats?.[MatchStatistics.STATS_DUELS_WON] !== 0))
                  ? (100 - Math.ceil((lineups?.away?.stats?.[MatchStatistics.STATS_DUELS_WON] * 100)
                  / (lineups?.home?.stats?.[MatchStatistics.STATS_DUELS_WON]
                    + lineups?.away?.stats?.[MatchStatistics.STATS_DUELS_WON]))) : 0
              }
              width={150}
            />
          </StatsBlock>
          <StatsBlock>
            <ValueSmall>
              {`${
                (lineups && (lineups?.away?.stats?.[MatchStatistics.STATS_DUELS_WON] !== 0
                  || lineups?.home?.stats?.[MatchStatistics.STATS_DUELS_WON] !== 0))
                  ? (Math.ceil((lineups?.away?.stats?.[MatchStatistics.STATS_DUELS_WON] * 100)
                  / (lineups?.home?.stats?.[MatchStatistics.STATS_DUELS_WON]
                    + lineups?.away?.stats?.[MatchStatistics.STATS_DUELS_WON]))) : 0
              }%`}
            </ValueSmall>
          </StatsBlock>
        </StatsBlock>
      </StatsBlockColumn>
      <StatsBlockColumn marginBottom="20px">
        <StatsBlock>
          <Caption><FormattedMessage id="statistics.interceptions" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <StatsBlock>
            <ValueSmall>{`${lineups?.home?.stats?.[MatchStatistics.STATS_INTERCEPTION] ?? 0}`}</ValueSmall>
          </StatsBlock>
          <StatsBlock>
            <LineGraph
              strokeHomeColor={theme.color.purple}
              strokeAwayColor={theme.color.red}
              value={
                lineups ? (lineups?.home?.stats?.[MatchStatistics.STATS_INTERCEPTION] * 100)
                / (lineups?.home?.stats?.[MatchStatistics.STATS_INTERCEPTION]
                  + lineups?.away?.stats?.[MatchStatistics.STATS_INTERCEPTION]) : 0
              }
              width={150}
            />
          </StatsBlock>
          <StatsBlock>
            <ValueSmall>{`${lineups?.away?.stats?.[MatchStatistics.STATS_INTERCEPTION] ?? 0}`}</ValueSmall>
          </StatsBlock>
        </StatsBlock>
      </StatsBlockColumn>
      <StatsBlockColumn marginBottom="20px">
        <StatsBlock>
          <Caption><FormattedMessage id="statistics.offsides" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <StatsBlock>
            <ValueSmall>{`${lineups?.home?.stats?.[MatchStatistics.STATS_OFFSIDES] ?? 0}`}</ValueSmall>
          </StatsBlock>
          <StatsBlock>
            <LineGraph
              strokeHomeColor={theme.color.purple}
              strokeAwayColor={theme.color.red}
              value={
                lineups ? (lineups?.home?.stats?.[MatchStatistics.STATS_OFFSIDES] * 100)
                / (lineups?.home?.stats?.[MatchStatistics.STATS_OFFSIDES]
                    + lineups?.away?.stats?.[MatchStatistics.STATS_OFFSIDES]) : 0
              }
              width={150}
            />
          </StatsBlock>
          <StatsBlock>
            <ValueSmall>{`${lineups?.away?.stats?.[MatchStatistics.STATS_OFFSIDES] ?? 0}`}</ValueSmall>
          </StatsBlock>
        </StatsBlock>
      </StatsBlockColumn>
      <StatsBlockColumn marginBottom="20px">
        <StatsBlock>
          <Caption><FormattedMessage id="statistics.cornerKicks" /></Caption>
        </StatsBlock>
        <StatsBlock>
          <StatsBlock>
            <ValueSmall>{`${lineups?.home?.stats?.[MatchStatistics.STATS_CORNERS_TAKEN] ?? 0}`}</ValueSmall>
          </StatsBlock>
          <StatsBlock>
            <LineGraph
              strokeHomeColor={theme.color.purple}
              strokeAwayColor={theme.color.red}
              value={
                lineups ? (lineups?.home?.stats?.[MatchStatistics.STATS_CORNERS_TAKEN] * 100)
                / (lineups?.home?.stats?.[MatchStatistics.STATS_CORNERS_TAKEN]
                  + lineups?.away?.stats?.[MatchStatistics.STATS_CORNERS_TAKEN]) : 0
              }
              width={150}
            />
          </StatsBlock>
          <StatsBlock>
            <ValueSmall>{`${lineups?.away?.stats?.[MatchStatistics.STATS_CORNERS_TAKEN] ?? 0}`}</ValueSmall>
          </StatsBlock>
        </StatsBlock>
      </StatsBlockColumn>
    </BlockContainerColumn>
  );
};

export const ValueLarge = styled(Value)`
  font-size: 32px;
`;

export const ValueSmall = styled(Value)`
  font-size: 16px;
`;

export default MatchBall;
