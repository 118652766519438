import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

interface ValueAndCaptionProps {
  value?: number | string;
  additionalValue?: number | string;
  caption: string;
  isSmall?: boolean;
}

const ValueAndCaption = ({
  value, additionalValue, caption, isSmall = false,
}: ValueAndCaptionProps): React.ReactElement => (
  <div>
    <Line>
      <LargeText isSmall={isSmall}>{value}</LargeText>
      {additionalValue && (
      <AdditionalValue isSmall={isSmall}>
        /
        {additionalValue}
      </AdditionalValue>
      )}
    </Line>
    <Line>
      <Caption isSmall={isSmall}>
        <FormattedMessage
          id={caption}
          values={{
            p: (...chunks: string[]) => <p>{chunks}</p>,
          }}
        />
      </Caption>
    </Line>
  </div>

);

const Line = styled.div`
  margin: 0;
  text-align: center;
  line-height: 1;
  font-size: 0;
`;

const LargeText = styled.span<{ isSmall: boolean }>`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: ${({ isSmall }): string => (isSmall ? '26px' : '44px')};
  font-weight: bold;
  letter-spacing: -0.03px;
  color: #000000;
`;

const AdditionalValue = styled.span<{ isSmall: boolean }>`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: ${({ isSmall }): string => (isSmall ? '16px' : '24px')};
  font-weight: bold;
  letter-spacing: -0.01px;
  color: #000000;
`;

const Caption = styled.span<{ isSmall: boolean }>`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: ${({ isSmall }): string => (isSmall ? '12px' : '14px')};
  font-weight: 300;
  line-height: ${({ isSmall }): string => (isSmall ? '1.5' : '1.29')};
  letter-spacing: -0.01px;
  text-align: center;
  color: #000000;
`;

export default ValueAndCaption;
