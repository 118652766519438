import React from 'react';
import styled from 'styled-components';

import { WebTemplatePageTitle } from '@app/types/webTemplateTypes';
import { Devices } from '@app/styles';
import { ContainerMedium } from '@app/components/WebTemplates/components';

interface WebPageTitleElementProps {
  data: WebTemplatePageTitle;
}

const WebPageTitleElement = ({ data }: WebPageTitleElementProps): React.ReactElement => (
  <Container>
    <Title>{data?.title}</Title>
    {data?.subTitle && (<SubTitle>{data?.subTitle}</SubTitle>)}
  </Container>
);

const Container = styled(ContainerMedium)`
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 20px;

  @media ${Devices.desktop} {
    margin-bottom: 30px;
  }

    @media ${Devices.desktop} {
    margin-top: 40px;
  }
`;

const Title = styled.h1`
  text-align: left;
  text-transform: uppercase;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0.16px;
  color: #000000;
`;

const SubTitle = styled.h2`
  margin-top: 5px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.29;
  letter-spacing: 0.08px;
  text-align: left;
  color: #000000;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};

  @media ${Devices.desktop} {
    font-size: 18px;
  }
`;

export default WebPageTitleElement;
