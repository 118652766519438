import React from 'react';

import { WebHomeElementBackground, WebTemplateHomeElement } from '@app/types/webTemplateTypes';
import { useHomeDataList, useHomeDataListDownload } from '@app/components/Home/HomeHooks';
import { EditorialContentTypes } from '@app/types/newsTypes';
import styled from 'styled-components';
import { NewItem } from '@app/components/News';
import { Devices } from '@app/styles';
import { HomeTitleLink } from '@app/components/Home/components';

interface WebHomeVideosElementProps {
  data: WebTemplateHomeElement;
}

const WebHomeVideosElement = ({ data }: WebHomeVideosElementProps): React.ReactElement | null => {
  useHomeDataListDownload(EditorialContentTypes.VIDEO, data.category);
  const dataList = useHomeDataList(EditorialContentTypes.VIDEO, data.category);
  const latest = dataList?.[0];
  const other = dataList?.slice(1);
  const { link, title, background } = data;

  return (
    <ContainerWrapper background={data.background}>
      <Container>
        <HomeTitleLink title={title} link={link} Component={Title} background={background} showSeeMore />
        <GridWrapper>
          <Grid>
            <Latest>
              {latest && (<NewItem data={latest} infoSize="medium" />)}
            </Latest>
            <OtherGrid>
              {
            other?.map((item) => (
              <Other key={item.id}>
                <NewItem data={item} />
              </Other>
            ))
          }
            </OtherGrid>
          </Grid>
        </GridWrapper>
      </Container>
    </ContainerWrapper>
  );
};

const ContainerWrapper = styled.div<{ background: WebHomeElementBackground }>`
  background-color: ${({ background, theme }): string => (background === WebHomeElementBackground.Purple ? theme.color.purple : 'transparent')};
  background-image: ${({ background }): string => (background === WebHomeElementBackground.Purple ? 'url("/images/block-background.webp")' : 'transparent')};
  background-position: center center;
  background-size: cover;
  padding: 15px 20px 45px;;
  overflow: hidden;

  @media ${Devices.desktopSmall} {
    padding: 30px 0 60px;
    display: flex;
    justify-content: center;
  }
`;

const Container = styled.div`
  width: 100%;
  overflow: hidden;

  @media ${Devices.desktopSmall} {
    width: 1140px;
  }
`;

const Title = styled.h1<{ background?: WebHomeElementBackground }>`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 18px;
  font-weight: bold;
  line-height: 2.35;
  letter-spacing: -0.01px;
  color: ${({ background, theme }): string => (background === WebHomeElementBackground.Transparent ? theme.color.purple : '#ffffff')};
  text-transform: uppercase;
  padding-left: 10px;
`;

const GridWrapper = styled.div`
  margin-top: 15px;
  overflow-x: auto;

  @media ${Devices.desktopSmall} {
    overflow: inherit;
  }
`;

const Grid = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: no-wrap;

  @media ${Devices.desktopSmall} {
    display: flex;
  }
`;

const Latest = styled.div`
  width: 300px;
  height: 170px;
  margin-right: 20px;

  @media ${Devices.desktopSmall} {
    width: 550px;
    height: 305px;
    margin-right: 25px;
  }
`;

const OtherGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;

  @media ${Devices.desktopSmall} {
    width: 565px;
    height: 305px;
    flex-wrap: wrap;
  }
`;

const Other = styled.div`
  width: 300px;
  height: 170px;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }

  @media ${Devices.desktopSmall} {
    width: 270px;
    height: 140px;
    margin-right: 0;

    &:nth-of-type(2n-1) {
      margin-right: 25px;
    }
    &:nth-of-type(1), &:nth-of-type(2) {
      margin-bottom: 25px;
    }
  }
`;

export default WebHomeVideosElement;
