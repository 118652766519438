import * as React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { Colors } from '@app/styles';

import { BaseMenuItem } from '@app/types/configurationTypes';
import { RoutePath } from '@app/types/routerTypes';

import { findCategoryMenuItemBySlug } from '@app/helpers/menuHelpers';

import { HorizontalScroll } from '@app/components';
import LocalizedLink from '@app/components/Localization/LocalizedLink';

interface CategoryMenuProps {
  items: BaseMenuItem[];
  pathKey: RoutePath;
  pathParams: {
    topLevel: string;
    secondLevel: string;
  };
  color: Colors;
}

const CategoryMenuComponent = ({
  items, pathKey, color, pathParams,
}: CategoryMenuProps): React.ReactElement => {
  const { thirdLevel = '' } = useParams();
  const selectedId = findCategoryMenuItemBySlug(items, thirdLevel)?.id;

  return (
    <HorizontalScroll color={color}>
      <Menu color={color}>
        {
          items.map(({ name, id, url }) => (
            <MenuItem key={id} selected={id === selectedId}>
              <LocalizedLink
                pathKey={pathKey}
                pathParams={{
                  ...pathParams,
                  thirdLevel: url,
                  forthLevel: '',
                  fifthLevel: '',
                }}
              >
                {name}
              </LocalizedLink>
            </MenuItem>
          ))
        }
      </Menu>
    </HorizontalScroll>
  );
};

const Menu = styled.ul<{ color: Colors }>`
  padding: 25px 0 30px;
  display: flex;
  height: 70px;

  & a {
    color: ${({ color }): string => color};
  }
`;

const MenuItem = styled.li<{ selected: boolean }>`
  margin-right: 30px;
  height: 100%;
  width: min-content;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  display: inline-block;

  & a {
    opacity: ${({ selected }): string => (selected ? '1' : '0.4')};
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export default CategoryMenuComponent;
