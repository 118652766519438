import React from 'react';

import { useInviolaSync } from '@app/components/Inviola/InviolaHooks';
import { PageWrapper } from '@app/pages/components';

import CatalogItemsListWithFilters from '@app/pages/Inviola/CatalogPage/CatalogItemsListWithFilters';
import CatalogItemDetails from './CatalogItemDetails';
import {
  useCatalogSync,
  useCurrentCatalogItem,
  useCatalogItems,
  useWishListDownload,
  useCatalogSyncStatus,
  useCatalogSelectedItemReset,
  useInviolaCatalogMultiLangRoute,
} from './CatalogPageHooks';

const InviolaCatalogPage = (): React.ReactElement => {
  useInviolaSync();
  useCatalogSync();
  useWishListDownload();
  useCatalogSelectedItemReset();
  useInviolaCatalogMultiLangRoute();

  const catalogItems = useCatalogItems();
  const syncInProgress = useCatalogSyncStatus();
  const [hasCatalogItemId, catalogItem] = useCurrentCatalogItem();

  return (
    <PageWrapper error={null} isLoading={syncInProgress}>
      {hasCatalogItemId
        ? catalogItem && <CatalogItemDetails item={catalogItem} />
        : catalogItems && <CatalogItemsListWithFilters items={catalogItems} />}
    </PageWrapper>
  );
};

export default InviolaCatalogPage;
