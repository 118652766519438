import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { Modal } from '@app/components';
import { Button } from '@app/components/Inviola/InviolaComponents';

interface LiveAudioErrorModalProps {
  open: boolean;
  onClose: () => void;
}

const LiveAudioErrorModal = (
  { open, onClose }: LiveAudioErrorModalProps,
): React.ReactElement => {
  const { formatMessage } = useIntl();
  return (
    <Modal open={open} onClose={onClose} contentFullWidth={false}>
      <ModalContainer>
        <Message>{formatMessage({ id: 'match.modal.liveAudio' })}</Message>
        <MessageText>{formatMessage({ id: 'match.modal.liveAudioError' })}</MessageText>

        <ButtonsContainer>
          <Button onClick={onClose}>{formatMessage({ id: 'match.modal.ok' })}</Button>
        </ButtonsContainer>
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled.div`
  width: 100%;
  max-width: 95vw;
  margin: auto;
  padding: 40px 20px;
  border-radius: 8px;

  background-color: ${(props): string => props.theme.color.whiteSmoke};
  color: black;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  text-align: left;

  button {
    margin-bottom: 0;
  }

  @media ${Devices.tablet} {
    width: 450px;
    max-width: 600px;
    padding: 60px 20px 40px;
  }
`;

const ButtonsContainer = styled.div`
  display: inline-flex;
  width: 100%;

  button {
    padding: 10px;
    font-size: .8rem;
    margin: 30px 15px 0;
  }
`;

const Message = styled.p`
  font-size: 1.375rem;
  font-weight: bold;

  text-transform: uppercase;
  text-align: center;
`;

const MessageText = styled.p`
  margin-top: 10px;
  font-size: 16px;
  font-weight: normal;

  text-align: center;
`;

export default LiveAudioErrorModal;
