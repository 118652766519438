import { InviolaCardMovement } from '@app/types/inviolaTypes';
import { InviolaGender } from '@app/services/inviola/apiConstants';

export interface InviolaRawCustomer {
  id: number;
  userName: string;
  name: string;
  surname: string;

  fidelyCode: string;
  expiration: string;

  card: string;
  identityCard: string;

  gender: InviolaGender;
  birthdate: string;

  mailContactData: string;
  mobileContactData: string;

  addressNumber: string;
  address: string;
  zip: string;

  country: number;
  geoLevel1: number;
  geoLevel2: number;
  geoLevel3: number;
  geoLevel4: number;
  geoLevel5: number;

  // eslint-disable-next-line camelcase
  geo_lat: number;
  // eslint-disable-next-line camelcase
  geo_long: number;

  // eslint-disable-next-line camelcase
  balance_points: number;

  privacy: {
    usedForPromotions: boolean | string;
    usedForStatistics: boolean | string;
  }

  customerDynamicFields?: Array<InviolaRawCustomerDynamicField>;
}

export interface InviolaRawCustomerDynamicField {
  id: number;
  value: string | number | boolean;
}

export interface InviolaResponse<T> {
  returncode: number | string;
  sessionid: string;
  data: T;
}

export interface InviolaCampaign {
  id: number;
  name: string;
}

export interface InviolaDynamicFields {
  [Identifier: number]: InviolaDynamicField
}

export interface InviolaDynamicRawFieldOption {
  id: number;
  value: string;
}

export enum InviolaDynamicFieldRepresentations {
  Text = 0,
  Date = 3,
  Select = 6,
  Checkbox = 7,
}

export enum InviolaDynamicFieldTypes {
  Boolean = 1,
  Date = 2,
  Text = 7,
}

export interface InviolaRawDynamicFields {
  dynamicFields: InviolaRawDynamicField[]
}

export interface InviolaRawDynamicField {
  id: number;
  name: string;
  order: number;

  representation: InviolaDynamicFieldRepresentations;

  type: {
    dataType: InviolaDynamicFieldTypes
  };

  flags: {
    isEnabled: boolean;
    isMandatory: boolean;
    isVisible: boolean;
  }

  options: InviolaDynamicRawFieldOption[];
}

export interface InviolaDynamicFieldOption {
  id: number | string;
  name: string;
}

export interface InviolaDynamicField {
  id: number;
  name: string;
  order: number;

  representation: InviolaDynamicFieldRepresentations;
  type: InviolaDynamicFieldTypes;

  enabled: boolean;
  mandatory: boolean;
  visible: boolean;

  options: InviolaDynamicFieldOption[];
}

export interface InviolaCountries {
  countries: InviolaCountry[]
}

export interface InviolaCountry {
  id: number;
  name: string;
}

export interface InviolaGeoLevels {
  geoLevel: InviolaGeoLevel[] | InviolaGeoLevel
}

export interface InviolaGeoLevel {
  id: number;
  name: string;
}

export interface InviolaLoginData {
  customer: InviolaRawCustomer;
  campaign: InviolaCampaign;
  token: string;
}

export interface InviolaModifyData {
  customer: InviolaRawCustomer;
  campaign: InviolaCampaign;
}

export interface InviolaSyncData {
  session: string;
}

export interface InviolaMovementsData {
  movements: InviolaCardMovement | InviolaCardMovement[];
}

export interface InviolaRawShop {
  id: string;
  name: string;

  addressNumber: string;
  addressPrefix: string;
  address: string;
  zip: string;

  geoLevel1name: string;
  geoLevel2name: string;
  geoLevel3name: string;
  geoLevel4name: string;
  geoLevel5name: string;

  // eslint-disable-next-line camelcase
  distance_txt: string;

  mail: string;
  telephone: string;
  contactMobile: string;

  contactName: string;

  shopType: string;

  geoLat: number;
  geoLong: number;

  imageURL: string;

  defaultWeighing?: {
    WeightExchangePrizeMoney: number;
    WeightExchangePrizePoints: number;
    weightChargePointMoney: number;
    weightChargePointPoints: number;
    weightPointValueMoney: number;
    weightPointValuePoints: number;
  }
}

export interface InviolaRawCategory {
  id: number;
  description: string;
  fatherId: number;
}

export interface InviolaRawCategories {
  categories: InviolaRawCategory[];
}

export interface InviolaRawCatalogItem {
  id: number;
  category?: InviolaRawCategory;
  categoryId?: number;
  name: string;
  currencyId: number;
  currencySymbol: string;
  description: string;
  flags: {
    enabled: boolean;
  }
  foreignId: number;
  idCatalog: number;
  issueDate: string;
  mark: string;
  maxToExchange: number;
  minimunPointsEP: number;
  moneyValue: number;
  pathImage: string;
  pathImageAbsolute: string;
  points: number
  prizeCode: string;
  shippingCostForeign: number;
  shippingCostLocal: number;
  stock: number;
  suggestedRetailPrice: number;
}

export interface InviolaRawCatalogItems {
  prizes: InviolaRawCatalogItem[];
}

export interface InviolaRawWishListItem {
  // eslint-disable-next-line camelcase
  catalog_id: string;
  // eslint-disable-next-line camelcase
  customer_id: string;
  // eslint-disable-next-line camelcase
  prize_id: string;
}

export interface InviolaRawRemainingPoints {
  customerId: string;
  remainingPoints: number;
}

export interface InviolaRawAward {
  id: number;
  code: string;

  prize: {
    id: number;
    name: string;
    points: number;
    quantity: number;
    exchangeDate: string;

    prize: InviolaRawCatalogItem;
  }
}

export interface InviolaRawAwards {
  prizes: InviolaRawAward[];
}

export interface InviolaProxyRawShop extends InviolaRawShop {
  defaultWeighing: {
    WeightExchangePrizeMoney: number;
    WeightExchangePrizePoints: number;
    weightChargePointMoney: number;
    weightChargePointPoints: number;
    weightPointValueMoney: number;
    weightPointValuePoints: number;
  }
}

export interface InviolaProxyRawShops {
  netsAndShops: InviolaProxyRawShop[];
  pagination: {
    actualPage: number;
    initLimit: number
    recordsTotal: number
    rowCount: number
    totalPages: number
  }
}
