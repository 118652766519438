import { LocalizedWebTemplateData } from '@app/types/webTemplateTypes';
import { PageError } from '@app/types/errorTypes';

export enum WebTemplateActionTypes {
  LOADING_WEB_TEMPLATE_DATA = 'WEB_TEMPLATE/LOADING_WEB_TEMPLATE_DATA',
  SET_WEB_TEMPLATE_DATA = 'WEB_TEMPLATE/SET_WEB_TEMPLATE_DATA',
  SET_WEB_TEMPLATE_DATA_LIST = 'WEB_TEMPLATE/SET_WEB_TEMPLATE_DATA_LIST',
  SET_NEWS_DATA = 'WEB_TEMPLATE/SET_NEWS_DATA',
  SET_NEWS_LOADING_STATE = 'WEB_TEMPLATE/SET_NEWS_LOADING_STATE',
  RESET_WEB_TEMPLATE_DATA = 'WEB_TEMPLATE/RESET_WEB_TEMPLATE_DATA',
  RESET_WEB_TEMPLATE_DATA_LIST = 'WEB_TEMPLATE/RESET_WEB_TEMPLATE_DATA_LIST',
  SET_WEB_TEMPLATE_ERROR = 'WEB_TEMPLATE/SET_WEB_TEMPLATE_ERROR',
  RESET_WEB_TEMPLATE_ERROR = 'WEB_TEMPLATE/RESET_WEB_TEMPLATE_ERROR',
}

export interface LoadingWebTemplateData {
  type: WebTemplateActionTypes.LOADING_WEB_TEMPLATE_DATA;
  payload: boolean;
}

export interface SetWebTemplateDataPayload {
  templates: {
    data: LocalizedWebTemplateData;
    id: string;
  };
}

export interface SetWebTemplateDataListPayload {
  templates: {
    data: LocalizedWebTemplateData;
    type: string;
  };
}

export interface SetWebTemplateData {
  type: WebTemplateActionTypes.SET_WEB_TEMPLATE_DATA;
  payload: SetWebTemplateDataPayload;
}

export interface ResetWebTemplateData {
  type: WebTemplateActionTypes.RESET_WEB_TEMPLATE_DATA;
}

export interface SetWebTemplateDataList {
  type: WebTemplateActionTypes.SET_WEB_TEMPLATE_DATA_LIST;
  payload: SetWebTemplateDataListPayload;
}

export interface ResetWebTemplateDataList {
  type: WebTemplateActionTypes.RESET_WEB_TEMPLATE_DATA_LIST;
}

export interface SetWebTemplateError {
  type: WebTemplateActionTypes.SET_WEB_TEMPLATE_ERROR;
  payload: PageError;
}

export interface ResetWebTemplateError {
  type: WebTemplateActionTypes.RESET_WEB_TEMPLATE_ERROR;
}

export type WebTemplateActions = SetWebTemplateData | ResetWebTemplateData | SetWebTemplateError
  | ResetWebTemplateError | LoadingWebTemplateData | SetWebTemplateDataList | ResetWebTemplateDataList;
