import React, { useState } from 'react';
import styled from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';

import { ContainerMedium } from '@app/components/WebTemplates/components';
import { WebTemplatePDF } from '@app/types/webTemplateTypes';

interface WebPDFElementProps {
  data: WebTemplatePDF;
}

const WebPDFElement = ({ data }: WebPDFElementProps): React.ReactElement => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number; }) => {
    setNumPages(numPages);
  };

  const previousPage = (): void => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  const nextPage = (): void => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <WebPDFElementContainer>
      <Document
        options={{
          cMapUrl: '/cmaps/',
          cMapPacked: true,
          disableTextLayer: true,
        }}
        file={data?.pdf?.url}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} width={1000} />
      </Document>
      <PDFControlWrapper>
        <PDFControl>
          <Pages>{`Page ${pageNumber} of ${numPages}`}</Pages>
          <Button onClick={previousPage}>&lt;</Button>
          <Button onClick={nextPage}>&gt;</Button>
        </PDFControl>
      </PDFControlWrapper>
    </WebPDFElementContainer>
  );
};

const PDFControlWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PDFControl = styled.div`
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  flex-wrap: nowrap;
`;

const WebPDFElementContainer = styled(ContainerMedium)`
  position: relative;
  width: 100%;
  overflow-x: auto;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.23px;
  line-height: 1.5;
  text-align: justify;
  color: black;
  padding-top: 20px;
  padding-bottom: 20px;

  & .react-pdf__Document {
    width: 100%;
  }

  & .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }

  & .react-pdf__Page__textContent, & .react-pdf__Page__annotations {
    display: none;
  }

  & ${PDFControl} {
    opacity: 0;
    transition: all 0.3s ease;
  }
  &:hover ${PDFControl} {
    opacity: 1;
  }
`;

const Pages = styled.div`
  padding: 5px;
  font-size: 18px;
`;

const Button = styled.div`
  padding: 5px;
  font-size: 18px;
  margin: 0 5px;
  width: 35px;
  text-align: center;
  background-color: #ffffff;
  color: ${(props): string => props.theme.color.purple};
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${(props): string => props.theme.color.purple};
    color: #ffffff;
  }
`;

export default WebPDFElement;
