export enum InviolaGender {
  Male = 'M',
  Female = 'F',
  Other = 'N',
}

export enum InviolaCountries {
  Italia = 3,
  Germany = 60,
  Albania = 178,
  Spain = 148,
}

export enum InviolaCustomerDynamicFields {
  BirthPlace = 316,
  Job = 313,
  Nationality = 1020,
  ForeignCity = 333,
  AcceptanceOfRegulations = 413,
}

export enum InviolaHttpErrors {
  EMAIL_CONFIRMATION_FAILURE = 444,
  OPERATOR_DISABLED = 78,
  SESSION_EXPIRED = 998,
}
