import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { useShop, useShops } from '@app/components/Inviola/InviolaHooks';
import { InviolaShop } from '@app/types/inviolaTypes';
import { CloseButton } from '@app/components/Buttons';
import { Devices } from '@app/styles';

interface ShopsListProps {
  activeShopId?: string;
  syncInProgress: boolean;
  setActiveShop: (id: string) => unknown;
  removeActiveShop: () => unknown;
}

interface ShopItemProps {
  shop: InviolaShop;
  onClick: () => void;
  onClose: () => void;
  activeShopId?: string;
}

const ShopItem = ({ shop, activeShopId, onClick, onClose }: ShopItemProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const activeShop = useShop(activeShopId);
  const isShopActive = activeShop && activeShopId === shop.id;

  const onUnsetActiveShop = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onClose();
  };

  return (
    <Item onClick={onClick} data-active={isShopActive}>
      <Row>
        {shop.imageURL
          ? (<ShopImage src={shop.imageURL} alt={shop.name} />)
          : (<ShopLogo>{shop.name.charAt(0)}</ShopLogo>)}
        <Infos>
          <Name>{shop.name}</Name>
          <Address>
            <p>{shop.shortAddress}</p>
            <p><em>{shop.distance}</em></p>
          </Address>
        </Infos>
        <Action>
          <MoreInfoIcon viewBox="0 0 12 19">
            <MoreInfoIconPath d="M.898 18.728c.397.363.991.363 1.388 0l9.416-8.594c.397-.363.397-.905 0-1.267L2.286.272C2.089.092 1.841 0 1.593 0 1.345 0 1.097.09.9.272.503.634.503 1.177.9 1.54l8.72 7.962-8.724 7.963c-.395.36-.395.904.002 1.265z" />
          </MoreInfoIcon>
        </Action>
      </Row>
      <InfoRow>
        <Infos>
          <dl>
            {shop.pointsWeight && (
              <>
                <dt>{formatMessage({ id: 'inviola.profile.shop.pointsRate' })}</dt>
                <dd>
                  {shop.pointsWeight.money}
                  &euro;&nbsp;&#8725;&nbsp;
                  {shop.pointsWeight.point}
                  &nbsp;
                  {shop.pointsWeight.point === 1
                    ? formatMessage({ id: 'inviola.stores.rate.point' }).toLowerCase()
                    : formatMessage({ id: 'inviola.stores.rate.points' }).toLowerCase()}
                </dd>
              </>
            )}
            {shop.distance && (
              <>
                <dt>{formatMessage({ id: 'inviola.profile.shop.distance' })}</dt>
                <dd>{shop.distance}</dd>
              </>
            )}
            {shop.fullAddress && (
              <>
                <dt>{formatMessage({ id: 'inviola.profile.shop.address' })}</dt>
                <dd>{shop.fullAddress}</dd>
              </>
            )}
            {shop.phone && (
              <>
                <dt>{formatMessage({ id: 'inviola.profile.shop.phone' })}</dt>
                <dd><a href={`tel:${shop.phone}`}>{shop.phone}</a></dd>
              </>
            )}
            {shop.mail && (
              <>
                <dt>{formatMessage({ id: 'inviola.profile.shop.mail' })}</dt>
                <dd><a href={`mailto:${shop.mail}`}>{shop.mail}</a></dd>
              </>
            )}
          </dl>
        </Infos>
      </InfoRow>
      <CloseButtonWrapper>
        <CloseButton onClick={onUnsetActiveShop} size={25} />
      </CloseButtonWrapper>
    </Item>
  );
};

export default React.memo((
  { activeShopId, setActiveShop, removeActiveShop, syncInProgress }: ShopsListProps,
): React.ReactElement => {
  const { formatMessage } = useIntl();
  const shops = useShops();

  return shops?.length ? (
    <>
      {shops?.map((shop) => (
        <ShopItem
          key={shop.id}
          shop={shop}
          onClick={() => setActiveShop(shop.id)}
          onClose={removeActiveShop}
          activeShopId={activeShopId}
        />
      ))}
    </>
  ) : (
    <ShopsListEmpty syncInProgress={syncInProgress}>
      {formatMessage({ id: 'inviola.profile.shop.emptyList' })}
    </ShopsListEmpty>
  );
});

const ShopsListEmpty = styled.div<{ syncInProgress: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 0 0 100%;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 0;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.2px;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};

  opacity: ${({ syncInProgress }): number => (syncInProgress ? 0 : 1)};
`;

const Action = styled.div`
  margin-left: auto;
  transition: opacity 0.2s ease;
  opacity: 1;
`;

const MoreInfoIcon = styled.svg`
  width: 12px;
  height: 19px;
`;

const MoreInfoIconPath = styled.path`
  transition: all 0.2s ease;
  fill: ${(props): string => props.theme.color.purple};
  opacity: 0.4;
`;

const ShopLogo = styled.div`
  display: flex;
  flex: 0 0 60px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 60px;
  border-radius: 50%;
  color: white;
  background-color: ${(props): string => props.theme.color.purple};
  box-shadow: 0 0 3px ${(props): string => props.theme.color.purple};
  font-size: 30px;
  font-weight: normal;
  line-height: 30px;
  transition: all 0.2s ease;
`;

const ShopImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 3px ${(props): string => props.theme.color.purple};
  transition: all 0.2s ease;
`;

const Infos = styled.div`
  margin: 0 15px;
  color: black;
  transition: all 0.2s ease;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.2px;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};

  a {
    color: inherit;
  }

  a:hover {
    text-decoration: underline;
  }

  dl {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 0;
  }

  dt {
    font-weight: bold;

    width: 100%;
    margin: 4px 0 0;

    @media ${Devices.tabletLarge} {
      width: 25%;
      margin: 4px 0;

      font-weight: normal;
    }
  }

  dd {
    width: 75%;
    margin: 4px 0;
    padding-left: 10px;
  }
`;

const Name = styled.p`
  display: flex;

  align-items: center;

  text-transform: uppercase;
  transition: font-size 0.2s ease;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 1.5px;
`;

const Address = styled.div`
  * {
    font-size: 12px;
    margin-top: 5px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 0;
`;

const InfoRow = styled(Row)`
  display: none;
  opacity: 0;
  animation: flip-in-hor-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  transition: opacity 0.2s ease;
  opacity: 0;
`;

const Item = styled.div`
  position: relative;
  background-color: white;
  transition: all 0.2s ease;

  padding: 20px;
  margin-bottom: 10px;

  font-size: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: default;

  &[data-active='true'] {
    transition: all 0.2s ease;
    background-color: #e5d9f2;

    cursor: default;
  }

  &[data-active='true'] ${Address} {
    display: none;
  }

  &[data-active='true'] ${InfoRow} {
    transition: opacity 0.2s ease;
    display: flex;
    opacity: 1;
  }

  &[data-active='true'] ${Action} {
    transition: opacity 0.2s ease;
    opacity: 0;
  }

  &[data-active='true'] ${CloseButtonWrapper} {
    transition: opacity 0.2s ease;
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: #e5d9f2;
  }

  &[data-active='true']:hover {
    cursor: default;
  }

  &:hover ${MoreInfoIconPath} {
    transition: all 0.2s ease;
    opacity: 0.8;
    fill: white;
  }

  &:hover ${Infos},
  &[data-active='true'] ${Infos} {
    transition: all 0.2s ease;
  }
`;
