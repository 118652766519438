import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { ContainerMedium } from '@app/components/WebTemplates/components';
import { useEmbeddedScripts } from '@app/pages/NewsDetails/NewsDetailsHooks';
import { decodeURISafe } from '@app/helpers/webTemplateHelpers';

interface WebEditorialTextElementProps {
  content: string;
}

const WebEditorialTextElement = ({ content }: WebEditorialTextElementProps): React.ReactElement => {
  const decoded = decodeURISafe(content); // use correct Uniform Resource Identifier
  useEmbeddedScripts(decoded);

  return (
    <WebEditorialElementContainer>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: decoded }} />
    </WebEditorialElementContainer>
  );
};

export const WebEditorialElementContainer = styled(ContainerMedium)`
  position: relative;
  width: 100%;
  overflow-x: auto;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-weight: normal;
  font-size: 13px;
  letter-spacing: 0.23px;
  line-height: normal;
  text-align: justify;
  color: black;
  padding-top: 20px;
  padding-bottom: 20px;

  & h1 {
    font-family: ${(props): string => props.theme.fontFamily.commutersSans};
    line-height: 30px;
  }

  & h2 {
    font-family: ${(props): string => props.theme.fontFamily.commutersSans};
    font-size: 12px;
    font-weight: 300;
    line-height: 28px;
  }

  & h3 {
    font-family: ${(props): string => props.theme.fontFamily.commutersSans};
    line-height: 26px;
  }

  & h4 {
    font-family: ${(props): string => props.theme.fontFamily.commutersSans};
    line-height: 24px;
  }

  & h5 {
    font-family: ${(props): string => props.theme.fontFamily.commutersSans};
    line-height: 22px;
  }

  & ul {
    list-style-type: disc;
    padding-left: 30px;
    margin-bottom: 10px;
  }

  & figure {
    margin: unset;
    text-align: center;
    padding: 20px 0;
  }

  & img {
    width: 100%;
    height: auto;
  }

  & table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    margin: 10px 0;
  }

  & tr:first-of-type td {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.26px;
    color: ${(props): string => props.theme.color.purple};
    border-bottom: solid 2px ${(props): string => props.theme.color.purple};
    padding: 5px;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  & tr td {
    font-size: 10px;
    letter-spacing: 0.23px;
    text-align: left;
    color: #000000;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    padding: 10px 5px;
    min-width: 50px;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  b, strong {
    font-weight: bold;
  }

  // & > div img {
  //   width: 100%;
  // }

  & > div > p, & > div > div, & > div > img {
    margin-bottom: 10px;
  }

  & a {
    color: ${(props): string => props.theme.color.purple};

    &:hover,
    &:visited,
    &:active {
      color: ${(props): string => props.theme.color.purple};
    }
  }

  @media ${Devices.desktop} {
    padding: 20px 0;

    & tr:first-of-type td {
      font-size: 16px;
      padding: 10px 5px;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    & tr td {
      font-size: 16px;
      padding: 20px 10px;
      min-width: 100px;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
`;

export default WebEditorialTextElement;
