import React from 'react';
import styled from 'styled-components';

import { useIsLoading, usePlayersDownload } from '@app/pages/Teams/TeamsHooks';
import { PageWrapper } from '@app/pages/components';
import { PlayerInfo, PlayerTabs } from '@app/pages/PlayerProfile/Profiles';
import {
  useCareerDownload,
  usePlayerProfileMultiLangRoute,
  usePlayersStatisticsDownload,
  useRouterValidation,
} from './PlayersHooks';

const PlayerProfile = (): React.ReactElement => {
  usePlayersDownload();
  usePlayersStatisticsDownload();
  usePlayerProfileMultiLangRoute();
  useCareerDownload();

  const error = useRouterValidation();
  const isLoading = useIsLoading();

  return (
    <PageWrapper error={error} isLoading={isLoading}>
      <Container>
        <InnerContainer>
          <PlayerInfo />
          <PlayerTabs />
        </InnerContainer>
      </Container>
    </PageWrapper>
  );
};

const Container = styled.div`
  height: 100%;
  background-color: ${(props): string => props.theme.color.whiteSmoke};
`;

const InnerContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`;

export default PlayerProfile;
