import React from 'react';

import { WebHomeElementType, WebTemplateHomeElement } from '@app/types/webTemplateTypes';
import {
  WebHomeGalleriesElement, WebHomeNewsElement, WebHomeVideosElement, WebHomeNewsAndStandingsElement,
} from '@app/components/Home/elements/index';

interface WebHomeElementSelectProps {
  data: WebTemplateHomeElement;
}

const WebHomeElementSelect = ({ data }: WebHomeElementSelectProps): React.ReactElement | null => {
  switch (data?.elementType) {
    case WebHomeElementType.Videos:
      return (<WebHomeVideosElement data={data} />);
    case WebHomeElementType.Gallery:
      return (<WebHomeGalleriesElement data={data} />);
    case WebHomeElementType.News:
      return (<WebHomeNewsElement data={data} />);
    case WebHomeElementType.NewsAndStandings:
      return (<WebHomeNewsAndStandingsElement data={data} />);
    default:
      return null;
  }
};

export default WebHomeElementSelect;
