import { CompetitionTypes } from '@app/types/configurationTypes';

export enum MenCompetitionsMap {
  SeriaA = 'competition_men_seriea',
  CoppaItalia = 'competition_men_coppaitalia',
  SuperCoppaItalia = 'competition_men_supercoppaitaliana',
  ChampionsLeague = 'competition_men_championsleague',
  EuropaLeague = 'competition_men_europaleague',
  ICC = 'competition_men_icc',
}

export enum OptaSDCompetitionsMap {
  WomenSeriaA = 'competition_women_seriea',
  Primavera = 'competition_youth_campionato_primavera_1',
  Primavera2 = 'primavera_2',
}

export enum OptaSDCompetitionCategoriesMap {
  WomenSeriaA = 'web_seasons_women_first_team',
  Primavera = 'web_seasons_primavera',
}

export const CompetitionTypesMap: {
  [key in CompetitionTypes]: CompetitionTypes;
} = {
  cup: 'cup',
  league: 'league',
  tournament: 'tournament',
};
