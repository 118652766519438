import { TeamStatistics } from '@app/types/teamsTypes';
import { sendRequestTyped } from '@app/services/opta/request';
import { FeedTypes } from '@app/services/opta/constants/feedTypes';
import { convertTeamStatistics } from './converters/teamsConverter';
import { OptaTeamCompetitionResponse } from './types/responseTypes';

 interface GetTeamDataInterface {
  seasonId: string;
  teamId: string;
  optaId: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function getTeamStatistics(requestParams: GetTeamDataInterface): Promise<TeamStatistics> {
  // todo: remove hardcode when proxy will be ready
  const params = {
    feed_type: FeedTypes.teams,
    json: true,
    season_id: requestParams.seasonId,
    team_id: requestParams.teamId,
    competition: requestParams.optaId,
    user: 'acf',
    psw: 'netco0202',
  };

  const optaResponse = await sendRequestTyped<OptaTeamCompetitionResponse>({
    path: 'team_competition.php',
    params,
  });

  return convertTeamStatistics(optaResponse);
}
