import { CurrentRouteState } from '@app/store/reducers/currentRouteReducer';

export enum CurrentRouteActionTypes {
  SET_CURRENT_ROUTE = 'ROUTE/SET_CURRENT_ROUTE',
}

export interface SetCurrentRoute {
  type: CurrentRouteActionTypes.SET_CURRENT_ROUTE;
  payload: CurrentRouteState;
}

export type CurrentRouteActions = SetCurrentRoute;
