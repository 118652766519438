import React, { useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import {
  ProgressBarBar,
  ProgressTime,
  ProgressSeparator,
  ProgressBarProgress,
  ProgressBarCurrent,
  ProgressBarControl,
  ProgressBarBlock,
} from './VideoComponents';

interface ProgressBarProps {
  currentTime: number;
  totalTime: number;
  onClick: (time: number) => void;
}

const formatSeconds = (seconds: number): string => {
  const minutes = Math.trunc(seconds / 60);
  const minutesPart = minutes.toString().padStart(2, '0');
  const secondsPart = Math.trunc(seconds % 60)
    .toString()
    .padStart(2, '0');

  return `${minutesPart}:${secondsPart}`;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ currentTime, totalTime, onClick }) => {
  const [currentTooltipTime, setCurrentTooltipTime] = useState('');
  const [isDragInProgress, setIsDragInProgress] = useState(false);
  const currentProgress = (currentTime / totalTime) * 100;
  const displayedTime = formatSeconds(currentTime);
  const displayedTotal = useMemo(() => formatSeconds(totalTime), [totalTime]);

  const tooltipTime = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const seekTime = (event.nativeEvent.offsetX / event.currentTarget.offsetWidth) * totalTime;
    setCurrentTooltipTime(formatSeconds(seekTime));
    if (isDragInProgress) {
      onClick(seekTime);
    }
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsDragInProgress(true);
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (isDragInProgress) {
      setIsDragInProgress(false);
      const seekTime = (event.nativeEvent.offsetX / event.currentTarget.offsetWidth) * totalTime;
      onClick(seekTime);
    }
  };

  return (
    <>
      <ProgressTime>
        <span>{displayedTime}</span>
        <ProgressSeparator>/</ProgressSeparator>
        <span>{displayedTotal}</span>
      </ProgressTime>
      <ProgressBarBar>
        <ProgressBarBlock
          onMouseUp={handleMouseUp}
          onMouseMove={tooltipTime}
        />
        <ProgressBarProgress percent={currentProgress} />
        <ProgressBarCurrent
          percent={currentProgress}
        />
        <ProgressBarControl
          data-tip
          data-for="progressBarTooltip"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={tooltipTime}
        />
      </ProgressBarBar>
      <ReactTooltip id="progressBarTooltip" getContent={() => currentTooltipTime} />
    </>
  );
};

export default ProgressBar;
