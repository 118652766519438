import { LocalizedPlayer, Player } from '@app/types/teamsTypes';
import { Competition, Competitions, TeamSubMenuId } from '@app/types/configurationTypes';
import { TeamSubMenuTypeMap } from '@app/constants/teamsConstants';
import {
  FiorentinaTeamId,
  OptaSDFiorentinaTeamIdMap,
  WomenFiorentinaTeamId,
} from '@app/services/opta/constants/teamConstants';
import { TeamSquadTypes } from '@app/services/kentico/teamsService';
import { LanguageType } from '@app/types/localizationTypes';
import { Tab } from '@app/pages/PlayerProfile/PlayersHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales';
import AppState from '@app/types/appStateTypes';
import { AppLanguages } from '@app/constants/localizationConstants';
import { PlayerCommonStatistics } from '@app/pages/PlayerProfile/Profiles';
import { CommonPageBaseUrlParams } from '@app/helpers/routeHelpers';
import { RouteParams } from '@app/types/routerTypes';
import { getSecondLevelMenuItem } from '@app/helpers/menuHelpers';
import PlayerCareer from '@app/pages/PlayerProfile/Profiles/PlayerCareer/PlayerCareer';

export const isTeamFiorentina = (id = ''): boolean => (
  [
    FiorentinaTeamId,
    WomenFiorentinaTeamId,
    OptaSDFiorentinaTeamIdMap.primavera_2,
    OptaSDFiorentinaTeamIdMap.competition_youth_campionato_primavera_1,
    OptaSDFiorentinaTeamIdMap.competition_women_seriea,
  ].includes(id)
);

export function mapTeamSquadType(type?: TeamSubMenuId): TeamSquadTypes | null {
  switch (type) {
    case TeamSubMenuTypeMap.men: return TeamSquadTypes.MenSquad;
    case TeamSubMenuTypeMap.women: return TeamSquadTypes.WomenSquad;
    case TeamSubMenuTypeMap.primavera: return TeamSquadTypes.PrimaveraSquad;
    default: return null;
  }
}

/* eslint-disable dot-notation */
export function mapTeamTypeToCompetition(type: TeamSubMenuId, competitions: Competitions): Competition | null {
  switch (type) {
    case TeamSubMenuTypeMap.men: return competitions['competition_men_seriea'];
    case TeamSubMenuTypeMap.women: return competitions['competition_women_seriea'];
    case TeamSubMenuTypeMap.primavera: return null;
    default: return null;
  }
}

export function sortPlayers(players: Player[]): Player[] {
  return players.sort((playerA, playerB) => {
    const sortA = playerA.sortingOrder;
    const sortB = playerB.sortingOrder;
    if (sortA < sortB) {
      return -1;
    }
    if (sortA > sortB) {
      return 1;
    }
    const nameA = playerA.lastName?.toUpperCase();
    const nameB = playerB.lastName?.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}

export const getPlayerTabs = (locale: LanguageType): Tab[] => ([
  {
    name: 'player.tabs.statistics',
    component: PlayerCommonStatistics,
    caption: (Translations[locale]?.['player.tabs.statistics'] ?? '').toLowerCase(),
  },
  {
    name: 'player.tabs.career',
    component: PlayerCareer,
    caption: (Translations[locale]?.['player.tabs.career'] ?? '').toLowerCase(),
  },
  /*   {
      name: 'player.tabs.biography',
      component: PlayerBiography,
      caption: (Translations[locale]?.['player.tabs.biography'] as string ?? '').toLowerCase(),
    }, */
]);

type GetLocalizedPlayer = (
  state: AppState,
  teamType: TeamSubMenuId,
  language: LanguageType,
  urlSlug: string,
) => LocalizedPlayer

export const getLocalizedPlayer: GetLocalizedPlayer = (state, teamType, language, urlSlug) => {
  const localizedTeam = state.teams.teams?.[teamType];
  const teamPlayerId = localizedTeam?.[language]?.find((teamPlayer) => teamPlayer.urlSlug === urlSlug)?.id;

  return (
    AppLanguages.reduce((acc, language) => {
      acc[language] = localizedTeam?.[language]?.find((teamPlayer) => teamPlayer.id === teamPlayerId);
      return acc;
    }, {} as LocalizedPlayer)
  );
};

type GetPlayerUrlParams = (
  state: AppState,
  params: RouteParams,
  language: LanguageType
) => CommonPageBaseUrlParams

export const getPlayerUrlParams: GetPlayerUrlParams = (state, params, language) => {
  const {
    topLevel, secondLevel, forthLevel = '', fifthLevel = '',
  } = params;
  const urlParams = {
    thirdLevel: {}, forthLevel: {}, fifthLevel: {},
  } as unknown as CommonPageBaseUrlParams;
  const secondLevelMenu = getSecondLevelMenuItem(state, topLevel, secondLevel, language);
  const localizedPlayer = getLocalizedPlayer(
    state,
    secondLevelMenu?.[language]?.data.id as TeamSubMenuId,
    language,
    forthLevel,
  );
  const localeTabs = getPlayerTabs(language);
  const activeLocaleTab = localeTabs.find((item) => item.caption === fifthLevel);

  return AppLanguages
    .reduce((acc, language) => {
      const params = { ...acc };

      params.thirdLevel[language] = (Translations[language]?.['players'] ?? '').toLowerCase();
      params.forthLevel[language] = localizedPlayer?.[language]?.urlSlug ?? '';

      const tabs = getPlayerTabs(language);
      const activeTab = tabs.find((item) => item.name === activeLocaleTab?.name);
      params.fifthLevel[language] = activeTab?.caption ?? '';

      return params;
    }, urlParams);
};
