import { InviolaSessionLifetime } from '@app/constants/inviolaConstants';
import { InviolaHttpErrors } from '@app/services/inviola/apiConstants';
import { loginWithToken, sync } from '@app/services/inviola/inviola';
import { InviolaAPIError, InviolaAppError } from '@app/services/inviola/types/errorTypes';
import { InviolaResponse, InviolaSyncData } from '@app/services/inviola/types/responseTypes';
import {
  SetSyncDone,
  InviolaActionTypes,
  SetCustomerSession,
  SetCustomerSessionAccessedAt,
} from '@app/store/actionTypes/inviolaActionTypes';
import { InviolaStateSession, InviolaState } from '@app/store/reducers/inviolaReducer';
import { Action, ActionWithPromiseReturn } from '@app/types/actionTypes';
import AppState from '@app/types/appStateTypes';
import { setSyncInProgress } from '@app/store/actions/inviolaActions/public';
import { handleInviolaError, setError } from './error';
import { resetCustomer, setCustomerUpdated } from './customer';

const setSyncDone = (syncData: InviolaResponse<InviolaSyncData>): SetSyncDone => ({
  type: InviolaActionTypes.SYNC_DONE,
  payload: syncData,
});

const setCustomerSession = (customerSessionId: string): SetCustomerSession => ({
  type: InviolaActionTypes.SET_CUSTOMER_SESSION,
  payload: customerSessionId,
});

const setCustomerSessionAccessedAt = (): SetCustomerSessionAccessedAt => ({
  type: InviolaActionTypes.SET_CUSTOMER_SESSION_ACCESSED_AT,
});

type sessionType = 'customer' | 'sync';

export const getSession = (type: sessionType): ActionWithPromiseReturn<string | never> => (
  async (dispatch, getState): Promise<string | never> => {
    const state = getState();
    let currentSession = getStateSession(type, state);

    if (!currentSession || isExpiredSession(currentSession)) {
      currentSession = await dispatch(renewSession(type));
    }

    if (currentSession && type === 'customer') {
      dispatch(setCustomerSessionAccessedAt());
    }

    if (!currentSession) {
      dispatch(setSyncInProgress(false));
      throw new Error(`Can not get or renew Inviola ${type} session`);
    }

    return currentSession.id;
  }
);

const getStateSession = (type: sessionType, state: AppState): InviolaStateSession | undefined => {
  const sessionKey = `${type}Session` as keyof InviolaState;

  return state.inviola[sessionKey] as InviolaStateSession;
};

const isExpiredSession = (session: InviolaStateSession): boolean => {
  if (!session?.createdAt) return true;
  const lastUsedAtDate = new Date(session.createdAt);

  const expireAtDate = new Date();
  expireAtDate.setMinutes(expireAtDate.getMinutes() - InviolaSessionLifetime);

  return lastUsedAtDate <= expireAtDate;
};

const renewSession = (type: sessionType): ActionWithPromiseReturn<InviolaStateSession | undefined> => (
  async (dispatch, getState): Promise<InviolaStateSession | undefined> => {
    const state = getState();

    if (type === 'sync') {
      await dispatch(renewSyncSession());
    } else {
      const { rememberToken, rememberMe, accessedAt } = state.inviola.customerSession || {};

      if (rememberMe && rememberToken) {
        await dispatch(renewCustomerSession(rememberToken));
      } else if (!rememberMe && rememberToken && accessedAt) {
        const lastUsedAtDate = new Date(accessedAt);

        const expireAtDate = new Date();
        expireAtDate.setMinutes(expireAtDate.getMinutes() - InviolaSessionLifetime);

        if (lastUsedAtDate >= expireAtDate) {
          await dispatch(renewCustomerSession(rememberToken));
        } else {
          dispatch(expireCustomerSession());
        }
      } else {
        dispatch(expireCustomerSession());
      }
    }

    return getStateSession(type, getState());
  }
);

const renewSyncSession = (): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    try {
      const syncData = await sync();

      dispatch(setSyncDone(syncData));
    } catch (error) {
      dispatch(handleInviolaError(error, InviolaAPIError.SyncError));
    }
  }
);

const renewCustomerSession = (token: string): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    try {
      const syncData = await sync();
      const syncSessionId = syncData.data.session;

      const renewedCustomer = await loginWithToken(syncSessionId, token);

      dispatch(setCustomerUpdated(renewedCustomer));

      // syncSessionId was converted by loginWithToken to a customerSessionId
      // and must be reused from now on
      dispatch(setCustomerSession(syncSessionId));
    } catch (error) {
      dispatch(expireCustomerSession());
    }
  }
);

const expireCustomerSession = (): Action => (
  (dispatch): void => {
    dispatch(resetCustomer());
    dispatch(setError({
      code: InviolaHttpErrors.SESSION_EXPIRED,
      type: InviolaAppError.AuthError,
    }));
  }
);
