import React from 'react';
import styled from 'styled-components';

interface CloseButtonProps {
  onClick: (event?: React.MouseEvent) => void;
  colorInvert?: boolean;
  size?: number;
}

const CloseButton = ({ onClick, size = 35 }: CloseButtonProps): React.ReactElement => (
  <Container onClick={onClick} size={size}>
    <InnerContainer>
      <Line1 />
      <Line2 />
    </InnerContainer>
  </Container>
);

const Line = styled.div`
  height: 100%;
  border-radius: 10px;
  transition: all 0.3s ease;
`;

const Container = styled.button<{ size: number; }>`
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: ${(props): string => props.theme.color.red};
  color: ${(props): string => props.theme.color.red};
  width: ${({ size }): string => `${size}px`};
  height: ${({ size }): string => `${size}px`};
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  & ${Line} {
      background-color: ${(props): string => props.theme.color.whiteSmoke};
  }

  &:hover {
    background-color: ${(props): string => props.theme.color.whiteSmoke};
    color: ${(props): string => props.theme.color.whiteSmoke};
    transition: all 0.3s ease;

    ${Line} {
      background-color: ${(props): string => props.theme.color.red};
      transition: all 0.3s ease;
    }
  }
`;

const InnerContainer = styled.div`
  height: 3px;
  width: 65%;
  position: relative;
`;

const Line1 = styled(Line)`
  transform: rotate(45deg);
`;

const Line2 = styled(Line)`
  position: absolute;
  top: 0;
  width: 100%;
  transform: rotate(-45deg);
`;

export default CloseButton;
