import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { MediaPlayer, MediaPlayerClass } from 'dashjs';

import { pauseMatchLiveAudio, resetMatchLiveAudio } from '@app/store/actions/matchActions';
import { useLiveAudio } from '@app/components/LiveAudio/LiveAudioHooks';
import { Devices } from '@app/styles';

import { LiveAudioControls, LiveAudioErrorModal } from './components';

const LiveAudio = (): React.ReactElement | null => {
  const liveAudio = useLiveAudio();
  const dispatch = useDispatch();
  const [audio] = useState(new Audio());
  const [dashPlayer, setDashPlayer] = useState<MediaPlayerClass | null>(null);
  const [isErrorModalVisible, setErrorModalVisible] = useState<boolean>(false);
  const [canPlay, setCanPlay] = useState<boolean>(false);
  const src = liveAudio?.paused ? '/images/liveaudio/fab_audio.png' : '/images/liveaudio/fab_audio_400.gif';

  useEffect(() => {
    const { audioStreamUrl } = liveAudio ?? {};
    const destroyDash = () => {
      if (dashPlayer) {
        dashPlayer.pause();
        dashPlayer.destroy();
        setDashPlayer(null);
      }
    };
    const canPlayListener = () => {
      setCanPlay(true);
    };
    const errorListener = () => {
      const { audioStreamUrl, additionalAudioStreamUrl } = liveAudio ?? {};
      if (audio.src === audioStreamUrl && additionalAudioStreamUrl && additionalAudioStreamUrl !== audioStreamUrl) {
        const dash = MediaPlayer().create();
        dash.initialize(audio);
        dash.attachSource(additionalAudioStreamUrl);
        dash.play();
        dash.on('streamTeardownComplete', () => {
          audio.removeEventListener('error', errorListener);
          audio.removeEventListener('canplay', canPlayListener);
        });
        setDashPlayer(dash);
        // audio.src = additionalAudioStreamUrl;
      } else if (audio.src === additionalAudioStreamUrl && audio.error?.code) {
        destroyDash();
        audio.removeEventListener('error', errorListener);
        audio.removeEventListener('canplay', canPlayListener);
        setErrorModalVisible(true);
      }
    };

    if (audioStreamUrl) {
      audio.addEventListener('error', errorListener);
      audio.addEventListener('canplay', canPlayListener);
      audio.src = audioStreamUrl;
      audio.autoplay = true;
    } else {
      destroyDash();
      audio.src = '';
    }
  }, [liveAudio?.audioStreamUrl]);

  useEffect(() => {
    if (!liveAudio || liveAudio.paused) {
      audio.pause();
    } else {
      // eslint-disable-next-line no-void
      void audio.play().then();
    }
  }, [liveAudio?.paused]);

  const onPlay = () => {
    dispatch(pauseMatchLiveAudio(false));
  };
  const onPause = () => {
    dispatch(pauseMatchLiveAudio(true));
  };
  const onChangeVolume = (value: number) => {
    audio.volume = value / 100;
  };
  const onStop = () => {
    dispatch(resetMatchLiveAudio());
  };
  const onCloseErrorModal = () => {
    setErrorModalVisible(false);
    dispatch(resetMatchLiveAudio());
  };

  return (
    <>
      {liveAudio && canPlay && (
      <LiveAudioContainer>
        <LiveAudioBlock>
          <img src={src} alt="fab_audio" />
          <LiveAudioControls
            volume={audio.volume * 100}
            paused={liveAudio.paused}
            onPause={onPause}
            onPlay={onPlay}
            onStop={onStop}
            onChangeVolume={onChangeVolume}
          />
        </LiveAudioBlock>
      </LiveAudioContainer>
      )}
      <LiveAudioErrorModal
        open={isErrorModalVisible}
        onClose={onCloseErrorModal}
      />
    </>
  );
};

const LiveAudioBlock = styled.div`
  position: relative;
  cursor: pointer;
  width: 56px;
  height: 56px;
  background-color: ${(props): string => props.theme.color.whiteSmoke};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.49);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 39px;
  }

  &:hover > div {
    display: block;
  }
`;

const LiveAudioContainer = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 10px;
  right: 10px;

  @media ${Devices.largeMobile} {
    bottom: 48px;
    right: 37px;
  }
`;

export default LiveAudio;
