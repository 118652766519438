export enum HttpServerErrorCodeEnum {
    InternalServerError = 500, // A generic error message, given when an unexpected condition was encountered and no more specific message is suitable.
	NotImplemented = 501, // The server either does not recognize the request method, or it lacks the ability to fulfil the request. Usually this implies future availability (e.g., a new feature of a web-service API).
	BadGateway = 502, // The server was acting as a gateway or proxy and received an invalid response from the upstream server.
	ServiceUnavailable = 503, // The server cannot handle the request (because it is overloaded or down for maintenance). Generally, this is a temporary state.
	GatewayTimeout = 504, // The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.
	HTTPVersionNotSupported = 505, // The server does not support the HTTP protocol version used in the request.
	VariantAlsoNegotiates = 506, // (RFC 2295) Transparent content negotiation for the request results in a circular reference.
	InsufficientStorage = 507, // (WebDAV; RFC 4918) The server is unable to store the representation needed to complete the request.
	LoopDetected = 508, // (WebDAV; RFC 5842) The server detected an infinite loop while processing the request (sent instead of 208 Already Reported).
	NotExtended = 510, // (RFC 2774) Further extensions to the request are required for the server to fulfil it.
	NetworkAuthenticationRequired = 511, // (RFC 6585) The client needs to authenticate to gain network access. Intended for use by intercepting proxies used to control access to the network (e.g., "captive portals" used to require agreement to Terms of Service before granting full Internet access via a Wi-Fi hotspot).
}