import React from 'react';
import { useIntl } from 'react-intl';
import Carousel, { ArrowProps } from 'react-multi-carousel';
import styled from 'styled-components';

import { Devices, Colors } from '@app/styles';
import { WebLineCarouselBackground, WebTemplateLineCarousel } from '@app/types/webTemplateTypes';
import { Loader } from '@app/components';
import { HomeTitleLink } from '@app/components/Home/components';
import { WebTemplateSelect } from '@app/components/WebTemplates';
import { useMaxWidthOfPartialVisibility } from '@app/components/Carousel/CarouselHooks';
import { getWebLineCarouselConfiguration } from '@app/helpers/webTemplateHelpers';

import { ContainerLarge } from '@app/components/WebTemplates/components';
import { useLineCarouselDataList, useLineCarouselDataListDownload } from '../WebTemplateElementHooks';

interface WebLineCarouselElementProps {
  data: WebTemplateLineCarousel;
}

const WebLineCarouselElement = ({ data }: WebLineCarouselElementProps): React.ReactElement | null => {
  const dataList = useLineCarouselDataList(data.carouselType);
  const configuration = getWebLineCarouselConfiguration(data.carouselType);
  const maxWidthOfPartialVisibility = useMaxWidthOfPartialVisibility(configuration);

  useLineCarouselDataListDownload(data.carouselType);

  return dataList && dataList.length === 0 ? null : (
    <Container background={data.background}>
      <ContainerLarge>
        <HomeTitleLink title={data.title} link={data.link} Component={Title} background={data.background} showSeeMore />
        <GridWrapper>
          {dataList ? (
            <Carousel
              swipeable
              partialVisible
              responsive={configuration}
              infinite={false}
              keyBoardControl={false}
              ssr
              customLeftArrow={<CustomLeftArrow mediaQueryMinWidth={maxWidthOfPartialVisibility} />}
              customRightArrow={<CustomRightArrow mediaQueryMinWidth={maxWidthOfPartialVisibility} />}
            >
              {dataList.map((item) => (
                <WebTemplateSelect data={item} key={item?.id} />
              ))}
            </Carousel>
          ) : (
            <Loader color={data.background === WebLineCarouselBackground.Purple ? Colors.white : Colors.purple} />
          )}
        </GridWrapper>
      </ContainerLarge>
    </Container>
  );
};

const ArrowContainer = styled.div`
  cursor: pointer;
  user-select: none;
  z-index: 1;
  position: absolute;

  & > img {
    width: 30px;
    height: 30px;
  }
`;

const ArrowLeftContainer = styled(ArrowContainer)<{mediaQueryMinWidth: number}>`
  left: 0;
  transform: rotate(180deg);

  @media ${({ mediaQueryMinWidth }): string => `(min-width: ${mediaQueryMinWidth}px)`} {
    left: 10px;
  }
`;

const ArrowRightContainer = styled(ArrowContainer)<{mediaQueryMinWidth: number}>`
  right: 0;

  @media ${({ mediaQueryMinWidth }): string => `(min-width: ${mediaQueryMinWidth}px)`} {
    right: 10px;
  }
`;

interface CustomArrowProps extends ArrowProps {
  mediaQueryMinWidth: number;
}

const CustomLeftArrow: React.FC<CustomArrowProps> = ({ onClick, mediaQueryMinWidth }) => {
  const { formatMessage } = useIntl();

  return (
    <ArrowLeftContainer mediaQueryMinWidth={mediaQueryMinWidth} onClick={(): void => onClick && onClick()}>
      <img src="/images/gallery-arrow.svg" alt={formatMessage({ id: 'carousel.leftArrow.alt' })} />
    </ArrowLeftContainer>
  );
};

const CustomRightArrow: React.FC<CustomArrowProps> = ({ onClick, mediaQueryMinWidth }) => {
  const { formatMessage } = useIntl();

  return (
    <ArrowRightContainer mediaQueryMinWidth={mediaQueryMinWidth} onClick={(): void => onClick && onClick()}>
      <img src="/images/gallery-arrow.svg" alt={formatMessage({ id: 'carousel.rightArrow.alt' })} />
    </ArrowRightContainer>
  );
};

const Container = styled.div<{ background: WebLineCarouselBackground }>`
  background-color: ${({ background, theme }): string => (background === WebLineCarouselBackground.Purple ? theme.color.purple : 'transparent')};
  padding: 15px 20px 45px;
  margin-top: 30px;

  @media ${Devices.desktopSmall} {
    padding: 30px 70px 60px;
    margin-top: 50px;
  }

  & .react-multi-carousel-item {
    padding: 0 10px;
  }
`;

const Title = styled.h1<{ background?: WebLineCarouselBackground }>`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 18px;
  font-weight: bold;
  line-height: 2.35;
  letter-spacing: -0.01px;
  color: ${({ background, theme }): string => (background === WebLineCarouselBackground.Transparent ? theme.color.purple : '#ffffff')};
  text-transform: uppercase;
  padding-left: 10px;
`;

const GridWrapper = styled.div`
  margin-top: 15px;
  overflow-x: auto;
  min-height: 200px;

  @media ${Devices.desktopSmall} {
    overflow: inherit;
  }
`;

export default WebLineCarouselElement;
