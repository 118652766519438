import React from 'react';
import styled from 'styled-components';

const CloseIcon = React.memo((): React.ReactElement => (
  <SvgIcon viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter id="7qul1l8ima">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
      </filter>
    </defs>
    <g transform="translate(-1191 -30)" filter="url(#7qul1l8ima)" fill="none" fillRule="evenodd">
      <path
        d="M1191.429 49.571c.285.286.571.429 1 .429.428 0 .714-.143 1-.429L1201 42l7.571 7.571c.286.286.715.429 1 .429.286 0 .715-.143 1-.429.572-.571.572-1.428 0-2L1203 40l7.571-7.571c.572-.572.572-1.429 0-2-.571-.572-1.428-.572-2 0L1201 38l-7.571-7.571c-.572-.572-1.429-.572-2 0-.572.571-.572 1.428 0 2L1199 40l-7.571 7.571c-.572.572-.572 1.429 0 2z"
        fill="#fff"
        fillRule="nonzero"
      />
    </g>
  </SvgIcon>
));

export default CloseIcon;

const SvgIcon = styled.svg`
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 1;
  transition: 0.1s all linear;

  &:hover {
    opacity: .69;
    transition: 0.1s all linear;
  }
`;
