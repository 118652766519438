import * as React from 'react';
import styled from 'styled-components';

import { ContainerLarge } from '@app/components/WebTemplates/components';
import { Devices } from '@app/styles';

interface LoaderElementParams {
  small?: boolean;
  color?: string;
}

interface LoaderParams extends LoaderElementParams {
  overflowMode?: boolean
}

const LoaderElement = ({ small = false, color }: LoaderElementParams): React.ReactElement => (
  <LoaderWrapper small={small} color={color}>
    <div />
    <div />
    <div />
    <div />
  </LoaderWrapper>
);

const Loader = ({ overflowMode = false, color, small }: LoaderParams): React.ReactElement => (
  overflowMode ? (
    <OverflowContainer>
      <LoaderElement small={small} color={color} />
    </OverflowContainer>
  ) : (
    <Container>
      <LoaderElement small={small} color={color} />
    </Container>
  )
);

const Container = styled(ContainerLarge)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  height: 100%;

  @media ${Devices.tablet} {
    padding: 200px 0;
  }
`;

const OverflowContainer = styled(Container)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;

  background-color: transparent;
  cursor: not-allowed;
  z-index: 99999;

  &:hover {
    cursor: not-allowed;
  }

  @media ${Devices.tablet} {
    padding: 0;
  }
`;

const LoaderWrapper = styled.div<{ small: boolean; color?: string }>`
  display: inline-block;
  position: relative;
  width: ${({ small }): string => (small ? '40px' : '80px')};
  height: ${({ small }): string => (small ? '40px' : '80px')};

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ small }): string => (small ? '32px' : '64px')};
    height: ${({ small }): string => (small ? '32px' : '64px')};
    border: ${({ small }): string => (small ? '3px' : '7px')} solid #fff;
    margin: 8px;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color, theme }): string => (color ?? theme.color.purple)} transparent transparent transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }

  & div:nth-child(2) {
    animation-delay: -0.3s;
  }

  & div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Loader;
