import pull from 'lodash/pull';

import { ActionWithPromiseReturn } from '@app/types/actionTypes';
import { LanguageType } from '@app/types/localizationTypes';
import AppState from '@app/types/appStateTypes';
import { PageError } from '@app/types/errorTypes';

import { waitForConfigurationDownload } from '@app/store/actions/configurationActions';

import {
  PromotionsActionTypes,
  ResetLatestPromotions,
  ResetPromotion,
  ResetPromotionsList,
  SetError,
  SetLatestPromotions,
  SetPromotion,
  SetPromotionsList,
  SetPromotionsLoadingState,
} from '@app/store/actionTypes/promotionsActionTypes';
import { LocalizedPromotionItem, Promotion, PromotionList } from '@app/types/promotionsTypes';
import { getPromotionDetails, getPromotionList } from '@app/services/kentico/promotionsService';
import { RouteParams } from '@app/types/routerTypes';
import { AppLanguages } from '@app/constants/localizationConstants';

function setPromotionsLoadingState(state: boolean): SetPromotionsLoadingState {
  return {
    type: PromotionsActionTypes.SET_PROMOTIONS_LOADING_STATE,
    payload: state,
  };
}

function setPromotionsList(promotions: PromotionList): SetPromotionsList {
  return {
    type: PromotionsActionTypes.SET_PROMOTIONS_LIST,
    payload: promotions,
  };
}

export const resetPromotionsList = (): ResetPromotionsList => ({ type: PromotionsActionTypes.RESET_PROMOTIONS_LIST });

export const setLatestPromotions = (latestPromotions: Promotion[]): SetLatestPromotions => ({
  type: PromotionsActionTypes.SET_LATEST_PROMOTIONS,
  payload: latestPromotions,
});

export const resetLatestPromotions = (): ResetLatestPromotions => ({
  type: PromotionsActionTypes.RESET_LATEST_PROMOTIONS,
});

export const setPromotion = (promotion: LocalizedPromotionItem): SetPromotion => ({
  type: PromotionsActionTypes.SET_PROMOTION,
  payload: promotion,
});

export const resetPromotion = (): ResetPromotion => ({ type: PromotionsActionTypes.RESET_PROMOTION });

export const setError = (error: PageError): SetError => ({
  type: PromotionsActionTypes.SET_ERROR,
  payload: error,
});

interface GetPromotions {
  language: LanguageType;
  limit?: number;
}

export const getPromotions = ({
  limit = 6,
  language,
}: GetPromotions): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    dispatch(setPromotionsLoadingState(true));
    await waitForConfigurationDownload();

    const state: AppState = getState();
    const numberDownloadedItems = state.promotions.list.items.length;

    try {
      const list = await getPromotionList({
        limit,
        skip: numberDownloadedItems,
        language,
      });
      dispatch(setPromotionsList(list));
    } catch (e) {
      console.error('Error on fetching promotions', e);
      dispatch(setError(PageError.Sorry));
    } finally {
      dispatch(setPromotionsLoadingState(false));
    }
  }
);

type GetPromotionByUrlSlug = RouteParams

export const getPromotionByUrlSlug = ({
  thirdLevel = '', forthLevel = '', language,
}: GetPromotionByUrlSlug): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    try {
      dispatch(setPromotionsLoadingState(true));
      await waitForConfigurationDownload();
      const currentLangPromotion = await getPromotionDetails({
        publicationDate: thirdLevel,
        urlSlug: forthLevel,
        language,
      });

      if (!currentLangPromotion) {
        dispatch(setError(PageError.NotFound));
        return;
      }

      const restLanguages = pull([...AppLanguages], language);

      const promotions = await Promise.all(restLanguages.map((language) => (
        getPromotionDetails({
          id: currentLangPromotion.systemId,
          language,
        })
      )));

      const langToConfigMap = restLanguages.reduce((acc, lang, index) => {
        acc[lang] = promotions[index];
        return acc;
      }, {} as (LocalizedPromotionItem));
      langToConfigMap[language] = currentLangPromotion;

      dispatch(setPromotion(langToConfigMap));
    } catch (e) {
      console.error('Error on fetching news ', e);
      dispatch(setError(PageError.Sorry));
    } finally {
      dispatch(setPromotionsLoadingState(false));
    }
  }
);
