import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Devices, DevicesSize } from '@app/styles';
import { CommonButton } from '@app/components/Buttons';
import { Loader } from '@app/components';

import {
  useInviolaCardMovements,
  useInviolaCardMovementsSyncState,
  useInviolaCardMovementsDownload,
} from '../ProfilePageHooks';

const PointsTable = (): React.ReactElement | null => {
  const [size, setSize] = useState(6);
  const movements = useInviolaCardMovements();
  const syncInProgress = useInviolaCardMovementsSyncState();

  useInviolaCardMovementsDownload();

  return (
    <LoaderWrapper>
      {syncInProgress && (<Loader overflowMode />)}
      <Grid syncInProgress={!movements.length || syncInProgress} isEmpty={!movements.length && !syncInProgress}>
        <GridHeader>
          <div><FormattedMessage id="inviola.profile.pointsTable.date" /></div>
          <div><FormattedMessage id="inviola.profile.pointsTable.pointsAdded" /></div>
          <div><FormattedMessage id="inviola.profile.pointsTable.pointsDrawned" /></div>
        </GridHeader>
        {movements.length ? (
          <>
            {movements.slice(0, size).map((movement) => (
              <GridRow key={movement.id}>
                <div>{movement.localTime}</div>
                <div>{movement.chargedPoints ?? '-'}</div>
                <div>{movement.dischargedPoints ?? '-'}</div>
              </GridRow>
            ))}
            {size < movements?.length && (
              <ShowMoreButton onClick={() => setSize(size + 6)}>
                <FormattedMessage id="buttons.loading" />
              </ShowMoreButton>
            )}
          </>
        ) : (
          <>
            {[...Array(6).keys()].map((key) => (
              <GridRow key={key}>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
              </GridRow>
            ))}
            <ShowMoreButton disabled>
              <FormattedMessage id="buttons.loading" />
            </ShowMoreButton>
          </>
        )}
      </Grid>
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const Grid = styled.div<{ syncInProgress: boolean; isEmpty: boolean }>`
  display: ${({ isEmpty }): string => (isEmpty ? 'none' : 'block')};
  opacity: ${({ syncInProgress }): number => (syncInProgress ? 0.5 : 1)};
  transition: opacity 0.2s ease;
  z-index: 10;
`;

const GridItem = styled.div`
  display: grid;
  grid-template-columns: 50% repeat(auto-fill, 25%);
  font-family: ${(props): string => props.theme.fontFamily.arialNova};

  @media ${Devices.tablet} and (max-width: ${DevicesSize.desktop}px){
    grid-template-columns: 60% repeat(2, 15%);
  }
`;

const GridHeader = styled(GridItem)`
  text-align: left;
  font-size: 12px;
  margin-bottom: 15px;
`;

const GridRow = styled(GridItem)`
  background-color: #fff;
  margin-bottom: 5px;
  padding: 15px;

  font-size: 12px;

  div:first-child {
    font-family: ${(props): string => props.theme.fontFamily.commutersSans};
    font-weight: bold;
  }

  @media ${Devices.tablet} {
    font-size: 1rem;
  }
`;

const ShowMoreButton = styled(CommonButton)`
  border-color: ${(props): string => props.theme.color.red};
  color: ${(props): string => props.theme.color.red};
  height: 35px;
  margin: 20px auto 0;
  width: auto;
  padding: 0 25px;

  &:hover {
    border-color: ${(props): string => props.theme.color.red};
    background-color: ${(props): string => props.theme.color.red};
    color: white;
  }
`;

export default PointsTable;
