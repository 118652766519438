import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { WebPageVideoSize, WebTemplatePageVideo } from '@app/types/webTemplateTypes';
import { Video } from '@app/pages/NewsDetails/Types';

import { ThroneVideo } from '@app/types/configurationTypes';
import { ContainerLarge, ContainerMedium } from '../components';

interface WebPageVideoElementProps {
  data: WebTemplatePageVideo;
}

interface NoContainerProps {
  children: ReactNode
}
const NoContainer = (props: NoContainerProps) => <div>{props.children}</div>;

const WebPageVideoElement: React.FC<WebPageVideoElementProps> = ({ data }: WebPageVideoElementProps) => {
  let Container;

  switch (data?.size) {
    case WebPageVideoSize.Big:
      Container = ContainerLarge;
      break;
    case WebPageVideoSize.Medium:
      Container = ContainerMedium;
      break;
    default:
      Container = NoContainer;
  }
  return (
    data?.video?.url ? (
      <Container>
        <ContainerWithPadding>
          <Video video={data.video as ThroneVideo} />
        </ContainerWithPadding>
      </Container>
    ) : null
  );
};

const ContainerWithPadding = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 10px;

  & > img {
    width: 100%;
  }
`;

export default WebPageVideoElement;
