import { getResponsiveImageByIdConfig } from '@app/helpers/imageHelper';
import { getImageUrl, getThumbnailUrl } from '@app/helpers/thronHelper';
import { ImageConfiguration, ImageWidthMap } from '@app/types/imageTypes';

export function useThronImages(
  thronId: string | undefined, width: number, height: number, scaleMode: string,
): [string, string] | void {
  if (!thronId) {
    return;
  }

  const thumbSrc = getThumbnailUrl({
    id: thronId, width: width ?? 0, height: height ?? 300, scaleMode,
  });

  const imageSrc = getImageUrl({
    id: thronId, width: width * 2, height: height * 2, scaleMode,
  });

  return [thumbSrc, imageSrc];
}

export function useResponsiveImages(
  thronId: string, imageWidthMap: ImageWidthMap | undefined, defaultImageWidthMap: ImageWidthMap, scaleMode: 'auto' | 'none',
): [ImageConfiguration, ImageConfiguration] {
  const screeWidthToImageWidthMap = imageWidthMap ?? defaultImageWidthMap;
  return [
    getResponsiveImageByIdConfig({ id: thronId, screeWidthToImageWidthMap, scaleMode }),
    getResponsiveImageByIdConfig({
      id: thronId, screeWidthToImageWidthMap, scaleMode, getterFn: getThumbnailUrl,
    }),
  ];
}
