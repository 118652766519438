import styled from 'styled-components';
import React from 'react';

import { CommonButton, CommonButtonLink } from '@app/components/Buttons/CommonButton';
import { LocalizedLinkProps } from '@app/components/Localization/LocalizedLink';

type ColorizedButtonProps = React.PropsWithChildren<{
  color: string;
  borderColor?: string;
  invertBackgroundColor?: string;
  invertColor?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}>

const Button = (props: ColorizedButtonProps): string => `
  width: 100%;
  border-radius: 2px;
  border: 2px solid ${props.borderColor ?? props.color};
  background-color: ${props.color};
  color: ${props.borderColor ?? '#ffffff'};

  & svg {
    margin-right: 9px;
  }

  & path {
    transition: fill 0.3s ease-in, stroke 0.3s ease-in;
    fill: ${props.borderColor ?? '#ffffff'};
    stroke: ${props.borderColor ?? '#ffffff'};
  }

  @media (pointer: fine) {
    &:hover {
      background-color: ${props.invertBackgroundColor ?? 'transparent'};
      color: ${props.invertColor ?? props.color};
      border: 2px solid ${props.borderColor ?? props.color};

      & path {
        fill: ${props.color};
        stroke: ${props.color};
      }
    }
  }
`;

const ColorizedButtonWrapper = styled(CommonButton)<ColorizedButtonProps>`
  ${Button}
`;

export const ColorizedButton = (props: ColorizedButtonProps): React.ReactElement => (
  <ColorizedButtonWrapper {...props}>
    <h3>{props.children}</h3>
  </ColorizedButtonWrapper>
);

interface ColorizedButtonLinkProps extends ColorizedButtonProps, LocalizedLinkProps {
}

const ColorizedButtonLinkWrapper = styled(CommonButtonLink)<ColorizedButtonLinkProps>`
  ${Button}
`;

export const ColorizedButtonLink = (props: ColorizedButtonLinkProps): React.ReactElement => (
  <ColorizedButtonLinkWrapper {...props}>
    <h3>{props.children}</h3>
  </ColorizedButtonLinkWrapper>
);

interface ColorizedLinkProps extends ColorizedButtonProps {
  children?: JSX.Element[] | JSX.Element | string | null | undefined;
  onClick?: () => void;
  url: string;
}

export const ColorizedExternalLink = ({
  color,
  borderColor,
  invertBackgroundColor,
  invertColor,
  url,
  children,
  onClick,
}: ColorizedLinkProps): React.ReactElement => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    <ColorizedButton
      color={color}
      borderColor={borderColor}
      invertBackgroundColor={invertBackgroundColor}
      invertColor={invertColor}
      onClick={onClick}
    >
      {children}
    </ColorizedButton>
  </a>
);
