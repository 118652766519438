import cloneDeep from 'lodash/cloneDeep';
import { WebTemplateActions, WebTemplateActionTypes } from '@app/store/actionTypes/webTemplateActionTypes';
import { ElementWebTemplateData } from '@app/types/webTemplateTypes';
import { PageError } from '@app/types/errorTypes';

export type WebTemplateState = {
  templates: ElementWebTemplateData;
  dataList: ElementWebTemplateData;
  isLoading: boolean;
  error: PageError | null;
};

const initialState: WebTemplateState = {
  templates: {},
  dataList: {},
  isLoading: true,
  error: null,
};

export default (
  state: WebTemplateState = cloneDeep(initialState),
  action: WebTemplateActions,
): WebTemplateState => {
  switch (action.type) {
    case WebTemplateActionTypes.LOADING_WEB_TEMPLATE_DATA:
      return {
        ...state,
        isLoading: action.payload,
      };
    case WebTemplateActionTypes.SET_WEB_TEMPLATE_DATA: {
      const { templates } = action.payload;
      return {
        ...state,
        templates: {
          ...state.templates,
          [templates.id]: {
            ...state.templates[templates.id],
            ...templates.data,
          },
        },
      };
    }
    case WebTemplateActionTypes.RESET_WEB_TEMPLATE_DATA:
      return {
        ...initialState,
      };
    case WebTemplateActionTypes.SET_WEB_TEMPLATE_DATA_LIST: {
      const { templates } = action.payload;
      return {
        ...state,
        dataList: {
          ...state.dataList,
          [templates.type]: {
            ...state.templates[templates.type],
            ...templates.data,
          },
        },
      };
    }
    case WebTemplateActionTypes.RESET_WEB_TEMPLATE_DATA_LIST:
      return {
        ...initialState,
      };
    case WebTemplateActionTypes.SET_WEB_TEMPLATE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case WebTemplateActionTypes.RESET_WEB_TEMPLATE_ERROR:
      return {
        ...state,
        error: initialState.error,
      };
    default:
      return state;
  }
};
