import React from 'react';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { SearchCategories } from '@app/types/newsTypes';
import { useSearchPagePath, useSearchParams } from '@app/components/Header/components/SearchBar/SearchBarHooks';

const Categories = React.memo((): React.ReactElement => {
  const { query, category } = useSearchParams();
  const searchPagePath = useSearchPagePath();

  const dispatch = useDispatch();

  const onCategoryClick = (selectedCategory: SearchCategories): void => {
    dispatch(push(`${searchPagePath}?query=${encodeURI(query)}&category=${selectedCategory}`));
  };

  return (
    <Container>
      <Category
        isActive={category === SearchCategories.ALL}
        onClick={(): void => onCategoryClick(SearchCategories.ALL)}
      >
        <FormattedMessage id="news.search.all" />
      </Category>
      <Category
        isActive={category === SearchCategories.NEWS}
        onClick={(): void => onCategoryClick(SearchCategories.NEWS)}
      >
        <FormattedMessage id="news.search.news" />
      </Category>
      <Category
        isActive={category === SearchCategories.VIDEO}
        onClick={(): void => onCategoryClick(SearchCategories.VIDEO)}
      >
        <FormattedMessage id="news.search.videos" />
      </Category>
      <Category
        isActive={category === SearchCategories.PHOTO}
        onClick={(): void => onCategoryClick(SearchCategories.PHOTO)}
      >
        <FormattedMessage id="news.search.galleries" />
      </Category>
    </Container>
  );
});

export default Categories;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
`;

const Category = styled.span<{ isActive: boolean }>`
  opacity: ${({ isActive }): number => (isActive ? 1 : 0.2)};
  margin: 3px 14px 1px 0;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 14px;
  font-weight: ${({ isActive }): string => (isActive ? 'bold' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  transition: all 0.3s linear;
  cursor: pointer;
`;
