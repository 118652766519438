import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { EditorialContentTypes } from '@app/types/newsTypes';
import { WebHomeElementBackground, WebTemplateHomeElement } from '@app/types/webTemplateTypes';
import { useHomeDataList, useHomeDataListDownload } from '@app/components/Home/HomeHooks';

import { NewItem } from '@app/components/News';
import { HomeTitleLink } from '@app/components/Home/components';
import HomeStandings from '@app/components/Home/components/HomeStandings';
import {
  useActiveStandingLinkForCompetitionSeriaA, useStandingsMenuTitle,
} from '@app/pages/Season/Season/SeasonHooks';

interface WebHomeVideosElementProps {
  data: WebTemplateHomeElement;
}

const WebHomeNewsAndStandingsElement = ({ data }: WebHomeVideosElementProps): React.ReactElement | null => {
  useHomeDataListDownload(EditorialContentTypes.NEWS, data.category, 5);
  const dataList = useHomeDataList(EditorialContentTypes.NEWS, data.category);
  const firstRow = dataList?.slice(0, 2);
  const secondRow = dataList?.slice(2, 5);
  const { link, title, background } = data;
  const standingsTitle = useStandingsMenuTitle();
  const standingsLink = useActiveStandingLinkForCompetitionSeriaA();

  return (
    <ContainerWrapper background={background}>
      <Container>
        <HomeTitleLink title={title} link={link} Component={Title} background={background} showSeeMore />
        <GridWrapper>
          <RowsWrapper>
            <Rows>
              <FirstRow>
                {firstRow?.map((item) => (
                  <FirstRowItem key={item.id}>
                    <NewItem data={item} infoSize="medium" />
                  </FirstRowItem>
                ))}
              </FirstRow>
              <SecondRow>
                {secondRow?.map((item) => (
                  <SecondRowItem key={item.id}>
                    <NewItem data={item} />
                  </SecondRowItem>
                ))}
              </SecondRow>
            </Rows>
          </RowsWrapper>
        </GridWrapper>
      </Container>
      <StandingsContainer>
        <HomeTitleLink
          title={standingsTitle}
          link={standingsLink}
          Component={Title}
          background={background}
          showSeeMore
        />
        <GridWrapper>
          <HomeStandings />
        </GridWrapper>
      </StandingsContainer>
    </ContainerWrapper>
  );
};

const ContainerWrapper = styled.div<{ background: WebHomeElementBackground }>`
  background-color: ${({ background, theme }): string => (background === WebHomeElementBackground.Purple ? theme.color.purple : 'transparent')};
  background-image: ${({ background }): string => (background === WebHomeElementBackground.Purple ? 'url("/images/block-background.webp")' : 'transparent')};
  background-position: center center;
  background-size: cover;
  padding: 15px 20px 45px;;

  @media ${Devices.desktopSmall} {
    padding: 30px 0 60px;
    display: flex;
    justify-content: center;
  }
`;

const Container = styled.div`
  width: 100%;

  @media ${Devices.desktopSmall} {
    width: 770px;
    padding-right: 30px;
  }
`;

const StandingsContainer = styled(Container)`
  width: 100%;
  margin-top: 20px;

  @media ${Devices.desktopSmall} {
    width: 360px;
    margin-top: 0;
    padding-right: 0;
  }
`;

const Title = styled.h1<{ background?: WebHomeElementBackground }>`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 18px;
  font-weight: bold;
  line-height: 2.35;
  letter-spacing: -0.01px;
  color: ${({ background, theme }): string => (background === WebHomeElementBackground.Transparent ? theme.color.purple : '#ffffff')};
  text-transform: uppercase;
  padding-left: 10px;
`;

const GridWrapper = styled.div`
  margin-top: 15px;
  overflow-x: auto;

  @media ${Devices.desktopSmall} {
    overflow: inherit;
  }
`;

const RowsWrapper = styled.div`
  overflow-x: auto;

  @media ${Devices.desktopSmall} {
    overflow: inherit;
  }
`;

const Rows = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;

  @media ${Devices.desktopSmall} {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

const FirstRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 0 20px;

  @media ${Devices.desktopSmall} {
    display: flex;
    justify-content: center;
  }
`;

const FirstRowItem = styled.div`
  width: 300px;
  height: 170px;
  margin-right: 20px;

  @media ${Devices.desktopSmall} {
    width: 365px;
    height: 205px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const SecondRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;

  @media ${Devices.desktopSmall} {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }
`;

const SecondRowItem = styled.div`
  width: 300px;
  height: 170px;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }

  @media ${Devices.desktopSmall} {
    width: 234px;
    height: 132px;
    margin-right: 21px;
  }
`;

export default WebHomeNewsAndStandingsElement;
