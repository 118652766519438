import { IContentItemsContainer, ItemResponses } from '@kentico/kontent-delivery';
import { Promotion as KenticoPromotion } from '@app/services/kentico/types/promotion';
import { PromotionList } from '@app/types/promotionsTypes';
import { convertPromotion } from '@app/services/kentico/converters/webTemplateConverter';
import { linkedItems } from '@app/services/kentico/converters/newsConverters';

export const mapPromotions = (
  data: ItemResponses.ListContentItemsResponse<KenticoPromotion>,
): PromotionList => ({
  hasMoreData: Boolean(data?.pagination?.nextPage) ?? false,
  items: (data?.items ?? [])
    .map((item) => convertPromotion(item, item, data.linkedItems as IContentItemsContainer<linkedItems>)),
});
