import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { Button } from '@app/components/Inviola/InviolaComponents';
import { useInviolaPath } from '@app/components/Inviola/InviolaHooks';
import { setCustomerRedirectPath } from '@app/store/actions/inviolaActions';

interface PremiumAccessNotificationProps {
  onLogin?: () => void;
  onCancel: () => void;
  redirectPath: string | null;
}

const InviolaPremiumAccessNotification = ({
  onLogin, onCancel, redirectPath,
}: PremiumAccessNotificationProps): React.ReactElement => {
  const { authPath } = useInviolaPath();
  const dispatch = useDispatch();
  const history = useHistory();

  const onLoginClick = (): void => {
    dispatch(setCustomerRedirectPath(redirectPath));
    onLogin && onLogin();
    history.push(authPath);
  };

  return (
    <Container>
      <Title>
        <FormattedMessage id="premium.title" />
      </Title>
      <Description>
        <FormattedMessage id="premium.description" />
        <br />
        <FormattedMessage id="premium.ctaText" />
      </Description>
      <ButtonsContainer>
        <ButtonLogin onClick={onLoginClick}>
          <FormattedMessage id="inviola.login.title" />
        </ButtonLogin>
        <ButtonCancel onClick={(): void => onCancel && onCancel()}>
          <FormattedMessage id="inviola.profile.card.transfer.cancel" />
        </ButtonCancel>
      </ButtonsContainer>
    </Container>
  );
};

export default InviolaPremiumAccessNotification;

const Container = styled.div`
  background-color: ${(props): string => props.theme.color.whiteSmoke};
  margin: 10px;
  padding: 30px 20px;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  text-align: left;
  max-width: 600px;

  @media ${Devices.tablet} {
    padding: 40px 30px;
  }
`;

const Title = styled.h1`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
`;

const Description = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 16px;
  text-align: center;
  color: #000000;
  margin: 30px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${Devices.tablet} {
    flex-direction: row;
  }
`;

const ButtonLogin = styled(Button)`
  background: ${(props): string => props.theme.color.red};
  border: solid 2px ${(props): string => props.theme.color.red};
  color: #ffffff;

  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;

  width: 230px;
  margin: 0 0 20px;

  &:last-of-type {
    margin: 0;
  }

  &:not(:disabled):hover {
    color: ${(props): string => props.theme.color.red};
    background: transparent;
  }

  @media ${Devices.tablet} {
    font-size: 12px;
    padding: 13px;
    margin: 0 20px 0 0;

    &:last-of-type {
      margin: 0;
    }
  }
`;

const ButtonCancel = styled(ButtonLogin)`
  background: transparent;
  border: solid 2px ${(props): string => props.theme.color.red};
  color: ${(props): string => props.theme.color.red};

  &:not(:disabled):hover {
    color: #ffffff;
    background: ${(props): string => props.theme.color.red};
  }
`;
