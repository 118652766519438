import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { LineGraph } from '@app/components/Graphs/LineGraph';

interface ValueAndGraphInlineProps {
  value?: number | string;
  progress?: number;
  caption: string;
}

const ValueAndGraphInline = ({ value, progress = 0, caption }: ValueAndGraphInlineProps): React.ReactElement => (
  <Container>
    <Text>
      <Value>{value}</Value>
      <Caption><FormattedMessage id={caption} /></Caption>
    </Text>
    <LineGraph value={progress} width={125} />
  </Container>
);

const Container = styled.div`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 24px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
`;

const Value = styled.span`
  font-size: 15px;
  font-weight: bold;
  margin-right: 10px;
`;

const Caption = styled.span`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};;
  font-size: 10px;
  font-weight: 300;
  text-transform: uppercase;
`;

export default ValueAndGraphInline;
