import React from 'react';
import styled from 'styled-components';

import { EditorialContentTypes } from '@app/types/newsTypes';

import { getLatestNews, resetLatestNews } from '@app/store/actions/newsActions';

import { Metadata } from '@app/components';
import { NewsDetailsPageWrapper, NewsItemInfo } from '@app/components/News';
import {
  useLatestNews,
  useNewsDetailsPath,
  useNewsDetailsMultiLangRoute,
  useNewsDetailsPageValidation,
} from '@app/components/Hooks';
import { useAllNewsUrlParams } from '@app/components/Hooks/NewsHooks';

import NewsContent from './NewsContent';
import NewsTopElement from './NewsTopElement';
import { useDownloadNews, useNews, useNewsSeo } from './NewsDetailsHooks';

const NewsDetails: React.FC = () => {
  useDownloadNews();
  useNewsDetailsMultiLangRoute(EditorialContentTypes.NEWS);
  const news = useNews();
  const seo = useNewsSeo();
  const latestNews = useLatestNews({
    type: EditorialContentTypes.NEWS,
    requestLatestNews: getLatestNews,
    resetLatestNews,
  });
  const redirectPath = useNewsDetailsPath(news);
  const error = useNewsDetailsPageValidation(news);
  const headerLinksParams = useAllNewsUrlParams(EditorialContentTypes.NEWS);

  return (
    <NewsDetailsPageWrapper
      error={error}
      latestNews={latestNews}
      latestNewsHeaderLabelId="news.lastNews"
      headerLinksParams={headerLinksParams}
      isPremium={news?.isPremium}
      redirectPath={redirectPath}
    >
      {seo && <Metadata seo={seo} />}
      {
        news && (
          <>
            <NewsTopElement item={news.topElement} />
            <NewsItemInfo
              title={news.title}
              subtitle={news.subtitle}
              category={news.categoryCode}
              size="large"
              time={news.publicationDate}
              color="black"
              isPremium={news.isPremium}
              isDetails
            />
            <NewsContentContainer>
              <NewsContent content={news.content ?? []} />
            </NewsContentContainer>
          </>
        )
      }
    </NewsDetailsPageWrapper>
  );
};

const NewsContentContainer = styled.div`
  margin-top: 10px;
`;

export default NewsDetails;
