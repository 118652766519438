import { useEffect } from 'react';

import { GoogleMaps as GoogleMapsType } from '@app/types/googleTypes';
import { GOOGLE_MAPS_KEY } from '@app/constants/googleMapsConstants';

export const useGoogleMaps = (map: GoogleMapsType): void => {
  const renderGoogleMaps = (map: GoogleMapsType): void => {
    const { google } = window;
    const { zoom, position } = map;

    const mapElement = document.getElementById(`map.${map.position.lat}.${map.position.lat}`);
    /* eslint-disable  */
    // @ts-ignore
    const googleMap = new google.maps.Map(mapElement, { zoom, center: position });
    // @ts-ignore
    new google.maps.Marker({ position, map: googleMap });
    /* eslint-enable  */
  };

  const initGoogleMaps = (): void => {
    const sdkScript = document.createElement('script');
    sdkScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}`;
    sdkScript.type = 'text/javascript';
    sdkScript.onload = (): void => renderGoogleMaps(map);
    sdkScript.async = true;

    document.head.appendChild(sdkScript);
  };

  useEffect((): void => {
    if (window.google?.maps) {
      renderGoogleMaps(map);
    } else {
      initGoogleMaps();
    }
  }, [map]);
};
