import { kenticoClient } from '@app/services/kentico/kentico';
import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';

import { LanguageType } from '@app/types/localizationTypes';
import { Game } from '@app/types/matchTypes';
import { Game as KenticoGame } from './types/game';

import { convertGames } from './converters/gamesConverter';

export async function getGames(language: LanguageType): Promise<Game[]> {
  const lang = langCodeToKenticoLangMap[language] ?? langCodeToKenticoLangMap.it;
  const { items } = await kenticoClient().items<KenticoGame>().type('game')
    .languageParameter(lang)
    .equalsFilter('system.language', lang)
    .toPromise();

  return convertGames(items);
}
