import React from 'react';

import { WebTemplateData } from '@app/types/webTemplateTypes';
import { ContainerFullWidth } from './components';
import WebTemplateSelect from './WebTemplateSelect';

interface WebTemplateContainerProps {
  data: WebTemplateData[] | null;
}

const WebTemplateContainer = ({ data }: WebTemplateContainerProps): React.ReactElement => (
  <ContainerFullWidth>
    {data?.filter((data) => data !== null).map(
      (data) => (<WebTemplateSelect key={data?.id} data={data} />),
    )}
  </ContainerFullWidth>
);

export default WebTemplateContainer;
