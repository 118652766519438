import React, { useState } from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { InviolaCatalogItem } from '@app/types/inviolaTypes';

import { ButtonsContainer, ImageContainer, ItemImage, Name } from './CatalogItemComponents';
import RequestItemButton from './RequestItemButton';
import MoreInfoButton from './MoreInfoButton';
import FavoritesIcon from './FavoritesIcon';
import PointsIcon from './PointsIcon';

interface InviolaCatalogItemProps {
  item: InviolaCatalogItem;
  onRequestDone?: () => void;
}

const CatalogItem = ({ item, onRequestDone }: InviolaCatalogItemProps): React.ReactElement => {
  const [useImage, setUseImage] = useState<boolean>(true);

  return (
    <GridWrapper>
      <Item id={`${item.id}`}>
        <FavoritesIcon item={item} />
        <PointsIcon points={item.points} />
        <ImageContainer>
          {useImage ? (
            <ItemImageCover
              src={item.pathImageAbsolute}
              alt={item.name}
              onError={() => setUseImage(false)}
            />
          ) : (
            <ItemPreviewLogo>
              {item.name.charAt(0)}
            </ItemPreviewLogo>
          )}
        </ImageContainer>
        <ItemName><span>{item.name}</span></ItemName>
        <ButtonsContainer>
          <RequestItemButton item={item} onRequestDone={onRequestDone} />
          <MoreInfoButton item={item} />
        </ButtonsContainer>
      </Item>
    </GridWrapper>
  );
};

const GridWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 5px;
  flex: 0 0 100%;

  @media ${Devices.tablet} {
    flex: 0 0 50%;
  }
`;

const Item = styled(GridWrapper)`
  height: 100%;
  padding: 25px 40px 55px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${Devices.tabletLarge} {
    flex-direction: row;
    height: 175px;
  }
`;

const ItemName = styled(Name)`
  margin: 20px auto;
  flex: unset;
  text-align: center;

  @media ${Devices.tabletLarge} {
    flex: 1;
    text-align: left;
    padding-right: 40px;
    padding-left: 10px;
  }
`;

const ItemImageCover = styled(ItemImage)`
  border-radius: 5px;
`;

const ItemPreviewLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;

  border: 3px solid #e12319;
  border-radius: 50%;
  font-size: 50px;
  font-weight: normal;
  line-height: 40px;

  background-color: transparent;
  color: #e12319;

  margin: auto;
`;

export default CatalogItem;
