import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Devices } from '@app/styles';
import { LocalizedDate } from '@app/components/Localization';

interface NewsItemInfoProps {
  time: string;
  subtitle?: string;
  category?: string;
  title: string;
  color?: string;
  size: 'large' | 'medium' | 'small';
  photosNumber?: number;
  isPremium: boolean;
  isDetails?: boolean;
}

const NewsItemInfo: React.FC<NewsItemInfoProps> = ({
  time, title, subtitle, category, color, size, photosNumber, isPremium, isDetails = false,
}) => (
  <Container color={color ?? 'white'} isHighlighted={false}>
    <NewsTypeContainer>
      {category && (
        <NewsType size={size}>
          <FormattedMessage id={`news.taxonomy.${category}`} />
        </NewsType>
      )}
      {isPremium && (
        <NewsPremium size={size}>
          <FormattedMessage id="premium.tag" />
        </NewsPremium>
      )}
      {
        !!photosNumber && (
          <PhotoNumber color={color ?? 'white'}>
            <FormattedMessage
              id="gallery.photos"
              values={{ number: photosNumber }}
            />
          </PhotoNumber>
        )
      }
    </NewsTypeContainer>
    <Title size={size}>
      { title }
    </Title>
    {subtitle && (<Description dangerouslySetInnerHTML={{ __html: subtitle }} />)}
    <Time size={size} isBold={!isDetails}>
      <LocalizedDate date={time} />
    </Time>
  </Container>
);

interface ContainerProps {
  color: string;
  isHighlighted: boolean;
}

const Container = styled.div<ContainerProps>`
  color: ${({ color }): string => color};
  height: 100%;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-weight: bold;
`;

const NewsTypeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const NewsType = styled.span<{ size: 'large' | 'medium' | 'small' }>`
  background-color: ${(props): string => props.theme.color.purple};
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-weight: bold;
  padding: 5px 10px;
  display: inline-block;
  width: fit-content;
  text-transform: uppercase;

  margin-bottom: 8px;
  color: white;

  height: 20px;
  line-height: 12px;
  font-size: 12px;
`;

export const NewsPremium = styled(NewsType)`
  background-color: ${(props): string => props.theme.color.red};
  margin-left: 5px;
`;

const PhotoNumber = styled.span<{color: string}>`
  color: ${({ color }): string => color};
  display: inline-block;
  font-size: 13px;
  letter-spacing: 0.36px;
  margin-left: 10px;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-weight: normal;
`;

const Time = styled.div<{ size: 'large' | 'medium' | 'small'; isBold: boolean }>`
  opacity: 0.3;
  letter-spacing: ${({ size }): number => {
    switch (size) {
      case 'large':
        return 0.33;
      case 'medium':
        return 0.28;
      default:
        return 0.28;
    }
  }};
  font-size: 12px;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-weight: ${({ isBold }): string => (isBold ? 'bold' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;

  @media ${Devices.desktopSmall} {
    /* eslint-enable indent */
    letter-spacing: ${({ size }): number => {
    switch (size) {
      case 'large':
        return 0.41;
      case 'medium':
        return 0.28;
      default:
        return 0.28;
    }
  }};
    font-size: ${({ size }): string => {
    switch (size) {
      case 'large':
        return '15px';
      case 'medium':
        return '16px';
      default:
        return '12px';
    }
  }};
  }
`;

const Title = styled.h3<{ size: 'large' | 'medium' | 'small' }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-transform: uppercase;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 4px;
  white-space: normal;
  font-size: ${({ size }): string => {
    switch (size) {
      case 'large':
        return '26px';
      default:
        return '14px';
    }
  }};
  line-height: ${({ size }): string => {
    switch (size) {
      case 'large':
        return '26px';
      default:
        return '14px';
    }
  }};

  @media ${Devices.desktopSmall} {
    font-size: ${({ size }): string => {
    /* eslint-disable indent */
      switch (size) {
        case 'large':
          return '26px';
        case 'medium':
          return '20px';
        default:
          return '14px';
      }
    }};
    line-height: ${({ size }): string => {
      switch (size) {
        case 'large':
          return '26px';
        case 'medium':
          return '20px';
        default:
          return '14px';
      }
    }};
  }
`;
/* eslint-enable indent */

const Description = styled.p`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.36px;

  b, strong {
    font-weight: bold;
  }
`;

export default NewsItemInfo;
