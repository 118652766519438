import * as React from 'react';
import { Link } from 'react-router-dom';

import { NavigationLinkParams } from '@app/types/routerTypes';
import { localizeRouteKey } from '@app/helpers/localizationHelper';
import { useLanguage } from '@app/components/Hooks/LocalizationHooks';

export interface LocalizedLinkProps extends NavigationLinkParams {
  className?: string; // passed by styled component automatically
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isNotFollowed?: boolean;
  isTab?: boolean;
}

const LocalizedLink = ({
  pathKey, pathParams, children, className, onClick, isNotFollowed, isTab = false,
}: React.PropsWithChildren<LocalizedLinkProps>): React.ReactElement => {
  const language = useLanguage();

  return (
    <Link
      to={{
        pathname: localizeRouteKey({ pathKey, pathParams }, language),
        state: { params: { isTab } },
      }}
      onClick={onClick}
      className={className}
      rel={isNotFollowed ? 'nofollow' : ''}
    >
      { children }
    </Link>
  );
};

export default LocalizedLink;
