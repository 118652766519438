import { useState, useEffect, useCallback } from 'react';
import screenfull, { Screenfull } from 'screenfull';
import { useLanguage } from '@app/components/Hooks';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales';
import { RemoteSubtitles, VideoControlsHook, VideoErrorMessages, VideoFullScreenHook } from '@app/types/videoPlayerTypes';
import { HTMLMediaControls } from 'react-use/lib/util/createHTMLMediaHook';
import { getRemoteSubtitles } from '@app/helpers/subtitlesHelper';
import { LanguageType } from '@app/types/localizationTypes';
import { useDispatch } from 'react-redux';
import { pauseMatchLiveAudio } from '@app/store/actions/matchActions';

export function useVideoErrorMessages(): VideoErrorMessages {
  const locale = useLanguage();

  return {
    invalidVideo: Translations[locale]['videoPlayer.errors.invalidVideo'] ?? '',
    invalidBrowser: Translations[locale]['videoPlayer.errors.invalidBrowser'] ?? '',
  };
}

export function useVideoControls(controls: HTMLMediaControls): VideoControlsHook {
  const dispatch = useDispatch();
  const onPlay = () => {
    dispatch(pauseMatchLiveAudio(true));
    // eslint-disable-next-line no-void
    void controls.play();
  };
  const onPause = controls.pause;
  const onMute = controls.mute;
  const onUnMute = controls.unmute;
  const onSeek = controls.seek;

  return { onPlay, onPause, onMute, onUnMute, onSeek };
}

interface FullScreenProps {
  target: Element | null
  active: boolean;
}

export function useFullScreen({ target, active = true }: FullScreenProps): VideoFullScreenHook {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const screenFull = screenfull as Screenfull;
  const fullScreenEnabled = active && screenFull.isEnabled;

  useEffect(() => {
    if (fullScreenEnabled) {
      screenFull.on('change', updateFullScreenState);
    }

    return () => {
      if (fullScreenEnabled) {
        screenFull.off('change', updateFullScreenState);
      }
    };
  }, [fullScreenEnabled]);

  const updateFullScreenState = useCallback(() => {
    setIsFullScreen(screenFull.isFullscreen);
  }, [screenFull.isFullscreen]);

  const toggleFullScreen = useCallback(async (): Promise<void> => {
    if (fullScreenEnabled && target) {
      await screenFull.toggle(target);
      setIsFullScreen(screenFull.isFullscreen);
    }
  }, [target, fullScreenEnabled, screenFull.isFullscreen]);

  return { fullScreenEnabled, isFullScreen, setIsFullScreen, toggleFullScreen };
}

export const useRemoteSubtitles = (remoteSubtitles: RemoteSubtitles | undefined): [boolean, string] => {
  const [srcData, setSrcData] = useState('');
  const [subtitleLoaded, setSubtitleLoaded] = useState(false);
  const locale = useLanguage();

  useEffect(() => {
    const fetchData = async () => {
      if (!remoteSubtitles || !allowedRemoteSubtitles(remoteSubtitles, locale)) {
        return;
      }

      const result = await getRemoteSubtitles(remoteSubtitles.url, remoteSubtitles.format);

      if (result) {
        setSrcData(result);
        setSubtitleLoaded(true);
      }
    };

    // eslint-disable-next-line no-void
    void fetchData();
  }, [remoteSubtitles, locale]);

  return [subtitleLoaded, srcData];
};

const allowedRemoteSubtitles = (remoteSubtitles: RemoteSubtitles, currentLocale: LanguageType) => (
  remoteSubtitles.url && remoteSubtitles.lang === currentLocale
);

export const useOverlayAutoHide = (videoPaused: boolean, hideDelay = 3000): [boolean, () => void] => {
  const [overlayVisible, setOverlayVisible] = useState(true);

  const onOverlayHover = useCallback(() => {
    if (!overlayVisible) {
      setOverlayVisible(true);
    }
  }, [overlayVisible]);

  useEffect(() => {
    if (videoPaused) {
      setOverlayVisible(true);
      return;
    }

    if (!overlayVisible) {
      return;
    }

    const timer = setTimeout(() => {
      setOverlayVisible(false);
    }, hideDelay);

    return () => clearTimeout(timer);
  }, [overlayVisible, videoPaused]);

  return [overlayVisible, onOverlayHover];
};

export const useSubtitlesToggle = (
  videoPlayerRef: { current: HTMLAudioElement | null },
): [boolean, (
) => void] => {
  const [subtitlesVisible, onSubtitlesVisible] = useState(true);
  const onSubtitlesToggle = () => {
    onSubtitlesVisible(!subtitlesVisible);
  };

  useEffect((): void => {
    const tracks = videoPlayerRef.current?.textTracks;
    if (!tracks || tracks.length === 0) {
      return;
    }

    for (let i = 0; i < tracks.length; i++) {
      tracks[i].mode = subtitlesVisible ? 'showing' : 'hidden';
    }
  }, [subtitlesVisible]);

  return [subtitlesVisible, onSubtitlesToggle];
};
