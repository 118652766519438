import { ContentItem, Elements } from '@kentico/kontent-delivery';

import MenuItem from './menu_item';

export default class RelatedMenuItem extends ContentItem {
    public thirdLevel: Elements.LinkedItemsElement<MenuItem>;
    public seoMetadataSocialDescription: Elements.TextElement;
    public seoMetadataMetaRobots: Elements.MultipleChoiceElement;
    public topLevel: Elements.LinkedItemsElement<MenuItem>;
    public secondLevel: Elements.LinkedItemsElement<MenuItem>;
    public seoMetadataCanonicalTag: Elements.TextElement;
    public seoMetadataMetaDescription: Elements.TextElement;
    public title: Elements.TextElement;
    public seoMetadataSocialImage: Elements.CustomElement;
    public seoMetadataMetaTitle: Elements.TextElement;
    public seoMetadataSocialTitle: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'third_level') {
                    return 'thirdLevel';
                }
                if (elementName === 'seo_metadata__social_description') {
                    return 'seoMetadataSocialDescription';
                }
                if (elementName === 'seo_metadata__meta_robots') {
                    return 'seoMetadataMetaRobots';
                }
                if (elementName === 'top_level') {
                    return 'topLevel';
                }
                if (elementName === 'second_level') {
                    return 'secondLevel';
                }
                if (elementName === 'seo_metadata__canonical_tag') {
                    return 'seoMetadataCanonicalTag';
                }
                if (elementName === 'seo_metadata__meta_description') {
                    return 'seoMetadataMetaDescription';
                }
                if (elementName === 'seo_metadata__social_image') {
                    return 'seoMetadataSocialImage';
                }
                if (elementName === 'seo_metadata__meta_title') {
                    return 'seoMetadataMetaTitle';
                }
                if (elementName === 'seo_metadata__social_title') {
                    return 'seoMetadataSocialTitle';
                }
                return elementName;
            })
        });
    }
}
