import React from 'react';
import styled from 'styled-components';
import { FormattedNumber, FormattedMessage } from 'react-intl';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales/index';
import AppRoutes from '@app/constants/routesConstants';
import { Devices } from '@app/styles';
import { LocalizedDate } from '@app/components/Localization';
import { MatchCenterButton } from '@app/components/Buttons';
import { MatchResult } from '@app/types/matchTypes';
import { MatchStatusMap } from '@app/constants/matchConstants';
import { getMatchTeamName } from '@app/helpers/matchHelpers';
import { useLanguage } from '@app/components/Hooks';
import { useCompetitionsSelector } from '@app/pages/Season/Season/SeasonHooks';
import { useGameByOptaIdSelector } from '@app/pages/MatchCenter/MatchCenterHooks';
import MatchLiveAudioButton from '@app/pages/MatchCenter/components/MatchLiveAudioButton';
import {
  useMatchLiveSubscribe,
  useMatchResultsSelector,
  useMatchLiveResetOnUnmout,
} from './MatchDayHooks';

interface MatchDayProps {
  result: MatchResult;
  isHomePage?: boolean;
  isWidget?: boolean;
}

const MatchDay = ({ result, isWidget = false, isHomePage = false }: MatchDayProps): React.ReactElement => {
  const {
    gameId, gameOptaId, matchDay, matchDate, matchStatus, matchTime,
    isHalfTime, matchDateTimeConfirmed, competitionOptaId,
    teams: { first, second },
  } = useMatchResultsSelector(result);
  const language = useLanguage();
  const competitions = useCompetitionsSelector();
  const competitionName = Object.values(competitions ?? {})
    .find(({ optaId }) => optaId === competitionOptaId)?.name ?? '';
  const game = useGameByOptaIdSelector(gameOptaId);
  const date = new Date(matchDate);
  const today = new Date();
  const isMatchLive = matchStatus === MatchStatusMap.live;
  const isMatchFinished = matchStatus === MatchStatusMap.finished;
  const isMatchUpcoming = matchStatus === MatchStatusMap.upcoming;
  const isOneHourBeforeMatch = date.getTime() > today.getTime()
    && Math.round(Math.abs(date.getTime() - today.getTime()) / 36e5) <= 1;
  const isMatchCenterAvailable = !!game?.urlSlug && (gameId || isOneHourBeforeMatch || isMatchFinished || isMatchLive);
  const isMatchLiveAudio = isWidget && isMatchLive && !!game?.audioStreamUrl;

  useMatchLiveSubscribe(result);
  useMatchLiveResetOnUnmout(result);

  return (
    <Grid data-status={matchStatus} isWidget={isWidget}>
      <DateArea isFullScreen={!isHomePage}>
        <MatchDayNumber isFullScreen={!isHomePage} isWidget={isWidget}>
          {competitionName}
          &nbsp;&#8722;&nbsp;
          <FormattedMessage id="match.day" />
          &nbsp;
          {matchDay}
        </MatchDayNumber>
        <MatchDateContainer
          isFullScreen={!isHomePage}
          isWidget={isWidget}
          matchDateTimeConfirmed={matchDateTimeConfirmed}
        >
          <LocalizedDateWrapper>
            <LocalizedDate date={date} dateFormat="dd MMM yyyy" />
          </LocalizedDateWrapper>
          {matchDateTimeConfirmed && (
            <span>
              <FormattedNumber value={date.getHours()} minimumIntegerDigits={2} />
              :
              <FormattedNumber value={date.getMinutes()} minimumIntegerDigits={2} />
            </span>
          )}
        </MatchDateContainer>
      </DateArea>
      <FirstTeamArea isFullScreen={!isHomePage}>
        <TeamName isFullScreen={!isHomePage} isWidget={isWidget}>{getMatchTeamName(result.teams.first, true)}</TeamName>
        <Logo src={result.teams.first.logoUrl} alt={first.name} />
      </FirstTeamArea>
      <GoalsArea>
        {isMatchLive && (<Live isWidget={isWidget}><FormattedMessage id="match.live" /></Live>)}
        {isMatchUpcoming ? ('VS') : (
          <Score isWidget={isWidget}>
            {first.goals}
            <ScoreDivider isWidget={isWidget}>&nbsp;&#8722;&nbsp;</ScoreDivider>
            {second.goals}
          </Score>
        )}
        {isMatchLive && (
          <MatchTime>
            { isHalfTime ? <FormattedMessage id="match.halfTime" /> : matchTime }
          </MatchTime>
        )}
      </GoalsArea>
      <SecondTeamArea isFullScreen={!isHomePage}>
        <Logo src={result.teams.second.logoUrl} alt={second.name} />
        <TeamName isFullScreen={!isHomePage} isWidget={isWidget}>
          {getMatchTeamName(result.teams.second, true)}
        </TeamName>
      </SecondTeamArea>
      <ActionArea isWidget={isWidget}>
        {/** TODO:INTEGRATE:BUTTONS */}
        {isMatchCenterAvailable && (
          <MatchCenterButton
            pathKey={AppRoutes.CommonPage.path}
            pathParams={{
              topLevel: 'match-center',
              secondLevel: 'game',
              thirdLevel: game?.urlSlug ?? '',
              forthLevel: (Translations[language]?.['matchcenter.tabs.lineups'] ?? '').toLowerCase(),
              fifthLevel: '',
            }}
            inverse={isWidget}
          >
            <FormattedMessage id="match.center" />
          </MatchCenterButton>
        )}
        {game && isMatchLiveAudio && (
          <MatchLiveAudioButton game={game} isWidget />
        )}
      </ActionArea>
    </Grid>
  );
};

export default MatchDay;

const Grid = styled.div<{isWidget: boolean}>`
  display: grid;
  grid-template:
    'dateArea dateArea dateArea'
    'firstTeamArea goalsArea secondTeamArea'
    'firstTeamArea actionArea secondTeamArea';
  grid-template-columns: 1fr 130px 1fr;
  grid-template-rows: 50px 100px 70px;
  grid-gap: 10px;
  justify-items: stretch;
  align-items: center;
  padding: 20px;
  color: ${({ isWidget }): string => (isWidget ? 'white' : 'black')};
  background-color: ${({ isWidget, theme }): string => (isWidget ? theme.color.purple : 'white')};
  background-image: ${({ isWidget }): string => (isWidget ? 'url("/images/header-background-mobile.webp")' : 'transparent')};;
  background-size: cover;
  background-position: center center;
  margin-bottom: ${({ isWidget }): string => (isWidget ? '0' : '15px')};

  @media ${Devices.tabletLarge} {
    padding: ${({ isWidget }): string => (isWidget ? '20px 100px' : '20px')};
    grid-template: 'dateArea firstTeamArea goalsArea secondTeamArea actionArea';
    grid-template-columns: 200px 1fr 100px 1fr 140px;
    grid-template-rows: 120px;
    background-image: ${({ isWidget }): string => (isWidget ? 'url("/images/header-background.webp")' : 'transparent')};;
  }

  & div {
    width: 100%;
    height: 100%;
  }
`;

const DateArea = styled.div<{isFullScreen: boolean}>`
  display: grid;
  grid-area: dateArea;
  justify-items: center;
  align-content: center;
  text-transform: capitalize;

  ${({ isFullScreen }): string => (
    isFullScreen ? `
      @media ${Devices.tabletLarge} {
        justify-items: flex-start;
      }` : ''
  )}
`;

const FirstTeamArea = styled.div<{isFullScreen: boolean}>`
  display: flex;
  grid-area: firstTeamArea;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;
  text-align: center;

  ${({ isFullScreen }): string => (
    isFullScreen ? `
      @media ${Devices.tabletLarge} {
        flex-direction: row;
        text-align: right;
      }` : ''
  )}
`;

const SecondTeamArea = styled(FirstTeamArea)<{isFullScreen: boolean}>`
  grid-area: secondTeamArea;
  flex-direction: column;
  justify-content: flex-start;

  ${({ isFullScreen }): string => (
    isFullScreen ? `
      @media ${Devices.tabletLarge} {
      text-align: left;
    }` : ''
  )}
`;

const GoalsArea = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: goalsArea;
  align-items: center;
  justify-items: center;
  text-align: center;
  justify-content: space-around;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.39px;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
`;

const Score = styled.span<{isWidget: boolean;}>`
  font-size: 36px;
  font-weight: bold;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  color: ${({ isWidget, theme }): string => (isWidget ? 'white' : theme.color.lightBlack)};
  opacity: ${({ isWidget }): string => (isWidget ? '0.8' : '1')};
  line-height: 0.75;
`;

const ScoreDivider = styled(Score)`
  font-size: 18px;
  font-weight: 300;
`;

const Live = styled.span<{ isWidget?: boolean }>`
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  width: 60px;
  line-height: 20px;
  margin: 0 0 5px 0;
  color: white;
  text-transform: uppercase;
  border: ${({ isWidget }): string => (isWidget ? '1px solid white' : 'none')};
  background-color: ${(props): string => props.theme.color.red};
`;

const MatchTime = styled.span`
  display: inline-block;
  font-size: 17px;
  font-weight: 300;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  color: ${(props): string => props.theme.color.lightBlack};
  letter-spacing: normal;
  line-height: 1;
  margin-top: 5px;
`;

const ActionArea = styled.div<{ isWidget?: boolean }>`
  grid-area: actionArea;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > * {
    width: ${({ isWidget }): string => (isWidget ? '200px;' : '120px')};
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const MatchDayNumber = styled.span<{isFullScreen: boolean; isWidget?: boolean; }>`
  font-size: 14px;
  font-weight: normal;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  color: ${({ isWidget, theme }): string => (isWidget ? 'white' : theme.color.lightBlack)};
  opacity: ${({ isWidget }): string => (isWidget ? '0.8' : '1')};
  line-height: 1.31;
  letter-spacing: normal;
  margin-bottom: 15px;

  ${({ isFullScreen }): string => (
    isFullScreen ? `
      @media ${Devices.tabletLarge} {
      font-size: 15px;
    }` : ''
  )}
`;

const MatchDateContainer = styled.div<{isFullScreen: boolean; isWidget: boolean; matchDateTimeConfirmed: boolean}>`
  display: flex;
  flex-direction: ${(props): string => (props.matchDateTimeConfirmed ? 'row' : 'column')};
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-weight: bold;

  & > span {
    font-size: 14px;
    font-weight: normal;
    font-family: ${(props): string => props.theme.fontFamily.arialNova};
    color: ${({ isWidget, theme }): string => (isWidget ? 'white' : theme.color.lightBlack)};
    opacity: ${({ isWidget }): string => (isWidget ? '0.8' : '1')};
    margin: 0 5px;
  }

  @media ${Devices.tabletLarge} {
    align-items: flex-start;
    font-size: 18px;
  }

  ${({ isFullScreen }): string => (
    isFullScreen ? `
      @media ${Devices.tabletLarge} {
      flex-direction: column;
      text-align: left;

      & > span {
        font-size: 18px;
        margin: 0;
      }
    }` : ''
  )}
`;

const LocalizedDateWrapper = styled.div`
  display: inherit;
  width: auto!important;
  height: auto!important;

  & * {
    font-size: 14px;
    font-family: ${(props): string => props.theme.fontFamily.arialNova};
  }

  @media ${Devices.tabletLarge} {
    width: 100%;

    & * {
      font-size: 22px;
      font-weight: bold;
      font-family: ${(props): string => props.theme.fontFamily.commutersSans};
    }
  }
`;

const TeamName = styled.span<{isFullScreen: boolean; isWidget: boolean; }>`
  font-size: 13px;
  font-weight: bold;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  color: ${({ isWidget, theme }): string => (isWidget ? 'white' : theme.color.lightBlack)};
  opacity: ${({ isWidget }): string => (isWidget ? '0.8' : '1')};
  letter-spacing: normal;
  line-height: 1.06;
  text-transform: uppercase;
  margin-top: 15px;
  word-break: break-word;

   ${({ isFullScreen }): string => (
    isFullScreen ? `
      @media ${Devices.tabletLarge} {
      font-size: 28px;
      margin-top: 0;
    }` : ''
  )}
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  max-width: 58px;
  max-height: 58px;
  margin: 0 10px;
`;
