import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { WebCardCtaPosition, WebCardLayoutSize, WebTemplateCard, WebTemplateTypes } from '@app/types/webTemplateTypes';
import { findAndFormatMenuLinkProps } from '@app/helpers/menuHelpers';
import { useMenu } from '@app/components/Hooks';
import { BaseMenuItem } from '@app/types/configurationTypes';
import { LocalizedLink } from '@app/components/Localization';
import { ImageWidthMap } from '@app/types/imageTypes';
import { LocalizedLinkProps } from '@app/components/Localization/LocalizedLink';
import { ThronImage } from '@app/components/Image';
import { WebCtaButtonElement } from '@app/components/WebTemplates/elements/index';

type WebCardButtonBlockProps = React.PropsWithChildren<{
  layoutSize?: WebCardLayoutSize;
  index: number;
  show: boolean;
  link: LocalizedLinkProps;
  as: keyof JSX.IntrinsicElements | React.ComponentType<unknown>;
}>

const Container = ({
  show, layoutSize, index, link, as, children,
}: WebCardButtonBlockProps): React.ReactElement | null => {
  switch (layoutSize) {
    case WebCardLayoutSize.Banner:
    case WebCardLayoutSize.Large:
      return <ContainerLarge as={as} show={show} {...link}>{children}</ContainerLarge>;
    case WebCardLayoutSize.Medium:
      return (
        <ContainerMediumWrapper index={index} show={show}>
          <ContainerMedium as={as} {...link} show={show}>{children}</ContainerMedium>
        </ContainerMediumWrapper>
      );
    case WebCardLayoutSize.Small:
      return <ContainerSmall index={index} as={as} show={show} {...link}>{children}</ContainerSmall>;
    default:
      return null;
  }
};

const getImageWidthMap = (layoutSize?: WebCardLayoutSize): ImageWidthMap => {
  switch (layoutSize) {
    case WebCardLayoutSize.Banner:
      return {
        1025: { width: 920, height: 250 },
        0: { width: 360, height: 230 },
      };
    case WebCardLayoutSize.Large:
      return {
        1025: { width: 920, height: 450 },
        0: { width: 360, height: 230 },
      };
    case WebCardLayoutSize.Medium:
      return {
        1025: { width: 450, height: 290 },
        0: { width: 360, height: 230 },
      };
    case WebCardLayoutSize.Small:
      return {};
    default:
      return {};
  }
};

interface WebCardElementProps {
  data: WebTemplateCard;
  width?: number;
  height?: number;
}

const WebCardElement = ({ data }: WebCardElementProps): React.ReactElement => {
  const menu = useMenu();

  const layoutSize = data?.layoutSize ?? WebCardLayoutSize.Small;
  const backgroundUrl = data?.backgroundImage?.url;
  const link = findAndFormatMenuLinkProps(
    Object.values(menu ?? {}),
    data?.link?.id ?? null,
    (data?.link as BaseMenuItem)?.url,
  );
  const ctaPosition = data?.ctaPosition ?? WebCardCtaPosition.None;

  return (
    <Container
      layoutSize={layoutSize}
      index={data?.indexOfType ?? 1}
      link={link}
      show
      as={data?.ctaPosition !== WebCardCtaPosition.None ? 'div' : LocalizedLink}
    >
      {backgroundUrl && (
        <ThronImage
          thronId={data.backgroundImage.id ?? ''}
          alt={data.backgroundImage.description}
          imageWidthMap={getImageWidthMap(data?.layoutSize)}
          useLazyLoading
        />
      )}
      <InfoWrapper>
        <Info position={ctaPosition}>
          <Title layoutSize={layoutSize} position={ctaPosition}>
            {data?.title}
          </Title>
          {data?.subTitle && (
            <SubTitle layoutSize={layoutSize} position={ctaPosition}>
              {data?.subTitle}
            </SubTitle>
          )}
          {ctaPosition !== WebCardCtaPosition.None && data?.link?.type === WebTemplateTypes.WebCtaButton && (
            <CtaButtonWrapper>
              <WebCtaButtonElement data={data?.link} width="200px" height="45px" />
            </CtaButtonWrapper>
          )}
        </Info>
      </InfoWrapper>
    </Container>
  );
};

const getFlexPosition = (position: WebCardCtaPosition) => {
  switch (position) {
    case WebCardCtaPosition.Centered:
      return 'center';
    case WebCardCtaPosition.RightAligned:
      return 'flex-end';
    default:
      return 'flex-start';
  }
};

const getAlignPosition = (position: WebCardCtaPosition) => {
  switch (position) {
    case WebCardCtaPosition.Centered:
      return 'center';
    case WebCardCtaPosition.RightAligned:
      return 'right';
    default:
      return 'left';
  }
};

const ContainerCommon = styled(LocalizedLink)<{ show?: boolean }>`
  display: ${({ show }): string => (show ? 'flex' : 'none')};
  position: relative;
  width: 100%;
  flex-direction: column;
  background-color: #ffffff;
  margin-bottom: 20px;
`;

const ContainerLarge = styled(ContainerCommon)`
  margin-bottom: 20px;
  min-height: 230px;

  @media ${Devices.tablet} {
    min-height: 250px;
    width: 100%;
  }
`;

const ContainerSmall = styled(ContainerCommon)<{ index: number }>`
  @media ${Devices.tablet} {
    width: calc(50% - 10px);
    max-width: 470px;
    margin-right: ${({ index }): string => (index % 2 > 0 ? '20px' : '0')};
  }

  @media ${Devices.desktop} {
    width: calc(33% - 15px);
    margin-right: 20px;
    margin-right: ${({ index }): string => (index % 3 === 0 ? '0' : '20px')};
  }
`;

const ContainerMediumWrapper = styled.div<{ index: number; show?: boolean }>`
  display: ${({ show }): string => (show ? 'block' : 'none')};
  width: 100%;

  @media ${Devices.tablet} {
    width: calc(50% - 10px);
    margin-top: 10px;

    &:nth-of-type(2n-1) {
      margin-right: 20px;
    }
  }
`;

const ContainerMedium = styled(ContainerCommon)`
  min-height: 230px;

  @media ${Devices.tablet} {
    width: 100%;
    max-width: 470px;
  }
`;

const InfoWrapper = styled.div`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 57%, #000000), linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  padding: 24px;
  justify-content: center;

  @media ${Devices.tablet} {
    padding: 30px;
  }
`;

const Info = styled.div<{ position: WebCardCtaPosition }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ position }) => getFlexPosition(position)};
  justify-content: flex-end;
  max-width: ${({ position }): string => (position !== WebCardCtaPosition.None ? '940px' : '100%')};
  width: 100%;
  height: 100%;
  color: #ffffff;
`;

const Title = styled.h2<{ layoutSize: WebCardLayoutSize, position: WebCardCtaPosition }>`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.67;
  text-align: ${({ position }) => getAlignPosition(position)};
  text-transform: uppercase;
  color: #ffffff;

  @media ${Devices.tablet} {
    font-size: ${({ layoutSize }): string => ([WebCardLayoutSize.Small, WebCardLayoutSize.Medium].includes(layoutSize) ? '18px' : '28px')};
  }
`;

const SubTitle = styled.p<{ layoutSize: WebCardLayoutSize, position: WebCardCtaPosition }>`
  display: block;
  width: 100%;
  margin: 5px auto 0;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 11px;
  text-align: ${({ position }) => getAlignPosition(position)};
  text-transform: uppercase;

  @media ${Devices.tablet} {
    font-size: ${({ layoutSize }): string => ([WebCardLayoutSize.Small, WebCardLayoutSize.Medium].includes(layoutSize) ? '11px' : '16px')};
  }

  @media ${Devices.desktop} {
    font-size: ${({ layoutSize }): string => ([WebCardLayoutSize.Small, WebCardLayoutSize.Medium].includes(layoutSize) ? '11px' : '16px')};
  }
`;

const CtaButtonWrapper = styled.div`
  margin-top: 45px;
`;

export default WebCardElement;
