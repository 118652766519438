import React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { useIsMenuOpened } from '@app/components/Hooks';
import { useIsHomeDetection, useMenuPosition } from './HeaderHooks';
import { TopHeader, NavigationHeader, SearchBar } from './components';

const Header = (): React.ReactElement => {
  const isMenuOpened = useIsMenuOpened();
  const isHome = useIsHomeDetection();
  const { isUpScroll, isOnTop, isNotScrolled } = useMenuPosition();
  const isTransparent = isMenuOpened || (isHome && isNotScrolled);

  return (
    <Container isTransparent={isTransparent} isUpScroll={isUpScroll} isOnTop={isOnTop}>
      <InnerContainer showGradient={isHome && isNotScrolled && !isMenuOpened}>
        <TopHeader />
        <NavigationHeader />
      </InnerContainer>
      <SearchBar />
    </Container>
  );
};

const Container = styled.header<{isTransparent: boolean; isUpScroll: boolean; isOnTop: boolean;}>`
  flex: 0 0 calc(var(--top-header-height) + var(--navigation-header-height));
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  background-image: ${(props): string => (props.isTransparent ? 'transparent' : 'url("/images/header-background-mobile.webp")')};
  background-size: cover;
  background-position: center center;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-weight: bold;
  z-index: 300;
  position: fixed;
  top: 0;
  transform: translateY(${({ isOnTop, isUpScroll }): string => ((isOnTop || isUpScroll) ? '0px' : '-100%')});
  transition: transform 300ms;

  @media ${Devices.desktopSmall} {
    background-image: ${(props): string => (props.isTransparent ? 'transparent' : 'url("/images/header-background.webp")')};
  }
`;

const InnerContainer = styled.div<{showGradient: boolean;}>`
  max-width: 1280px;
  margin: auto;
  position: relative;
  padding: 0 var(--horizontal-padding-mobile);
  color: white;
  background-image: ${({ showGradient }): string => (showGradient ? 'linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0))' : 'none')};

  @media ${Devices.desktopSmall} {
    padding: 0 var(--horizontal-padding);
  }
`;

export default Header;
