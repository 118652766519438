import React from 'react';
import { useIntl } from 'react-intl';

import { InviolaErrorTypes, InviolaSuccessTypes } from '@app/services/inviola/types/errorTypes';

import { FormError, FormSuccess } from './InviolaComponents';
import { useError, useSuccess } from './InviolaHooks';

interface InviolaErrorProps {
  errorType?: InviolaErrorTypes;
  message?: string;
}

interface InviolaSuccessProps {
  type?: InviolaSuccessTypes;
  translationId: string;
}

export const InviolaErrorMessage: React.FC<InviolaErrorProps> = ({ errorType }) => {
  const inviolaError = useError();
  const { formatMessage } = useIntl();

  if (!inviolaError || (errorType && inviolaError.type !== errorType)) {
    return null;
  }

  return (
    <FormError>{formatMessage({ id: `inviola.errors.${inviolaError.code}` })}</FormError>
  );
};

export const InviolaSuccessMessage: React.FC<InviolaSuccessProps> = ({ type, translationId }) => {
  const inviolaSuccess = useSuccess();
  const { formatMessage } = useIntl();

  if (!inviolaSuccess || (type && inviolaSuccess !== type)) {
    return null;
  }

  return (
    <FormSuccess>{formatMessage({ id: translationId })}</FormSuccess>
  );
};
