import { SubMenuId, TeamSubMenuId } from '@app/types/configurationTypes';

export type TeamTypes = 'men' | 'women' | 'primavera';

export const TeamSubMenuTypeMap: {
  [key in TeamTypes]: TeamSubMenuId;
} = {
  men: SubMenuId.FIRST_TEAM,
  women: SubMenuId.WOMEN_TEAM,
  primavera: SubMenuId.PRIMAVERA_TEAM,
};
