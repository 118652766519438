import {
  BaseMenuItem, ExternalLinkMenuItem, Image, RelatedMenuItem, Seo, ThroneAsset,
} from '@app/types/configurationTypes';
import { Game } from '@app/types/matchTypes';
import { GoogleAdsProps, GoogleMaps } from '@app/types/googleTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { BaseNewsItem, EditorialContentTypes, NewsContentItem, Video } from '@app/types/newsTypes';
import { ImageThron } from '@app/services/kentico/types/image_thron';
import { Promotion } from '@app/types/promotionsTypes';
import { InviolaCatalogItem } from '@app/types/inviolaTypes';
import { Question } from './faqQuestionTypes';

export enum WebTemplateTypes {
  WebGoogleMaps = 'web_google_maps',
  WebGoogleAds = 'web_ad_manager_banner',
  WebDepartment = 'web_department',
  WebPerson = 'web_person',
  WebPartner = 'web_partner',
  WebLanding = 'web_landing',
  WebCard = 'web_card',
  WebCtaButton = 'cta_button',
  WebCtaImage = 'web_cta_image',
  WebCtaDuoButton = 'web_cta_duo',
  WebPageImage = 'web_page_image',
  WebPageTitle = 'web_page_title',
  WebTabs = 'web_tabs',
  WebTabItem = 'web_tab_item',
  WebEditorial = 'web_editorial',
  WebEditorialDuo = 'web_editorial_duo',
  WebEmbedded = 'embedded',
  WebTicketsPartners = 'web_tickets_partners',
  WebPartnerBottom = 'web_partner_bottom',
  WebShare = 'web_share',
  WebFaqCategoryGroup = 'web_faq_category_group',
  WebFaqCategory = 'web_faq_category',
  WebContentGroup = 'web_content_group',
  WebHomeLanding = 'web_home_landing',
  WebHomeSlider = 'web_home_slider',
  WebCarousel = 'web_carousel',
  WebCarouselItem = 'web_carousel_item',
  WebHomeElement = 'web_home_element',
  WebPageVideo = 'web_page_video',
  News = 'news',
  Video = 'video',
  PhotoGallery = 'photo_gallery',
  WebIframe = 'web_iframe',
  PDF = 'pdf',
  WebCtaButtonQuatro = 'web_cta_quatro',
  WebLiveVideo = 'web_live_video',
  WebAdvertisementBanner = 'web_advertisement_banner',
  WebMatchbox = 'web_matchbox',
  WebInviolaMembershipCard = 'web_inviola_membership_card',
  WebLineCarousel = 'web_line_carousel',
  Promotion = 'promotion',
  WebInviolaCatalogItem = 'web_inviola_catalog_item',
}

export interface WebTemplateElement {
  type: WebTemplateTypes;
  id: string;
  index?: number;
  indexOfType?: number;
}

export interface WebGoogleMaps extends WebTemplateElement {
  type: WebTemplateTypes.WebGoogleMaps;
  map: GoogleMaps;
}

export interface WebTemplatePerson extends WebTemplateElement {
  type: WebTemplateTypes.WebPerson;
  firstName: string;
  lastName: string;
  position: string;
  quote: string;
  photo: Image;
  resume: string;
  url: string;
}

export interface WebTemplatePartner extends WebTemplateElement {
  type: WebTemplateTypes.WebPartner;
  title: string;
  description: string;
  photo: Image;
  redirectUrl: string;
}

type WebTemplateDepartmentContent = WebTemplatePerson | WebTemplatePartner;

export interface WebTemplateDepartment extends WebTemplateElement {
  type: WebTemplateTypes.WebDepartment;
  title?: string;
  name?: string;
  showDivider?: boolean;
  content: WebTemplateData[];
}

export enum WebLandingType {
  Normal = 'Normal',
  Covered = 'Covered',
}

export enum WebLandingSize {
  Medium = 'Medium',
  Big = 'Big',
}

export interface WebTemplateLanding extends WebTemplateElement {
  type: WebTemplateTypes.WebLanding;
  name?: string;
  isVisible?: boolean;
  content: WebTemplateData[];
  url: string;
  size: WebLandingSize;
  layoutType: WebLandingType;
}

export enum WebSliderLayoutSize {
  Medium = 'Medium',
  Big = 'Big',
}

export enum WebCardLayoutSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  Banner = 'Banner',
}

export enum WebCardLayoutType {
  Plain = 'Plain',
  Overlay = 'Overlay',
}
export enum WebCardCtaPosition {
  None = 'None',
  LeftAligned = 'Left-aligned',
  Centered = 'Centered',
  RightAligned = 'Right-aligned',
}

export enum WebHomeElementType {
  News = 'news',
  Videos = 'videos',
  Gallery = 'gallery',
  NewsAndStandings = 'news_and_standings',
}

export enum WebHomeElementBackground {
  Transparent = 'transparent',
  Purple = 'purple',
}

export enum WebPageVideoSize {
  Big = 'big',
  Medium = 'medium',
}

export interface WebGoogleAds extends WebTemplateElement {
  type: WebTemplateTypes.WebGoogleAds;
  ads: GoogleAdsProps;
}

export type MenuItem = BaseMenuItem | RelatedMenuItem | ExternalLinkMenuItem | null;

export interface WebCardOptions {
  layoutSize?: WebCardLayoutSize;
  layoutType?: WebCardLayoutType;
  ctaPosition?: WebCardCtaPosition;
}

export interface WebTemplateCard extends WebTemplateElement {
  type: WebTemplateTypes.WebCard;
  title?: string;
  subTitle?: string;
  layoutSize?: WebCardLayoutSize;
  layoutType?: WebCardLayoutType;
  ctaPosition?: WebCardCtaPosition;
  backgroundImage: ThroneAsset;
  link: MenuItem | WebTemplateCtaButton;
}

export enum WebPageImageSizeType {
  Big = 'big',
  Medium = 'medium',
}

export interface WebTemplatePageImage extends WebTemplateElement {
  type: WebTemplateTypes.WebPageImage;
  image: ImageThron;
  size: WebPageImageSizeType;
}

export interface WebTemplatePageTitle extends WebTemplateElement {
  type: WebTemplateTypes.WebPageTitle;
  title: string;
  subTitle: string;
}

export interface WebEditorial extends WebTemplateElement {
  type: WebTemplateTypes.WebEditorial;
  content: (NewsContentItem | null)[];
}

export interface WebTemplateEditorialDuo extends WebTemplateElement {
  type: WebTemplateTypes.WebEditorialDuo;
  isContentFirst: boolean;
  content: string;
  image: Image;
}

export interface WebEmbedded extends WebTemplateElement {
  type: WebTemplateTypes.WebEmbedded;
  content: string;
}

export interface WebTabItemTemplateElement extends WebTemplateElement, Seo {
  type: WebTemplateTypes.WebTabItem;
  name: string;
  isVisible: boolean;
  url: string;
  content: WebTemplateData[];
}

export interface WebTabsTemplateElement extends WebTemplateElement {
  type: WebTemplateTypes.WebTabs;
  tabs: WebTabItemTemplateElement[];
}

export interface WebTemplatePartnerBottom extends WebTemplateElement {
  type: WebTemplateTypes.WebPartnerBottom;
  title: string;
  content: WebTemplateDepartment[];
}

export enum WebCtaButtonTemplateType {
  Plain = 'plain',
  Transparent = 'transparent'
}

export interface WebTemplateCtaButton extends WebTemplateElement {
  type: WebTemplateTypes.WebCtaButton;
  label: string;
  template: WebCtaButtonTemplateType;
  menuItem: MenuItem;
}

export interface WebCtaImage extends WebTemplateElement {
  type: WebTemplateTypes.WebCtaImage;
  image: Image;
  menuItem: MenuItem;
}

export interface WebTemplateCtaDuoButton extends WebTemplateElement {
  type: WebTemplateTypes.WebCtaDuoButton;
  content: WebTemplateCtaButton[];
}

export interface TicketsPartner {
  url: string;
  logo: Image;
}

export interface WebTicketsPartners extends WebTemplateElement {
  type: WebTemplateTypes.WebTicketsPartners;
  partner1: TicketsPartner;
  partner2: TicketsPartner;
  partner3: TicketsPartner;
  partner4: TicketsPartner;
}

export interface WebTemplateShare extends WebTemplateElement {
  type: WebTemplateTypes.WebShare;
}

export interface WebFaqGroup extends WebTemplateElement {
  type: WebTemplateTypes.WebFaqCategoryGroup;
  content: WebTemplateData[];
}

export interface WebFaqCategory extends WebTemplateElement {
  type: WebTemplateTypes.WebFaqCategory;
  categoryName: string;
  questions: Question[];
}
export interface WebContentGroup extends WebTemplateElement {
  type: WebTemplateTypes.WebContentGroup;
}

export interface WebHomeLanding extends WebTemplateElement {
  type: WebTemplateTypes.WebHomeLanding;
  title: string;
  layoutSize: WebCardLayoutSize;
  content: WebTemplateCard[];
}

export interface WebHomeSlider extends WebTemplateElement {
  type: WebTemplateTypes.WebHomeSlider;
  title: string;
  layoutSize: WebSliderLayoutSize;
  content: WebTemplateCard[];
}

export type WebTemplateCarouselItemContentTypes = WebTemplateCard | BaseNewsItem | Video;

export interface WebTemplateCarouselItem extends WebTemplateElement {
  type: WebTemplateTypes.WebCarouselItem;
  content: WebTemplateCarouselItemContentTypes[];
}

export interface WebTemplateCarousel extends WebTemplateElement {
  type: WebTemplateTypes.WebCarousel;
  title: string;
  items: WebTemplateCarouselItem[];
  link: RelatedMenuItem | null;
}

export interface WebTemplateHomeElement extends WebTemplateElement {
  type: WebTemplateTypes.WebHomeElement;
  title: string;
  elementType: WebHomeElementType;
  background: WebHomeElementBackground;
  link: RelatedMenuItem | null;
  category: string;
}

export interface WebTemplatePageVideo extends WebTemplateElement {
  type: WebTemplateTypes.WebPageVideo;
  size: WebPageVideoSize;
  video: ThroneAsset;
}

export interface WebIframeTemplateElement extends WebTemplateElement {
  type: WebTemplateTypes.WebIframe;
  name: string;
  iframeInnerHtml: string;
}

export interface WebTemplatePDF extends WebTemplateElement {
  type: WebTemplateTypes.PDF;
  pdf: Image;
}

export interface WebTempalteCtaButtonQuatro extends WebTemplateElement {
  type: WebTemplateTypes.WebCtaButtonQuatro;
  buttons: Array<WebTemplateCtaButton>
}

export interface WebTemplateLiveVideo extends WebTemplateElement {
  type: WebTemplateTypes.WebLiveVideo;
}

export interface WebTemplateAdvertisementBanner extends WebTemplateElement {
  type: WebTemplateTypes.WebAdvertisementBanner;
  desktopBannerImage: Image;
  mobileBannerImage?: Image;
  url?: string;
}

export interface WebTemplateMatchbox extends WebTemplateElement {
  type: WebTemplateTypes.WebMatchbox;
  games: Game[];
}

export interface WebTemplateInviolaMembershipCard extends WebTemplateElement {
  type: WebTemplateTypes.WebInviolaMembershipCard;
  id: string;
  title: string;
  price: string;
  description: string;
  cardImage: Image;
  ctaButton: WebTemplateCtaButton;
}

export enum WebLineCarouselType {
  News = 'news',
  Videos = 'videos',
  Gallery = 'gallery',
  NewsAndStandings = 'news_and_standings',
  Promotion = 'promotion',
  InviolaCatalog = 'catalog_items',
}

export enum WebLineCarouselBackground {
  Transparent = 'transparent',
  Purple = 'purple',
}

export interface WebTemplateLineCarousel extends WebTemplateElement {
  type: WebTemplateTypes.WebLineCarousel;
  title: string;
  background: WebLineCarouselBackground;
  carouselType: WebLineCarouselType;
  link: RelatedMenuItem | null;
}

export interface WebTemplateInviolaCatalogItem extends WebTemplateElement {
  type: WebTemplateTypes.WebInviolaCatalogItem;
  item: InviolaCatalogItem;
}

export type LineCarouselItem = Promotion;

export type WebTemplateData = WebTemplateDepartment | WebTemplateDepartmentContent | WebTemplatePerson
  | WebTemplatePartner
  | WebTemplateLanding | WebTemplateCard | WebTemplateCtaButton | WebTemplatePageImage | WebTemplatePageTitle
  | WebTemplateCarousel | WebTemplateCarouselItem | WebTabsTemplateElement
  | WebTabItemTemplateElement | WebEditorial | WebTicketsPartners | WebTemplatePartnerBottom | WebTemplateShare
  | WebFaqGroup
  | WebFaqCategory | WebCtaImage | WebTemplateCtaDuoButton | WebTemplateEditorialDuo
  | WebEmbedded | WebContentGroup | WebGoogleAds | WebGoogleMaps | WebHomeLanding | WebHomeSlider
  | WebTemplateHomeElement | BaseNewsItem | Video | WebTemplatePageVideo | WebIframeTemplateElement
  | WebTemplatePDF | WebTemplateLiveVideo
  | WebTempalteCtaButtonQuatro | WebTemplateAdvertisementBanner | WebTemplateMatchbox | WebTemplateInviolaMembershipCard
  | WebTemplateLineCarousel | Promotion | WebTemplateInviolaCatalogItem
  | null;

export type LocalizedWebTemplateData = {
  [key in LanguageType]?: WebTemplateData[];
}

export interface WebTemplate {
  templateHierarchy: WebTemplateData[];
}

export type ElementWebTemplateData = {
  [propName: string]: LocalizedWebTemplateData;
}

export enum PageType {
  Template,
  Question,
  HallOfFame,
  HallOfFamePlayer,
}

export interface UrlParams {
  topLevel?: string;
  secondLevel?: string;
  thirdLevel?: string;
  tab?: string;
}

export interface SponsorParams {
  type: string;
  id: string;
  description: string;
  photo: {
    description: string;
    url: string;
  };
  redirectUrl: string;
  title: string;
}

export const WebTemplateTypesWithContent: (WebTemplateTypes | EditorialContentTypes)[] = [
  WebTemplateTypes.WebDepartment,
  WebTemplateTypes.WebLanding,
  WebTemplateTypes.WebTabItem,
  WebTemplateTypes.WebFaqCategoryGroup,
  WebTemplateTypes.WebHomeLanding,
  WebTemplateTypes.WebHomeSlider,
];
