import { encode } from 'querystring';

import { HttpError, HttpErrorBody } from './types/errorTypes';
import {
  ValidateCardParams, RegisterCardParams, VerifyEmailParams, ProxyRegistrationCredentials,
} from './types/requestTypes';
import {
  InviolaRawCatalogItems, InviolaRawCategories, InviolaRawRemainingPoints, InviolaProxyRawShops,
} from './types/responseTypes';
import { InViolaProxyValidateCardData, InViolaProxyCardGenerateCode } from './types/responseProxyTypes';

const ProxyConfig = {
  apiUrl: process.env.INVIOLA_PROXY_API_URL,
  campaignId: process.env.INVIOLA_CAMPAIGN_ID,
};

const inviolaProxyRequest = async <T>(
  uri: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userBody: Record<string, any>,
): Promise<T> => {
  const response = await fetch(`${ProxyConfig.apiUrl}/${uri}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: encode(userBody),
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const responseJson = await response.json();

  if (!response.ok) {
    const errorBody = responseJson as HttpErrorBody;
    throw new HttpError(errorBody.code, errorBody.message);
  }

  return responseJson as Promise<T>;
};

export const proxySendPoints = async (
  session: string, customerId: number, customerBirthdate: string, targetCardNumber: string, pointsAmount: string,
): Promise<InviolaRawRemainingPoints> => (
  inviolaProxyRequest<InviolaRawRemainingPoints>('card/send_points', {
    customerSessionId: session,
    customerId,
    customerBirthdate,
    targetCardNumber,
    pointsAmount,
  })
);

export const fetchProxyCatalogItems = async (
  syncSessionId: string, catalogId: number, rowCount = 100,
): Promise<InviolaRawCatalogItems> => (
  inviolaProxyRequest<InviolaRawCatalogItems>('catalog/prizes', {
    syncSessionId,
    catalogId,
    initlimit: 0,
    rowCount,
  })
);

export const fetchProxyCatalogCategories = async (
  syncSessionId: string, rowCount = 100,
): Promise<InviolaRawCategories> => (
  inviolaProxyRequest('catalog/categories', {
    syncSessionId,
    initlimit: 0,
    rowCount,
  })
);

export const validateCardProxy = async (
  { syncSessionId, cardNumber }: ValidateCardParams,
): Promise<InViolaProxyValidateCardData> => (
  inviolaProxyRequest('card/validate', {
    syncSessionId,
    cardNumber,
  })
);

export const verifyCustomerByEmailProxy = async (
  { syncSessionId, customerId, email }: VerifyEmailParams,
): Promise<InViolaProxyCardGenerateCode> => (
  inviolaProxyRequest('card/generate/code', {
    syncSessionId,
    customerId,
    email,
  })
);

export const verifyCustomerEmailUniquenessProxy = async (email = ''): Promise<{ isExist: boolean }> => (
  inviolaProxyRequest('customer/verifyexist', { email })
);

export const registerCustomerProxy = async (params: ProxyRegistrationCredentials): Promise<void> => (
  inviolaProxyRequest('customer/edit', { ...params })
);

export const verifyCardValidationCodeProxy = async (
  params: RegisterCardParams,
): Promise<{ customer: { campaignId: number } }> => (
  inviolaProxyRequest('customer/credentials/edit', params)
);

export const getProxyShopsCategories = async (syncSessionId = ''): Promise<InviolaRawCategories> => (
  inviolaProxyRequest('shops/categories', {
    syncSessionId,
    languageid: 3, // 1 - EN; 3 - IT
    rowCount: 100,
  })
);

export const getProxyShopsByCategory = async (syncSessionId = '', categoryid = 0): Promise<InviolaProxyRawShops> => (
  inviolaProxyRequest('shops/bycategory', {
    syncSessionId,
    categoryid,
    languageid: 3, // 1 - EN; 3 - IT
    rowCount: 100,
  })
);

export const getProxyShopsByDistance = async (
  syncSessionId = '', latitude: number, longitude: number, rowLimit = 100,
): Promise<InviolaProxyRawShops> => (
  inviolaProxyRequest('shops/bydistance', {
    syncSessionId,
    latitude,
    longitude,
    initlimit: 0,
    rowcount: rowLimit,
  })
);
