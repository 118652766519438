import { LayoutType } from '@app/types/configurationTypes';
import { EditorialContentTypes } from '@app/types/newsTypes';

export const LayoutTypeToItemsForDownload: Record<LayoutType, number> = {
  layout_2x2: 9,
  layout_2x3: 11,
  layout_3x3: 13,
};

export const ApplicationConfig = {
  news: {
    defaultNewsLimit: 4,
    latestNewsLimit: {
      [EditorialContentTypes.NEWS]: 11,
      [EditorialContentTypes.VIDEO]: 11,
      [EditorialContentTypes.PHOTO]: 11,
    },
  },
  home: {
    latestNewsLimit: {
      [EditorialContentTypes.NEWS]: 5,
      [EditorialContentTypes.VIDEO]: 5,
      [EditorialContentTypes.PHOTO]: 4,
    },
  },
};

export const HOME_STANDINGS_TEAMS_NUMBER = 5;

export const HEADER_PARTNERS_COUNT = 3;

export enum WebTemplatePagesSecondLevelMenuId {
  FAQ_MENU = 'fan_faq',
  HALL_OF_FAME = 'club_storia_halloffame'
}
