import React from 'react';

import { LocalizedLink } from '@app/components/Localization';
import { RelatedMenuItem } from '@app/types/configurationTypes';
import AppRoutes from '@app/constants/routesConstants';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { WebHomeElementBackground, WebLineCarouselBackground } from '@app/types/webTemplateTypes';
import { NavigationLinkParams } from '@app/types/routerTypes';

interface HomeTitleProps {
  title: string;
  link?: RelatedMenuItem | NavigationLinkParams | null;
  Component: React.FunctionComponent<{
    background?: WebHomeElementBackground | WebLineCarouselBackground
  }>;
  showSeeMore?: boolean;
  background?: WebHomeElementBackground | WebLineCarouselBackground;
}

const HomeTitleLink = ({ title, link, Component, showSeeMore, background }: HomeTitleProps): React.ReactElement => {
  const params = link && 'type' in link ? {
    pathKey: AppRoutes.CommonPage.path,
    pathParams: {
      topLevel: link.topLevel?.url ?? '',
      secondLevel: link.secondLevel?.url ?? '',
      thirdLevel: link.thirdLevel?.url ?? '',
      forthLevel: '',
      fifthLevel: '',
    },
  } : link;

  return (
    <HomeTitleLinkWrapper>
      { link ? (
        <LocalizedLink
          pathKey={params?.pathKey ?? AppRoutes.CommonPage.path}
          pathParams={params?.pathParams ?? {}}
        >
          <Component background={background}>{title}</Component>
        </LocalizedLink>
      ) : (
        <Component background={background}>{title}</Component>
      )}
      <FillSpace />
      {link && showSeeMore && (
        <SeeMore
          pathKey={params?.pathKey ?? AppRoutes.CommonPage.path}
          pathParams={params?.pathParams ?? {}}
          background={background}
        >
          <FormattedMessage id="home.seeMore" />
        </SeeMore>
      )}
    </HomeTitleLinkWrapper>
  );
};

const HomeTitleLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const FillSpace = styled.span`
  flex: 1;
`;

const SeeMore = styled(LocalizedLink)<{ background?: WebHomeElementBackground | WebLineCarouselBackground }>`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.82;
  letter-spacing: -0.01px;
  text-align: right;
  color: ${({ theme }): string => theme.color.red};
`;

export default HomeTitleLink;
