import styled, { css, DefaultTheme, ThemeProps } from 'styled-components';

import { Devices } from '@app/styles';
import { Link } from 'react-router-dom';

export const Title = styled.h3`
  text-transform: uppercase;
  font-size: 2rem;
  margin-bottom: 3.571rem;
`;

export const SubTitle = styled.h4`
  text-transform: uppercase;
  font-size: 1.142rem;
  margin-bottom: 0.714rem;
  color: ${(props): string => props.theme.color.purple};
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props): string => props.theme.color.purple};
  margin-bottom: 10px;
  display: inline-block;
`;

export const CheckboxLabel = styled.span<{ required?: boolean | string }>`
  margin-bottom: 10px;
  display: inline-block;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 14px;
  font-weight: 300;
  color: ${(props): string => (props.required ? props.theme.color.purple : 'inherit')};

  &:hover {
    cursor: default;
  }
`;

const fieldCSS = css`
  display: block;
  width: 100%;
  padding: 15px 20px;
  margin-bottom: 30px;

  transition: all 0.30s ease-in-out;
  outline: none;
  border: 0;

  &:focus {
    box-shadow: 0 0 5px ${(props): string => props.theme.color.purple};
  }

  &::placeholder {
    font-family: ${(props): string => props.theme.fontFamily.arialNova};
    font-size: 15px;
    font-weight: 300;
    line-height: 0.93;
    color: #b5b5b5;
    opacity: 1;
  }

  &:disabled {
    background: rgba(255,255,255,0.69);
    cursor: not-allowed;
  }
`;

export const Input = styled.input`
  ${fieldCSS}

  &[type="date"]:hover,
  &[type="radio"]:hover,
  &[type="checkbox"]:hover {
    cursor: pointer;
  }
`;

export const Select = styled.select`
  ${fieldCSS}

  &:hover {
    cursor: pointer;
  }
`;

export const EmptyOption = styled.option`
  opacity: 0.6;
`;

const CommonButton = (props: ThemeProps<DefaultTheme>): string => `
  background-color: ${props.theme.color.red};
  border: 0;
  color: white;
  display: block;
  font-weight: bold;
  margin: 30px 0;
  text-transform: uppercase;
  width: 100%;
  opacity: .9;
  transition: all 0.30s ease-in-out;

  font-size: 0.857rem;
  padding: 0.857rem;

  & a {
    color: inherit;
    opacity: inherit;
  }

  &:hover {
    opacity: 1;
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  @media ${Devices.tabletLarge} {
    font-size: 1rem;
    padding: 15px;
  }
`;

export const Button = styled.button`
  ${CommonButton};

  border: solid 2px ${(props): string => props.theme.color.red};

  &:hover {
    background: transparent;
    border-color: ${(props): string => props.theme.color.red};
    color: ${(props): string => props.theme.color.red};
  }

  &:disabled {
    background: ${(props): string => props.theme.color.red};
    border-color: ${(props): string => props.theme.color.red};
    color: white;
  }
`;

export const ButtonLink = styled(Link)`
  ${CommonButton}
`;

export const ButtonOutline = styled(Button)`
  background: transparent;
  border: solid 2px ${(props): string => props.theme.color.red};
  color: ${(props): string => props.theme.color.red};
  opacity: .8;

  &:hover {
    border-color: ${(props): string => props.theme.color.red};
    background-color: ${(props): string => props.theme.color.red};
    color: white;
  }
`;

export const Checkbox = styled.input`
  margin-right: 15px;
  cursor: pointer;
`;

export const TextError = styled.div`
  color: ${(props): string => props.theme.color.red};
  margin-top: -20px;
  margin-bottom: 20px;
`;

export const Hint = styled(TextError)`
  color: ${(props): string => props.theme.color.purple};
`;

export const CheckboxError = styled(TextError)`
  margin-top: 0;
`;

export const TextHint = styled.div`
  margin-top: -20px;
  margin-bottom: 20px;
  text-align: right;
`;

const FormMessage = `
  color: white;
  padding: 15px;
  margin-top: 30px;
  border-radius: 5px;
`;

export const MessageContainerTop = styled.div`
  & > * {
    margin: 0 0 30px!important;
  }
`;

export const FormError = styled.div`
  ${FormMessage}
  background-color: ${(props): string => props.theme.color.red};

  -webkit-animation: bounce-in-fwd 0.5s both;
  animation: bounce-in-fwd 0.5s both;
`;

export const FormSuccess = styled.div`
  ${FormMessage}
  background-color: ${(props): string => props.theme.color.purple};

  -webkit-animation: bounce-in-fwd 0.5s both;
  animation: bounce-in-fwd 0.5s both;
`;

export const CenteredBlock = styled.div`
  text-align: center;
`;

export const Description = styled(CenteredBlock)`
  padding-bottom: 2rem;
`;

export const Container = styled.div`
  width: 100%;
`;

export const Column = styled.div<{ width?: string }>`
  flex-direction: column;
  flex-basis: 100%;
  display: block;

  padding: 0 15px;

  @media ${Devices.tablet} {
    display: flex;
    flex: ${(props): string => (props.width ? `0 0 ${props.width}` : '1')};
  }
`;

export const Row = styled.div`
  @media ${Devices.tablet} {
    display: flex;
    flex-wrap: wrap;
  }

  margin-left: -15px;
  margin-right: -15px;
`;

export const ProfilePhoto = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 90px;

  border: 3px solid white;
  border-radius: 50%;
  font-size: 40px;
  font-weight: normal;
  line-height: 40px;

  background-color: ${(props): string => props.theme.color.purple};
  color: white;

  margin: auto;
  margin-bottom: 15px;
`;
