import React from 'react';
import styled from 'styled-components';
import chunk from 'lodash/chunk';

import { MenuItem as MenuItemInterface } from '@app/types/configurationTypes';

import { Devices } from '@app/styles';
import { useHeader } from '@app/components/Header/HeaderHooks';
import { useMenu, useIsMenuOpened } from '@app/components/Hooks';
import SocialIcons from '@app/components/SocialIcons';
import Sponsors from '@app/components/Sponsors';
import MenuItem from './MenuItem';
import { useNumberItemsPerColumn } from './FullMenuHooks';

const TopMenu: React.FC<{menu: MenuItemInterface[]}> = ({ menu }) => {
  const itemsWithSubItems = menu.filter((item) => Object.values(item.navigation).length);
  const itemsWithoutSubItems = menu.filter((item) => !Object.values(item.navigation).length);
  return (
    <TopMenuContainer>
      {
        !!itemsWithSubItems.length && (
          itemsWithSubItems.map((item) => (
            <MenuItem item={item} key={item.data.id} />
          ))
        )
      }
      {
        !!itemsWithoutSubItems.length && (
          <div>
            {
              itemsWithoutSubItems.map((item) => (
                <MenuItem item={item} key={item.data.id} />
              ))
            }
          </div>
        )
      }
    </TopMenuContainer>
  );
};

const FullMenu: React.FC = () => {
  const header = useHeader();
  const menuItems = useMenu();
  const isMenuOpened = useIsMenuOpened();
  const menu = (menuItems && Object.values(menuItems).filter(({ data }) => data.isVisible)) || [];
  const topMenu = menu.splice(0, 4);
  const columnsSize = useNumberItemsPerColumn(menu?.length ?? 0, header?.partners?.length ?? 0);

  return (
    <Container isOpened={isMenuOpened}>
      <ScrolledContainer>
        <InnerContainer>
          { !!topMenu?.length && <TopMenu menu={topMenu} /> }
          { !!topMenu?.length && !!menu?.length && <Line /> }
          {
            !!menu?.length && (
              <List>
                {
                  chunk(menu, columnsSize).map((column, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ul key={index}>
                      {
                        column.map((subItem) => (
                          <MenuItem item={subItem} key={subItem.data.id} />
                        ))
                      }
                    </ul>
                  ))
                }
              </List>
            )
          }
          <SocialList>
            { header?.social?.length && <StyledSocialIcons social={header.social} /> }
          </SocialList>
          <Sponsors showDivider={false} isOpenedMenu isMenu />
        </InnerContainer>
      </ScrolledContainer>
    </Container>
  );
};

const Container = styled.div<{ isOpened: boolean }>`
  display: ${({ isOpened }): string => (isOpened ? 'block' : 'none')};
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  user-select: none;
  z-index: 200;
  -webkit-tap-highlight-color: transparent;
  background-color: ${(props): string => props.theme.color.purple};;
  background-image: url('/images/menuBackground.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  padding-top: 165px;
  text-transform: uppercase;
`;

const ScrolledContainer = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const InnerContainer = styled.div`
  max-width: 1280px;
  padding: 0 var(--horizontal-padding-mobile);
  margin: auto;
  overflow-y: auto;
  position: relative;

  @media ${Devices.desktopSmall} {
    padding: 0 var(--horizontal-padding);
  }
`;

const TopMenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Line = styled.hr`
  width: 100%;
  border: none;
  height: 1px;
  background-color: ${(props): string => props.theme.color.red};
  margin: 10px 0 20px;

  @media ${Devices.tablet} {
    width: 300px;
  }
`;

const List = styled.div`
  display: flex;
`;

const SocialList = styled.ul`
  position: absolute;
  right: 70px;
  top: 50%;
  transform: translateY(-60%);
  display: none;

  @media ${Devices.desktopSmall} {
    display: block;
  }
`;

const StyledSocialIcons = styled(SocialIcons)`
  width: 21px;
  padding: 0;
  margin-bottom: 50px;

  & img {
    width: 100%;
    height: auto;
  }
`;

export default FullMenu;
