import styled from 'styled-components';
import { CommonButtonLink } from './CommonButton';

export const MatchCenterButton = styled(CommonButtonLink)<{ inverse?: boolean }>`
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-weight: bold;
  line-height: 1.15;
  transition: all 0.5s ease;
  width: ${({ inverse }): string => (inverse ? 'auto' : '100%')};
  min-width: 140px;
  height: ${({ inverse }): string => (inverse ? '50px' : '30px')};
  background: ${({ theme, inverse }): string => (inverse ? theme.color.red : 'transparent')};
  color: ${({ theme, inverse }): string => (inverse ? 'white' : theme.color.red)};
  border: 2px solid ${(props): string => props.theme.color.red};
  font-size: ${({ inverse }): string => (inverse ? '15px' : '12px')};

  &:hover {
    transition: all 0.5s ease;
    background: ${({ theme, inverse }): string => (inverse ? 'white' : theme.color.red)};
    color: ${({ theme, inverse }): string => (inverse ? theme.color.red : 'white')};
  }
`;
