import React from 'react';
import styled from 'styled-components';

import WebTemplatePage from './WebTemplatePage';

import {
  useWebTemplateMultiLangUrl,
  usePageType,
} from './WebTemplateHooks';

const WebTemplate = (): React.ReactElement => {
  useWebTemplateMultiLangUrl();
  const pageType = usePageType();
  let pageComponent;

  switch (pageType) {
    default:
      pageComponent = (<WebTemplatePage />);
  }

  return (
    <>
      <Container>
        {pageComponent}
      </Container>
    </>
  );
};

const Container = styled.div`
  height: 100%;
  background-color: ${(props): string => props.theme.color.whiteSmoke};
`;

export default WebTemplate;
