import * as React from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';

const DESKTOP_ITEM_MARGIN = '20px';
const TABLET_ITEM_MARGING = '20px';
const MOBILE_ITEM_MARGIN = '10px';

export const ThreeColumnGrid: React.FC = React.memo(({ children }) => (
  <ThreeColumnGridContainer>
    {children}
  </ThreeColumnGridContainer>
));

const ThreeColumnGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${MOBILE_ITEM_MARGIN};

  @media ${Devices.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${TABLET_ITEM_MARGING};
  }

  @media ${Devices.tabletLarge} {
   grid-template-columns: repeat(3, 1fr);
   grid-gap: ${DESKTOP_ITEM_MARGIN};
  }
`;

export const TwoColumnGrid: React.FC = ({ children }) => (
  <TwoColumnGridContainer>
    {children}
  </TwoColumnGridContainer>
);

const TwoColumnGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${MOBILE_ITEM_MARGIN};

  @media ${Devices.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${TABLET_ITEM_MARGING};
  }
`;

export const TwoThreeColumnGrid: React.FC = ({ children }) => (
  <TwoThreeColumnGridContainer>
    {children}
  </TwoThreeColumnGridContainer>
);

const TwoThreeColumnGridContainer = styled.div`
  display: grid;
  grid-gap: ${MOBILE_ITEM_MARGIN};
  grid-template-columns: repeat(1, minmax(200px, 1fr));

  @media ${Devices.tablet} {
    grid-gap: ${TABLET_ITEM_MARGING};
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${Devices.tabletLarge} {
    grid-gap: ${DESKTOP_ITEM_MARGIN};
    grid-template-columns: repeat(6, 1fr);
  }

  & > * {
    &:nth-child(n + 4) {
      display: block;
    }

    @media ${Devices.tablet} {
      &:nth-child(3n + 1) {
        grid-column: auto /span 2;
      }
    }

    @media ${Devices.tabletLarge} {
      &:nth-child(n + 4) {
        display: block;
      }

      &:nth-child(5n + 1), &:nth-child(5n + 2) {
        grid-column: auto /span 3;
      }

      &:nth-child(5n + 3), &:nth-child(5n + 4), &:nth-child(5n + 5) {
        grid-column: auto /span 2;
      }
    }
  }
`;
