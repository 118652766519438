import React from 'react';
import styled from 'styled-components';

import { ContainerMedium } from '@app/components/WebTemplates/components';
import { WebEmbedded } from '@app/types/webTemplateTypes';
import { useEmbeddedScripts } from '@app/pages/NewsDetails/NewsDetailsHooks';

interface WebEmbeddedParamsProps {
  data: WebEmbedded;
}

const WebEmbeddedElement = ({ data }: WebEmbeddedParamsProps): React.ReactElement => {
  const decoded = decodeURI(data.content);
  useEmbeddedScripts(decoded);

  return (
    <Container>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: decoded }} />
    </Container>
  );
};

const Container = styled(ContainerMedium)`
  margin-top: 20px;
`;

export default WebEmbeddedElement;
