import pull from 'lodash/pull';

import {
  Player, SetTeamPayload, TeamStatistics,
} from '@app/types/teamsTypes';
import {
  ResetAllTeams, SetCareers, SetError,
  SetTeam, SetTeamStatistics,
  TeamsActionTypes,
} from '@app/store/actionTypes/teamsActionTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { Action, ActionWithPromiseReturn } from '@app/types/actionTypes';
import { TeamSubMenuId } from '@app/types/configurationTypes';

import { waitForConfigurationDownload } from '@app/store/actions/configurationActions';
import { getPlayersList, TeamSquadTypes } from '@app/services/kentico/teamsService';

import { AppLanguages } from '@app/constants/localizationConstants';
import { mapTeamSquadType, mapTeamTypeToCompetition, sortPlayers } from '@app/helpers/teamsHelpers';
import { getSecondLevelMenuItem, isBaseMenuItem } from '@app/helpers/menuHelpers';
import { getTeamStatistics } from '@app/services/opta/teamsService';
import { CareerItem } from '@app/services/careers/types/responseTypes';
import { getCareersRequest } from '@app/services/careers/request';
import { Errors, PageError } from '@app/types/errorTypes';

const setTeam = (team: SetTeamPayload): SetTeam => ({
  type: TeamsActionTypes.SET_TEAM,
  payload: team,
});

const setTeamStatistics = (statistics: TeamStatistics, type: TeamSubMenuId): SetTeamStatistics => ({
  type: TeamsActionTypes.SET_TEAM_STATISTICS,
  payload: { type, statistics },
});

const setCareers = (careerList: Array<CareerItem>): SetCareers => ({
  type: TeamsActionTypes.SET_CAREER,
  payload: careerList,
});

export const resetAllTeams = (): ResetAllTeams => ({
  type: TeamsActionTypes.RESET_ALL_TEAMS,
});

export const setError = (error: Errors): SetError => ({
  type: TeamsActionTypes.SET_ERROR,
  payload: error,
});

interface GetPlayersType {
  topLevel: string;
  teamType: string;
  menuSubItemId?: TeamSubMenuId;
  language: LanguageType;
}

export type LocalizedPlayers = Record<LanguageType, Player[]>;
interface GetPlayersForNotActiveLangs {
  teamType: TeamSquadTypes;
  language: LanguageType;
  menuSubItemId: TeamSubMenuId;
}

const getPlayersForNotActiveLangs = ({ language, teamType, menuSubItemId }: GetPlayersForNotActiveLangs): Action => (
  async (dispatch): Promise<void> => {
    const restLanguages = pull([...AppLanguages], language);

    const playersRequests = restLanguages.map((language) => (
      getPlayersList({
        language,
        teamType,
      })
    ));

    const multiLangPlayersData = await Promise.all(playersRequests);

    const languageToPlayerDataMap = restLanguages.reduce((acc, language, index) => {
      acc[language] = sortPlayers(multiLangPlayersData[index]);
      return acc;
    }, {} as LocalizedPlayers);

    dispatch(setTeam({
      type: menuSubItemId,
      players: languageToPlayerDataMap,
    }));
  }
);

export const getPlayers = ({
  topLevel, teamType, menuSubItemId, language,
}: GetPlayersType): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();
    if (!teamType && !menuSubItemId) return;

    try {
      const subItem = getSecondLevelMenuItem(getState(), topLevel, teamType, language)?.[language];
      const subItemId = menuSubItemId ?? subItem?.data.id as TeamSubMenuId;

      const teamSquadType = mapTeamSquadType(subItemId);
      if (!teamSquadType) return;

      const players: Player[] = await getPlayersList({
        language,
        teamType: teamSquadType,
      });
      sortPlayers(players);
      dispatch(setTeam({
        type: subItemId,
        players: {
          [language]: players,
        },
      }));

      /* dispatch(getWebTemplateDataById({
        id: subItemId,
        language,
      })); */

      return dispatch(getPlayersForNotActiveLangs({ language, teamType: teamSquadType, menuSubItemId: subItemId }));
    } catch (e) {
      console.error('Error on fetching players', e);
      dispatch(setError(PageError.Sorry));
    }
  }
);

export const getPlayerCareers = (): ActionWithPromiseReturn => async (dispatch): Promise<void> => {
  const careerList = await getCareersRequest();

  dispatch(setCareers(careerList));
};

interface GetPlayersStatisticsType {
  topLevel: string;
  teamType: string;
  language: LanguageType;
}

export const getPlayersStatistics = ({ topLevel, teamType, language }: GetPlayersStatisticsType): Action => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();

    try {
      const subItem = getSecondLevelMenuItem(getState(), topLevel, teamType, language)?.[language];
      const subItemId = subItem?.data.id as TeamSubMenuId;
      const competition = mapTeamTypeToCompetition(subItemId, getState().configuration[language]?.competitions ?? {});
      if (subItem && teamType && isBaseMenuItem(subItem?.data)) {
        const { statisticsSeasonId = '', statisticsOptaId = '' } = competition ?? {};
        const teamId = (subItem?.data?.optaId ?? '').replace(/\D/g, '');
        const statistics = await getTeamStatistics({
          seasonId: statisticsSeasonId, teamId, optaId: statisticsOptaId,
        });// todo: remove hardcode
        dispatch(setTeamStatistics(statistics, subItemId));
      }
    } catch (e) {
      console.error('Error on fetching players', e);
    }
  }
);
