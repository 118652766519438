import React from 'react';
import styled from 'styled-components';
import isNumber from 'lodash/isNumber';

import { getCommonPageDataForSsr } from '@app/store/actions/commonPageActions';
import { PageTypeToComponent } from '@app/constants/commonPageConstants';

import { SecondLevelMenu, Metadata } from '@app/components';
import { PageWrapper } from '@app/pages/components';
import { usePageType, usePageValidation, useSeoData } from './CommonPageHooks';

const CommonPage = (): React.ReactElement => {
  const pageType = usePageType();
  const PageComponent = isNumber(pageType) ? PageTypeToComponent[pageType] : null;
  const seo = useSeoData();
  const error = usePageValidation();

  return (
    <Container>
      <PageWrapper error={error}>
        {seo && <Metadata seo={seo} />}
        <SecondLevelMenu />
        <Content>
          {
            PageComponent && (<PageComponent />)
          }
        </Content>
      </PageWrapper>
    </Container>
  );
};

CommonPage.serverFetch = [getCommonPageDataForSsr];

const Container = styled.div`
  padding-top: calc(var(--navigation-header-height) + var(--top-header-height));
`;

const Content = styled.div`
  flex-grow: 1;
  padding-bottom: 25px;
  font-size: 32px;
`;

export default CommonPage;
