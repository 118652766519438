import React from 'react';

import { ContainerMedium } from '@app/components/WebTemplates/components';
import { WebIframeTemplateElement } from '@app/types/webTemplateTypes';
import styled from 'styled-components';

interface WebIframeParamsProps {
  data: WebIframeTemplateElement;
}

const WebIframeElement = ({ data }: WebIframeParamsProps): React.ReactElement => (
  <Container>
    {/* eslint-disable-next-line react/no-danger */}
    <div dangerouslySetInnerHTML={{ __html: data.iframeInnerHtml }} />
  </Container>
);

const Container = styled(ContainerMedium)`
  margin-top: 20px;
`;

export default WebIframeElement;
