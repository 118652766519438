import React from 'react';

import { InviolaDynamicFieldRepresentations } from '@app/services/inviola/types/responseTypes';

import { mapSelectOptions } from '@app/helpers/inviolaHelpers';

import { Checkbox, HiddenInput, Input, Select } from './InviolaFields';
import { useCustomerDynamicFieldValue, useDynamicField, useInputValidations } from './InviolaHooks';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const inputComponents: Partial<Record<InviolaDynamicFieldRepresentations, (props: any) => React.ReactElement>> = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [InviolaDynamicFieldRepresentations.Select]: Select,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [InviolaDynamicFieldRepresentations.Checkbox]: Checkbox,
};

const inputRepresentations: Partial<Record<InviolaDynamicFieldRepresentations, string>> = {
  [InviolaDynamicFieldRepresentations.Date]: 'date',
  [InviolaDynamicFieldRepresentations.Text]: 'text',
  [InviolaDynamicFieldRepresentations.Checkbox]: 'checkbox',
};

interface DynamicFieldProps {
  id: number;
}

export const InviolaDynamicField = React.memo(({ id }: DynamicFieldProps): React.ReactElement | null => {
  const dynamicField = useDynamicField(id);
  const fieldValue = useCustomerDynamicFieldValue(id);

  const validationsMap = useInputValidations();

  if (!dynamicField || !dynamicField.enabled) {
    return null;
  }

  const FieldComponent = inputComponents[dynamicField.representation] ?? Input;

  const fieldIndex = fieldValue ? `user-${id}` : `new-${id}`;
  const isSelect = dynamicField.representation === InviolaDynamicFieldRepresentations.Select;
  const isCheckbox = dynamicField.representation === InviolaDynamicFieldRepresentations.Checkbox;

  const validations = dynamicField.mandatory ? { validations: validationsMap.required } : {};
  const options = isSelect ? { options: mapSelectOptions(dynamicField.options) } : {};
  const defaultValue = isCheckbox ? { defaultChecked: fieldValue } : { defaultValue: fieldValue };
  const emptyOption = isSelect ? { emptyOption: true } : {};

  const fieldOptions = {
    name: `formDynamicFields[${fieldIndex}][value]`,
    labelId: `inviola.dynamicFields.${id}`,
    placeholderId: `inviola.dynamicFields.${id}`,
    type: inputRepresentations[dynamicField.representation],
    ...options,
    ...emptyOption,
    ...defaultValue,
    ...validations,
  };

  return (
    <>
      <HiddenInput name={`formDynamicFields[${fieldIndex}][id]`} value={id} />
      <FieldComponent {...fieldOptions} />
    </>
  );
});

export default InviolaDynamicField;
