import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import pull from 'lodash/pull';
import styled from 'styled-components';

import { AppState } from '@app/types/appStateTypes';

import { AppLanguages } from '@app/constants/localizationConstants';

import { useLanguage } from '@app/components/Hooks';
import Language, { ExpandArrowState } from './LanguageItem';

interface UseLangSwitcher {
  isOpened: boolean;
  openLangList: { (): void };
  closeLangList: { (): void };
}

const useLangSwitcherState = (): UseLangSwitcher => {
  const [isOpened, setIsOpened] = useState(false);
  const openLangList = (): void => setIsOpened(true);
  const closeLangList = (): void => setIsOpened(false);

  return { isOpened, openLangList, closeLangList };
};

const LanguageSwitcher: React.FC = () => {
  const currentRoute = useSelector<AppState, AppState['currentRoute']>(
    (state) => state.currentRoute,
    shallowEqual,
  );
  const { isOpened, openLangList, closeLangList } = useLangSwitcherState();
  const language = useLanguage();

  const languages = pull([...AppLanguages], language);
  languages.unshift(language);

  return (
    <LanguageControl
      onClick={openLangList}
      onMouseLeave={closeLangList}
    >
      <SelectedLanguage
        isOpened={isOpened}
        lang={language}
        expandArrow={ExpandArrowState.Closed}
      />
      <LangList isOpened={isOpened}>
        {
          languages.map((lang, index) => (
            <Language
              key={lang}
              lang={lang}
              expandArrow={!index ? ExpandArrowState.Opened : ExpandArrowState.Hidden}
              onClick={
                (e): void => {
                  closeLangList();
                  e.stopPropagation();
                }
              }
              url={currentRoute[lang]}
            />
          ))
        }
      </LangList>
    </LanguageControl>
  );
};

const LanguageControl = styled.div`
  cursor: pointer;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 12px;
  position: relative;
  text-transform: uppercase;
  z-index: 5;
  width: 95px;
`;

const SelectedLanguage = styled(Language)<{ isOpened: boolean }>`
  visibility: ${({ isOpened }): string => (isOpened ? 'hidden' : 'visible')};
`;

const LangList = styled.ul<{ isOpened: boolean }>`
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  color: white;
  visibility: ${({ isOpened }): string => (isOpened ? 'visible' : 'hidden')};
  width: 100%;
`;

export default LanguageSwitcher;
