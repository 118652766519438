import { MenuActions, MenuActionTypes } from '@app/store/actionTypes/menuActionTypes';

export type MenuState = boolean;

export default (
  state = false,
  action: MenuActions,
): MenuState => (
  action.type === MenuActionTypes.SET_STATE ? action.payload : state
);
