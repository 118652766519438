
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class WebCard extends ContentItem {
    public layoutType: Elements.MultipleChoiceElement;
    public subTitle: Elements.TextElement;
    public cta: Elements.LinkedItemsElement<ContentItem>;
    public title: Elements.TextElement;
    public backgroundImage: Elements.CustomElement;
    public layoutSize: Elements.MultipleChoiceElement;
    public ctaPosition: Elements.MultipleChoiceElement;
    public link: Elements.LinkedItemsElement<ContentItem>;
    public id: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'layout_type') {
                    return 'layoutType';
                }
                if (elementName === 'sub_title') {
                    return 'subTitle';
                }
                if (elementName === 'background_image') {
                    return 'backgroundImage';
                }
                if (elementName === 'layout_size') {
                    return 'layoutSize';
                }
                if (elementName === 'cta_position') {
                    return 'ctaPosition';
                }
                return elementName;
            })
        });
    }
}
