import { ContentItem, Elements } from '@kentico/kontent-delivery';

export default class ExternalLink extends ContentItem {
    public linkName: Elements.TextElement;
    public linkUrl: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'link_name') {
                    return 'linkName';
                }
                if (elementName === 'link_url') {
                    return 'linkUrl';
                }
                return elementName;
            })
        });
    }
}
