import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { MountContext } from '@app/ReactContext';
import { useLanguage } from '@app/components/Hooks';

import { useCommonPageBaseMultiLangRoute, useLastLevelMenuId } from '@app/pages/CommonPage/CommonPageHooks';

import { setCatalogFilterPage, setCatalogSelectedItemId, syncCatalogItems } from '@app/store/actions/inviolaActions';
import AppState from '@app/types/appStateTypes';
import { InviolaCatalogFilters, InviolaCatalogItem, InviolaCatalogItemId } from '@app/types/inviolaTypes';
import { SubMenuId } from '@app/types/configurationTypes';
import { getCatalogItemsFromWishList } from '@app/store/actions/inviolaActions/catalog';
import { scrollToElement } from '@app/helpers/documentHelper';
import { CommonPageBaseUrlParams } from '@app/helpers/routeHelpers';
import { LanguageType } from '@app/types/localizationTypes';

export interface ParamTypes {
  topLevel: string;
  secondLevel: string
  thirdLevel: string
  forthLevel: string;
  fifthLevel: string;
}

export const useCatalogSync = (): void => {
  const { topLevel = '', secondLevel = '', thirdLevel = '' } = useParams<ParamTypes>();
  const language = useLanguage();
  const lastLevelMenuId = useLastLevelMenuId();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(syncCatalogItems(language));
  }, [topLevel, secondLevel, thirdLevel, lastLevelMenuId, language]);
};

export const useCatalogSelectedItemReset = (): void => {
  const dispatch = useDispatch();

  useEffect(() => (): void => { dispatch(setCatalogSelectedItemId()); }, []);
};

export function useWishListDownload(): void {
  const { isInitialMount } = useContext(MountContext);
  const {
    topLevel = '', secondLevel = '', thirdLevel = '',
  } = useParams<ParamTypes>();
  const language = useLanguage();
  const lastLevelMenuId = useLastLevelMenuId();
  const dispatch = useDispatch();

  const hasItems = useSelector<AppState, boolean>(
    (state) => state.inviola.wishList.length > 0,
    shallowEqual,
  );
  useEffect(() => {
    if (!isInitialMount || !hasItems) {
      // check if data has been loaded on server before very first render
      !hasItems && dispatch(getCatalogItemsFromWishList(language));
    }
  }, [topLevel, secondLevel, thirdLevel, lastLevelMenuId, language]);
}

export function useCatalogItems(): InviolaCatalogItem[] {
  return useSelector<AppState, InviolaCatalogItem[]>((state) => state.inviola.catalog.items);
}

export const useCatalogSelectedItemId = ():number | undefined => useSelector<AppState, number | undefined>(
  (state) => state.inviola.catalog.selectedItemId, shallowEqual,
);

export const useCatalogFilters = ():InviolaCatalogFilters => useSelector<AppState, InviolaCatalogFilters>(
  (state) => state.inviola.catalog.filters, shallowEqual,
);

export const useCatalogScrollToSelectedItem = (): void => {
  const dispatch = useDispatch();

  const selectedItemId = useCatalogSelectedItemId();
  const catalogItems = useCatalogItems();
  const { pageCount } = useCatalogFilters();

  const selectedItemIndex = catalogItems?.findIndex(({ id }) => id === selectedItemId) ?? 0;
  const useScrollToItem = 10 * pageCount > selectedItemIndex;
  const requiredPageCount = useScrollToItem ? pageCount : Math.ceil(selectedItemIndex / 10);

  useEffect((): void => {
    if (selectedItemId) {
      if (useScrollToItem) {
        setTimeout(() => scrollToElement(`${selectedItemId}`, false), 100);
        dispatch(setCatalogSelectedItemId());
      } else {
        dispatch(setCatalogFilterPage(requiredPageCount));
      }
    }
  }, [selectedItemId, useScrollToItem]);
};

export function useCatalogItemsCacheDate(): number | null {
  return useSelector<AppState, number | null>((state) => state.inviola.catalog.cache);
}

export function useCatalogSyncStatus(): boolean {
  return useSelector<AppState, boolean>(
    (state) => state.inviola.catalog.syncInProgress,
    shallowEqual,
  );
}

export function useWishListItems(): InviolaCatalogItemId[] {
  return useSelector<AppState, InviolaCatalogItemId[]>((state) => state.inviola.wishList);
}

export function useCatalogItem(id?: number): InviolaCatalogItem | undefined {
  return useSelector((state: AppState) => state.inviola.catalog.items.find((item) => item.id === id));
}

export function useCurrentCatalogItem(): [boolean, InviolaCatalogItem | undefined] {
  const { thirdLevel } = useParams<ParamTypes>();
  const catalogItemId = parseInt(thirdLevel, 10);
  const activeItem = useCatalogItem(catalogItemId);

  return [!Number.isNaN(catalogItemId), activeItem];
}

export function useInviolaCatalogItemUrlParams(item: InviolaCatalogItem): Record<string, string> {
  const language = useLanguage();

  return useSelector<AppState, Record<string, string>>(
    (state) => {
      // eslint-disable-next-line camelcase
      const menuItem = state.configuration[language]?.menu?.web_inviola;
      const subItem = menuItem?.navigation[SubMenuId.CATALOG_ITEMS];

      return {
        topLevel: menuItem?.data?.url ?? '',
        secondLevel: subItem?.data?.url ?? '',
        thirdLevel: item.id.toString(),
        forthLevel: '',
        fifthLevel: '',
      };
    },
    isEqual,
  );
}

export function useInviolaCatalogMultiLangRoute(): void {
  const { thirdLevel } = useParams<ParamTypes>();

  const thirdLevelParams:Partial<Record<LanguageType, string>> = thirdLevel ? {
    en: thirdLevel,
    it: thirdLevel,
  } : {};
  const initialParams: CommonPageBaseUrlParams = {
    thirdLevel: thirdLevelParams,
  } as CommonPageBaseUrlParams;

  useCommonPageBaseMultiLangRoute(initialParams);
}
