import Competition from '@app/services/kentico/types/competition';
import KenicoConfig from '@app/services/kentico/types/configuration';

export interface LinkedCompetitions {
  competitions: Competition[];
  archives: Competition[];
}

export const mapSeasonCategoryIdToConfigCategoryIdMap = (data: KenicoConfig, id: string): LinkedCompetitions => {
  switch (id) {
    case 'web_seasons_men_first_team': return {
      competitions: data.squadMenCompetitions?.value ?? [],
      archives: data.squadMenCompetitionsArchive?.value ?? [],
    };
    case 'web_seasons_women_first_team': return {
      competitions: data.squadWomenCompetitions?.value ?? [],
      archives: [],
    };
    case 'web_seasons_primavera': return {
      competitions: data.squadPrimaveraCompetitions?.value ?? [],
      archives: [],
    };
    default: return {
      competitions: [],
      archives: [],
    };
  }
};
