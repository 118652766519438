import React from 'react';

import { App } from '@app/components';

import { RoutePath } from '@app/types/routerTypes';
import { PageError } from '@app/types/errorTypes';

import { CommonPage, Error } from '@app/pages';
import Home from '@app/pages/Home/Home';

type AppRoutes = Record<string, {
  path: RoutePath;
  component: React.FC<{error: PageError }> | React.FC<unknown>;
  exact?: boolean;
}>

const AppRoutes: AppRoutes = {
  App: {
    path: 'routes.home',
    component: App,
  },
  Home: {
    path: 'routes.home',
    component: Home,
    exact: true,
  },
  CommonPage: {
    path: 'routes.commonPage',
    component: CommonPage,
    exact: true,
  },
  NotFound: {
    path: 'routes.notFound',
    component: Error,
  },
};

export default AppRoutes;
