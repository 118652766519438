import React from 'react';

import { VideoPlayer } from '@app/components';
import { ThroneVideo } from '@app/types/configurationTypes';

interface VideoProps {
  video: ThroneVideo;
  isPremium?: boolean;
}

const Video = ({ video, isPremium }: VideoProps): React.ReactElement => (
  <VideoPlayer
    src={video?.url ?? ''}
    remoteSubtitles={video?.subtitles ?? ''}
    isPremium={isPremium}
  />
);

export default Video;
