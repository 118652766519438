import React from 'react';
import { GoalkeeperStatistics } from '@app/types/teamsTypes';

import {
  ValueAndCaption, BlockContainerColumn, StatsBlock, StatsColumns,
} from '@app/components/Statistics';
import {
  DisciplineStatistics,
  DuelsStatistics,
  PassesStatistics,
} from '@app/pages/PlayerProfile/Profiles/PlayerPositionStatistics/StatisticsSections';

interface GoalkeeperStatisticsProps {
  statistics?: GoalkeeperStatistics;
}

const GoalkeeperPlayerStatistics = ({ statistics }: GoalkeeperStatisticsProps): React.ReactElement => (
  <BlockContainerColumn>
    <StatsBlock borderWidth="0 0 1px 0">
      <StatsBlock marginBottom="20px">
        <ValueAndCaption value={statistics?.cleanSheets ?? 0} caption="statistics.cleanSheets" />
      </StatsBlock>
      <StatsBlock marginBottom="20px">
        <ValueAndCaption value={statistics?.goalsConceded ?? 0} caption="statistics.goalsConceded" />
      </StatsBlock>
    </StatsBlock>
    <StatsBlock marginTop="20px">
      <StatsBlock>
        <ValueAndCaption value={statistics?.savesMade ?? 0} caption="statistics.savesMade" isSmall />
      </StatsBlock>
      <StatsBlock>
        <ValueAndCaption value={statistics?.punches ?? 0} caption="statistics.punches" isSmall />
      </StatsBlock>
      <StatsBlock>
        <ValueAndCaption value={statistics?.catches ?? 0} caption="statistics.catches" isSmall />
      </StatsBlock>
    </StatsBlock>
  </BlockContainerColumn>
);

const GoalkeeperLayoutStatistics = ({ statistics }: GoalkeeperStatisticsProps): React.ReactElement => (
  <>
    <StatsColumns>
      <GoalkeeperPlayerStatistics statistics={statistics} />
      <PassesStatistics statistics={statistics} />
    </StatsColumns>
    <StatsColumns>
      <DuelsStatistics statistics={statistics} />
      <DisciplineStatistics statistics={statistics} />
    </StatsColumns>
  </>
);

export default GoalkeeperLayoutStatistics;
