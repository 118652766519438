import { Configuration } from '@app/types/configurationTypes';
import { LanguageType } from '@app/types/localizationTypes';
import KenticoConfiguration from '@app/services/kentico/types/configuration';

import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';
import { kenticoClient } from '@app/services/kentico/kentico';
import { mapConfig } from './converters/configurationConverters';

export async function getInitialConfiguration(language: LanguageType): Promise<Configuration> {
  // todo: request backup config on error
  const lang = langCodeToKenticoLangMap[language];
  const response = await kenticoClient()
    .item<KenticoConfiguration>('web_home_config')
    .depthParameter(3)
    .languageParameter(lang)
    .withParameter('system.language', lang)
    .toPromise();

  return response.item && mapConfig(response.item);
}
