import React from 'react';
import styled from 'styled-components';

import AppRoutes from '@app/constants/routesConstants';

import { BaseMenuItem } from '@app/types/configurationTypes';
import { COVER_IMAGE_TYPE } from '@app/constants/imageConstants';

import { Devices, Colors } from '@app/styles';

import { PageWrapper } from '@app/pages/components';
import { CategoryMenu } from '@app/components';
import { NewsList, FullSizeNewItem } from '@app/components/News';

import { useNewsLoadingState } from '@app/components/Hooks';
import {
  useCommonPageBaseMultiLangRoute,
  useSecondLevelMenu,
  useTopLevelMenu,
} from '@app/pages/CommonPage/CommonPageHooks';
import WebTemplatePage from '@app/pages/WebTemplate/WebTemplatePage';
import {
  useNewsDownload, useNewsList, useLoadMoreNews, usePageValidation,
} from './NewsListPageHooks';

const NewsListPage: React.FC = () => {
  useCommonPageBaseMultiLangRoute();
  const loadMoreNews = useLoadMoreNews();
  const topLevel = useTopLevelMenu();
  const subItem = useSecondLevelMenu();
  const categoryMenu = subItem?.categories ?? [];
  const listParams = useNewsList();
  const newsList = [...listParams.items];
  const topNews = newsList.shift();
  const error = usePageValidation();
  const isLoading = useNewsLoadingState();

  useNewsDownload();

  const pathParams = {
    topLevel: topLevel.data?.url ?? '',
    secondLevel: (subItem?.data as BaseMenuItem)?.url ?? '',
  };

  const params = {
    ...listParams,
    items: newsList,
  };

  return (
    <Container>
      {topNews && (<FullSizeNewItem data={topNews} coverType={COVER_IMAGE_TYPE.TOP_HIGHLIGHT_DESKTOP} />)}
      <ListContainer>
        <ListInnerContainer>
          <CategoryMenu
            items={categoryMenu}
            pathKey={AppRoutes.CommonPage.path}
            pathParams={pathParams}
            color={Colors.purple}
          />
          <PageWrapper error={error} isLoading={isLoading}>
            <NewsList {...params} dataRequest={loadMoreNews} />
          </PageWrapper>
          <WebTemplatePage />
        </ListInnerContainer>
      </ListContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props): string => props.theme.color.whiteSmoke};
`;

const ListContainer = styled.div`
  padding: 0 var(--horizontal-padding-mobile);

  @media ${Devices.desktopSmall} {
    padding: 0 var(--horizontal-padding);
  }
`;

const ListInnerContainer = styled.div`
  max-width: 920px;
  margin: 0 auto;
`;

export default NewsListPage;
