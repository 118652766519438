import { CurrentRouteParams } from '@app/store/actions/currentRouteActions';
import { LanguageType } from '@app/types/localizationTypes';
import AppState from '@app/types/appStateTypes';
import { RouteParams, NavigationLinkParams, SeasonRouteParams } from '@app/types/routerTypes';
import { BaseMenuItem, SubMenuId, SubMenuItem } from '@app/types/configurationTypes';

import { AppLanguages } from '@app/constants/localizationConstants';
import AppRoutes from '@app/constants/routesConstants';

import {
  getTopLevelMenuItem, getSecondLevelMenuItem, findCategoryMenuItemBySlug, isBaseMenuItem, findCategoryMenuItemById,
} from '@app/helpers/menuHelpers';

type GetCommonPageBaseUrlParams = (
  state: AppState,
  urlParams: RouteParams,
  initialParams?: CommonPageBaseUrlParams,
) => CommonPageBaseUrlParams

export interface CommonPageBaseUrlParams extends CurrentRouteParams{
  topLevel: Record<LanguageType, string>;
  secondLevel: Record<LanguageType, string>;
  thirdLevel: Record<LanguageType, string>;
  forthLevel: Record<LanguageType, string>;
  fifthLevel: Record<LanguageType, string>;
}

export const getCommonPageBaseUrlParams: GetCommonPageBaseUrlParams = (state, urlParams, initialParams) => {
  const {
    topLevel, secondLevel, thirdLevel, language,
  } = urlParams;
  const topLevelMenuItem = getTopLevelMenuItem(state, topLevel, language);
  const localizedSecondLevelMenuItem = getSecondLevelMenuItem(state, topLevel, secondLevel, language);
  const categoryId = thirdLevel
    ? (findCategoryMenuItemBySlug(localizedSecondLevelMenuItem?.[language]?.categories ?? [], thirdLevel)?.id ?? '')
    : '';

  const currentRouteParams = {
    topLevel: {}, secondLevel: {}, thirdLevel: {}, forthLevel: {}, fifthLevel: {},
  } as unknown as CommonPageBaseUrlParams;

  return AppLanguages.reduce((acc, language) => {
    const params = { ...acc };
    params.topLevel[language] = initialParams?.topLevel?.[language] ?? topLevelMenuItem[language]?.data?.url ?? '';
    const secondLevelMenuItem = localizedSecondLevelMenuItem?.[language];

    if (initialParams?.secondLevel?.[language]) {
      params.secondLevel[language] = initialParams?.secondLevel?.[language];
    } else if (secondLevelMenuItem && isBaseMenuItem(secondLevelMenuItem?.data)) {
      params.secondLevel[language] = secondLevelMenuItem?.data?.url ?? '';
    }

    params.thirdLevel[language] = initialParams?.thirdLevel?.[language] ?? findCategoryMenuItemById(secondLevelMenuItem?.categories ?? [], categoryId)?.url ?? '';
    params.forthLevel[language] = initialParams?.forthLevel?.[language] ?? '';
    params.fifthLevel[language] = initialParams?.fifthLevel?.[language] ?? '';

    return params;
  }, currentRouteParams);
};

interface FormatSubMenuLinkPropsFunc {
  (item: BaseMenuItem, subItem: SubMenuItem, seasonData: SeasonRouteParams): NavigationLinkParams;
}

export const formatSubMenuLinkProps: FormatSubMenuLinkPropsFunc = (
  item, subItem, seasonData,
) => {
  const topLevel = item?.url ?? '';
  const secondLevel = subItem?.data && isBaseMenuItem(subItem.data) ? subItem?.data?.url : '';
  const isSeasonRoute = [`${SubMenuId.SCHEDULE}`, `${SubMenuId.STANDINGS}`].includes(subItem?.data.id);
  const firstCategory = subItem?.categories?.[0];
  const thirdLevel = firstCategory && isBaseMenuItem(firstCategory) ? firstCategory?.url : '';

  return {
    pathKey: AppRoutes.CommonPage.path,
    pathParams: {
      topLevel,
      secondLevel: secondLevel ?? '',
      thirdLevel: isSeasonRoute ? seasonData.thirdLevel : thirdLevel ?? '',
      forthLevel: isSeasonRoute ? seasonData.forthLevel : '',
      fifthLevel: '',
    },
  };
};
