import {
  DefenderStatistics, ForwardStatistics, GoalkeeperStatistics,
  MidfielderStatistics, PlayerPosition, PlayerStatistics,
  TeamStatistics,
} from '@app/types/teamsTypes';
import { OptaTeamCompetitionResponse, OptaStat, OptaPlayer } from '../types/responseTypes';
import { getOptaItemValue } from '../helpers/responseHelpers';

interface GetStatistics {
  (player: OptaStat): PlayerStatistics;
}

/* eslint-disable camelcase */
function convertPlayerStatistics(player: OptaPlayer): PlayerStatistics {
  const { player_id } = player['@attributes'];
  return {
    playerId: player_id,
    gamesPlayed: getOptaItemValue(player, 'Games Played'),
    timePlayed: getOptaItemValue(player, 'Time Played'),
    yellowCards: getOptaItemValue(player, 'Yellow Cards'),
    redCards: getOptaItemValue(player, 'Total Red Cards'),
    duelsWon: getOptaItemValue(player, 'Duels won'),
    totalPasses: getOptaItemValue(player, 'Total Passes'),
    successfulShortPasses: getOptaItemValue(player, 'Successful Short Passes'),
    unsuccessfulShortPasses: getOptaItemValue(player, 'Unsuccessful Short Passes'),
    successfulLongPasses: getOptaItemValue(player, 'Successful Long Passes'),
    unsuccessfulLongPasses: getOptaItemValue(player, 'Unsuccessful Long Passes'),
    totalSuccessfulPasses: getOptaItemValue(player, 'Total Successful Passes ( Excl Crosses & Corners ) '),
    successfulLaunches: getOptaItemValue(player, 'Successful Launches'),
  };
}

function convertDefenderStatistics(player: OptaPlayer): DefenderStatistics {
  return {
    ...convertPlayerStatistics(player),
    position: PlayerPosition.Defender,
    recoveries: getOptaItemValue(player, 'Recoveries'),
    totalTackles: getOptaItemValue(player, 'Total Tackles'),
    blocks: getOptaItemValue(player, 'Blocks'),
    totalClearances: getOptaItemValue(player, 'Total Clearances'),
    totalFoulsConceded: getOptaItemValue(player, 'Total Fouls Conceded'),
    totalFoulsWon: getOptaItemValue(player, 'Total Fouls Won'),
  };
}

function convertGoalkeeperStatistics(player: OptaPlayer): GoalkeeperStatistics {
  return {
    ...convertPlayerStatistics(player),
    position: PlayerPosition.Goalkeeper,
    cleanSheets: getOptaItemValue(player, 'Clean Sheets'),
    goalsConceded: getOptaItemValue(player, 'Goals Conceded'),
    savesMade: getOptaItemValue(player, 'Saves Made'),
    punches: getOptaItemValue(player, 'Punches'),
    catches: getOptaItemValue(player, 'Catches'),
  };
}

function convertForwardStatistics(player: OptaPlayer): ForwardStatistics {
  return {
    ...convertPlayerStatistics(player),
    position: PlayerPosition.Forward,
    goals: getOptaItemValue(player, 'Goals'),
    rightFootGoals: getOptaItemValue(player, 'Right Foot Goals'),
    leftFootGoals: getOptaItemValue(player, 'Left Foot Goals'),
    headedGoals: getOptaItemValue(player, 'Headed Goals'),
    goalsFromInsideBox: getOptaItemValue(player, 'Goals from Inside Box'),
    goalsFromOutsideBox: getOptaItemValue(player, 'Goals from Outside Box'),
    totalShots: getOptaItemValue(player, 'Total Shots'),
    shotsOnTarget: getOptaItemValue(player, 'Shots On Target ( inc goals )'),
    shotsOffTarget: getOptaItemValue(player, 'Shots Off Target (inc woodwork)'),
    blockedShots: getOptaItemValue(player, 'Blocked Shots'),
  };
}

function convertMidfielderStatistics(player: OptaPlayer): MidfielderStatistics {
  return {
    ...convertPlayerStatistics(player),
    position: PlayerPosition.Midfielder,
    totalShots: getOptaItemValue(player, 'Total Shots'),
    shotsOnTarget: getOptaItemValue(player, 'Shots On Target ( inc goals )'),
    shotsOffTarget: getOptaItemValue(player, 'Shots Off Target (inc woodwork)'),
    blockedShots: getOptaItemValue(player, 'Blocked Shots'),
    recoveries: getOptaItemValue(player, 'Recoveries'),
    totalTackles: getOptaItemValue(player, 'Total Tackles'),
    totalFoulsConceded: getOptaItemValue(player, 'Total Fouls Conceded'),
    totalFoulsWon: getOptaItemValue(player, 'Total Fouls Won'),
  };
}

const positionMap: { [position: string]: GetStatistics } = {
  Defender: convertDefenderStatistics,
  Midfielder: convertMidfielderStatistics,
  Forward: convertForwardStatistics,
  Goalkeeper: convertGoalkeeperStatistics,
};

export function convertTeamStatistics(optaResponse: OptaTeamCompetitionResponse): TeamStatistics {
  const response: TeamStatistics = {
    gamesPlayed: getOptaItemValue(optaResponse?.SeasonStatistics?.Team, 'Games Played'),
    players: {},
  };
  if (optaResponse?.SeasonStatistics?.Team?.Player) {
    optaResponse.SeasonStatistics.Team.Player.forEach((player) => {
      const { player_id, position } = player['@attributes'];
      if (player.Stat) {
        response.players[player_id] = positionMap[position](player);
      }
    });
  }
  return response;
}

/**
 * OPTA CLASSIC team Id format - t123
 * UI team Id format - 123
 * */
export const convertTeamId = (id = ''): string => id.replace(/\D/g, '');
