import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { openCenteredPopup } from '@app/helpers/popupHelper';
import { useShareIcons } from '@app/components/SocialSharing/SocialSharingHooks';
import { ShareIconsItem, ShareIconsType } from '@app/types/configurationTypes';

export const shareUrlsMap: {
  [key in ShareIconsType]: string;
} = {
  [ShareIconsType.Facebook]: 'https://facebook.com/sharer/sharer.php?u={url}',
  [ShareIconsType.Twitter]: 'https://twitter.com/intent/tweet/?url={url}',
  [ShareIconsType.WhatsApp]: 'https://web.whatsapp.com/send?text={url}',
};

interface SharingButtonProps {
  url: string;
  shareIcon?: ShareIconsItem;
}

const SharingButton = ({
  url, shareIcon,
}: SharingButtonProps): React.ReactElement => (
  <>
    {shareIcon?.icon?.url && (
    <ShareLink
      onClick={(): void => openCenteredPopup(shareUrlsMap[shareIcon?.type].replace('{url}', url), shareIcon?.type, 626, 436)}
    >
      <img src={shareIcon?.icon?.url} alt={shareIcon?.icon?.description} />
    </ShareLink>
    )}
  </>
);

interface SocialSharingProps {
  onlyIcons?: boolean;
  url: string;
}

const SocialSharing = ({ onlyIcons, url }: SocialSharingProps): React.ReactElement => {
  const encodedUrl = url ? encodeURIComponent(url) : '';
  const shareIcons = useShareIcons();

  return (
    <ShareContainer>
      {
        !onlyIcons && (
          <ShareOnText>
            <FormattedMessage id="share" />
          </ShareOnText>
        )
      }
      {shareIcons?.map((shareIcon) => (
        <SharingButton shareIcon={shareIcon} url={encodedUrl} key={shareIcon.type} />
      ))}
    </ShareContainer>
  );
};

const ShareLink = styled.div`
  &:not(:last-child) {
    margin-right: 30px;
  }
  &:hover {
    cursor: pointer;
  }

  & > img {
    height: 20px;
  }
`;

const ShareOnText = styled.span`
  margin-right: 20px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.26px;
  text-transform: uppercase;
  color: inherit;  // Use defined on page text color
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  display: inline-block;
`;

const ShareContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export default SocialSharing;
